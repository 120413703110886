import React, { useState, useEffect } from 'react';

// Material-UI
import { MenuItem } from '@material-ui/core';

// Services
import { getCategories } from 'services/requests';

// Components
import SelectOutlined from 'components/select-outlined';

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT CATEGORY --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectCategory = ({ categoryId, setCategoryId }) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getCategories().then(res => {
            if (res.status === 200) {
                setCategories(res.data.data.categories);
            }
            else {
                console.log(res);
            }
        })
    }, []);

    return (
        <SelectOutlined
            value={categoryId}
            onChange={e => setCategoryId(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>Catégorie</em>
            </MenuItem>
            {categories.map(category => (
                <MenuItem
                    key={category.id}
                    value={category.id}
                >
                    {category.name}
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

export default SelectCategory;
