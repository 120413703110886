import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Switch, Route, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getSessionDay } from 'services/requests';
import { getFormattedDate, getSessionDayAddress } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import DayPrograms from '../day-programs';
import SessionDayUpdate from './session-days-update';

// ------------------------------------------------------------------------------------- \\
// ------------------------------------- SESSION DAY ----------------------------------- \\
// ------------------------------------------------------------------------------------- \\

const SessionDayRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <SessionDay />
            </Route>
            <Route exact path={`${match.path}/update`}>
                <SessionDayUpdate />
            </Route>
        </Switch>
    );
};

// ------------------------------------------------------------------------------------- \\
// ------------------------------------- SESSION DAY ----------------------------------- \\
// ------------------------------------------------------------------------------------- \\

const SessionDay = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();
    const { sessionDayId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        session_day: undefined,
    });

    useEffect(() => {
        if (state.loading) {
            getSessionDay(sessionDayId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, session_day: res.data.data.sessionDay });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Gérer la journée de formation
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={() => history.push(match.url + '/update')} variant="contained">
                        Modifier
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- SESSION DAY INFOS -------------------- */}
            <Grid style={{ padding: 24 }}>
                <SessionDayInfos
                    session_day={state.session_day}
                />
            </Grid>
            <Divider />
            {/* -------------------- DAY PROGRAMS -------------------- */}
            <DayPrograms
                session_day={state.session_day}
                refetch={() => setState({ ...state, loading: true })}
            />
        </>
    );
};

// ------------------------------------------------------------------------------------- \\
// ---------------------------------- SESSION DAY INFOS -------------------------------- \\
// ------------------------------------------------------------------------------------- \\

const SessionDayInfos = ({ session_day }) => {
    return (
        <>
            {/* -------------------- SESSION DATE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={2}>
                    Date
                </Grid>
                <Grid item xs={12} sm={10}>
                    {getFormattedDate(session_day.session_date)}
                </Grid>
            </Grid>
            {/* -------------------- TRAINING PLACE -------------------- */}
            {session_day.trainingPlace && (
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={2}>
                        Lieu
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        {session_day.trainingPlace.name}
                    </Grid>
                </Grid>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={2}>
                    Adresse
                </Grid>
                <Grid item xs={12} sm={10}>
                    {getSessionDayAddress(session_day)}
                </Grid>
            </Grid>
        </>
    );
};

export default SessionDayRouter;
