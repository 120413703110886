import React, { useState, useEffect } from 'react';

// Material-UI
import { MenuItem } from '@material-ui/core';

// Services
import { getRoles } from 'services/requests';

// Components
import SelectOutlined from 'components/select-outlined';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SELECT ROLE ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectRole = ({ roleId, setRoleId }) => {
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getRoles().then(res => {
            if (res.status === 200) {
                setRoles(res.data.data.roles);
            }
        });
    }, []);

    return (
        <SelectOutlined
            value={roleId}
            onChange={e => setRoleId(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>Type de compte</em>
            </MenuItem>
            {roles.map(role => (
                <MenuItem
                    key={role.id}
                    value={role.id}
                >
                    {role.display_name}
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

export default SelectRole;
