import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services
import { createUserCategory } from 'services/requests';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- CREATE USER CATEGORY ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const CreateUserCategory = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: '',
    });

    function onCreate() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createUserCategory({ name: state.name }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Ajouter un rôle
                </p>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                        Nom<FieldRequired />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Nom"
                            value={state.name}
                            onChange={e => setState({ ...state, name: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Annuler
                </Button>
                <Button onClick={onCreate} variant="contained">
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateUserCategory;
