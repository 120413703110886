import React from 'react';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- FIELD REQUIRED --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FieldRequired = ({ props }) => {
    return (
        <b style={{ ...props, fontSize: 22, color: '#f50057' }}>*</b>
    );
};

export default FieldRequired;
