import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider, Paper } from '@material-ui/core';
import { SaveAlt as DonwloadIcon } from '@material-ui/icons';

// Services
import { userCanUpdateFederatedAssociation } from 'services/permissions';
import { getFederatedAssociation } from 'services/requests';
import { fileUrl } from 'services/axios-config'
import { federatedAssociationTypes } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import PageNotFound from 'components/page-not-found';

// Views
import UpdateFederatedAssociation from './update-federated-association';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ROUTER -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FederatedAssociationRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <FederatedAssociation />
            </Route>
            {userCanUpdateFederatedAssociation() && (
                <Route exact path={`${match.path}/update`}>
                    <UpdateFederatedAssociation />
                </Route>
            )}
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------ FEDERATED ASSOCIATION ----------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FederatedAssociation = () => {
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const match = useRouteMatch();

    // Params
    const federatedAssociationId = match.params.federatedAssociationId;
    const backUrl = match.path.split('/:federatedAssociationId')[0];

    const [state, setState] = useState({
        loading: true,
        error: false,
        federated_association: null,
    });

    useEffect(() => {
        if (state.loading) {
            getFederatedAssociation(federatedAssociationId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, federated_association: res.data.data.federatedAssociation });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button
                        onClick={() => history.push(backUrl)}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10, textAlign: 'center' }}>
                    <b>{state.federated_association && state.federated_association.name}</b>
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanUpdateFederatedAssociation(federatedAssociationId) && (
                        <Button
                            onClick={() => history.push(`${match.url}/update`)}
                            variant="contained"
                        >
                            Modifier
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- INFOS -------------------- */}
            <FederatedAssociationInfos
                loading={state.loading}
                error={state.error}
                federated_association={state.federated_association}
            />
        </>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------- INFOS -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FederatedAssociationInfos = ({ loading, error, federated_association }) => {

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <Grid container style={{ padding: 24 }}>
                <Grid item xs={false} md={2} lg={4} />
                <Grid item xs={12} md={8} lg={4}>
                    <Paper elevation={3} style={{ padding: 24 }}>
                        {/* -------------------- IS ROOT -------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} className="primary-color">
                                Type
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {federatedAssociationTypes.find(t => t.value === federated_association.type).label}
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: '10px 0px' }} />
                        {/* -------------------- DOCUMENTATION -------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} className="primary-color">
                                Document de présentation
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {federated_association.documentation_url != null ? (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        target="_blank"
                                        style={{ textTransform: 'none' }}
                                        href={fileUrl + federated_association.documentation_url}
                                    >
                                        Télécharger
                                        <DonwloadIcon style={{ marginLeft: 5 }} />
                                    </Button>
                                ) : (
                                    'Aucun'
                                )}
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: '10px 0px' }} />
                        {/* -------------------- ADDRESS -------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} className="primary-color">
                                Adresse
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {federated_association.address} {federated_association.postal_code} {federated_association.city}{federated_association.country ? ', ' + federated_association.country.name : ''}
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: '10px 0px' }} />
                        {/* -------------------- DEPARTMENTS -------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} className="primary-color">
                                Départements
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {federated_association.frenchDepartments.length > 0 ? (
                                    federated_association.frenchDepartments.map((fd, index) => {
                                        if (index === 0) return fd.number + ' ' + fd.name;
                                        else return ', ' + fd.number + ' ' + fd.name;
                                    })
                                ) : (
                                'Aucun'
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {/* -------------------- CONTACTS -------------------- */}
            <Contacts federated_association={federated_association} />
        </>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- CONTACTS --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Contacts = ({ federated_association }) => {
    const { mediaMD } = useMediaQueries();

    return (
        <div className="main-container">
            {/* -------------------- HEADERS -------------------- */}
            {mediaMD ? (
                <Grid container alignItems="center" className="list-headers">
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Nom
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Fonction ou rôle
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Email
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Téléphone
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ padding: 10 }}>
                        Description
                    </Grid>
                </Grid>
            ) : (
                <Grid container justify="center" className="list-headers" style={{ padding: 10 }}>
                    Contacts
                </Grid>
            )}
            {/* -------------------- CONTACT LIST -------------------- */}
            <div className="main-container-item">
                {federated_association.contacts.length > 0 ? (
                    federated_association.contacts.map(contact => (
                        <Fragment key={contact.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                                    {contact.name}
                                </Grid>
                                <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                                    {contact.type}
                                </Grid>
                                <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                                    {contact.email}
                                </Grid>
                                <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                                    {contact.phone}
                                </Grid>
                                <Grid item xs={12} sm={4} style={{ padding: 10 }}>
                                    {contact.description}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid container style={{ padding: 10 }}>
                            Aucun
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
        </div>
    );
};

export default FederatedAssociationRouter;
