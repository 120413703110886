import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services
import { updateExternalBill } from 'services/requests';

// Components
import Spinner from 'components/spinner';
import InputText from 'components/input-text';
import InputFile from 'components/input-file';
import FieldRequired from 'components/field-required';

// Messages
import messages from 'messages.json';

// -------------------------------------------------------------------------------------- \\
// -------------------------------- UPDATE EXTERNAL BILL -------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const UpdateExternalBill = ({ bill, onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        external_reference: bill.external_reference || '',
        uploaded_file: null,
        uploaded_credit_file: null,
    });

    function onSave() {
        if (state.external_reference === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const uploaded_file = document.getElementById('upload-file').files[0];
            const uploaded_credit_file = document.getElementById('upload-credit-file').files[0];
            setState({ ...state, loading: true, uploaded_file, uploaded_credit_file });
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('external_reference', state.external_reference);

            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }
            if (state.uploaded_credit_file) {
                formData.append('uploaded_credit_file', state.uploaded_credit_file);
            }

            updateExternalBill(bill.id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog
            open
            fullWidth
        >
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter une référence externe
                        </p>
                        {/* -------------------- EXTERNAL REFERENCE -------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                                Référence externe<FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputText
                                    label="Référence externe"
                                    value={state.external_reference}
                                    onChange={e => setState({ ...state, external_reference: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* -------------------- BILL -------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 6 }}
                        >
                            <Grid item xs={12} sm={4}>
                                Facture PDF
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputFile inputId="file" />
                            </Grid>
                        </Grid>
                        {/* -------------------- CREDIT -------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 6 }}
                        >
                            <Grid item xs={12} sm={4}>
                                Avoir PDF
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputFile inputId="credit-file" />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            Fermer
                        </Button>
                        <Button
                            onClick={onSave}
                            variant="contained"
                        >
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateExternalBill;
