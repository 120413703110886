import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Button } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

// Services
import useMediaQueries from 'services/media';

// Views
import UpdateSubscriptionTypes from './subscription-types/update-subscription-types';
import UpdateMembershipTypes from './membership-types/update-membership-types';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------- UPDATE SHOP ------------------------------------ \\
// -------------------------------------------------------------------------------------- \\

const UpdateShop = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const [menu, setMenu] = useState('memberships');

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container
                    style={{ justifyContent: mediaMD ? 'flex-start' : 'center' }}
                >
                    <Button
                        onClick={() => history.push('/shop')}
                        variant="contained"
                        style={{ marginRight: 10 }}
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ padding: mediaMD ? 0 : 10 }}
                >
                    Mettre à jour la boutique
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    style={{ justifyContent: mediaMD ? 'flex-end' : 'center' }}
                >
                    <ToggleButtonGroup
                        value={menu}
                        onChange={(e, value) => value ? setMenu(value) : undefined}
                        exclusive
                        size="small"
                        className="toggle-button"
                    >
                        <ToggleButton value="memberships" style={{ width: 122 }}>
                            Adhésions
                        </ToggleButton>
                        <ToggleButton value="subscriptions" style={{ width: 122 }}>
                            Abonnements
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            {/* -------------------- SUBSCRIPTION & MEMBERSHIP TYPES -------------------- */}
            {menu === 'memberships' && (
                <UpdateMembershipTypes />
            )}
            {menu === 'subscriptions' && (
                <UpdateSubscriptionTypes />
            )}
        </>
    );
};

export default UpdateShop;
