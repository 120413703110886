import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Checkbox, Button, CircularProgress } from '@material-ui/core';

// Services
import { updateUser } from 'services/requests';
import { getGeocode } from 'services/helpers';

// Components
import InputText from 'components/input-text';
import DatePicker from 'components/date-picker';
import InputFile from 'components/input-file';
import FieldRequired from 'components/field-required';
import InputPhone from 'components/input-phone';

// Views
import SelectRole from 'selects/select-role';
import AddressForm from 'views/common/address-form';
import BillAddressForm from 'views/common/bill-address-form';
import ConfirmUpdateAddress from 'views/common/confirm-update-address';

// Messages
import messages from 'messages.json';

// ------------------------------------------------------------------------------------------ \\
// ------------------------------------ USER ATTRIBUTES ------------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const UserAttributes = ({ user }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        company_name: user.company_name || '',
        email: user.email || '',
        phone: user.phone || '',
        mobile_phone: user.mobile_phone || '',
        other_phone: user.other_phone || '',
        birth_date: user.birth_date || null,
        address: user.address || '',
        address_details: user.address_details || '',
        postal_code: user.postal_code || '',
        city: user.city || '',
        french_departments: [],
        french_department_id: user.french_department_id || -1,
        countries: [],
        country_id: user.country_id || 1,
        geocode: getGeocode(user),
        roles: [],
        role_id: (user.role && user.role.id) ? user.role.id : -1,
        password: '',
        presentation: user.presentation || '',
        uploaded_file: null,
        remove_photo: false,
        openConfirmUpdateAddress: false,
        update_bill_address: true,
        update_subscriptions_address: true,
        siret: user?.siret || '',

        // Bill Address
        bill_first_name: user?.billAddress?.first_name || '',
        bill_last_name: user?.billAddress?.last_name || '',
        bill_company_name: user?.billAddress?.company_name || '',
        bill_address: user?.billAddress?.address || '',
        bill_address_details: user?.billAddress?.address_details || '',
        bill_city: user?.billAddress?.city || '',
        bill_postal_code: user?.billAddress?.postal_code || '',
        bill_country_id: user?.billAddress?.country_id || 1,
        bill_email: user?.billAddress?.email || '',
        bill_geocode: getGeocode(user?.billAddress),
    });

    function onSave() {
        if (
            state.first_name === ''
            || state.last_name === ''
            || state.country_id === -1
            || (state.country_id === 1 && state.french_department_id === -1)
            || state.role_id === -1
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-photo').files[0];

            setState(prevState => ({ ...prevState, openConfirmUpdateAddress: true, uploaded_file: file }));
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('first_name', state.first_name);
            formData.append('last_name', state.last_name);
            formData.append('company_name', state.company_name);
            formData.append('email', state.email);
            formData.append('phone', state.phone);
            formData.append('mobile_phone', state.mobile_phone);
            formData.append('other_phone', state.other_phone);
            formData.append('birth_date', state.birth_date ? moment(state.birth_date).format('YYYY-MM-DD') : '');
            formData.append('address', state.address || '');
            formData.append('address_details', state.address_details || '');
            formData.append('postal_code', state.postal_code || '');
            formData.append('city', state.city || '');
            formData.append('french_department_id', state.country_id === 1 ? state.french_department_id : '');
            formData.append('country_id', state.country_id);
            formData.append('role_id', state.role_id);
            formData.append('password', state.password);
            formData.append('presentation', state.presentation);
            formData.append('remove_photo', state.remove_photo ? '1' : '0');
            formData.append('update_bill_address', state.update_bill_address ? '1' : '0');
            formData.append('update_subscriptions_address', state.update_subscriptions_address ? '1' : '0');
            formData.append('siret', state.siret);

            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }

            formData.append('bill_address', JSON.stringify({
                first_name: state.bill_first_name,
                last_name: state.bill_last_name,
                company_name: state.bill_company_name,
                email: state.bill_email,
                address: state.bill_address,
                address_details: state.bill_address_details,
                city: state.bill_city,
                postal_code: state.bill_postal_code,
                country_id: state.bill_country_id,
            }));

            updateUser(user.id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loading: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div style={{ padding: 12, backgroundColor: 'white', marginTop: 24 }}>
            {/* -------------------- TITLE & SUBMIT -------------------- */}
            <Grid
                container alignItems="center"
                style={{ padding: 12, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            >
                <Grid item xs>
                    <span
                        className="subtitle-primary-color"
                        style={{ fontSize: 16 }}
                    >
                        Informations client
                    </span>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        onClick={onSave}
                        disabled={state.loading}
                        endIcon={state.loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                        variant="contained" color="primary"
                    >
                        Enregistrer
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- ATTRIBUTES -------------------- */}
            <Grid
                container
                style={{ marginTop: 24 }}
            >
                <Grid
                    item xs={false} md={1} lg={2} xl={3}
                />
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    {/* -------------------- LAST NAME -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <span>Nom</span><FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom"
                                type="text"
                                value={state.last_name}
                                onChange={e => setState({ ...state, last_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- FIRST NAME -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Prénom</span><FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Prénom"
                                type="text"
                                value={state.first_name}
                                onChange={e => setState({ ...state, first_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- COMPANY NAME -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Nom de l'entreprise</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom de l'entreprise"
                                type="text"
                                value={state.company_name}
                                onChange={e => setState({ ...state, company_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- EMAIL -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Email</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Email"
                                type="email"
                                value={state.email}
                                onChange={e => setState({ ...state, email: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- PHONE -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Téléphone fixe</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputPhone
                                label="Téléphone fixe"
                                value={state.phone}
                                setValue={phone => setState({ ...state, phone })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- MOBILE PHONE -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Téléphone portable</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputPhone
                                label="Téléphone portable"
                                value={state.mobile_phone}
                                setValue={mobile_phone => setState({ ...state, mobile_phone })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- OTHER PHONE -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Autre téléphone</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputPhone
                                label="Autre téléphone"
                                value={state.other_phone}
                                setValue={other_phone => setState({ ...state, other_phone })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- SIRET -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>N°SIRET</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="N°SIRET"
                                type="number"
                                value={state.siret}
                                onChange={e => e.target.value?.length <= 14 ? setState({ ...state, siret: e.target.value }) : undefined}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- BIRTHDATE -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Date de naissance</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <DatePicker
                                outlined
                                value={state.birth_date}
                                onChange={birth_date => setState({ ...state, birth_date })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- ADDRESS FORM -------------------- */}
                    <AddressForm
                        state={state}
                        setState={setState}
                        requiredCountry
                        requiredDepartment
                    />
                    {/* -------------------- ROLE -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Type de compte</span><FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectRole
                                roleId={state.role_id}
                                setRoleId={role_id => setState({ ...state, role_id })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- PASSWORD -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Mot de passe</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Mot de passe"
                                type="password"
                                value={state.password}
                                onChange={e => setState({ ...state, password: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- PRESENTATION -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Présentation</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Présentation"
                                multiline
                                value={state.presentation}
                                onChange={e => setState({ ...state, presentation: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- PHOTO -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Photo de profil</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputFile inputId="photo" />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4} />
                        <Grid item xs={12} sm={8} container alignItems="center">
                            <Checkbox
                                value={state.remove_photo}
                                onChange={e => setState({ ...state, remove_photo: e.target.checked })}
                            />
                            Supprimer la photo de profil
                        </Grid>
                    </Grid>
                    {/* -------------------- BILL ADDRESS -------------------- */}
                    <div
                        className="subtitle-primary-color"
                        style={{ fontSize: 14, marginTop: 24 }}
                    >
                        Adresse de facturation
                    </div>
                    {/* -------------------- FIRST NAME -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            Prénom
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Prénom"
                                value={state.bill_first_name}
                                onChange={e => setState({ ...state, bill_first_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- LAST NAME -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            Nom
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom"
                                value={state.bill_last_name}
                                onChange={e => setState({ ...state, bill_last_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- EMAIL -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            Email
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Email"
                                value={state.bill_email}
                                onChange={e => setState({ ...state, bill_email: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- COMPANY NAME -------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 6 }}>
                        <Grid item xs={12} sm={4}>
                            Nom de l'entreprise
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom de l'entreprise"
                                value={state.bill_company_name}
                                onChange={e => setState({ ...state, bill_company_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- BILL ADDRESS FORM -------------------- */}
                    <BillAddressForm
                        state={state}
                        setState={setState}
                        disabledDepartment
                    />
                </Grid>
            </Grid>
            {/* -------------------- CONFIRM UPDATE ADDRESS -------------------- */}
            {state.openConfirmUpdateAddress && (
                <ConfirmUpdateAddress
                    state={state}
                    setState={setState}
                    onSubmit={() => setState(prevState => ({ ...prevState, openConfirmUpdateAddress: false, loading: true }))}
                />
            )}
        </div>
    );
};

export default UserAttributes;
