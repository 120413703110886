import React, { useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Radio, Checkbox, Accordion, AccordionSummary } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';
import { sessionPriceStatuses } from 'services/constants';
import { getAmountTTC, getLabelStatus } from 'services/helpers';

// Components
import InputText from 'components/input-text';
import DatePicker from 'components/date-picker';

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------- FORMATION REQUEST FORM 2 ------------------------------------ \\
// --------------------------------------------------------------------------------------------------- \\

const FormationRequestForm2 = ({ state, setState }) => {
    const { mediaMD } = useMediaQueries();

    const getCurrentUserId = () => {
        const item = Object.entries(state.accordions).find(([key, value]) => value === true);
        if (item) return item[0];
        return null;
    };

    const currentRegistration = state.user_registrations.find(r => r.user.id === Number(getCurrentUserId()));
    const currentPrice = currentRegistration ? currentRegistration.session_price : null;
    const currentPhone = currentRegistration ? currentRegistration.phone : '';
    const currentBirthDate = currentRegistration ? currentRegistration.birth_date : null;

    useEffect(() => {
        if (currentRegistration) {

            let userId = currentRegistration.user.id;
            let updatedUsersCompleted = state.usersCompleted;

            // True by default
            updatedUsersCompleted[userId] = true;

            if (currentPrice == null) {
                updatedUsersCompleted[userId] = false;
            }
            if (currentPhone.length <= 10) {
                updatedUsersCompleted[userId] = false;
            }
            if (currentPrice != null && currentPrice.require_birth_date && currentBirthDate == null) {
                updatedUsersCompleted[userId] = false;
            }

            setState({ ...state, usersCompleted: updatedUsersCompleted });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPrice, currentPhone, currentBirthDate]);

    function onChangeAccordion(userId, expanded) {
        let accordions = state.accordions;

        Object.keys(accordions).forEach(key => {
            accordions[key] = false;
        });

        accordions[userId] = expanded;
        setState({ ...state, accordions });
    }

    function getBackgroundColor(userId) {
        if (state.usersCompleted[userId]) {
            return '#6CE193';
        }
        return '#FF7B7D';
    }

    function birthDateIsRequired(userId) {
        const user_registrations = state.user_registrations;
        const user_registration = user_registrations.find(r => r.user.id === userId);
        if (user_registration.session_price && user_registration.session_price.require_birth_date) {
            return true;
        }
        return false;
    }

    function onChangePhone(userId, value) {
        let updated_registrations = state.user_registrations;
        let user_registration = updated_registrations.find(r => r.user.id === userId);
        user_registration.phone = value;
        setState({ ...state, user_registrations: updated_registrations });
    }

    function onChangeBirthDate(userId, value) {
        let updated_registrations = state.user_registrations;
        let user_registration = updated_registrations.find(r => r.user.id === userId);
        user_registration.birth_date = value;
        setState({ ...state, user_registrations: updated_registrations });
    }

    return (
        <>
            <Grid className="main-container" style={{ padding: 0 }}>
                <Grid style={{ padding: 24 }}>
                    <b>CONFIGUREZ L'INSCRIPTION DE CHAQUE UTILISATEUR</b>
                </Grid>          
                <Grid style={{ padding: '0px 24px 24px 24px' }}>
                    {state.user_registrations.map(user_registration => (
                        <Accordion
                            key={user_registration.user.id}
                            expanded={state.accordions[user_registration.user.id]}
                            onChange={(e, expanded) => onChangeAccordion(user_registration.user.id, expanded)}
                        >
                            <AccordionSummary
                                style={{ backgroundColor: getBackgroundColor(user_registration.user.id) }}
                            >
                                {user_registration.user.fullName}
                            </AccordionSummary>
                            <Divider />
                            <div style={{ padding: 24 }}>
                                {/* -------------------- SESSION PRICES -------------------- */}
                                <SessionPrices
                                    state={state}
                                    setState={setState}
                                    userId={user_registration.user.id}
                                />
                                <Divider style={{ margin: '15px 0px' }} />
                                {/* -------------------- SESSION OPTIONS -------------------- */}
                                <SessionOptions
                                    state={state}
                                    setState={setState}
                                    userId={user_registration.user.id}
                                />
                                <Divider style={{ margin: '15px 0px' }} />
                                {/* -------------------- PHONE -------------------- */}
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4}>
                                        Téléphone*
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <InputText
                                            label="Téléphone"
                                            value={user_registration.phone}
                                            onChange={e => onChangePhone(user_registration.user.id, e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                {/* -------------------- BIRTH DATE -------------------- */}
                                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                                    <Grid item xs={12} sm={4}>
                                        Date de naissance{birthDateIsRequired(user_registration.user.id) && '*'}
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <DatePicker
                                            outlined
                                            value={user_registration.birth_date}
                                            onChange={value => onChangeBirthDate(user_registration.user.id, value)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Accordion>
                    ))} 
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- SUBMIT -------------------- */}
            <Grid container alignItems="center" justify="center" style={{ padding: 24 }}>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        Précédent
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    2 / 3
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <Submit state={state} setState={setState} />
                </Grid>
            </Grid>
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SESSION PRICES ------------------------------------------ \\
// --------------------------------------------------------------------------------------------------- \\

const SessionPrices = ({ state, setState, userId }) => {

    function getSelectedPrice(session_price) {
        const user_registration = state.user_registrations.find(r => r.user.id === userId);
        if (user_registration.session_price && user_registration.session_price.id === session_price.id) {
            return true;
        }
        return false;
    }

    function onChangePrice(session_price) {
        let updated_registrations = state.user_registrations;
        let user_registration = updated_registrations.find(r => r.user.id === userId);
        user_registration.session_price = session_price;
        setState({ ...state, user_registrations: updated_registrations });
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={12} sm={4}>
                Tarifs*
            </Grid>
            <Grid item xs={12} sm={8}>
                {state.formationSession.sessionPrices.length > 0 ? (
                    state.formationSession.sessionPrices.map(sessionPrice => (
                        <Grid key={sessionPrice.id} container alignItems="center">
                            <Radio
                                color="secondary"
                                checked={getSelectedPrice(sessionPrice)}
                                onChange={() => onChangePrice(sessionPrice)}
                                style={{ padding: 6, marginRight: 5 }}
                            />
                            {getAmountTTC(sessionPrice.price)} - {getLabelStatus(sessionPriceStatuses, sessionPrice.status)}
                        </Grid>
                    ))
                ) : (
                    <Grid container style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SESSION OPTIONS ----------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const SessionOptions = ({ state, setState, userId }) => {

    function getSelectedOptions(session_option) {
        const user_registration = state.user_registrations.find(r => r.user.id === userId);
        if (user_registration.options.map(o => o.id).includes(session_option.id)) {
            return true;
        }
        return false;
    }

    function onChangeOptions(session_option) {
        let updated_registrations = state.user_registrations;
        let user_registration = updated_registrations.find(r => r.user.id === userId);
        let options = user_registration.options;

        if (options && options.map(o => o.id).includes(session_option.id)) {
            const index = options.findIndex(o => o.id === session_option.id);
            options.splice(index, 1);
        }
        else {
            options.push(session_option);
        }

        setState({ ...state, updated_registrations });
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={12} sm={4}>
                Options
            </Grid>
            <Grid item xs={12} sm={8}>
                {state.formationSession.sessionOptions.length > 0 ? (
                    state.formationSession.sessionOptions.map(sessionOption => (
                        <Grid key={sessionOption.id} container alignItems="center">
                            <Checkbox
                                color="secondary"
                                checked={getSelectedOptions(sessionOption)}
                                onChange={() => onChangeOptions(sessionOption)}
                                style={{ padding: 6, marginRight: 5 }}
                            /> 
                            {getAmountTTC(sessionOption.price)} - {sessionOption.description}
                        </Grid>
                    ))
                ) : (
                    <Grid container style={{ padding: 10 }}>
                        Aucune
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- SUBMIT --------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const Submit = ({ state, setState }) => {
    const { enqueueSnackbar } = useSnackbar();

    function onSubmit() {
        if (Object.values(state.usersCompleted).some(value => value === false)) {
            enqueueSnackbar('Veuillez finaliser la configuration de chaque utilisateur', { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 3 });
        }
    }

    return (
        <Button
            onClick={onSubmit}
            variant="contained"
        >
            Suivant
        </Button>
    );
};

export default FormationRequestForm2;
