import React, { useEffect } from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import InputPhone from 'components/input-phone';
import FieldRequired from 'components/field-required';

// Views
import AddressForm from 'views/common/address-form';

// Selects
import SelectActivities from 'selects/select-activities';
import SelectActivity from 'selects/select-activity';

// ----------------------------------------------------------------------------------------- \\
// ----------------------------------- SUBSCRIPTION FORM  ---------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const SubscriptionForm = ({ subscriptionType, state, setState }) => {

    useEffect(() => {
        if (
            subscriptionType === 'subscription'
            && state.subscription_type_id !== 1
        ) {
            if (state.country_id === 1) {
                let subscription_type_id = state.membershipStatus === 'active' ? 4 : 2;
                setState(prevState => ({
                    ...prevState,
                    subscription_type: state.subscriptionTypes.find(st => st.id === subscription_type_id),
                    subscription_type_id,
                }));
            }
            else {
                let subscription_type_id = state.membershipStatus === 'active' ? 5 : 3;
                setState(prevState => ({
                    ...prevState,
                    subscription_type: state.subscriptionTypes.find(st => st.id === subscription_type_id),
                    subscription_type_id,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.country_id]);

    return (
        <div style={{ padding: '24px 0px' }}>
            <p style={{ textAlign: 'center', color: '#f50057' }}>
                <em>Les champs avec un astérisque (*) sont obligatoires</em>
            </p>
            {/* -------------------- FIRST NAME -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 24 }}
            >
                <Grid item xs={12} sm={4}>
                    Prénom<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Prénom"
                        value={state.first_name}
                        onChange={e => setState({ ...state, first_name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- LAST NAME -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Nom<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- COMPANY NAME -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Nom de l'entreprise
                    {(subscriptionType === 'membership' && state.membership_type.is_pro === 1) && (
                        <FieldRequired />
                    )}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom de l'entreprise"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- ADDRESS FORM -------------------- */}
            <AddressForm
                state={state}
                setState={setState}
                requiredAddress
                requiredCity
                requiredPostalCode
                requiredCountry
                disabledDepartment
            />
            {/* -------------------- EMAIL -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Email
                    {subscriptionType === 'subscription' && (
                        <>&nbsp;abonnement</>
                    )}
                    {(
                        (subscriptionType === 'subscription' && state.subscription_type.id === 1)
                        || (subscriptionType === 'membership' && state.extraType?.id === 1)
                    ) && (
                        <FieldRequired />
                    )}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                    />
                </Grid>
            </Grid>
            <div style={{ margin: '24px 0px' }}>
                <span style={{ color: 'gray' }}>
                    <FieldRequired />Renseigner au moins 1 numéro de téléphone :
                </span>
                {/* -------------------- PHONE -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Téléphone fixe
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputPhone
                            label="Téléphone fixe"
                            value={state.phone}
                            setValue={phone => setState({ ...state, phone })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- MOBILE PHONE -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Téléphone portable
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputPhone
                            label="Téléphone portable"
                            value={state.mobile_phone}
                            setValue={mobile_phone => setState({ ...state, mobile_phone })}
                        />
                    </Grid>
                </Grid>
            </div>
            {/* * *
            * MEMBERSHIP ONLY
            */}
            {subscriptionType === 'membership' && (
                <>
                    {/* -------------------- MAIN ACTIVITY -------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={12} sm={4}>
                            {state.membership_type.is_pro ? (
                                <>Votre activité principale<FieldRequired /></>
                            ) : (
                                <>Afin de mieux vous connaître vous êtes<FieldRequired /></>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectActivity
                                label="Activité principale en lien avec la biodynamie"
                                activityId={state.main_activity_id}
                                setActivityId={main_activity_id => setState({ ...state, main_activity_id })}
                                isPro={state.membership_type.is_pro}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={false} sm={4} />
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Détails de l'activité"
                                value={state.profile}
                                onChange={e => setState({ ...state, profile: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- SECONDARY ACTIVITIES -------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Vos activités secondaires
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectActivities
                                isPro={true}
                                label="Activités secondaires"
                                activities={state.secondary_activities}
                                setActivities={secondary_activities => setState({ ...state, secondary_activities })}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default SubscriptionForm;
