import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams, useRouteMatch, Switch, Route } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button, Checkbox, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

// Services
import {
    getSubscriptionsForMailingList, updateIndexPreferences,
    removeSubscriptionsFromMailingList, updateSubscriptionInMailingList,
} from 'services/requests';
import { getStoredUser } from 'services/storage';
import { subscriptionStatuses, membershipStatuses } from 'services/constants';
import { getLabelStatus, getColorStatus, getFormattedDate, getMonthYearDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Pagination from 'components/pagination';
import InputText from 'components/input-text';
import Tooltip from 'components/tooltip';
import SelectCreatable from 'components/select-creatable';

// Selects
import SelectSubscriptionStatuses from 'selects/select-subscription-statuses';
import SelectMembershipStatuses from 'selects/select-membership-statuses';
import SelectFrenchDepartments from 'selects/select-french-departments';
import SelectAdherentType from 'selects/select-adherent-type';
import SelectCountries from 'selects/select-countries';
import SelectUserCategories from 'selects/select-user-categories';
import SelectFederatedAssociations from 'selects/select-federated-associations';
import SelectYesOrNo from 'selects/select-yes-or-no';

// Views
import Bill from 'views/common/bill';
import SubscriptionsForMailingListExport from './subscriptions-for-mailing-list-export';
import UpdateSubscription from 'views/management/subscriptions/update-subscription';
import { SubscriptionTypeHeader, SubscriptionTypeFilter, SubscriptionTypeValue } from 'views/columns/subscription-type';

/* * *
* Liste d'envoi d'utilisateurs pour la revue en question
*/

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ROUTER -------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SubscriptionsForMailingListRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <SubscriptionsForMailingList />
            </Route>
            <Route exact path={`${match.path}/:billId`}>
                <Bill />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------- SUBSCRIPTIONS FOR MAILING LIST ------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SubscriptionsForMailingList = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { magazineId, mailingListId } = useParams();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        subscriptions: [],
        offset: 0,
        limit: 20,
        total_count: 0,

        // Is Sent
        openIsSent: false,

        // Remove
        openRemove: false,
        checkboxAll: false,
        checkboxes: {},
        subscription_ids: [],

        // Update
        openUpdate: false,
        subscription: null,

        // Links
        openLinks: false,
        billId: null,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_is_sent: true,
        enabled_subscription_id: true,
        enabled_subscription_status: true,
        enabled_client_code: true,
        enabled_external_client_code: true,
        enabled_subscription_type: true,
        enabled_membership_status: true,
        enabled_last_name: true,
        enabled_first_name: true,
        enabled_company_name: true,
        enabled_mobile_phone: true,
        enabled_email: true,
        enabled_french_department: true,
        enabled_city: true,
        enabled_postal_code: false,
        enabled_address: false,
        enabled_address_details: false,
        enabled_country: false,
        enabled_phone: false,
        enabled_other_phone: false,
        enabled_subscription_email: false,
        enabled_subscription_expiration: false,
        enabled_month_year_subscription_expiration: false,
        enabled_user_category: false,
        enabled_birth_date: false,
        enabled_federated_association: false,
        enabled_membership_expiration: false,
        enabled_membership_email: false,
        enabled_bill_email: false,
        enabled_adherent_type: false,
        enabled_last_number_received: false,
        enabled_last_number_to_receive: false,
        enabled_is_alterrenat: false,

        // Filters
        subscription_statuses: [],
        subscription_id: '',
        client_code: '',
        external_client_code: '',
        subscription_types: [],
        membership_statuses: [],
        last_name: '',
        first_name: '',
        company_name: '',
        mobile_phone: '',
        email: '',
        french_departments: [],
        cities: [],
        postal_codes: [],
        address: '',
        address_details: '',
        countries: [],
        phone: '',
        other_phone: '',
        subscription_email: '',
        subscription_expiration: '',
        month_year_subscription_expirations: [],
        user_categories: [],
        birth_date: '',
        federated_associations: [],
        membership_expiration: '',
        membership_email: '',
        bill_email: '',
        adherent_type: -1,
        last_number_received: '',
        last_number_to_receive: '',
        is_alterrenat: -1,
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            subscription_statuses: [],
            subscription_id: '',
            client_code: '',
            external_client_code: '',
            subscription_types: [],
            membership_statuses: [],
            last_name: '',
            first_name: '',
            company_name: '',
            mobile_phone: '',
            email: '',
            french_departments: [],
            cities: [],
            postal_codes: [],
            address: '',
            address_details: '',
            countries: [],
            phone: '',
            other_phone: '',
            subscription_email: '',
            subscription_expiration: '',
            month_year_subscription_expirations: [],
            user_categories: [],
            birth_date: '',
            federated_associations: [],
            membership_expiration: '',
            membership_email: '',
            bill_email: '',
            adherent_type: -1,
            last_number_received: '',
            last_number_to_receive: '',
            is_alterrenat: -1,
        });
    }

    useEffect(() => {
        if (state.loading) {
            getSubscriptionsForMailingList(mailingListId, {
                offset: state.offset,
                limit: state.limit,
                subscription_statuses: state.subscription_statuses ? state.subscription_statuses.map(s => s.value) : null,
                subscription_id: state.subscription_id,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                subscription_type_ids: state.subscription_types ? state.subscription_types.map(s => s.value) : null,
                membership_statuses: state.membership_statuses ? state.membership_statuses.map(s => s.value) : null,
                last_name: state.last_name,
                first_name: state.first_name,
                company_name: state.company_name,
                mobile_phone: state.mobile_phone,
                email: state.email,
                french_department_ids: state.french_departments ? state.french_departments.map(d => d.value) : null,
                cities: state.cities ? state.cities.map(c => c.value) : null,
                postal_codes: state.postal_codes ? state.postal_codes.map(c => c.value) : null,
                address: state.address,
                address_details: state.address_details,
                country_ids: state.countries ? state.countries.map(c => c.value) : null,
                phone: state.phone,
                other_phone: state.other_phone,
                subscription_email: state.subscription_email,
                subscription_expiration: state.subscription_expiration,
                month_year_subscription_expirations: state.month_year_subscription_expirations ? state.month_year_subscription_expirations.map(e => e.value) : null,
                user_category_ids: state.user_categories ? state.user_categories.map(c => c.value) : null,
                birth_date: state.birth_date,
                federated_association_ids: state.federated_associations ? state.federated_associations.map(a => a.value) : null,
                membership_expiration: state.membership_expiration,
                membership_email: state.membership_email,
                bill_email: state.bill_email,
                adherent_type: state.adherent_type,
                last_number_received: state.last_number_received,
                last_number_to_receive: state.last_number_to_receive,
                is_alterrenat: state.is_alterrenat,
            }).then(res => {
                if (res.status === 200) {

                    let subscriptions = res.data.data.subscriptions;
                    let checkboxes = {};
                    subscriptions.forEach(subscription => {
                        checkboxes[subscription.id] = false;
                    });

                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        subscriptions,
                        checkboxes,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management/magazines/' + magazineId)}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Liste d'envoi
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={() => setState({ ...state, openRemove: true })} color="secondary" variant="contained">
                        Retirer
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- TITLE -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <b>Si vous souhaitez retirer des utilisateurs de la liste d'envoi, sélectionnez-les puis appuyer sur le bouton {'<Retirer>'}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <SubscriptionsForMailingListExport
                        mailingListId={mailingListId}
                        data={state}
                    />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <SubscriptionList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
            {/* -------------------- LINKS -------------------- */}
            {state.openLinks && (
                <SubscriptionLinks
                    state={state}
                    setState={setState}
                />
            )}
            {/* -------------------- REMOVE -------------------- */}
            {state.openRemove && (
                <RemoveSubscriptionsFromMailingList
                    onClose={() => setState({ ...state, openRemove: false })}
                    refetch={() => setState({ ...state, openRemove: false, loading: true })}
                    mailingListId={mailingListId}
                    subscriptionIds={state.subscription_ids}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- REMOVE -------------------- */}
            <div className="column-list" style={{ width: 160, visibility: 'hidden' }}>
                Checkboxes
            </div>
            {/* -------------------- IS SENT -------------------- */}
            {state.enabled_is_sent && (
                <div className="column-list">
                    Statut de l'envoi
                </div>
            )}
            {/* -------------------- SUBSCRIPTION ID -------------------- */}
            {state.enabled_subscription_id && (
                <div className="column-list">
                    N°abo
                </div>
            )}
            {/* -------------------- SUBSCRIPTION STATUS -------------------- */}
            {state.enabled_subscription_status && (
                <div className="column-list">
                    Statut abonnement
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    Code client
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    Code client externe
                </div>
            )}
            {/* -------------------- SUBSCRIPTION TYPE -------------------- */}
            {state.enabled_subscription_type && (
                <SubscriptionTypeHeader />
            )}
            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
            {state.enabled_membership_status && (
                <div className="column-list">
                    Statut adhésion
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    Nom
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    Prénom
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    Nom de l'entreprise
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    Téléphone portable
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    Email compte
                </div>
            )}
            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    Département
                </div>
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <div className="column-list">
                    Ville
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    Code postal
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    Adresse (n° et voie)
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    Adresse (complément)
                </div>
            )}
            {/* -------------------- PAYS -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    Pays
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    Téléphone fixe
                </div>
            )}
            {/* -------------------- OTHER PHONE -------------------- */}
            {state.enabled_other_phone && (
                <div className="column-list">
                    Autre téléphone
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
            {state.enabled_subscription_email && (
                <div className="column-list">
                    Email abonnement
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_subscription_expiration && (
                <div className="column-list">
                    Date d'expiration abo.
                </div>
            )}
            {/* -------------------- MONTH YEAR SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_month_year_subscription_expiration && (
                <div className="column-list">
                    Mois / Année d'expiration abo.
                </div>
            )}
            {/* -------------------- USER CATEGORY -------------------- */}
            {state.enabled_user_category && (
                <div className="column-list">
                    Rôle
                </div>
            )}
            {/* -------------------- BIRTH DATE -------------------- */}
            {state.enabled_birth_date && (
                <div className="column-list">
                    Date de naissance
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    Association fédérée
                </div>
            )}
            {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
            {state.enabled_membership_expiration && (
                <div className="column-list">
                    Date d'expiration adhé.
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    Email adhésion
                </div>
            )}
            {/* -------------------- BILL EMAIL -------------------- */}
            {state.enabled_bill_email && (
                <div className="column-list">
                    Email bon de commande
                </div>
            )}
            {/* -------------------- ADHERENT TYPE -------------------- */}
            {state.enabled_adherent_type && (
                <div className="column-list">
                    Type d'adhérent
                </div>
            )}
            {/* -------------------- LAST NUMBER RECEIVED -------------------- */}
            {state.enabled_last_number_received && (
                <div className="column-list">
                    Dernier n° reçu
                </div>
            )}
            {/* -------------------- LAST NUMBER TO RECEIVED -------------------- */}
            {state.enabled_last_number_to_receive && (
                <div className="column-list">
                    Abonné jusqu'au n°
                </div>
            )}
            {/* -------------------- IS ALTERRENAT -------------------- */}
            {state.enabled_is_alterrenat && (
                <div className="column-list">
                    Alterrenat
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {

    function onSelectAll(checked) {
        let subscription_ids = state.subscription_ids;
        let checkboxes = state.checkboxes;
        if (checked) {
            Object.keys(checkboxes).forEach(key => {
                checkboxes[key] = true;
            });
            state.subscriptions.forEach(subscription => {
                if (!subscription_ids.includes(subscription.id)) {
                    subscription_ids.push(subscription.id);
                }
            });
        }
        else {
            Object.keys(checkboxes).forEach(key => {
                checkboxes[key] = false;
            });
            subscription_ids = [];
        }
        setState({ ...state, checkboxAll: checked, checkboxes, subscription_ids });
    }

    // if (state.loading) return null;

    return (
        <div className="list-filters">
            {/* -------------------- REMOVE -------------------- */}
            <div className="column-list" style={{ width: 160, justifyContent: 'center' }}>
                {state.checkboxAll ? (
                    <Button onClick={() => onSelectAll(false)} variant="contained">
                        Aucun
                    </Button>
                ) : (
                    <Button onClick={() => onSelectAll(true)} variant="contained">
                        Tout
                    </Button>
                )}
            </div>
            {/* -------------------- IS SENT -------------------- */}
            {state.enabled_is_sent && (
                <div className="column-list" />
            )}
            {/* -------------------- SUBSCRIPTION ID -------------------- */}
            {state.enabled_subscription_id && (
                <div className="column-list">
                    <InputText
                        label="N°abo"
                        value={state.subscription_id}
                        onChange={e => setState({ ...state, subscription_id: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- SUBSCRIPTION STATUS -------------------- */}
            {state.enabled_subscription_status && (
                <div className="column-list">
                    <SelectSubscriptionStatuses
                        statuses={state.subscription_statuses}
                        setStatuses={subscription_statuses => onSelectSearch('subscription_statuses', subscription_statuses)}
                    />
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client"
                        value={state.client_code}
                        onChange={e => setState({ ...state, client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client externe"
                        value={state.external_client_code}
                        onChange={e => setState({ ...state, external_client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- SUBSCRIPTION TYPE -------------------- */}
            {state.enabled_subscription_type && (
                <SubscriptionTypeFilter
                    state={state}
                    onSelectSearch={onSelectSearch}
                />
            )}
            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
            {state.enabled_membership_status && (
                <div className="column-list">
                    <SelectMembershipStatuses
                        statuses={state.membership_statuses}
                        setStatuses={membership_statuses => onSelectSearch('membership_statuses', membership_statuses)}
                    />
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    <InputText
                        label="Nom"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    <InputText
                        label="Prénom"
                        value={state.first_name}
                        onChange={e => setState({ ...state, first_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    <InputText
                        label="Nom de l'entreprise"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone portable"
                        value={state.mobile_phone}
                        onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    <InputText
                        label="Email comtpe"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    <SelectFrenchDepartments
                        frenchDepartments={state.french_departments}
                        setFrenchDepartments={french_departments => onSelectSearch('french_departments', french_departments)}
                    />
                </div>
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.cities}
                        onChange={cities => onSelectSearch('cities', cities)}
                        placeholder="Ville"
                    />
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.postal_codes}
                        onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                        placeholder="Code postal"
                    />
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    <InputText
                        label="Adresse (n° et voie)"
                        value={state.address}
                        onChange={e => setState({ ...state, address: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    <InputText
                        label="Adresse (complément)"
                        value={state.address_details}
                        onChange={e => setState({ ...state, address_details: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PAYS -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    <SelectCountries
                        countries={state.countries}
                        setCountries={countries => onSelectSearch('countries', countries)}
                    />
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone fixe"
                        value={state.phone}
                        onChange={e => setState({ ...state, phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- OTHER PHONE -------------------- */}
            {state.enabled_other_phone && (
                <div className="column-list">
                    <InputText
                        label="Autre téléphone"
                        value={state.other_phone}
                        onChange={e => setState({ ...state, other_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
            {state.enabled_subscription_email && (
                <div className="column-list">
                    <InputText
                        label="Email abonnement"
                        value={state.subscription_email}
                        onChange={e => setState({ ...state, subscription_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_subscription_expiration && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.subscription_expiration}
                        onChange={e => setState({ ...state, subscription_expiration: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MONTH YEAR SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_month_year_subscription_expiration && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.month_year_subscription_expirations}
                        onChange={month_year_subscription_expirations => onSelectSearch('month_year_subscription_expirations', month_year_subscription_expirations)}
                        placeholder="Mois / Année d'expiration adhé."
                    />
                </div>
            )}
            {/* -------------------- USER CATEGORY -------------------- */}
            {state.enabled_user_category && (
                <div className="column-list">
                    <SelectUserCategories
                        userCategories={state.user_categories}
                        setUserCategories={user_categories => onSelectSearch('user_categories', user_categories)}
                    />
                </div>
            )}
            {/* -------------------- BIRTH DATE -------------------- */}
            {state.enabled_birth_date && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.birth_date}
                        onChange={e => setState({ ...state, birth_date: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    <SelectFederatedAssociations
                        federatedAssociations={state.federated_associations}
                        setFederatedAssociations={federated_associations => onSelectSearch('federated_associations', federated_associations)}
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
            {state.enabled_membership_expiration && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.membership_expiration}
                        onChange={e => setState({ ...state, membership_expiration: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    <InputText
                        label="Email adhésion"
                        value={state.membership_email}
                        onChange={e => setState({ ...state, membership_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL EMAIL -------------------- */}
            {state.enabled_bill_email && (
                <div className="column-list">
                    <InputText
                        label="Email bon de commande"
                        value={state.bill_email}
                        onChange={e => setState({ ...state, bill_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADHERENT TYPE -------------------- */}
            {state.enabled_adherent_type && (
                <div className="column-list">
                    <SelectAdherentType
                        adherentType={state.adherent_type}
                        setAdherentType={adherent_type => onSelectSearch('adherent_type', adherent_type)}    
                    />
                </div>
            )}
            {/* -------------------- LAST NUMBER RECEIVED -------------------- */}
            {state.enabled_last_number_received && (
                <div className="column-list">
                    <InputText
                        label="Dernier n° reçu"
                        value={state.last_number_received}
                        onChange={e => setState({ ...state, last_number_received: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- LAST NUMBER TO RECEIVED -------------------- */}
            {state.enabled_last_number_to_receive && (
                <div className="column-list">
                    <InputText
                        label="Abonné jusqu'au n°"
                        value={state.last_number_to_receive}
                        onChange={e => setState({ ...state, last_number_to_receive: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- IS ALTERRENAT -------------------- */}
            {state.enabled_is_alterrenat && (
                <div className="column-list">
                    <SelectYesOrNo
                        label="Alerrenat"
                        value={state.is_alterrenat}
                        onChange={is_alterrenat => onSelectSearch('is_alterrenat', is_alterrenat)}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- SUBSCRIPTION LIST -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SubscriptionList = ({ state, setState }) => {

    function onSelectSubscription(checked, subscription) {
        let subscription_ids = state.subscription_ids;
        let checkboxes = state.checkboxes;
        if (checked) {
            checkboxes[subscription.id] = true;
            subscription_ids.push(subscription.id);
        }
        else {
            checkboxes[subscription.id] = false;
            const index = subscription_ids.findIndex(id => id === subscription.id);
            if (index !== -1) {
                subscription_ids.splice(index, 1);
            }
        }
        setState({ ...state, checkboxes, subscription_ids });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.subscriptions.length > 0 && (
                state.subscriptions.map(subscription => (
                    <Fragment key={subscription.id}>
                        <div
                            onClick={() => setState({ ...state, openLinks: true, billId: subscription.billId, subscription })}
                            className="simple-list"
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- REMOVE -------------------- */}
                            <div className="column-list" style={{ width: 160, justifyContent: 'center' }}>
                                <Checkbox
                                    checked={state.checkboxes[subscription.id]}
                                    onChange={e => onSelectSubscription(e.target.checked, subscription)}
                                    style={{ padding: 6 }}
                                />
                            </div>
                            {/* -------------------- IS SENT -------------------- */}
                            {state.enabled_is_sent && (
                                <div className="column-list">
                                    {subscription.is_sent === 1 ? (
                                        <b style={{ color: 'green' }}>ENVOYÉ</b>
                                    ) : (
                                        <b style={{ color: 'red' }}>NON ENVOYÉ</b>
                                    )}
                                </div>
                            )}
                            {/* -------------------- CLIENT CODE -------------------- */}
                            {state.enabled_subscription_id && (
                                <div className="column-list">
                                    #{subscription.id}
                                </div>
                            )}
                            {/* -------------------- SUBSCRIPTION STATUS -------------------- */}
                            {state.enabled_subscription_status && (
                                <div className="column-list">
                                    <b style={{ color: getColorStatus(subscriptionStatuses, subscription.status) }}>
                                        {getLabelStatus(subscriptionStatuses, subscription.status)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- CLIENT CODE -------------------- */}
                            {state.enabled_client_code && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.clientCode : ''}
                                </div>
                            )}
                            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                            {state.enabled_external_client_code && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.external_client_code : ''}
                                </div>
                            )}
                            {/* -------------------- SUBSCRIPTION TYPE -------------------- */}
                            {state.enabled_subscription_type && (
                                <SubscriptionTypeValue
                                    value={subscription?.subscriptionType?.name}
                                />
                            )}
                            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
                            {state.enabled_membership_status && (
                                <div className="column-list">
                                    {subscription.user ? getLabelStatus(membershipStatuses, subscription.user.membershipStatus) : ''}
                                </div>
                            )}
                            {/* -------------------- LAST NAME -------------------- */}
                            {state.enabled_last_name && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.last_name : ''}
                                </div>
                            )}
                            {/* -------------------- FIRST NAME -------------------- */}
                            {state.enabled_first_name && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.first_name : ''}
                                </div>
                            )}
                            {/* -------------------- COMPANY NAME -------------------- */}
                            {state.enabled_company_name && (
                                <div className="column-list">
                                    {subscription.company_name}
                                </div>
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.mobile_phone : ''}
                                </div>
                            )}
                            {/* -------------------- EMAIL -------------------- */}
                            {state.enabled_email && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.email : ''}
                                </div>
                            )}
                            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                            {state.enabled_french_department && (
                                <div className="column-list">
                                    {(subscription.user && subscription.user.department) ? (
                                        subscription.user.department.name + ' (' + subscription.user.department.number + ')'
                                    ) : ''}
                                </div>
                            )}
                            {/* -------------------- CITY -------------------- */}
                            {state.enabled_city && (
                                <div className="column-list">
                                    {subscription.city}
                                </div>
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <div className="column-list">
                                    {subscription.postal_code}
                                </div>
                            )}
                            {/* -------------------- ADDRESS -------------------- */}
                            {state.enabled_address && (
                                <div className="column-list">
                                    {subscription.address}
                                </div>
                            )}
                            {/* -------------------- ADDRESS DETAILS -------------------- */}
                            {state.enabled_address_details && (
                                <div className="column-list">
                                    {subscription.address_details}
                                </div>
                            )}
                            {/* -------------------- PAYS -------------------- */}
                            {state.enabled_country && (
                                <div className="column-list">
                                    {subscription.country ? subscription.country.name : ''}
                                </div>
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.phone : ''}
                                </div>
                            )}
                            {/* -------------------- OTHER PHONE -------------------- */}
                            {state.enabled_other_phone && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.other_phone : ''}
                                </div>
                            )}
                            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
                            {state.enabled_subscription_email && (
                                <div className="column-list">
                                    {subscription.email}
                                </div>
                            )}
                            {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
                            {state.enabled_subscription_expiration && (
                                <div className="column-list">
                                    {subscription.end_date ? getFormattedDate(subscription.end_date) : ''}
                                </div>
                            )}
                            {/* -------------------- MONTH YEAR SUBSCRIPTION EXPIRATION -------------------- */}
                            {state.enabled_month_year_subscription_expiration && (
                                <div className="column-list">
                                    {subscription.end_date ? getMonthYearDate(subscription.end_date) : ''}
                                </div>
                            )}
                            {/* -------------------- USER CATEGORY -------------------- */}
                            {state.enabled_user_category && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.userCategories.map((c, index) => {
                                        if (index === 0) return c.name;
                                        else return ', ' + c.name;
                                    }) : ''}
                                </div>
                            )}
                            {/* -------------------- BIRTH DATE -------------------- */}
                            {state.enabled_birth_date && (
                                <div className="column-list">
                                    {(subscription.user && subscription.user.birth_date) ? getFormattedDate(subscription.user.birth_date) : ''}
                                </div>
                            )}
                            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                            {state.enabled_federated_association && (
                                <div className="column-list">
                                    {(subscription.user && subscription.user.federatedAssociation) ? subscription.user.federatedAssociation.name : ''}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
                            {state.enabled_membership_expiration && (
                                <div className="column-list">
                                    {(subscription.user && subscription.user.membershipExpiration) ? getFormattedDate(subscription.user.membershipExpiration) : ''}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                            {state.enabled_membership_email && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.membershipEmail : ''}
                                </div>
                            )}
                            {/* -------------------- BILL EMAIL -------------------- */}
                            {state.enabled_bill_email && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.billEmail : ''}
                                </div>
                            )}
                            {/* -------------------- ADHERENT TYPE -------------------- */}
                            {state.enabled_adherent_type && (
                                <div className="column-list">
                                    {subscription.user ? subscription.user.adherentType : ''}
                                </div>
                            )}
                            {/* -------------------- LAST NUMBER RECEIVED -------------------- */}
                            {state.enabled_last_number_received && (
                                <div className="column-list">
                                    {subscription.lastNumberReceived}
                                </div>
                            )}
                            {/* -------------------- LAST NUMBER TO RECEIVED -------------------- */}
                            {state.enabled_last_number_to_receive && (
                                <div className="column-list">
                                    {subscription.lastNumberToReceive}
                                </div>
                            )}
                            {/* -------------------- IS ALTERRENAT -------------------- */}
                            {state.enabled_is_alterrenat && (
                                <div className="column-list">
                                    {subscription.is_alterrenat ? 'Oui' : 'Non'}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
            {/* -------------------- SUBSCRIPTION UPDATE -------------------- */}
            {state.openUpdate && (
                <UpdateSubscription
                    subscription={state.subscription}
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                />
            )}
            {/* -------------------- IS SENT -------------------- */}
            {state.openIsSent && (
                <ManageIsSentStatus
                    onClose={() => setState({ ...state, openIsSent: false })}
                    refetch={() => setState({ ...state, openIsSent: false, loading: true })}
                    subscription={state.subscription}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_is_sent: state.enabled_is_sent,
            enabled_subscription_id: state.enabled_subscription_id,
            enabled_subscription_status: state.enabled_subscription_status,
            enabled_client_code: state.enabled_client_code,
            enabled_external_client_code: state.enabled_external_client_code,
            enabled_subscription_type: state.enabled_subscription_type,
            enabled_membership_status: state.enabled_membership_status,
            enabled_last_name: state.enabled_last_name,
            enabled_first_name: state.enabled_first_name,
            enabled_company_name: state.enabled_company_name,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_email: state.enabled_email,
            enabled_french_department: state.enabled_french_department,
            enabled_city: state.enabled_city,
            enabled_postal_code: state.enabled_postal_code,
            enabled_address: state.enabled_address,
            enabled_address_details: state.enabled_address_details,
            enabled_country: state.enabled_country,
            enabled_phone: state.enabled_phone,
            enabled_other_phone: state.enabled_other_phone,
            enabled_subscription_email: state.enabled_subscription_email,
            enabled_subscription_expiration: state.enabled_subscription_expiration,
            enabled_month_year_subscription_expiration: state.enabled_month_year_subscription_expiration,
            enabled_user_category: state.enabled_user_category,
            enabled_birth_date: state.enabled_birth_date,
            enabled_federated_association: state.enabled_federated_association,
            enabled_membership_expiration: state.enabled_membership_expiration,
            enabled_membership_email: state.enabled_membership_email,
            enabled_bill_email: state.enabled_bill_email,
            enabled_adherent_type: state.enabled_adherent_type,
            enabled_last_number_received: state.enabled_last_number_received,
            enabled_last_number_to_receive: state.enabled_last_number_to_receive,
            enabled_is_alterrenat: state.enabled_is_alterrenat,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'mailingList',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                <div style={{ display: 'flex' }}>
                    <div>
                        {/* -------------------- IS SENT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_is_sent}
                                onChange={e => setState({ ...state, enabled_is_sent: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Revue envoyée
                        </Grid>
                        {/* -------------------- SUBSCRIPTION STATUS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_subscription_status}
                                onChange={e => setState({ ...state, enabled_subscription_status: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Statut abonnement
                        </Grid>
                        {/* -------------------- SUBSCRIPTION ID -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_subscription_id}
                                onChange={e => setState({ ...state, enabled_subscription_id: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            N°abo
                        </Grid>
                        {/* -------------------- CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_client_code}
                                onChange={e => setState({ ...state, enabled_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client
                        </Grid>
                        {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_external_client_code}
                                onChange={e => setState({ ...state, enabled_external_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client externe
                        </Grid>
                        {/* -------------------- SUBSCRIPTION TYPE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_subscription_type}
                                onChange={e => setState({ ...state, enabled_subscription_type: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Type d'abonnement
                        </Grid>
                        {/* -------------------- MEMBERSHIP STATUS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_membership_status}
                                onChange={e => setState({ ...state, enabled_membership_status: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Statut adhésion
                        </Grid>
                        {/* -------------------- LAST NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_last_name}
                                onChange={e => setState({ ...state, enabled_last_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom
                        </Grid>
                        {/* -------------------- FIRST NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_first_name}
                                onChange={e => setState({ ...state, enabled_first_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Prénom
                        </Grid>
                        {/* -------------------- COMPANY NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_company_name}
                                onChange={e => setState({ ...state, enabled_company_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom de l'entreprise
                        </Grid>
                        {/* -------------------- MOBILE PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_mobile_phone}
                                onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone portable
                        </Grid>
                        {/* -------------------- EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_email}
                                onChange={e => setState({ ...state, enabled_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email
                        </Grid>
                        {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_french_department}
                                onChange={e => setState({ ...state, enabled_french_department: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Département
                        </Grid>
                        {/* -------------------- CITY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_city}
                                onChange={e => setState({ ...state, enabled_city: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Ville
                        </Grid>
                        {/* -------------------- POSTAL CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_postal_code}
                                onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code postal
                        </Grid>
                        {/* -------------------- ADDRESS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address}
                                onChange={e => setState({ ...state, enabled_address: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse (n° et voie)
                        </Grid>
                        {/* -------------------- ADDRESS DETAILS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address_details}
                                onChange={e => setState({ ...state, enabled_address_details: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse (complément)
                        </Grid>
                    </div>
                    <div>
                        {/* -------------------- COUNTRY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_country}
                                onChange={e => setState({ ...state, enabled_country: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Pays
                        </Grid>
                        {/* -------------------- PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_phone}
                                onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone fixe
                        </Grid>
                        {/* -------------------- OTHER PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_other_phone}
                                onChange={e => setState({ ...state, enabled_other_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Autre téléphone
                        </Grid>
                        {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_subscription_email}
                                onChange={e => setState({ ...state, enabled_subscription_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email abonnement
                        </Grid>
                        {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_subscription_expiration}
                                onChange={e => setState({ ...state, enabled_subscription_expiration: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Date d'expiration abo.
                        </Grid>
                        {/* -------------------- MONTH YEAR SUBSCRIPTION EXPIRATION -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_month_year_subscription_expiration}
                                onChange={e => setState({ ...state, enabled_month_year_subscription_expiration: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Mois / Année d'expiration abo.
                        </Grid>
                        {/* -------------------- USER CATEGORY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_user_category}
                                onChange={e => setState({ ...state, enabled_user_category: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Rôle
                        </Grid>
                        {/* -------------------- BIRTH DATE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_birth_date}
                                onChange={e => setState({ ...state, enabled_birth_date: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Date de naissance
                        </Grid>
                        {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_federated_association}
                                onChange={e => setState({ ...state, enabled_federated_association: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Association fédérée
                        </Grid>
                        {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_membership_expiration}
                                onChange={e => setState({ ...state, enabled_membership_expiration: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Date d'expiration adhé.
                        </Grid>
                        {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_membership_email}
                                onChange={e => setState({ ...state, enabled_membership_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email adhésion
                        </Grid>
                        {/* -------------------- BILL EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_bill_email}
                                onChange={e => setState({ ...state, enabled_bill_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email bon de commande
                        </Grid>
                        {/* -------------------- ADHERENT TYPE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_adherent_type}
                                onChange={e => setState({ ...state, enabled_adherent_type: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Type d'adhérent
                        </Grid>
                        {/* -------------------- LAST NUMBER RECEIVED -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_last_number_received}
                                onChange={e => setState({ ...state, enabled_last_number_received: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Dernier n° reçu
                        </Grid>
                        {/* -------------------- LAST NUMBER TO RECEIVED -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_last_number_to_receive}
                                onChange={e => setState({ ...state, enabled_last_number_to_receive: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Abonné jusqu'au n°
                        </Grid>
                        {/* -------------------- IS ALTERRENAT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_is_alterrenat}
                                onChange={e => setState({ ...state, enabled_is_alterrenat: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Alterrenat
                        </Grid>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- SUBSCRIPTION LINKS ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SubscriptionLinks = ({ state, setState }) => {
    const history = useHistory();
    const match = useRouteMatch();

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20, display: 'flex' }}>
                <div
                    onClick={() => setState({ ...state, openIsSent: true, openLinks: false })}
                    className="subscription-links"
                >
                    Modifier le statut de l'envoi
                </div>
                <div
                    onClick={() => setState({ ...state, openUpdate: true, openLinks: false })}
                    className="subscription-links"
                    style={{ marginLeft: 24 }}
                >
                    Modifier l'abonnement
                </div>
                <div
                    onClick={() => history.push(match.url + '/' + state.billId)}
                    className="subscription-links"
                    style={{ marginLeft: 24 }}
                >
                    Accéder au bon de commande
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={() => setState({ ...state, openLinks: false })} variant="contained">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------------- REMOVE ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const RemoveSubscriptionsFromMailingList = ({ onClose, refetch, mailingListId, subscriptionIds }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    function onRemove() {
        if (subscriptionIds == null || subscriptionIds.length === 0) {
            enqueueSnackbar('Veuillez sélectionner au moins un utilisateur à retirer', { variant: 'warning' });
        }
        else {
            setLoading(true);
        }
    }

    useEffect(() => {
        if (loading) {
            removeSubscriptionsFromMailingList(mailingListId, {
                subscription_ids: subscriptionIds,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        Êtes-vous sûr de vouloir retirer ces utilisateurs de la liste d'envoi ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={onRemove} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- MANAGE IS SENT STATUS ---------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageIsSentStatus = ({ onClose, refetch, subscription }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mailingListId } = useParams();

    const [state, setState] = useState({
        loading: false,
        is_sent: subscription.is_sent ? subscription.is_sent.toString() : '0',
    });

    useEffect(() => {
        if (state.loading) {
            updateSubscriptionInMailingList(mailingListId, subscription.id, {
                is_sent: state.is_sent
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Modifier le statut de l'envoi
                </p>
                <ToggleButtonGroup
                    value={state.is_sent}
                    onChange={(e, value) => setState({ ...state, is_sent: (value == null ? state.is_sent : value) })}
                    exclusive size="small"
                    className="toggle-button"
                >
                    <ToggleButton value="0" style={{ width: 140 }}>
                        Non envoyé
                    </ToggleButton>
                    <ToggleButton value="1" style={{ width: 140 }}>
                        Envoyé
                    </ToggleButton>
                </ToggleButtonGroup>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Annuler
                </Button>
                <Button onClick={() => setState({ ...state, loading: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SubscriptionsForMailingListRouter;
