import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getUserFormationRequests } from 'services/requests';
import { userCanCreateFormation, userCanHandleParticipations } from 'services/permissions';
import { getFormattedDate, getLabelStatus, getColorStatus, cancelParticipationIsAvailable, getAmountTTC } from 'services/helpers';
import { formationRequestStatuses } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import CancelParticipation from 'views/dasboard/formation-requests/formation-requests-cancel';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- FORMATION REQUESTS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const FormationRequests = ({ user }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formationRequests: [],
        total_count: 0,

        // Cancel Participation
        formationRequest: undefined,
        openCancelParticipation: false,
    });

    useEffect(() => {
        if (state.loading) {
            getUserFormationRequests(user.id).then(res => {
                if (res.status === 200) {
                    const formationRequests = res.data.data.formationRequests;
                    setState({
                        ...state,
                        loading: false,
                        formationRequests,
                        total_count: formationRequests.length,
                    });
                }
                else {
                    console.log(res);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div
            className="light-background-color"
            style={{ padding: '0px 24px 24px 24px' }}
        >
            <div style={{ padding: 12, backgroundColor: 'white' }}>
                {/* -------------------- TOOLBAR -------------------- */}
                <Grid container justify="space-between" alignItems="center">
                    <b>DEMANDES DE FORMATION</b>
                    <b>
                        {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                    </b>
                </Grid>
                {/* -------------------- SUBSCRIPTION TYPES -------------------- */}
                {userCanCreateFormation() && (
                    <Grid
                        container alignItems="center" justify="flex-end"
                        style={{ padding: '12px 0px' }}
                    >
                        <Button
                            onClick={() => history.push(match.url + '/formations')}
                            variant="contained"
                            style={{ textTransform: 'none' }}
                        >
                            Inscrire à une formation
                        </Button>
                    </Grid>
                )}
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid
                        container alignItems="center"
                        className="subtitle-primary-color"
                        style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
                    >
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={1} style={{ padding: 10 }}>
                            N°formation
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Nom formation
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Période
                        </Grid>
                        <Grid item xs={1} style={{ padding: 10 }}>
                            Montant
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Responsable
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Départements
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- BILL LIST -------------------- */}
                <FormationRequestList
                    state={state}
                    setState={setState}
                />
            </div>
        </div>
    );
};

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------- FORMATION REQUEST LIST -------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const FormationRequestList = ({ state, setState }) => {
    const history = useHistory();
    const match = useRouteMatch();

    function openModalCancelParticipation(event, formationRequest) {
        event.stopPropagation();
        setState({ ...state, openCancelParticipation: true, formationRequest });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.formationRequests.length > 0 ? (
                state.formationRequests.map(formationRequest => (
                    <Fragment key={formationRequest.id}>
                        <Grid
                            onClick={() => history.push(match.url + '/formation/' + formationRequest.formationSession.id)}
                            container alignItems="center"
                            className="simple-list"
                        >
                            <Grid
                                item xs={12} md={2}
                                style={{ padding: 10 }}
                            >
                                <span style={{ color: getColorStatus(formationRequestStatuses, formationRequest.status) }}>
                                    {getLabelStatus(formationRequestStatuses, formationRequest.status)}
                                </span>
                                {(userCanHandleParticipations() && cancelParticipationIsAvailable(formationRequest)) && (
                                    <>
                                        <br />
                                        <Button
                                            onClick={e => openModalCancelParticipation(e, formationRequest)}
                                            variant="contained"
                                            style={{ marginTop: 10, textTransform: 'none', padding: '4px 8px' }}
                                        >
                                            Annuler la participation
                                        </Button>
                                    </>
                                )}
                            </Grid>
                            <Grid
                                item xs={12} md={1}
                                style={{ padding: 10 }}
                            >
                                Formation #{formationRequest.formationSession?.number}
                            </Grid>
                            <Grid
                                item xs={12} md={2}
                                style={{ padding: 10 }}
                            >
                                {formationRequest.formationTitle}
                            </Grid>
                            <Grid
                                item xs={12} md={2}
                                style={{ padding: 10 }}
                            >
                                {formationRequest.formationSession ? (
                                    getFormattedDate(formationRequest.formationSession.begin) + ' - ' + getFormattedDate(formationRequest.formationSession.end)
                                ) : ''}
                            </Grid>
                            <Grid
                                item xs={12} md={1}
                                style={{ padding: 10 }}
                            >
                                {formationRequest.sessionPrice ? getAmountTTC(formationRequest.sessionPrice.price) : ''}
                            </Grid>
                            <Grid
                                item xs={12} md={2}
                                style={{ padding: 10 }}
                            >
                                {formationRequest.formationSession?.manager?.fullName}
                            </Grid>
                            <Grid
                                item xs={12} md={2}
                                style={{ padding: 10 }}
                            >
                                {formationRequest.formationSession?.frenchDepartments
                                    ? formationRequest.formationSession.frenchDepartments.map(fd => fd.name + ` (${fd.number})`).join(', ')
                                    : null
                                }
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <div style={{ padding: 10 }}>
                        Aucune
                    </div>
                    <Divider />
                </>
            )}
            {/* -------------------- CANCEL PARTICIPATION -------------------- */}
            {state.openCancelParticipation && (
                <CancelParticipation
                    onClose={() => setState({ ...state, openCancelParticipation: false })}
                    refetch={() => setState({ ...state, openCancelParticipation: false, loading: true })}
                    formationRequest={state.formationRequest}
                />
            )}
        </>
    );
};

export default FormationRequests;
