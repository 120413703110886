import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Checkbox, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';

// Services
import { getUserCategories, getIndexPreferences, updateIndexPreferences } from 'services/requests';
import { userCanCreateUserCategory, userCanReadUserCategory } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import SelectCreatable from 'components/select-creatable';
import InputText from 'components/input-text';
import Tooltip from 'components/tooltip';

// Views
import CreateUserCategory from './user-categories-create';
import UserCategory from './user-category';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ USER CATEGORIES ROUTER ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UserCategoriesRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <UserCategories />
            </Route>
            <Route exact path={`${match.path}/:userCategoryId`}>
                <UserCategory />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- USER CATEGORIES --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UserCategories = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        categories: [],
        total_count: 0,

        // Modals
        category: undefined,
        openCreate: false,
        openUpdate: false,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_name: true,
        enabled_count_users: true,

        // Filters
        names: [],
        count_users: '',
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            names: [],
            count_users: '',
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'user_categories',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_name: columns.enabled_name,
                            enabled_count_users: columns.enabled_count_users,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            getUserCategories({
                names: state.names ? state.names.map(n => n.value) : null,
                count_users: state.count_users,
            }).then(res => {
                if (res.status === 200) {
                    const userCategories = res.data.data.userCategories;
                    setState({
                        ...state,
                        loading: false,
                        total_count: userCategories.length,
                        categories: userCategories,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: false });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Rôles au sein du MABD
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanCreateUserCategory() && (
                        <Button onClick={() => setState({ ...state, openCreate: true })} variant="contained">
                            Ajouter un rôle
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <UserCategoryList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
            {/* -------------------- CREATE CATEGORY -------------------- */}
            {state.openCreate && (
                <CreateUserCategory
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- NAME -------------------- */}
            {state.enabled_name && (
                <div className="column-list">
                    Intitulé du rôle
                </div>
            )}
            {/* -------------------- COUNT USERS -------------------- */}
            {state.enabled_count_users && (
                <div className="column-list">
                    Nombre d'utilisateurs
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- NAME -------------------- */}
            {state.enabled_name && (
                <div className="column-list">
                    <SelectCreatable
                        placeholder="Intitulé du rôle"
                        value={state.names}
                        onChange={names => onSelectSearch('names', names)}
                    />
                </div>
            )}
            {/* -------------------- COUNT USER -------------------- */}
            {state.enabled_count_users && (
                <div className="column-list">
                    <InputText
                        label="Nombre d'utilisateurs"
                        type="number"
                        value={state.count_users}
                        onChange={e => setState({ ...state, count_users: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- USER CATEGORY LIST ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UserCategoryList = ({ state }) => {
    const history = useHistory();
    const match = useRouteMatch();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.categories.length > 0 && (
                state.categories.map(category => (
                    <Fragment key={category.id}>
                        <div
                            onClick={userCanReadUserCategory() ? () => history.push(match.url + '/' + category.id) : null}
                            className={userCanReadUserCategory() ?  'simple-list' : null}
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- NAME -------------------- */}
                            {state.enabled_name && (
                                <div className="column-list">
                                    {category.name}
                                </div>
                            )}
                            {/* -------------------- COUNT USERS -------------------- */}
                            {state.enabled_count_users && (
                                <div className="column-list">
                                    {category.count_users}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_name: state.enabled_name,
            enabled_count_users: state.enabled_count_users,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'user_categories',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                {/* -------------------- NAME -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_name}
                        onChange={e => setState({ ...state, enabled_name: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Intitulé du rôle
                </Grid>
                {/* -------------------- COUNT USERS -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_count_users}
                        onChange={e => setState({ ...state, enabled_count_users: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Nombre d'utilisateurs
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserCategoriesRouter;
