import React from 'react';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Button, Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningRounded';

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------------- ERROR ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Error = () => {
    const history = useHistory();

    return (
        <div
            className="feedback"
            style={{ flexDirection: 'column' }}
        >
            <Grid style={{ fontSize: 20 }}>
                <WarningIcon style={{ marginRight: 5 }} />
                UNE ERREUR EST SURVENUE
            </Grid>
            <Grid style={{ marginTop: 5, display: 'flex', alignItems: 'center' }}>
                Veuillez actualiser la page. Vous pouvez revenir
                <Button
                    onClick={() => history.push('/login')}
                    color="primary"
                    style={{ marginLeft: 4, padding: 0, textTransform: 'none' }}
                >
                    à l'accueil du site.
                </Button>
            </Grid>
        </div>
    );
};

export default Error;
