import React, { Fragment } from 'react';

// Material-UI
import { Divider, Button, Grid, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

// Services
import { userCanAccessToBillList, userCanUpdateBill } from 'services/permissions';
import { getAmountTTC, getAmountHT, getTVA } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// Views
import AddCustomItem from '../../custom-items/custom-items-add';
import DeleteCustomItem from '../../custom-items/custom-items-delete';
import CreateRefund from '../../../management/refunds/refunds-create';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------- BILL ITEMS ------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const BillItems = ({ state, setState }) => {

    function getTotalAmountHT() {
        let totalPriceHT = 0;
        state.bill.billItems.forEach(item => {
            let dividedAmount = item.amount / 100;
            let dividedTVA = item.tva / 10000;
            let priceHT = dividedAmount / (1 + dividedTVA);
            let roundedPriceHT = Math.round(priceHT * 100) / 100;
            totalPriceHT += roundedPriceHT;
        });
        return (Math.round(totalPriceHT * 100) / 100) + '€';
    }

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container alignItems="center" justify="space-between"
                style={{ marginTop: 50 }}
            >
                <b>Articles</b>
                {userCanAccessToBillList() && (
                    <Button
                        onClick={() => setState({ ...state, openAddCustom: true })}
                        variant="contained"
                        style={{ textTransform: 'none' }}
                    >
                        Ajouter un article
                    </Button>
                )}
            </Grid>
            {/* -------------------- HEADERS -------------------- */}
            <Grid
                container className="list-headers"
                style={{ marginTop: 10 }}
            >
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Nom
                </Grid>
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Montant HT
                </Grid>
                <Grid 
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    TVA
                </Grid>
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Montant TTC
                </Grid>
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Quantité
                </Grid>
            </Grid>
            {/* -------------------- LIST -------------------- */}
            {state.bill.billItems.length > 0 ? (
                state.bill.billItems.map(item => (
                    <Fragment key={item.id}>
                        <Grid container alignItems="center">
                            <Grid
                                item xs={2}
                                style={{ padding: 10 }}
                            >
                                {item.name}
                            </Grid>
                            <Grid
                                item xs={2}
                                style={{ padding: 10 }}
                            >
                                {getAmountHT(item.amount, item.tva)}
                            </Grid>
                            <Grid
                                item xs={2}
                                style={{ padding: 10 }}
                            >
                                {getTVA(item.tva)}
                            </Grid>
                            <Grid
                                item xs={2}
                                style={{ padding: 10 }}
                            >
                                {getAmountTTC(item.amount)}
                            </Grid>
                            <Grid
                                item xs={2}
                                style={{ padding: 10 }}
                            >
                                1
                            </Grid>
                            <Grid
                                item xs={2}
                                style={{ padding: 10, textAlign: 'center' }}
                            >
                                {item.isCustom ? (
                                    <>
                                        {userCanAccessToBillList() && (
                                            <Tooltip title="Supprimer"
                                                item={(
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openDeleteCustom: true, customItem: item })}
                                                        style={{ padding: 6 }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {(
                                            state.bill?.user?.federatedAssociation
                                            && userCanUpdateBill(state.bill.user.federatedAssociation)
                                            && state.bill.status === 'paid'
                                            && item.refund == null
                                        ) && (
                                            <Button
                                                onClick={() => setState({ ...state, openCreateRefund: true, billItem: item })}
                                                color="secondary"
                                                style={{ textTransform: 'none' }}
                                            >
                                                Rembourser cet article
                                            </Button>
                                        )}
                                        {(
                                            state.bill.status === 'paid'
                                            && item.refund != null
                                            && (item.amount > item.refund.amount)
                                        ) && (
                                            <b style={{ color: 'orange' }}>
                                                Partiellement remboursé
                                            </b>
                                        )}
                                        {(
                                            state.bill.status === 'paid'
                                            && item.refund != null
                                            && (item.amount <= item.refund.amount)
                                        ) && (
                                            <b style={{ color: 'green' }}>
                                                Remboursé
                                            </b>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- TOTAL -------------------- */}
            {state.bill.billItems.length > 0 && (
                <>
                    <Grid
                        container
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                    >
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        >
                            Total
                        </Grid>
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        >
                            {getTotalAmountHT()}
                        </Grid>
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        />
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        >
                            {getAmountTTC(state.bill.total_amount)}
                        </Grid>
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        >
                            {state.bill.billItems?.length}
                        </Grid>
                    </Grid>
                    <Divider />
                </>
            )}
            <Grid
                container justify="flex-end"
                style={{ marginTop: 10 }}
            >
                <b>TOTAL À PAYER : {(state.bill.total_amount - state.total_refunded) / 100}€</b>
            </Grid>
            {/* -------------------- CREATE REFUND -------------------- */}
            {state.openCreateRefund && (
                <CreateRefund
                    billItem={state.billItem}
                    onClose={() => setState({ ...state, openCreateRefund: false })}
                    refetch={() => setState({ ...state, openCreateRefund: false, loading: true })}
                />
            )}
            {/* -------------------- ADD CUSTOM ITEM -------------------- */}
            {state.openAddCustom && (
                <AddCustomItem
                    bill_id={state.bill.id}
                    onClose={() => setState({ ...state, openAddCustom: false })}
                    refetch={() => setState({ ...state, openAddCustom: false, loading: true })}
                />
            )}
            {/* -------------------- DELETE CUSTOM ITEM -------------------- */}
            {state.openDeleteCustom && (
                <DeleteCustomItem
                    customItem={state.customItem}
                    onClose={() => setState({ ...state, openDeleteCustom: false })}
                    refetch={() => setState({ ...state, openDeleteCustom: false, loading: true })}
                />
            )}
        </>
    );
};

export default BillItems;
