import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services
import { createDomain } from 'services/requests';

// Components
import InputText from 'components/input-text';
import Spinner from 'components/spinner';
import FieldRequired from 'components/field-required';

// Messages
import messages from 'messages.json';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- CREATE DOMAIN ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const CreateDomain = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: '',
    });

    function onCreate() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createDomain({ name: state.name }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Créer un domaine
                        </p>
                        <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                            <Grid item xs={12} sm={4}>
                                <span>Nom du domaine</span><FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputText
                                    label="Nom du domaine"
                                    type="text"
                                    value={state.name}
                                    onChange={e => setState({ ...state, name: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onCreate} variant="contained">
                            Créer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateDomain;
