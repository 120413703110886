import React, { Fragment } from 'react';

// Material-UI
import { Grid, Divider } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------- MANAGERS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

export default function Managers({ managers }) {
    const { mediaMD } = useMediaQueries();

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                Gestionnaires
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Prénom
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Nom
                        </Grid>
                        <Grid item xs={6} style={{ padding: 10 }}>
                            Email
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- MANAGER LIST -------------------- */}
                <div className="main-container-item">
                    {managers.length > 0 ? (
                        managers.map(manager => (
                            <Fragment key={manager.id}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                        {manager.first_name}
                                    </Grid>
                                    <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                        {manager.last_name}
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{ padding: 10 }}>
                                        {manager.email}
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <Grid container style={{ padding: 10 }}>
                                Aucun
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
