import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getSession } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import FormationDetails from 'views/common/formation-details';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------- FORMATION SESSION -------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FormationSession = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { formationSessionId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formationSession: undefined,
    });

    useEffect(() => {
        if (state.loading) {
            getSession(formationSessionId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, formationSession: res.data.data.formationSession });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Formation #{state.formationSession.number}
                </Grid>
            </Grid>
            {/* -------------------- DETAILS -------------------- */}
            <FormationDetails
                formationSession={state.formationSession}
            />
        </>
    );
};

export default FormationSession;
