import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Button, CircularProgress, Checkbox } from '@material-ui/core';

// Services
import { renewMembership } from 'services/requests';
import { getAmountTTC } from 'services/helpers';

/* * *
* Propriétés d'une adhésion :
* is_pro : définit si l'adhésion est une adhésion professionnelle (1 = professionnel, 0 = particulier)
* is_demeter : définit si l'utilisateur est un adhérent DEMETER pour l'année civile en cours
* is_biodyvin : définit si l'utilisateur appartient à l'organisme Biodyvin
* with_subscription : définit si l'utilisateur souhaite renouveler son abonnement en même temps que son adhésion
*/

// ----------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- RENEW MEMBERSHIP ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------------- \\

const RenewMembership = ({ state, setState }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        var price = 0;
        if (state.hasValidMembership) {
            price += state.hasValidMembership?.membershipType?.price;
        }
        if (state.hasValidMembership.membershipType?.is_pro === 0 && state.with_subscription && state.hasValidSubscription) {
            price += state.hasValidSubscription?.subscriptionType?.price;
        }
        if (state.hasValidMembership.is_demeter === 1) {
            price -= state.hasValidMembership?.membershipType?.demeter_discount;
        }
        setState({ ...state, price });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.hasValidMembership, state.with_subscription]);

    useEffect(() => {
        if (state.loadingRenewMembership) {
            renewMembership(state.hasValidMembership.id, (state.hasValidSubscription && state.with_subscription) ? '1' : '0').then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/payment-method/' + res.data.data.bill.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingRenewMembership: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingRenewMembership]);

    return (
        <div
            className="main-container"
            style={{ padding: '48px 24px', alignItems: 'center', textAlign: 'center' }}
        >
            {/* -------------------- NOT PRO -------------------- */}
            {state.hasValidMembership?.membershipType?.is_pro === 0 && (
                <>
                    {`Vous possédez actuellement une ${state.hasValidMembership.membershipType?.name} ${state.hasValidMembership.status === 'validated' ? 'valide' : 'expirée'} pour ${state.hasValidMembership.end_date?.substring(0, 4)}.
                    Vous pouvez dès à présent renouveler votre adhésion pour ${moment(state.hasValidMembership.end_date?.substring(0, 4), 'YYYY').add(1, 'y').year()}.`}
                    {state.hasValidSubscription != null && (
                        <>
                            <br />
                            {`Vous avez pris un ${state.hasValidSubscription?.subscriptionType?.name} en même temps que votre adhésion.
                            Nous vous proposons de renouveler votre abonnement également.`}
                            <Grid container alignItems="center" justify="center">
                                <Checkbox
                                    checked={!state.with_subscription}
                                    onChange={e => setState({ ...state, with_subscription: !e.target.checked })}
                                    color="primary"
                                />
                                Renouveler uniquement l'adhésion
                            </Grid>
                        </>
                    )}
                </>
            )}
            {/* -------------------- IS PRO -------------------- */}
            {(state.hasValidMembership?.membershipType?.is_pro === 1
                && state.hasValidMembership?.is_biodyvin === 0
            ) && (
                <>
                    {`Vous possédez actuellement une Adhésion professionnelle ${state.hasValidMembership.status === 'validated' ? 'valide' : 'expirée'} pour ${state.hasValidMembership.end_date?.substring(0, 4)}.
                    Vous pouvez dès à présent renouveler votre adhésion pour ${moment(state.hasValidMembership.end_date?.substring(0, 4), 'YYYY').add(1, 'y').year()}.
                    Votre adhésion professionnelle inclut un abonnement gratuit qui sera aussi renouvelé automatiquement lors du renouvellement de votre adhésion.`}
                </>
            )}
            {/* -------------------- IS PRO & BIODYVIN -------------------- */}
            {(state.hasValidMembership?.membershipType?.is_pro === 1
                && state.hasValidMembership?.is_biodyvin === 1
            ) && (
                <>
                    {`Votre adhésion professionnelle au MABD est collectée et gérée par l'organisme Biodyvin. Vous ne pouvez la renouveler par vous-même, Biodyvin nous enverra votre renouvellement.
                    Si vous ne faites plus partie de Biodyvin, vous pouvez prendre une nouvelle adhésion professionnelle au MABD en cliquant sur le bouton "Choisir une nouvelle formule".`}
                </>
            )}
            {/* -------------------- DEMETER -------------------- */}
            {state.hasValidMembership?.is_demeter === 1 && (
                <>
                    {`Votre adhésion bénéficie de la remise Demeter.`}
                </>
            )}
            {state.hasValidMembership?.is_biodyvin === 0 && (
                <>
                    <Button
                        onClick={() => setState({ ...state, loadingRenewMembership: true })}
                        variant="contained" color="primary"
                        disabled={state.loadingRenewMembership}
                        style={{ margin: '12px 0px' }}
                    >
                        Renouveler ({getAmountTTC(state.price)})
                        {state.loadingRenewMembership && (
                            <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
                        )}
                    </Button>
                    ou
                </>
            )}
            <Button
                onClick={() => setState({ ...state, hasValidMembership: null })}
                variant="contained" color="primary"
                style={{ margin: '12px 0px' }}
            >
                Choisir une nouvelle formule
            </Button>
        </div>
    );
};

export default RenewMembership;
