import React from 'react';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// ----------------------------------------------------------------------------------------- \\
// ------------------------------------ VALIDITY YEAR -------------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const ValidityYear = ({ state, setState }) => {
    return (
        <Grid container alignItems="center" style={{ padding: '24px 0px' }}>
            {'Année de validité :'}
            <Button
                onClick={() => setState(prevState => ({ ...prevState, next_year: false }))}
                variant={state.next_year ? 'outlined' : 'contained'}
                color="primary"
                style={{ marginLeft: 24, fontSize: 18, padding: '8px 48px' }}
            >
                {moment().year()}
            </Button>
            <Button
                onClick={() => setState(prevState => ({ ...prevState, next_year: true }))}
                variant={state.next_year ? 'contained' : 'outlined'}
                color="primary"
                style={{ marginLeft: 24, fontSize: 18, padding: '8px 48px' }}
            >
                {moment().add(1, 'y').year()}
            </Button>
        </Grid>
    );
};

export default ValidityYear;
