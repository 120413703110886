import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useParams } from 'react-router-dom';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Dialog, DialogContent, DialogActions, Divider, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

// Services
import { addUserToDomain, removeUserFromDomain, searchUsers } from 'services/requests';

// Assets
import Autocomplete from 'components/autocomplete';
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';
import Error from 'components/error';
import useMediaQueries from 'services/media';

// Messages
import messages from 'messages.json';

// ----------------------------------- USERS ---------------------------------- \\
const Users = ({ domain }) => {
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        error: false,
        users: domain.users || [],
        contact: null,
        openAdd: false,
        openRemove: false,
    });

    function onAddUser(user) {
        let users = state.users;
        users.push(user);
        setState({ ...state, openAdd: false, users });
    }

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <Grid item xs={6}>
                    Membres
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                    <Button onClick={() => setState({ ...state, openAdd: true })} variant="contained">
                        Ajouter un membre
                    </Button>
                    <Button color="primary" variant="contained" style={{ marginLeft: 5 }}>
                        Importer des membres
                    </Button>
                </Grid>
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Prénom
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Nom
                        </Grid>
                        <Grid item xs={4} style={{ padding: 10 }}>
                            Email
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10, textAlign: 'center' }}>
                            Action
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- USER LIST -------------------- */}
                <UserList
                    state={state}
                    setState={setState}
                />
            </div>
            {/* -------------------- ADD USER -------------------- */}
            {state.openAdd && (
                <AddUser
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={user => onAddUser(user)}
                />
            )}
        </>
    );
};

// ----------------------------------- USER LIST ---------------------------------- \\
const UserList = ({ state, setState }) => {

    function onRemove(user_id) {
        let users = state.users;
        let index = users.findIndex(c => c.id === user_id);
        users.splice(index, 1);
        setState({ ...state, openRemove: false, users });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <div className="main-container-item">
            {state.users.length > 0 ? (
                state.users.map(user => (
                    <Fragment key={user.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                {user.first_name}
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                {user.last_name}
                            </Grid>
                            <Grid item xs={12} md={4} style={{ padding: 10 }}>
                                {user.email}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                <Tooltip title="Supprimer"
                                    item={(
                                        <IconButton
                                            onClick={() => setState({ ...state, openRemove: true, user })}
                                            style={{ padding: 6 }}       
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid container style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- REMOVE USER -------------------- */}
            {state.openRemove && (
                <RemoveUser
                    onClose={() => setState({ ...state, openRemove: false })}
                    refetch={user_id => onRemove(user_id)}
                    user={state.user}
                />
            )}
        </div>
    );
};

// ----------------------------------- ADD USER ----------------------------------- \\
const AddUser = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { domainId } = useParams();

    const [state, setState] = useState({
        loading: false,      
        options: [],
        user: null,
        searchRef: undefined,
        loadingAdd: false,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    function onAdd() {
        if (state.user == null) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loadingAdd: true });
        }
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 3) {
                searchUsers({ search: state.searchRef }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.data.users.map(u => ({ label: u.fullName + ' (' + u.clientCode + ')', value: u.id })),
                        });
                    }
                    else {
                        setState({ ...state, loading: false });
                    }
                });
            } 
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingAdd) {
            addUserToDomain(domainId, { user_id: state.user.value }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.user);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingAdd]);

    return (
        <Dialog open fullWidth>
            {state.loadingAdd ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un membre
                        </p>
                        <Autocomplete
                            value={state.user}
                            options={state.options}
                            loading={state.loading}
                            onChange={(e, value) => setState({ ...state, user: value })}
                            onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
                            placeholder="Chercher un utilisateur..."
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ---------------------------------- REMOVE USER  ---------------------------------- \\
const RemoveUser = ({ onClose, refetch, user }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { domainId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            removeUserFromDomain(domainId, user.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(user.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {user.first_name} {user.last_name}
                        </p>
                        Êtes-vous sûr de vouloir retirer ce membre ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default Users;
