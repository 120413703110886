import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button, Grid, MenuItem } from '@material-ui/core';

// Services
import { addCustomItem } from 'services/requests';
import { amountToSend, isValidAmount } from 'services/helpers';
import { customItemTypes } from 'services/constants';

// Components
import Spinner from 'components/spinner';
import InputText from 'components/input-text';
import SelectOutlined from 'components/select-outlined';
import FieldRequired from 'components/field-required';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ADD CUSTOM ITEM ------------------------------------------ \\
// ----------------------------------------------------------------------------------------------------- \\

const AddCustomItem = ({ onClose, refetch, bill_id }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: '',
        amount: '',
        tva: '0',
        type: 'discount',
    });

    function onValidate() {
        if (state.name === '' || state.tva === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!isValidAmount(state.amount)) {
            enqueueSnackbar('Veuillez saisir un montant valide', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {

            let amount = amountToSend(state.amount);
            if (state.type === 'discount') {
                amount =- amount;
            }

            addCustomItem(bill_id, {
                name: state.name,
                amount: amount,
                tva: amountToSend(state.tva),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un article personnalisé
                        </p>
                        {/* -------------------- NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                                Nom<FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputText
                                    label="Nom"
                                    value={state.name}
                                    onChange={e => setState({ ...state, name: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* -------------------- TYPE -------------------- */}
                        <Grid container alignItems="center" style={{ marginTop: 5 }}>
                            <Grid item xs={12} sm={4}>
                                Type d'article<FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <SelectOutlined
                                    value={state.type}
                                    onChange={e => setState({ ...state, type: e.target.value, tva: '0' })}
                                >
                                    {customItemTypes.map(type => (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.label}
                                        </MenuItem>
                                    ))}
                                </SelectOutlined>
                            </Grid>
                        </Grid>
                        {/* -------------------- AMOUNT -------------------- */}
                        <Grid container alignItems="center" style={{ marginTop: 5 }}>
                            <Grid item xs={12} sm={4}>
                                Montant (€)<FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputText
                                    label="Montant (€)"
                                    type="number"
                                    value={state.amount}
                                    onChange={e => setState({ ...state, amount: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* -------------------- AMOUNT -------------------- */}
                        <Grid container alignItems="center" style={{ marginTop: 5 }}>
                            <Grid item xs={12} sm={4}>
                                TVA (%)<FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputText
                                    label="TVA (%)"
                                    type="number"
                                    value={state.tva}
                                    onChange={e => setState({ ...state, tva: e.target.value })}
                                    disabled={state.type === 'discount'}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onValidate} variant="contained">
                            Valider
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default AddCustomItem;
