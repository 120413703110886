import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getFrenchDepartments, setFrenchDepartmentsToFederatedAssociation } from 'services/requests';

// Components
import SelectMultiple from 'components/select-multiple';
import Spinner from 'components/spinner';
import Error from 'components/error';

// -------------------------------------------------------------------------------------- \\
// ---------------------------------- FRENCH DEPARTMENTS -------------------------------- \\
// -------------------------------------------------------------------------------------- \\

export default function SetFrenchDepartments({ federated_association }) {
    const { enqueueSnackbar } = useSnackbar();
    const { federatedAssociationId } = useParams();

    const [state, setState] = useState({
        loading: true,
        loadingSave: false,
        error: false,
        french_departments_options: [],
        french_departments: federated_association.frenchDepartments.map(fd => ({
            label: fd.name + ' (' + fd.number + ')', value: fd.id
        })),
    });

    useEffect(() => {
        if (state.loading) {
            getFrenchDepartments().then(res => {
                if (res.status === 200) {
                    const french_departments = res.data.data.frenchDepartments;
                    setState({
                        ...state,
                        loading: false,
                        french_departments_options: french_departments.map(fd => ({
                            label: fd.name + ' (' + fd.number + ')', value: fd.id
                        })),
                    });
                }
                else {
                    console.log(res);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingSave) {
            const french_departments_ids = state.french_departments.map(fd => fd.value);
            setFrenchDepartmentsToFederatedAssociation(federatedAssociationId, {
                french_department_ids: french_departments_ids,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingSave: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingSave: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingSave]);

    if (state.loading || state.loadingSave) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <Grid item xs={12} md={10} lg={8}>
                {/* -------------------- FRENCH DEPARTMENTS -------------------- */}
                <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                    <Grid item xs={12} sm={4}>
                        <span>Assigner des départements à l'association</span>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <SelectMultiple 
                            value={state.french_departments}
                            options={state.french_departments_options}
                            onChange={french_departments => setState({ ...state, french_departments })}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* -------------------- SAVE BUTTON -------------------- */}
            <Grid item xs={12} md={2} lg={4} container alignItems="center" justify="flex-end">
                <Button onClick={() => setState({ ...state, loadingSave: true })} variant="contained">
                    Enregistrer
                </Button>
            </Grid>
        </>
    );
}
