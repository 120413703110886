import React from 'react';

// Services
import { sessionPriceStatuses } from 'services/constants';
import { getLabelStatus } from 'services/helpers';
import { getAmountTTC } from 'services/helpers';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- INFORMATIONS TABLE ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const InformationsTable = ({ formationRequests, sessionPrices, sessionOptions }) => {

    const getTotalSessionPrice = sessionPrice => {
        var price = 0;
        formationRequests.forEach(formationRequest => {
            if (formationRequest.sessionPrice?.id === sessionPrice.id) {
                price += sessionPrice.price;
            }
        });
        return getAmountTTC(price);
    };

    const getTotalSessionOption = sessionOption => {
        var price = 0;
        formationRequests.forEach(formationRequest => {
            if (formationRequest.sessionOptions?.findIndex(o => o.id === sessionOption.id) !== -1) {
                price += sessionOption.price;
            }
        });
        return getAmountTTC(price);
    };

    return (
        <table>
            {/* -------------------- HEADERS -------------------- */}
            <thead>
                <tr>
                    <td />
                    <td
                        colSpan={sessionPrices.length}
                        className="simple-td"
                    >
                        <b>TARIFS</b>
                    </td>
                    {sessionOptions.length > 0 && (
                        <td
                            colSpan={sessionOptions.length}
                            className="simple-td"
                        >
                            <b>OPTIONS</b>
                        </td>
                    )}
                </tr>
            </thead>
            {/* -------------------- SESSION PRICES / OPTIONS -------------------- */}
            <tbody>
                <tr>
                    <td />
                    {sessionPrices.map(sessionPrice => (
                        <td key={sessionPrice.id} className="day-programs-td">
                            {getLabelStatus(sessionPriceStatuses, sessionPrice.status)}
                        </td>
                    ))}
                    {sessionOptions.map(sessionOption => (
                        <td key={sessionOption.id} className="day-programs-td">
                            {sessionOption.name}
                        </td>
                    ))}
                </tr>
            </tbody>
            {/* -------------------- DESCRIPTIONS OF SESSION PRICES / OPTIONS -------------------- */}
            <tbody>
                <tr>
                    <td className="users-td">
                        <b>UTILISATEURS</b>
                    </td>
                    {sessionPrices.map(sessionPrice => (
                        <td key={sessionPrice.id} className="day-programs-td">
                            {sessionPrice.description}
                        </td>
                    ))}
                    {sessionOptions.map(sessionOption => (
                        <td key={sessionOption.id} className="day-programs-td">
                            {sessionOption.description}
                        </td>
                    ))}
                </tr>
            </tbody>
            {/* -------------------- USERS -------------------- */}
            <tbody>
                {formationRequests.map(formationRequest => (
                    <tr key={formationRequest.id}>
                        <td className="users-td">
                            {formationRequest.user?.fullName}
                        </td>
                        {sessionPrices.map(sessionPrice => (
                            <td key={sessionPrice.id} className="day-programs-td">
                                {sessionPrice.id === formationRequest.sessionPrice?.id ? getAmountTTC(sessionPrice.price) : ''}
                            </td>
                        ))}
                        {sessionOptions.map(sessionOption => (
                            <td key={sessionOption.id} className="day-programs-td">
                                {formationRequest.sessionOptions?.findIndex(o => o.id === sessionOption.id) !== -1 ? getAmountTTC(sessionOption.price) : ''}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            {/* -------------------- TOTAL -------------------- */}
            <tbody>
                <tr>
                    <td className="users-td">
                        <b>TOTAL QUANTITÉ</b>
                    </td>
                    {sessionPrices.map(sessionPrice => (
                        <td key={sessionPrice.id} className="day-programs-td">
                            {formationRequests.filter(fr => fr.sessionPrice?.id === sessionPrice.id)?.length}
                        </td>
                    ))}
                    {sessionOptions.map(sessionOption => (
                        <td key={sessionOption.id} className="day-programs-td">
                            {formationRequests.filter(fr => fr.sessionOptions.findIndex(o => o.id === sessionOption.id) !== -1)?.length}
                        </td>
                    ))}
                </tr>
                <tr>
                    <td className="users-td">
                        <b>TOTAL EURO (€)</b>
                    </td>
                    {sessionPrices.map(sessionPrice => (
                        <td key={sessionPrice.id} className="day-programs-td">
                            {getTotalSessionPrice(sessionPrice)}
                        </td>
                    ))}
                    {sessionOptions.map(sessionOption => (
                        <td key={sessionOption.id} className="day-programs-td">
                            {getTotalSessionOption(sessionOption)}
                        </td>
                    ))}
                </tr>
            </tbody>
        </table>
    );
};

export default InformationsTable;
