import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getTransfers } from 'services/requests';
import { getAmountTTC, getFormattedCreatedAt } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- TRANSFERS EXPORT -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const TransfersExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getAllTranfersToExport(transfers, offset) {
        getTransfers({
            offset: offset,
            limit: data.limit,
            federated_association_id: data.federated_association_id,
            amount_to_transfer: data.amount_to_transfer,
            money_is_transferred: data.money_is_transferred,
        }).then(res => {
            if (res.status === 200) {

                let res_transfers = res.data.data.transfers;
                transfers = transfers.concat(res_transfers);

                setState({
                    ...state,
                    percentage: Math.round((100 * transfers.length) / data.total_count) + '%',
                });

                if (res_transfers.length < data.limit) {
                    createExcelFile(transfers);
                }
                else {
                    getAllTranfersToExport(transfers, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(transfers) {
        if (transfers.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Transferts');

            // Header
            worksheet.columns = [
                { header: 'Association fédérée', key: 'federated_association', width: 30 },
                { header: 'Montant à transférer', key: 'amount_to_transfer', width: 30 },
                { header: 'Statut', key: 'money_is_transferred', width: 30 },
                { header: 'Date de création', key: 'created_at', width: 30 },
                { header: 'Dernière mise à jour', key: 'updated_at', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            transfers.map(transfer => {
                return worksheet.addRow({
                    federated_association: transfer.federatedAssociation ? transfer.federatedAssociation.name : '',
                    amount_to_transfer: getAmountTTC(transfer.amount_to_transfer),
                    money_is_transferred: transfer.money_is_transferred === 0 ? 'Non transféré' : 'Transféré',
                    created_at: getFormattedCreatedAt(transfer.created_at),
                    updated_at: getFormattedCreatedAt(transfer.updated_at),
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'transferts.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllTranfersToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default TransfersExport;
