import React, { useState, useEffect } from 'react';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';

// File-Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';
import { SaveAlt as SaveIcon } from '@material-ui/icons';

// Services
import { getSession } from 'services/requests';
import { userCanUpdateFormationSession, userCanHandleParticipations } from 'services/permissions';
import { fileUrl } from 'services/axios-config';
import useMediaQueries from 'services/media';
import { getFormattedDate, getColorStatus, getLabelStatus, getFileExtension } from 'services/helpers';
import { formationSessionStatuses } from 'services/constants';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import PageNotFound from 'components/page-not-found';

// Views
import FormationSessionUpdate from './formation-session-update';
import FormationRequests from './formation-requests';
import FormationParticipations from './formation-participations';
import OnlinePayments from './online-payments';
import FormationDocuments from './formation-documents';
import RegisteredFormationRequests from './registered-formation-requests';
import SessionDays from './session-days';
import SessionPrices from './session-prices';
import SessionOptions from './session-options';
import SessionDay from './session-days/session-day';

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------- FORMATION SESSION ROUTER ---------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSessionRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <FormationSession />
            </Route>
            {userCanUpdateFormationSession() && (
                <Route exact path={`${match.path}/update`}>
                    <FormationSessionUpdate />
                </Route>
            )}
            <Route exact path={`${match.path}/requests`}>
                <FormationRequests />
            </Route>
            <Route exact path={`${match.path}/participations`}>
                <FormationParticipations />
            </Route>
            <Route exact path={`${match.path}/online-payments`}>
                <OnlinePayments />
            </Route>
            <Route exact path={`${match.path}/documents`}>
                <FormationDocuments />
            </Route>
            <Route exact path={`${match.path}/registered-requests`}>
                <RegisteredFormationRequests />
            </Route>
            <Route path={`${match.path}/day/:sessionDayId`}>
                <SessionDay />
            </Route>
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- FORMATION SESSION ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSession = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();
    const sessionId = match.params.sessionId;

    const [state, setState] = useState({
        loading: true,
        error: false,
        session: undefined,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getSession(sessionId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, session: res.data.data.formationSession });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Formation #{state.session.number}
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanUpdateFormationSession() && (
                        <Button
                            onClick={() => history.push(match.url + '/update')}
                            variant="contained"
                        >
                            Modifier
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- FORMATION SESSION INFOS -------------------- */}
            <Grid style={{ padding: 24 }}>
                <FormationSessionInfos
                    session={state.session}
                />
            </Grid>
            <Divider />
            {/* -------------------- FORMATION REQUESTS -------------------- */}
            {userCanHandleParticipations() && (
                <>
                    <Grid container alignItems="center" style={{ padding: 24 }}>
                        <FormationSessionRequests
                            session={state.session}
                        />
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- SESSION DAYS -------------------- */}
            <SessionDays
                refetch={() => setState({ ...state, loading: true })}
                session={state.session}
            />
            <Divider />
            {/* -------------------- SESSION PRICES -------------------- */}
            <SessionPrices
                refetch={() => setState({ ...state, loading: true })}
                session={state.session}
            />
            <Divider />
            {/* -------------------- SESSION OPTIONS -------------------- */}
            <SessionOptions
                refetch={() => setState({ ...state, loading: true })}
                session={state.session}
            />
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ FORMATION SESSION INFOS ---------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSessionInfos = ({ session }) => {
    const history = useHistory();
    const match = useRouteMatch();

    return (
        <Grid container alignItems="center">
            <Grid item xs={12} md={6}>
                {/* -------------------- REFERENCE VIVEA -------------------- */}
                {session.vivea_reference && (
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <b>Référence VIVEA</b>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b>{session.vivea_reference}</b>
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- STATUS -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        Statut
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <b style={{ color: getColorStatus(formationSessionStatuses, session.status) }}>
                            {getLabelStatus(formationSessionStatuses, session.status)}
                        </b>
                    </Grid>
                </Grid>
                {/* -------------------- NAME -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        Nom de la formation
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {session.name}
                    </Grid>
                </Grid>
                {/* -------------------- END OF REGISTRATION -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        Fin d'inscription
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {getFormattedDate(session.end_of_registration)}
                    </Grid>
                </Grid>
                {/* -------------------- CAPACITY -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        Nombre maximum de participants
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {session.capacity}
                    </Grid>
                </Grid>
                {/* -------------------- DEPARTMENTS -------------------- */}
                {(session.frenchDepartments && session.frenchDepartments.length > 0) && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Département(s)
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {session.frenchDepartments.map((fd, index) => {
                                if (index === 0) return fd.name + ' (' + fd.number + ')';
                                else return ', ' + fd.name +  ' (' + fd.number + ')';
                            })}
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- MANAGER -------------------- */}
                {session.manager && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Responsable de la formation
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {session.manager.fullName}
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- ANIMATOR -------------------- */}
                {session.animator && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Animateur de la formation
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {session.animator.fullName}
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- TRAINERS -------------------- */}
                {(session.trainers && session.trainers.length > 0) && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Formateur(s)
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {session.trainers.map((t, index) => {
                                if (index === 0) return t.fullName;
                                else return ', ' + t.fullName;
                            })}
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- DOCUMENT -------------------- */}
                {session.document_url && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Programme détaillé et infos pratiques
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Button
                                onClick={() => saveAs(fileUrl + session.document_url, 'Programme formation #' + session.number + getFileExtension(session.document_url))}
                                color="secondary"
                                style={{ textTransform: 'none' }}
                            >
                                Télécharger
                                <SaveIcon style={{ marginLeft: 5 }} />
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- DOCUMENTS -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        Documents associés ({session.countDocuments})
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Button
                            onClick={() => history.push(match.url + '/documents')}
                            color="secondary"
                            style={{ textTransform: 'none' }}
                        >
                            Accéder à la liste
                        </Button>
                    </Grid>
                </Grid>
                {/* -------------------- TIMESHEET -------------------- */}
                {session.timesheet_url && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Feuille d'émargement
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Button
                                onClick={() => saveAs(fileUrl + session.timesheet_url, 'Feuille emargement formation #' + session.number + getFileExtension(session.timesheet_url))}
                                color="secondary"
                                style={{ textTransform: 'none' }}
                            >
                                Télécharger
                                <SaveIcon style={{ marginLeft: 5 }} />
                            </Button>
                        </Grid>
                    </Grid>
                )}

                {/* -------------------- PEDAGOGICAL SUPPORT -------------------- */}
                {/* -------------------- PREVIOUSLY : TIMESHEET VIVEA -------------------- */}
                {session.timesheet_vivea_url && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Support pédagogique
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Button
                                onClick={() => saveAs(fileUrl + session.timesheet_vivea_url, 'Support pédagogique formation #' + session.number + getFileExtension(session.timesheet_vivea_url))}
                                color="secondary"
                                style={{ textTransform: 'none' }}
                            >
                                Télécharger
                                <SaveIcon style={{ marginLeft: 5 }} />
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {/* -------------------- PHOTO -------------------- */}
            <Grid item xs={12} md={6} container justify="flex-end" alignItems="center">
                {session.photo_url && (
                    <img
                        alt="session"
                        src={fileUrl + session.photo_url}
                        style={{ maxHeight: 220 }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------- FORMATION SESSION REQUESTS -------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSessionRequests = ({ session }) => {
    const history = useHistory();
    const match = useRouteMatch();

    return (
        <>
            <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                Demandes d'inscription
                <br />
                <b style={{ margin: '10px 0px', fontSize: 20 }}>
                    {session.countRequests}
                </b>
                <br />
                <Button
                    onClick={() => history.push(match.url + '/requests')}
                    disabled={session.countRequests === 0}
                    variant="contained" color="primary"
                >
                    Accéder à la liste
                </Button>
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                Utilisateurs confirmés
                <br />
                <b style={{ margin: '10px 0px', fontSize: 20 }}>
                    {session.countRegistered}
                </b>
                <br />
                <Button
                    onClick={() => history.push(match.url + '/registered-requests')}
                    disabled={session.countRegistered === 0}
                    variant="contained" color="primary"
                >
                    Voir le tableau des participants
                </Button>
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                Valider la présence des participants
                <br />
                <Button
                    onClick={() => history.push(match.url + '/participations')}
                    disabled={session.countRegistered === 0}
                    variant="contained" color="primary"
                    style={{ marginTop: 30 }}
                >
                    Voir le tableau des présences
                </Button>
            </Grid>
        </>
    );
};

export default FormationSessionRouter;
