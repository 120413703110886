import React, { useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, MenuItem, Checkbox } from '@material-ui/core';

// Services
import { changePaymentMethod } from 'services/requests';
import { paymentMethods } from 'services/constants';

// Components
import SelectOutlined from 'components/select-outlined';

// ------------------------------------------------------------------------------------------------ \\
// ------------------------------------- CHANGE PAYMENT METHOD ------------------------------------ \\
// ------------------------------------------------------------------------------------------------ \\

const ChangePaymentMethod = ({ state, setState }) => {
    const { enqueueSnackbar } = useSnackbar();

    const filteredPaymentMethods = paymentMethods.filter(pm =>
        pm.value !== 'cash'
        && pm.value !== 'ticket'
        && pm.value !== state.bill.payment_method
    );

    function onValidate() {
        if (state.payment_method !== 'cb' && !state.confirm_terms) {
            enqueueSnackbar('Veuillez valider les conditions', { variant: 'warning' });
        }
        else {
            setState({ ...state, loadingChange: true });
        }
    }

    useEffect(() => {
        if (state.loadingChange) {
            changePaymentMethod(state.bill.id, { payment_method: state.payment_method }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingChange: false, loading: true, payment_method: -1 });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingChange: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingChange]);

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Grid
                container
                style={{ marginTop: 24 }}
            >
                <Grid container justify="center">
                    CHOISIR UN AUTRE MODE DE PAIEMENT
                </Grid>
                <Grid
                    container
                    style={{ marginTop: 6 }}
                >
                    <SelectOutlined
                        value={state.payment_method}
                        onChange={e => setState({ ...state, payment_method: e.target.value })}
                    >
                        <MenuItem value={-1}>
                            <em>Mode de paiement</em>
                        </MenuItem>
                        {filteredPaymentMethods.map(paymentMethod => (
                            <MenuItem key={paymentMethod.value} value={paymentMethod.value}>
                                {paymentMethod.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
                {state.payment_method !== -1 && (
                    <>
                        {(
                            state.payment_method === 'check'
                            || state.payment_method === 'transfer'
                        ) && (
                            <Grid
                                container alignItems="center"
                                style={{ marginTop: 10 }}
                            >
                                <Checkbox
                                    value={state.confirm_terms}
                                    onChange={e => setState({ ...state, confirm_terms: e.target.checked })}
                                    color="secondary"
                                />
                                <div style={{ flex: 1 }}>
                                    {state.payment_method === 'check' && (
                                        <b>
                                            En choisissant de payer par chèque, je m'engage à noter la référence
                                            [<span className="secondary-color">{state.bill.reference}</span>]
                                            au dos du chèque sans quoi le paiement est susceptible d'être refusé.
                                        </b>
                                    )}
                                    {state.payment_method === 'transfer' && (
                                        <b>
                                            En choisissant de payer par virement, je m'engage à noter la référence
                                            [<span className="secondary-color">{state.bill.reference}</span>]
                                            lors du virement sans quoi le paiement est susceptible d'être refusé.
                                        </b>
                                    )}
                                </div>
                            </Grid>
                        )}
                        <Grid
                            container justify="center"
                            style={{ marginTop: 10 }}
                        >
                            <Button
                                onClick={onValidate}
                                variant="contained"
                            >
                                Valider
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};

export default ChangePaymentMethod;
