import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider, IconButton } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, Delete as DeleteIcon, Edit as UpdateIcon } from '@material-ui/icons';

// Services
import { getTransfers } from 'services/requests';
import { userCanCreateTransfer, userCanUpdateTransfer, userCanDeleteTransfer } from 'services/permissions';
import useMediaQueries from 'services/media';
import { getAmountTTC, getFormattedCreatedAt } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';
import Error from 'components/error';
import InputText from 'components/input-text';
import Pagination from 'components/pagination';
import PageNotFound from 'components/page-not-found';

// Views
import Transfer from './transfer';
import CreateTransfer from './transfers-create';
import UpdateTransfer from './transfers-update';
import DeleteTransfer from './transfers-delete';
import TransfersExport from './transfers-export';

// Selects
import SelectFederatedAssociation from 'selects/select-federated-association';
import SelectYesOrNo from 'selects/select-yes-or-no';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- TRANSFERS ROUTER --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const TransfersRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Transfers />
            </Route>
            {userCanCreateTransfer() && (
                <Route exact path={`${match.path}/create`}>
                    <CreateTransfer />
                </Route>
            )}
            <Route path={`${match.path}/:transferId`}>
                <Transfer />
            </Route>
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------ TRANSFERS ------------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const Transfers = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        transfers: [],
        offset: 0,
        limit: 30,
        total_count: 0,

        // Update
        openUpdate: false,
        transfer: null,

        // Delete
        openDelete: false,

        // Filters
        federated_association_id: -1,
        amount_to_transfer: '',
        money_is_transferred: -1,
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            transfers: [],
            federated_association_id: -1,
            amount_to_transfer: '',
            money_is_transferred: -1,
        });
    }

    useEffect(() => {
        if (state.loading) {
            getTransfers({
                offset: state.offset,
                limit: state.limit,
                federated_association_id: state.federated_association_id,
                amount_to_transfer: state.amount_to_transfer,
                money_is_transferred: state.money_is_transferred,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        transfers: res.data.data.transfers,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Transferts
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanCreateTransfer() && (
                        <Button onClick={() => history.push('/management/transfers/create')} variant="contained">
                            Ajouter un transfert
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={8} container alignItems="center">
                    <Tooltip title="Chercher"
                        item={(
                            <Button
                                onClick={onSearch}
                                variant="contained"
                            >
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button
                                onClick={cancelSearch}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <TransfersExport data={state} />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container alignItems="center" justify={mediaMD ? 'flex-end' : 'flex-start'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <b>
                        {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                    </b>
                </Grid>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Association fédérée
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Montant à transférer
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Date de création
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Dernière modification
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10, textAlign: 'center' }}>
                            Action
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <SelectFederatedAssociation
                                federatedAssociationId={state.federated_association_id}
                                setFederatedAssociationId={federated_association_id => onSelectSearch('federated_association_id', federated_association_id)}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <InputText
                                label="Montant (€)"
                                value={state.amount_to_transfer}
                                onChange={e => setState({ ...state, amount_to_transfer: e.target.value })}
                                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <SelectYesOrNo
                                label="Statut"
                                labelFalse="Non transféré"
                                labelTrue="Transféré"
                                value={state.money_is_transferred}
                                onChange={money_is_transferred => onSelectSearch('money_is_transferred', money_is_transferred)}
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- LISt -------------------- */}
                <TransferList
                    state={state}
                    setState={setState}
                />
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- TRANSFER LIST --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const TransferList = ({ state, setState }) => {
    const history = useHistory();
    const match = useRouteMatch();

    function onUpdate(event, transfer) {
        event.stopPropagation();
        setState({ ...state, openUpdate: true, transfer });
    }

    function onDelete(event, transfer) {
        event.stopPropagation();
        setState({ ...state, openDelete: true, transfer });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <div className="main-container-item">
                {state.transfers.map(transfer => (
                    <Fragment key={transfer.id}>
                        <Grid
                            onClick={() => history.push(match.url + '/' + transfer.id)}
                            className="simple-list"
                            container alignItems="center"
                        >
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {transfer.federatedAssociation ? transfer.federatedAssociation.name : ''}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {getAmountTTC(transfer.amount_to_transfer)}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {transfer.money_is_transferred === 0 ? (
                                    <b className="title-status-list" style={{ color: 'red' }}>Non transféré</b>
                                ) : (
                                    <b className="title-status-list" style={{ color: 'green' }}>Transféré</b>
                                )}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {getFormattedCreatedAt(transfer.created_at)}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {getFormattedCreatedAt(transfer.updated_at)}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                {userCanUpdateTransfer() && (
                                    <Tooltip title="Modifier"
                                        item={(
                                            <IconButton
                                                onClick={e => onUpdate(e, transfer)}
                                                style={{ padding: 6 }}
                                            >
                                                <UpdateIcon />
                                            </IconButton>
                                        )}
                                    />
                                )}
                                {userCanDeleteTransfer(transfer.money_is_transferred) && (
                                    <Tooltip title="Supprimer"
                                        item={(
                                            <IconButton
                                                onClick={e => onDelete(e, transfer)}
                                                style={{ padding: 6 }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))}
            </div>
            {/* -------------------- UPDATE -------------------- */}
            {state.openUpdate && (
                <UpdateTransfer
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    transfer={state.transfer}
                />
            )}
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <DeleteTransfer
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => setState({ ...state, openDelete: false, loading: true })}
                    transfer={state.transfer}
                />
            )}
        </>
    );
};

export default TransfersRouter;
