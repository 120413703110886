import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { userCanDeleteFederatedAssociation } from 'services/permissions';
import { getFederatedAssociation } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import SetFederatedAssociation from './set-federated-association';
import SetFrenchDepartments from './set-french-departments';
import SetContacts from './set-contacts';
import DeleteFederatedAssociation from '../delete-federated-association';

// -------------------------------------------------------------------------------------- \\
// ---------------------------- UPDATE FEDERATED ASSOCIATION ---------------------------- \\
// -------------------------------------------------------------------------------------- \\

const UpdateFederatedAssociation = () => {
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const match = useRouteMatch();
    const federated_association_id = match.params.federatedAssociationId

    const [state, setState] = useState({
        loading: true,
        error: false,
        federated_association: null,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getFederatedAssociation(federated_association_id).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, federated_association: res.data.data.federatedAssociation });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button
                        onClick={() => history.push(`/federated-associations/${federated_association_id}`)}
                        variant="contained"
                    >
                        Retour
                    </Button>
                    
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    <b>Modifier l'association fédérée</b>
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanDeleteFederatedAssociation(federated_association_id) && (
                        <Button
                            onClick={() => setState({ ...state, openDelete: true })}
                            variant="contained"
                        >
                            Supprimer
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- FEDERATED ASSOCIATION FIELDS -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <SetFederatedAssociation federated_association={state.federated_association} />
            </Grid>
            <Divider />
            {/* -------------------- DEPARTMENTS -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <SetFrenchDepartments federated_association={state.federated_association} />
            </Grid>
            <Divider />
            {/* -------------------- CONTACT -------------------- */}
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <SetContacts federated_association={state.federated_association} />
            </div>
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <DeleteFederatedAssociation
                    federated_association={state.federated_association}
                    onClose={() => setState({ ...state, openDelete: false })}
                />
            )}
        </>
    );
};

export default UpdateFederatedAssociation;
