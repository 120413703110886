import React, { useState, useEffect } from 'react';

// Services
import { magazineStatuses } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------- SELECT MAGAZINE STATUSES ---------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectMagazineStatuses = ({ statuses, setStatuses }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(magazineStatuses.map(s => ({ label: s.label, value: s.value })));
    }, []);

    return (
        <SelectMultiple
            value={statuses}
            onChange={value => setStatuses(value)}
            options={options}
            placeholder="Statut revue"
        />
    );
};

export default SelectMagazineStatuses;
