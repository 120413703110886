import React from 'react';

// Material-UI
import CircularProgress from '@material-ui/core/CircularProgress';

/* * *
* Icône utilisée lors des phases de chargement
*/

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SPINNER ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const Spinner = () => {
    return (
        <div className="feedback">
            <CircularProgress color="inherit" />
        </div>
    );
};

export default Spinner;
