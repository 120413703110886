import React from 'react';
import { useHistory } from 'react-router-dom';

// MUI
import { Grid, Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// ------------------------------------------------------------------------------------------ \\
// -------------------------------------- FULLNAME USED ------------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const FullNameUsed = ({ fullName, hiddenEmails, onClose, onRegister }) => {
    let history = useHistory();

    return (
        <Dialog
            open
            fullWidth
        >
            <DialogContent
                style={{ marginBottom: 20 }}
            >
                <div style={{ fontSize: 16 }}>
                    Un ou plusieurs comptes existent déjà pour :<br />
                    <b>{fullName}</b>
                </div>
                {/* -------------------- HIDDEN EMAIL LIST -------------------- */}
                <div style={{ marginTop: 12 }}>  
                    {hiddenEmails.map((email, index) => (
                        <div
                            key={index}
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', padding: 6, marginTop: index > 0 ? 2 : undefined }}
                        >
                            {email}
                        </div>
                    ))}
                </div>
                {/* -------------------- RESET PASSWORD -------------------- */}
                <div style={{ marginTop: 32 }}>
                    Si une de ces adresses mails est la votre, nous vous invitons à récupérer le compte associé au lieu de créer un doublon.<br />
                    Je souhaite récupérer le compte :
                </div>
                <Grid
                    container justify="center"
                    style={{ marginTop: 12 }}
                >
                    <Button
                        onClick={() => history.push('/forgotten-password')}
                        variant="contained" color="primary"
                    >
                        Réinitialiser le mot de passe
                    </Button>
                </Grid>
                {/* -------------------- RESET PASSWORD -------------------- */}
                <div style={{ marginTop: 32 }}>
                    Je ne connais pas ces adresses mails :
                </div>
                <Grid
                    container justify="center"
                    style={{ marginTop: 12 }}
                >
                    <Button
                        onClick={onRegister}
                        variant="contained" color="primary"
                    >
                        Continuer à créer un nouveau compte
                    </Button>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant="contained" color="inherit"
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FullNameUsed;
