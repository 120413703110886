import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';
import { getUserMemberships } from 'services/requests';
import { subscriptionStatuses } from 'services/constants';
import { getFormattedDate, getColorStatus, getLabelStatus } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import UpdateMembership from 'views/management/memberships/update-membership';

/* * *
* Historique des adhésions
*/

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------- MEMBERSHIPS HISTORY ------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const MembershipsHistory = () => {
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const USER_ID = storedUser ? storedUser.id : null;
    const history = useHistory();

    const [state, setState] = useState({
        loading: true,
        error: false,
        memberships: [],
        membership: null,
        openUpdate: false,
    });

    useEffect(() => {
        if (state.loading) {
            getUserMemberships(USER_ID).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, memberships: res.data.data.memberships });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/dashboard')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Historique des adhésions
                </Grid>
            </Grid>
            <Grid style={{ padding: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Période
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Type d'adhésion
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Adresse de facturation
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- LIST -------------------- */}
                {state.memberships.length > 0 ? (
                    state.memberships.map(membership => (
                        <Fragment key={membership.id}>
                           <Grid
                                onClick={() => setState({ ...state, openUpdate: true, membership })}
                                container alignItems="center"
                                className="simple-list"
                            >
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    <b style={{ color: getColorStatus(subscriptionStatuses, membership.status) }}>
                                        {getLabelStatus(subscriptionStatuses, membership.status)}
                                    </b>
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {getFormattedDate(membership.start_date)} - {getFormattedDate(membership.end_date)}
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {membership.membershipType ? membership.membershipType.name : ''}
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {membership.address} {membership.postal_code} {membership.city}, {membership.country.name}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid container style={{ padding: 10 }}>
                            Aucun
                        </Grid>
                        <Divider />
                    </>
                )}
            </Grid>
            {/* -------------------- UPDATE -------------------- */}
            {state.openUpdate && (
                <UpdateMembership
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    membership={state.membership}
                />
            )}
        </>
    )
};

export default MembershipsHistory;
