import React, { useState, useEffect } from 'react';
import Select from 'components/react-select';
import { getUserCategories } from 'services/requests';

// Column Width
let columnWidth = 160;

// -------------------- HEADER -------------------- \\
const UserCategoryHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Rôle
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const UserCategoryFilter = ({ state, onSelectSearch }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getUserCategories({}).then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.userCategories.map(c => ({ label: c.name, value: c.id })));
            }
        });
    }, []);

    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <Select
                placeholder="Rôle"
                value={state.user_categories}
                onChange={user_categories => onSelectSearch('user_categories', user_categories)}
                options={options}
                isMulti
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const UserCategoryValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { UserCategoryHeader, UserCategoryFilter, UserCategoryValue };
