import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 160;

// -------------------- HEADER -------------------- \\
const OtherPhoneHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Autre téléphone
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const OtherPhoneFilter = ({ state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label="Autre téléphone"
                value={state.other_phone}
                onChange={e => setState({ ...state, other_phone: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const OtherPhoneValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { OtherPhoneHeader, OtherPhoneFilter, OtherPhoneValue };
