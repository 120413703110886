import React, { useState, useEffect } from 'react';

// Services
import { getFrenchDepartments } from 'services/requests';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------- SELECT FRENCH DEPARTMENTS --------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectFrenchDepartments = ({ frenchDepartments, setFrenchDepartments }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getFrenchDepartments().then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.frenchDepartments.map(d => ({ label: (d.name + ' (' + d.number + ')'), value: d.id })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <SelectMultiple
            value={frenchDepartments}
            onChange={value => setFrenchDepartments(value)}
            options={options}
            placeholder="Département"
        />
    );
};

export default SelectFrenchDepartments;
