import { Get, Post, Delete } from './axios-config';
import { amountToSend } from 'services/helpers';

/* * *
* Toutes les requêtes de l'application sont répertoriées dans ce fichier.
* La requête varie selon le type de méthode utilisée (get, post ou delete) appelée
* depuis le fichier de configuration axios.
*
* Cas spécial : les requêtes avec l'envoi de fichier possèdent l'attribut "file" dans la méthode POST
*/

// ------------------------------------------------------------------------------------ \\
// --------------------------------------- LOGIN -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const login = data => {
    let url = 'login';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------------- LOGOUT ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const logout = () => {
    let url = 'logout';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- SIGN IN ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const register = data => {
    let url = 'register';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------ CHECK EXISTING EMAIL -------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const checkExistingEmail = email => {
    let url = 'checkExistingEmail?email=' + email;
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------- CHECK EXISTING FULLNAME ------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const checkExistingFullName = (firstName, lastName) => {
    let url = 'checkExistingFullName?first_name=' + firstName + '&last_name=' + lastName;
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- CONFIRM EMAIL ---------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const sendConfirmEmail = email => {
    let url = 'confirm?email=' + email;
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- RESET PASSWORD --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const sendResetPasswordToken = email => {
    let url = 'reset?email=' + email;
    return Get(url);
};

export const resetPassword = data => {
    let url = 'reset';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------------- ROLES -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getRoles = () => {
    let url = 'roles';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------- COUNTRIES ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getCountries = () => {
    let url = 'countries';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- FRENCH DEPARTMENTS -------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getFrenchDepartments = () => {
    let url = 'frenchDepartments';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------ FEDERATED ASSOCIATIONS ------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getFederatedAssociations = data => {
    let url = 'federatedAssociations?offset=' + data.offset + '&limit=' + data.limit;

    if (data.name != null && data.name !== '') {
        url += '&name=' + data.name;
    }
    if (data.antenna != null && data.antenna !== '') {
        url += '&antenna=' + data.antenna;
    }

    return Get(url);
};

export const getFederatedAssociation = federated_association_id => {
    let url = 'federatedAssociations/' + federated_association_id;
    return Get(url);
};

export const createFederatedAssociation = data => {
    let url = 'federatedAssociations';
    return Post(url, data, 'file');
};

export const updateFederatedAssociation = (federated_association_id, data) => {
    let url = 'federatedAssociations/' + federated_association_id;
    return Post(url, data, 'file');
};

export const deleteFederatedAssociation = federated_association_id => {
    let url = 'federatedAssociations/' + federated_association_id;
    return Delete(url);
};

export const setFrenchDepartmentsToFederatedAssociation = (federated_association_id, data) => {
    let url = 'federatedAssociations/' + federated_association_id + '/frenchDepartments';
    return Post(url, data);
};

export const addContactToFederationAssociation = (federated_association_id, data) => {
    let url = 'federatedAssociations/' + federated_association_id + '/contacts';
    return Post(url, data);
};

export const updateContactFromFederationAssociation = (federated_association_id, contact_id, data) => {
    let url = 'federatedAssociations/' + federated_association_id + '/contacts/' + contact_id;
    return Post(url, data);
};

export const deleteContactFromFederationAssociation = (federated_association_id, contact_id) => {
    let url = 'federatedAssociations/' + federated_association_id + '/contacts/' + contact_id;
    return Delete(url);
};

export const getFederatedAssociationList = () => {
    let url = 'lists/federatedAssociations';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- DOMAINS ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getDomains = data => {
    let url = 'domains?offset=' + data.offset + '&limit=' + data.limit;

    if (data.name != null && data.name !== '') {
        url += '&name=' + data.name;
    }

    return Get(url);
};

export const getDomain = domain_id => {
    let url = 'domains/' + domain_id;
    return Get(url);
};

export const createDomain = data => {
    let url = 'domains';
    return Post(url, data);
};

export const updateDomain = (domain_id, data) => {
    let url = 'domains/' + domain_id;
    return Post(url, data);
};

export const deleteDomain = domain_id => {
    let url = 'domains/' + domain_id;
    return Delete(url);
};

export const getUserDomains = user_id => {
    let url = 'users/' + user_id + '/domains';
    return Get(url);
};

export const addContactToDomain = (domain_id, data) => {
    let url = 'domains/' + domain_id + '/contacts';
    return Post(url, data);
};

export const updateContactFromDomain = (domain_id, contact_id, data) => {
    let url = 'domains/' + domain_id + '/contacts/' + contact_id;
    return Post(url, data);
};

export const deleteContactFromDomain = (domain_id, contact_id) => {
    let url = 'domains/' + domain_id + '/contacts/' + contact_id;
    return Delete(url);
};

export const addUserToDomain = (domain_id, data) => {
    let url = 'domains/' + domain_id + '/users';
    return Post(url, data);
};

export const removeUserFromDomain = (domain_id, user_id) => {
    let url = 'domains/' + domain_id + '/users/' + user_id;
    return Delete(url);
};

export const addManagerToDomain = (domain_id, data) => {
    let url = 'domains/' + domain_id + '/managers';
    return Post(url, data);
};

export const removeManagerFromDomain = (domain_id, manager_id) => {
    let url = 'domains/' + domain_id + '/managers/' + manager_id;
    return Delete(url);
};

export const getDomainsWithUsers = () => {
    let url = 'domainsList';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------------- USERS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getUsers = data => {
    let url = 'users?offset=' + data.offset + '&limit=' + data.limit;

    if (data.membership_statuses != null && data.membership_statuses.length > 0) {
        data.membership_statuses.forEach(membership_status => {
            url += '&membership_statuses[]=' + membership_status;
        });
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.french_department_ids != null && data.french_department_ids.length > 0) {
        data.french_department_ids.forEach(french_department_id => {
            url += '&french_department_ids[]=' + french_department_id;
        });
    }
    if (data.role_ids != null && data.role_ids.length > 0) {
        data.role_ids.forEach(role_id => {
            url += '&role_ids[]=' + role_id;
        });
    }
    if (data.federated_association_ids != null && data.federated_association_ids.length > 0) {
        data.federated_association_ids.forEach(federated_association_id => {
            url += '&federated_association_ids[]=' + federated_association_id;
        });
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.other_phone != null && data.other_phone !== '') {
        url += '&other_phone=' + data.other_phone;
    }
    if (data.membership_email != null && data.membership_email !== '') {
        url += '&membership_email=' + data.membership_email;
    }
    if (data.country_ids != null && data.country_ids.length > 0) {
        data.country_ids.forEach(country_id => {
            url += '&country_ids[]=' + country_id;
        });
    }
    if (data.user_category_ids != null && data.user_category_ids.length > 0) {
        data.user_category_ids.forEach(category_id => {
            url += '&user_category_ids[]=' + category_id;
        });
    }
    if (data.birth_date != null && data.birth_date !== '') {
        url += '&birth_date=' + data.birth_date;
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.membership_expiration != null && data.membership_expiration !== '') {
        url += '&membership_expiration=' + data.membership_expiration;
    }
    if (data.subscription_email != null && data.subscription_email !== '') {
        url += '&subscription_email=' + data.subscription_email;
    }
    if (data.bill_email != null && data.bill_email !== '') {
        url += '&bill_email=' + data.bill_email;
    }
    if (data.subscription_expiration != null && data.subscription_expiration !== '') {
        url += '&subscription_expiration=' + data.subscription_expiration;
    }

    return Get(url);
};

export const getUser = user_id => {
    let url = 'users/' + user_id;
    return Get(url);
};

export const createUser = data => {
    let url = 'users';
    return Post(url, data);
};

export const updateUser = (user_id, data) => {
    let url = 'users/' + user_id;
    return Post(url, data, 'file');
};

export const updateUserManagement = (user_id, data) => {
    let url = 'users/' + user_id + '/managementUpdate';
    return Post(url, data);
};

export const deleteUser = user_id => {
    let url = 'users/' + user_id;
    return Delete(url);
};

export const mergeUsers = (data) => {
    let url = 'mergeUsers';
    return Post(url, data);
};

export const searchUsers = data => {
    let url = 'search/users?search=' + data.search;

    if (data.user_category_id != null) {
        url += '&user_category_id=' + data.user_category_id;
    }

    return Get(url);
};

export const assignFederatedAssociationToUser = (user_id, data) => {
    let url = 'users/' + user_id + '/federatedAssociation';
    return Post(url, data);
};

export const getTrashUsers = data => {
    let url = 'trash/users?offset=' + data.offset + '&limit=' + data.limit + '&search=' + data.search;
    return Get(url);
};

export const restoreUser = user_id => {
    let url = 'trash/users/'+ user_id;
    return Post(url);
};

export const forceDeleteUser = user_id => {
    let url = 'trash/users/'+ user_id;
    return Delete(url);
};

export const getIndexPreferences = (user_id, data) => {
    let url = 'users/' + user_id + '/indexPreferences';

    if (data.name != null && data.name !== '') {
        url += '?name=' + data.name;
    }

    return Get(url);
};

export const updateIndexPreferences = (user_id, data) => {
    let url = 'users/' + user_id + '/indexPreferences';
    return Post(url, data);
};

export const setExternalClientCode = (user_id, data) => {
    let url = 'users/' + user_id + '/externalClientCode';
    return Post(url, data);
};

export const checkMemberships = (user_id, begin) => {
    let url = 'users/' + user_id + '/checkMemberships?begin=' + begin;
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- GROUPS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getGroups = data => {
    let url = 'userLists?offset=' + data.offset + '&limit=' + data.limit;

    if (data.name != null && data.name !== '') {
        url += '&name=' + data.name;
    }

    return Get(url);
};

export const getGroup = group_id => {
    let url = 'userLists/' + group_id;
    return Get(url);
};

export const createGroup = data => {
    let url = 'userLists';
    return Post(url, data);
};

export const updateGroup = (group_id, data) => {
    let url = 'userLists/' + group_id;
    return Post(url, data);
};

export const deleteGroup = group_id => {
    let url = 'userLists/' + group_id;
    return Delete(url);
};

export const addUserToGroup = (group_id, data) => {
    let url = 'userLists/' + group_id + '/users'
    return Post(url, data);
};

export const removeUserFromGroup = (group_id, user_id) => {
    let url = 'userLists/' + group_id + '/users/' + user_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- NOTES --------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getUserNotes = user_id => {
    let url = 'users/' + user_id + '/notes';
    return Get(url);
};

export const createNote = (user_id, data) => {
    let url = 'users/' + user_id + '/notes';
    return Post(url, data);
};

export const updateNote = (note_id, data) => {
    let url = 'notes/' + note_id;
    return Post(url, data);
};

export const deleteNote = note_id => {
    let url = 'notes/' + note_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- SUBSCRIPTION TYPES -------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getSubscriptionTypes = (withSpecials = false) => {
    let url = 'subscriptionTypes';

    if (withSpecials) {
        url += '?withSpecials=1'
    }

    return Get(url);
};

export const getSubscriptionType = (subscription_type_id) => {
    let url = 'subscriptionTypes/' + subscription_type_id;
    return Get(url);
};

export const updateSubscriptionType = (subscription_type_id, data) => {
    let url = 'subscriptionTypes/' + subscription_type_id;
    return Post(url, data, 'file');
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------- MEMBERSHIP TYPES --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getMembershipTypes = data => {
    let url = 'membershipTypes';

    if (data?.only_available === 1) {
        url += '?only_available=1';
    }

    return Get(url);
};

export const getMembershipType = (membership_type_id) => {
    let url = 'membershipTypes/' + membership_type_id;
    return Get(url);
} ;

export const updateMembershipType = (membership_type_id, data) => {
    let url = 'membershipTypes/' + membership_type_id;
    return Post(url, data, 'file');
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- SUBSCRIPTIONS ---------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getSubscriptions = data => {
    let url = 'subscriptions?offset=' + data.offset + '&limit=' + data.limit;

    if (data.order_by != null && data.order_by !== '') {
        url += '&order_by=' + data.order_by;
    }
    if (data.order != null && data.order !== '') {
        url += '&order=' + data.order;
    }
    if (data.copy_emails) {
        url += '&copy_emails=1';
    }

    if (data.subscription_id != null && data.subscription_id !== '') {
        url += '&id=' + data.subscription_id;
    }
    if (data.subscription_statuses != null && data.subscription_statuses.length > 0) {
        data.subscription_statuses.forEach(subscription_status => {
            url += '&statuses[]=' + subscription_status;
        });
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.subscription_type_ids != null && data.subscription_type_ids.length > 0) {
        data.subscription_type_ids.forEach(subscription_type_id => {
            url += '&subscription_type_ids[]=' + subscription_type_id;
        });
    }
    if (data.membership_statuses != null && data.membership_statuses.length > 0) {
        data.membership_statuses.forEach(membership_status => {
            url += '&membership_statuses[]=' + membership_status;
        });
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.french_department_ids != null && data.french_department_ids.length > 0) {
        data.french_department_ids.forEach(french_department_id => {
            url += '&french_department_ids[]=' + french_department_id;
        });
    }
    if (data.cities != null && data.cities.length > 0) {
        data.cities.forEach(city => {
            url += '&cities[]=' + city;
        });
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.country_ids != null && data.country_ids.length > 0) {
        data.country_ids.forEach(country_id => {
            url += '&country_ids[]=' + country_id;
        });
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.other_phone != null && data.other_phone !== '') {
        url += '&other_phone=' + data.other_phone;
    }
    if (data.subscription_email != null && data.subscription_email !== '') {
        url += '&subscription_email=' + data.subscription_email;
    }
    if (data.subscription_expiration != null && data.subscription_expiration !== '') {
        url += '&subscription_expiration=' + data.subscription_expiration;
    }
    if (data.month_year_subscription_expirations != null && data.month_year_subscription_expirations.length > 0) {
        data.month_year_subscription_expirations.forEach(expiration => {
            url += '&month_year_subscription_expirations[]=' + expiration;
        });
    }
    if (data.user_category_ids != null && data.user_category_ids.length > 0) {
        data.user_category_ids.forEach(user_category_id => {
            url += '&user_category_ids[]=' + user_category_id;
        });
    }
    if (data.birth_date != null && data.birth_date !== '') {
        url += '&birth_date=' + data.birth_date;
    }
    if (data.federated_association_ids != null && data.federated_association_ids.length > 0) {
        data.federated_association_ids.forEach(federated_association_id => {
            url += '&federated_association_ids[]=' + federated_association_id;
        });
    }
    if (data.membership_expiration != null && data.membership_expiration !== '') {
        url += '&membership_expiration=' + data.membership_expiration;
    }
    if (data.membership_email != null && data.membership_email !== '') {
        url += '&membership_email=' + data.membership_email;
    }
    if (data.bill_email != null && data.bill_email !== '') {
        url += '&bill_email=' + data.bill_email;
    }
    if (data.adherent_type != null && data.adherent_type !== -1) {
        url += '&adherent_type=' + data.adherent_type;
    }
    if (data.last_number_received != null && data.last_number_received !== '') {
        url += '&last_number_received=' + data.last_number_received;
    }
    if (data.last_number_to_receive != null && data.last_number_to_receive !== '') {
        url += '&last_number_to_receive=' + data.last_number_to_receive;
    }
    if (data.created_at != null && data.created_at !== '') {
        url += '&created_at=' + data.created_at;
    }
    if (data.bill_state != null && data.bill_state !== -1) {
        url += '&bill_state=' + data.bill_state;
    }
    if (data.bill_reference != null && data.bill_reference !== '') {
        url += '&bill_reference=' + data.bill_reference;
    }
    if (data.bill_statuses != null && data.bill_statuses.length > 0) {
        data.bill_statuses.forEach(bill_status => {
            url += '&bill_statuses[]=' + bill_status;
        });
    }
    if (data.bill_total_amount != null && data.bill_total_amount !== '') {
        url += '&bill_total_amount=' + amountToSend(data.bill_total_amount);
    }
    if (data.user_list_ids != null && data.user_list_ids.length > 0) {
        data.user_list_ids.forEach(user_list_id => {
            url += '&user_list_ids[]=' + user_list_id;
        });
    }
    if (data.is_alterrenat != null && data.is_alterrenat !== -1) {
        url += '&is_alterrenat=' + data.is_alterrenat;
    }
    if (data.renew_status) {
        url += '&renew_status=' + data.renew_status;
    }
    if (data.origin != null && data.origin !== -1) {
        url += '&origin=' + data.origin;
    }
    if (data.start_date != null && data.start_date !== '') {
        url += '&start_date=' + data.start_date;
    }

    return Get(url);
};

export const getSubscription = subscription_id => {
    let url = 'subscriptions/' + subscription_id;
    return Get(url);
};

export const createSubscription = data => {
    let url = 'subscriptions';
    return Post(url, data);
};

export const updateSubscription = (subscription_id, data) => {
    let url = 'subscriptions/' + subscription_id;
    return Post(url, data);
};

export const getUserSubscriptions = user_id => {
    let url = 'users/' + user_id + '/subscriptions';
    return Get(url);
};

export const fetchRenewableSubscriptionsForUser = user_id => {
    let url = 'users/' + user_id + '/renewableSubscriptions';
    return Get(url);
};

export const renewSubscription = subscription_id => {
    let url = 'subscriptions/' + subscription_id + '/renew';
    return Get(url);
};

export const stopSubscription = (subscription_id, data) => {
    let url = 'subscriptions/' + subscription_id + '/stop';
    return Post(url, data);
};

export const importSpecialSubscriptions = data => {
    let url = 'specialSubscriptions';
    return Post(url, data, 'file');
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ MEMBERSHIPS ----------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getMemberships = data => {
    let url = 'memberships?offset=' + data.offset + '&limit=' + data.limit;

    if (data.order_by != null && data.order_by !== '') {
        url += '&order_by=' + data.order_by;
    }
    if (data.order != null && data.order !== '') {
        url += '&order=' + data.order;
    }
    if (data.membership_statuses != null && data.membership_statuses.length > 0) {
        data.membership_statuses.forEach(membership_status => {
            url += '&statuses[]=' + membership_status;
        });
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.adherent_type != null && data.adherent_type !== -1) {
        url += '&adherent_type=' + data.adherent_type;
    }
    if (data.membership_type_ids != null && data.membership_type_ids.length > 0) {
        data.membership_type_ids.forEach(membership_type_id => {
            url += '&membership_type_ids[]=' + membership_type_id;
        });
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.membership_email != null && data.membership_email !== '') {
        url += '&membership_email=' + data.membership_email;
    }
    if (data.french_department_ids != null && data.french_department_ids.length > 0) {
        data.french_department_ids.forEach(french_department_id => {
            url += '&french_department_ids[]=' + french_department_id;
        });
    }
    if (data.cities != null && data.cities.length > 0) {
        data.cities.forEach(city => {
            url += '&cities[]=' + city;
        });
    }
    if (data.year_membership_expirations != null && data.year_membership_expirations.length > 0) {
        data.year_membership_expirations.forEach(expiration => {
            url += '&year_membership_expirations[]=' + expiration;
        });
    }
    if (data.membership_expiration != null && data.membership_expiration !== '') {
        url += '&membership_expiration=' + data.membership_expiration;
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.country_ids != null && data.country_ids.length > 0) {
        data.country_ids.forEach(country_id => {
            url += '&country_ids[]=' + country_id;
        });
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.other_phone != null && data.other_phone !== '') {
        url += '&other_phone=' + data.other_phone;
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.subscription_expiration != null && data.subscription_expiration !== '') {
        url += '&subscription_expiration=' + data.subscription_expiration;
    }
    if (data.month_year_subscription_expirations != null && data.month_year_subscription_expirations.length > 0) {
        data.month_year_subscription_expirations.forEach(expiration => {
            url += '&month_year_subscription_expirations[]=' + expiration;
        });
    }
    if (data.user_category_ids != null && data.user_category_ids.length > 0) {
        data.user_category_ids.forEach(user_category_id => {
            url += '&user_category_ids[]=' + user_category_id;
        });
    }
    if (data.birth_date != null && data.birth_date !== '') {
        url += '&birth_date=' + data.birth_date;
    }
    if (data.federated_association_ids != null && data.federated_association_ids.length > 0) {
        data.federated_association_ids.forEach(federated_association_id => {
            url += '&federated_association_ids[]=' + federated_association_id;
        });
    }
    if (data.subscription_email != null && data.subscription_email !== '') {
        url += '&subscription_email=' + data.subscription_email;
    }
    if (data.bill_email != null && data.bill_email !== '') {
        url += '&bill_email=' + data.bill_email;
    }
    if (data.is_biodyvin != null && data.is_biodyvin !== -1) {
        url += '&is_biodyvin=' + data.is_biodyvin;
    }
    if (data.is_demeter != null && data.is_demeter !== -1) {
        url += '&is_demeter=' + data.is_demeter;
    }
    if (data.main_activity_ids != null && data.main_activity_ids.length > 0) {
        data.main_activity_ids.forEach(main_activity_id => {
            url += '&main_activity_ids[]=' + main_activity_id;
        });
    }
    if (data.secondary_activity_ids != null && data.secondary_activity_ids.length > 0) {
        data.secondary_activity_ids.forEach(secondary_activity_id => {
            url += '&secondary_activity_ids[]=' + secondary_activity_id;
        });
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.transfer_statuses != null && data.transfer_statuses.length > 0) {
        data.transfer_statuses.forEach(transfer_status => {
            url += '&transfer_statuses[]=' + transfer_status;
        });
    }
    if (data.created_at != null && data.created_at !== '') {
        url += '&created_at=' + data.created_at;
    }
    if (data.bill_state != null && data.bill_state !== -1) {
        url += '&bill_state=' + data.bill_state;
    }
    if (data.bill_reference != null && data.bill_reference !== '') {
        url += '&bill_reference=' + data.bill_reference;
    }
    if (data.bill_statuses != null && data.bill_statuses.length > 0) {
        data.bill_statuses.forEach(bill_status => {
            url += '&bill_statuses[]=' + bill_status;
        });
    }
    if (data.user_list_ids != null && data.user_list_ids.length > 0) {
        data.user_list_ids.forEach(user_list_id => {
            url += '&user_list_ids[]=' + user_list_id;
        });
    }
    if (data.renew_status) {
        url += '&renew_status=' + data.renew_status;
    }
    if (data.copy_emails) {
        url += '&copy_emails=1';
    }

    return Get(url);
};

export const getMembership = membership_id => {
    let url = 'memberships/' + membership_id;
    return Get(url);
};

export const getUserMemberships = user_id => {
    let url = 'users/' + user_id + '/memberships';
    return Get(url);
};

export const updateMembership = (membership_id, data) => {
    let url = 'memberships/' + membership_id;
    return Post(url, data);
};

export const fetchRenewableMembershipsForUser = user_id => {
    let url = 'users/' + user_id + '/renewableMemberships';
    return Get(url);
};

export const renewMembership = (membership_id, with_subscription) => {
    let url = 'memberships/' + membership_id + '/renew';

    if (with_subscription != null) {
        url += '?with_subscription=' + with_subscription;
    }

    return Get(url);
};

export const importBiodyvinMemberships = data => {
    let url = 'biodyvinMemberships';
    return Post(url, data, 'file');
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------------- BILLS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getBills = data => {
    let url = 'bills?offset=' + data.offset + '&limit=' + data.limit;

    if (data.order_by != null && data.order_by !== '') {
        url += '&order_by=' + data.order_by;
    }
    if (data.order != null && data.order !== '') {
        url += '&order=' + data.order;
    }
    if (data.statuses != null && data.statuses.length > 0) {
        data.statuses.forEach(status => {
            url += '&statuses[]=' + status;
        });
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.reference != null && data.reference !== '') {
        url += '&reference=' + data.reference;
    }
    if (data.total_amount != null && data.total_amount !== '') {
        url += '&total_amount=' + data.total_amount;
    }
    if (data.rest != null && data.rest !== '') {
        url += '&left_to_pay=' + data.rest;
    }
    if (data.external_reference != null && data.external_reference !== '') {
        url += '&external_reference=' + data.external_reference;
    }
    if (data.pdf_bill != null && data.pdf_bill !== -1) {
        url += '&has_document=' + data.pdf_bill;
    }
    if (data.pdf_credit != null && data.pdf_credit !== -1) {
        url += '&has_credit_document=' + data.pdf_credit;
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.country_ids != null && data.country_ids.length > 0) {
        data.country_ids.forEach(country_id => {
            url += '&country_ids[]=' + country_id;
        });
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.federated_association_ids != null && data.federated_association_ids.length > 0) {
        data.federated_association_ids.forEach(federated_association_id => {
            url += '&federated_association_ids[]=' + federated_association_id;
        });
    }
    if (data.payment_methods != null && data.payment_methods.length > 0) {
        data.payment_methods.forEach(payment_method => {
            url += '&payment_methods[]=' + payment_method;
        });
    }
    if (data.membership_email != null && data.membership_email !== '') {
        url += '&membership_email=' + data.membership_email;
    }
    if (data.subscription_email != null && data.subscription_email !== '') {
        url += '&subscription_email=' + data.subscription_email;
    }
    if (data.bill_email != null && data.bill_email !== '') {
        url += '&bill_email=' + data.bill_email;
    }
    if (data.created_at != null && data.created_at !== '') {
        url += '&created_at=' + data.created_at;
    }
    if (data.bill_state != null && data.bill_state !== -1) {
        url += '&bill_state=' + data.bill_state;
    }
    if (data.payment_recipient_id != null && data.payment_recipient_id !== -1) {
        url += '&payment_recipient_id=' + data.payment_recipient_id;
    }
    if (data.payment_creator != null && data.payment_creator !== '') {
        url += '&payment_creator=' + data.payment_creator;
    }

    return Get(url);
};

export const getBill = bill_id => {
    let url = 'bills/' + bill_id;
    return Get(url);
};

export const deleteBill = bill_id => {
    let url = 'bills/' + bill_id;
    return Delete(url);
};

export const getUserBills = user_id => {
    let url = 'users/' + user_id + '/bills';
    return Get(url);
};

export const updateExternalBill = (bill_id, data) => {
    let url = 'bills/' + bill_id + '/external';
    return Post(url, data, 'file');
};

export const changePaymentMethod = (bill_id, data) => {
    let url = 'bills/' + bill_id + '/payment_method';
    return Post(url, data);
};

export const updateBillState = (bill_id, data) => {
    let url = 'bills/' + bill_id + '/state';
    return Post(url, data);
};

export const getCountNotProcessed = () => {
    let url = 'countNotProcessed';
    return Get(url);
};

export const getBillIdsFromReferences = references => {
    let url = 'billsFromReferences';

    if (references) {
        references.forEach((reference, index) => {
            url += (index === 0 ? '?' : '&') + 'bill_references[]=' + reference;
        });
    }

    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------- DASHBOARD ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getUserDashboard = user_id => {
    let url = 'users/' + user_id + '/dashboard';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------- PAYMENTS ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getPayments = data => {
    let url = 'payments?offset=' + data.offset + '&limit=' + data.limit;

    if (data.statuses != null && data.statuses.length > 0) {
        data.statuses.forEach(status => {
            url += '&statuses[]=' + status;
        });
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.bill_reference != null && data.bill_reference !== '') {
        url += '&reference=' + data.bill_reference;
    }
    if (data.external_reference != null && data.external_reference !== '') {
        url += '&external_reference=' + data.external_reference;
    }
    if (data.amount != null && data.amount !== '') {
        url += '&amount=' + data.amount;
    }
    if (data.payment_methods != null && data.payment_methods.length > 0) {
        data.payment_methods.forEach(payment_method => {
            url += '&payment_methods[]=' + payment_method;
        });
    }
    if (data.check_reference != null && data.check_reference !== '') {
        url += '&check_number=' + data.check_reference;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.country_ids != null && data.country_ids.length > 0) {
        data.country_ids.forEach(country_id => {
            url += '&country_ids[]=' + country_id;
        });
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.federated_association_ids != null && data.federated_association_ids.length > 0) {
        data.federated_association_ids.forEach(federated_association_id => {
            url += '&federated_association_ids[]=' + federated_association_id;
        });
    }

    return Get(url);
};

export const createPayment = data => {
    let url = 'payments';
    return Post(url, data);
};

export const updatePayment = (payment_id, data) => {
    let url = 'payments/' + payment_id;
    return Post(url, data);
};

export const deletePayment = payment_id => {
    let url = 'payments/' + payment_id;
    return Delete(url);
};

export const onlinePayment = bill_id => {
    let url = 'bills/' + bill_id + '/online_payment';
    return Get(url);
};

export const recoverPayment = (payment_id, data) => {
    let url = 'payments/' + payment_id + '/recover';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- CONSULTING VISITS --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getConsultingVisits = data => {
    let url = 'consultingVisits?offset=' + data.offset + '&limit=' + data.limit;

    if (data.statuses != null && data.statuses.length > 0) {
        data.statuses.forEach(status => {
            url += '&statuses[]=' + status;
        });
    }
    if (data.membership_statuses != null && data.membership_statuses.length > 0) {
        data.membership_statuses.forEach(status => {
            url += '&membership_statuses[]=' + status;
        });
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.membership_type_ids != null && data.membership_type_ids.length > 0) {
        data.membership_type_ids.forEach(membership_type_id => {
            url += '&membership_type_ids[]=' + membership_type_id;
        });
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.membership_email != null && data.membership_email !== '') {
        url += '&membership_email=' + data.membership_email;
    }
    if (data.french_department_ids != null && data.french_department_ids.length > 0) {
        data.french_department_ids.forEach(french_department_id => {
            url += '&french_department_ids[]=' + french_department_id;
        });
    }
    if (data.cities != null && data.cities.length > 0) {
        data.cities.forEach(city => {
            url += '&cities[]=' + city;
        });
    }
    if (data.request_date != null && data.request_date !== '') {
        url += '&created_at=' + data.request_date;
    }
    if (data.years_of_request != null && data.years_of_request.length > 0) {
        data.years_of_request.forEach(year => {
            url += '&years_of_request[]=' + year;
        })
    }
    if (data.years_visit_one != null && data.years_visit_one.length > 0) {
        data.years_visit_one.forEach(year => {
            url += '&years_visit_one[]=' + year;
        })
    }
    if (data.visit_date_one != null && data.visit_date_one !== '') {
        url += '&visit_date_one=' + data.visit_date_one;
    }
    if (data.visit_date_two != null && data.visit_date_two !== '') {
        url += '&visit_date_two=' + data.visit_date_two;
    }
    if (data.report_first_visit != null && data.report_first_visit !== '') {
        url += '&report_first_visit=' + data.report_first_visit;
    }
    if (data.report_second_visit != null && data.report_second_visit !== '') {
        url += '&report_second_visit=' + data.report_second_visit;
    }
    if (data.main_activity_ids != null && data.main_activity_ids.length > 0) {
        data.main_activity_ids.forEach(main_activity_id => {
            url += '&main_activity_ids[]=' + main_activity_id;
        });
    }
    if (data.secondary_activity_ids != null && data.secondary_activity_ids.length > 0) {
        data.secondary_activity_ids.forEach(secondary_activity_id => {
            url += '&secondary_activity_ids[]=' + secondary_activity_id;
        });
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.country_ids != null && data.country_ids.length > 0) {
        data.country_ids.forEach(country_id => {
            url += '&country_ids[]=' + country_id;
        });
    }
    if (data.agricultural_area != null && data.agricultural_area !== '') {
        url += '&agricultural_area=' + data.agricultural_area + '&agricultural_area_comparator=' + data.agricultural_area_comparator;
    }
    if (data.agricultural_methods != null && data.agricultural_methods.length > 0) {
        data.agricultural_methods.forEach(agricultural_method => {
            url += '&agricultural_methods[]=' + agricultural_method;
        });
    }
    if (data.situations != null && data.situations.length > 0) {
        data.situations.forEach(situation => {
            url += '&situations[]=' + situation;
        });
    }
    if (data.needs != null && data.needs.length > 0) {
        data.needs.forEach(need => {
            url += '&needs[]=' + need;
        });
    }
    if (data.equipments != null && data.equipments.length > 0) {
        data.equipments.forEach(equipment => {
            url += '&equipments[]=' + equipment;
        });
    }
    if (data.comments != null && data.comments !== '') {
        url += '&comments=' + data.comments;
    }
    if (data.contact_user_ids != null && data.contact_user_ids.length > 0) {
        data.contact_user_ids.forEach(contact_user_id => {
            url += '&contact_user_ids[]=' + contact_user_id;
        });
    }

    return Get(url);
};

export const getConsultingVisit = consulting_visit_id => {
    let url = 'consultingVisits/' + consulting_visit_id;
    return Get(url);
};

export const createConsultingVisit = data => {
    let url = 'consultingVisits';
    return Post(url, data);
};

export const updateConsultingVisit = (consulting_visit_id, data) => {
    let url = 'consultingVisits/' + consulting_visit_id;
    return Post(url, data, 'file');
};

export const deleteConsultingVisit = consulting_visit_id => {
    let url = 'consultingVisits/' + consulting_visit_id;
    return Delete(url);
};

export const getUserConsultingVisits = user_id => {
  let url = 'users/' + user_id + '/consultingVisits';
  return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ MAGAZINES ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getMagazines = data => {
    let url = 'magazines?offset=' + data.offset + '&limit=' + data.limit;

    if (data.statuses != null && data.statuses.length > 0) {
        data.statuses.forEach(status => {
            url += '&statuses[]=' + status;
        });
    }
    if (data.numbers != null && data.numbers.length > 0) {
        data.numbers.forEach(number => {
            url += '&numbers[]=' + number;
        });
    }
    if (data.publication_date != null && data.publication_date !== '') {
        url += '&publication_date=' + data.publication_date;
    }
    if (data.periods != null && data.periods.length > 0) {
        data.periods.forEach(period => {
            url += '&periods[]=' + period;
        });
    }
    if (data.name != null && data.name !== '') {
        url += '&name=' + data.name;
    }
    if (data.price != null && data.price !== '') {
        url += '&price=' + data.price;
    }
    if (data.summary != null && data.summary !== '') {
        url += '&summary=' + data.summary;
    }
    if (data.publication_years != null && data.publication_years.length > 0) {
        data.publication_years.forEach(publication_year => {
            url += '&publication_years[]=' + publication_year;
        });
    }

    return Get(url);
};

export const getMagazine = magazine_id => {
    let url = 'magazines/' + magazine_id;
    return Get(url);
};

export const createMagazine = data => {
    let url = 'magazines';
    return Post(url, data);
};

export const updateMagazine = (magazine_id, data) => {
    let url = 'magazines/' + magazine_id;
    return Post(url, data);
};

export const deleteMagazine = magazine_id => {
    let url = 'magazines/' + magazine_id;
    return Delete(url);
};

export const generateMailingList = (magazine_id, data) => {
    let url = 'magazines/' + magazine_id + '/mailingList';
    return Post(url, data);
};

export const getSubscriptionsToAddInMailingList = (mailing_list_id, data) => {
    let url = 'mailingLists/' + mailing_list_id + '/subscriptionsToAdd?offset=' + data.offset + '&limit=' + data.limit;

    if (data.subscription_id != null && data.subscription_id !== '') {
        url += '&id=' + data.subscription_id;
    }
    if (data.name != null && data.name !== '') {
        url += '&name=' + data.name;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.subscription_type_ids != null && data.subscription_type_ids.length > 0) {
        data.subscription_type_ids.forEach(subscription_type_id => {
            url += '&subscription_type_ids[]=' + subscription_type_id;
        });
    }
    if (data.statuses != null && data.statuses.length > 0) {
        data.statuses.forEach(status => {
            url += '&statuses[]=' + status;
        });
    }

    return Get(url);
};

export const addSubscriptionsToMailingList = (mailing_list_id, data) => {
    let url = 'mailingLists/' + mailing_list_id + '/subscriptions';
    return Post(url, data);
};

export const removeSubscriptionsFromMailingList = (mailing_list_id, data) => {
    let url = 'mailingLists/' + mailing_list_id + '/subscriptions';

    if (data.subscription_ids != null && data.subscription_ids.length > 0) {
        data.subscription_ids.forEach((subscription_id, index) => {
            if (index === 0) return url += '?subscription_ids[]=' + subscription_id;
            return url += '&subscription_ids[]=' + subscription_id;
        });
    }

    return Delete(url);
};

export const getSubscriptionsForMailingList = (mailing_list_id, data) => {
    let url = 'mailingLists/' + mailing_list_id + '/subscriptions?offset=' + data.offset + '&limit=' + data.limit;

    if (data.subscription_statuses != null && data.subscription_statuses.length > 0) {
        data.subscription_statuses.forEach(subscription_status => {
            url += '&statuses[]=' + subscription_status;
        });
    }
    if (data.subscription_id != null && data.subscription_id !== '') {
        url += '&id=' + data.subscription_id;
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.subscription_type_ids != null && data.subscription_type_ids.length > 0) {
        data.subscription_type_ids.forEach(subscription_type_id => {
            url += '&subscription_type_ids[]=' + subscription_type_id;
        });
    }
    if (data.membership_statuses != null && data.membership_statuses.length > 0) {
        data.membership_statuses.forEach(membership_status => {
            url += '&membership_statuses[]=' + membership_status;
        });
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.french_department_ids != null && data.french_department_ids.length > 0) {
        data.french_department_ids.forEach(french_department_id => {
            url += '&french_department_ids[]=' + french_department_id;
        });
    }
    if (data.cities != null && data.cities.length > 0) {
        data.cities.forEach(city => {
            url += '&cities[]=' + city;
        });
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.country_ids != null && data.country_ids.length > 0) {
        data.country_ids.forEach(country_id => {
            url += '&country_ids[]=' + country_id;
        });
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.other_phone != null && data.other_phone !== '') {
        url += '&other_phone=' + data.other_phone;
    }
    if (data.subscription_email != null && data.subscription_email !== '') {
        url += '&subscription_email=' + data.subscription_email;
    }
    if (data.subscription_expiration != null && data.subscription_expiration !== '') {
        url += '&subscription_expiration=' + data.subscription_expiration;
    }
    if (data.month_year_subscription_expirations != null && data.month_year_subscription_expirations.length > 0) {
        data.month_year_subscription_expirations.forEach(expiration => {
            url += '&month_year_subscription_expirations[]=' + expiration;
        });
    }
    if (data.user_category_ids != null && data.user_category_ids.length > 0) {
        data.user_category_ids.forEach(user_category_id => {
            url += '&user_category_ids[]=' + user_category_id;
        });
    }
    if (data.birth_date != null && data.birth_date !== '') {
        url += '&birth_date=' + data.birth_date;
    }
    if (data.federated_association_ids != null && data.federated_association_ids.length > 0) {
        data.federated_association_ids.forEach(federated_association_id => {
            url += '&federated_association_ids[]=' + federated_association_id;
        });
    }
    if (data.membership_expiration != null && data.membership_expiration !== '') {
        url += '&membership_expiration=' + data.membership_expiration;
    }
    if (data.membership_email != null && data.membership_email !== '') {
        url += '&membership_email=' + data.membership_email;
    }
    if (data.bill_email != null && data.bill_email !== '') {
        url += '&bill_email=' + data.bill_email;
    }
    if (data.adherent_type != null && data.adherent_type !== -1) {
        url += '&adherent_type=' + data.adherent_type;
    }
    if (data.last_number_received != null && data.last_number_received !== '') {
        url += '&last_number_received=' + data.last_number_received;
    }
    if (data.last_number_to_receive != null && data.last_number_to_receive !== '') {
        url += '&last_number_to_receive=' + data.last_number_to_receive;
    }
    if (data.is_alterrenat != null && data.is_alterrenat !== -1) {
        url += '&is_alterrenat=' + data.is_alterrenat;
    }

    return Get(url);
};

export const updateSubscriptionInMailingList = (mailing_list_id, subscription_id, data) => {
    let url = 'mailingLists/' + mailing_list_id + '/subscriptions/' + subscription_id;
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- CATEGORIES ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getCategories = () => {
    let url = 'categories';
    return Get(url);
};

export const getCategory = category_id => {
    let url = 'categories/' + category_id;
    return Get(url);
};

export const createCategory = data => {
    let url = 'categories';
    return Post(url, data, 'file');
};

export const updateCategory = (category_id, data) => {
    let url = 'categories/' + category_id;
    return Post(url, data, 'file');
};

export const deleteCategory = category_id => {
    let url = 'categories/' + category_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- FORMATIONS ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getFormations = data => {
    let url = 'formations?offset=' + data.offset + '&limit=' + data.limit;

    if (data.titles != null && data.titles.length > 0) {
        data.titles.forEach(title => {
            url += '&titles[]=' + title;
        });
    }
    if (data.durations != null && data.durations.length > 0) {
        data.durations.forEach(duration => {
            url += '&durations[]=' + duration;
        });
    }
    if (data.category_ids != null && data.category_ids.length > 0) {
        data.category_ids.forEach(category_id => {
            url += '&category_ids[]=' + category_id;
        });
    }
    if (data.support != null && data.support !== -1) {
        url += '&support=' + data.support;
    }

    return Get(url);
};

export const getFormation = formation_id => {
    let url = 'formations/' + formation_id;
    return Get(url);
};

export const getManagementFormation = (formation_id, data) => {
    let url = 'management/formations/' + formation_id;

    if (data.management != null && data.management) {
        url += '?management=' + data.management;
    }

    return Get(url);
};

export const createFormation = data => {
    let url = 'formations';
    return Post(url, data, 'file');
};

export const updateFormation = (formation_id, data) => {
    let url = 'formations/' + formation_id;
    return Post(url, data, 'file');
};

export const deleteFormation = formation_id => {
    let url = 'formations/' + formation_id;
    return Delete(url);
};

export const createGroupedFormationRequests = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id + '/groupedFormationRequests';
    return Post(url, data);
};

export const getOnlinePaymentsForFormationSession = formation_session_id => {
    let url = 'formationSessions/' + formation_session_id + '/onlinePayments';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------- FORMATION SESSIONS --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getUpcomingSessions = data => {
    let url = 'formationSessions?';

    if (data.status != null && data.status !== '') {
        url += '&status=' + data.status;
    }
    if (data.begin != null) {
        url += '&begin=' + data.begin;
    }
    if (data.end != null) {
        url += '&end=' + data.end;
    }
    if (data.session_date != null) {
        url += '&session_date=' + data.session_date;
    }
    if (data.name != null && data.name !== '') {
        url += '&name=' + data.name;
    }
    if (data.category_id != null && data.category_id !== -1) {
        url += '&category_id=' + data.category_id;
    }
    if (data.french_department_ids != null && data.french_department_ids.length > 0) {
        data.french_department_ids.forEach(id => {
            url += '&french_department_ids[]=' + id;
        });
    }
    if (data.trainer_ids != null && data.trainer_ids.length > 0) {
        data.trainer_ids.forEach(id => {
            url += '&trainer_ids[]=' + id;
        });
    }
    if (data.manager_ids != null && data.manager_ids.length > 0) {
        data.manager_ids.forEach(id => {
            url += '&manager_ids[]=' + id;
        });
    }

    return Get(url);
};

export const getManagementSessions = data => {
    let url = 'management/formationSessions?offset=' + data.offset + '&limit=' + data.limit;

    if (data.order_by != null && data.order_by !== '') {
        url += '&order_by=' + data.order_by;
    }
    if (data.order != null && data.order !== '') {
        url += '&order=' + data.order;
    }
    if (data.period != null && data.period !== 'all') {
        url += '&period=' + data.period;
    }
    if (data.statuses != null && data.statuses.length > 0) {
        data.statuses.forEach(status => {
            url += '&statuses[]=' + status;
        });
    }
    if (data.titles != null && data.titles.length > 0) {
        data.titles.forEach(title => {
            url += '&titles[]=' + title;
        });
    }
    if (data.names != null && data.names.length > 0) {
        data.names.forEach(name => {
            url += '&names[]=' + name;
        });
    }
    if (data.french_department_ids != null && data.french_department_ids.length > 0) {
        data.french_department_ids.forEach(french_department_id => {
            url += '&french_department_ids[]=' + french_department_id;
        });
    }
    if (data.category_ids != null && data.category_ids.length > 0) {
        data.category_ids.forEach(category_id => {
            url += '&category_ids[]=' + category_id;
        });
    }
    if (data.manager_ids != null && data.manager_ids.length > 0) {
        data.manager_ids.forEach(manager_id => {
            url += '&manager_ids[]=' + manager_id;
        });
    }
    if (data.trainer_ids != null && data.trainer_ids.length > 0) {
        data.trainer_ids.forEach(trainer_id => {
            url += '&trainer_ids[]=' + trainer_id;
        });
    }
    if (data.start_date != null && data.start_date !== '') {
        url += '&start_date=' + data.start_date;
    }
    if (data.end_date != null && data.end_date !== '') {
        url += '&end_date=' + data.end_date;
    }
    if (data.place_name != null && data.place_name !== '') {
        url += '&place_name=' + data.place_name;
    }
    if (data.end_of_registration != null && data.end_of_registration !== '') {
        url += '&end_of_registration=' + data.end_of_registration;
    }
    if (data.vivea_reference != null && data.vivea_reference !== '') {
        url += '&vivea_reference=' + data.vivea_reference;
    }
    if (data.nb_registered != null && data.nb_registered !== '') {
        url += '&nb_registered=' + data.nb_registered + '&nb_registered_comparator=' + data.nb_registered_comparator;
    }
    if (data.nb_requested != null && data.nb_requested !== '') {
        url += '&nb_requested=' + data.nb_requested + '&nb_requested_comparator=' + data.nb_requested_comparator;
    }
    if (data.animator_ids != null && data.animator_ids.length > 0) {
        data.animator_ids.forEach(animator_id => {
            url += '&animator_ids[]=' + animator_id;
        });
    }
    if (data.seasons != null && data.seasons.length > 0) {
        data.seasons.forEach(season => {
            url += '&seasons[]=' + season;
        });
    }
    if (data.created_at != null && data.created_at !== '') {
        url += '&created_at=' + data.created_at;
    }

    return Get(url);
};

export const getSession = formation_session_id => {
    let url = 'formationSessions/' + formation_session_id;
    return Get(url);
};

export const addSession = (formation_id, data) => {
    let url = 'formations/' + formation_id + '/formationSessions';
    return Post(url, data, 'file');
};

export const updateSession = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id;
    return Post(url, data, 'file');
};

export const removeSession = formation_session_id => {
    let url = 'formationSessions/' + formation_session_id;
    return Delete(url);
};

export const generateTrainingCertificates = formation_session_id => {
    let url = 'formationSessions/' + formation_session_id + '/trainingCertificates';
    return Post(url, {});
};

export const downloadTrainingCertificate = formation_request_id => {
    let url = 'formationRequests/' + formation_request_id + '/trainingCertificate';
    return Get(url, 'blob');
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- SESSION DAYS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getSessionDay = session_day_id => {
    let url = 'sessionDays/' + session_day_id;
    return Get(url);
};

export const addSessionDay = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id + '/sessionDays';
    return Post(url, data);
};

export const updateSessionDay = (session_day_id, data) => {
    let url = 'sessionDays/' + session_day_id;
    return Post(url, data);
};

export const removeSessionDay = session_day_id => {
    let url = 'sessionDays/' + session_day_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------- SESSION PRICES ----------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const addSessionPrice = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id + '/sessionPrices';
    return Post(url, data);
};

export const updateSessionPrice = (session_price_id, data) => {
    let url = 'sessionPrices/' + session_price_id;
    return Post(url, data);
};

export const removeSessionPrice = session_price_id => {
    let url = 'sessionPrices/' + session_price_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- SESSION OPTIONS ----------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const addSessionOption = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id + '/sessionOptions';
    return Post(url, data);
};

export const updateSessionOption = (session_option_id, data) => {
    let url = 'sessionOptions/' + session_option_id;
    return Post(url, data);
};

export const removeSessionOption = session_option_id => {
    let url = 'sessionOptions/' + session_option_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- DAY PROGRAMS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const addDayProgram = (session_day_id, data) => {
    let url = 'sessionDays/' + session_day_id + '/dayPrograms';
    return Post(url, data);
};

export const updateDayProgram = (day_program_id, data) => {
    let url = 'dayPrograms/' + day_program_id;
    return Post(url, data);
};

export const removeDayProgram = day_program_id => {
    let url = 'dayPrograms/' + day_program_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- FORMATION REQUESTS -------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getUserFormationRequests = user_id => {
    let url = 'users/' + user_id + '/formationRequests';
    return Get(url);
};

export const createFormationRequest = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id + '/formationRequests';
    return Post(url, data);
};

export const getFormationRequestsForSession = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id + '/formationRequests?order=' + data.order;

    if (data.order_by != null && data.order_by !== '') {
        url += '&order_by=' + data.order_by;
    }
    if (data.statuses != null && data.statuses.length > 0) {
        data.statuses.forEach(status => {
            url += '&statuses[]=' + status;
        });
    }
    if (data.created_at != null && data.created_at !== '') {
        url += '&created_at=' + data.created_at;
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.session_price_statuses != null && data.session_price_statuses.length > 0) {
        data.session_price_statuses.forEach(status => {
            url += '&session_price_statuses[]=' + status;
        });
    }
    if (data.bill_payment_methods != null && data.bill_payment_methods.length > 0) {
        data.bill_payment_methods.forEach(method => {
            url += '&bill_payment_methods[]=' + method;
        });
    }
    if (data.bill_date != null && data.bill_date !== '') {
        url += '&bill_date=' + data.bill_date;
    }
    if (data.bill_reference != null && data.bill_reference !== '') {
        url += '&bill_reference=' + data.bill_reference;
    }
    if (data.bill_state != null && data.bill_state !== -1) {
        url += '&bill_state=' + data.bill_state;
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.cities != null && data.cities.length > 0) {
        data.cities.forEach(city => {
            url += '&cities[]=' + city;
        });
    }
    if (data.birth_date != null && data.birth_date !== '') {
        url += '&birth_date=' + data.birth_date;
    }

    return Get(url);
};

export const confirmFormationRequest = formation_request_id => {
    let url = 'formationRequests/' + formation_request_id + '/confirm';
    return Post(url);
};

export const cancelParticipation = (formation_request_id, data) => {
    let url = 'formationRequests/' + formation_request_id + '/cancel';
    return Post(url, data);
};

export const getRegisteredFormationRequests = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id + '/registered?order=' + data.order;

    if (data.order_by != null && data.order_by !== '') {
        url += '&order_by=' + data.order_by;
    }
    if (data.statuses != null && data.statuses.length > 0) {
        data.statuses.forEach(status => {
            url += '&statuses[]=' + status;
        });
    }
    if (data.created_at != null && data.created_at !== '') {
        url += '&created_at=' + data.created_at;
    }
    if (data.confirmation_date != null && data.confirmation_date !== '') {
        url += '&confirmation_date=' + data.confirmation_date;
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.first_name != null && data.first_name !== '') {
        url += '&first_name=' + data.first_name;
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.session_price_statuses != null && data.session_price_statuses.length > 0) {
        data.session_price_statuses.forEach(status => {
            url += '&session_price_statuses[]=' + status;
        });
    }
    if (data.bill_payment_methods != null && data.bill_payment_methods.length > 0) {
        data.bill_payment_methods.forEach(method => {
            url += '&bill_payment_methods[]=' + method;
        });
    }
    if (data.bill_date != null && data.bill_date !== '') {
        url += '&bill_date=' + data.bill_date;
    }
    if (data.bill_reference != null && data.bill_reference !== '') {
        url += '&bill_reference=' + data.bill_reference;
    }
    if (data.bill_state != null && data.bill_state !== -1) {
        url += '&bill_state=' + data.bill_state;
    }
    if (data.external_reference != null && data.external_reference !== '') {
        url += '&bill_external_reference=' + data.external_reference;
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.cities != null && data.cities.length > 0) {
        data.cities.forEach(city => {
            url += '&cities[]=' + city;
        });
    }
    if (data.birth_date != null && data.birth_date !== '') {
        url += '&birth_date=' + data.birth_date;
    }

    return Get(url);
};

export const updateFormationRequestComment = (formation_request_id, data) => {
    let url = 'formationRequests/' + formation_request_id + '/comments';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------- FORMATION PARTICIPATIONS ----------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getFormationParticipationsForSession = formation_session_id => {
    let url = 'formationSessions/' + formation_session_id + '/formationParticipations';
    return Get(url);
};

export const setParticipations = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id + '/formationParticipations';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------- TRAINING PLACES ---------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getTrainingPlaces = data => {
    let url = 'trainingPlaces?offset=' + data.offset + '&limit=' + data.limit;

    if (data.names != null && data.names.length > 0) {
        data.names.forEach(name => {
            url += '&names[]=' + name;
        });
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.country_ids != null && data.country_ids.length > 0) {
        data.country_ids.forEach(country_id => {
            url += '&country_ids[]=' + country_id;
        });
    }
    if (data.cities != null && data.cities.length > 0) {
        data.cities.forEach(city => {
            url += '&cities[]=' + city;
        });
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.contact != null && data.contact !== '') {
        url += '&contact=' + data.contact;
    }
    if (data.french_department_ids != null && data.french_department_ids.length > 0) {
        data.french_department_ids.forEach(french_department_id => {
            url += '&french_department_ids[]=' + french_department_id;
        });
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.other_phone != null && data.other_phone !== '') {
        url += '&other_phone=' + data.other_phone;
    }
    if (data.other_email != null && data.other_email !== '') {
        url += '&other_email=' + data.other_email;
    }

    return Get(url);
};

export const createTrainingPlace = data => {
    let url = 'trainingPlaces';
    return Post(url, data);
};

export const updateTrainingPlace = (training_place_id, data) => {
    let url = 'trainingPlaces/' + training_place_id;
    return Post(url, data);
};

export const deleteTrainingPlace = training_place_id => {
    let url = 'trainingPlaces/' + training_place_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------- REFUNDS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getRefunds = data => {
    let url = 'refunds?';

    if (data.statuses != null && data.statuses.length > 0) {
        data.statuses.forEach(status => {
            url += '&statuses[]=' + status;
        });
    }
    if (data.client_code != null && data.client_code !== '') {
        url += '&client_code=' + data.client_code;
    }
    if (data.external_client_code != null && data.external_client_code !== '') {
        url += '&external_client_code=' + data.external_client_code;
    }
    if (data.last_name != null && data.last_name !== '') {
        url += '&last_name=' + data.last_name;
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.bill_reference != null && data.bill_reference !== '') {
        url += '&reference=' + data.bill_reference;
    }
    if (data.external_reference != null && data.external_reference !== '') {
        url += '&external_reference=' + data.external_reference;
    }
    if (data.amount != null && data.amount !== '') {
        url += '&amount=' + data.amount;
    }
    if (data.refund_methods != null && data.refund_methods.length > 0) {
        data.refund_methods.forEach(refund_method => {
            url += '&payment_methods[]=' + refund_method;
        });
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.address != null && data.address !== '') {
        url += '&address=' + data.address;
    }
    if (data.address_details != null && data.address_details !== '') {
        url += '&address_details=' + data.address_details;
    }
    if (data.country_ids != null && data.country_ids.length > 0) {
        data.country_ids.forEach(country_id => {
            url += '&country_ids[]=' + country_id;
        });
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }
    if (data.federated_association_ids != null && data.federated_association_ids.length > 0) {
        data.federated_association_ids.forEach(federated_association_id => {
            url += '&federated_association_ids[]=' + federated_association_id;
        });
    }
    if (data.payment_methods != null && data.payment_methods.length > 0) {
        data.payment_methods.forEach(payment_method => {
            url += '&bill_payment_methods[]=' + payment_method;
        });
    }

    return Get(url);
};

export const createRefund = (bill_item_id, data) => {
    let url = 'billItems/' + bill_item_id + '/refund';
    return Post(url, data);
};

export const updateRefund = (refund_id, data) => {
    let url = 'refunds/' + refund_id;
    return Post(url, data);
};

export const deleteRefund = refund_id => {
    let url = 'refunds/' + refund_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- CUSTOM ITEMS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const addCustomItem = (bill_id, data) => {
    let url = 'bills/' + bill_id + '/customItems';
    return Post(url, data);
};

export const deleteCustomItem = custom_item_id => {
    let url = 'customItems/' + custom_item_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- USER CATEGORIES ----------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getUserCategories = data => {
    let url = 'userCategories?';

    if (data.names != null && data.names.length > 0) {
        data.names.forEach(name => {
            url += '&names[]=' + name;
        });
    }
    if (data.count_users != null && data.count_users !== '') {
        url += '&nb_users=' + data.count_users;
    }

    return Get(url);
};

export const getUserCategory = user_category_id => {
    let url = 'userCategories/' + user_category_id;
    return Get(url);
};

export const createUserCategory = data => {
    let url = 'userCategories';
    return Post(url, data);
};

export const updateUserCategory = (user_category_id, data) => {
    let url = 'userCategories/' + user_category_id;
    return Post(url, data);
};

export const deleteUserCategory = user_category_id => {
    let url = 'userCategories/' + user_category_id;
    return Delete(url);
};

export const assignUserCategories = (user_id, data) => {
    let url = 'users/' + user_id + '/userCategories';
    return Post(url, data);
};

export const getUsersInCategory = (user_category_id, data) => {
    let url = 'userCategories/' + user_category_id + '/users?offset=' + data.offset + '&limit=' + data.limit;

    if (data.full_names != null && data.full_names.length > 0) {
        data.full_names.forEach(full_name => {
            url += '&full_names[]=' + full_name;
        });
    }
    if (data.company_name != null && data.company_name !== '') {
        url += '&company_name=' + data.company_name;
    }
    if (data.email != null && data.email !== '') {
        url += '&email=' + data.email;
    }
    if (data.membership_email != null && data.membership_email !== '') {
        url += '&membership_email=' + data.membership_email;
    }
    if (data.mobile_phone != null && data.mobile_phone !== '') {
        url += '&mobile_phone=' + data.mobile_phone;
    }
    if (data.phone != null && data.phone !== '') {
        url += '&phone=' + data.phone;
    }
    if (data.federated_association_ids != null && data.federated_association_ids.length > 0) {
        data.federated_association_ids.forEach(federated_association_id => {
            url += '&federated_association_ids[]=' + federated_association_id;
        });
    }
    if (data.postal_codes != null && data.postal_codes.length > 0) {
        data.postal_codes.forEach(postal_code => {
            url += '&postal_codes[]=' + postal_code;
        });
    }

    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------ FORMATION DOCUMENTS --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getFormationDocuments = formation_session_id => {
    let url = 'formationSessions/' + formation_session_id + '/formationDocuments';
    return Get(url);
};

export const addDocumentToFormationSession = (formation_session_id, data) => {
    let url = 'formationSessions/' + formation_session_id + '/formationDocuments';
    return Post(url, data, 'file');
};

export const removeDocumentFromFormationSession = formation_document_id => {
    let url = 'formationDocuments/' + formation_document_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- ACTIVITIES ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getActivities = data => {
    let url = 'activities';

    if (data.is_pro != null && data.is_pro) {
        url += '?is_pro=1';
    }
    if (data.is_pro != null && !data.is_pro) {
        url += '?is_pro=0';
    }

    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- RECAPTCHA -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const checkCaptcha = data => {
    let url = 'captcha';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- TRANSFERS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getTransfers = data => {
    let url = 'transfers?offset=' + data.offset + '&limit=' + data.limit;

    if (data.federated_association_id != null && data.federated_association_id !== -1) {
        url += '&federated_association_id=' + data.federated_association_id;
    }
    if (data.amount_to_transfer != null && data.amount_to_transfer !== '') {
        url += '&amount_to_transfer=' + data.amount_to_transfer;
    }
    if (data.money_is_transferred != null && data.money_is_transferred !== -1) {
        url += '&money_is_transferred=' + data.money_is_transferred;
    }

    return Get(url);
};

export const getTransfer = transfer_id => {
    let url = 'transfers/' + transfer_id;
    return Get(url);
};

export const createTransfer = data => {
    let url = 'transfers';
    return Post(url, data);
};

export const updateTransfer = (transfer_id, data) => {
    let url = 'transfers/' + transfer_id;
    return Post(url, data);
};

export const deleteTransfer = transfer_id => {
    let url = 'transfers/' + transfer_id;
    return Delete(url);
};

export const getCollectedBy = (transfer_id, data) => {
    let url = 'transfers/' + transfer_id + '/memberships?offset=' + data.offset + '&limit=' + data.limit;

    if (data.collected_by != null && data.collected_by !== '') {
        url += '&collected_by=' + data.collected_by;
    }
    if (data.username != null && data.username !== '') {
        url += '&username=' + data.username;
    }

    return Get(url);
};

export const getSubscriptionsCollected = (transfer_id, data) => {
    let url = 'transfers/' + transfer_id + '/subscriptions?offset=' + data.offset + '&limit=' + data.limit;

    if (data.username != null && data.username !== '') {
        url += '&username=' + data.username;
    }

    return Get(url);
};
