import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getCategories } from 'services/requests';
import { fileUrl } from 'services/axios-config';
import { userCanCreateCategory, userCanUpdateCategory } from 'services/permissions';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import CreateCategory from './create-category';
import UpdateCategory from './update-category';

// --------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- CATEGORIES ------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const Categories = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        categories: [],
        category: undefined,
        openCreate: false,
        openUpdate: false,
    });

    useEffect(() => {
        if (state.loading) {
            getCategories().then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, categories: res.data.data.categories });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: false });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Catégories de formation
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanCreateCategory() && (
                        <Button onClick={() => setState({ ...state, openCreate: true })} variant="contained">
                            Ajouter une catégorie
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Grid className="main-container" style={{ marginTop: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Icône
                        </Grid>
                        <Grid item xs={10} style={{ padding: 10 }}>
                            Nom
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- LIST -------------------- */}
                <CategoryList state={state} setState={setState} />
            </Grid>
            {/* -------------------- CREATE CATEGORY -------------------- */}
            {state.openCreate && (
                <CreateCategory
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- CATEGORY LIST ----------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const CategoryList = ({ state, setState }) => {

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <div className="main-container-item" style={{ paddingBottom: 24 }}>
                {state.categories.length > 0 ? (
                    state.categories.map(category => (
                        <Fragment key={category.id}>
                            <Grid
                                onClick={userCanUpdateCategory() ? () => setState({ ...state, openUpdate: true, category }) : null}
                                className={userCanUpdateCategory() ?  'simple-list' : null}
                                container alignItems="center"
                            >
                                <Grid item xs={2} style={{ padding: 10 }}>
                                    <img
                                        alt="category-icon"
                                        src={fileUrl + category.icon_url}
                                        style={{ height: 20, width: 'auto' }}
                                    />
                                </Grid>
                                <Grid item xs={10} style={{ padding: 10 }}>
                                    {category.name}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid container style={{ padding: 10 }}>
                            Aucun
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- UPDATE CATEGORY -------------------- */}
            {state.openUpdate && (
                <UpdateCategory
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    category={state.category}
                />
            )}
        </>
    );
};

export default Categories;
