import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { updateSessionOption, removeSessionOption } from 'services/requests';
import { amountToSend, amountToBeReceived, isValidAmount } from 'services/helpers';

// Components
import Spinner from 'components/spinner';

// Views
import SessionOptionForm from './session-options-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- UPDATE SESSION OPTION ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UpdateSessionOption = ({ onClose, refetch, session_option }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        price: amountToBeReceived(session_option.price),
        name: session_option.name || '',
        description: session_option.description || '',
        openDelete: false,
    });

    function onUpdate() {
        if (state.price === '' || state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!isValidAmount(state.price)) {
            enqueueSnackbar('Veuillez saisir un montant valide', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateSessionOption(session_option.id, {
                price: amountToSend(state.price),
                name: state.name,
                description: state.description,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <>
            <Dialog open fullWidth>
                {state.loading ? (
                    <Spinner />
                ) : (
                    <>
                        <DialogContent style={{ paddingBottom: 20 }}>
                            <p className="dialog-title">
                                Modifier une option à la session
                            </p>
                            {/* -------------------- FORM -------------------- */}
                            <SessionOptionForm
                                state={state}
                                setState={setState}
                            />
                            {!session_option.isUsed && (
                                <>
                                    <Divider style={{ margin: '15px 0px' }} />
                                    {/* -------------------- DELETE -------------------- */}
                                    <Grid container alignItems="center" style={{ marginTop: 5 }}>
                                        <Grid item xs={12} sm={4}>
                                            Supprimer cette option
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Button
                                                onClick={() => setState({ ...state, openDelete: true })}
                                                variant="contained" color="secondary"
                                            >
                                                Supprimer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </DialogContent>
                        <Divider />
                        <DialogActions style={{ justifyContent: 'space-between' }}>
                            <Button onClick={onClose} variant="contained">
                                Annuler
                            </Button>
                            <Button onClick={onUpdate} variant="contained">
                                Enregistrer
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            {/* -------------------- DELETE SESSION OPTION -------------------- */}
            {state.openDelete && (
                <DeleteSessionOption
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => refetch()}
                    session_option={session_option}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- DELETE SESSION OPTION ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const DeleteSessionOption = ({ onClose, refetch, session_option }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            removeSessionOption(session_option.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {session_option.name}
                        </p>
                        Êtes-vous sûr de vouloir supprimer cette option ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateSessionOption;
