import { getStoredUser } from './storage';

/* * *
* Permet de gérer l'affichage des interfaces et les droits de l'utilisateur en fonction
* de ses permissions (en cohérence avec le Backend).
*/

// ------------------------------------------------------------------------- \\
// ------------------------------ MANAGEMENT ------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToManagement = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('management:list')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ------------------------ FEDERATED ASSOCIATIONS ------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanCreateFederatedAssociation = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('fa:create')) {
        return true;
    }
    return false;
};

export const userCanUpdateFederatedAssociation = federated_association_id => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('fa:update') && (
        user.role.name === 'admin'
        || user.federated_association_id === federated_association_id
        || user.isFromRoot
    ))) {
        return true;
    }
    return false;
};

export const userCanDeleteFederatedAssociation = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('fa:delete')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// -------------------------------- DOMAINS -------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanGetAllDomains = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('domain:fetchAll')) {
        return true;
    }
    return false;
};

export const userCanCreateDomain = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('domain:create')) {
        return true;
    }
    return false;
};

export const userCanUpdateDomain = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('domain:update')) {
        return true;
    }
    return false;
};

export const userCanDeleteDomain = domain_creator_id => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('domain:delete') && (
        user.role.name === 'admin'
        || user.id === domain_creator_id
        || ((user.role.name === 'manager' || user.role.name === 'collaborator') && user.isFromRoot)
    ))) {
        return true;
    }
    return false;
};

export const userCanFetchDomainWithUsers = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('domain:fetchWithUsers')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// --------------------------------- USERS --------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToUserList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('users:list')) {
        return true;
    }
    return false;
};

export const userCanCreateUser = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('users:create')) {
        return true;
    }
    return false;
};

export const userCanUpdateUser = model => {
    const user = getStoredUser();
    if (user && (user.id === model.id
        || user.role?.name === 'admin'
        || ((user.role?.name === 'manager' || user.role?.name === 'collaborator') && 
            ((user.federated_association_id === model.federated_association_id) || user.isFromRoot))
    )) {
        return true;
    }
    return false;
};

export const userCanUpdateUserSettings = () => {
    let storedUser = getStoredUser();
    if (
        storedUser
        && (
            storedUser.role?.name === 'admin'
            || (
                storedUser.isFromRoot
                && (
                    storedUser.role?.name === 'manager'
                    || storedUser.role?.name === 'collaborator'
                )
            )
        )
    ) {
        return true;
    }
    return false;
};

export const userCanDeleteUser = model => {
    const user = getStoredUser();
    if (user && (user.id === model.id || user.permissions.includes('users:trash'))) {
        return true;
    }
    return false;
};

export const userCanChangeFA = () => {
    const user = getStoredUser();
    if (user && (user.role.name === 'admin'
        || ((user.role.name === 'manager' || user.role.name === 'collaborator') && user.isFromRoot)
    )) {
        return true;
    }
    return false;
};

export const userCanAccessToUserTrash = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('users:trash')) {
        return true;
    }
    return false;
};

export const userCanMergeUsers = () => {
    const user = getStoredUser();
    return user && user.role.name === 'admin';
}

// ------------------------------------------------------------------------- \\
// -------------------------------- GROUPS --------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToGroups = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('userList:read')) {
        return true;
    }
    return false;
};

export const userCanCreateGroup = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('userList:create') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanUpdateGroup = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('userList:update') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanDeleteGroup = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('userList:delete') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// -------------------------------- NOTES ---------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanReadNote = () => {
    const user = getStoredUser();
    if (user && user.permissions?.includes('note:read')) {
        return true;
    }
    return false;
};

export const userCanCreateNote = () => {
    const user = getStoredUser();
    if (user && user.permissions?.includes('note:create')) {
        return true;
    }
    return false;
};

export const userCanUpdateNote = () => {
    const user = getStoredUser();
    if (user && user.permissions?.includes('note:update')) {
        return true;
    }
    return false;
};

export const userCanDeleteNote = () => {
    const user = getStoredUser();
    if (user && user.permissions?.includes('note:delete')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ------------------------- SUBSCRIPTION TYPES ---------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanUpdateSubscriptionTypes = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('subscriptionType:update')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// -------------------------- MEMBERSHIP TYPES ----------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanUpdateMembershipTypes = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('membershipType:update')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ---------------------------- SUBSCRIPTIONS ------------------------------ \\
// ------------------------------------------------------------------------- \\

export const userCanHandleSubscriptionForUser = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('subscription:handleForUser')) {
        return true;
    }
    return false;
};

export const userCanAccessToSubscriptionList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('subscription:list')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ----------------------------- MEMBERSHIPS ------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToMembershipList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('membership:list')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// --------------------------------- BILLS --------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToBillList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('bill:list')) {
        return true;
    }
    return false;
};

export const userCanUpdateBill = federated_association => {
    const user = getStoredUser();
    if (user
        && user.permissions?.includes('bill:update')
        && (
            user.role.name === 'admin'
            || user.isFromRoot
            || (federated_association && federated_association.id === user.federated_association_id)
        )
    ) {
        return true;
    }
    return false;
};

export const userCanDeleteBill = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('bill:delete') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ------------------------------- PAYMENTS -------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToPaymentList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('payment:list')) {
        return true;
    }
    return false;
};

export const userCanCreatePayment = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('payment:create')) {
        return true;
    }
    return false;
};

export const userCanUpdatePayment = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('payment:update')) {
        return true;
    }
    return false;
};

export const userCanDeletePayment = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('payment:delete')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// --------------------------- CONSULTING VISITS --------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToConsultingVisitList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('consultingVisit:list')) {
        return true;
    }
    return false;
};

export const userCanCreateConsultingVisit = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('consultingVisit:create') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanUpdateConsultingVisit = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('consultingVisit:update') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanDeleteConsultingVisit = consulting_visit => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('consultingVisit:delete')
        && consulting_visit.visit_date_one == null
        && consulting_visit.report_first_visit == null
    )) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ------------------------------- MAGAZINES ------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToMagazineList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('magazine:list')) {
        return true;
    }
    return false;
};

export const userCanCreateMagazine = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('magazine:create')) {
        return true;
    }
    return false;
};

export const userCanUpdateMagazine = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('magazine:update')) {
        return true;
    }
    return false;
};

export const userCanDeleteMagazine = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('magazine:delete')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ------------------------------ CATEGORIES ------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanCreateCategory = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('category:create')) {
        return true;
    }
    return false;
};

export const userCanUpdateCategory = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('category:update')) {
        return true;
    }
    return false;
};

export const userCanDeleteCategory = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('category:delete')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ------------------------------ FORMATIONS ------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToFormationList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('formation:list')) {
        return true;
    }
    return false;
};

export const userCanCreateFormation = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('formation:create') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanUpdateFormation = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('formation:update') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanDeleteFormation = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('formation:delete') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// -------------------------- FORMATION SESSIONS --------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanCreateFormationSession = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('formationSession:create') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanUpdateFormationSession = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('formationSession:update')) {
        return true;
    }
    return false;
};

export const userCanDeleteFormationSession = manager_id => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('formationSession:delete') && (
        user.role.name === 'admin'
        || user.isFromRoot
        || user.id === manager_id
    ))) {
        return true;
    }
    return false;
};

export const userCanHandleParticipations = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('formationSession:handleParticipations'))) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// --------------------------- TRAINING PLACES ----------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToTrainingPlaceList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('trainingPlace:list')) {
        return true;
    }
    return false;
};

export const userCanCreateTrainingPlace = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('trainingPlace:create') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanUpdateTrainingPlace = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('trainingPlace:update') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanDeleteTrainingPlace = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('trainingPlace:delete') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ---------------------------- USER CATEGORIES ---------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanReadUserCategory = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('userCategory:read')) {
        return true;
    }
    return false;
};

export const userCanCreateUserCategory = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('userCategory:create')) {
        return true;
    }
    return false;
};

export const userCanUpdateUserCategory = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('userCategory:update')) {
        return true;
    }
    return false;
};

export const userCanDeleteUserCategory = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('userCategory:delete')) {
        return true;
    }
    return false;
};

// ------------------------------------------------------------------------- \\
// ------------------------------- TRANSFERS ------------------------------- \\
// ------------------------------------------------------------------------- \\

export const userCanAccessToTransferList = () => {
    const user = getStoredUser();
    if (user && user.permissions.includes('transfer:list')) {
        return true;
    }
    return false;
};

export const userCanCreateTransfer = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('transfer:create') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanUpdateTransfer = () => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('transfer:update') && (user.role.name === 'admin' || user.isFromRoot))) {
        return true;
    }
    return false;
};

export const userCanDeleteTransfer = money_is_transferred => {
    const user = getStoredUser();
    if (user && (user.permissions.includes('transfer:update')
        && (user.role.name === 'admin' || user.isFromRoot))
        && money_is_transferred === 0
    ) {
        return true;
    }
    return false;
};
