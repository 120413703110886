import React from 'react';
import SelectCreatable from 'components/select-creatable';

// Column Width
let columnWidth = 140;

// -------------------- HEADER -------------------- \\
const PostalCodeHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Code postal
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const PostalCodeFilter = ({ state, onSelectSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <SelectCreatable
                value={state.postal_codes}
                onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                placeholder="Code postal"
                minWidth={(columnWidth - 20)}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const PostalCodeValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { PostalCodeHeader, PostalCodeFilter, PostalCodeValue };
