import React, { useState, useEffect } from 'react';
import { useHistory, Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom';

// Material-UI
import { Button, Grid } from '@material-ui/core';

// Services
import { getSession } from 'services/requests';
import { getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import UpcomingSessions from 'views/formations/upcoming-sessions';
import CreateFormationRequest from 'views/formations/create-formation-request';

// ------------------------------------------------------------------------------------------- \\
// ------------------------------------ FORMATIONS ROUTER ------------------------------------ \\
// ------------------------------------------------------------------------------------------- \\

const FormationsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <UpcomingSessions />
            </Route>
            <Route exact path={`${match.path}/:formationSessionId/session`}>
                <CreateUserFormationRequest />
            </Route>
            <Redirect
                from="/formations/:formationSessionId/session/:userId"
                to="/formations/:formationSessionId/session"
            />
        </Switch>
    );
};

// ------------------------------------------------------------------------------------------- \\
// --------------------------------- CREATE FORMATION REQUEST -------------------------------- \\
// ------------------------------------------------------------------------------------------- \\

const CreateUserFormationRequest = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { formationSessionId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formationSession: null,
    });

    useEffect(() => {
        if (state.loading) {
            getSession(formationSessionId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, formationSession: res.data.data.formationSession });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push('/formations')}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    Formulaire d'inscription
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-end' : 'center'}
                >
                    <div style={{ backgroundColor: 'rgba(255, 0, 0, 0.1)', borderRadius: 4, padding: 4 }}>
                        Fin d'inscription : {getFormattedDate(state.formationSession.end_of_registration)}
                    </div>
                </Grid>
            </Grid>
            {/* -------------------- CREATE FORMATION REQUEST -------------------- */}
            <CreateFormationRequest
                formationSession={state.formationSession}
            />
        </>
    );
};

export default FormationsRouter;
