import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';
import { getUserSubscriptions } from 'services/requests';
import useMediaQueries from 'services/media';
import { subscriptionStatuses } from 'services/constants';
import { getFormattedDate, getColorStatus, getLabelStatus } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import UpdateSubscription from 'views/management/subscriptions/update-subscription';

/* * *
* Historique des abonnements
*/

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------- SUBSCRIPTIONS HISTORY ------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const SubscriptionsHistory = () => {
    const storedUserId = getStoredUser()?.id;
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        subscriptions: [],
        subscription: null,
        openUpdate: false,
    });

    useEffect(() => {
        if (state.loading) {
            getUserSubscriptions(storedUserId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, subscriptions: res.data.data.subscriptions });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/dashboard')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Historique des abonnements
                </Grid>
            </Grid>
            <Grid style={{ padding: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Période
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Type d'abonnement
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Adresse de facturation
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- LIST -------------------- */}
                {state.subscriptions.length > 0 ? (
                    state.subscriptions.map(subscription => (
                        <Fragment key={subscription.id}>
                            <Grid
                                onClick={() => setState({ ...state, openUpdate: true, subscription })}
                                container alignItems="center"
                                className="simple-list"
                            >
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    <b style={{ color: getColorStatus(subscriptionStatuses, subscription.status) }}>
                                        {getLabelStatus(subscriptionStatuses, subscription.status)}
                                    </b>
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {getFormattedDate(subscription.start_date)} - {getFormattedDate(subscription.end_date)}
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {subscription.subscriptionType ? subscription.subscriptionType.name : ''}
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {subscription.address} {subscription.postal_code} {subscription.city} {subscription.country.name}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid container style={{ padding: 10 }}>
                            Aucun
                        </Grid>
                        <Divider />
                    </>
                )}
            </Grid>
            {/* -------------------- UPDATE -------------------- */}
            {state.openUpdate && (
                <UpdateSubscription
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    subscription={state.subscription}
                />
            )}
        </>
    );
};

export default SubscriptionsHistory;
