import React, { useEffect, useState, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Checkbox, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon } from '@material-ui/icons';

// Services
import { getSubscriptionsToAddInMailingList, addSubscriptionsToMailingList } from 'services/requests';
import useMediaQueries from 'services/media';
import { getLabelStatus } from 'services/helpers';
import { subscriptionStatuses } from 'services/constants';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import InputText from 'components/input-text';
import Tooltip from 'components/tooltip';
import Pagination from 'components/pagination';

// Selects
import SelectSubscriptionStatuses from 'selects/select-subscription-statuses';
import SelectSubscriptionTypes from 'selects/select-subscription-types';

// Views
import SubscriptionsToAddInMailingListExport from './subscriptions-to-add-in-mailing-list-export';

/* * *
* Liste des utilisateurs à ajouter dans la liste d'envoi pour la revue en question
*/

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------- SUBSCRIPTIONS TO ADD IN MAILING LIST ---------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SubscriptionsToAddInMailingList = () => {
    const history = useHistory();
    const { magazineId, mailingListId } = useParams();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: true,
        error: false,
        offset: 0,
        limit: 30,
        total_count: 0,
        subscriptions: [],

        // Add
        openAdd: false,
        checkboxAll: false,
        checkboxes: {},
        subscription_ids: [],

        // Filter
        subscription_id: '',
        name: '',
        first_name: '',
        last_name: '',
        subscription_types: [],
        statuses: [],
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            subscription_id: '',
            name: '',
            first_name: '',
            last_name: '',
            subscription_types: [],
            statuses: [],
        });
    }

    function onSelectAll(checked) {
        let subscription_ids = state.subscription_ids;
        let checkboxes = state.checkboxes;
        if (checked) {
            Object.keys(checkboxes).forEach(key => {
                checkboxes[key] = true;
            });
            state.subscriptions.forEach(subscription => {
                if (!subscription_ids.includes(subscription.id)) {
                    subscription_ids.push(subscription.id);
                }
            });
        }
        else {
            Object.keys(checkboxes).forEach(key => {
                checkboxes[key] = false;
            });
            subscription_ids = [];
        }
        setState({ ...state, checkboxAll: checked, checkboxes, subscription_ids });
    }

    useEffect(() => {
        if (state.loading) {
            getSubscriptionsToAddInMailingList(mailingListId, {
                offset: state.offset,
                limit: state.limit,
                subscription_id: state.subscription_id,
                name: state.name,
                first_name: state.first_name,
                last_name: state.last_name,
                subscription_type_ids: state.subscription_types ? state.subscription_types.map(s => s.value) : null,
                statuses: state.statuses ? state.statuses.map(s => s.value) : null,
            }).then(res => {
                if (res.status === 200) {

                    let subscriptions = res.data.data.subscriptions;
                    let checkboxes = {};
                    subscriptions.forEach(subscription => {
                        checkboxes[subscription.id] = false;
                    });

                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        subscriptions,
                        checkboxes,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingAdd) {
            addSubscriptionsToMailingList(mailingListId, {
                subscription_ids: state.subscription_ids,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingAdd: false, loading: true });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingAdd: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingAdd]);
    
    if (state.loadingAdd) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management/magazines/' + magazineId)}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Liste des nouveaux abonnements
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={() => setState({ ...state, openAdd: true })} color="secondary" variant="contained">
                        Ajouter
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- TITLE -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <b>Si vous souhaitez ajouter des utilisateurs à la liste d'envoi, sélectionnez-les puis appuyez sur le bouton {'<Ajouter>'}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={8} container alignItems="center">
                    <div className="input-group" style={{ width: 300 }}>
                        <div className="input-group-prepend" onClick={onSearch} style={{ cursor: 'pointer' }}>
                            <span className="input-group-text">
                                <SearchIcon />
                            </span>
                        </div>
                        <InputText
                            label="Chercher un utilisateur..."
                            type="text"
                            value={state.name}
                            onChange={e => setState({ ...state, name: e.target.value })}
                            onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                        />
                    </div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <SubscriptionsToAddInMailingListExport mailingListId={mailingListId} data={state} />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container alignItems="center" justify={mediaMD ? 'flex-end' : 'flex-start'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <b>
                        {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                    </b>
                </Grid>
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                <Grid container alignItems="center" className="list-headers">
                    {mediaMD ? (
                        <>
                            <Grid item xs={1} style={{ textAlign: 'center' }}>
                                {state.checkboxAll ? (
                                    <Button
                                        onClick={() => onSelectAll(false)}
                                        variant="contained"
                                        style={{ padding: '3px 6px' }}
                                    >
                                        Aucun
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => onSelectAll(true)}
                                        variant="contained"
                                        style={{ padding: '3px 6px' }}
                                    >
                                        Tout
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={1} style={{ padding: 10 }}>
                                N°abo
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Nom
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Prénom
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Type d'abonnement
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Statut abonnement
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Adresse
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ padding: 10 }}>
                            Nouveaux abonnements
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- FILTER -------------------- */}
                {mediaMD && (
                    <>
                        <Grid container alignItems="center">
                                <Grid item xs={1} />
                                <Grid item xs={1} style={{ padding: 10 }}>
                                    <InputText
                                        label="N°abo"
                                        value={state.subscription_id}
                                        onChange={e => setState({ ...state, subscription_id: e.target.value })}
                                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{ padding: 10 }}>
                                    <InputText
                                        label="Nom"
                                        value={state.last_name}
                                        onChange={e => setState({ ...state, last_name: e.target.value })}
                                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{ padding: 10 }}>
                                    <InputText
                                        label="Prénom"
                                        value={state.first_name}
                                        onChange={e => setState({ ...state, first_name: e.target.value })}
                                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{ padding: 10 }}>
                                    <SelectSubscriptionTypes
                                        subscriptionTypes={state.subscription_types}
                                        setSubscriptionTypes={subscription_types => onSelectSearch('subscription_types', subscription_types)}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{ padding: 10 }}>
                                    <SelectSubscriptionStatuses
                                        statuses={state.statuses}
                                        setStatuses={statuses => onSelectSearch('statuses', statuses)}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{ padding: 10 }} />
                        </Grid>
                        <Divider />
                    </>
                )}
                {/* -------------------- LIST -------------------- */}
                <SubscriptionList
                    state={state}
                    setState={setState}
                />
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- ADD -------------------- */}
            {state.openAdd && (
                <AddSubscriptionsToMailingList 
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={() => setState({ ...state, openAdd: false, loading: true })}
                    mailingListId={mailingListId}
                    subscriptionIds={state.subscription_ids}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- SUBSCRIPTION LIST ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SubscriptionList = ({ state, setState }) => {

    function onSelectSubscription(checked, subscription) {
        let subscription_ids = state.subscription_ids;
        let checkboxes = state.checkboxes;
        if (checked) {
            checkboxes[subscription.id] = true;
            subscription_ids.push(subscription.id);
        }
        else {
            checkboxes[subscription.id] = false;
            const index = subscription_ids.findIndex(id => id === subscription.id);
            if (index !== -1) {
                subscription_ids.splice(index, 1);
            }
        }
        setState({ ...state, checkboxes, subscription_ids });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <div className="main-container-item">
            {state.subscriptions.length > 0 ? (
                state.subscriptions.map(subscription => (
                    <Fragment key={subscription.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={1} style={{ textAlign: 'center' }}>
                                <Checkbox
                                    checked={state.checkboxes[subscription.id]}
                                    onChange={e => onSelectSubscription(e.target.checked, subscription)}
                                    style={{ padding: 6 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={1} style={{ padding: 10 }}>
                                #{subscription.id}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {subscription.last_name}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {subscription.first_name}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {subscription.subscriptionType?.name}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {getLabelStatus(subscriptionStatuses, subscription?.status)}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {subscription.address} {subscription.postal_code} {subscription.city} {subscription.country?.name}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid container style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------------- ADD ---------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const AddSubscriptionsToMailingList  = ({ onClose, refetch, mailingListId, subscriptionIds }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    function onAdd() {
        if (subscriptionIds == null || subscriptionIds.length === 0) {
            enqueueSnackbar('Veuillez sélectionner au moins un utilisateur à ajouter', { variant: 'warning' });
        }
        else {
            setLoading(true);
        }
    }

    useEffect(() => {
        if (loading) {
            addSubscriptionsToMailingList (mailingListId, {
                subscription_ids: subscriptionIds,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        Êtes-vous sûr de vouloir ajouter ces utilisateurs à la liste d'envoi ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default SubscriptionsToAddInMailingList;
