import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getFormations } from 'services/requests';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- FORMATIOMS EXPORT --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationsExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getStringFromArray(array) {
        let result = '';
        array.forEach((item, index) => {
            if (index === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });
        return result;
    }

    function getAllFormationsToExport(formations, offset) {
        getFormations({
            offset: offset,
            limit: data.limit,
            titles: data.titles ? data.titles.map(t => t.value) : null,
            durations: data.durations ? data.durations.map(d => d.value) : null,
            category_ids: data.categories ? data.categories.map(c => c.value) : null,
            support: data.support,
        }).then(res => {
            if (res.status === 200) {

                let res_formations = res.data.data.formations;
                formations = formations.concat(res_formations);

                setState({
                    ...state,
                    percentage: Math.round((100 * formations.length) / data.total_count) + '%',
                });

                if (res_formations.length < data.limit) {
                    createExcelFile(formations);
                }
                else {
                    getAllFormationsToExport(formations, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(formations) {
        if (formations.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {

            // Create Excel File
            var workbook = new ExcelJS.Workbook();
            
            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Modèles de formation');

            // Header
            worksheet.columns = [
                { header: 'Intitulé du modèle', key: 'title', width: 30 },
                { header: 'Durée', key: 'duration', width: 30 },
                { header: 'Catégorie', key: 'category', width: 30 },
                { header: 'Support pédagogique', key: 'support', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            formations.map(formation => {
                return worksheet.addRow({
                    title: formation.title || '',
                    duration: formation.duration ? (formation.duration + 'h') : '',
                    category: formation.categories ? getStringFromArray(formation.categories) : '',
                    support: formation.document_url ? 'Oui' : 'Non',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'modeles-de-formations.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllFormationsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default FormationsExport;
