import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Dialog, DialogContent, DialogActions, Divider, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as UpdateIcon } from '@material-ui/icons';

// Services
import { addContactToDomain, deleteContactFromDomain, updateContactFromDomain } from 'services/requests';

// Assets
import InputText from 'components/input-text';
import InputPhone from 'components/input-phone';
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';
import Error from 'components/error';
import FieldRequired from 'components/field-required';
import useMediaQueries from 'services/media';

// Messages
import messages from 'messages.json';

// ----------------------------------- CONTACTS ---------------------------------- \\
const Contacts = ({ domain }) => {
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        error: false,
        contacts: domain.contacts || [],
        contact: null,
        openAdd: false,
        openUpdate: false,
        openDelete: false,
    });

    function onAddContact(contact) {
        let contacts = state.contacts;
        contacts.push(contact);
        setState({ ...state, openAdd: false, contacts });
    }

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <Grid item xs={6}>
                    Contacts
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                    <Button onClick={() => setState({ ...state, openAdd: true })} variant="contained">
                        Ajouter un contact
                    </Button>
                </Grid>
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Nom
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Fonction ou rôle
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Email
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Téléphone
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Description
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10, textAlign: 'center' }}>
                            Action
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- CONTACT LIST -------------------- */}
                <ContactList
                    state={state}
                    setState={setState}
                />
            </div>
            {/* -------------------- ADD CONTACT -------------------- */}
            {state.openAdd && (
                <AddContact
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={contact => onAddContact(contact)}
                />
            )}
        </>
    );
};

// ----------------------------------- CONTACT LIST ---------------------------------- \\
const ContactList = ({ state, setState }) => {

    function onUpdate(contact) {
        let contacts = state.contacts;
        let index = contacts.findIndex(c => c.id === contact.id);
        contacts.splice(index, 1, contact);
        setState({ ...state, openUpdate: false, contacts });
    }

    function onDelete(contact_id) {
        let contacts = state.contacts;
        let index = contacts.findIndex(c => c.id === contact_id);
        contacts.splice(index, 1);
        setState({ ...state, openDelete: false, contacts });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <div className="main-container-item">
            {state.contacts.length > 0 ? (
                state.contacts.map(contact => (
                    <Fragment key={contact.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {contact.name}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {contact.type}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {contact.email}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {contact.phone}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {contact.description}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                <Tooltip title="Modifier"
                                    item={(
                                        <IconButton
                                            onClick={() => setState({ ...state, openUpdate: true, contact })}
                                            style={{ padding: 6 }}
                                        >
                                            <UpdateIcon />
                                        </IconButton>
                                    )}
                                />
                                <Tooltip title="Supprimer"
                                    item={(
                                        <IconButton
                                            onClick={() => setState({ ...state, openDelete: true, contact })}
                                            style={{ padding: 6 }}       
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid container style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- DELETE CONTACT -------------------- */}
            {state.openUpdate && (
                <UpdateContact
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={contact => onUpdate(contact)}
                    contact={state.contact}
                />
            )}
            {/* -------------------- DELETE CONTACT -------------------- */}
            {state.openDelete && (
                <DeleteContact
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={contact_id => onDelete(contact_id)}
                    contact={state.contact}
                />
            )}
        </div>
    );
};

// ----------------------------------- ADD CONTACT ----------------------------------- \\
const AddContact = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { domainId } = useParams();

    const [state, setState] = useState({
        loading: false,
        name: '',
        type: '',
        email: '',
        phone: '',
        description: '',
    });

    function onAdd() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            addContactToDomain(domainId, {
                name: state.name,
                type: state.type,
                email: state.email,
                phone: state.phone,
                description: state.description,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.contact);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un contact
                        </p>
                        <ContactForm state={state} setState={setState} />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ----------------------------------- ADD CONTACT ----------------------------------- \\
const UpdateContact = ({ onClose, refetch, contact }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { domainId } = useParams();

    const [state, setState] = useState({
        loading: false,
        name: contact.name || '',
        type: contact.type || '',
        email: contact.email || '',
        phone: contact.phone || '',
        description: contact.description || '',
    });

    function onUpdate() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateContactFromDomain(domainId, contact.id, {
                name: state.name,
                type: state.type,
                email: state.email,
                phone: state.phone,
                description: state.description,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.contact);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Modifier un contact
                        </p>
                        <ContactForm state={state} setState={setState} />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onUpdate} variant="contained">
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ---------------------------------- DELETE CONTACT ---------------------------------- \\
const DeleteContact = ({ onClose, refetch, contact }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { domainId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteContactFromDomain(domainId, contact.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(contact.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {contact.name}
                        </p>
                        Êtes-vous sûr de vouloir supprimer ce contact ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ---------------------------------- CONTACT FORM ---------------------------------- \\
const ContactForm = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Nom</span><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom"
                        type="text"
                        value={state.name}
                        onChange={e => setState({ ...state, name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- TYPE -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Fonction ou rôle</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Fonction ou rôle"
                        type="text"
                        value={state.type}
                        onChange={e => setState({ ...state, type: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- EMAIL -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Email</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Email"
                        type="email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- PHONE -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Téléphone</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputPhone
                        label="Téléphone"
                        value={state.phone}
                        setValue={phone => setState({ ...state, phone })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- DESCRIPTION -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <span>Description</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Description"
                        multiline
                        value={state.description}
                        onChange={e => setState({ ...state, description: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Contacts;
