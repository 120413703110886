import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// File-Saver
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, MenuItem } from '@material-ui/core';
import { SaveAlt as SaveIcon } from '@material-ui/icons';

// Services
import { updateConsultingVisit } from 'services/requests';
import { fileUrl } from 'services/axios-config';
import { getFileExtension } from 'services/helpers';
import { consultingVisitStatuses } from 'services/constants';

// Components
import Spinner from 'components/spinner';
import InputText from 'components/input-text';
import InputFile from 'components/input-file';
import DatePicker from 'components/date-picker';
import SelectOutlined from 'components/select-outlined';

// Views
import SelectContactUser from './select-contact-user';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- SET CONSULTING VISIT ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

export default function SetConsultingVisit({ consulting_visit }) {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        status: consulting_visit.status || 'not_processed',
        contact_user: consulting_visit.contactUser ? { label: consulting_visit.contactUser.first_name + ' ' + consulting_visit.contactUser.last_name, value: consulting_visit.contactUser.id } : null,
        contact_date: consulting_visit.contact_date || null,
        contact_method: consulting_visit.contact_method || '',
        observations: consulting_visit.observations || '',
        visit_date_one: consulting_visit.visit_date_one || null,
        first_visit_doc: null,
        first_visit_doc_url: consulting_visit.first_visit_doc_url || null,
        visit_date_two: consulting_visit.visit_date_two || null,
        second_visit_doc: null,
        second_visit_doc_url: consulting_visit.second_visit_doc_url || null,
    });

    function onUpdate() {
        const first_visit_doc = document.getElementById('upload-first-visit-doc')?.files[0];
        const second_visit_doc = document.getElementById('upload-second-visit-doc')?.files[0];
        setState({ ...state, loading: true, first_visit_doc, second_visit_doc });
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('status', state.status);
            formData.append('contact_user_id', state.contact_user ? state.contact_user.value : '');
            formData.append('contact_date', state.contact_date ? moment(state.contact_date).format('YYYY-MM-DD') : '');
            formData.append('contact_method', state.contact_method || '');
            formData.append('observations', state.observations || '');
            formData.append('visit_date_one', state.visit_date_one ? moment(state.visit_date_one).format('YYYY-MM-DD') : '');
            formData.append('visit_date_two', state.visit_date_two ? moment(state.visit_date_two).format('YYYY-MM-DD') : '');

            if (state.first_visit_doc) {
                formData.append('first_visit_doc', state.first_visit_doc);
            }
            if (state.second_visit_doc) {
                formData.append('second_visit_doc', state.second_visit_doc);
            }

            updateConsultingVisit(consulting_visit.id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({
                        ...state,
                        loading: false,
                        first_visit_doc_url: res.data.data.consultingVisit.first_visit_doc_url,
                        second_visit_doc_url: res.data.data.consultingVisit.second_visit_doc_url,
                    });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <>
            <Grid item xs={12} md={10} lg={8}>
                {/* -------------------- STATUS -------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <span>Statut</span>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <SelectOutlined
                            value={state.status}
                            onChange={e => setState({ ...state, status: e.target.value })}
                        >
                            {consultingVisitStatuses.map(status => (
                                <MenuItem key={status.value} value={status.value}>
                                    {status.label}
                                </MenuItem>
                            ))}
                        </SelectOutlined>
                    </Grid>
                </Grid>
                {/* -------------------- CONTACT USER -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        <span>Accompagnateur</span>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <SelectContactUser
                            contactUser={state.contact_user}
                            setContactUser={contact_user => setState({ ...state, contact_user })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- CONTACT DATE -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        <span>Date de premier contact</span>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <DatePicker
                            outlined
                            value={state.contact_date}
                            onChange={contact_date => setState({ ...state, contact_date })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- CONTACT METHOD -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        <span>Méthode de contact</span>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Méthode de contact"
                            value={state.contact_method}
                            onChange={e => setState({ ...state, contact_method: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- OBERSVATIONS -------------------- */}
                <Grid container style={{ marginTop: 10 }}>
                    <InputText
                        label="Observations et commentaires..."
                        multiline
                        value={state.observations}
                        onChange={e => setState({ ...state, observations: e.target.value })}
                    />
                </Grid>
                {/* -------------------- VISIT DATE ONE -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        <span>Date de la première visite</span>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <DatePicker
                            outlined
                            value={state.visit_date_one}
                            onChange={visit_date_one => setState({ ...state, visit_date_one })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- FIRST VISIT DOC URL -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        <span>Rapport de visite 1 en PDF</span>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        { state.visit_date_one ? (
                            <InputFile inputId="first-visit-doc" />
                        ) : (
                            <span>Remplir la date de la première visite pour pouvoir uploader le PDF</span>
                        )}
                    </Grid>
                </Grid>
                {state.first_visit_doc_url && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={false} sm={4} />
                        <Grid item xs={12} sm={8}>
                            <Button
                                onClick={() => saveAs(fileUrl + state.first_visit_doc_url, 'Rapport de visite 1' + getFileExtension(state.first_visit_doc_url))}
                                color="secondary"
                                style={{ textTransform: 'none' }}
                            >
                                Télécharger
                                <SaveIcon style={{ marginLeft: 5 }} />
                            </Button>
                        </Grid>
                    </Grid>    
                )}
                {/* -------------------- VISIT DATE TWO -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        <span>Date de la seconde visite</span>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <DatePicker
                            outlined
                            value={state.visit_date_two}
                            onChange={visit_date_two => setState({ ...state, visit_date_two })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- SECOND VISIT DOC URL -------------------- */}

                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Rapport de visite 2 en PDF</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            { state.visit_date_two ? (
                                <InputFile inputId="second-visit-doc" />
                            ) : (
                                <span>Remplir la date de la seconde visite pour pouvoir uploader le PDF</span>
                            )}
                        </Grid>
                    </Grid>

                {state.second_visit_doc_url && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={false} sm={4} />
                        <Grid item xs={12} sm={8}>
                            <Button
                                onClick={() => saveAs(fileUrl + state.second_visit_doc_url, 'Rapport de visite 2' + getFileExtension(state.second_visit_doc_url))}
                                color="secondary"
                                style={{ textTransform: 'none' }}
                            >
                                Télécharger
                                <SaveIcon style={{ marginLeft: 5 }} />
                            </Button>
                        </Grid>
                    </Grid>    
                )}
            </Grid>
            {/* -------------------- SAVE BUTTON -------------------- */}
            <Grid item xs={12} md={2} lg={4} container alignItems="center" justify="flex-end">
                <Button onClick={onUpdate} variant="contained">
                    Enregistrer
                </Button>
            </Grid>
        </>
    )
}
