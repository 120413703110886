import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Divider, Button } from '@material-ui/core';

// Services
import { billStatuses, paymentMethods } from 'services/constants';
import { getLabelStatus, getColorStatus, getAmountTTC } from 'services/helpers';

/* * *
* Carte bon de commande correspondant à la dernière facture effectuée, visible sur le tableau de bord de l'utilisateur
*/

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- LAST BILL ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const LastBill = ({ state }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const bill = state.last_bill;

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <b>BON DE COMMANDE</b>
            {bill ? (
                <>
                    <Divider style={{ margin: '10px 0px' }} />
                    {/* -------------------- REFERENCE -------------------- */}
                    <p>
                        <b>Référence</b>
                        <br />
                        <b className="secondary-color" style={{ fontSize: 16 }}>
                            {bill.reference}
                        </b>
                    </p>
                    {/* -------------------- AMOUNT -------------------- */}
                    <p>
                        <b>Montant</b>
                        <br />
                        {getAmountTTC(bill.total_amount)}
                    </p>
                    {/* -------------------- ADDRESS -------------------- */}
                    <p>
                        <b>Adresse de facturation</b>
                        <br />
                        {bill.address} {bill.postal_code} {bill.city} {bill.country?.name}
                    </p>
                    {/* -------------------- COMPANY NAME -------------------- */}
                    {bill.company_name && (
                        <p>
                            <b>Nom de l'entreprise</b>
                            <br />
                            {bill.company_name}
                        </p>
                    )}
                    {/* -------------------- PAYMENT METHOD -------------------- */}
                    <p>
                        <b>Mode de paiement</b>
                        <br />
                        {getLabelStatus(paymentMethods, bill.payment_method)}
                        {(bill.payment_method === 'cb' && bill.status === 'payment_waiting') && (
                            <>
                                <br />
                                <Button
                                    onClick={() => history.push(match.url + '/payment-method/' + bill.id)}
                                    variant="contained"
                                    color="primary"
                                >
                                    Procéder au paiement en ligne
                                </Button>
                            </>
                        )}
                        {bill.payment_method === 'check' && (
                            <p>
                                Payable en France à l'ordre du MABD<br />
                                À envoyer au 5 place de la gare 68000 Colmar<br />
                                <b>Notez la référence au dos du chèque</b>
                            </p>
                        )}
                        {bill.payment_method === 'transfer' && (
                            <p>
                                Merci de préciser votre nom et le motif du règlement de lors votre virement :<br />
                                <b>Coordonnées bancaires du MABD :</b><br />
                                Domiciliation CIC Agence de Colmar rue des clefs<br />
                                IBAN : FR76 3008 7332 0000 0195 5320 120 / BIC CMCIFRPP<br />
                                <b>Notez la référence lors du virement</b>
                            </p>
                        )}
                    </p>
                    {/* -------------------- BILL -------------------- */}
                    <p>
                        <b>Statut</b>
                        <br />
                        <b style={{ color: getColorStatus(billStatuses, bill.status) }}>
                            {getLabelStatus(billStatuses, bill.status)?.toUpperCase()}
                        </b>
                    </p>
                    <Divider style={{ margin: '10px 0px' }} />
                    {/* -------------------- HISTORICAL -------------------- */}
                    <Button
                        onClick={() => history.push(`${match.url}/bills`)}
                        variant="contained"
                        style={{ textTransform: 'none' }}
                    >
                        Historique des bons de commande
                    </Button>
                </>
            ) : (
                <>
                    <Divider style={{ margin: '10px 0px' }} />
                    <span>Aucun</span>
                </>
            )}
        </>
    );
};

export default LastBill;
