import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { amountToSend, isValidAmount } from 'services/helpers';
import { addSessionPrice } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// Views
import SessionPriceForm from './session-prices-form';

// Messages
import messages from 'messages.json';

// ------------------------------------------------------------------------------------- \\
// ---------------------------------- ADD SESSION PRICE -------------------------------- \\
// ------------------------------------------------------------------------------------- \\

const AddSessionPrice = ({ onClose, refetch, session }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        price: '',
        tva: '0',
        status: -1,
        require_birth_date: '0',
        require_company_name: '0',
        description: '',
    });

    function onAdd() {
        if (state.price === '' || state.status === -1) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!isValidAmount(state.price)) {
            enqueueSnackbar('Veuillez saisir un montant valide', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            addSessionPrice(session.id, {
                price: amountToSend(state.price),
                tva: amountToSend(state.tva),
                status: state.status,
                require_birth_date: state.require_birth_date,
                require_company_name: state.require_company_name,
                description: state.description,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un prix à la session
                        </p>
                        {/* -------------------- FORM -------------------- */}
                        <SessionPriceForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default AddSessionPrice;
