import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider, GridList, Paper } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Search as SearchIcon } from '@material-ui/icons';

// Services
import { userCanCreateFederatedAssociation } from 'services/permissions';
import { getFederatedAssociations } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Pagination from 'components/pagination';
import Spinner from 'components/spinner';
import Error from 'components/error';
import InputText from 'components/input-text';

// Views
import CreateFederatedAssociation from './create-federated-association';
import FederatedAssociation from './federated-association';

// Assets
import FederatedAssociationIcon from 'assets/images/federated-associations.png';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ROUTER ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FederatedAssociationsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <FederatedAssociations />
            </Route>
            <Route path={`${match.path}/:federatedAssociationId`}>
                <FederatedAssociation />
            </Route>
        </Switch>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------- FEDERATED ASSOCIATIONS ------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const FederatedAssociations = () => {
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        federated_associations: [],
        offset: 0,
        limit: 20,
        total_count: 0,
        antenna: '0',
        searchName: '',
        openCreate: false,
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(label, value) {
        setState({ ...state, loading: true, error: false, offset: 0, [label]: value });
    }

    useEffect(() => {
        if (state.loading) {
            getFederatedAssociations({
                offset: state.offset,
                limit: state.limit,
                antenna: state.antenna,
                name: state.searchName,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        federated_associations: res.data.data.federatedAssociations
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="subheader">
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <ToggleButtonGroup
                        value={state.antenna}
                        onChange={(e, value) => onSelectSearch('antenna', (value == null ? state.antenna : value))}
                        exclusive size="small"
                        className="toggle-button"
                    >
                        <ToggleButton value="0" style={{ width: 120 }}>
                            Associations
                        </ToggleButton>
                        <ToggleButton value="1" style={{ width: 120 }}>
                            Antennes
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                >
                    Associations fédérées
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-end' : 'center'}
                >
                    {userCanCreateFederatedAssociation() && (
                        <Button
                            onClick={() => setState({ ...state, openCreate: true })}
                            variant="contained"
                        >
                            Créer une association fédérée
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div className="input-group" style={{ width: 300 }}>
                    <div className="input-group-prepend" onClick={onSearch} style={{ cursor: 'pointer' }}>
                        <span className="input-group-text">
                            <SearchIcon />
                        </span>
                    </div>
                    <InputText
                        label="Chercher une association..."
                        type="text"
                        value={state.searchName}
                        onChange={e => setState({ ...state, searchName: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            {/* -------------------- LIST CONTAINER -------------------- */}
            <div className="main-container">
                <FederatedAssociationList
                    loading={state.loading}
                    error={state.error}
                    federated_associations={state.federated_associations}
                />
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- LIST CONTAINER -------------------- */}
            {state.openCreate && (
                <CreateFederatedAssociation
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
        </>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------- LIST --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FederatedAssociationList = ({ loading, error, federated_associations }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaXS, mediaSM, mediaMD, mediaLG, mediaXL } = useMediaQueries();
    const [cols, setCols] = useState(6);

    useEffect(() => {
        if (mediaXS) setCols(1);
        if (mediaSM) setCols(2);
        if (mediaMD) setCols(4);
        if (mediaLG) setCols(5);
        if (mediaXL) setCols(6);
    }, [mediaXS, mediaSM, mediaMD, mediaLG, mediaXL]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <div
            className="main-container-item"
            style={{ paddingBottom: 24 }}
        >
            <GridList
                cellHeight={160}
                cols={cols}
                style={{ margin: 0 }}
            >
                {federated_associations.map(federated_association => (
                    <Grid container key={federated_association.id} style={{ padding: 10 }}>
                        <Paper
                            elevation={3}
                            className="paper"
                            onClick={() => history.push(`${match.url}/${federated_association.id}`)}
                            style={{ textAlign: 'center' }}
                        >
                            <img alt="federated-association-icon" src={FederatedAssociationIcon} style={{ height: 40, width: 'auto' }} />
                            <b>{federated_association.name}</b>
                            {federated_association.departments_str}
                        </Paper>
                    </Grid>
                ))}
            </GridList>
        </div>
    );
};

export default FederatedAssociationsRouter;
