import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services
import { createUser } from 'services/requests';

// Components
import Spinner from 'components/spinner';
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';
import InputPhone from 'components/input-phone';
import AddressForm from 'views/common/address-form';

// Selects
import SelectRole from 'selects/select-role';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------- \\
// -------------------------------------- CREATE USER -------------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const CreateUser = ({ onClose }) => {
    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        phone: '',
        mobile_phone: '',
        address: '',
        address_details: '',
        postal_code: '',
        city: '',
        country_id: 1,
        geocode: null,
        role_id: 5,
    });

    function onCreate() {
        if (
            state.first_name === ''
            || state.last_name === ''
            || state.address === ''
            || state.postal_code === ''
            || state.city === ''
            || state.country_id === -1
            || state.role_id === -1
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createUser({
                first_name: state.first_name,
                last_name: state.last_name,
                company_name: state.company_name,
                email: state.email,
                phone: state.phone,
                mobile_phone: state.mobile_phone,
                address: state.address,
                address_details: state.address_details,
                postal_code: state.postal_code,
                city: state.city,
                country_id: state.country_id,
                role_id: state.role_id,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/users/' + res?.data?.data?.user?.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog
            open
            fullWidth
        >
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Créer un utilisateur
                        </p>
                        {/* ---------------------- ROLE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                Type de compte<FieldRequired />
                            </Grid>
                            <Grid item xs={8}>
                                <SelectRole
                                    roleId={state.role_id}
                                    setRoleId={role_id => setState({ ...state, role_id })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- FIRST NAME ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 6 }}
                        >
                            <Grid item xs={4}>
                                Prénom<FieldRequired />
                            </Grid>
                            <Grid item xs={8}>
                                <InputText
                                    label="Prénom"
                                    type="text"
                                    value={state.first_name}
                                    onChange={e => setState({ ...state, first_name: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- LAST NAME ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 6 }}
                        >
                            <Grid item xs={4}>
                                Nom<FieldRequired />
                            </Grid>
                            <Grid item xs={8}>
                                <InputText
                                    label="Nom"
                                    type="text"
                                    value={state.last_name}
                                    onChange={e => setState({ ...state, last_name: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- EMAIL ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 6 }}
                        >
                            <Grid item xs={4}>
                                Email
                            </Grid>
                            <Grid item xs={8}>
                                <InputText
                                    label="Email"
                                    type="email"
                                    value={state.email}
                                    onChange={e => setState({ ...state, email: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- COMPANY NAME ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 6 }}
                        >
                            <Grid item xs={4}>
                                Nom de l'entreprise
                            </Grid>
                            <Grid item xs={8}>
                                <InputText
                                    label="Nom de l'entreprise"
                                    type="text"
                                    value={state.company_name}
                                    onChange={e => setState({ ...state, company_name: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- PHONE ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 6 }}
                        >
                            <Grid item xs={4}>
                                Téléphone fixe
                            </Grid>
                            <Grid item xs={8}>
                                <InputPhone
                                    label="Téléphone fixe"
                                    value={state.phone}
                                    setValue={phone => setState({ ...state, phone })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- MOBILE PHONE ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 6 }}
                        >
                            <Grid item xs={4}>
                                Téléphone portable
                            </Grid>
                            <Grid item xs={8}>
                                <InputPhone
                                    label="Téléphone portable"
                                    value={state.mobile_phone}
                                    setValue={mobile_phone => setState({ ...state, mobile_phone })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- ADDRESS FORM ---------------------- */}
                        <AddressForm
                            state={state}
                            setState={setState}
                            requiredAddress
                            requiredPostalCode
                            requiredCity
                            requiredCountry
                            disabledDepartment
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            Fermer
                        </Button>
                        <Button
                            onClick={onCreate}
                            variant="contained"
                        >
                            Créer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateUser;
