import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Backdrop, CircularProgress } from '@material-ui/core';

// Services
import { getBackUrl, getBillId } from 'services/storage';

/* * *
* Vue appelée après le retour de Monetico et redirige selon le résultat du paiemet
*/

// ----------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ MONETICO CALLBACK ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------------- \\

const MoneticoCallback = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { search } = useLocation();
    const history = useHistory();
    const backUrl = getBackUrl();
    const billId = getBillId();

    useEffect(() => {

        setTimeout(() => {

            let online_payment_success = search.split('online_payment_success=')[1];

            if (online_payment_success === 'true' && billId != null) {
                enqueueSnackbar('Paiement réussi', { variant: 'success' });
                history.push('/dashboard/bills/' + billId);
            }
            else if (online_payment_success === 'true' && billId == null) {
                enqueueSnackbar('La facture correspondant au paiement est introuvable', { variant: 'warning' });
                history.push('/dashboard');
            }
            else {
                enqueueSnackbar('Paiement échoué', { variant: 'error' });
                history.push(backUrl || '/dashboard');
            }

        }, 2000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Backdrop open>
            <CircularProgress style={{ color: 'white' }} />
        </Backdrop>
    );
};

export default MoneticoCallback;
