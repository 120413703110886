import React, { useState, useEffect } from 'react';

// Services
import { refundStatuses } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------- SELECT REFUND STATUSES ----------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectRefundStatuses = ({ statuses, setStatuses }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(refundStatuses.map(s => ({ label: s.label, value: s.value })));
    }, []);

    return (
        <SelectMultiple
            value={statuses}
            onChange={value => setStatuses(value)}
            options={options}
            placeholder="Statut remboursement"
        />
    );
};

export default SelectRefundStatuses;
