import React, { Fragment, useState } from 'react';

// Material-UI
import { Button, Dialog, DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';

// Services
import { getAmountTTC, getFormattedDate, getLabelStatus, getColorStatus } from 'services/helpers';
import { paymentStatuses, paymentMethods } from 'services/constants';

// Views
import UpdatePayment from '../../../payments/payments-update';
import RecoverPayment from '../../../payments/recover-payment';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- PAYMENT DETAILS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const PaymentDetails = ({ onClose, refetch, payments }) => {

    const [state, setState] = useState({
        payment: null,
        openUpdatePayment: false,
        openRecover: false,
    });

    function onRefetch() {
        setState({ ...state, openUpdatePayment: false, openRecover: false });
       refetch();
    }

    return (
        <>
            <Dialog open maxWidth="lg" fullWidth>
                <DialogContent style={{ paddingBottom: 20 }}>
                    <p className="dialog-title">
                        Détails des paiements
                    </p>
                    {/* -------------------- HEADERS -------------------- */}
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                            Montant TTC
                        </Grid>
                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                            Montant recouvré
                        </Grid>
                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                            Date
                        </Grid>
                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                            Mode de paiement
                        </Grid>
                        <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                            Action
                        </Grid>
                    </Grid>
                    {/* -------------------- PAYMENT LIST -------------------- */}
                    {payments.map(payment => (
                        <Fragment key={payment.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    <b style={{ color: getColorStatus(paymentStatuses, payment.status) }}>
                                        {getLabelStatus(paymentStatuses, payment.status)}
                                    </b>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {payment.amount ? getAmountTTC(payment.amount) : '0€'}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {payment.status === 'cb' ? getAmountTTC(payment.recovery) : getAmountTTC(payment.amount)}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {payment.payment_date ? getFormattedDate(payment.payment_date) : ''}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {getLabelStatus(paymentMethods , payment.payment_method)}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                    {payment.payment_method !== 'cb' && (
                                        <Button
                                            onClick={() => setState({ ...state, openUpdatePayment: true, payment })}
                                            variant="contained"
                                            style={{ textTransform: 'none', padding: '4px 12px' }}
                                        >
                                            Modifier
                                        </Button>
                                    )}
                                    {(payment.status === 'received' && payment.payment_method === 'cb') && (
                                        <Button
                                            onClick={() => setState({ ...state, openRecover: true, payment })}
                                            variant="contained"
                                            style={{ marginLeft: 10, textTransform: 'none', padding: '4px 12px' }}
                                        >
                                            Recouvrer
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider /> 
                        </Fragment>
                    ))}
                </DialogContent>
                <Divider />
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={onClose} variant="contained">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            {/* -------------------- UPDATE PAYMENT -------------------- */}
            {state.openUpdatePayment && (
                <UpdatePayment
                    onClose={() => setState({ ...state, openUpdatePayment: false })}
                    refetch={() => onRefetch()}
                    payment={state.payment}
                />
            )}
            {/* -------------------- RECOVER FORMATION PAYMENT -------------------- */}
            {state.openRecover && (
                <RecoverPayment
                    onClose={() => setState({ ...state, openRecover: false })}
                    refetch={() => onRefetch()}
                    payment={state.payment}
                />
            )}
        </>
    );
};

export default PaymentDetails;
