import React, { useState, useEffect, Fragment } from 'react';

// Material-UI
import { Button, Dialog, DialogContent, DialogActions, Divider, Grid, Checkbox } from '@material-ui/core';
import { RemoveCircle as RemoveIcon } from '@material-ui/icons';

// Services
import { getGroups } from 'services/requests';

// Assets
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';

// CSS
import './group-filter.css';

// ----------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- GROUP FILTER -------------------------------------------- \\
// ----------------------------------------------------------------------------------------------------- \\

const GroupFilter = ({ userListIds, setUserListIds }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Tooltip title="Retirer des groupes de la recherche"
                item={(
                    <Button
                        onClick={() => setOpen(true)}
                        variant="contained"
                        style={{ marginLeft: 5 }}
                    >
                        <RemoveIcon />
                    </Button>
                )}
            />
            {open && (
                <GroupModal
                    onClose={() => setOpen(false)}
                    userListIds={userListIds}
                    setUserListIds={setUserListIds}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- GROUP MODAL -------------------------------------------- \\
// ----------------------------------------------------------------------------------------------------- \\

const GroupModal = ({ onClose, userListIds, setUserListIds }) => {

    const [state, setState] = useState({
        loading: true,
        userLists: [],
        selectedIds: [...new Set(userListIds)] || [],
    });

    const onChecked = (checked, userListId) => {
        let selectedIds = state.selectedIds;
        if (checked) {
            selectedIds.push(userListId);
        }
        else {
            let index = selectedIds.findIndex(id => id === userListId);
            selectedIds.splice(index, 1);
        }
        setState({ ...state, selectedIds });
    };

    const onValidate = () => {
        setUserListIds(state.selectedIds);
        onClose();
    };

    useEffect(() => {
        if (state.loading) {
            getGroups({ offset: 0, limit: 100 }).then(res => {
                let userLists = [];
                if (res.status === 200) {
                    userLists = res.data.data.userLists;
                }
                setState({ ...state, loading: false, userLists });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Retirer des groupes d'utilisateurs de la recherche
                        </p>
                        <Grid className="group-title">
                            Groupes
                        </Grid>
                        {state.userLists.map(userList => (
                            <Fragment key={userList.id}>
                                <Grid container alignItems="center" justify="space-between" style={{ padding: 10 }}>
                                    {userList.name}
                                    <Checkbox
                                        checked={state.selectedIds.includes(userList.id)}
                                        onChange={e => onChecked(e.target.checked, userList.id)}
                                    />
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            Fermer
                        </Button>
                        <Button
                            onClick={onValidate}
                            variant="contained"
                        >
                            Valider
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default GroupFilter;
