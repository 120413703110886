import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getConsultingVisits } from 'services/requests';
import { subscriptionStatuses, consultingVisitStatuses } from 'services/constants';
import { getLabelStatus, getFormattedCreatedAt, getFormattedDate } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------- CONSULTING VISITS EXPORT ---------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ConsultingVisitsExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getStringFromArray(array) {
        let result = '';
        array.forEach((item, index) => {
            if (index === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });
        return result;
    }

    function getAgriculturalMethods(consulting_visit) {
        let result = '';
        if (consulting_visit.is_in_conventional) {
            result += 'Conventionnelle';
        }
        if (consulting_visit.is_in_bio) {
            result += 'Biologique';
        }
        if (consulting_visit.is_in_conversion) {
            result += 'En conversion bio';
        }
        return result;
    }

    function getSituations(consulting_visit) {
        let result = '';
        if (consulting_visit.has_followed_biodynamic_training) {
            result += 'J\'ai déjà suivi une formation en biodynamie';
        }
        if (consulting_visit.practice_biodynamics) {
            result += 'Je pratique déjà la biodynamie';
        }
        return result;
    }

    function getNeeds(consulting_visit) {
        let result = '';
        if (consulting_visit.want_visit_from_pro) {
            result += 'Une visite par un professionnel de la biodynamie';
        }
        if (consulting_visit.want_to_be_informed_next_meeting) {
            result += 'Je souhaite être informé de la prochaine journée d’élaboration des préparations qui aura lieu près de chez moi';
        }
        if (consulting_visit.wish_diagnostic) {
            result += 'Faire un tour du domaine pour établir un diagnostic';
        }
        if (consulting_visit.wish_practice_biodynamics) {
            result += 'Mettre en oeuvre une des pratiques de la biodynamie';
        }
        return result;
    }

    function getEquipments(consulting_visit) {
        let result = '';
        if (consulting_visit.has_manual_energizer) {
            result += 'J\'ai un dynamiseur manuel';
        }
        if (consulting_visit.has_mechanical_energizer) {
            result += 'J\'ai un dynamiseur mécanique';
        }
        if (consulting_visit.has_backpack_sprayer) {
            result += 'J\'ai un pulvérisateur à dos';
        }
        if (consulting_visit.has_mechanical_sprayer) {
            result += 'J\'ai un pulvérisateur mécanique';
        }
        return result;
    }

    function getAllConsultingVisitsToExport(consulting_visits, offset) {
        getConsultingVisits({
            offset: offset,
            limit: data.limit,
            statuses: data.statuses ? data.statuses.map(s => s.value) : null,
            membership_statuses: data.membership_statuses ? data.membership_statuses.map(s => s.value) : null,
            client_code: data.client_code,
            external_client_code: data.external_client_code,
            membership_type_ids: data.membership_types ? data.membership_types.map(t => t.value) : null,
            last_name: data.last_name,
            first_name: data.first_name,
            company_name: data.company_name,
            mobile_phone: data.mobile_phone,
            phone: data.phone,
            membership_email: data.membership_email,
            french_department_ids: data.french_departments ? data.french_departments.map(d => d.value) : null,
            cities: data.cities ? data.cities.map(c => c.value) : null,
            request_date: '',
            main_activity_ids: data.main_activities ? data.main_activities.map(a => a.value) : null,
            secondary_activity_ids: data.secondary_activities ? data.secondary_activities.map(a => a.value) : null,
            address: data.address,
            address_details: data.address_details,
            postal_codes: data.postal_codes ? data.postal_codes.map(c => c.value) : null,
            country_ids: data.countries ? data.countries.map(c => c.value) : null,
            agricultural_area: data.agricultural_area,
            agricultural_area_comparator: data.agricultural_area_comparator,
            agricultural_methods: data.agricultural_methods ? data.agricultural_methods.map(m => m.value) : null,
            situations: data.situations ? data.situations.map(s => s.value) : null,
            needs: data.needs ? data.needs.map(n => n.value) : null,
            equipment: data.equipment ? data.equipment.map(e => e.value) : null,
            comments: data.comments,
        }).then(res => {
            if (res.status === 200) {

                let res_consulting_visits = res.data.data.consultingVisits;
                consulting_visits = consulting_visits.concat(res_consulting_visits);

                setState({
                    ...state,
                    percentage: Math.round((100 * consulting_visits.length) / data.total_count) + '%',
                });

                if (res_consulting_visits.length < data.limit) {
                    createExcelFile(consulting_visits);
                }
                else {
                    getAllConsultingVisitsToExport(consulting_visits, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(consulting_visits) {
        if (consulting_visits.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Visite-conseil');

            // Header
            worksheet.columns = [
                { header: 'Statut visite-conseil', key: 'status', width: 30 },
                { header: 'Statut adhésion', key: 'membership_status', width: 30 },
                { header: 'Code client', key: 'client_code', width: 30 },
                { header: 'Code client externe', key: 'external_client_code', width: 30 },
                { header: 'Type d\'adhésion', key: 'membership_type', width: 30 },
                { header: 'Nom', key: 'last_name', width: 30 },
                { header: 'Prénom', key: 'first_name', width: 30 },
                { header: 'Nom de l\'entreprise', key: 'company_name', width: 30 },
                { header: 'Téléphone portable', key: 'mobile_phone', width: 30 },
                { header: 'Téléphone', key: 'phone', width: 30 },
                { header: 'Email adhésion', key: 'membership_email', width: 30 },
                { header: 'Département', key: 'department', width: 30 },
                { header: 'Ville', key: 'city', width: 30 },
                { header: 'Date de la demande', key: 'created_at', width: 30 },
                { header: 'Année de la demande', key: 'request_year', width: 30 },
                { header: 'Année visite 1', key: 'visit_one_year', width: 30 },
                { header: 'Date visite 1', key: 'visit_date_one', width: 30 },
                { header: 'Date visite 2', key: 'visit_date_two', width: 30 },
                { header: 'Rapport 1', key: 'report_first_visit', width: 30 },
                { header: 'Rapport 2', key: 'report_second_visit', width: 30 },
                { header: 'Activité principale', key: 'main_activity', width: 30 },
                { header: 'Activité secondaire', key: 'secondary_activity', width: 30 },
                { header: 'Adresse (n° et voie)', key: 'address', width: 30 },
                { header: 'Adresse (complément)', key: 'address_details', width: 30 },
                { header: 'Code postal', key: 'postal_code', width: 30 },
                { header: 'Pays', key: 'country', width: 30 },
                { header: 'Surface agricole (ha)', key: 'agricultural_area', width: 30 },
                { header: 'Conduite agricole', key: 'agricultural_method', width: 30 },
                { header: 'Situation', key: 'situation', width: 30 },
                { header: 'Besoins pour la visite', key: 'need', width: 30 },
                { header: 'Matériel', key: 'equipment', width: 30 },
                { header: 'Commentaires', key: 'comment', width: 30 },
                { header: 'Accompagnateur', key: 'contact_user', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            consulting_visits.map(consulting_visit => {
                return worksheet.addRow({
                    status: getLabelStatus(consultingVisitStatuses, consulting_visit.status),
                    membership_status: getLabelStatus(subscriptionStatuses, (consulting_visit.membership ? consulting_visit.membership.status : null)),
                    client_code: consulting_visit.user ? consulting_visit.user.clientCode : '',
                    external_client_code: consulting_visit.user ? consulting_visit.user.external_client_code : '',
                    membership_type: (consulting_visit.membership && consulting_visit.membership.membershipType) ? consulting_visit.membership.membershipType.name : '',
                    last_name: consulting_visit.user ? consulting_visit.user.last_name : '',
                    first_name: consulting_visit.user ? consulting_visit.user.first_name : '',
                    company_name: consulting_visit.user ? consulting_visit.user.company_name : '',
                    mobile_phone: consulting_visit.user ? consulting_visit.user.mobile_phone : '',
                    phone: consulting_visit.user ? consulting_visit.user.phone : '',
                    membership_email: consulting_visit.membership ? consulting_visit.membership.email : '',
                    department: (consulting_visit.user && consulting_visit.user.department) ? (consulting_visit.user.department.name + ' (' + consulting_visit.user.department.number + ')') : '',
                    city: consulting_visit.membership ? consulting_visit.membership.city : '',
                    created_at: consulting_visit.created_at ? getFormattedCreatedAt(consulting_visit.created_at) : '',
                    request_year: consulting_visit.created_at ? consulting_visit.created_at.substring(0, 4) : '',
                    visit_one_year: consulting_visit.visit_date_one ? consulting_visit.visit_date_one.substring(0, 4) : '',
                    visit_date_one: consulting_visit.visit_date_one ? getFormattedDate(consulting_visit.visit_date_one) : '',
                    visit_date_two: consulting_visit.visit_date_two ? getFormattedDate(consulting_visit.visit_date_two) : '',
                    report_first_visit: consulting_visit.first_visit_doc_url ? "Oui" : "Non",
                    report_second_visit: consulting_visit.second_visit_doc_url ? "Oui" : "Non",
                    main_activity: consulting_visit.membership && consulting_visit.membership.mainActivity ? consulting_visit.membership.mainActivity.name : '',
                    secondary_activity: consulting_visit.membership && consulting_visit.membership.secondaryActivities ? getStringFromArray(consulting_visit.membership.secondaryActivities) : '',
                    address: consulting_visit.membership ? consulting_visit.membership.address : '',
                    address_details: consulting_visit.membership ? consulting_visit.membership.address_details : '',
                    postal_code: consulting_visit.membership ? consulting_visit.membership.postal_code : '',
                    country: (consulting_visit.membership && consulting_visit.membership.country) ? consulting_visit.membership.country.name : '',
                    agricultural_area: consulting_visit.agricultural_area || '',
                    agricultural_method: getAgriculturalMethods(consulting_visit),
                    situation: getSituations(consulting_visit),
                    need: getNeeds(consulting_visit),
                    equipment: getEquipments(consulting_visit),
                    comment: consulting_visit.comments || '',
                    contact_user: consulting_visit.contactUser ? consulting_visit.contactUser.fullName : '',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'visite-conseil.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllConsultingVisitsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default ConsultingVisitsExport;
