import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { addDocumentToFormationSession } from 'services/requests';

// Components
import Spinner from 'components/spinner';
import InputText from 'components/input-text';
import InputFile from 'components/input-file';
import FieldRequired from 'components/field-required';

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------- ADD FORMATION DOCUMENT ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const AddFormationDocument = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { sessionId } = useParams();

    const [state, setState] = useState({
        loading: false,
        name: '',
        uploaded_document: null,
    });

    function onValidate() {
        const file = document.getElementById('upload-document').files[0];

        if (state.name === '') {
            enqueueSnackbar('Veuillez saisir le nom du document', { variant: 'warning' });
        }
        else if (file == null) {
            enqueueSnackbar('Veuillez sélectionner un fichier', { variant: 'warning' });
        }
        else {
            setState({ ...state, uploaded_document: file, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('name', state.name);
            formData.append('uploaded_document', state.uploaded_document);

            addDocumentToFormationSession(sessionId, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un document
                        </p>
                        {/* -------------------- NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                                Nom<FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputText
                                    label="Nom"
                                    value={state.name}
                                    onChange={e => setState({ ...state, name: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* -------------------- DOCUMENT -------------------- */}
                        <Grid container alignItems="center" style={{ marginTop: 5 }}>
                            <Grid item xs={12} sm={4}>
                                Document<FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputFile
                                    inputId="document"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onValidate} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default AddFormationDocument;
