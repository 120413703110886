import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogActions, DialogContent, Checkbox } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';

// Services
import { getMagazines, getIndexPreferences, updateIndexPreferences } from 'services/requests';
import { userCanCreateMagazine, userCanUpdateMagazine } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';
import { getFormattedDate, getLabelStatus, getColorStatus, getAmountTTC } from 'services/helpers';
import { magazineStatuses, magazinePeriods } from 'services/constants';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Tooltip from 'components/tooltip';
import InputText from 'components/input-text';
import SelectCreatable from 'components/select-creatable';
import Pagination from 'components/pagination';

// Selects
import SelectMagazineStatuses from 'selects/select-magazine-statuses';
import SelectMagazinePeriods from 'selects/select-magazine-periods';

// Views
import CreateMagazine from './magazines-create';
import Magazine from './magazine';
import MagazinesExport from './magazines-export';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ROUTER -------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MagazinesRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Magazines />
            </Route>
            {userCanUpdateMagazine() && (
                <Route path={`${match.path}/:magazineId`}>
                    <Magazine />
                </Route>
            )}
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------ MAGAZINES ------------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const Magazines = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        magazines: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        openCreate: false,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_status: true,
        enabled_number: true,
        enabled_publication_date: true,
        enabled_period: true,
        enabled_name: true,
        enabled_price: true,
        enabled_summary: false,
        enabled_publication_year: false,

        // Filters
        statuses: [],
        numbers: [],
        publication_date: '',
        periods: [],
        name: '',
        price: '',
        summary: '',
        publication_years: [],
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            statuses: [],
            numbers: [],
            publication_date: '',
            periods: [],
            name: '',
            price: '',
            summary: '',
            publication_years: [],
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'magazines',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_status: columns.enabled_status,
                            enabled_number: columns.enabled_number,
                            enabled_publication_date: columns.enabled_publication_date,
                            enabled_period: columns.enabled_period,
                            enabled_name: columns.enabled_name,
                            enabled_price: columns.enabled_price,
                            enabled_summary: columns.enabled_summary,
                            enabled_publication_year: columns.enabled_publication_year,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }
                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            getMagazines({
                offset: state.offset,
                limit: state.limit,
                statuses: state.statuses ? state.statuses.map(s => s.value) : null,
                numbers: state.numbers ? state.numbers.map(n => n.value) : null,
                publication_date: state.publication_date,
                periods: state.periods ? state.periods.map(p => p.value) : null,
                name: state.name,
                price: state.price,
                summary: state.summary,
                publication_years: state.publication_years ? state.publication_years.map(y => y.value) : null,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        magazines: res.data.data.magazines,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Revues
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanCreateMagazine() && (
                        <Button onClick={() => setState({ ...state, openCreate: true })} variant="contained">
                            Ajouter une revue
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <MagazinesExport data={state} />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <MagazineList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
            {/* -------------------- CREATE MAGAZINE -------------------- */}
            {state.openCreate && (
                <CreateMagazine
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    Statut revue
                </div>
            )}
            {/* -------------------- NUMBER -------------------- */}
            {state.enabled_number && (
                <div className="column-list">
                    Numéro revue
                </div>
            )}
            {/* -------------------- PUBLICATION DATE -------------------- */}
            {state.enabled_publication_date && (
                <div className="column-list">
                    Date de publication
                </div>
            )}
            {/* -------------------- PERIOD -------------------- */}
            {state.enabled_period && (
                <div className="column-list">
                    Période
                </div>
            )}
            {/* -------------------- NAME -------------------- */}
            {state.enabled_name && (
                <div className="column-list">
                    Nom de n°
                </div>
            )}
            {/* -------------------- PRICE -------------------- */}
            {state.enabled_price && (
                <div className="column-list">
                    Prix
                </div>
            )}
            {/* -------------------- SUMMARY -------------------- */}
            {state.enabled_summary && (
                <div className="column-list">
                    Sommaire
                </div>
            )}
            {/* -------------------- PUBLICATION YEAR -------------------- */}
            {state.enabled_publication_year && (
                <div className="column-list">
                    Année publication
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    <SelectMagazineStatuses
                        statuses={state.statuses}
                        setStatuses={statuses => onSelectSearch('statuses', statuses)}
                    />
                </div>
            )}
            {/* -------------------- NUMBER -------------------- */}
            {state.enabled_number && (
                <div className="column-list">
                    <SelectCreatable
                        placeholder="Numéro revue"
                        value={state.numbers}
                        onChange={numbers => onSelectSearch('numbers', numbers)}
                    />
                </div>
            )}
            {/* -------------------- PUBLICATION DATE -------------------- */}
            {state.enabled_publication_date && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.publication_date}
                        onChange={e => setState({ ...state, publication_date: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PERIOD -------------------- */}
            {state.enabled_period && (
                <div className="column-list">
                    <SelectMagazinePeriods
                        periods={state.periods}
                        setPeriods={periods => onSelectSearch('periods', periods)}
                    />
                </div>
            )}
            {/* -------------------- NAME -------------------- */}
            {state.enabled_name && (
                <div className="column-list">
                   <InputText
                        label="Nom du n°"
                        value={state.name}
                        onChange={e => setState({ ...state, name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PRICE -------------------- */}
            {state.enabled_price && (
                <div className="column-list">
                   <InputText
                        label="Prix"
                        value={state.price}
                        onChange={e => setState({ ...state, price: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- SUMMARY -------------------- */}
            {state.enabled_summary && (
                <div className="column-list">
                    <InputText
                        label="Sommaire"
                        value={state.summary}
                        onChange={e => setState({ ...state, summary: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PUBLICATION YEAR -------------------- */}
            {state.enabled_publication_year && (
                <div className="column-list">
                    <SelectCreatable
                        placeholder="Année publication"
                        value={state.publication_years}
                        onChange={publication_years => onSelectSearch('publication_years', publication_years)}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- MAGAZINE LIST --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MagazineList = ({ state }) => {
    const match = useRouteMatch();
    const history = useHistory();

    function getPeriodLabel(period) {
        const foundedPeriod = magazinePeriods.find(p => p.value === period);
        if (foundedPeriod) {
            return foundedPeriod.label;
        }
        return '';
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.magazines.length > 0 && (
                state.magazines.map(magazine => (
                    <Fragment key={magazine.id}>
                        <div
                            onClick={userCanUpdateMagazine() ? () => history.push(`${match.url}/${magazine.id}`) : null}
                            className={userCanUpdateMagazine() ? 'simple-list' : null}
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- STATUS -------------------- */}
                            {state.enabled_status && (
                                <div className="column-list">
                                    <b style={{ color: getColorStatus(magazineStatuses, magazine.status) }}>
                                        {getLabelStatus(magazineStatuses, magazine.status)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- NUMBER -------------------- */}
                            {state.enabled_number && (
                                <div className="column-list">
                                    {magazine.number}
                                </div>
                            )}
                            {/* -------------------- PUBLICATION DATE -------------------- */}
                            {state.enabled_publication_date && (
                                <div className="column-list">
                                    {magazine.publication_date ? getFormattedDate(magazine.publication_date) : ''}
                                </div>
                            )}
                            {/* -------------------- PERIOD -------------------- */}
                            {state.enabled_period && (
                                <div className="column-list">
                                    {getPeriodLabel(magazine.period)}   
                                </div>
                            )}
                            {/* -------------------- NAME -------------------- */}
                            {state.enabled_name && (
                                <div className="column-list">
                                    {magazine.name}
                                </div>
                            )}
                            {/* -------------------- PRICE -------------------- */}
                            {state.enabled_price && (
                                <div className="column-list">
                                    {getAmountTTC(magazine.price)}
                                </div>
                            )}
                            {/* -------------------- SUMMARY -------------------- */}
                            {state.enabled_summary && (
                                <div className="column-list">
                                    {magazine.description}
                                </div>
                            )}
                            {/* -------------------- PUBLICATION YEAR -------------------- */}
                            {state.enabled_publication_year && (
                                <div className="column-list">
                                    {magazine.publication_date ? magazine.publication_date.split('-')[0] : ''}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_status: state.enabled_status,
            enabled_number: state.enabled_number,
            enabled_publication_date: state.enabled_publication_date,
            enabled_period: state.enabled_period,
            enabled_name: state.enabled_name,
            enabled_price: state.enabled_price,
            enabled_summary: state.enabled_summary,
            enabled_publication_year: state.enabled_publication_year,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'magazines',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                {/* -------------------- STATUS -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_status}
                        onChange={e => setState({ ...state, enabled_status: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Statut revue
                </Grid>
                {/* -------------------- NUMBER -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_number}
                        onChange={e => setState({ ...state, enabled_number: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Numéro revue
                </Grid>
                {/* -------------------- PUBLICATION DATE -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_publication_date}
                        onChange={e => setState({ ...state, enabled_publication_date: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Date de publication
                </Grid>
                {/* -------------------- PERIOD -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_period}
                        onChange={e => setState({ ...state, enabled_period: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Période
                </Grid>
                {/* -------------------- NAME -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_name}
                        onChange={e => setState({ ...state, enabled_name: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Nom du n°
                </Grid>
                {/* -------------------- PRICE -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_price}
                        onChange={e => setState({ ...state, enabled_price: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Prix
                </Grid>
                {/* -------------------- SUMMARY -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_summary}
                        onChange={e => setState({ ...state, enabled_summary: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Sommaire
                </Grid>
                {/* -------------------- PUBLICATION YEAR -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_publication_year}
                        onChange={e => setState({ ...state, enabled_publication_year: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Année publication
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MagazinesRouter;
