import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button, Paper } from '@material-ui/core';

// Services
import { getFormattedDate } from 'services/helpers';

// Components
import DeleteConsultingVisit from 'views/management/consulting-visits/delete-consulting-visit';

/* * *
* Carte visite-Conseil
*/

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------ CONSULTING VISITS --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const ConsultingVisit = ({ state, setState }) => {
    const match = useRouteMatch();
    const history = useHistory();

    function userHasProMembership() {
        if ((state.last_membership != null && state.last_membership.membershipType?.is_pro === 1)
            || state.consulting_visit != null
        ) {
            return true;
        }
        return false;
    }

    return (
        <Paper
            elevation={3}
            style={{
                padding: 10,
                textAlign: 'center',
                minHeight: 230,
                backgroundColor: userHasProMembership() ? null : 'lightgray',
                opacity: userHasProMembership() ? null : 0.5,
            }}
        >
            {/* -------------------- TITLE -------------------- */}
            <Grid container justify="center">
                <b>VISITE-CONSEIL</b>
            </Grid>
            <Divider style={{ margin: '10px 0px' }} />
            {(state.consulting_visit == null && (state.last_membership == null || (state.last_membership != null && state.last_membership.membershipType?.is_pro === 0))) && (
                <Grid>
                    Disponible aux adhérents possédant une adhésion professionnelle
                </Grid>
            )}
            {(state.consulting_visit == null && state.last_membership != null && state.last_membership.membershipType?.is_pro === 1) && (
                <Grid container justify="center">
                    <Button
                        onClick={() => history.push(`${match.url}/consulting-visit/${state.user.id}`)}
                        variant="contained"
                        color="primary"
                    >
                        Demande de visite-conseil
                    </Button>
                </Grid>
            )}
            {(state.consulting_visit != null && state.consulting_visit.visit_date_one == null) && (
                <>
                    <Grid container justify="center">
                        Une demande de visite-conseil est en cours ({getFormattedDate(state.consulting_visit.created_at.split('T')[0])})
                        <Button
                            onClick={() => setState({ ...state, openDeleteConsultingVisit: true })}
                            variant="contained"
                            color="secondary"
                            style={{ textTransform: 'none', marginTop: 5 }}
                        >
                            Annuler la demande
                        </Button>
                    </Grid>
                    {state.openDeleteConsultingVisit && (
                        <DeleteConsultingVisit
                            onClose={() => setState({ ...state, openDeleteConsultingVisit: false })}
                            refetch={() => setState({ ...state, openDeleteConsultingVisit: false, consulting_visit: undefined })}
                            consulting_visit={state.consulting_visit}
                        />
                    )}
                </>
            )}
            {(state.consulting_visit != null && state.consulting_visit.visit_date_one != null) && (
                <Grid container justify="center">
                    Date de la première visite : {getFormattedDate(state.consulting_visit.visit_date_one)}
                    <br />
                    {state.consulting_visit.visit_date_two ? ('Date de la seconde visite ' + getFormattedDate(state.consulting_visit.visit_date_two)) : ''}
                </Grid>
            )}
        </Paper>
    );
};

export default ConsultingVisit;
