import React, { useState, useEffect, Fragment } from 'react';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Divider, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { getUserSubscriptions } from 'services/requests';
import { userCanHandleSubscriptionForUser } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import { subscriptionStatuses } from 'services/constants';
import { getFormattedDate, getLabelStatus, getColorStatus } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import RenewSubscription from 'views/common/renew/renew-subscription-modal';
import UpdateSubscription from 'views/management/subscriptions/update-subscription';
import StopSubscription from 'views/management/subscriptions/stop-subscription';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SUBSCRIPTIONS ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Subscriptions = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { userId } = useParams();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        subscriptions: [],

        // Modal
        openModal: false,
        openRenew: false,

        // Update
        openUpdate: false,
        subscription: null,

        // Stop
        openStop: false,

        // Filters
        status: '',
        subscription_type_id: -1,
        payment_method: '',
        federated_association_id: -1,
        first_name: '',
        last_name: '',
    });

    useEffect(() => {
        if (state.loading) {
            getUserSubscriptions(userId).then(res => {
                if (res.status === 200) {
                    const subscriptions = res.data.data.subscriptions;
                    setState({ ...state, loading: false, subscriptions, total_count: subscriptions.length });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div
            className="light-background-color"
            style={{ padding: '0px 24px 24px 24px' }}
        >
            <div style={{ padding: 12, backgroundColor: 'white' }}>
                {/* -------------------- TOOLBAR -------------------- */}
                <Grid container justify="space-between" alignItems="center">
                    <b>ABONNEMENTS</b>
                    <b>
                        {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                    </b>
                </Grid>
                {/* -------------------- SUBSCRIPTION TYPES -------------------- */}
                {userCanHandleSubscriptionForUser() && (
                    <Grid
                        container alignItems="center" justify="flex-end"
                        style={{ padding: '12px 0px' }}
                    >
                        <Button
                            onClick={() => history.push(`${match.url}/shop/subscriptions`)}
                            variant="contained"
                            style={{ textTransform: 'none' }}
                        >
                            Ajouter un abonnement
                        </Button>
                    </Grid>
                )}
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid
                        container alignItems="center"
                        className="subtitle-primary-color"
                        style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
                    >
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={1} style={{ padding: 10 }}>
                            N°abo
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Période de validité
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Type d'abonnement
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Adresse Abo
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- SUBSCRIPTION LIST -------------------- */}
                <SubscriptionList
                    state={state}
                    setState={setState}
                />
            </div>
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- SUBSCRIPTION LIST -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SubscriptionList = ({ state, setState }) => {

    const getAddress = (subscription) => {
        let array = [];
        if (subscription?.address) {
            array.push(subscription.address);
        }
        if (subscription?.postal_code) {
            array.push(subscription.postal_code);
        }
        if (subscription?.city) {
            array.push(subscription.city);
        }
        if (subscription?.country) {
            array.push(subscription.country?.name);
        }
        return array.join(' ');
    };

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.subscriptions.length > 0 ? (
                state.subscriptions.map(subscription => (
                    <Fragment key={subscription.id}>
                        <Grid
                            onClick={() => setState({ ...state, openModal: true, subscription })}
                            container alignItems="center"
                            className="simple-list"
                        >
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                <span style={{ color: getColorStatus(subscriptionStatuses, subscription.status) }}>
                                    {getLabelStatus(subscriptionStatuses, subscription.status)}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={1} style={{ padding: 10 }}>
                                #{subscription.id}
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                {getFormattedDate(subscription.start_date)} - {getFormattedDate(subscription.end_date)}
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                {subscription.subscriptionType ? subscription.subscriptionType.name : ''}
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                <span>
                                    {getAddress(subscription)}
                                </span>
                                {subscription.address_details && (
                                    <>
                                        <br />
                                        <span className="text">
                                            {subscription.address_details}
                                        </span>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <div style={{ padding: 10 }}>
                        Aucun
                    </div>
                    <Divider />
                </>
            )}
            {/* -------------------- MODAL -------------------- */}
            {state.openModal && (
                <SubscriptionModal
                    state={state}
                    setState={setState}
                />
            )}
            {/* -------------------- UPDATE -------------------- */}
            {state.openUpdate && (
                <UpdateSubscription
                    subscription={state.subscription}
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                />
            )}
            {/* -------------------- RENEW -------------------- */}
            {state.openRenew && (
                <RenewSubscription
                    subscription={state.subscription}
                    onClose={() => setState({ ...state, openRenew: false })}
                />
            )}
            {/* -------------------- STOP SUBSCRIPTION -------------------- */}
            {state.openStop && (
                <StopSubscription
                    subscription={state.subscription}
                    onClose={() => setState({ ...state, openStop: false })}
                    refetch={() => setState({ ...state, openStop: false, loading: true })}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- SUBSCRIPTION MODAL ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SubscriptionModal = ({ state, setState }) => {
    const storedUser = getStoredUser();

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20, display: 'flex' }}>
                <div
                    onClick={() => setState({ ...state, openUpdate: true, openModal: false })}
                    className="subscription-links"
                >
                    Modifier l'abonnement
                </div>
                {(
                    storedUser?.role?.id < 4
                    && (
                        state.subscription?.status === 'validated'
                        || state.subscription?.status === 'expired'
                        || state.subscription?.status === 'stopped'
                    )
                ) && (
                    <div
                        onClick={() => setState({ ...state, openStop: true, openModal: false })}
                        className="subscription-links"
                        style={{ marginLeft: 24 }}
                    >
                        {(
                            state.subscription?.status === 'validated'
                            || state.subscription?.status === 'expired'
                        ) && (
                            "Stopper l'abonnement"
                        )}
                        {state.subscription?.status === 'stopped' && (
                            "Reprendre l'abonnement"
                        )}
                    </div>
                )}
                {(
                    state.subscription.newSubscription == null
                    && (
                        state.subscription.status === 'validated'
                        || state.subscription.status === 'expired'
                    )
                    && (
                        Number(state.subscription.end_date?.substring(0, 4)) === moment().year()
                        || Number(state.subscription.end_date?.substring(0, 4)) === moment().year() - 1
                        || Number(state.subscription.end_date?.substring(0, 4)) === moment().year() + 1
                    )
                ) && (
                    <div
                        onClick={() => setState({ ...state, openRenew: true, openModal: false })}
                        className="subscription-links"
                        style={{ marginLeft: 24 }}
                    >
                        Renouveler l'abonnement
                    </div>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    onClick={() => setState({ ...state, openModal: false })}
                    variant="contained"
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Subscriptions;
