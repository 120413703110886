import React, { useState, Fragment } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getFormattedDate, getFormattedTime, getSessionDayAddress } from 'services/helpers';
import useMediaQueries from 'services/media';

// Views
import AddSessionDay from './session-days-add';

// ----------------------------------------------------------------------------------- \\
// ----------------------------------- SESSION DAYS ---------------------------------- \\
// ----------------------------------------------------------------------------------- \\

const SessionDays = ({ refetch, session }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        session_days: session.sessionDays,
        session_day: null,
        total_count: session.sessionDays.length,
        openAdd: false,
    });

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid
                container justify="space-between" alignItems="center"
                className="toolbar"
            >
                <b>JOURNÉES DE FORMATION</b>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            {/* -------------------- ADD SESSION DAY -------------------- */}
            <Grid
                container justify="flex-end"
                style={{ padding: '0px 24px' }}
            >
                <Button
                    onClick={() => setState({ ...state, openAdd: true })}
                    variant="contained"
                    style={{ textTransform: 'none' }}
                >
                    Ajouter une journée de formation
                </Button>
            </Grid>
            <Grid style={{ padding: 24 }}>
                {/* -------------------- HEADER LIST -------------------- */}
                <Grid
                    container alignItems="center"
                    className="list-headers"
                >
                    {mediaMD ? (
                        <>
                            <Grid item xs={3} style={{ padding: 10 }}>
                                Date
                            </Grid>
                            <Grid item xs={3} style={{ padding: 10 }}>
                                Adresse
                            </Grid>
                            <Grid item xs={3} style={{ padding: 10 }}>
                                Horaires
                            </Grid>
                            <Grid item xs={3} style={{ padding: 10 }}>
                                Lieu
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ padding: 10 }}>
                            Options
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- SESSION DAY LIST -------------------- */}
                {state.session_days.length > 0 ? (
                    state.session_days.map(session_day => (
                        <Fragment key={session_day.id}>
                            <Grid
                                onClick={() => history.push(`${match.url}/day/${session_day.id}`)}
                                container alignItems="center"
                                className="simple-list"
                            >
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {getFormattedDate(session_day.session_date)}
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {getSessionDayAddress(session_day)}
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {session_day.dayPrograms
                                        .map(dp => getFormattedTime(dp.start_time)  + ' - ' + getFormattedTime(dp.end_time))
                                        .join(' / ')
                                    }
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {session_day.trainingPlace?.name}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <div style={{ padding: 10 }}>
                            Aucune
                        </div>
                        <Divider />
                    </>
                )}
            </Grid>
            {/* -------------------- ADD SESSION DAY -------------------- */}
            {state.openAdd && (
                <AddSessionDay
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={() => refetch()}
                    session={session}
                />
            )}
        </>
    );
};

export default SessionDays;
