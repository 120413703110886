import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getOnlinePaymentsForFormationSession } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- ONLINE PAYMENTS ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const OnlinePayments = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { sessionId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formationRequests: [],
    });

    useEffect(() => {
        if (state.loading) {
            getOnlinePaymentsForFormationSession(sessionId).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        formationRequests: res.data.data.formationRequests,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, error: true });
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Liste des paiements en ligne
                </Grid>
            </Grid>
            <div className="main-container" style={{ marginTop: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                <Grid container alignItems="center" className="list-headers">
                    <Grid item xs={5} style={{ padding: 10 }}>
                        Nom
                    </Grid>
                    <Grid item xs={5} style={{ padding: 10 }}>
                        Document
                    </Grid>
                    <Grid item xs={2} style={{ padding: 10, textAlign: 'center' }}>
                        Supprimer
                    </Grid>
                </Grid>
                {/* -------------------- LIST -------------------- */}
                <OnlinePaymentList
                    state={state}
                    setState={setState}
                />
            </div>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- ONLINE PAYMENT LIST ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const OnlinePaymentList = ({ state, setState }) => {

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <div className="main-container-item">
            {state.formationRequests.length > 0 ? (
                state.formationRequests.map(formationRequest => (
                    <Fragment key={formationRequest.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={5} style={{ padding: 6 }}>

                            </Grid>
                            <Grid item xs={5} style={{ padding: 6 }}>

                            </Grid>
                            <Grid item xs={2} style={{ padding: 6, textAlign: 'center' }}>

                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    );
};

export default OnlinePayments;
