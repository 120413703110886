import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- CONTACT FORM --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

export default function ContactForm({ state, setState }) {
    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Nom</span><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom"
                        type="text"
                        value={state.name}
                        onChange={e => setState({ ...state, name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- TYPE -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Fonction ou rôle</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Fonction ou rôle"
                        type="text"
                        value={state.type}
                        onChange={e => setState({ ...state, type: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- EMAIL -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Email</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Email"
                        type="email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- PHONE -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Téléphone</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Téléphone"
                        type="tel"
                        value={state.phone}
                        onChange={e => setState({ ...state, phone: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- DESCRIPTION -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <span>Description</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Description"
                        multiline
                        value={state.description}
                        onChange={e => setState({ ...state, description: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    );
}
