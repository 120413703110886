import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';

// Services
import { addContactToFederationAssociation } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// Messages
import messages from 'messages.json';

// Views
import ContactForm from '../contact-form';

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------------- ADD CONTACT ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

export default function AddContact({ onClose, refetch }) {
    const { enqueueSnackbar } = useSnackbar();
    const { federatedAssociationId } = useParams();

    const [state, setState] = useState({
        loading: false,
        name: '',
        type: '',
        email: '',
        phone: '',
        description: '',
    });

    function onAdd() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            addContactToFederationAssociation(federatedAssociationId, {
                name: state.name,
                type: state.type,
                email: state.email,
                phone: state.phone,
                description: state.description,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.contact);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un contact
                        </p>
                        <ContactForm state={state} setState={setState} />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
