import React, { useState, useEffect } from 'react';
import Select from 'components/react-select';
import { getFederatedAssociationList } from 'services/requests';

// Column Width
let columnWidth = 220;

// -------------------- HEADER -------------------- \\
const FederatedAssociationHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Association fédérée
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const FederatedAssociationFilter = ({ state, onSelectSearch }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getFederatedAssociationList().then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.federatedAssociations.map(d => ({ label: (d.name + ' (' + d.number + ')'), value: d.id })));
            }
        });
    }, []);

    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <Select
                placeholder="Association fédérée"
                value={state.federated_associations}
                onChange={federated_associations => onSelectSearch('federated_associations', federated_associations)}
                options={options}
                isMulti
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const FederatedAssociationValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { FederatedAssociationHeader, FederatedAssociationFilter, FederatedAssociationValue };
