import React from 'react';

// File-Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Button, Grid } from '@material-ui/core';
import { SaveAlt as SaveIcon } from '@material-ui/icons';

// Services
import { userCanUpdateBill } from 'services/permissions';
import { fileUrl } from 'services/axios-config';
import { getFileExtension } from 'services/helpers';

// Views
import UpdateExternalBill from './update-external-bill';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------ EXTERNAL BILL ----------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const ExternalBill = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container justify="space-between"
                style={{ marginTop: 50 }}
            >
                <Grid>
                    <span style={{ fontWeight: 600 }}>
                        Référence externe
                    </span>
                    <br />
                    <span>
                        {state.bill?.external_reference ? (
                            state.bill.external_reference
                        ) : (
                            'Aucune'
                        )}
                    </span>
                    {state.bill.document_url && (
                        <>
                            <br />
                            <Button
                                onClick={() => saveAs(fileUrl + state.bill.document_url, 'Facture_BC' + state.bill.reference + getFileExtension(state.bill.document_url))}
                                style={{ textTransform: 'none', padding: '0px 2px' }}
                                color="secondary"
                            >
                                <span>
                                    Télécharger Facture PDF
                                </span>
                                <SaveIcon style={{ marginLeft: 6 }} />
                            </Button>
                        </>
                    )}
                    {state.bill.credit_document_url && (
                        <>
                            <br />
                            <Button
                                onClick={() => saveAs(fileUrl + state.bill.credit_document_url, 'Avoir_BC' + state.bill.reference + getFileExtension(state.bill.credit_document_url))}
                                style={{ textTransform: 'none', padding: '0px 2px' }}
                                color="secondary"
                            >
                                <span>
                                    Télécharger Avoir PDF
                                </span>
                                <SaveIcon style={{ marginLeft: 6 }} />
                            </Button>
                        </>
                    )}
                </Grid>
                <Grid>
                    {userCanUpdateBill(state.bill.user.federatedAssociation) && (
                        <Button
                            onClick={() => setState({ ...state, openExternal: true })}
                            variant="contained"
                            style={{ textTransform: 'none' }}
                        >
                            Ajouter une référence externe
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- UPDATE EXTERNAL BILL -------------------- */}
            {state.openExternal && (
                <UpdateExternalBill
                    bill={state.bill}
                    onClose={() => setState({ ...state, openExternal: false })}
                    refetch={() => setState({ ...state, openExternal: false, loading: true })}
                />
            )}
        </>
    );
};

export default ExternalBill;
