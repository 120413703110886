import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getDomain } from 'services/requests';
import { userCanUpdateDomain } from 'services/permissions';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import PageNotFound from 'components/page-not-found';

// Views
import Subdomains from './subdomains';
import Users from './users';
import Managers from './managers';
import Contacts from './contacts';
import UpdateDomain from '../domains-update';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ROUTER -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const DomainRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Domain />
            </Route>
            {userCanUpdateDomain() && (
                <Route exact path={`${match.path}/update`}>
                    <UpdateDomain />
                </Route>
            )}
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- DOMAIN --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Domain = () => {
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const match = useRouteMatch();
    const domainId = match.params.domainId;

    const [state, setState] = useState({
        loading: true,
        error: false,
        domain: null,
    });

    useEffect(() => {
        if (state.loading) {
            getDomain(domainId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, domain: res.data.data.domain });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button
                        onClick={() => history.push('/domains')}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    <div style={{ textAlign: 'center' }}>
                        <b>{state.domain && state.domain.name}</b>
                        {state.domain.root_domain_id != null && (
                            <>
                                <br />
                                Sous-domaine du {state.domain.rootDomain.name}
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanUpdateDomain() && (
                        <Button
                            onClick={() => history.push(`${match.url}/update`)}
                            variant="contained"
                        >
                            Modifier
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- MENU -------------------- */}
            <Menu domain={state.domain} />
        </>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------ MENU ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Menu = ({ domain }) => {
    const [menu, setMenu] = useState(domain.root_domain_id == null ? 1 : 2);

    function handleMenuSelected(selectedMenu) {
        if (selectedMenu === menu) {
            return 'domains-menu-item domains-menu-item-selected';
        }
        return 'domains-menu-item';
    }

    return (
        <Grid container style={{ padding: 24, flex: 1 }}>
            <Grid container style={{ border: '1px solid lightgray', display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Grid container className="domains-menu">
                    {domain.root_domain_id == null && (
                        <Grid
                            item xs="auto"
                            className={handleMenuSelected(1)}
                            onClick={() => setMenu(1)}
                        >
                            Sous-domaines
                        </Grid>
                    )}
                    <Grid
                        item xs="auto"
                        className={handleMenuSelected(2)}
                        onClick={() => setMenu(2)}
                    >
                        Membres
                    </Grid>
                    <Grid
                        item xs="auto"
                        className={handleMenuSelected(3)}
                        onClick={() => setMenu(3)}
                    >
                        Gestionnaires
                    </Grid>
                    <Grid
                        item xs="auto"
                        className={handleMenuSelected(4)}
                        onClick={() => setMenu(4)}
                    >
                        Contacts
                    </Grid>
                </Grid>
                {/* -------------------- CONTACT -------------------- */}
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {(domain.root_domain_id == null && menu === 1) && <Subdomains subdomains={domain.domains} />}
                    {menu === 2 && <Users users={domain.users} />}
                    {menu === 3 && <Managers managers={domain.managers} />}
                    {menu === 4 && <Contacts contacts={domain.contacts} />}
                </div>
            </Grid>
        </Grid>
    );
};

export default DomainRouter;
