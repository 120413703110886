import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 160;

// -------------------- HEADER -------------------- \\
const MobilePhoneHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Téléphone portable
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const MobilePhoneFilter = ({ state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label="Téléphone portable"
                value={state.mobile_phone}
                onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const MobilePhoneValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { MobilePhoneHeader, MobilePhoneFilter, MobilePhoneValue };
