import React, { useState, useEffect } from 'react';

// File-Saver
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, CircularProgress } from '@material-ui/core';
import { GetApp as DownloadIcon } from '@material-ui/icons';

// Services
import { downloadTrainingCertificate } from 'services/requests';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------- DOWNLOAD TRAINING CERTIFICATE ------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const DownloadTrainingCertificate = ({ formationRequest }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const onClick = event => {
        event.stopPropagation();
        setLoading(true);
    };
    
    useEffect(() => {
        if (loading) {
            downloadTrainingCertificate(formationRequest.id).then(res => {
                if (res.status === 200) {
                    saveAs(res.data, ('Attestation de formation - ' + formationRequest.user?.fullName + '.pdf'));
                }
                else {
                    console.log(res);
                    enqueueSnackbar(res, { variant: 'error' });
                }
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <>
            {formationRequest.training_certificate_url != null && (
                <Button
                    onClick={e => onClick(e)}
                    disabled={loading}
                    variant="contained" color="inherit"
                    style={{ textTransform: 'none' }}
                >
                    Télécharger
                    {loading ? (
                        <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
                    ) : (
                        <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                    )}
                </Button>
            )}
        </>
    );
};

export default DownloadTrainingCertificate;
