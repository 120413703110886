import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Dialog, DialogActions, DialogContent, Divider, Grid } from '@material-ui/core';

// Services
import { recoverPayment } from 'services/requests';
import { isValidAmount, amountToSend, amountToBeReceived } from 'services/helpers';

// Components
import InputText from 'components/input-text';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- RECOVER PAYMENT --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const RecoverPayment = ({ onClose, refetch, payment }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        amount: amountToBeReceived(payment.amount),
    });

    function onValidate() {
        if (!isValidAmount(state.amount)) {
            enqueueSnackbar('Veuillez saisir le montant du recouvrement', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            recoverPayment(payment.id, {
                amount: amountToSend(state.amount),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Recouvrer le paiement
                </p>
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                        Montant du recouvrement (€)
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputText
                            label="Montant (€)"
                            type="number"
                            value={state.amount}
                            onChange={e => setState({ ...state, amount: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Annuler
                </Button>
                <Button onClick={onValidate} variant="contained">
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RecoverPayment;
