import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Checkbox } from '@material-ui/core';
import { SaveAlt as DonwloadIcon } from '@material-ui/icons';

// Services
import { fileUrl } from 'services/axios-config';
import { updateFederatedAssociation } from 'services/requests';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import FederatedAssociationForm from '../../federated-association-form';

// Messages
import messages from 'messages.json';

// -------------------------------------------------------------------------------------- \\
// ----------------------------- SET FEDERATED ASSOCIATION ------------------------------ \\
// -------------------------------------------------------------------------------------- \\

export default function SetFederatedAssociation({ federated_association }) {
    const { enqueueSnackbar } = useSnackbar();
    const { federatedAssociationId } = useParams();

    const [state, setState] = useState({
        loading: false,
        error: false,
        name: federated_association.name || '',
        type: federated_association.type || -1,
        uploaded_file: null,
        documentation_url: federated_association.documentation_url,
        remove_documentation: false,
        address: federated_association.address || '',
        city: federated_association.city || '',
        postal_code: federated_association.postal_code || '',
        country_id: federated_association.country_id || -1,
    });

    function onSave() {
        if (state.name === '' || state.type === -1) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-file').files[0];

            if (file) {
                setState({ ...state, loading: true, uploaded_file: file });
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    }

    useEffect(() => {
        if (state.loading) {
 
            const formData = new FormData();
            formData.append('name', state.name);
            formData.append('type', state.type);
            formData.append('remove_documentation', state.remove_documentation ? '1' : '0');
            formData.append('address', state.address);
            formData.append('postal_code', state.postal_code);
            formData.append('city', state.city);
            formData.append('country_id', state.country_id);

            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }

            updateFederatedAssociation(federatedAssociationId, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({
                        ...state,
                        loading: false,
                        documentation_url: res.data.data.federatedAssociation.documentation_url,
                        remove_documentation: false,
                    });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);
    
    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <Grid item xs={12} md={10} lg={8}>
                {/* -------------------- FORM -------------------- */}
                <FederatedAssociationForm
                    state={state}
                    setState={setState}
                />
                {/* -------------------- DOCUMENT -------------------- */}
                {state.documentation_url != null && (
                    <Grid container alignItems="center" style={{ marginTop: 5 }}>
                        <Grid item xs={12} sm={4}>
                            <span>Document actuel</span>
                        </Grid>
                        <Grid item xs={12} sm={8} container alignItems="center">
                            <Button
                                variant="contained"
                                color="secondary"
                                target="_blank"
                                style={{ textTransform: 'none' }}
                                href={fileUrl + state.documentation_url}
                            >
                                Télécharger
                                <DonwloadIcon style={{ marginLeft: 5 }} />
                            </Button>
                            <Checkbox
                                style={{ padding: 6, marginLeft: 5 }}
                                checked={state.remove_documentation}
                                onChange={e => setState({ ...state, remove_documentation: e.target.checked })}
                            />
                            Supprimer
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {/* -------------------- SAVE BUTTON -------------------- */}
            <Grid item xs={12} md={2} lg={4} container alignItems="center" justify="flex-end">
                <Button onClick={onSave} variant="contained">
                    Enregistrer
                </Button>
            </Grid>
        </>
    );
}
