import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 260;

// -------------------- HEADER -------------------- \\
const EmailHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Email compte
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const EmailFilter = ({ state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label="Email compte"
                value={state.email}
                onChange={e => setState({ ...state, email: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const EmailValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { EmailHeader, EmailFilter, EmailValue };
