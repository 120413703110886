import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 120;

// -------------------- HEADER -------------------- \\
const ClientCodeHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Code client
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const ClientCodeFilter = ({ state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label="Code client"
                value={state.client_code}
                onChange={e => setState({ ...state, client_code: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const ClientCodeValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { ClientCodeHeader, ClientCodeFilter, ClientCodeValue };
