import React, { useState, Fragment } from 'react';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { sessionPriceStatuses } from 'services/constants';
import { getLabelStatus, getAmountTTC, getTVA } from 'services/helpers';
import useMediaQueries from 'services/media';

// Views
import AddSessionPrice from './session-prices-add';
import UpdateSessionPrice from './session-prices-update';

// ------------------------------------------------------------------------------------- \\
// ----------------------------------- SESSION PRICES ---------------------------------- \\
// ------------------------------------------------------------------------------------- \\

const SessionPrices = ({ refetch, session }) => {
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        session_prices: session.sessionPrices,
        session_price: null,
        total_count: session.sessionPrices.length,
        openAdd: false,
        openUpdate: false,
    });

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid
                container justify="space-between" alignItems="center"
                className="toolbar"
            >
                <b>PRIX</b>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            {/* -------------------- ADD SESSION PRICE -------------------- */}
            <Grid
                container justify="flex-end"
                style={{ padding: '0px 24px' }}
            >
                <Button
                    onClick={() => setState({ ...state, openAdd: true })}
                    variant="contained"
                    style={{ textTransform: 'none' }}
                >
                    Ajouter un prix à la session
                </Button>
            </Grid>
            <Grid style={{ padding: 24 }}>
                {/* -------------------- HEADER LIST -------------------- */}
                <Grid
                    container alignItems="center"
                    className="list-headers"
                >
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Prix (€)
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                TVA (%)
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Statut
                            </Grid>
                            <Grid item xs={6} style={{ padding: 10 }}>
                                Description
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ padding: 10 }}>
                            Prix
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- SESSION LIST -------------------- */}
                {state.session_prices.length > 0 ? (
                    state.session_prices.map(session_price => (
                        <Fragment key={session_price.id}>
                            <Grid
                                onClick={() => setState({ ...state, openUpdate: true, session_price })}
                                container alignItems="center"
                                className="simple-list"
                            >
                                <Grid
                                    item xs={12} md={2}
                                    style={{ padding: 10 }}
                                >
                                    {getAmountTTC(session_price.price)}
                                </Grid>
                                <Grid
                                    item xs={12} md={2}
                                    style={{ padding: 10 }}
                                >
                                    {getTVA(session_price.tva)}
                                </Grid>
                                <Grid
                                    item xs={12} md={2}
                                    style={{ padding: 10 }}
                                >
                                    {getLabelStatus(sessionPriceStatuses, session_price.status)}
                                </Grid>
                                <Grid
                                    item xs={12} md={6}
                                    className="text"
                                    style={{ padding: 10 }}
                                > 
                                    {session_price.description}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid container style={{ padding: 10 }}>
                            Aucun
                        </Grid>
                        <Divider />
                    </>
                )}
            </Grid>
            {/* -------------------- ADD SESSION PRICE -------------------- */}
            {state.openAdd && (
                <AddSessionPrice
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={() => refetch()}
                    session={session}
                />
            )}
            {/* -------------------- ADD SESSION PRICE -------------------- */}
            {state.openUpdate && (
                <UpdateSessionPrice
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => refetch()}
                    session_price={state.session_price}
                />
            )}
        </>
    );
};

export default SessionPrices;
