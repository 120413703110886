import React from 'react';

// File-Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Grid, MenuItem, RadioGroup, FormControlLabel, Typography, Radio, Checkbox, Button } from '@material-ui/core';
import { SaveAlt as SaveIcon } from '@material-ui/icons';

// Services
import { fileUrl } from 'services/axios-config';
import { magazinePeriods, magazineStatuses } from 'services/constants';
import { getFileExtension } from 'services/helpers';

// Components
import InputText from 'components/input-text';
import DatePicker from 'components/date-picker';
import SelectOutlined from 'components/select-outlined';
import InputFile from 'components/input-file';
import FieldRequired from 'components/field-required';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MAGAZINE FORM ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MagazineForm = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Nom de la revue<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom"
                        value={state.name}
                        onChange={e => setState({ ...state, name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- NUMBER -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Numéro
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Numéro"
                        type="number"
                        value={state.number}
                        onChange={e => setState({ ...state, number: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- PUBLICATION DATE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Date de publication
                </Grid>
                <Grid item xs={12} sm={8}>
                    <DatePicker
                        outlined
                        value={state.publication_date}
                        onChange={publication_date => setState({ ...state, publication_date })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- START DATE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Début des envois
                </Grid>
                <Grid item xs={12} sm={8}>
                    <DatePicker
                        outlined
                        value={state.start_date}
                        onChange={start_date => setState({ ...state, start_date })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- END DATE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Fin de envois
                </Grid>
                <Grid item xs={12} sm={8}>
                    <DatePicker
                        outlined
                        value={state.end_date}
                        onChange={end_date => setState({ ...state, end_date })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- PERIOD -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Période
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectOutlined
                        value={state.period}
                        onChange={e => setState({ ...state, period: e.target.value })}
                    >
                        <MenuItem value={-1}>
                            <em>Période</em>
                        </MenuItem>
                        {magazinePeriods.map(period => (
                            <MenuItem key={period.value} value={period.value}>
                                {period.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- STATUS -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Statut<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectOutlined
                        value={state.status}
                        onChange={e => setState({ ...state, status: e.target.value })}
                    >
                        <MenuItem value={-1}>
                            <em>Statut</em>
                        </MenuItem>
                        {magazineStatuses.map(status => (
                            <MenuItem key={status.value} value={status.value}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- DESCRIPTION -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Description
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Description"
                        multiline
                        value={state.description}
                        onChange={e => setState({ ...state, description: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- UPLOADED FILE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Document
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputFile inputId="magazine" />
                </Grid>
            </Grid>
            {(state.actionType === 'UPDATE' && state.digital_version_url) && (
                <Grid container alignItems="center" style={{ marginTop: 5 }}>
                    <Grid item xs={false} sm={4}>
                        Document actuel
                    </Grid>
                    <Grid item xs={12} sm={8} container alignItems="center" justify="space-between">
                        <Button
                            onClick={() => saveAs(fileUrl + state.digital_version_url, state.name + getFileExtension(state.digital_version_url))}
                            style={{ textTransform: 'none', padding: '0px 2px' }}
                            color="secondary"
                        >
                            Télécharger
                            <SaveIcon style={{ marginLeft: 5 }} />
                        </Button>
                        <div>
                            <Checkbox
                                value={state.remove_digital_version}
                                onChange={e => setState({ ...state, remove_digital_version: e.target.checked })}
                            />
                            Supprimer le document
                        </div>
                    </Grid>
                </Grid>
            )}
            {/* -------------------- IS SPECIAL ISSUE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Hors-série
                </Grid>
                <Grid item xs={12} sm={8}>
                    <RadioGroup
                        value={state.is_special_issue}
                        onChange={e => setState({ ...state, is_special_issue: e.target.value })}
                        style={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label={<Typography style={{ fontSize: 12 }}>Non</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={<Typography style={{ fontSize: 12 }}>Oui</Typography>}
                            style={{ margin: '0px 0px 0px 15px' }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            {/* -------------------- PRICE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    <b>Prix (€)</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Prix (€)"
                        type="number"
                        value={state.price}
                        onChange={e => setState({ ...state, price: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default MagazineForm;
