import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 160;

// -------------------- HEADER -------------------- \\
const CompanyNameHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Nom de l'entreprise
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const CompanyNameFilter = ({ state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label="Nom de l'entreprise"
                value={state.company_name}
                onChange={e => setState({ ...state, company_name: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const CompanyNameValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { CompanyNameHeader, CompanyNameFilter, CompanyNameValue };
