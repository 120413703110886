import React, { useState, useEffect } from 'react';

// Services
import { getRoles } from 'services/requests';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SELECT ROLES ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectRoles = ({ roles, setRoles }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getRoles().then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.roles.map(r => ({ label: r.display_name, value: r.id })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <SelectMultiple
            value={roles}
            onChange={value => setRoles(value)}
            options={options}
            placeholder="Type de compte"
        />
    );
};

export default SelectRoles;
