import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, MenuItem } from '@material-ui/core';

// Services
import { getCountries } from 'services/requests';
import { isValidAmount } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/input-text';
import SelectOutlined from 'components/select-outlined';

// Views
import PurchaseConditions from 'views/common/purchase-conditions';

// Messages
import messages from 'messages.json';

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------- FORMATION REQUEST FORM 3 ------------------------------------ \\
// --------------------------------------------------------------------------------------------------- \\

const FormationRequestForm3 = ({ state, setState }) => {
    const { mediaMD } = useMediaQueries();

    useEffect(() => {
        let price = 0;
        state.user_registrations.forEach(r => {
            price += r.session_price.price;
            if (r.options && r.options.length > 0) {
                r.options.forEach(o => {
                    price += o.price;
                });
            }
        });
        if (isValidAmount(state.contribution_amount)) {
            price += (state.contribution_amount * 100);
        }
        setState({ ...state, price });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.contribution_amount]);

    return (
        <>
            <Grid className="main-container" style={{ padding: 0 }}>
                <Grid className="main-container-item" style={{ padding: 24 }}>
                    <Grid container>
                        <Grid item xs={false} sm={1} md={3} />
                        <Grid item xs={12} sm={10} md={6}>
                            <RegistrationForm
                                state={state}
                                setState={setState}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- SUBMIT -------------------- */}
            <Grid container alignItems="center" justify="center" style={{ padding: 24 }}>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => setState({ ...state, page: 2 })} variant="contained">
                        Précédent
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    3 / 3
                </Grid>
                <Grid item xs={12} md={4} container alignItems="center" justify={mediaMD ? 'flex-end' : 'center'}>
                    <Submit
                        state={state}
                        setState={setState}
                    />
                </Grid>
            </Grid>
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ REGISTRATION FORM -------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const RegistrationForm = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- FIRST NAME -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Prénom*
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Prénom"
                        value={state.first_name}
                        onChange={e => setState({ ...state, first_name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- LAST NAME -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Nom*
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- EMAIL -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Email*
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- COMPANY NAME -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Nom de l'entreprise / Employeur
                    {(state.session_price && state.session_price.require_company_name) ? '*' : ''}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom de l'entreprise / Employeur"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- ADDRESS -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Adresse de facturation*
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Adresse de facturation"
                        value={state.address}
                        onChange={e => setState({ ...state, address: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Détails adresse
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Détails adresse"
                        value={state.address_details}
                        onChange={e => setState({ ...state, address_details: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- POSTAL CODE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Code postal*
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Code postal"
                        value={state.postal_code}
                        onChange={e => setState({ ...state, postal_code: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- CITY -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Ville*
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Ville"
                        value={state.city}
                        onChange={e => setState({ ...state, city: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* ---------------------- COUNTRY ---------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Pays*
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectCountry
                        countryId={state.country_id}
                        setCountryId={country_id => setState({ ...state, country_id })}
                    />
                </Grid>
            </Grid>
            <Divider style={{ margin: '24px 0px' }} />
            {/* -------------------- CONTRIBUTION AMOUNT -------------------- */}
            <Grid style={{ marginBottom: 5 }}>
                En plus de la formation, je verse si je le souhaite une cotisation de
                solidarité de :
                <br />
                (montant au choix en euro)
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs={false} sm={4} />
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Cotisation de solidarité (€)"
                        type="number"
                        value={state.contribution_amount}
                        // onChange={e => onChangeContributionAmount(e.target.value)}
                        onChange={e => setState({ ...state, contribution_amount: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Divider style={{ margin: '24px 0px' }} />
            {/* -------------------- PURCHASE CONDITIONS -------------------- */}
            <PurchaseConditions
                state={state}
                setState={setState}
            />
            {/* -------------------- IS FULL -------------------- */}
            {state.formationSession.isFull && (
                <>
                    <Divider style={{ margin: '24px 0px' }} />
                    <Grid container>
                        <b>
                            Le nombre maximum de participants pour cette formation a été atteint.
                            <br />
                            Par conséquent, vous serez en liste d'attente.
                        </b>
                    </Grid>
                </>
            )}
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ SELECT COUNTRY ----------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const SelectCountry = ({ countryId, setCountryId }) => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountries().then(res => {
            if (res.status === 200) {
                setCountries(res.data.data.countries);
            }
            else {
                console.log(res);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SelectOutlined
            value={countryId}
            onChange={e => setCountryId(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>Pays</em>
            </MenuItem>
            {countries.map(country => (
                <MenuItem key={country.id} value={country.id}>
                    {country.name}
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- SUBMIT --------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const Submit = ({ state, setState }) => {
    const { enqueueSnackbar } = useSnackbar();

    function onSubmit() {
        if (state.first_name === ''
            || state.last_name === ''
            || state.email === ''
            || state.address === ''
            || state.postal_code === ''
            || state.city === ''
            || state.country_id === -1
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!state.cgv || !state.pdc) {
            enqueueSnackbar('Veuillez accepter les CGV et la politique de confidentialité', { variant: 'warning' });
        }
        else {

            let final_registrations = state.user_registrations.map(registration => ({
                user_id: registration.user.id,
                session_price_id: registration.session_price.id,
                option_ids: registration.options.map(o => o.id),
                phone: registration.phone || '',
                birth_date: registration.birth_date ? moment(registration.birth_date).format('YYYY-MM-DD') : '',
            }));

            setState({ ...state, loadingCreate: true, user_registrations: final_registrations });
        }
    }

    return (
        <>
            <b style={{ fontSize: 16, marginRight: 10 }}>
                PRIX {state.price / 100}€ TTC
            </b>
            <Button
                onClick={onSubmit}
                variant="contained"
            >
                Valider
            </Button>
        </>
    );
};

export default FormationRequestForm3;
