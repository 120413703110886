import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { clearToken } from 'services/storage';
import { logout } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// -------------------------------------------------------------------------------------- \\
// --------------------------------------- LOGOUT --------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const Logout = ({ onClose }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            logout().then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                }
                clearToken();
                history.push('/home');
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Déconnexion
                        </p>
                        <span>
                            Êtes-vous sûr de vouloir vous déconnecter ?
                        </span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            Non
                        </Button>
                        <Button
                            onClick={() => setLoading(true)}
                            variant="contained"
                        >
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default Logout;
