import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Divider, Button } from '@material-ui/core';

// Services
import { formationRequestStatuses } from 'services/constants';
import { getFormattedDate, getColorStatus, getLabelStatus, cancelParticipationIsAvailable, getAmountTTC } from 'services/helpers';

// Views
import CancelParticipation from './formation-requests-cancel';
import DownloadTrainingCertificate from 'views/management/formations/formation-session/formation-participations/download-training-certificate';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------- FORMATION REQUESTS ------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FormationRequests = ({ state, setState }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const formationRequests = state.formation_requests;

    function getDate() {
        if (formationRequests[0].formationSession && formationRequests[0].formationSession.begin && formationRequests[0].formationSession.end) {
            return getFormattedDate(formationRequests[0].formationSession.begin) + ' - ' + getFormattedDate(formationRequests[0].formationSession.end);
        }
        return '';
    }

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <b>FORMATION</b>
            {(formationRequests && formationRequests.length > 0) ? (
                <>
                    <br />
                    {/* -------------------- DATE -------------------- */}
                    {getDate()}
                    {/* -------------------- CANCEL PARTICIPATION -------------------- */}
                    {cancelParticipationIsAvailable(formationRequests[0]) && (
                        <>
                            <br />
                            <Button
                                onClick={() => setState({ ...state, openCancelParticipation: true, formationRequest: formationRequests[0] })}
                                color="primary" variant="contained"
                            >
                                Annuler votre formation
                            </Button>
                        </>
                    )}  
                    <Divider style={{ margin: '10px 0px' }} />
                    {/* -------------------- NUMBER -------------------- */}
                    <p>
                        <b>Numéro</b>
                        <br />
                        #{formationRequests[0].formationSession?.number}
                    </p>
                    {/* -------------------- TITLE -------------------- */}
                    <p>
                        <b>Intitulé</b>
                        <br />
                        {formationRequests[0].formationTitle}
                    </p>
                    {/* -------------------- PRICE -------------------- */}
                    <p>
                        <b>Montant</b>
                        <br />
                        {getAmountTTC(formationRequests[0].sessionPrice?.price)}
                    </p>
                    {/* -------------------- MANAGER -------------------- */}
                    {(formationRequests[0].formationSession && formationRequests[0].formationSession.manager) && (
                        <p>
                            <b>Responsable</b>
                            <br />
                            {formationRequests[0].formationSession?.manager?.fullName}
                        </p>
                    )}
                    {/* -------------------- TRAINERS -------------------- */}
                    {(formationRequests[0].formationSession && formationRequests[0].formationSession.trainers && formationRequests[0].formationSession.trainers.length > 0) && (
                        <p>
                            <b>Formateur(s)</b>
                            <br />
                            {formationRequests[0].formationSession.trainers.map((t, index) => {
                                if (index === 0) return t.fullName;
                                return ', ' + t.fullName;
                            })}
                        </p>
                    )}
                    {/* -------------------- CERTIFICATE -------------------- */}
                    {formationRequests[0].training_certificate_url != null && (
                        <p>
                            <b>Attestation</b>
                            <br />
                            <DownloadTrainingCertificate
                                formationRequest={formationRequests[0]}
                            />
                        </p>
                    )}
                    {/* -------------------- STATUS -------------------- */}
                    <p>
                        <b>Statut</b>
                        <br />
                        <b style={{ color: getColorStatus(formationRequestStatuses, formationRequests[0].status) }}>
                            {getLabelStatus(formationRequestStatuses, formationRequests[0].status)?.toUpperCase()}
                        </b>
                    </p>
                    <Divider style={{ margin: '10px 0px' }} />
                    {/* -------------------- HISTORICAL -------------------- */}
                    <Button
                        onClick={() => history.push(`${match.url}/formation-requests`)}
                        variant="contained"
                        style={{ textTransform: 'none' }}
                    >
                        Historique des formations
                    </Button>
                </>
            ) : (
                <>
                    <Divider style={{ margin: '10px 0px' }} />
                    <span>Aucune</span>
                </>
            )}
            {/* -------------------- CANCEL PARTICIPATION -------------------- */}
            {state.openCancelParticipation && (
                <CancelParticipation
                    onClose={() => setState({ ...state, openCancelParticipation: false })}
                    refetch={() => setState({ ...state, openCancelParticipation: false, loading: true })}
                    formationRequest={state.formationRequest}
                />
            )}
        </>
    );
};

export default FormationRequests;
