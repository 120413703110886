import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services
import { updateCategory } from 'services/requests';
import { userCanDeleteCategory } from 'services/permissions';

// Components
import InputText from 'components/input-text';
import InputFile from 'components/input-file';
import FieldRequired from 'components/field-required';

// Messages
import messages from 'messages.json';

// Views
import DeleteCategory from '../delete-category';

// --------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- UPDATE CATEGORY ----------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const UpdateCategory = ({ onClose, refetch, category }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: category.name || '',
        icon: null,
        openDelete: false,
    });

    function onUpdate() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const icon = document.getElementById('upload-icon').files[0];
            setState({ ...state, loading: true, icon });
        }
    }

    function onRefetch() {
        setState({ ...state, openDelete: false });
        refetch();
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('name', state.name);

            if (state.icon) {
                formData.append('icon', state.icon);
            }

            updateCategory(category.id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Dialog open fullWidth>
                <DialogContent style={{ paddingBottom: 20 }}>
                    <p className="dialog-title">
                        Modifier une catégorie de formation
                    </p>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            Nom de la catégorie<FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom de la catégorie"
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Icône de la catégorie
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputFile inputId="icon" />
                        </Grid>
                    </Grid>
                    {userCanDeleteCategory() && (
                        <>
                            <Divider style={{ margin: '15px 0px' }} />
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    Supprimer la catégorie
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Button
                                        onClick={() => setState({ ...state, openDelete: true })}
                                        variant="contained" color="secondary"
                                    >
                                        Supprimer
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={onClose} variant="contained">
                        Annuler
                    </Button>
                    <Button onClick={onUpdate} variant="contained">
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <DeleteCategory
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => onRefetch()}
                    category={category}
                />
            )}
        </>
    );
};

export default UpdateCategory;
