import React from 'react';

// Material-UI
import { Grid, Checkbox, Divider, Button } from '@material-ui/core';

// Components
import InputText from 'components/input-text';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ CONSULTING VISIT FORM ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const ConsultingVisitForm = ({ state, setState }) => {
    return (
        <>
            <Grid container justify="center" className="list-headers" style={{ padding: 10 }}>
                {state.actionType === 'CREATE' ? (
                    'Questionnaire à remplir pour la visite-conseil'
                ) : (
                    'Questionnaire pour la visite-conseil'
                )}
            </Grid>
            {/* -------------------- AGRICULTURA AREA -------------------- */}
            <Grid container alignItems="center" style={{ padding: 10 }}>
                <Grid item xs={4}>
                    <b>Ma surface agricole (hectares) :</b>
                </Grid>
                <Grid item xs={8}>
                    <InputText
                        label="Surface agricole (hectares)"
                        type="number"
                        value={state.agricultural_area}
                        onChange={e => setState({ ...state, agricultural_area: e.target.value })}
                        disabled={state.actionType === 'UPDATE'}
                    />
                </Grid>
            </Grid>
            <Divider />
            <Grid style={{ padding: 10 }}>
                {/* -------------------- IS IN CONVENTIONAL -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.is_in_conventional}
                        onChange={e => setState({ ...state, is_in_conventional: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Je suis en conventionnel
                </div>
                {/* -------------------- IS IN CONVERSION -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.is_in_conversion}
                        onChange={e => setState({ ...state, is_in_conversion: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Je suis en conversion bio
                </div>
                {/* -------------------- IS IN BIO -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.is_in_bio}
                        onChange={e => setState({ ...state, is_in_bio: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Je suis en bio
                </div>
                {/* -------------------- IS IN BIODYNAMIC -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.is_in_biodynamic}
                        onChange={e => setState({ ...state, is_in_biodynamic: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Je suis en biodynamique
                </div>
                {/* -------------------- IS CERTIFIED IN BIODYNAMIC -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.is_certified_in_biodynamics}
                        onChange={e => setState({ ...state, is_certified_in_biodynamics: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Je suis certifié biodynamique
                </div>
            </Grid>
            <Divider />
            <Grid style={{ padding: 10 }}>
                <b>Ma situation</b>
                {/* -------------------- NO KNOWLEDGE ABOUT BIODYNAMIC -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.no_knowledge_about_biodynamic}
                        onChange={e => setState({ ...state, no_knowledge_about_biodynamic: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Je n'ai pas de connaissances spécifiques à la biodynamie
                </div>
                {/* -------------------- HAS FOLLOWED BIODYNAMIC TRAINING -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.has_followed_biodynamic_training}
                        onChange={e => setState({ ...state, has_followed_biodynamic_training: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    J’ai déjà suivi une formation en biodynamie
                </div>
                {/* -------------------- PRACTICE BIODYNAMICS -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.practice_biodynamics}
                        onChange={e => setState({ ...state, practice_biodynamics: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Je pratique déjà la biodynamie
                </div>
            </Grid>
            <Divider />
            <Grid style={{ padding: 10 }}>
                <b>Mes besoins pour la visite :</b>
                {/* -------------------- WISH DIAGNOSTIC -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.wish_diagnostic}
                        onChange={e => setState({ ...state, wish_diagnostic: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Etablir un diagnostic pour accompagner la conversion
                </div>
                {/* -------------------- WISH PRACTICE BIODYNAMICS -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.wish_practice_biodynamics}
                        onChange={e => setState({ ...state, wish_practice_biodynamics: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Mettre en oeuvre une des pratiques biodynamiques
                </div>
                {/* -------------------- WISH TO BE ACCOMPANIED -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={state.wish_to_be_accompanied}
                        onChange={e => setState({ ...state, wish_to_be_accompanied: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    Faire le point sur mes pratiques
                </div>
            </Grid>
            <Divider />
            <Grid style={{ padding: 10 }}>
                <b>Mon matériel :</b>
                {/* -------------------- MANUAL & MECHANICAL ENERGIZER -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    J'ai un dynamiseur :
                    <Checkbox
                        checked={state.has_manual_energizer}
                        onChange={e => setState({ ...state, has_manual_energizer: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    manuel
                    <Checkbox
                        checked={state.has_mechanical_energizer}
                        onChange={e => setState({ ...state, has_mechanical_energizer: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    mécanique
                </div>
                {/* -------------------- BACKPACK & MECHANICAL SPRAYER -------------------- */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    J’ai un pulvérisateur :
                    <Checkbox
                        checked={state.has_backpack_sprayer}
                        onChange={e => setState({ ...state, has_backpack_sprayer: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    à dos
                    <Checkbox
                        checked={state.has_mechanical_sprayer}
                        onChange={e => setState({ ...state, has_mechanical_sprayer: e.target.checked })}
                        disabled={state.actionType === 'UPDATE'}
                        style={{ padding: 6 }}
                    />
                    mécanique
                </div>
            </Grid>
            <Grid style={{ padding: 10 }}>
                <b>Commentaires :</b>
                <Grid container style={{ marginTop: 5 }}>
                    <InputText
                        label="Commentaires..."
                        multiline
                        value={state.comments}
                        onChange={e => setState({ ...state, comments: e.target.value })}
                        disabled={state.actionType === 'UPDATE'}
                    />
                </Grid>
            </Grid>
            {state.actionType === 'CREATE' && (
                <Grid container justify="center" style={{ marginTop: 24 }}>
                    <Button onClick={() => setState({ ...state, loading: true })} variant="contained">
                        Soumettre ma demande de visite-conseil
                    </Button>
                </Grid>
            )}
        </>
    );
};

export default ConsultingVisitForm;
