import React, { useState, useEffect, Fragment } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

// Moment
import moment from 'moment';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, Divider, Button, DialogContent, DialogActions, Dialog } from '@material-ui/core';

// Services
import { getUserMemberships, getUserConsultingVisits } from 'services/requests';
import { userCanCreateConsultingVisit, userCanUpdateConsultingVisit, userCanHandleSubscriptionForUser } from 'services/permissions';
import { getFormattedDate, getLabelStatus, getColorStatus } from 'services/helpers';
import { subscriptionStatuses, transferStatuses } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import UpdateMembership from 'views/management/memberships/update-membership';
import RenewMembership from 'views/common/renew/renew-membership-modal';

// --------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MEMBERSHIPS ---------------------------------------- \\
// --------------------------------------------------------------------------------------------- \\

const Memberships = ({ user }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        memberships: [],
        total_count: 0,
        consulting_visits: [],
        consultingVisit: false,

        // Modal
        openModal: false,
        openRenew: false,

        // Update
        openUpdate: false,
        membership: null,
    });

    useEffect(() => {
        if (state.loading) {
            axios.all([getUserMemberships(user.id), getUserConsultingVisits(user.id)])
            .then(axios.spread(function (res_memberships, res_consulting_visits) {
                if (res_memberships.status === 200 && res_consulting_visits.status === 200) {
                    const memberships = res_memberships.data.data.memberships;

                    let consultingVisit = false;
                    if (memberships.findIndex(m => m.membershipType.is_pro === 1 && m.status === 'validated') !== -1) {
                        consultingVisit = true;
                    }

                    setState({
                        ...state,
                        loading: false,
                        memberships,
                        total_count: memberships.length,
                        consulting_visits: res_consulting_visits.data.data.consultingVisits,
                        consultingVisit,
                    });
                }
                else {
                    console.log(res_memberships + res_consulting_visits);
                    setState({ ...state, loading: false, error: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div
            className="light-background-color"
            style={{ padding: '0px 24px 24px 24px' }}
        >
            <div style={{ padding: 12, backgroundColor: 'white' }}>
                {/* -------------------- TOOLBAR -------------------- */}
                <Grid container justify="space-between" alignItems="center">
                    <b>ADHÉSIONS</b>
                    <b>
                        {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                    </b>
                </Grid>
                {/* -------------------- CONSULTING VISIT & MEMBERSHIP TYPES -------------------- */}
                <Grid
                    container spacing={2} alignItems="center" justify="flex-end"
                    style={{ padding: '12px 0px' }}
                >
                    {(
                        userCanCreateConsultingVisit()
                        && state.consulting_visits.length === 0
                        && state.consultingVisit
                    ) && (
                        <Grid item xs="auto">
                            <Button
                                onClick={() => history.push(`${match.url}/consulting-visit`)}
                                variant="contained"
                                style={{ textTransform: 'none' }}
                            >
                                Ajouter une demande de visite-conseil
                            </Button>
                        </Grid>
                    )}
                    {(
                        userCanUpdateConsultingVisit()
                        && state.consulting_visits.length > 0
                    ) && (
                        <Grid item xs="auto">
                            <Button
                                onClick={() => history.push(`${match.url}/consulting-visit/${state.consulting_visits[0].id}`)}
                                variant="contained"
                                style={{ textTransform: 'none' }}
                            >
                                Voir la demande de visite-conseil en cours
                            </Button>
                        </Grid>
                    )}
                    {userCanHandleSubscriptionForUser() && (
                        <Grid item xs="auto">
                            <Button
                                onClick={() => history.push(`${match.url}/shop/memberships`)}
                                variant="contained"
                                style={{ textTransform: 'none' }}
                            >
                                Ajouter une adhésion
                            </Button>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid
                        container alignItems="center"
                        className="subtitle-primary-color"
                        style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}
                    >
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Période de validité
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Type d'adhésion
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Statut transfert
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- BILL LIST -------------------- */}
                <MembershipList
                    state={state}
                    setState={setState}
                />
            </div>
        </div>
    );
};

// --------------------------------------------------------------------------------------------- \\
// -------------------------------------- MEMBERSHIP LIST -------------------------------------- \\
// --------------------------------------------------------------------------------------------- \\

const MembershipList = ({ state, setState }) => {

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.memberships.length > 0 ? (
                state.memberships.map(membership => (
                    <Fragment key={membership.id}>
                        <Grid
                            onClick={() => setState({ ...state, openModal: true, membership })}
                            container alignItems="center"
                            className="simple-list"
                        >
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                <span style={{ color: getColorStatus(subscriptionStatuses, membership.status) }}>
                                    {getLabelStatus(subscriptionStatuses, membership.status)}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                {getFormattedDate(membership.start_date)} - {getFormattedDate(membership.end_date)}
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                {membership.membershipType?.name}
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                {getLabelStatus(transferStatuses, membership.transfer_status)}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid container style={{ padding: 10 }}>
                        Aucune
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- MODAL -------------------- */}
            {state.openModal && (
                <MembershipModal
                    state={state}
                    setState={setState}
                />
            )}
            {/* -------------------- UPDATE -------------------- */}
            {state.openUpdate && (
                <UpdateMembership
                    membership={state.membership}
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                />
            )}
            {/* -------------------- RENEW -------------------- */}
            {state.openRenew && (
                <RenewMembership
                    membership={state.membership}
                    onClose={() => setState({ ...state, openRenew: false })}
                />
            )}
        </>
    );
};

// --------------------------------------------------------------------------------------------- \\
// -------------------------------------- MEMBERSHIP MODAL ------------------------------------- \\
// --------------------------------------------------------------------------------------------- \\

const MembershipModal = ({ state, setState }) => {
    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20, display: 'flex' }}>
                <div
                    onClick={() => setState({ ...state, openUpdate: true, openModal: false })}
                    className="subscription-links"
                >
                    Modifier l'adhésion
                </div>
                {(
                    state.membership?.newMembership == null
                    && (
                        state.membership?.status === 'validated'
                        || state.membership?.status === 'expired'
                    )
                    && (
                        (Number(state.membership?.end_date?.substring(0, 4)) === moment().year() && moment().format('MM-DD') >= '07-01')
                        || Number(state.membership?.end_date?.substring(0, 4)) === moment().year() - 1
                    )
                ) && (
                    <div
                        onClick={() => setState({ ...state, openRenew: true, openModal: false })}
                        className="subscription-links"
                        style={{ marginLeft: 24 }}
                    >
                        Renouveler l'adhésion
                    </div>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    onClick={() => setState({ ...state, openModal: false })}
                    variant="contained"
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Memberships;
