import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getUsers } from 'services/requests';
import { getFormattedDate, getLabelStatus } from 'services/helpers';
import { membershipStatuses } from 'services/constants';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------- \\
// -------------------------------------- USERS EXPORT ------------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const UsersExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getStringFromArray(array) {
        let result = '';
        array.forEach((item, index) => {
            if (index === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });
        return result;
    }

    function getAllUsersToExport(users, offset) {
        getUsers({
            offset: offset,
            limit: data.limit,
            membership_statuses: data.membership_statuses ? data.membership_statuses.map(s => s.value) : null,
            client_code: data.client_code,
            external_client_code: data.external_client_code,
            first_name: data.first_name,
            last_name: data.last_name,
            company_name: data.company_name,
            mobile_phone: data.mobile_phone,
            email: data.email,
            french_department_ids: data.french_departments ? data.french_departments.map(d => d.value) : null,
            role_ids: data.roles ? data.roles.map(r => r.value) : null,
            federated_association_ids: data.federated_associations ? data.federated_associations.map(a => a.value) : null,
            phone: data.phone,
            other_phone: data.other_phone,
            membership_email: data.membership_email,
            country_ids: data.countries ? data.countries.map(c => c.value) : null,
            user_category_ids: data.user_categories ? data.user_categories.map(c => c.value) : null,
            birth_date: data.birth_date,
            postal_codes: data.postal_codes ? data.postal_codes.map(c => c.value) : null,
            membership_expiration: data.membership_expiration,
            subscription_email: data.subscription_email,
            bill_email: data.bill_email,
            subscription_expiration: data.subscription_expiration,
        }).then(res => {
            if (res.status === 200) {

                let res_users = res.data.data.users;
                users = users.concat(res_users);

                setState({
                    ...state,
                    percentage: Math.round((100 * users.length) / data.total_count) + '%',
                });

                if (res_users.length < data.limit) {
                    createExcelFile(users);
                }
                else {
                    getAllUsersToExport(users, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(users) {
        if (users.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Utilisateurs');

            // Header
            worksheet.columns = [
                { header: 'Statut', key: 'status', width: 30 },
                { header: 'Code client', key: 'client_code', width: 30 },
                { header: 'Code client externe', key: 'external_client_code', width: 30 },
                { header: 'Nom', key: 'last_name', width: 30 },
                { header: 'Prénom', key: 'first_name', width: 30 },
                { header: 'Nom de l\'entreprise', key: 'company_name', width: 30 },
                { header: 'Téléphone portable', key: 'mobile_phone', width: 30 },
                { header: 'Email', key: 'email', width: 30 },
                { header: 'Département', key: 'department', width: 30 },
                { header: 'Type de compte', key: 'role', width: 30 },
                { header: 'Association fédérée', key: 'federated_association', width: 30 },
                { header: 'Téléphone', key: 'phone', width: 30 },
                { header: 'Autre téléphone', key: 'other_phone', width: 30 },
                { header: 'Email adhésion', key: 'membership_email', width: 30 },
                { header: 'Pays', key: 'country', width: 30 },
                { header: 'Rôle', key: 'user_category', width: 30 },
                { header: 'Date de naissance', key: 'birth_date', width: 30 },
                { header: 'Code postal', key: 'postal_code', width: 30 },
                { header: 'Date d\'expiration adhé.', key: 'membership_expiration', width: 30 },
                { header: 'Email abonnement', key: 'subscription_email', width: 30 },
                { header: 'Email bon de commande', key: 'bill_email', width: 30 },
                { header: 'Date d\'expiration abo.', key: 'subscription_expiration', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            users.map(user => {
                return worksheet.addRow({
                    status: getLabelStatus(membershipStatuses, user.membershipStatus),
                    client_code: user.clientCode || '',
                    external_client_code: user.external_client_code || '',
                    last_name: user.last_name || '',
                    first_name: user.first_name || '',
                    company_name: user.company_name || '',
                    mobile_phone: user.mobile_phone || '',
                    email: user.email || '',
                    department: user.department ? (user.department.name + ' (' + user.department.number + ')') : '',
                    role: user.role?.display_name,
                    federated_association: user.federatedAssociation ? user.federatedAssociation.name : '',
                    phone: user.phone || '',
                    other_phone: user.other_phone || '',
                    membership_email: user.membershipEmail || '',
                    country: user.country ? user.country.name : '',
                    user_category: user.userCategories ? getStringFromArray(user.userCategories) : '',
                    birth_date: user.birth_date ? getFormattedDate(user.birth_date) : '',
                    postal_code: user.postal_code || '',
                    membership_expiration: user.membershipExpiration ? getFormattedDate(user.membershipExpiration) : '',
                    subscription_email: user.subscriptionEmail || '',
                    bill_email: user.billEmail || '',
                    subscription_expiration: user.subscriptionExpiration ? getFormattedDate(user.subscriptionExpiration) : '',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'utilisateurs.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllUsersToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default UsersExport;
