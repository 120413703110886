import React, { useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, CircularProgress } from '@material-ui/core';
import { Check as IconCheck } from '@material-ui/icons';

// Services
import { checkExistingEmail } from 'services/requests';
import { isValidEmail } from 'services/helpers';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// --------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- COUPLE MEMBERSHIP ---------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const CoupleMembership = ({ state, setState }) => {
    const { enqueueSnackbar } = useSnackbar();

    const onChangeSecondEmail = value => {
        setState({
            ...state,
            second_user_email: value,
            second_user_is_verified: false,
            second_user_not_exist: false,
        });
    };

    const onVerified = () => {
        if (state.second_user_email === '') {
            enqueueSnackbar('Veuillez saisir l\'email du second compte', { variant: 'warning' });
        }
        else if (!isValidEmail(state.second_user_email)) {
            enqueueSnackbar('L\'email du second compte n\'est pas valide', { variant: 'warning' });
        }
        else {
            setState({ ...state, second_user_loading: true });
        }
    };

    useEffect(() => {
        if (state.second_user_loading) {
            checkExistingEmail(state.second_user_email).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        second_user_loading: false,
                        second_user_is_verified: true,
                        second_user_not_exist: !res.data.data.emailUsed,
                    });
                }
                else {
                    enqueueSnackbar(res, { variant: 'warning' });
                    setState({ ...state, second_user_loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.second_user_loading]);

    return (
        <>
            <div style={{ marginTop: 24 }}>
                <span>Modifier le bénéficiaire</span>
            </div>
            {/* -------------------- SECOND USER EMAIL -------------------- */}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}>
                <div style={{ flex: 1 }}>
                    <InputText
                        label="Email du deuxième compte"
                        value={state.second_user_email}
                        onChange={e => onChangeSecondEmail(e.target.value)}
                    />
                </div>
                <div style={{ marginLeft: 6 }}>
                    {state.second_user_is_verified ? (
                        <IconCheck
                            style={{ color: 'green' }}
                        />
                    ) : (
                        <Button
                            onClick={onVerified}
                            disabled={state.second_user_loading}
                            variant="contained"
                        >
                            {state.second_user_loading ? (
                                <CircularProgress size={24} style={{ color: 'black' }} />
                            ) : (
                                'Vérifier'
                            )}
                        </Button>
                    )}
                </div>
            </div>
            {state.second_user_not_exist && (
                <>
                    {/* -------------------- SECOND USER FIRST NAME -------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Prénom du second utilisateur<FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Prénom du second utilisateur"
                                value={state.second_user_first_name}
                                onChange={e => setState({ ...state, second_user_first_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- SECOND USER LAST NAME -------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Nom du second utilisateur<FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom du second utilisateur"
                                value={state.second_user_last_name}
                                onChange={e => setState({ ...state, second_user_last_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default CoupleMembership;
