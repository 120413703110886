import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Switch, Route, useRouteMatch } from 'react-router-dom';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';

// Services
import { getMagazine, deleteMagazine, updateMagazine, generateMailingList } from 'services/requests';
import { userCanDeleteMagazine } from 'services/permissions';
import useMediaQueries from 'services/media';
import { amountToSend, amountToBeReceived, isValidAmount } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import MagazineForm from '../magazines-form';
import SubscriptionsForMailingList from './subscriptions-for-mailing-list';
import SubscriptionsToAddInMailingList from './subscriptions-to-add-in-mailing-list';
import SearchAndSelectGroups from 'selects/search-and-select-groups';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MAGAZINE ROUTER -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MagazineRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Magazine />
            </Route>
            <Route exact path={`${match.path}/new-subscriptions/:mailingListId`}>
                <SubscriptionsToAddInMailingList />
            </Route>
            <Route path={`${match.path}/mailing-list/:mailingListId`}>
                <SubscriptionsForMailingList />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- MAGAZINE ------------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const Magazine = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();
    const { magazineId } = useParams();

    const [state, setState] = useState({
        loading: true,
        generate: false,
        error: false,
        magazine: null,
        openDelete: false,
        openGenerate: false,
    });

    useEffect(() => {
        if (state.loading) {
            getMagazine(magazineId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, magazine: res.data.data.magazine });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management/magazines')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    {state.magazine.name}
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanDeleteMagazine() && (
                        <Button onClick={() => setState({ ...state, openDelete: true })} variant="contained">
                            Supprimer
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- MAILING LIST -------------------- */}
            <Grid container alignItems="center" justify="space-between" style={{ padding: 24 }}>
                {(state.magazine && state.magazine.mailingList) ? (
                    <Button
                        onClick={() => history.push(match.url + '/mailing-list/' + state.magazine.mailingList.id)}
                        variant="contained" color="primary"
                    >
                        Accéder à la liste d'envoi
                    </Button>
                ) : (
                    <Button
                        onClick={() => setState({ ...state, openGenerate: true })}
                        variant="contained" color="primary"
                    >
                        Générer la liste d'envoi
                    </Button>
                )}
                {(state.magazine && state.magazine.mailingList) && (
                    <Button
                        onClick={() => history.push(match.url + '/new-subscriptions/' + state.magazine.mailingList.id)}
                        variant="contained" color="primary"
                    >
                        Nouveaux abonnements
                    </Button>
                )}
            </Grid>
            <Divider />
            {/* -------------------- UPDATE -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <SetMagazine magazine={state.magazine} />
            </Grid>
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <DeleteMagazine
                    onClose={() => setState({ ...state, openDelete: false })}
                    magazine={state.magazine}
                />
            )}
            {/* -------------------- GENERATE -------------------- */}
            {state.openGenerate && (
                <GenerateMailingList
                    onClose={() => setState({ ...state, openGenerate: false })}
                    magazineId={magazineId}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SET MAGAZINE ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SetMagazine = ({ magazine }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        actionType: 'UPDATE',
        loading: false,
        name: magazine.name,
        description: magazine.description || '',
        number: magazine.number || '',
        publication_date: magazine.publication_date ? magazine.publication_date : null,
        start_date: magazine.start_date ? magazine.start_date : null,
        end_date: magazine.end_date ? magazine.end_date : null,
        price: amountToBeReceived(magazine.price),
        is_special_issue: magazine.is_special_issue.toString(),
        period: magazine.period || -1,
        status: magazine.status,
        uploaded_file: null,
        digital_version_url: magazine.digital_version_url || null,
        remove_digital_version: false,
    });

    function onSave() {
        if (state.name === '' || state.status === -1) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!isValidAmount(state.price)) {
            enqueueSnackbar('Veuillez saisir un montant valide', { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-magazine').files[0];

            if (file) {
                setState({ ...state, loading: true, uploaded_file: file });
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    }

    useEffect(() => {
        if (state.loading) {
            const formData = new FormData();

            formData.append('name', state.name);
            formData.append('description', state.description);
            formData.append('number', state.number);
            formData.append('publication_date', state.publication_date ? moment(state.publication_date).format('YYYY-MM-DD') : '');
            formData.append('start_date', state.start_date ? moment(state.start_date).format('YYYY-MM-DD') : '');
            formData.append('end_date', state.end_date ? moment(state.end_date).format('YYYY-MM-DD') : '');
            formData.append('price', amountToSend(state.price).toString());
            formData.append('is_special_issue', state.is_special_issue);
            formData.append('period', state.period !== -1 ? state.period : '');
            formData.append('status', state.status !== -1 ? state.status : '');
            formData.append('remove_digital_version', state.remove_digital_version ? '1' : '0');

            if (state.uploaded_file && state.remove_digital_version === false) {
                formData.append('uploaded_file', state.uploaded_file);
            }

            updateMagazine(magazine.id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loading: false, digital_version_url: res.data.data.magazine.digital_version_url });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <>
            <Grid item xs={12} md={10} lg={8}>
                {/* -------------------- NAME -------------------- */}
                <MagazineForm
                    state={state}
                    setState={setState}
                />
            </Grid>
            {/* -------------------- SAVE BUTTON -------------------- */}
            <Grid item xs={12} md={2} lg={4} container alignItems="center" justify="flex-end">
                <Button onClick={onSave} variant="contained">
                    Enregistrer
                </Button>
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- DELETE MAGAZINE ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const DeleteMagazine = ({ onClose, magazine }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteMagazine(magazine.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/management/magazines');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Supprimer
                </p>
                Êtes-vous sûr de vouloir supprimer la revue {magazine.name} ?
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Non
                </Button>
                <Button onClick={() => setLoading(true)} variant="contained">
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- GENERATE MAILING LIST --------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const GenerateMailingList = ({ onClose, magazineId }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        groups: [],
    });

    useEffect(() => {
        if (state.loading) {
            generateMailingList(magazineId, {
                user_list_ids: state.groups ? state.groups.map(g => g.value) : null,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(match.url + '/mailing-list/' + res.data.data.mailingList.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Générer la liste d'envoi
                </p>
                Si vous désirez que certains groupes d'utilisateur soient retirés de la liste d'envoi, sélectionnez les
                ci-dessous :
                <div style={{ marginTop: 5 }}>     
                    <SearchAndSelectGroups
                        placeholder="Chercher un groupe d'utilisateur..."
                        groups={state.groups}
                        setGroups={groups => setState({ ...state, groups })}
                    />
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Annuler
                </Button>
                <Button onClick={() => setState({ ...state, loading: true })} variant="contained">
                    Générer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MagazineRouter;
