import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Divider, Button, Dialog, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';

// Services
import { renewSubscription } from 'services/requests';
import { getAmountTTC } from 'services/helpers';

// ----------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- RENEW SUBSCRIPTION ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------------- \\

const RenewSubscription = ({ onClose, subscription }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            renewSubscription(subscription.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/payment-method/' + res.data.data.bill.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20, textAlign: 'center' }}>
                <p className="dialog-title">
                    Renouvellement abonnement
                </p>
                {`Vous possédez actuellement un ${subscription.subscriptionType?.name} ${subscription.status === 'validated' ? 'valide' : 'expiré'}.
                Vous pouvez dès à présent renouveler votre abonnement pour ${moment(subscription.end_date?.substring(0, 4), 'YYYY').add(1, 'y').year()}.`}
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Annuler
                </Button>
                <Button
                    onClick={() => setLoading(true)}
                    disabled={loading}
                    variant="contained" color="primary"
                >
                    Renouveler ({getAmountTTC(subscription.subscriptionType?.price)})
                    {loading && (
                        <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RenewSubscription;
