import React, { useState, useEffect } from 'react';

// File-Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Divider, Grid, Button, Checkbox } from '@material-ui/core';

// Services
import { getCategories,  } from 'services/requests';
import { fileUrl } from 'services/axios-config';
import { getFileExtension } from 'services/helpers';

// Components
import InputText from 'components/input-text';
import InputFile from 'components/input-file';
import SelectMultiple from 'components/select-multiple';
import FieldRequired from 'components/field-required';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- FORMATION FORM ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationForm = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Intitulé<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Intitulé"
                        value={state.title}
                        onChange={e => setState({ ...state, title: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- DURATION -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Durée (h)<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Durée (h)"
                        type="number"
                        value={state.duration}
                        onChange={e => setState({ ...state, duration: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- DESCRIPTION -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Objectifs
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Objectifs"
                        multiline
                        value={state.description}
                        onChange={e => setState({ ...state, description: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- PREREQUISITE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Prérequis
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Prérequis"
                        multiline
                        value={state.prerequisite}
                        onChange={e => setState({ ...state, prerequisite: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Divider style={{ margin: '15px 0px' }} />
            {/* -------------------- CATEGORIES -------------------- */}
            <Grid>
                Catégories<FieldRequired />
            </Grid>
            <Grid style={{ marginTop: 5 }}>
                <SelectCategories
                    categories={state.categories}
                    setCategories={categories => setState({ ...state, categories })}
                />
            </Grid>
            <Divider style={{ margin: '15px 0px' }} />
            {/* -------------------- UPLOADED PHOTO -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Photo
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputFile inputId="photo" />
                </Grid>
            </Grid>
            {/* -------------------- UPLOADED FILE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Support pédagogique
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputFile inputId="file" />
                </Grid>
            </Grid>
            {/* -------------------- REMOVE FILE -------------------- */}
            {(state.actionType === 'UPDATE' && state.document_url) && (
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={false} sm={4} />
                    <Grid item xs={12} sm={8} container alignItems="center">
                        <Button
                            onClick={() => saveAs(fileUrl + state.document_url, 'Support pédagogique' + getFileExtension(state.document_url))}
                            variant="contained"
                            color="secondary"
                        >
                            Télécharger
                        </Button>
                        <Checkbox
                            checked={state.remove_documentation}
                            onChange={e => setState({ ...state, remove_documentation: e.target.checked })}
                            style={{ padding: 6, marginLeft: 10 }}
                        />
                        Supprimer le document
                    </Grid>
                </Grid>
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT CATEGORIES -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SelectCategories = ({ categories, setCategories }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getCategories().then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.categories.map(c => ({ label: c.name, value: c.id })));
            }
        })
    }, []);

    return (
        <SelectMultiple
            value={categories}
            onChange={categories => setCategories(categories)}
            options={options}
        />
    );
};

export default FormationForm;
