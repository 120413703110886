import React from 'react';

// SimpleBar
import SimpleBar from 'simplebar-react';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SCROLLBAR ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const Scrollbar = props => {
    return (
        <SimpleBar
            forceVisible={true}
            autoHide={false}
            style={{ width: '100%', maxWidth: '100%', maxHeight: '100%' }}
        >
            {props.children}
        </SimpleBar>
    );
};

export default Scrollbar;
