import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, Switch, Route, useParams } from 'react-router-dom';

// Material-UI
import { Button, Grid } from '@material-ui/core';

// Services
import { getBill } from 'services/requests';
import { userCanUpdateBill } from 'services/permissions';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import PageNotFound from 'components/page-not-found';

// Views
import BillDetails from './bill-details';
import BillState from './bill-state';
import ExternalBill from './external-bill';
import BillItems from './bill-items';
import BillPayments from './bill-payments';
import BillRefunds from './bill-refunds';
import DeleteBill from './delete-bill';
import PaymentMethod from '../payment-method';

/* * *
* Page de détails d'un bon de commande
*/

// -------------------------------------------------------------------------------------- \\
// ------------------------------------- BILL ROUTER ------------------------------------ \\
// -------------------------------------------------------------------------------------- \\

const BillRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Bill />
            </Route>
            <Route exact path={`${match.path}/payment-method`}>
                <PaymentMethod />
            </Route>
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// -------------------------------------------------------------------------------------- \\
// ---------------------------------------- BILL ---------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const Bill = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const path = match.path;
    const { mediaMD } = useMediaQueries();
    const { userId, billId } = useParams();

    const getBackUrl = () => {
        if (userId) {
            return '/users/' + userId;
        }
        if (path.includes('memberships')) {
            return '/management/memberships';
        }
        if (path.includes('subscriptions')) {
            return '/management/subscriptions';
        }
        return match.url.substring(0, match.url.lastIndexOf('/'));
    };

    const [state, setState] = useState({
        loading: true,
        error: false,
        bill: null,
        openDelete: false,

        // Bill State
        openBillState: false,

        // External Bill
        openExternal: false,

        // Payments
        payment: null,
        left_to_pay: 0,
        total_paid: 0,
        total_refunded: 0,
        openCreatePayment: false,
        openUpdatePayment: false,
        openRecoverPayment: false,
        openCancelledPayment: false,
        openDeletePayment: false,

        // Custom Items
        customItem: null,
        openAddCustom: false,
        openDeleteCustom: false,

        // Refunds
        billItem: null,
        refund: null,
        openCreateRefund: false,
        openUpdateRefund: false,
    });

    useEffect(() => {
        if (state.loading) {
            getBill(billId).then(res => {
                if (res.status === 200) {

                    const bill = res.data.data.bill;

                    let total_refunded = 0;
                    bill.refunds.forEach(refund => {
                        total_refunded += refund.amount;
                    });

                    setState({
                        ...state,
                        loading: false,
                        bill,
                        left_to_pay: bill.leftToPay,
                        total_paid: bill.amountPaid,
                        total_refunded,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push(getBackUrl())}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ padding: mediaMD ? 0 : 10 }}
                >
                    Détails du bon de commande
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-end' : 'center'}
                >
                    {state.bill.status === 'payment_waiting' && (
                        <Button onClick={() => setState({ ...state, openDelete: true })} variant="contained">
                            Annuler la commande
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- CONTAINER -------------------- */}
            <Grid
                container
                style={{ padding: 24 }}
            >
                <Grid
                    item xs={false} sm={1} md={2}
                />
                <Grid item xs={12} sm={10} md={8}>
                    {/* -------------------- BILL DETAILS -------------------- */}
                    <BillDetails
                        bill={state.bill}
                    />
                    {/* -------------------- BILL STATE -------------------- */}
                    {(state.bill.user && userCanUpdateBill(state.bill.user.federatedAssociation)) && (
                        <BillState
                            state={state}
                            setState={setState}
                        />
                    )}
                    {/* -------------------- EXTERNAL BILL -------------------- */}
                    <ExternalBill
                        state={state}
                        setState={setState}
                    />
                    {/* -------------------- ITEMS -------------------- */}
                    <BillItems
                        state={state}
                        setState={setState}
                    />
                    {/* -------------------- PAYMENTS -------------------- */}
                    <BillPayments
                        state={state}
                        setState={setState}
                    />
                    {/* -------------------- REFUNDS -------------------- */}
                    {(state.bill.refunds && state.bill.refunds.length > 0) && (
                        <BillRefunds
                            state={state}
                            setState={setState}
                        />
                    )}
                </Grid>
            </Grid>
            {/* -------------------- DELETE BILL -------------------- */}
            {state.openDelete && (
                <DeleteBill
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => setState({ ...state, openDelete: false, loading: true })}
                    bill={state.bill}
                />
            )}
        </>
    );
};

export default BillRouter;
