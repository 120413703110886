import React from 'react';

// Material-UI
import { Grid, MenuItem } from '@material-ui/core';

// Services
import { federatedAssociationTypes } from 'services/constants';

// Components
import InputText from 'components/input-text';
import InputFile from 'components/input-file';
import SelectOutlined from 'components/select-outlined';
import FieldRequired from 'components/field-required';

// Selects
import SelectCountry from 'selects/select-country';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------- FEDERATED ASSOCIATION FORM --------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

export default function FederatedAssociationForm({ state, setState }) {
    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Nom de l'association fédérée</span><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom de l'association fédérée"
                        type="text"
                        value={state.name}
                        onChange={e => setState({ ...state, name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- IS ROOT -------------------- */}
            <Grid container alignItems="center" justify="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    Type<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectOutlined
                        value={state.type}
                        onChange={e => setState({ ...state, type: e.target.value })}
                    >
                        <MenuItem value={-1}>
                            <em>Type</em>
                        </MenuItem>
                        {federatedAssociationTypes.map(type => (
                            <MenuItem key={type.value} value={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- ADDRESS -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Adresse</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Adresse"
                        value={state.address}
                        onChange={e => setState({ ...state, address: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- POSTAL CODE -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Code postal</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Code postal"
                        value={state.postal_code}
                        onChange={e => setState({ ...state, postal_code: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- CITY -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Ville</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Ville"
                        value={state.city}
                        onChange={e => setState({ ...state, city: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- COUNTRY -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Pays</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectCountry
                        countryId={state.country_id}
                        setCountryId={country_id => setState({ ...state, country_id })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- DOCUMENTATION -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    <span>Document de présentation</span>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputFile inputId="file" />
                </Grid>
            </Grid>
        </>
    );
}
