import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { userCanDeleteUser, userCanUpdateUserSettings } from 'services/permissions';
import { getUser } from 'services/requests';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import useMediaQueries from 'services/media';

// Views
import DeleteUser from '../delete-user';
import UserSettings from './user-settings';
import UserAttributes from './user-attributes';

// ------------------------------------------------------------------------------------------ \\
// -------------------------------------- UPDATE USER --------------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const UpdateUser = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();

    // Params
    const userId = match.params.userId;
    const backUrl = match.path.split('/:userId')[0];

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: null,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getUser(userId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, user: res.data.data.user });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push(`${backUrl}/${userId}`)}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    <b>Modifier l'utilisateur</b>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-end' : 'center'}
                >
                    {userCanDeleteUser(userId) && (
                        <Button
                            onClick={() => setState({ ...state, openDelete: true })}
                            variant="contained"
                        >
                            Supprimer
                        </Button>
                    )}
                </Grid>
            </Grid>
            <div
                className="light-background-color"
                style={{ padding: 24 }}
            >
                {/* -------------------- USER SETTINGS -------------------- */}
                {userCanUpdateUserSettings() && (
                    <UserSettings
                        user={state.user}
                    />
                )}
                {/* -------------------- USER ATTRIBUTES -------------------- */}
                <UserAttributes
                    user={state.user}
                />
            </div>
            {/* -------------------- DELETE USER -------------------- */}
            {state.openDelete && (
                <DeleteUser
                    onClose={() => setState({ ...state, openDelete: false })}
                    user={state.user}
                />
            )}
        </>
    );
};

export default UpdateUser;
