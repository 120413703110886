import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';

// Services
import { getSessionDay, updateSessionDay, removeSessionDay } from 'services/requests';
import useMediaQueries from 'services/media';
import { getGeocode } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import SessionDayForm from './session-days-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------ SESSION DAY ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UpdateSessionDay = () => {
    const { mediaMD } = useMediaQueries();
    const { sessionDayId } = useParams();
    const history = useHistory();

    const [state, setState] = useState({
        loading: true,
        error: false,
        session_day: undefined,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getSessionDay(sessionDayId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, session_day: res.data.data.sessionDay });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Modifier la journée de formation
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={() => setState({ ...state, openDelete: true })} variant="contained">
                        Supprimer
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- UPDATE -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <SetSessionDay
                    session_day={state.session_day}
                />
            </Grid>
            <Divider />
            {/* -------------------- REMOVE -------------------- */}
            {state.openDelete && (
                <RemoveSessionDay onClose={() => setState({ ...state, openDelete: false })} />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- UPDATE SESSION DAY ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SetSessionDay = ({ session_day }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        session_date: session_day.session_date || null,
        training_place: session_day.trainingPlace ? ({ value: session_day.trainingPlace.id, label: session_day.trainingPlace.name }) : null,
        address: session_day.address || '',
        city: session_day.city || '',
        postal_code: session_day.postal_code || '',
        french_department_id: session_day.french_department_id || -1,
        country_id: session_day.country_id || 1,
        longitude: session_day.longitude || '',
        latitude: session_day.latitude || '',
        geocode: getGeocode(session_day),
    });

    function onSave() {
        if (state.session_date == null) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            if ((state.latitude === '' || state.longitude === '')
                && state.country_id === 1
                && state.address !== ''
                && state.postal_code !== ''
                && state.city !== ''
            ) {
                getCoordinates(state.address, state.postal_code, state.city);
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    }

    function getCoordinates(address, postal_code, city) {
        axios.get(
            `https://api-adresse.data.gouv.fr/search/?q=${address}
                &postcode=${postal_code}
                &city=${city}
                &limit=20`
        )
        .then(res => {
            if (res.status === 200) {
                const features = res.data.features;
                if (features && features.length > 0) {
                    setState({
                        ...state,
                        loading: true,
                        longitude: features[0].geometry.coordinates[0],
                        latitude: features[0].geometry.coordinates[1],
                    });
                }
                else {
                    setState({ ...state, loading: true });
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: true });
            }
        });
    }

    useEffect(() => {
        if (state.loading) {
            updateSessionDay(session_day.id, {
                session_date: moment(state.session_date).format('YYYY-MM-DD'),
                training_place_id: state.training_place ? state.training_place.value : null,
                address: state.address,
                city: state.city,
                postal_code: state.postal_code,
                french_department_id: state.french_department_id !== -1 ? state.french_department_id : null,
                country_id: state.country_id !== -1 ? state.country_id : null,
                longitude: state.longitude,
                latitude: state.latitude,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                }
                setState({ ...state, loading: false });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <>
            {/* -------------------- SESSION DAY FORM -------------------- */}
            <Grid item xs={12} md={10} lg={8}>
                <SessionDayForm
                    state={state}
                    setState={setState}
                />
            </Grid>
            {/* -------------------- SAVE BUTTON -------------------- */}
            <Grid item xs={12} md={2} lg={4} container alignItems="center" justify="flex-end">
                <Button onClick={onSave} variant="contained">
                    Enregistrer
                </Button>
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- REMOVE SESSION DAY ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const RemoveSessionDay = ({ onClose }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { formationId, sessionId, sessionDayId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            removeSessionDay(sessionDayId).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/management/formations/' + formationId + '/session/' + sessionId);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Supprimer la journée de formation
                </p>
                Êtes-vous sûr de vouloir supprimer cette journée de formation ?
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Non
                </Button>
                <Button onClick={() => setLoading(true)} variant="contained">
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateSessionDay;
