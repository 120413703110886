import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, CircularProgress } from '@material-ui/core';

// Services
import { createSubscription } from 'services/requests';
import { isAuthenticated, setStoredSubscription } from 'services/storage';
import { amountToSend } from 'services/helpers';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------- \\
// ----------------------------------- SUBSCRIPTION SUBMIT --------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const SubscriptionSubmit = ({ userId, subscriptionType, state, setState }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    function onSubmit() {
        if (
            state.first_name === ''
            || state.last_name === ''
            || state.address === ''
            || state.city === ''
            || state.postal_code === ''
            || state.country_id === -1
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (
            state.phone === ''
            && state.mobile_phone === ''
        ) {
            enqueueSnackbar('Veuillez renseigner au moins 1 numéro de téléphone', { variant: 'warning' });
        }
        else if (
            subscriptionType === 'membership'
            && state.membership_type?.is_pro === 1
            && state.company_name === ''
        ) {
            enqueueSnackbar('Veuillez renseigner le nom de votre entreprise', { variant: 'warning' });
        }
        else if (
            state.subscription_type_id === 1
            && state.email === ''
        ) {
            enqueueSnackbar('Veuillez saisir votre email', { variant: 'warning' });
        }
        else if (
            state.membership_type_id === 2
            && !state.second_user_is_verified
        ) {
            enqueueSnackbar('Veuillez saisir et vérifier l\'email du second compte qui bénéficiera de l\'adhésion couple', { variant: 'warning' });
        }
        else if (
            state.membership_type_id === 2
            && state.second_user_is_verified
            && state.second_user_not_exist
            && (
                state.second_user_first_name === ''
                || state.second_user_last_name === ''
            )
        ) {
            enqueueSnackbar('Veuillez saisir le nom et prénom du second utilisateur', { variant: 'warning' });
        }
        else if (
            state.different_bill_address
            && (
                state.bill_first_name === ''
                || state.bill_last_name === ''
                || state.bill_address === ''
                || state.bill_city === ''
                || state.bill_postal_code === ''
                || state.bill_country_id === -1
            )
        ) {
            enqueueSnackbar('Veuillez saisir les champs requis de votre adresse de facturation', { variant: 'warning' });
        }
        else if (
            subscriptionType === 'membership'
            && state.main_activity_id === -1
        ) {
            enqueueSnackbar('Veuillez renseigner votre activité principale', { variant: 'warning' });
        }
        else if (!state.cgv || !state.pdc) {
            enqueueSnackbar('Veuillez accepter les CGV et la politique de confidentialité', { variant: 'warning' });
        }
        else {
            setState({ ...state, loadingCreate: true });
        }
    }

    useEffect(() => {
        if (state.loadingCreate) {

            const data = {
                subscription_type_id: state.subscription_type_id,
                membership_type_id: state.membership_type_id,
                first_name: state.first_name,
                last_name: state.last_name,
                company_name: state.company_name,
                address: state.address,
                address_details: state.address_details,
                city: state.city,
                postal_code: state.postal_code,
                country_id: state.country_id,
                email: state.email,
                contribution_amount: amountToSend(state.contribution_amount),
                payment_method: state.payment_method,
                different_bill_address: state.different_bill_address,
                only_mabd: state.only_mabd,
                is_demeter: state.is_demeter,
                phone: state.phone,
                mobile_phone: state.mobile_phone,
            };

            if (state.membership_type_id === 2 && !state.second_user_not_exist) {
                data.second_user_email = state.second_user_email;
            }
            if (state.membership_type_id === 2 && state.second_user_not_exist) {
                data.second_user_email = state.second_user_email;
                data.second_user_first_name = state.second_user_first_name;
                data.second_user_last_name = state.second_user_last_name;
            }

            if (state.different_bill_address) {
                data.bill_first_name = state.bill_first_name;
                data.bill_last_name = state.bill_last_name;
                data.bill_company_name = state.bill_company_name;
                data.bill_address = state.bill_address;
                data.bill_address_details = state.bill_address_details;
                data.bill_city = state.bill_city;
                data.bill_postal_code = state.bill_postal_code;
                data.bill_country_id = state.bill_country_id;
                data.bill_email = state.bill_email;
            }
            
            if (subscriptionType === 'membership') {
                data.main_activity_id = state.main_activity_id;
                data.secondary_activity_ids = state.secondary_activities.map(a => a.value);
                data.profile = state.profile;
                data.next_year = state.next_year;
            }

            if (subscriptionType === 'subscription' && state.membership_type_id != null) {
                data.main_activity_id = 4;
            }

            if (state.wants_visit === '1') {
                data.wants_visit = state.wants_visit;
                data.agricultural_area = state.agricultural_area;
                data.is_in_conventional = state.is_in_conventional;
                data.is_in_conversion = state.is_in_conversion;
                data.is_in_bio = state.is_in_bio;
                data.is_in_biodynamic = state.is_in_biodynamic;
                data.is_certified_in_biodynamics = state.is_certified_in_biodynamics;
                data.no_knowledge_about_biodynamic = state.no_knowledge_about_biodynamic;
                data.has_followed_biodynamic_training = state.has_followed_biodynamic_training;
                data.practice_biodynamics = state.practice_biodynamics;
                data.wish_diagnostic = state.wish_diagnostic;
                data.wish_practice_biodynamics = state.wish_practice_biodynamics;
                data.wish_to_be_accompanied = state.wish_to_be_accompanied;
                data.has_manual_energizer = state.has_manual_energizer;
                data.has_mechanical_energizer = state.has_mechanical_energizer;
                data.has_backpack_sprayer = state.has_backpack_sprayer;
                data.has_mechanical_sprayer = state.has_mechanical_sprayer;
                data.comments = state.comments;
            }

            if (isAuthenticated()) {
                data.user_id = userId;
                createSubscription(data).then(res => {
                    if (res.status === 200) {
                        enqueueSnackbar(res.data.message, { variant: 'success' });
                        history.push('/payment-method/' + res.data.data.bill.id);
                    }
                    else {
                        enqueueSnackbar(res, { variant: 'error' });
                        setState({ ...state, loadingCreate: false });
                    }
                });
            }
            else {
                data.subscriptionType = subscriptionType;
                setStoredSubscription(data);
                history.push('/purchase-registration');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingCreate]);

    return (
        <Button
            variant="contained"
            onClick={onSubmit}
        >
            {state.loadingCreate ? (
                <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
                <>
                    {subscriptionType === 'membership' && 'Soumettre ma demande d\'adhésion'}
                    {subscriptionType === 'subscription' && 'Soumettre ma demande d\'abonnement'}
                </>
            )}
        </Button>
    );
};

export default SubscriptionSubmit;
