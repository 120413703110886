import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// File-Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Grid, Button, Divider, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon, SaveAlt as SaveIcon } from '@material-ui/icons';

// Services
import { getFormationDocuments } from 'services/requests';
import { fileUrl } from 'services/axios-config';
import { getFileExtension } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Tooltip from 'components/tooltip';

// Views
import AddFormationDocument from './formation-documents-add';
import RemoveFormationDocument from './formation-documents-remove';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ FORMATION DOCUMENTS -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationDocuments = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { sessionId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        documents: [],
        formationSessionNumber: '',
        document: null,
        openAdd: false,
        openRemove: false,
    });

    useEffect(() => {
        if (state.loading) {
            getFormationDocuments(sessionId).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        documents: res.data.data.formationDocuments,
                        formationSessionNumber: res.data.data.formationSessionNumber,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, error: true });
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Documents associés à la formation #{state.formationSessionNumber}
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={() => setState({ ...state, openAdd: true })} variant="contained">
                        Ajouter un document
                    </Button>
                </Grid>
            </Grid>
            <div className="main-container" style={{ marginTop: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                <Grid container alignItems="center" className="list-headers">
                    <Grid item xs={5} style={{ padding: 10 }}>
                        Nom
                    </Grid>
                    <Grid item xs={5} style={{ padding: 10 }}>
                        Document
                    </Grid>
                    <Grid item xs={2} style={{ padding: 10, textAlign: 'center' }}>
                        Supprimer
                    </Grid>
                </Grid>
                {/* -------------------- LIST -------------------- */}
                <FormationDocumentList
                    state={state}
                    setState={setState}
                />
            </div>
            {/* -------------------- ADD -------------------- */}
            {state.openAdd && (
                <AddFormationDocument
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={() => setState({ ...state, openAdd: false, loading: true })}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------- FORMATION DOCUMENT LIST ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const FormationDocumentList = ({ state, setState }) => {

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <div className="main-container-item">
                {state.documents.length > 0 ? (
                    state.documents.map(document => (
                        <Fragment key={document.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={5} style={{ padding: 6 }}>
                                    {document.name}
                                </Grid>
                                <Grid item xs={5} style={{ padding: 6 }}>
                                    <Button
                                        onClick={() => saveAs(fileUrl + document.document_url, document.name + getFileExtension(document.name))}
                                        color="secondary"
                                        style={{ textTransform: 'none' }}
                                    >
                                        Télécharger
                                        <SaveIcon style={{ marginLeft: 5 }} />
                                    </Button>
                                </Grid>
                                <Grid item xs={2} style={{ padding: 6, textAlign: 'center' }}>
                                    <Tooltip title="Supprimer"
                                        item={(
                                            <IconButton
                                                onClick={() => setState({ ...state, openRemove: true, document })}
                                                style={{ padding: 6 }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )} 
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid style={{ padding: 10 }}>
                            Aucun
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- REMOVE -------------------- */}
            {state.openRemove && (
                <RemoveFormationDocument
                    onClose={() => setState({ ...state, openRemove: false })}
                    refetch={() => setState({ ...state, openRemove: false, loading: true })}
                    document={state.document}
                />
            )}
        </>
    );
};

export default FormationDocuments;
