import React, { useState, useEffect } from 'react';

// Services
import { getCategories } from 'services/requests';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- SELECT CATEGORIES ------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const SelectCategories = ({ categories, setCategories }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getCategories().then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.categories.map(c => ({ label: c.name, value: c.id })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <SelectMultiple
            value={categories}
            onChange={value => setCategories(value)}
            options={options}
            placeholder="Catégorie"
        />
    );
};

export default SelectCategories;
