import React, { useState, useEffect, Fragment } from 'react';

// Material-UI
import { Divider } from '@material-ui/core';

// Services
import { getCategories } from 'services/requests';
import { fileUrl } from 'services/axios-config';

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- CATEGORIES -------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const Categories = ({ parentState, setParentState, mapHeight }) => {

    const [state, setState] = useState({
        loading: true,
        error: false,
        categories: [],
    });

    function onCategoryClick(categoryId) {

        if (categoryId === parentState.category_id) {
            categoryId = -1;
        }

        setParentState({
            ...parentState, 
            loading: true,
            error: false,
            offset: 0,
            category_id: categoryId,
        });
    };

    useEffect(() => {
        if (state.loading) {
            getCategories().then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, categories: res?.data?.data?.categories });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return null;
    if (state.error) return null;

    return (
        <div style={{ maxHeight: mapHeight, overflow: 'auto' }}>
            {state.categories.map((category, index) => (
                <Fragment key={category.id}>
                    {index === 0 && (
                        <Divider />
                    )}
                    <div
                        style={{ padding: 10 }}
                        onClick={() => onCategoryClick(category.id)}
                        className={parentState.category_id === category.id ? 'simple-item-selected' : 'simple-list'}
                    >
                        <img
                            alt={`category/img/${category.id}`}
                            src={fileUrl + category.icon_url}
                            style={{ height: 30, width: 'auto', marginRight: 12 }}
                        />
                        <span>
                            {category.name}
                        </span>
                    </div>
                    <Divider />
                </Fragment>
            ))}
        </div>
    );
};

export default Categories;
