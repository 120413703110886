import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, MenuItem } from '@material-ui/core';

// Services
import { getMembershipTypes, createTransfer, getFederatedAssociationList } from 'services/requests';
import { isValidAmount, amountToSend } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import InputText from 'components/input-text';
import SelectOutlined from 'components/select-outlined';

/* * *
* Les montants initiaux à transférer à l'association fédérée sélectionnée selon le type d'adhésion sont définis dans le useEffect
* À modifier si besoin
*/

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- CREATE TRANSFER --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const CreateTransfer = () => {
    const history = useHistory();
    const { mediaSM, mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: true,
        loadingCreate: false,
        error: false,
        federated_association_id: -1,
        membership_types: [],
        amounts_for_association: {},
    });

    function onCreate() {
        if (state.federated_association_id === -1) {
            enqueueSnackbar('Veuillez sélectionner une association fédérée', { variant: 'warning' });
        }
        else {
            setState({ ...state, loadingCreate: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            getMembershipTypes().then(res => {
                if (res.status === 200) {

                    const membership_types = res.data.data.membershipTypes;
                    let amounts_for_association = {};

                    // Montants par défaut
                    membership_types.forEach(membership_type => {
                        switch (membership_type.id) {

                            case 1 : // Adhésion simple
                                amounts_for_association[membership_type.id] = '11';
                                break;

                            case 2: // Adhésion couple
                                amounts_for_association[membership_type.id] = '16.50';
                                break;

                            default: // Autres types d'adhésions
                                amounts_for_association[membership_type.id] = '20';
                                break;
                        }

                    });

                    setState({ ...state, loading: false, membership_types, amounts_for_association });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingCreate) {

            let amounts_for_association = [];
            Object.entries(state.amounts_for_association).forEach(([key, value]) => {
                amounts_for_association.push({
                    membership_type_id: key,
                    amount_for_association: (isValidAmount(value) ? amountToSend(value) : 0),
                });
            });

            createTransfer({
                federated_association_id: state.federated_association_id,
                amounts_for_association,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/management/transfers');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingCreate: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingCreate]);

    if (state.loading || state.loadingCreate) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management/transfers')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Ajouter un transfert
                </Grid>
            </Grid>
            <Grid container style={{ padding: 24 }}>
                <Grid item xs={12} sm={8} md={6}>
                    {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                    <FederatedAssociation
                        state={state}
                        setState={setState}
                    />
                    {/* -------------------- MEMBERSHIP TYPES -------------------- */}
                    <MembershipTypes
                        state={state}
                        setState={setState}
                    />
                </Grid>
                <Grid
                    item xs={12} sm={4} md={6}
                    container alignItems="center" justify={mediaSM ? 'flex-end' : 'center'}
                    style={{ padding: '24px 0px' }}
                >
                    <Button onClick={onCreate} variant="contained">
                        Ajouter transfert
                    </Button>
                </Grid>
            </Grid>
            <Divider />
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- FEDERATED ASSOCIATION ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FederatedAssociation = ({ state, setState }) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                Sélectionner une association fédérée
            </Grid>
            <Grid item xs={12} md={8}>
                <SelectFederatedAssociation
                    federatedAssociationId={state.federated_association_id}
                    setFederatedAssociationId={federated_association_id => setState({ ...state, federated_association_id })}
                />
            </Grid>
        </Grid>
    )
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- MEMBERSHIP TYPES -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MembershipTypes = ({ state, setState }) => {

    function onChange(value, membership_type_id) {
        let amounts_for_association = state.amounts_for_association;
        amounts_for_association[membership_type_id] = value;
        setState({ ...state, amounts_for_association });
    }

    return (
        <>
            <Grid style={{ marginTop: 24 }}>
                Saissisez un montant pour chaque adhésion du MABD
            </Grid>
            {state.membership_types.map((membership_type, index) => (
                <Grid
                    key={membership_type.id}
                    container alignItems="center"
                    style={{ marginTop: 10 }}
                >
                    <Grid item xs={12} md={4}>
                        {membership_type.is_pro === 0 && (
                            <b>{membership_type.name}</b>
                        )}
                        {membership_type.is_pro === 1 && (
                            <>
                                <b>Adhésion professionnelle</b>
                                <br />
                                {membership_type.name}
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <InputText
                            label="Montant (€)"
                            type="number"
                            value={state.amounts_for_association[membership_type.id]}
                            onChange={e => onChange(e.target.value, membership_type.id)}
                        />
                    </Grid>
                </Grid>
            ))}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------- SELECT FEDERATED ASSOCIATION -------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SelectFederatedAssociation = ({ federatedAssociationId, setFederatedAssociationId }) => {
    const [federatedAssociations, setFederatedAssociations] = useState([]);

    useEffect(() => {
        getFederatedAssociationList().then(res => {
            if (res.status === 200) {
                setFederatedAssociations(res.data.data.federatedAssociations.filter(a => a.id !== 1));
            }
        });
    }, []);

    return (
        <SelectOutlined
            value={federatedAssociationId}
            onChange={e => setFederatedAssociationId(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>Association fédérée</em>
            </MenuItem>
            {federatedAssociations.map(federatedAssociation => (
                <MenuItem key={federatedAssociation.id} value={federatedAssociation.id}>
                    {federatedAssociation.name}
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

export default CreateTransfer;
