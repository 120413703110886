import React, { useState, useEffect } from 'react';

// Material-UI
import { MenuItem } from '@material-ui/core';

// Services
import { getCountries } from 'services/requests';

// Components
import SelectOutlined from 'components/select-outlined';

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT COUNTRY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectCountry = ({ countryId, setCountryId }) => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountries().then(res => {
            if (res.status === 200) {
                setCountries(res.data.data.countries);
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <SelectOutlined
            value={countryId}
            onChange={e => setCountryId(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>Pays</em>
            </MenuItem>
            {countries.map(country => (
                <MenuItem
                    key={country.id}
                    value={country.id}
                >
                    {country.name}
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

export default SelectCountry;
