import React, { useEffect, useState, Fragment } from 'react';
import { useHistory, useParams, Switch, Route, useRouteMatch } from 'react-router-dom';

// File-Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getManagementFormation } from 'services/requests';
import { userCanUpdateFormation, userCanCreateFormationSession } from 'services/permissions';
import { fileUrl } from 'services/axios-config';
import { formationSessionStatuses } from 'services/constants';
import { getLabelStatus, getColorStatus, getFileExtension } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import PageNotFound from 'components/page-not-found';

// Views
import FormationUpdate from './update-formation';
import FormationSession from './formation-session';
import AddFormationSession from './formation-session/formation-session-add';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- FORMATION ROUTER -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Formation />
            </Route>
            {userCanUpdateFormation() && (
                <Route exact path={`${match.path}/update`}>
                    <FormationUpdate />
                </Route>
            )}
            <Route path={`${match.path}/session/:sessionId`}>
                <FormationSession />
            </Route>
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------ FORMATION ------------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const Formation = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { formationId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formation: undefined,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getManagementFormation(formationId, { management: '1' }).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, formation: res.data.data.formation });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management/formations')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    {state.formation.title}
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanUpdateFormation() && (
                        <Button
                            onClick={() => history.push(match.url + '/update')}
                            variant="contained"
                        >
                            Modifier
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- FORMATION INFOS -------------------- */}
            <Grid style={{ padding: 24 }}>
                <FormationInfos formation={state.formation} />
            </Grid>
            <Divider />
            {/* -------------------- FORMATION SESSIONS -------------------- */}
            <FormationSessions
                refetch={() => setState({ ...state, loading: true })}
                formation={state.formation}
            />
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- FORMATION INFOS ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationInfos = ({ formation }) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs={12} md={6}>
                {/* -------------------- DURATION -------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                        Durée
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {formation.duration}h
                    </Grid>
                </Grid>
                {/* -------------------- DURATION -------------------- */}
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4}>
                        Catégorie(s)
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {formation.categories.map((category, index) => {
                            if (index === 0) return category.name;
                            else return ', ' + category.name;
                        })}
                    </Grid>
                </Grid>
                {/* -------------------- DESCRIPTION -------------------- */}
                {formation.description && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Objectifs
                        </Grid>
                        <Grid item xs={12} sm={8} style={{ whiteSpace: 'pre-line' }}>
                            {formation.description}
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- PREREQUISITE -------------------- */}
                {formation.prerequisite && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Prérequis
                        </Grid>
                        <Grid item xs={12} sm={8} style={{ whiteSpace: 'pre-line' }}>
                            {formation.prerequisite}
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- DOCUMENT -------------------- */}
                {formation.document_url && (
                    <Grid container alignItems="center" style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={4}>
                            Support pédagogique
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Button
                                onClick={() => saveAs(fileUrl + formation.document_url, 'Support pédagogique' + getFileExtension(formation.document_url))}
                                variant="contained"
                                color="secondary"
                            >
                                Télécharger
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {/* -------------------- PHOTO -------------------- */}
            <Grid item xs={12} md={6} container justify="flex-end" alignItems="center">
                {formation.photo_url && (
                    <img
                        alt="formation"
                        src={fileUrl + formation.photo_url}
                        style={{ maxHeight: 220 }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- FORMATION SESSIONS -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSessions = ({ refetch, formation }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        sessions: formation.formationSessions,
        total_count: formation.formationSessions.length,
        openAdd: false,
    });

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <b>LISTE DES FORMATIONS</b>
                {userCanCreateFormationSession() && (
                    <Button
                        onClick={() => setState({ ...state, openAdd: true })}
                        variant="contained"
                        style={{ textTransform: 'none' }}
                    >
                        Ajouter une formation
                    </Button>
                )}
            </Grid>
            <div className="main-container">
                <div className="main-container-item">
                    <div className="list-sticky">
                        {/* -------------------- HEADER LIST -------------------- */}
                        <Grid container alignItems="center" className="list-headers">
                            {mediaMD ? (
                                <>
                                    <Grid item xs={2} style={{ padding: 10 }}>
                                        Statut
                                    </Grid>
                                    <Grid item xs={2} style={{ padding: 10 }}>
                                        Formation
                                    </Grid>
                                    <Grid item xs={2} style={{ padding: 10 }}>
                                        Responsable
                                    </Grid>
                                    <Grid item xs={2} style={{ padding: 10 }}>
                                        Animateur
                                    </Grid>
                                    <Grid item xs={2} style={{ padding: 10 }}>
                                        Formateur(s)
                                    </Grid>
                                    <Grid item xs={2} style={{ padding: 10 }}>
                                        Département(s)
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12} style={{ padding: 10 }}>
                                    Sessions de formation
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    {/* -------------------- SESSION LIST -------------------- */}
                    <div style={{ paddingBottom: 24 }}>
                        {state.sessions.length > 0 ? (
                            state.sessions.map(session => (
                                <Fragment key={session.id}>
                                    <Grid
                                        onClick={() => history.push(`${match.url}/session/${session.id}`)}
                                        className="simple-list"
                                        container alignItems="center"
                                    >
                                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                            <b style={{ color: getColorStatus(formationSessionStatuses, session.status) }}>
                                                {getLabelStatus(formationSessionStatuses, session.status)}
                                            </b>
                                        </Grid>
                                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                            #{session.number}
                                        </Grid>
                                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                            {session.manager && session.manager.fullName}
                                        </Grid>
                                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                            {session.animator && session.animator.fullName}
                                        </Grid>
                                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                            {session.trainers.map((trainer, index) => {
                                                if (index === 0) return trainer.fullName;
                                                else return ', ' + trainer.fullName;
                                            })}
                                        </Grid>
                                        <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                            {session.frenchDepartments.map((fd, index) => {
                                                if (index === 0) return fd.name + ' (' + fd.number + ')';
                                                else return ', ' + fd.name + ' (' + fd.number + ')';
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </Fragment>
                            ))
                        ) : (
                            <>
                                <Grid container style={{ padding: 10 }}>
                                    Aucune
                                </Grid>
                                <Divider />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Divider />
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            {/* -------------------- ADD FORMATION SESSION -------------------- */}
            {state.openAdd && (
                <AddFormationSession
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={() => refetch()}
                    formation={formation}
                />
            )}
        </>
    );
};

export default FormationRouter;
