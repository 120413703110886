import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// Selects
import SelectCountry from 'selects/select-country';

// Views
import Geocode from './geocode';
import PostalCodeAndCityInputs from './postal-code-and-city-inputs';
import SelectFrenchDepartment from './select-french-department';

/* * *
* Formulaire identique au dossier "address-form" utilisé pour l'adresse de facturation
*/

// ------------------------------------------------------------------------------------------ \\
// ------------------------------------ BILL ADDRESS FORM ----------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const BillAddressForm = ({
    state,
    setState,
    addressLabel,
    requiredAddress,
    requiredPostalCode,
    requiredCity,
    requiredCountry,
    requiredDepartment,
    disabledDetailsAddress,
    disabledDepartment,
}) => {

    function handleChangeGeocode(geocode) {
        if (geocode) {
            setState({
                ...state,
                bill_geocode: geocode,
                bill_postal_code: geocode.value.split('&')[0],
                bill_city: geocode.value.split('&')[1],
            });
        }
        else {
            setState({ ...state, bill_geocode: null, bill_postal_code: '', bill_city: '' });
        }
    }

    return (
        <>
            {/* -------------------- ADDRESS -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    {addressLabel}{requiredAddress && <FieldRequired />}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label={addressLabel}
                        value={state.bill_address}
                        onChange={e => setState({ ...state, bill_address: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {!disabledDetailsAddress && (
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Adresse facturation (complément)
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Adresse facturation (complément)"
                            value={state.bill_address_details}
                            onChange={e => setState({ ...state, bill_address_details: e.target.value })}
                        />
                    </Grid>
                </Grid>
            )}
            {/* -------------------- POSTAL CODE AND CITY -------------------- */}
            {state.bill_country_id !== 1 && (
                <PostalCodeAndCityInputs
                    state={state}
                    setState={setState}
                    requiredPostalCode={requiredPostalCode}
                    requiredCity={requiredCity}
                />
            )}
            {/* -------------------- GEOCODE -------------------- */}
            {state.bill_country_id === 1 && (
                <Geocode
                    requiredPostalCode={requiredPostalCode}
                    requiredCity={requiredCity}
                    geocode={state.bill_geocode}
                    setGeocode={geocode => handleChangeGeocode(geocode)}
                />
            )}
            {/* ---------------------- DEPARTMENT ---------------------- */}
            {(!disabledDepartment && state.bill_country_id === 1) && (
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Département{requiredDepartment && <FieldRequired />}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <SelectFrenchDepartment
                            frenchDepartmentId={state.bill_french_department_id}
                            setFrenchDepartmentId={bill_french_department_id => setState({ ...state, bill_french_department_id })}
                            postalCode={state.bill_postal_code}
                            countryId={state.bill_country_id}
                        />
                    </Grid>
                </Grid>
            )}
            {/* ---------------------- COUNTRY ---------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Pays facturation{requiredCountry && <FieldRequired />}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectCountry
                        countryId={state.bill_country_id}
                        setCountryId={bill_country_id => setState({ ...state, bill_country_id })}
                    />
                </Grid>
            </Grid>
        </>
    );
};

// Propriétés par défaut
BillAddressForm.defaultProps = {
    addressLabel: 'Adresse facturation (n° et voie)',
    requiredAddress: false,
    requiredPostalCode: false,
    requiredCity: false,
    requiredCountry: false,
    requiredDepartment: false,
    disabledDetailsAddress: false,
    disabledDepartment: false,
};

export default BillAddressForm;
