import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams, Switch, Route, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogActions, DialogContent } from '@material-ui/core';

// Services
import { getTransfer } from 'services/requests';
import useMediaQueries from 'services/media';
import { getAmountTTC, getFormattedCreatedAt } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import MembershipsCollectedByAssoc from './memberships-collected-by-assoc';
import MembershipsCollectedByMabd from './memberships-collected-by-mabd';
import SubscriptionsCollectedByAssoc from './subscriptions-collected-by-assoc';
import Bill from 'views/common/bill';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- TRANSFER ROUTER ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const TransferRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Transfer />
            </Route>
            <Route exact path={`${match.path}/:billId`}>
                <Bill />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- TRANSFER -------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Transfer = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { transferId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        transfer: null,
        openAmounts: false,
    });

    useEffect(() => {
        if (state.loading) {
            getTransfer(transferId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, transfer: res.data.data.transfer });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading])

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management/transfers')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Transfert {state.transfer ? ('#' + state.transfer.id) : ''}
                </Grid>
            </Grid>
            {/* -------------------- INFOS -------------------- */}
            <Grid style={{ padding: 24 }}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} md={2} className="primary-color">
                        Association fédérée
                    </Grid>
                    <Grid item xs={12} sm={8} md={10}>
                        <b>{state.transfer.federatedAssociation ? state.transfer.federatedAssociation.name : ''}</b>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4} md={2} className="primary-color">
                        Statut
                    </Grid>
                    <Grid item xs={12} sm={8} md={10}>
                        {state.transfer.money_is_transferred === 0 ? (
                            <b className="secondary-color">Non transféré</b>
                        ) : (
                            <b style={{ color: 'green' }}>Transféré</b>
                        )}
                    </Grid>
                </Grid>
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4} md={2} className="primary-color">
                        Montant final à transférer
                    </Grid>
                    <Grid item xs={12} sm={8} md={10}>
                        <b style={{ color: 'gray' }}>
                            {getAmountTTC(state.transfer.amount_to_transfer)}
                        </b>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4} md={2} className="primary-color">
                        Date de création
                    </Grid>
                    <Grid item xs={12} sm={8} md={10}>
                        {getFormattedCreatedAt(state.transfer.created_at)}
                    </Grid>
                </Grid>
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4} md={2} className="primary-color">
                        Dernière mise à jour
                    </Grid>
                    <Grid item xs={12} sm={8} md={10}>
                        {getFormattedCreatedAt(state.transfer.updated_at)}
                    </Grid>
                </Grid>
                <Grid container alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={4} md={2} className="primary-color">
                        Montant revenant à l'association
                    </Grid>
                    <Grid item xs={12} sm={8} md={10}>
                        <Button onClick={() => setState({ ...state, openAmounts: true })} color="secondary">
                            Ouvrir
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- SUBSCRIPTIONS COLLECTED BY ASSOC -------------------- */}
            <SubscriptionsCollectedByAssoc transfer={state.transfer} />
            <Divider />
            {/* -------------------- MEMBERSHIP COLLECTED BY ASSOC -------------------- */}
            <MembershipsCollectedByAssoc transfer={state.transfer} />
            <Divider />
            {/* -------------------- MEMBERSHIP COLLECTED BY MABD -------------------- */}
            <MembershipsCollectedByMabd transfer={state.transfer} />
            {/* -------------------- AMOUNTS MODAL -------------------- */}
            {state.openAmounts && (
                <AmountsForAssociation
                    onClose={() => setState({ ...state, openAmounts: false })}
                    membershipTypes={state.transfer.membershipTypes}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ AMOUNTS FOR ASSOCIATION ---------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const AmountsForAssociation = ({ onClose, membershipTypes }) => {
    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Montant revenant à l'association pour chaque type d'adhésion
                </p>
                {membershipTypes.map((membershipType, index) => (
                    <Fragment key={membershipType.id}>
                        <Grid container>
                            <Grid item xs={12} sm={8} style={{ padding: 10 }}>
                                {membershipType.name}
                            </Grid>
                            <Grid item xs={12} sm={4} style={{ padding: 10 }}>
                                {getAmountTTC(membershipType.amount_for_association)}
                            </Grid>
                        </Grid>
                        {index + 1 !== membershipTypes.length && (
                            <Divider />
                        )}
                    </Fragment>
                ))}
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={onClose} variant="contained">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TransferRouter;
