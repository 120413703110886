import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getConsultingVisit } from 'services/requests';
import { userCanDeleteConsultingVisit } from 'services/permissions';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import SetConsultingVisit from './set-consulting-visit';
import DeleteConsultingVisit from '../delete-consulting-visit';
import ConsultingVisitForm from '../consulting-visit-form';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ CONSULTING VISIT UPDATE ---------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ConsultingVisitUpdate = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { consultingVisitId } = useParams();

    const [state, setState] = useState({
        actionType: 'UPDATE',
        loading: true,
        error: false,
        consulting_visit: undefined,
        openDelete: false,
        agricultural_area: '',
        is_in_conventional: false,
        is_in_conversion: false,
        is_in_bio: false,
        is_in_biodynamic: false,
        is_certified_in_biodynamics: false,
        no_knowledge_about_biodynamic: false,
        has_followed_biodynamic_training: false,
        practice_biodynamics: false,
        wish_diagnostic: false,
        wish_practice_biodynamics: false,
        wish_to_be_accompanied: false,
        has_manual_energizer: false,
        has_mechanical_energizer: false,
        has_backpack_sprayer: false,
        has_mechanical_sprayer: false,
        comments: '',
    });

    function getValue(value) {
        if (value && value === 1) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (state.loading) {
            getConsultingVisit(consultingVisitId).then(res => {
                if (res.status === 200) {
                    const consulting_visit = res.data.data.consultingVisit;

                    setState({
                        ...state,
                        loading: false,
                        consulting_visit,
                        agricultural_area: consulting_visit.agricultural_area || '',
                        is_in_conventional: getValue(consulting_visit.is_in_conventional),
                        is_in_conversion: getValue(consulting_visit.is_in_conversion),
                        is_in_bio: getValue(consulting_visit.is_in_bio),
                        is_in_biodynamic: getValue(consulting_visit.is_in_biodynamic),
                        is_certified_in_biodynamics: getValue(consulting_visit.is_certified_in_biodynamics),
                        no_knowledge_about_biodynamic: getValue(consulting_visit.no_knowledge_about_biodynamic),
                        has_followed_biodynamic_training: getValue(consulting_visit.has_followed_biodynamic_training),
                        practice_biodynamics: getValue(consulting_visit.practice_biodynamics),
                        wish_diagnostic: getValue(consulting_visit.wish_diagnostic),
                        wish_practice_biodynamics: getValue(consulting_visit.wish_practice_biodynamics),
                        wish_to_be_accompanied: getValue(consulting_visit.wish_to_be_accompanied),
                        has_manual_energizer: getValue(consulting_visit.has_manual_energizer),
                        has_mechanical_energizer: getValue(consulting_visit.has_mechanical_energizer),
                        has_backpack_sprayer: getValue(consulting_visit.has_backpack_sprayer),
                        has_mechanical_sprayer: getValue(consulting_visit.has_mechanical_sprayer),
                        comments: consulting_visit.comments || '',
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Visite-conseil : {state.consulting_visit.user.first_name} {state.consulting_visit.user.last_name} ({state.consulting_visit.user.clientCode})
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanDeleteConsultingVisit(state.consulting_visit) && (
                        <Button onClick={() => setState({ ...state, openDelete: true })} variant="contained">
                            Supprimer
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- CONTAINER -------------------- */}
            <Grid container style={{ padding: 24 }}>
                <SetConsultingVisit consulting_visit={state.consulting_visit} />
            </Grid>
            <Divider />
            {/* -------------------- FORM -------------------- */}
            <Grid container style={{ padding: 24 }}>
                <Grid item xs={false} sm={1} md={3} />
                <Grid item xs={12} sm={10} md={6}>
                    <ConsultingVisitForm state={state} setState={setState} />
                </Grid>
            </Grid>
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <DeleteConsultingVisit
                    onClose={() => setState({ ...state, openDelete: false })}
                    consulting_visit={state.consulting_visit}
                />
            )}
        </>
    );
};

export default ConsultingVisitUpdate;
