import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

// Services
import { updateBillState } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// -------------------------------------------------------------------------------------- \\
// --------------------------------- UPDATE BILL STATE ---------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const UpdateBillState = ({ bill, onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        bill_state: bill.bill_state || 'not_processed',
    });

    useEffect(() => {
        if (state.loading) {
            updateBillState(bill.id, { bill_state: state.bill_state }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog
            open
            fullWidth
        >
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20, textAlign: 'center' }}>
                        <p className="dialog-title">
                            Changer l'état du BC
                        </p>
                        {/* -------------------- BILL STATE -------------------- */}
                        <ToggleButtonGroup
                            value={state.bill_state}
                            onChange={(e, value) => setState({ ...state, bill_state: (value == null ? state.bill_state : value) })}
                            exclusive
                            size="small"
                            className="toggle-button"
                        >
                            <ToggleButton
                                value="not_processed"
                                style={{ width: 140 }}
                            >
                                Non traité
                            </ToggleButton>
                            <ToggleButton
                                value="processed"
                                style={{ width: 140 }}
                            >
                                Traité
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            Fermer
                        </Button>
                        <Button
                            onClick={() => setState({ ...state, loading: true })}
                            variant="contained"
                        >
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateBillState;
