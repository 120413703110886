import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { createFederatedAssociation } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// Views
import FederatedAssociationForm from '../federated-association-form';

// Messages
import messages from 'messages.json';

// -------------------------------------------------------------------------------------- \\
// ---------------------------- CREATE FEDERATED ASSOCIATION ---------------------------- \\
// -------------------------------------------------------------------------------------- \\

export default function CreateFederatedAssociation({ onClose, refetch }) {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: '',
        type: -1,
        uploaded_file: null,
        address: '',
        postal_code: '',
        city: '',
        country_id: -1,
    });

    function onCreate() {
        if (state.name === '' || state.type === -1) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-file').files[0];

            if (file) {
                setState({ ...state, loading: true, uploaded_file: file });
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    }

    useEffect(() => {
        if (state.loading) {
 
            const formData = new FormData();
            formData.append('name', state.name);
            formData.append('type', state.type);
            formData.append('address', state.address);
            formData.append('postal_code', state.postal_code);
            formData.append('city', state.city);
            formData.append('country_id', state.country_id);

            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }

            createFederatedAssociation(formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Créer une association fédérée
                        </p>
                        {/* -------------------- NAME -------------------- */}
                        <FederatedAssociationForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onCreate} variant="contained">
                            Créer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
