import React from 'react';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ FORMATION REQUEST MESSAGE -------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationRequestMessage = ({ onClose, message }) => {
    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Commentaire stagiaire
                </p>
                {(message != null && message.length > 0) ? message : 'Aucun commentaire'}
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={onClose} variant="contained">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormationRequestMessage;
