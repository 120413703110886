import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 140;

// -------------------- HEADER -------------------- \\
const BirthDateHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Date de naissance
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const BirthDateFilter = ({ state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label="jj/mm/aaaa"
                value={state.birth_date}
                onChange={e => setState({ ...state, birth_date: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const BirthDateValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { BirthDateHeader, BirthDateFilter, BirthDateValue };
