import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select';

/* * *
* Composant permettant de sélectionner des éléments à partir d'une saisie et non d'une liste
*/

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT CREATABLE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectCreatable = ({ value, onChange, options, placeholder, minWidth }) => {
    return (
        <CreatableSelect
            value={value}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            noOptionsMessage={() => 'Aucun'}
            formatCreateLabel={inputValue => `Chercher ${inputValue}`}
            isMulti
            filterOption={createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                matchFrom: 'any',
                stringify: option => `${option.label}`,
                trim: true,
            })}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                container: base => ({ ...base, minWidth: minWidth, width: '100%' }),
                placeholder: base => ({ ...base, whiteSpace: 'nowrap' })
            }}
        />
    );
};

// Propriétés par défaut
SelectCreatable.defaultProps = {
    options: [],
    placeholder: 'Saisir...',
    minWidth: 120,
};

export default SelectCreatable;
