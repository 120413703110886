import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Divider } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

// Services
import { getCollectedBy } from 'services/requests';
import useMediaQueries from 'services/media';
import { subscriptionStatuses } from 'services/constants';
import { getAmountTTC, getColorStatus, getLabelStatus, getFormattedDate } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Pagination from 'components/pagination';
import InputText from 'components/input-text';

// Views
import CollectedMembershipsExport from './collected-memberships-export';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------- MEMBERSHIP COLLECTED BY MABD --------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MembershipsCollectedByMabd = ({ transfer }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        offset: 0,
        limit: 20,
        total_count: 0,
        memberships: [],
        searchName: '',
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    useEffect(() => {
        if (state.loading) {
            getCollectedBy(transfer.id, {
                collected_by: 'collectedByMabd',
                offset: state.offset,
                limit: state.limit,
                username: state.searchName,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        memberships: res.data.data.memberships,
                    });
                }
                else {
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid style={{ padding: '24px 24px 0px 24px' }}>
                    <b>ADHÉSIONS COLLECTÉES PAR LE MABD (Montant à transférer : {getAmountTTC(transfer.subtotal_memberships_mabd)})</b>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <div className="input-group" style={{ width: 300 }}>
                        <div className="input-group-prepend" onClick={onSearch} style={{ cursor: 'pointer' }}>
                            <span className="input-group-text">
                                <SearchIcon />
                            </span>
                        </div>
                        <InputText
                            label="Chercher un utilisateur..."
                            type="text"
                            value={state.searchName}
                            onChange={e => setState({ ...state, searchName: e.target.value })}
                            onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                        />
                    </div>
                    <CollectedMembershipsExport
                        transferId={transfer.id}
                        collectedBy="collectedByMabd"
                        data={state}
                    />
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        offset={state.offset}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <b>
                        {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                    </b>
                </Grid>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Statut adhésion
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Date
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Utilisateur
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Montant adhésion
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Type d'adhésion
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Adresse de facturation
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- LIST -------------------- */}
                <div className="main-container-item">
                    {state.loading ? (
                        <Spinner />
                    ) : (
                        <>
                            {state.memberships.length > 0 ? (
                                state.memberships.map(membership => (
                                    <Fragment key={membership.id}>
                                        <Grid
                                            onClick={() => history.push(match.url + '/' + membership.billId)}
                                            className="simple-list"
                                            container alignItems="center"
                                        >
                                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                                <b style={{ color: getColorStatus(subscriptionStatuses, membership.status) }}>
                                                    {getLabelStatus(subscriptionStatuses, membership.status)}
                                                </b>
                                            </Grid>
                                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                                {getFormattedDate(membership.start_date)} - {getFormattedDate(membership.end_date)}
                                            </Grid>
                                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                                {membership.user ? membership.user.fullName : ''}
                                                <br />
                                                {membership.user ? membership.user.clientCode : ''}
                                            </Grid>
                                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                                {membership.billItem ? getAmountTTC(membership.billItem.amount) : ''}
                                            </Grid>
                                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                                {membership.membershipType ? membership.membershipType.name : ''}
                                            </Grid>
                                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                                {membership.address} {membership.postal_code} {membership.city} {membership.country ? membership.country.name : ''}
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Fragment>
                                ))
                            ) : (
                                <>
                                    <Grid container style={{ padding: 10 }}>
                                        Aucune
                                    </Grid>
                                    <Divider />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default MembershipsCollectedByMabd;
