import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { createRefund } from 'services/requests';
import { amountToSend, isValidAmount, amountToBeReceived } from 'services/helpers';

// Components
import Spinner from 'components/spinner';

// Messages
import messages from 'messages.json';

// Views
import RefundForm from './refunds-form';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- CREATE REFUND ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const CreateRefund = ({ onClose, refetch, billItem }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        refund_date: new Date(),
        amount: amountToBeReceived(billItem.amount),
        reference: '',
        payment_method: 'transfer',
        status: 'recovered',
    });

    function onCreate() {
        if (state.payment_method === -1 || state.status === -1) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!isValidAmount(state.amount)) {
            enqueueSnackbar('Veuillez saisir un montant valide', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createRefund(billItem.id, {
                refund_date: state.refund_date ? moment(state.refund_date).format('YYYY-MM-DD') : null,
                amount: amountToSend(state.amount),
                reference: state.reference,
                payment_method: state.payment_method,
                status: state.status,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Rembourser l'article : {billItem.name}
                        </p>
                        <RefundForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onCreate} variant="contained">
                            Rembourser
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateRefund;
