import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, DialogContent, DialogActions, Dialog, Checkbox } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';

// Services
import { getRefunds, getIndexPreferences, updateIndexPreferences } from 'services/requests';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';
import { getLabelStatus, getColorStatus, getAmountTTC } from 'services/helpers';
import { paymentMethods, refundStatuses } from 'services/constants';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Tooltip from 'components/tooltip';
import InputText from 'components/input-text';
import SelectCreatable from 'components/select-creatable';
import Pagination from 'components/pagination';

// Views
import UpdateRefund from './refunds-update';
import RefundsExport from './refunds-export';

// Selects
import SelectRefundStatuses from 'selects/select-refund-statuses';
import SelectFederatedAssociations from 'selects/select-federated-associations';
import SelectCountries from 'selects/select-countries';
import SelectPaymentMethods from 'selects/select-payment-methods';
import SelectRefundMethods from 'selects/select-refund-methods';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- REFUNDS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Refunds = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        refunds: [],
        offset: 0,
        limit: 30,
        total_count: 0,

        // Update
        openUpdate: false,
        refund: undefined,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_status: true,
        enabled_client_code: true,
        enabled_external_client_code: true,
        enabled_last_name: true,
        enabled_company_name: true,
        enabled_bill_reference: true,
        enabled_external_reference: true,
        enabled_amount: true,
        enabled_refund_method: true,
        enabled_email: false,
        enabled_mobile_phone: false,
        enabled_phone: false,
        enabled_address: false,
        enabled_address_details: false,
        enabled_country: false,
        enabled_postal_code: false,
        enabled_federated_association: false,
        enabled_payment_method: true,

        // Filters
        statuses: [],
        client_code: '',
        external_client_code: '',
        last_name: '',
        company_name: '',
        bill_reference: '',
        external_reference: '',
        amount: '',
        refund_methods: [],
        email: '',
        mobile_phone: '',
        phone: '',
        address: '',
        address_details: '',
        countries: [],
        postal_codes: [],
        federated_associations: [],
        payment_methods: [],
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            offset: 0,
            error: false,
            statuses: [],
            client_code: '',
            external_client_code: '',
            last_name: '',
            company_name: '',
            bill_reference: '',
            external_reference: '',
            amount: '',
            refund_methods: [],
            email: '',
            mobile_phone: '',
            phone: '',
            address: '',
            address_details: '',
            countries: [],
            postal_codes: [],
            federated_associations: [],
            payment_methods: [],
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'refunds',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_status: columns.enabled_status,
                            enabled_client_code: columns.enabled_client_code,
                            enabled_external_client_code: columns.enabled_external_client_code,
                            enabled_last_name: columns.enabled_last_name,
                            enabled_company_name: columns.enabled_company_name,
                            enabled_bill_reference: columns.enabled_bill_reference,
                            enabled_external_reference: columns.enabled_external_reference,
                            enabled_amount: columns.enabled_amount,
                            enabled_refund_method: columns.enabled_refund_method,
                            enabled_email: columns.enabled_email,
                            enabled_mobile_phone: columns.enabled_mobile_phone,
                            enabled_phone: columns.enabled_phone,
                            enabled_address: columns.enabled_address,
                            enabled_address_details: columns.enabled_address_details,
                            enabled_country: columns.enabled_country,
                            enabled_postal_code: columns.enabled_postal_code,
                            enabled_federated_association: columns.enabled_federated_association,
                            enabled_payment_method: columns.enabled_payment_method,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            getRefunds({
                offset: state.offset,
                limit: state.limit,
                statuses: state.statuses ? state.statuses.map(s => s.value) : null,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                last_name: state.last_name,
                company_name: state.company_name,
                bill_reference: state.bill_reference,
                external_reference: state.external_reference,
                amount: state.amount,
                refund_methods: state.refund_methods ? state.refund_methods.map(m => m.value) : null,
                email: state.email,
                mobile_phone: state.mobile_phone,
                phone: state.phone,
                address: state.address,
                address_details: state.address_details,
                country_ids: state.countries ? state.countries.map(c => c.value) : null,
                postal_codes: state.postal_codes ? state.postal_codes.map(c => c.value) : null,
                federated_association_ids: state.federated_associations ? state.federated_associations.map(a => a.value) : null,
                payment_methods: state.payment_methods ? state.payment_methods.map(m => m.value) : null,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        refunds: res.data.data.refunds,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Remboursements
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <RefundsExport data={state} />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <RefundList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    Statut remboursement
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    Code client
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    Code client externe
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    Nom
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    Nom de l'entreprise
                </div>
            )}
            {/* -------------------- BILL REFERENCE -------------------- */}
            {state.enabled_bill_reference && (
                <div className="column-list">
                    Réf. bon de commande
                </div>
            )}
            {/* -------------------- EXTERNAL REFERENCE -------------------- */}
            {state.enabled_external_reference && (
                <div className="column-list">
                    Réf. externe
                </div>
            )}
            {/* -------------------- AMOUNT -------------------- */}
            {state.enabled_amount && (
                <div className="column-list">
                    Montant TTC
                </div>
            )}
            {/* -------------------- REFUND METHOD -------------------- */}
            {state.enabled_refund_method && (
                <div className="column-list">
                    Mode de remboursement
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    Email compte
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    Téléphone portable
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    Téléphone fixe
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    Adresse FACT (n° et voie)
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    Adresse FACT (complément)
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    Pays
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    Code postal
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    Association fédérée
                </div>
            )}
            {/* -------------------- PAYMENT METHOD -------------------- */}
            {state.enabled_payment_method && (
                <div className="column-list">
                    Mode de paiment bon de commande
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    <SelectRefundStatuses
                        statuses={state.statuses}
                        setStatuses={statuses => onSelectSearch('statuses', statuses)}
                    />
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client"
                        value={state.client_code}
                        onChange={e => setState({ ...state, client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client externe"
                        value={state.external_client_code}
                        onChange={e => setState({ ...state, external_client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    <InputText
                        label="Nom"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    <InputText
                        label="Nom de l'entreprise"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL REFERENCE -------------------- */}
            {state.enabled_bill_reference && (
                <div className="column-list">
                    <InputText
                        label="Réf. bon de commande"
                        value={state.reference}
                        onChange={e => setState({ ...state, reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EXTERNAL REFERENCE -------------------- */}
            {state.enabled_external_reference && (
                <div className="column-list">
                    <InputText
                        label="Réf. externe"
                        value={state.external_reference}
                        onChange={e => setState({ ...state, external_reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- AMOUNT -------------------- */}
            {state.enabled_amount && (
                <div className="column-list">
                    <InputText
                        label="Montant TTC"
                        value={state.amount}
                        onChange={e => setState({ ...state, amount: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- REFUND METHOD -------------------- */}
            {state.enabled_refund_method && (
                <div className="column-list">
                    <SelectRefundMethods
                        methods={state.refund_methods}
                        setMethods={refund_methods => onSelectSearch('refund_methods', refund_methods)}
                    />
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    <InputText
                        label="Email compte"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone portable"
                        value={state.mobile_phone}
                        onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone fixe"
                        value={state.phone}
                        onChange={e => setState({ ...state, phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    <InputText
                        label="Adresse (n° et voie)"
                        value={state.address}
                        onChange={e => setState({ ...state, address: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    <InputText
                        label="Adresse (complément)"
                        value={state.address_details}
                        onChange={e => setState({ ...state, address_details: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    <SelectCountries
                        countries={state.countries}
                        setCountries={countries => onSelectSearch('countries', countries)}
                    />
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    <SelectCreatable
                        placeholder="Code postal"
                        value={state.postal_codes}
                        onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                    />
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    <SelectFederatedAssociations
                        federatedAssociations={state.federated_associations}
                        setFederatedAssociations={federated_associations => onSelectSearch('federated_associations', federated_associations)}
                    />
                </div>
            )}
            {/* -------------------- PAYMENT METHOD -------------------- */}
            {state.enabled_payment_method && (
                <div className="column-list">
                    <SelectPaymentMethods
                        methods={state.payment_methods}
                        setMethods={payment_methods => onSelectSearch('payment_methods', payment_methods)}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------ REFUND LIST ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const RefundList = ({ state, setState }) => {

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.refunds.length > 0 && (
                state.refunds.map(refund => (
                    <Fragment key={refund.id}>
                        <div
                            onClick={() => setState({ ...state, openUpdate: true, refund })}
                            className="simple-list"
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- STATUS -------------------- */}
                            {state.enabled_status && (
                                <div className="column-list">
                                    <b className="title-status-list" style={{ color: getColorStatus(refundStatuses, refund.status) }}>
                                        {getLabelStatus(refundStatuses, refund.status)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- CLIENT CODE -------------------- */}
                            {state.enabled_client_code && (
                                <div className="column-list">
                                    {(refund.bill && refund.bill.user) ? refund.bill.user.clientCode : ''}
                                </div>
                            )}
                            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                            {state.enabled_external_client_code && (
                                <div className="column-list">
                                    {(refund.bill && refund.bill.user) ? refund.bill.user.external_client_code : ''}
                                </div>
                            )}
                            {/* -------------------- LAST NAME -------------------- */}
                            {state.enabled_last_name && (
                                <div className="column-list">
                                    {refund.bill ? refund.bill.last_name : ''}
                                </div>
                            )}
                            {/* -------------------- COMPANY NAME -------------------- */}
                            {state.enabled_company_name && (
                                <div className="column-list">
                                    {refund.bill ? refund.bill.company_name : ''}
                                </div>
                            )}
                            {/* -------------------- BILL REFERENCE -------------------- */}
                            {state.enabled_bill_reference && (
                                <div className="column-list">
                                    {refund.bill ? refund.bill.reference : ''}
                                </div>
                            )}
                            {/* -------------------- EXTERNAL REFERENCE -------------------- */}
                            {state.enabled_external_reference && (
                                <div className="column-list">
                                    {refund.bill ? refund.bill.external_reference : ''}
                                </div>
                            )}
                            {/* -------------------- AMOUNT -------------------- */}
                            {state.enabled_amount && (
                                <div className="column-list">
                                    {getAmountTTC(refund.amount)}
                                </div>
                            )}
                            {/* -------------------- REFUND METHOD -------------------- */}
                            {state.enabled_refund_method && (
                                <div className="column-list">
                                    {getLabelStatus(paymentMethods, refund.payment_method)}
                                </div>
                            )}
                            {/* -------------------- EMAIL -------------------- */}
                            {state.enabled_email && (
                                <div className="column-list">
                                    {(refund.bill && refund.bill.user) ? refund.bill.user.email : ''}
                                </div>
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <div className="column-list">
                                    {(refund.bill && refund.bill.user) ? refund.bill.user.mobile_phone : ''}
                                </div>
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <div className="column-list">
                                    {(refund.bill && refund.bill.user) ? refund.bill.user.phone : ''}
                                </div>
                            )}
                            {/* -------------------- ADDRESS -------------------- */}
                            {state.enabled_address && (
                                <div className="column-list">
                                    {refund.bill ? refund.bill.address : ''}
                                </div>
                            )}
                            {/* -------------------- ADDRESS DETAILS -------------------- */}
                            {state.enabled_address_details && (
                                <div className="column-list">
                                    {refund.bill ? refund.bill.address_details : ''}
                                </div>
                            )}
                            {/* -------------------- COUNTRY -------------------- */}
                            {state.enabled_country && (
                                <div className="column-list">
                                    {(refund.bill && refund.bill.country) ? refund.bill.country.name : ''}
                                </div>
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <div className="column-list">
                                    {refund.bill ? refund.bill.postal_code : ''}
                                </div>
                            )}
                            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                            {state.enabled_federated_association && (
                                <div className="column-list">
                                    {refund.federatedAssociation ? refund.federatedAssociation.name : ''}
                                </div>
                            )}
                            {/* -------------------- PAYMENT METHOD -------------------- */}
                            {state.enabled_payment_method && (
                                <div className="column-list">
                                    {getLabelStatus(paymentMethods, (refund.bill ? refund.bill.payment_method : null))}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
            {/* -------------------- UPDATE REFUND -------------------- */}
            {state.openUpdate && (
                <UpdateRefund
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    refund={state.refund}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_status: state.enabled_status,
            enabled_client_code: state.enabled_client_code,
            enabled_external_client_code: state.enabled_external_client_code,
            enabled_last_name: state.enabled_last_name,
            enabled_company_name: state.enabled_company_name,
            enabled_bill_reference: state.enabled_bill_reference,
            enabled_external_reference: state.enabled_external_reference,
            enabled_amount: state.enabled_amount,
            enabled_refund_method: state.enabled_refund_method,
            enabled_email: state.enabled_email,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_phone: state.enabled_phone,
            enabled_address: state.enabled_address,
            enabled_address_details: state.enabled_address_details,
            enabled_country: state.enabled_country,
            enabled_postal_code: state.enabled_postal_code,
            enabled_federated_association: state.enabled_federated_association,
            enabled_payment_method: state.enabled_payment_method,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'refunds',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                <div style={{ display: 'flex' }}>
                    <div>
                        {/* -------------------- STATUS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_status}
                                onChange={e => setState({ ...state, enabled_status: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Statut bon de commande
                        </Grid>
                        {/* -------------------- CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_client_code}
                                onChange={e => setState({ ...state, enabled_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client
                        </Grid>
                        {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_external_client_code}
                                onChange={e => setState({ ...state, enabled_external_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client externe
                        </Grid>
                        {/* -------------------- LAST NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_last_name}
                                onChange={e => setState({ ...state, enabled_last_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom
                        </Grid>
                        {/* -------------------- COMPANY NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_company_name}
                                onChange={e => setState({ ...state, enabled_company_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom de l'entreprise
                        </Grid>
                        {/* -------------------- BILL REFERENCE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_bill_reference}
                                onChange={e => setState({ ...state, enabled_bill_reference: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Réf. bon de commande
                        </Grid>
                        {/* -------------------- EXTERNAL REFERENCE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_external_reference}
                                onChange={e => setState({ ...state, enabled_external_reference: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Réf. externe
                        </Grid>
                        {/* -------------------- AMOUNT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_amount}
                                onChange={e => setState({ ...state, enabled_amount: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Montant TTC
                        </Grid>
                        {/* -------------------- REFUND METHOD -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_refund_method}
                                onChange={e => setState({ ...state, enabled_refund_method: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Mode de remboursement
                        </Grid>
                    </div>
                    <div>
                        {/* -------------------- EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_email}
                                onChange={e => setState({ ...state, enabled_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email compte
                        </Grid>
                        {/* -------------------- MOBILE PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_mobile_phone}
                                onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone portable
                        </Grid>
                        {/* -------------------- PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_phone}
                                onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone fixe
                        </Grid>
                        {/* -------------------- ADDRESS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address}
                                onChange={e => setState({ ...state, enabled_address: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse FACT (n° et voie)
                        </Grid>
                        {/* -------------------- ADDRESS DETAILS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address_details}
                                onChange={e => setState({ ...state, enabled_address_details: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse FACT (complément)
                        </Grid>
                        {/* -------------------- COUNTRY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_country}
                                onChange={e => setState({ ...state, enabled_country: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Pays
                        </Grid>
                        {/* -------------------- POSTAL CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_postal_code}
                                onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code postal
                        </Grid>
                        {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_federated_association}
                                onChange={e => setState({ ...state, enabled_federated_association: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Association fédérée
                        </Grid>
                        {/* -------------------- PAYMENT METHOD -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_payment_method}
                                onChange={e => setState({ ...state, enabled_payment_method: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Mode de paiement bon de commande
                        </Grid>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Refunds;
