import React from 'react';

// Material-UI
import { Grid, Checkbox } from '@material-ui/core';

// Components
import InputText from 'components/input-text';

// ---------------------------------------- CONTRIBUTION AMOUNT ----------------------------------------- \\
const ContributionAmount = ({ subscriptionType, state, setState }) => {
    return (
        <div style={{ padding: '24px 0px' }}>
            <p style={{ marginBottom: 0 }}>
                Facultatif : pour soutenir l'association MABD, je verse en plus de mon&nbsp;
                {subscriptionType === 'subscription' ? 'abonnement' : 'adhésion'} une cotisation de solidarité de :
            </p>
            {/* -------------------- 10 -------------------- */}
            <Grid container alignItems="center">
                <Checkbox
                    checked={state.contribution_amount === '10'}
                    onChange={(e, checked) => setState(prevState => ({ ...prevState, contribution_amount: checked ? '10' : '' }))}
                />
                <span>
                    10€
                </span>
            </Grid>
            {/* -------------------- 20 -------------------- */}
            <Grid container alignItems="center">
                <Checkbox
                    checked={state.contribution_amount === '20'}
                    onChange={(e, checked) => setState(prevState => ({ ...prevState, contribution_amount: checked ? '20' : '' }))}
                />
                <span>
                    20€
                </span>
            </Grid>
            {/* -------------------- 50 -------------------- */}
            <Grid container alignItems="center">
                <Checkbox
                    checked={state.contribution_amount === '50'}
                    onChange={(e, checked) => setState(prevState => ({ ...prevState, contribution_amount: checked ? '50' : '' }))}
                />
                <span>
                    50€
                </span>
            </Grid>
            {/* -------------------- 100 -------------------- */}
            <Grid container alignItems="center">
                <Checkbox
                    checked={state.contribution_amount === '100'}
                    onChange={(e, checked) => setState(prevState => ({ ...prevState, contribution_amount: checked ? '100' : '' }))}
                />
                <span>
                    100€
                </span>
            </Grid>
            {/* -------------------- FREE PRICE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <InputText
                        label="Montant libre en euro (€)"
                        type="number"
                        value={state.contribution_amount}
                        onChange={e => setState({ ...state, contribution_amount: e.target.value })}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default ContributionAmount;
