import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { getBill, onlinePayment } from 'services/requests';
import { getStoredUser, setBackUrl, setBillId } from 'services/storage';
import { moneticoUrl } from 'services/axios-config';
import { paymentMethods } from 'services/constants';
import { getAmountTTC, getLabelStatus } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import ChangePaymentMethod from './change-payment-method';

// ------------------------------------------------------------------------------------------------ \\
// ---------------------------------------- PAYMENT METHOD ---------------------------------------- \\
// ------------------------------------------------------------------------------------------------ \\

const PaymentMethod = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { mediaMD } = useMediaQueries();
    const { billId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const storedUserId = getStoredUser()?.id;

    const [state, setState] = useState({
        loading: true,
        error: false,
        bill: undefined,

        // Change Payment Method
        payment_method: -1,
        confirm_terms: false,
        loadingChange: false,

        // Online Payment
        loadingOnlinePayment: false,
        sendOnlinePayment: false,
        MAC: null,
        TPE: null,
        contexte_commande: null,
        date: null,
        lgue: null,
        libelleMonetique: null,
        libelleMonetiqueLocalite: null,
        mail: null,
        montant: null,
        reference: null,
        societe: null,
        url_retour_err: null,
        url_retour_ok: null,
        version: null,
    });

    function getBackUrl() {
        if (pathname.includes('bills')) {
            return pathname.split('/payment-method')[0];
        }
        if (pathname.includes('memberships')) {
            return '/management/memberships/' + billId;
        }
        if (pathname.includes('subscriptions')) {
            return '/management/subscriptions/' + billId;
        }
        if (storedUserId === state.bill.user_id) {
            return '/dashboard';
        }
        return '/users/' + state.bill.user_id;
    }

    useEffect(() => {
        if (state.loading) {
            getBill(billId).then(res => {
                if (res.status === 200) {
                    const bill = res.data.data.bill;
                    setState({ ...state, loading: false, bill });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingOnlinePayment) {
            onlinePayment(billId).then(res => {
                if (res.status === 200) {
                    const data = res.data.data;
                    setState({
                        ...state,
                        loadingOnlinePayment: false,
                        sendOnlinePayment: true,
                        MAC: data.MAC,
                        TPE: data.TPE,
                        contexte_commande: data.contexte_commande,
                        date: data.date,
                        lgue: data.lgue,
                        libelleMonetique: data.libelleMonetique,
                        libelleMonetiqueLocalite: data.libelleMonetiqueLocalite,
                        mail: data.mail,
                        montant: data.montant,
                        reference: data.reference,
                        societe: data.societe,
                        url_retour_err: data.url_retour_err,
                        url_retour_ok: data.url_retour_ok,
                        version: data.version,
                    });
                }
                else {
                    console.log(res);
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingOnlinePayment: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingOnlinePayment]);

    useEffect(() => {
        if (state.sendOnlinePayment) {
            setBackUrl(pathname);
            setBillId(billId);
            document.getElementById("Monetico").submit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.sendOnlinePayment]);

    if (state.loading || state.loadingOnlinePayment) return <Spinner />;
    if (state.error) return <Error />;

    if (state.sendOnlinePayment) {
        return (
            <form id="Monetico" name="Monetico" method="post" target="_top" action={moneticoUrl}>
                <input type="hidden" name="MAC" value={state.MAC} />
                <input type="hidden" name="TPE" value={state.TPE} />
                <input type="hidden" name="contexte_commande" value={state.contexte_commande} />
                <input type="hidden" name="date" value={state.date} />
                <input type="hidden" name="lgue" value={state.lgue} />
                {/* <input type="hidden" name="libelleMonetique" value={state.libelleMonetique} />
                <input type="hidden" name="libelleMonetiqueLocalite" value={state.libelleMonetiqueLocalite} /> */}
                <input type="hidden" name="mail" value={state.mail} />
                <input type="hidden" name="montant" value={state.montant} />
                <input type="hidden" name="reference" value={state.reference} />
                <input type="hidden" name="societe" value={state.societe} />
                <input type="hidden" name="url_retour_err" value={state.url_retour_err} />
                <input type="hidden" name="url_retour_ok" value={state.url_retour_ok} />
                <input type="hidden" name="version" value={state.version} />
            </form>
        );
    }

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push(getBackUrl())}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ padding: mediaMD ? 0 : 10 }}
                >
                    Payer en ligne / Choisir son mode de paiement
                </Grid>
            </Grid>
            <Grid
                container
                style={{ flex: 1 }}
            >
                <Grid
                    item xs={false} sm={2} md={3} lg={4}
                />
                <Grid
                    item xs={12} sm={8} md={6} lg={4}
                    style={{ padding: 24, display: 'flex', flexDirection: 'column' }}
                >
                    <Grid style={{ marginBottom: 10 }}>
                        Mode de paiement actuel : <b style={{ fontSize: 16 }}>{getLabelStatus(paymentMethods, state.bill.payment_method)}</b>
                    </Grid>
                    {state.bill.payment_method === 'check' && (
                        <p style={{ marginBottom: 10 }}>
                            <b>Vous avez opté pour un paiement par chèque.</b><br/>
                            Payable en France à l'ordre du MABD<br />
                            À envoyer au 5 place de la gare 68000 Colmar<br />
                            <b>Notez la référence [<span className="secondary-color">{state.bill.reference}</span>] au dos du chèque</b>
                        </p>
                    )}
                    {state.bill.payment_method === 'transfer' && (
                        <p style={{ marginBottom: 10 }}>
                            <b>Vous avez opté pour un paiement par virement bancaire.</b><br/>
                            Coordonnées bancaires du MABD :<br />
                            Domiciliation CIC Agence de Colmar rue des clefs<br />
                            IBAN : FR76 3008 7332 0000 0195 5320 120 / BIC CMCIFRPP<br />
                            <b>Notez la référence [<span className="secondary-color">{state.bill.reference}</span>] lors du virement</b>
                        </p>
                    )}
                    <Grid style={{ border: '1px solid black', borderRadius: 4, padding: 24 }}>
                        {/* -------------------- TITLE -------------------- */}
                        <p className="dialog-title">
                            Récapitulatif de votre commande
                        </p>
                        {/* -------------------- USER -------------------- */}
                        <Grid
                            container justify="space-between"
                            style={{ marginBottom: 50 }}
                        >
                            <Grid>
                                <b>{state.bill.first_name} {state.bill.last_name}</b>
                            </Grid>
                            <Grid style={{ textAlign: 'right' }}>
                                Référence
                                <br />
                                <b className="secondary-color">{state.bill.reference}</b>
                            </Grid>
                        </Grid>
                        {/* -------------------- BILL ITEMS -------------------- */}
                        {state.bill.billItems.map((item, index) => (
                            <Grid
                                key={item.id}
                                container
                                style={{ marginTop: index === 0 ? 0 : 10 }}
                            >
                                <Grid item xs={10}>
                                    {item.name}
                                </Grid>
                                <Grid
                                    item xs={2}
                                    style={{ textAlign: 'right' }}
                                >
                                    {getAmountTTC(item.amount)}
                                </Grid>
                            </Grid>
                        ))}
                        <Divider
                            style={{ margin: '10px 0px' }}
                        />
                        {/* -------------------- TOTAL -------------------- */}
                        <Grid container>
                            <Grid item xs={10}>
                                <b>TOTAL</b>
                            </Grid>
                            <Grid
                                item xs={2}
                                style={{ textAlign: 'right' }}
                            >
                                <b>{state.bill.total_amount / 100}€</b>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* -------------------- CB BUTTON -------------------- */}
                    {(
                        state.bill.payment_method === 'cb'
                        && state.bill.total_amount > 0
                    ) && (
                        <Grid
                            container justify="center"
                            style={{ marginTop: 12 }}
                        >
                            <Button
                                onClick={() => setState({ ...state, loadingOnlinePayment: true })}
                                variant="contained" color="primary"
                                style={{ width: '100%', fontSize: 18 }}
                            >
                                Procéder au paiement en ligne
                            </Button>
                        </Grid>
                    )}
                    {/* -------------------- BACK DASHBOARD BUTTON -------------------- */}
                    {(
                        state.bill.payment_method === 'check'
                        || state.bill.payment_method === 'transfer'
                    ) && (
                        <Grid
                            container justify="center"
                            style={{ marginTop: 24, textAlign: 'center' }}
                        >
                            <div style={{ fontSize: 18 }}>
                                Votre inscription sera prise en compte dès réception du règlement
                            </div>
                            <Button
                                onClick={() => history.push('/dashboard')}
                                variant="contained" color="inherit"
                                style={{ fontSize: 18, marginTop: 12 }}
                            >
                                Aller sur mon tableau de bord
                            </Button>
                        </Grid>
                    )}
                    {/* -------------------- PAYMENT METHOD -------------------- */}
                    {state.bill.total_amount > 0 && (
                        <ChangePaymentMethod
                            state={state}
                            setState={setState}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default PaymentMethod;
