import React from 'react';

// Material-UI
import { Grid, Checkbox, Button } from '@material-ui/core';

// Components
import FieldRequired from 'components/field-required';

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- PURCHASE CONDITIONS ----------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const PurchaseConditions = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- CGV -------------------- */}
            <Grid container alignItems="center">
                <Checkbox
                    state={state.cgv}
                    onChange={e => setState({ ...state, cgv: e.target.checked })}
                />
                <Grid item xs>
                    <span>
                        En passant commande, j'accepte les
                    </span>
                    <Button
                        href="https://www.bio-dynamie.org/cgv/"
                        target="blank_"
                        color="primary"
                        className="purchase-conditions-link"
                        style={{ textTransform: 'none' }}
                    >
                        conditions générales de vente
                    </Button>
                    <FieldRequired />
                </Grid>
            </Grid>
            {/* -------------------- PDC -------------------- */}
            <Grid container alignItems="center">
                <Checkbox
                    state={state.pdc}
                    onChange={e => setState({ ...state, pdc: e.target.checked })}
                />
                <Grid item xs>
                    <span>
                        J'ai lu et j'accepte la
                    </span>
                    <Button
                        href="https://www.bio-dynamie.org/politique-de-confidentialite/"
                        target="blank_"
                        color="primary"
                        className="purchase-conditions-link"
                        style={{ textTransform: 'none' }}
                    >
                        politique de confidentialité
                    </Button>
                    <FieldRequired />
                </Grid>
            </Grid>
        </>
    );
};

export default PurchaseConditions;
