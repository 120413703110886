import React, { useState, useEffect, Fragment } from 'react';

// Material-UI
import { Grid, Divider } from '@material-ui/core';

// Services
import { getMembershipTypes } from 'services/requests';
import { getAmountTTC } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import UpdateMembershipType from './update-membership-type';

// ------------------------------------------------------------------------------------------------ \\
// ----------------------------------- FETCH MEMBERSHIP TYPES ------------------------------------- \\
// ------------------------------------------------------------------------------------------------ \\

const MembershipTypes = () => {

    const [state, setState] = useState({
        loading: true,
        error: false,
        membership_types: [],
        membership_type: null,
        openUpdate: false,
    });

    useEffect(() => {
        if (state.loading) {
            getMembershipTypes().then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, membership_types: res.data.data.membershipTypes });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                <Grid
                    container alignItems="center"
                    className="list-headers"
                    style={{ marginTop: 24 }}
                >
                    <Grid
                        item xs={10}
                        style={{ padding: 10 }}
                    >
                        Adhésion
                    </Grid>
                    <Grid
                        item xs={2}
                        container justify="center"
                        style={{ padding: 10 }}
                    >
                        Prix TTC
                    </Grid>
                </Grid>
                {/* -------------------- LIST -------------------- */}
                <div
                    className="main-container-item"
                    style={{ paddingBottom: 24 }}
                >
                    {state.membership_types.length > 0 ? (
                        state.membership_types.map(membership_type => (
                            <Fragment key={membership_type.id}>
                                <Grid
                                    onClick={() => setState({ ...state, openUpdate: true, membership_type })}
                                    container alignItems="center"
                                    className="simple-list"
                                    style={{ backgroundColor: membership_type.available ? 'rgba(0, 255, 0, 0.3)' : 'rgba(255, 0, 0, 0.3)' }}
                                >
                                    <Grid
                                        item xs={10}
                                        className="text"
                                        style={{ padding: 10 }}
                                    >
                                        {membership_type.name}
                                    </Grid>
                                    <Grid
                                        item xs={2}
                                        container justify="center"
                                        style={{ padding: 10 }}
                                    >
                                        {getAmountTTC(membership_type.price)}
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <div style={{ padding: 10 }}>
                                Aucun
                            </div>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
            {/* -------------------- UPDATE -------------------- */}
            {state.openUpdate && (
                <UpdateMembershipType
                    membership_type={state.membership_type}
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                />
            )}
        </>
    );
};

export default MembershipTypes;
