import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Material-UI
import { Grid } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';
import { userCanAccessToUserList, userCanAccessToGroups, userCanAccessToManagement } from 'services/permissions';

// Assets
import DashboardIcon from 'assets/images/dashboard.png';
import FederatedAssociationsIcon from 'assets/images/federated-associations.png';
import DomainsIcon from 'assets/images/domains.png';
import UsersIcon from 'assets/images/users.png';
import GroupsIcon from 'assets/images/groups.png';
import FormationsIcon from 'assets/images/formations.png';
import ShopIcon from 'assets/images/shop.png';
import ManagementIcon from 'assets/images/management.png';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------- MENU ITEMS ------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const MenuItems = ({ fromSideDrawer, onClose }) => {
    let storedUser = getStoredUser();
    const history = useHistory();
    const location = useLocation();
    const [path, setPath] = useState('dashboard');

    const iconProps = {
        width: 'auto',
        height: 40,
    };

    const itemClass = useMemo(() => {
        if (fromSideDrawer) {
            return 'side-drawer-menu-item';
        }
        return 'menu-item';
    }, [fromSideDrawer]);

    const itemSelectedClass = useMemo(() => {
        if (fromSideDrawer) {
            return 'side-drawer-menu-item-selected';
        }
        return 'menu-item-selected';
    }, [fromSideDrawer]);

    const onNavigateTo = (url) => {
        history.push(url);

        if (fromSideDrawer) {
            onClose();
        }
    };

    useEffect(() => {
        setPath(location.pathname.split('/')[1]);
    }, [location]);

    return (
        <>
            {/* -------------------- DASHBOARD -------------------- */}
            <Grid
                item xs="auto"
                className={path === 'dashboard' ? `${itemClass} ${itemSelectedClass}` : itemClass}
                onClick={() => onNavigateTo('/dashboard')} 
            >
                <img
                    alt="dashboard-icon"
                    src={DashboardIcon}
                    style={iconProps}
                />
                <br />
                <span>
                    Tableau de bord
                </span>
            </Grid>
            {/* -------------------- FEDERATED ASSOCIATIONS -------------------- */}
            <Grid
                item xs="auto"
                className={path === 'federated-associations' ? `${itemClass} ${itemSelectedClass}` : itemClass}
                onClick={() => onNavigateTo('/federated-associations')} 
            >
                <img
                    alt="federated-associations-icon"
                    src={FederatedAssociationsIcon}
                    style={iconProps}
                />
                <br />
                <span>
                    Associations fédérées
                </span>
            </Grid>
            {/* -------------------- DOMAINS -------------------- */}
            <Grid
                item xs="auto"
                className={path === 'domains' ? `${itemClass} ${itemSelectedClass}` : itemClass}
                onClick={() => onNavigateTo('/domains')} 
            >
                <img
                    alt="domains-icon"
                    src={DomainsIcon}
                    style={iconProps}
                />
                <br />
                <span>
                    Domaines
                </span>
            </Grid>
            {/* -------------------- USERS -------------------- */}
            {userCanAccessToUserList() && (
                <Grid
                    item xs="auto"
                    className={path === 'users' ? `${itemClass} ${itemSelectedClass}` : itemClass}
                    onClick={() => onNavigateTo('/users')} 
                >
                    <img
                        alt="users-icon"
                        src={UsersIcon}
                        style={iconProps}
                    />
                    <br />
                    <span>
                        Utilisateurs
                    </span>
                </Grid>
            )}
            {/* -------------------- GROUPS -------------------- */}
            {userCanAccessToGroups() && (
                <Grid
                    item xs="auto"
                    className={path === 'groups' ? `${itemClass} ${itemSelectedClass}` : itemClass}
                    onClick={() => onNavigateTo('/groups')} 
                >
                    <img
                        alt="groups-icon"
                        src={GroupsIcon}
                        style={iconProps}
                    />
                    <br />
                    <span>
                        Groupes
                    </span>
                </Grid>
            )}
            {/* -------------------- FORMATIONS -------------------- */}
            <Grid
                item xs="auto"
                className={path === 'formations' ? `${itemClass} ${itemSelectedClass}` : itemClass}
                onClick={() => onNavigateTo('/formations')} 
            >
                <img
                    alt="formations-icon"
                    src={FormationsIcon}
                    style={iconProps}
                />
                <br />
                <span>
                    Formations
                </span>
            </Grid>
            {/* -------------------- MEMBERSHIPS & SUBSCRIPTIONS -------------------- */}
            <Grid
                item xs="auto"
                className={path === 'shop' ? `${itemClass} ${itemSelectedClass}` : itemClass}
                onClick={() => onNavigateTo('/shop')} 
            >
                <img
                    alt="shop-icon"
                    src={ShopIcon}
                    style={iconProps}
                />
                <br />
                <span>
                    Adhésions et Abonnements
                </span>
            </Grid>
            {/* -------------------- MANAGEMENT -------------------- */}
            {userCanAccessToManagement() && (
                <Grid
                    item xs="auto"
                    className={path === 'management' ? `${itemClass} ${itemSelectedClass}` : itemClass}
                    onClick={() => onNavigateTo('/management')} 
                >
                    <img
                        alt="management-icon"
                        src={ManagementIcon}
                        style={iconProps}
                    />
                    <br />
                    <span>
                        Gestion
                    </span>
                </Grid>
            )}
            {/* -------------------- CONSULTING VISITS -------------------- */}
            {storedUser?.hasVisitsAsContact && (
                <Grid
                    item xs="auto"
                    className={path === 'consulting-visits' ? `${itemClass} ${itemSelectedClass}` : itemClass}
                    onClick={() => onNavigateTo('/consulting-visits')} 
                >
                    <img
                        alt="management-icon"
                        src={ManagementIcon}
                        style={iconProps}
                    />
                    <br />
                    <span>
                        Visite-conseil
                    </span>
                </Grid>
            )}
        </>
    );
};

export default MenuItems;
