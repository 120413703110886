import React, { useEffect } from 'react';

// Services
import { getAmountTTC, isValidAmount } from 'services/helpers';
import { sessionPriceStatuses } from 'services/constants';

// ----------------------------------------------------------------------------------------- \\
// ----------------------------------- PURCHASE SUMMARY ------------------------------------ \\
// ----------------------------------------------------------------------------------------- \\

const PurchaseSummary = ({ state, setState }) => {

    useEffect(() => {

        /* * *
        * Le montant total est recalculé à partir de zéro à chaque fois qu'un des paramètres
        * est modifié
        */

        let price = 0;
        if (state.session_price) {
            price += state.session_price.price;
        }
        if (state.options.length > 0) {
            state.options.forEach(option => price += option.price);
        }
        if (state.membership) {
            price += state.membership.membershipType?.price;
        }
        if (state.membership_type) {
            price += state.membership_type.price;
        }
        if (isValidAmount(state.contribution_amount) && Number(state.contribution_amount) > 0) {
            price += (Number(state.contribution_amount) * 100);
        }
        setState({ ...state, price });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.session_price, state.options.length, state.membership, state.membership_type, state.contribution_amount]);

    return (
        <table style={{ width: '100%' }}>
            <tbody>
                {/* -------------------- SESSION PRICE -------------------- */}
                {state.session_price && (
                    <tr>
                        <td className="table-td">
                            {sessionPriceStatuses.find(s => s.value === state.session_price.status)?.label}
                        </td>
                        <td className="table-td">
                            {getAmountTTC(state.session_price.price)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- OPTIONS -------------------- */}
                {(state.options && state.options.length > 0) && (
                    state.options.map(option => (
                        <tr key={option.id}>
                            <td className="table-td">
                                {option.name}
                            </td>
                            <td className="table-td">
                                {getAmountTTC(option.price)} TTC
                            </td>
                        </tr>
                    ))
                )}
                {/* -------------------- MEMBERSHIP TYPE -------------------- */}
                {state.membership_type && (
                    <tr>
                        <td className="table-td">
                            {state.membership_type.name}
                        </td>
                        <td className="table-td">
                            {getAmountTTC(state.membership_type.price)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- MEMBERSHIP -------------------- */}
                {state.membership && (
                    <tr>
                        <td className="table-td">
                            {state.membership.membershipType?.name}
                        </td>
                        <td className="table-td">
                            {getAmountTTC(state.membership.membershipType?.price)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- CONTRIBUTION AMOUNT -------------------- */}
                {(isValidAmount(state.contribution_amount) && Number(state.contribution_amount) > 0) && (
                    <tr>
                        <td className="table-td">
                            Cotisation de solidarité
                        </td>
                        <td className="table-td">
                            {getAmountTTC(state.contribution_amount * 100)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- TOTAL -------------------- */}
                <tr style={{ fontWeight: 600 }}>
                    <td className="table-td">
                        PRIX TOTAL
                    </td>
                    <td className="table-td">
                        {getAmountTTC(state.price)} TTC
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default PurchaseSummary;
