import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider, Paper, GridList } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

// Services
import { userCanGetAllDomains, userCanCreateDomain } from 'services/permissions';
import { getDomains, getUserDomains } from 'services/requests';
import { getStoredUser } from 'services/storage';

// Assets
import Pagination from 'components/pagination';
import Spinner from 'components/spinner';
import Error from 'components/error';
import InputText from 'components/input-text';
import useMediaQueries from 'services/media';

// Components
import CreateDomain from './domains-create';
import Domain from './domain';

// Images
import DomainsIcon from 'assets/images/domains.png';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ROUTER -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const DomainsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Domains />
            </Route>
            <Route path={`${match.path}/:domainId`}>
                <Domain />
            </Route>
        </Switch>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- DOMAINS -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Domains = () => {
    const storedUser = getStoredUser();
    const USER_ID = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        domains: [],
        offset: 0,
        limit: 15,
        total_count: 0,
        searchName: '',
        openCreate: false,
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    useEffect(() => {
        if (state.loading) {
            if (userCanGetAllDomains()) {
                getDomains({ offset: state.offset, limit: state.limit, name: state.searchName }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            offset: res.data.data.offset,
                            limit: res.data.data.limit,
                            total_count: res.data.data.totalCount,
                            domains: res.data.data.domains,
                        });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, loading: false, error: true });
                    }
                });
            }
            else {
                getUserDomains(USER_ID).then(res => {
                    if (res.status === 200) {
                        const managerOfDomains = res.data.data.managerOfDomains;
                        const memberOfDomains = res.data.data.memberOfDomains;
                        const domains = managerOfDomains.concat(memberOfDomains);
                        setState({ ...state, loading: false, domains, total_count: domains.length  });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, loading: false, error: true });
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="subheader">
                Domaines
                {userCanCreateDomain() && (
                    <Button onClick={() => setState({ ...state, openCreate: true })} variant="contained">
                        Créer un domaine
                    </Button>
                )}
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div className="input-group" style={{ width: 300 }}>
                    <div className="input-group-prepend" onClick={onSearch} style={{ cursor: 'pointer' }}>
                        <span className="input-group-text">
                            <SearchIcon />
                        </span>
                    </div>
                    <InputText
                        label="Chercher un domaine..."
                        type="text"
                        value={state.searchName}
                        onChange={e => setState({ ...state, searchName: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            {/* -------------------- DOMAIN LIST -------------------- */}
            <div className="main-container">
                <DomainList
                    loading={state.loading}
                    error={state.error}
                    domains={state.domains}
                />
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            {userCanGetAllDomains() && (
                <Grid container justify="flex-end" style={{ padding: 24 }}>
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        offset={state.offset}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
            )}
            {/* -------------------- CREATE DOMAIN -------------------- */}
            {state.openCreate && (
                <CreateDomain
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
        </>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------------- DOMAIN LIST ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const DomainList = ({ loading, error, domains }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaXS, mediaSM, mediaMD, mediaLG, mediaXL } = useMediaQueries();
    const [cols, setCols] = useState(6);

    useEffect(() => {
        if (mediaXS) setCols(1);
        if (mediaSM) setCols(2);
        if (mediaMD) setCols(4);
        if (mediaLG) setCols(5);
        if (mediaXL) setCols(6);
    }, [mediaXS, mediaSM, mediaMD, mediaLG, mediaXL]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <div className="main-container-item" style={{ paddingBottom: 24 }}>
            <GridList cellHeight={160} cols={cols} style={{ margin: 0 }}>
                {domains.map(domain => (
                    <Grid container key={domain.id} style={{ padding: 10 }}>
                        <Paper
                            elevation={3}
                            className="paper"
                            onClick={() => history.push(`${match.url}/${domain.id}`)}
                            style={{ textAlign: 'center' }}
                        >
                            <img alt="domains-icon" src={DomainsIcon} style={{ height: 40, width: 'auto' }} />
                            <b>{domain.name}</b>
                            {domain.root_domain_id != null && 'Sous-domaine'}
                        </Paper>
                    </Grid>
                ))}
            </GridList>
        </div>
    );
};

export default DomainsRouter;
