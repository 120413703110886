import React, { useState, useEffect, useRef } from 'react';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Services
import { searchUsers } from 'services/requests';

// Components
import Autocomplete from 'components/autocomplete';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- SELECT CONTACT USER ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

export default function SelectContactUser({ contactUser, setContactUser }) {
    const [state, setState] = useState({
        loading: false,      
        options: [],
        searchRef: undefined,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 3) {
                // Accompagnateur = user_category_id 6
                searchUsers({ search: state.searchRef, user_category_id: 6 }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.data.users.map(u => ({ label: u.fullName + ' (' + u.clientCode + ')', value: u.id })),
                        });
                    }
                    else {
                        setState({ ...state, loading: false });
                    }
                });
            } 
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Autocomplete
            value={contactUser}
            options={state.options}
            loading={state.loading}
            onChange={(e, value) => setContactUser(value)}
            onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
            placeholder="Chercher un utilisateur..."
        />
    );
}
