import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getManagementFormation, updateFormation } from 'services/requests';
import { userCanDeleteFormation, userCanUpdateFormation } from 'services/permissions';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import FormationForm from '../formation-form';
import DeleteFormation from '../delete-formation';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------ FORMATION ------------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const UpdateFormation = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { formationId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formation: undefined,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getManagementFormation(formationId, { management: '1' }).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, formation: res.data.data.formation });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    {state.formation.title}
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanDeleteFormation() && (
                        <Button onClick={() => setState({ ...state, openDelete: true })} variant="contained">
                            Supprimer
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- UPDATE FORMATION -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <SetFormation
                    refetch={() => setState({ ...state, loading: true })}
                    formation={state.formation}
                />
            </Grid>
            <Divider />
            {/* -------------------- DELETE FORMATION -------------------- */}
            {state.openDelete && (
                <DeleteFormation
                    onClose={() => setState({ ...state, openDelete: false })}
                    formation={state.formation}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SET FORMATION ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SetFormation = ({ refetch, formation }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        actionType: 'UPDATE',
        loading: false,
        title: formation.title || '',
        description: formation.description || '',
        prerequisite: formation.prerequisite || '',
        duration: formation.duration || '',
        categories: formation.categories ? formation.categories.map(c => ({ label: c.name, value: c.id })) : [],
        uploaded_file: null,
        uploaded_photo: null,
        document_url: formation.document_url,
        remove_documentation: false,
    });

    function onSave() {
        if (state.title === ''
            || state.duration === ''
            || (state.categories == null || state.categories.length === 0)
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const photo = document.getElementById('upload-photo').files[0];
            const file = document.getElementById('upload-file').files[0];

            setState({ ...state, loading: true, uploaded_photo: photo, uploaded_file: file });
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('title', state.title);
            formData.append('description', state.description);
            formData.append('prerequisite', state.prerequisite);
            formData.append('duration', state.duration);
            formData.append('category_ids', JSON.stringify(state.categories.map(c => c.value)));
            formData.append('remove_documentation', state.remove_documentation ? '1' : '0');

            if (state.uploaded_photo) {
                formData.append('uploaded_photo', state.uploaded_photo);
            }
            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }

            updateFormation(formation.id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <Grid item xs={12} md={10} lg={8}>
                <FormationForm state={state} setState={setState} />
            </Grid>
            {/* -------------------- SAVE BUTTON -------------------- */}
            <Grid item xs={12} md={2} lg={4} container alignItems="center" justify="flex-end">
                {userCanUpdateFormation() && (
                    <Button onClick={onSave} variant="contained">
                        Enregistrer
                    </Button>
                )}
            </Grid>
        </>
    );
};

export default UpdateFormation;
