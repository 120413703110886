import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Assets
import useMediaQueries from 'services/media';

// Views
import CreateSubscription from 'views/shop/create-subscription';

// -------------------------------------------------------------------------------------- \\
// ----------------------------- CREATE UNLOGGED SUBSCRIPTION --------------------------- \\
// -------------------------------------------------------------------------------------- \\

const CreateUnloggedSubscription = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { subscriptionType } = useParams();

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push('/shop')}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ margin: mediaMD ? 0 : 10, textAlign: 'center' }}
                >
                    <span>
                        Formulaire d'{subscriptionType === 'membership' ? 'adhésion' : 'abonnement'}
                    </span>
                </Grid>
            </Grid>
            {/* -------------------- CREATE SUBSCRIPTION -------------------- */}
            <CreateSubscription />
        </>
    );
};

export default CreateUnloggedSubscription;
