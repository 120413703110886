import React, { useState, useEffect } from 'react';

// Material-UI
import { MenuItem } from '@material-ui/core';

// Services
import { getFrenchDepartments } from 'services/requests';

// Components
import SelectOutlined from 'components/select-outlined';

// ------------------------------------------------------------------------------------------ \\
// --------------------------------- SELECT FRENCH DEPARTMENT ------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const SelectFrenchDepartment = ({ frenchDepartmentId, setFrenchDepartmentId, postalCode, countryId }) => {
    const [frenchDepartments, setFrenchDepartments] = useState([]);

    useEffect(() => {
        getFrenchDepartments().then(res => {
            if (res.status === 200) {
                setFrenchDepartments(res.data.data.frenchDepartments);
            }
            else {
                console.log(res);
            }
        });
    }, []);

    useEffect(() => {
        if (countryId === 1 && postalCode !== '') {
            const frenchDepartment = frenchDepartments.find(fd => fd.number === postalCode.substring(0, 2));
            if (frenchDepartment) {
                setFrenchDepartmentId(frenchDepartment.id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postalCode]);

    return (
        <SelectOutlined
            value={frenchDepartmentId}
            onChange={e => setFrenchDepartmentId(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>Département</em>
            </MenuItem>
            {frenchDepartments.map(frenchDepartment => (
                <MenuItem key={frenchDepartment.id} value={frenchDepartment.id}>
                    {frenchDepartment.name} ({frenchDepartment.number})
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

export default SelectFrenchDepartment;
