import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getCollectedBy } from 'services/requests';
import { subscriptionStatuses } from 'services/constants';
import { getAmountTTC, getFormattedDate, getLabelStatus } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------- COLLECTED MEMBERSHIPS EXPORT --------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const CollectedMembershipsExport = ({ transferId, collectedBy, data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getAllMembershipsToExport(memberships, offset) {
        getCollectedBy(transferId, {
            collected_by: collectedBy,
            offset: offset,
            limit: data.limit,
            username: data.searchName,
        }).then(res => {
            if (res.status === 200) {

                let res_memberships = res.data.data.memberships;
                memberships = memberships.concat(res_memberships);

                setState({
                    ...state,
                    percentage: Math.round((100 * memberships.length) / data.total_count) + '%',
                });

                if (res_memberships.length < data.limit) {
                    createExcelFile(memberships);
                }
                else {
                    getAllMembershipsToExport(memberships, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(memberships) {
        if (memberships.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Adhésions collectées ' + (collectedBy === 'collectedByAssoc' ? 'par l\'association' : 'par le MABD'));

            // Header
            worksheet.columns = [
                { header: 'Statut adhésion', key: 'status', width: 30 },
                { header: 'Date', key: 'date', width: 30 },
                { header: 'Utilisateur', key: 'user', width: 30 },
                { header: 'Montant adhésion', key: 'price', width: 30 },
                { header: 'Type d\'adhésion', key: 'membership_type', width: 30 },
                { header: 'Adresse de facturation', key: 'address', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            memberships.map(membership => {
                return worksheet.addRow({
                    status: getLabelStatus(subscriptionStatuses, membership.status),
                    date: getFormattedDate(membership.start_date) + ' - ' + getFormattedDate(membership.end_date),
                    user: membership.user ? (membership.user.first_name + ' ' + membership.user.last_name + ' (' + membership.user.clientCode + ')') : '',
                    price: membership.billItem ? getAmountTTC(membership.billItem.amount) : '',
                    membership_type: membership.membershipType ? membership.membershipType.name : '',
                    address: membership.address + ' ' + membership.postal_code + ' ' + membership.city + ' ' + (membership.country ? membership.country.name : ''),
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'adhesions-collectees-' + (collectedBy === 'collectedByAssoc' ? 'assoc' : 'mabd') + '.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllMembershipsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default CollectedMembershipsExport;
