import React from 'react';

// Material-UI
import { MenuItem } from '@material-ui/core';

// Services
import { billStates } from 'services/constants';

// Components
import SelectOutlined from 'components/select-outlined';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------- SELECT BILL STATE -------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectBillState = ({ billState, setBillState }) => {
    return (
        <SelectOutlined
            value={billState}
            onChange={e => setBillState(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>Etat BC</em>
            </MenuItem>
            {billStates.map(billState => (
                <MenuItem
                    key={billState.value}
                    value={billState.value}
                >
                    {billState.label}
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

export default SelectBillState;
