import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Assets
import Tooltip from 'components/tooltip';

// Services
import useMediaQueries from 'services/media';

// Images
import logoMABD from 'assets/images/logo-mabd-trans.png';

// CSS
import './header.css';

// ---------------------------------------------------------------------------------------- \\
// ---------------------------------------- HEADER ---------------------------------------- \\
// ---------------------------------------------------------------------------------------- \\

const Header = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { mediaMD } = useMediaQueries();

    return (
        <Grid
            container alignItems="center"
            className="header"
        >
            <Grid
                item xs={12} md="auto"
                className="header-logo-container"
            >
                <Tooltip title="Site www.bio-dynamie.org"
                    item={(
                        <a
                            href="https://www.bio-dynamie.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                alt="mabd-logo"
                                src={logoMABD}
                                className="header-logo"
                            />
                        </a>
                    )}
                />
            </Grid>
            <Grid
                className="header-nav-container"
                style={{ justifyContent: mediaMD ? 'right' : 'center' }}
            >
                {!pathname.includes('/shop') && (
                    <Button
                        onClick={() => history.push('/shop')}
                        variant="contained"
                        style={{ margin: 6 }}
                    >
                        Adhésions et Abonnements
                    </Button>
                )}
                {!pathname.includes('/formations') && (
                    <Button
                        onClick={() => history.push('/formations')}
                        variant="contained"
                        style={{ margin: 6 }}
                    >
                        Formations
                    </Button>
                )}
                {!pathname.includes('/login') && (
                    <Button
                        onClick={() => history.push('/login')}
                        variant="contained"
                        style={{ margin: 6 }}
                    >
                        Se connecter
                    </Button>
                )}
                {!pathname.includes('/register') && (
                    <Button
                        onClick={() => history.push('/register')}
                        variant="contained"
                        style={{ margin: 6 }}
                    >
                        Créer un compte
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

export default Header;
