import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

// Services
import { getTrashUsers, restoreUser, forceDeleteUser } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Pagination from 'components/pagination';
import Spinner from 'components/spinner';
import Error from 'components/error';
import InputText from 'components/input-text';

// ----------------------------------------------------------------------------------------- \\
// ------------------------------------- TRASH USERS --------------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const TrashUsers = () => {
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const match = useRouteMatch();

    // Params
    const backUrl = match.path.split('/trash')[0];

    const [state, setState] = useState({
        loading: true,
        loadingRestore: false,
        error: false,
        users: [],
        offset: 0,
        limit: 20,
        total_count: 0,
        search: '',
        user: null,
        openDelete: false,
    });

    function onSearch() {
        setState({ ...state, loading: true, offset: 0 });
    }

    useEffect(() => {
        if (state.loading) {
            getTrashUsers({
                offset: state.offset,
                limit: state.limit,
                search: state.search,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        users: res.data.data.users, 
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button
                        onClick={() => history.push(backUrl)}
                        variant="contained"
                    >
                        Retour
                    </Button>
                    
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    <b>Corbeille utilisateurs</b>
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div className="input-group" style={{ width: 300 }}>
                    <div className="input-group-prepend" onClick={onSearch} style={{ cursor: 'pointer' }}>
                        <span className="input-group-text">
                            <SearchIcon />
                        </span>
                    </div>
                    <InputText
                        label="Chercher un utilisateur..."
                        type="text"
                        value={state.search}
                        onChange={e => setState({ ...state, search: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Prénom
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Nom
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Email
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Nom de l'entreprise
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- USER LIST -------------------- */}
                <UserList
                    state={state}
                    setState={setState}
                />
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------- \\
// --------------------------------------- USER LIST --------------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const UserList = ({ state, setState }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (state.loadingRestore) {
            restoreUser(state.user.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingRestore: false, loading: true });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingRestore: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingRestore]);

    if (state.loading || state.loadingRestore) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <div className="main-container-item" style={{ paddingBottom: 24 }}>
                {state.users.length > 0 ? (
                    state.users.map(user => (
                        <Fragment key={user.id}>
                            <Grid
                                container
                                alignItems="center"
                            >
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {user.first_name}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {user.last_name}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10, overflowWrap: 'break-word' }}>
                                    {user.email}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {user.company_name}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                    <Button
                                        onClick={() => setState({ ...state, loadingRestore: true, user })}
                                        variant="contained"
                                    >
                                        Restaurer
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                    <Button
                                        onClick={() => setState({ ...state, openDelete: true, user })}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Supprimer
                                    </Button>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid container style={{ padding: 10 }}>
                            Aucun
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- FORCE DELETE USER -------------------- */}
            {state.openDelete && (
                <ForceDeleteUser state={state} setState={setState} />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------- \\
// ------------------------------------ FORCE DELETE USER ---------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const ForceDeleteUser = ({ state, setState }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            forceDeleteUser(state.user.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, openDelete: false, loading: true });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    {state.user.first_name ? (
                        <>{state.user.first_name}&nbsp;{state.user.last_name}</>
                    ) : (
                        state.user.company_name
                    )}
                </p>
                Êtes-vous sûr de vouloir supprimer définitivement cet utilisateur ?
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openDelete: false })} variant="contained">
                    Non
                </Button>
                <Button onClick={() => setLoading(true)} variant="contained">
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TrashUsers;
