import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 180;

// -------------------- HEADER -------------------- \\
const AddressDetailsHeader = ({ label }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {label ? label : 'Adresse (complément)'}
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const AddressDetailsFilter = ({ label, state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label={label ? label : 'Adresse (complément)'}
                value={state.address_details}
                onChange={e => setState({ ...state, address_details: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const AddressDetailsValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { AddressDetailsHeader, AddressDetailsFilter, AddressDetailsValue };
