import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, CircularProgress } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { importSpecialSubscriptions } from 'services/requests';

// Components
import InputFile from 'components/input-file';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- ALTERRENAT IMPORT -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const AlterrenatImport = ({ onClose }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        file: null,
    });

    const onImport = () => {
        const file = document.getElementById('upload-alterrenat').files[0];
        if (file) {
            setState({ ...state, loading: true, file });
        }
        else {
            enqueueSnackbar('Veuillez sélectionner un fichier à importer (.xlsx)', { variant: 'warning' });
        }
    };

    useEffect(() => {
        if (state.loading) {

            let formData = new FormData();
            formData.append('file', state.file);

            importSpecialSubscriptions(formData).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    onClose();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Import abos spéciaux
                </p>
                <p style={{ marginBottom: 5 }}>
                    Importer des abonnements gratuits, échanges et Alterrenats depuis un fichier Excel :
                    <br />
                    <b>(.xlsx)</b>
                </p>
                <InputFile
                    inputId="alterrenat"
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    disabled={state.loading}
                    variant="contained"
                >
                    Annuler
                </Button>
                <Button
                    onClick={onImport}
                    disabled={state.loading}
                    variant="contained"
                >
                    Importer
                    {state.loading ? (
                        <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
                    ) : (
                        <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlterrenatImport;
