import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// CSS
import './footer.css';

// ---------------------------------------------------------------------------------------- \\
// ---------------------------------------- FOOTER ---------------------------------------- \\
// ---------------------------------------------------------------------------------------- \\

const Footer = () => {
    const date = new Date();

    return (
        <Grid
            container alignItems="center" justify="center"
            className="footer"
        >
            © {date.getFullYear()} MABD. Tous droits réservés.
            <a
                href="https://www.bio-dynamie.org/mentions-legales/"
                target="blank_"
                style={{ cursor: 'pointer', marginLeft: 4, color: 'white' }}
            >
                Mentions légales.
            </a>
        </Grid>
    );
};

export default Footer;
