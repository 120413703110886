import React from 'react';

// Material-UI
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Styles
const styles = {
    tooltip: {
        backgroundColor: 'black',
        fontSize: 14,
    },
    arrow: {
        color: 'black',
    },
};

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------- TOOLTIP ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const EditedTooltip = props => {
    const { item, title, classes } = props;

    return (
        <Tooltip
            arrow
            placement="top"
            title={title}
            enterDelay={800}
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
            {item}
        </Tooltip>
    );
};

EditedTooltip.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditedTooltip);
