import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getGroup } from 'services/requests';
import { userCanUpdateGroup } from 'services/permissions';
import { membershipStatuses } from 'services/constants';
import { getLabelStatus, getColorStatus } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import PageNotFound from 'components/page-not-found';

// Views
import UpdateGroup from '../update-group';
import ExcelExport from './excel-export';

// --------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- ROUTER ----------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const GroupRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Group />
            </Route>
            {userCanUpdateGroup() && (
                <Route exact path={`${match.path}/update`}>
                    <UpdateGroup />
                </Route>
            )}
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- GROUP ----------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const Group = () => {
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const match = useRouteMatch();

    // Params
    const groupId = match.params.groupId;
    const backUrl = match.path.split('/:groupId')[0];

    const [state, setState] = useState({
        loading: true,
        error: false,
        group: null,
        total: 0,
    });

    useEffect(() => {
        if (state.loading) {
            getGroup(groupId).then(res => {
                if (res.status === 200) {
                    let group = res.data.data.userList;
                    setState({ ...state, loading: false, group, total: group?.users?.length });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button
                        onClick={() => history.push(backUrl)}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    <div style={{ textAlign: 'center' }}>
                        <b>{state.group?.name}</b>
                    </div>
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanUpdateGroup() && (
                        <Button
                            onClick={() => history.push(`${match.url}/update`)}
                            variant="contained"
                        >
                            Modifier
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid
                container justify="space-between" alignItems="center"
                className="toolbar"
            >
                <ExcelExport
                    data={state}
                />
                <b>{state.total} {'Résultat' + (state.total > 1 ? 's' : '')}</b>
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Code client
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Prénom
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Nom
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Email
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Association fédérée
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- LIST -------------------- */}
                <UserList
                    loading={state.loading}
                    error={state.error}
                    group={state.group}
                />
            </div>
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- USER LIST -------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const UserList = ({ loading, error, group }) => {

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <div className="main-container-item" style={{ paddingBottom: 24 }}>
            {group.users.length > 0 ? (
                group.users.map(user => (
                    <Fragment key={user.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                <b style={{ color: getColorStatus(membershipStatuses, user.membershipStatus) }}>
                                    {getLabelStatus(membershipStatuses, user.membershipStatus)}
                                </b>
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {user.clientCode}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {user.first_name}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {user.last_name}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10, overflowWrap: 'break-word' }}>
                                {user.email}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                {user.federatedAssociation ? user.federatedAssociation.name : 'Aucune'}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid container style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    );
};

export default GroupRouter;
