import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { getFormattedDate, getLabelStatus, getColorStatus } from 'services/helpers';
import { subscriptionStatuses } from 'services/constants';

/* * *
* Carte adhésion
*/

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- LAST MEMBERSHIP --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const LastMembership = ({ state }) => {
    const history = useHistory();
    const match = useRouteMatch();
    let membership;

    if (state.user?.currentYearMembership) {
        membership = state.user.currentYearMembership;
    }
    else if (state.user?.nextYearMembership) {
        membership = state.user.nextYearMembership;
    }
    else {
        membership = state.last_membership;
    }

    function getDate() {
        if (membership.start_date && membership.end_date) {
            return getFormattedDate(membership.start_date) + ' - ' + getFormattedDate(membership.end_date);
        }
        return '';
    }

    function getPeriod(membership) {
        const start = membership.start_date;
        const end = membership.end_date;
        if (start && end) {
            return '(valide du ' + getFormattedDate(start) + ' au ' + getFormattedDate(end) + ')';
        }
        return '';
    }

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <b>
                ADHÉSION
                &nbsp;
                {(membership?.membershipType?.is_pro === 0) && (
                    'TOUT PUBLIC'
                )}
                {(membership?.membershipType?.is_pro === 1) && (
                    'PRO'
                )}
            </b>
            {(membership?.membershipType?.is_pro === 1) && (
                <>
                    <br />
                    (Abonnement inclus)
                </>
            )}
            {/* -------------------- MEMBERSHIP -------------------- */}
            {membership && (
                <>
                    <br />
                    {/* -------------------- DATE -------------------- */}
                    {getDate()}
                    {/* -------------------- RENEWAL -------------------- */}
                    {((membership.status === 'validated' || membership.status === 'expired')
                        && membership.newMembership == null
                        && (
                            (Number(membership.end_date?.substring(0, 4)) === moment().year() && moment().format('MM-DD') >= '07-01')
                            || Number(membership.end_date?.substring(0, 4)) === moment().year() - 1
                        )
                    ) && (
                        <>
                            <br />
                            <Button
                                onClick={() => history.push('/shop')}
                                color="primary" variant="contained"
                            >
                                Renouveler votre adhésion
                            </Button>
                        </>
                    )}
                    <Divider style={{ margin: '10px 0px' }} />
                    {/* -------------------- MEMBERSHIP TYPE -------------------- */}
                    <p>
                        <b>Type d'adhésion</b>
                        <br />
                        {membership.membershipType?.name}
                        {/* -------------------- LINKED USER (COUPLE MENBERHSIP) -------------------- */}
                        {(membership.linkedUsers && membership.linkedUsers.length > 0) && (
                            <>
                                <br />
                                <span className="secondary-color">
                                    Le second bénéficiaire est {membership.linkedUsers[0].fullName}
                                </span>
                            </>
                        )}
                    </p>
                    {/* -------------------- ADDRESS -------------------- */}
                    <p>
                        <b>Adresse de facturation</b>
                        <br />
                        {membership.address} {membership.postal_code} {membership.city} {membership.country?.name}
                    </p>
                    {/* -------------------- STATUS -------------------- */}
                    <p>
                        <b>Statut</b>
                        <br />
                        <b style={{ color: getColorStatus(subscriptionStatuses, membership.status) }}>
                            {getLabelStatus(subscriptionStatuses, membership.status)?.toUpperCase()}
                        </b>
                    </p>
                    <Divider style={{ margin: '10px 0px' }} />
                    {/* -------------------- HISTORICAL -------------------- */}
                    <Button
                        onClick={() => history.push(`${match.url}/memberships`)}
                        variant="contained"
                        style={{ textTransform: 'none' }}
                    >
                        Historique des adhésions
                    </Button>
                </>
            )}
            {/* -------------------- COUPLE MEMBERSHIP -------------------- */}
            {state.coupleMembership != null && (
                <>
                    <Divider style={{ margin: '10px 0px' }} />
                    <Grid container className="secondary-color" style={{ textAlign: 'left' }}>
                        Vous bénéficiez de l'adhésion couple souscrite par {state.coupleMembership.user?.fullName}
                        &nbsp;
                        {getPeriod(state.coupleMembership)}
                    </Grid>
                </>
            )}
            {/* -------------------- DOMAIN MEMBERSHIP -------------------- */}
            {state.domainMembership != null && (
                <>
                    <Divider style={{ margin: '10px 0px' }} />
                    <Grid container className="secondary-color" style={{ textAlign: 'left' }}>
                        Vous bénéficiez de l'adhésion professionnelle souscrite par {state.domainMembership.user?.fullName}
                        &nbsp;
                        {getPeriod(state.domainMembership)}
                    </Grid>
                </>
            )}
            {/* -------------------- NONE -------------------- */}
            {(membership == null && state.coupleMembership == null && state.domainMembership == null) && (
                <>
                    <Divider style={{ margin: '10px 0px' }} />
                    <span>Aucune</span>
                </>
            )}
        </>
    );
};

export default LastMembership;
