import moment from 'moment';
import { getStoredUser } from 'services/storage';

/* * *
* Fonctions utilitaires utilisées dans toute l'application
*/

// --------------------------------------------------------------- \\
// ----------------------------- EMAIL --------------------------- \\
// --------------------------------------------------------------- \\

export const isValidEmail = email => {
    if (email != null) {
        const expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return expression.test(String(email).toLowerCase());
    }
    return false;
};

// --------------------------------------------------------------- \\
// ---------------------------- DATE ----------------------------- \\
// --------------------------------------------------------------- \\

export const getFormattedDate = date => {
    if (date != null) {
        const data = date.split('-');
        return data[2] + '/' + data[1] + '/' + data[0];
    }
    return '';
};

export const getMonthYearDate = date => {
    if (date != null) {
        const data = date.split('-');
        return data[1] + '/' + data[0];
    }
    return '';
};



// --------------------------------------------------------------- \\
// ---------------------------- TIME ----------------------------- \\
// --------------------------------------------------------------- \\

export const getFormattedTime = time => {
    if (time != null) {
        return time.slice(0, 5).replace(':', 'h');
    }
    return '';
};

// --------------------------------------------------------------- \\
// ------------------------- CREATED AT -------------------------- \\
// --------------------------------------------------------------- \\

export const getFormattedCreatedAt = data => {
    if (data != null) {
        let date = data.split('T')[0];
        let time = data.split('T')[1].split('.')[0];
        return getFormattedDate(date) + ' à ' + getFormattedTime(time);
    }
    return '';
};

// --------------------------------------------------------------- \\
// ------------------------ LABEL STATUS ------------------------- \\
// --------------------------------------------------------------- \\

export const getLabelStatus = (statusList, value) => {
    const status = statusList.find(s => s.value === value);
    if (status != null) {
        return status.label;
    }
    return '';
};

// --------------------------------------------------------------- \\
// ------------------------ COLOR STATUS ------------------------- \\
// --------------------------------------------------------------- \\

export const getColorStatus = (statusList, value) => {
    const status = statusList.find(s => s.value === value);
    if (status != null) {
        return status.color;
    }
    return null;
};

// --------------------------------------------------------------- \\
// -------------------- CANCEL PARTICIPATION --------------------- \\
// --------------------------------------------------------------- \\

export const cancelParticipationIsAvailable = formationRequest => {
    let storedUser = getStoredUser();

    if (
        formationRequest != null
        && (
            (
                storedUser?.id === formationRequest.user?.id
                && formationRequest.formatSession?.begin
                && moment().subtract(2, 'd').format('YYYY-MM-DD') < formationRequest.formatSession.begin
            ) || (
                storedUser?.role?.id < 4
            )
        )
        && (
            formationRequest.status === 'request_payment_waiting'
            || formationRequest.status === 'request_paid'
            || formationRequest.status === 'registered_payment_waiting'
            || formationRequest.status === 'registered_paid'
        )
    ) {
        return true;
    }
    return false;
};

// --------------------------------------------------------------- \\
// ---------------------------- AMOUNT --------------------------- \\
// --------------------------------------------------------------- \\

export const isValidAmount = amount => {
    if (amount != null && amount !== '' && Number(amount) >= 0) {
        return true;
    }
    return false;
};

// --------------------------------------------------------------- \\
// ------------------------ AMOUNT TO SEND ----------------------- \\
// --------------------------------------------------------------- \\

export const amountToSend = amount => {
    if (amount != null) {
        let res = amount * 100;
        return res.toFixed(0);
    }
    return '';
};

// --------------------------------------------------------------- \\
// -------------------- AMOUNT TO BE RECEIVED -------------------- \\
// --------------------------------------------------------------- \\

export const amountToBeReceived = amount => {
    if (amount != null) {
        let res = amount / 100;
        if (parseInt(res) !== res) {
            return res.toFixed(2);
        }
        return res;
    }
    return '';
};

// --------------------------------------------------------------- \\
// ------------------------- AMOUNT TTC -------------------------- \\
// --------------------------------------------------------------- \\

export const getAmountTTC = amount => {
    if (amount != null) {
        let res = amount / 100;
        if (parseInt(res) !== res) {
            return res.toFixed(2) + '€';
        }
        return res + '€';
    }
    return '0€';
};

// --------------------------------------------------------------- \\
// -------------------------- AMOUNT HT -------------------------- \\
// --------------------------------------------------------------- \\

export const getAmountHT = (amount, tva) => {
    if (amount != null && tva != null) {
        let dividedAmount = amount / 100;
        let dividedTVA = tva / 10000;
        let priceHT = dividedAmount / (1 + dividedTVA);
        let roundedPriceHT = Math.round(priceHT * 100) / 100;
        return roundedPriceHT + '€';
    }
    return '0€';
};

// --------------------------------------------------------------- \\
// ---------------------------- TVA ------------------------------ \\
// --------------------------------------------------------------- \\

export const getTVA = tva => {
    if (tva != null) {
        return (tva / 100) + '%';
    }
    return '0%';
};

// --------------------------------------------------------------- \\
// --------------------- SESSION DAY ADDRESS --------------------- \\
// --------------------------------------------------------------- \\

export const getSessionDayAddress = session_day => {
    const item = session_day.address ? session_day : session_day.trainingPlace;

    let array = [];
    if (item && item.address) array.push(item.address);
    if (item && item.postal_code) array.push(item.postal_code);
    if (item && item.city) array.push(item.city);
    if (item && item.country) array.push(item.country.name);

    return array.join(' ');
};

// --------------------------------------------------------------- \\
// ------------------------- CHECK NUMBER ------------------------ \\
// --------------------------------------------------------------- \\

export const isValidCheckNumber = reference => {
    if (reference != null && reference.toString().length === 7) {
        return true;
    }
    return false;
};

// --------------------------------------------------------------- \\
// --------------------------- GEOCODE --------------------------- \\
// --------------------------------------------------------------- \\

export const getGeocode = item => {
    if (item != null && item?.postal_code != null && item?.city != null) {
        return ({
            label: item.postal_code + ' ' + item.city,
            value: item.postal_code + '&' + item.city,
        });
    }
    return null;
};

// --------------------------------------------------------------- \\
// ---------------------- FRENCH DEPARTMENT ---------------------- \\
// --------------------------------------------------------------- \\

export const getFrenchDepartmentLabel = frenchDepartment => {
    if (frenchDepartment != null) {
        return frenchDepartment.name + ' (' + frenchDepartment.number + ')';
    }
    return '';
};

// --------------------------------------------------------------- \\
// -------------------------- FILTER ORDER ----------------------- \\
// --------------------------------------------------------------- \\

export const getFilterOrder = value => {
    if (value === 'desc') {
        return 'asc';
    }
    return 'desc';
};

// --------------------------------------------------------------- \\
// ----------------------------- YEARS --------------------------- \\
// --------------------------------------------------------------- \\

export const getCurrentYear = () => {
    return moment().month() < 10 ? moment().year() : moment().add(1, 'y').year();
};

export const getNextYear = () => {
    return moment().month() < 10 ? moment().add(1, 'y').year() : moment().add(2, 'y').year();
};

// --------------------------------------------------------------- \\
// ------------------------ FILE EXTENSION ----------------------- \\
// --------------------------------------------------------------- \\

export const getFileExtension = fileName => {
    let index = fileName?.lastIndexOf('.');
    if (index && index !== -1) {
        return fileName.slice(index, fileName.length);
    }
    return '.pdf';
};

// --------------------------------------------------------------- \\
// ------------------- SUBSCRIPTION ORIGIN LABEL ----------------- \\
// --------------------------------------------------------------- \\

export const getSubscriptionOriginLabel = (origin) => {
    switch (origin) {
        case 'alone':
            return 'Seul';

        case 'simple':
            return 'Particulier';

        case 'pro':
            return 'Professionnel';

        default:
            return '';
    };
};

// --------------------------------------------------------------- \\
// ------------------------- GET USERNAME ------------------------ \\
// --------------------------------------------------------------- \\

export const getUsername = (user) => {
    let array = [];
    if (user.first_name && user.last_name) {
        array.push(user.first_name + ' ' + user.last_name);
    }
    if (user.company_name) {
        array.push(user.company_name);
    }
    return array.join(' / ');
};
