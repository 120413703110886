import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- DAY PROGRAM FORM ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const DayProgramForm = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- START TIME -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Heure de début<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Heure de début"
                        type="time"
                        value={state.start_time}
                        onChange={e => setState({ ...state, start_time: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- END TIME -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Heure de fin<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Heure de fin"
                        type="time"
                        value={state.end_time}
                        onChange={e => setState({ ...state, end_time: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- DURATION -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Durée (en heures)<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Durée (h)"
                        type="number"
                        value={state.duration}
                        onChange={e => setState({ ...state, duration: e.target.value })}
                    />
                </Grid>
            </Grid>                
            {/* -------------------- DESCRIPTION -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 14 }}>
                <InputText
                    label="Description..."
                    multiline
                    value={state.description}
                    onChange={e => setState({ ...state, description: e.target.value })}
                />
            </Grid>
        </>
    );
};

export default DayProgramForm;
