import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useParams } from 'react-router-dom';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Dialog, DialogContent, DialogActions, Divider, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

// Services
import { addManagerToDomain, removeManagerFromDomain, searchUsers } from 'services/requests';

// Assets
import Autocomplete from 'components/autocomplete';
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';
import Error from 'components/error';
import useMediaQueries from 'services/media';

// Messages
import messages from 'messages.json';

// ----------------------------------- MANAGERS ---------------------------------- \\
const Managers = ({ domain }) => {
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        error: false,
        managers: domain.managers || [],
        contact: null,
        openAdd: false,
        openRemove: false,
    });

    function onAddManager(manager) {
        let managers = state.managers;
        managers.push(manager);
        setState({ ...state, openAdd: false, managers });
    }

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <Grid item xs={6}>
                    Gestionnaires
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                    <Button onClick={() => setState({ ...state, openAdd: true })} variant="contained">
                        Ajouter un gestionnaire
                    </Button>
                </Grid>
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Prénom
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Nom
                        </Grid>
                        <Grid item xs={4} style={{ padding: 10 }}>
                            Email
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10, textAlign: 'center' }}>
                            Action
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- MANAGER LIST -------------------- */}
                <ManagerList
                    state={state}
                    setState={setState}
                />
            </div>
            {/* -------------------- MANAGER USER -------------------- */}
            {state.openAdd && (
                <AddManager
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={manager => onAddManager(manager)}
                />
            )}
        </>
    );
};

// --------------------------------- MANAGER LIST --------------------------------- \\
const ManagerList = ({ state, setState }) => {

    function onRemove(manager_id) {
        let managers = state.managers;
        let index = managers.findIndex(c => c.id === manager_id);
        managers.splice(index, 1);
        setState({ ...state, openRemove: false, managers });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <div className="main-container-item">
            {state.managers.length > 0 ? (
                state.managers.map(manager => (
                    <Fragment key={manager.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                {manager.first_name}
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                {manager.last_name}
                            </Grid>
                            <Grid item xs={12} md={4} style={{ padding: 10 }}>
                                {manager.email}
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                <Tooltip title="Supprimer"
                                    item={(
                                        <IconButton
                                            onClick={() => setState({ ...state, openRemove: true, manager })}
                                            style={{ padding: 6 }}       
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid container style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- REMOVE MANAGER -------------------- */}
            {state.openRemove && (
                <RemoveManager
                    onClose={() => setState({ ...state, openRemove: false })}
                    refetch={manager_id => onRemove(manager_id)}
                    manager={state.manager}
                />
            )}
        </div>
    );
};

// ---------------------------------- ADD MANAGER --------------------------------- \\
const AddManager = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { domainId } = useParams();

    const [state, setState] = useState({
        loading: false,
        options: [],
        manager: null,
        searchRef: undefined,
        loadingAdd: false,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    function onAdd() {
        if (state.manager == null) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loadingAdd: true });
        }
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 3) {
                searchUsers({ search: state.searchRef }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.data.users.map(u => ({ label: u.fullName + ' (' + u.clientCode + ')', value: u.id })),
                        });
                    }
                    else {
                        setState({ ...state, loading: false });
                    }
                });
            }
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingAdd) {
             addManagerToDomain(domainId, { user_id: state.manager.value }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.user);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingAdd]);

    return (
        <Dialog open fullWidth>
            {state.loadingAdd ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un gestionnaire
                        </p>
                        <Autocomplete
                            value={state.manager}
                            options={state.options}
                            loading={state.loading}
                            onChange={(e, value) => setState({ ...state, manager: value })}
                            onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
                            placeholder="Chercher un utilisateur..."
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// --------------------------------- REMOVE MANAGER  ------------------------------- \\
const RemoveManager = ({ onClose, refetch, manager }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { domainId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            removeManagerFromDomain(domainId, manager.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(manager.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {manager.first_name} {manager.last_name}
                        </p>
                        Êtes-vous sûr de vouloir retirer ce gestionnaire ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default Managers;
