import React from 'react';

/* * *
* SAISIE DE NUMÉRO DE TÉLÉPHONE
* L'ajout d'espaces entre 2 chiffres à la fin de la saisie d'un numéro de téléphone se fait
* à l'aide de regex (à retirer si les espaces à l'affichage n'est plus souhaité).
*/

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- INPUT PHONE ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const InputPhone = ({ label, value, setValue, disabled, onKeyPress }) => {

    let regex = /^\d+$/; // Ne garde que les chiffres
    let maxLength = 10;

    const onChange = value => {

        let phoneNumber = value.replace(/\s/g, ''); // Retire les espaces vides

        if (phoneNumber === '' || (phoneNumber === '+' && phoneNumber.length === 1)) {
            setValue(phoneNumber);
        }
        else {

            if (phoneNumber.substring(0, 1) === '+') {
                phoneNumber = phoneNumber.replace('+', '');
                maxLength = 12;
            }

            if ((phoneNumber === '' || regex.test(phoneNumber)) && phoneNumber.length <= maxLength) {

                // Ajout d'espaces tous les 2 chiffres
                if (phoneNumber.length === 10 && maxLength === 10) {
                    phoneNumber = phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
                }
                if (phoneNumber.length === 12) {
                    phoneNumber = phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5 $6');
                }

                // Ajout du caractère + devant le numéro de téléphone
                setValue((value.includes('+') ? '+' : '') + phoneNumber);
            }
        }
    };

    return (
        <input
            placeholder={label}
            value={value}
            onChange={e => onChange(e.target.value)}
            disabled={disabled}
            onKeyPress={onKeyPress}
            className="form-control"
            type="tel"
            style={{ fontSize: 14 }}
        />
    );
};

export default InputPhone;
