import useMediaQuery from '@material-ui/core/useMediaQuery';

/* * *
* Hook permettant de gérer le responsive (adaptation aux dimensions de l'écran)
*/

// Material-UI BreakPoints
const useMediaQueries = () => {
    return {
        mediaXL: useMediaQuery('(min-width:1920px)'),
        mediaLG: useMediaQuery('(min-width:1280px)'),
        mediaMD: useMediaQuery('(min-width:960px)'),
        mediaSM: useMediaQuery('(min-width:600px)'),
        mediaXS: useMediaQuery('(min-width:0px)'),
    };
};

export default useMediaQueries;
