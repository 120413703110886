import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, Paper } from '@material-ui/core';

// Services
import { getUserDashboard, getUser } from 'services/requests';
import { getStoredUser } from 'services/storage';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import useMediaQueries from 'services/media';

// Views
import Profile from './profile';
import Informations from './profile/informations';
import ConsultingVisit from './consulting-visit';
import LastSubscription from './subscriptions';
import LastMembership from './memberships';
import FormationRequests from './formation-requests';
import LastBill from './bills';
import CreateConsultingVisit from '../management/consulting-visits/create-consulting-visit';
import SubscriptionsHistory from './subscriptions/subscriptions-history';
import MembershipsHistory from './memberships/memberships-history';
import FormationRequestsHistory from './formation-requests/formation-requests-history';
import BillsHistory from './bills/bills-history';
import PaymentMethod from '../common/payment-method';

/* * *
* Tableau de bord
*/

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- DASHBOARD ROUTER ------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const DashboardRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Dashboard />
            </Route>
            <Route exact path={`${match.path}/subscriptions`}>
                <SubscriptionsHistory />
            </Route>
            <Route exact path={`${match.path}/memberships`}>
                <MembershipsHistory />
            </Route>
            <Route path={`${match.path}/formation-requests`}>
                <FormationRequestsHistory />
            </Route>
            <Route path={`${match.path}/bills`}>
                <BillsHistory />
            </Route>
            <Route exact path={`${match.path}/consulting-visit/:userId`}>
                <CreateConsultingVisit />
            </Route>
            <Route exact path={`${match.path}/payment-method/:billId`}>
                <PaymentMethod />
            </Route>
            <Redirect to="/dashboard" />
        </Switch>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- DASHBOARD ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const Dashboard = () => {
    const { mediaMD, mediaLG } = useMediaQueries();
    const storedUserId = getStoredUser()?.id;

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: null,
        last_subscription: null,
        last_membership: null,
        formation_requests: [],
        last_bill: null,
        consulting_visit: null,
        cancelOrder: false,
        openDeleteConsultingVisit: false,
        openCancelParticipation: false,
        formationRequest: null,
        coupleMembership: null,
        domainMembership: null,
    });

    useEffect(() => {
        if (state.loading) {
            axios.all([getUser(storedUserId), getUserDashboard(storedUserId)])
            .then(axios.spread(function (res_user, res_dashboard) {
                if (res_user.status === 200 && res_dashboard.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        user: res_user.data.data.user,
                        last_subscription: res_dashboard.data.data.lastSubscription,
                        last_membership: res_dashboard.data.data.lastMembership,
                        formation_requests: res_dashboard.data.data.formationRequests,
                        last_bill: res_dashboard.data.data.lastBill,
                        consulting_visit: res_dashboard.data.data.consultingVisit,
                        coupleMembership: res_dashboard.data.data.coupleMembership,
                        domainMembership: res_dashboard.data.data.domainMembership,
                    });
                }
                else {
                    console.log(res_user + res_dashboard);
                    setState({ ...state, loading: false, error: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                Tableau de bord
            </Grid>
            <Grid container style={{ padding: 24 }}>
                {/* -------------------- PROFILE -------------------- */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper elevation={3} style={{ padding: 10, textAlign: 'center', minHeight: 230 }}>
                        <Profile state={state} setState={setState} />
                    </Paper>
                </Grid>
                {/* -------------------- INFORMATIONS -------------------- */}
                <Grid
                    item xs={12} md={8} lg={6}
                    style={{ paddingLeft: mediaMD ? 24 : 0, paddingTop: mediaMD ? 0 : 24 }}
                >
                    <Paper elevation={3} style={{ padding: 10, textAlign: 'center', minHeight: 230 }}>
                        <Informations state={state} />
                    </Paper>
                </Grid>
                {/* -------------------- CONSULTING VISITS -------------------- */}
                <Grid
                    item xs={12} md={6} lg={3}
                    style={{ paddingLeft: mediaLG ? 24 : 0, paddingTop: mediaLG ? 0 : 24 }}
                >
                    <ConsultingVisit state={state} setState={setState} />
                </Grid>
            </Grid>
            <Grid container style={{ padding: '0px 24px 24px 24px' }}>
                {/* -------------------- LAST SUBSCRIPTION -------------------- */}
                <Grid item xs={12} md={6} lg={3}>
                    <Paper elevation={3} style={{ padding: 10, textAlign: 'center' }}>
                        <LastMembership state={state} />
                    </Paper>
                </Grid>
                {/* -------------------- LAST MEMBERSHIP -------------------- */}
                <Grid
                    item xs={12} md={6} lg={3}
                    style={{ paddingLeft: mediaMD ? 24 : 0, paddingTop: mediaMD ? 0 : 24 }}
                >
                    <Paper elevation={3} style={{ padding: 10, textAlign: 'center' }}>
                        <LastSubscription state={state} />
                    </Paper>
                </Grid>
                {/* -------------------- FORMATION -------------------- */}
                <Grid
                    item xs={12} md={6} lg={3}
                    style={{ paddingLeft: mediaMD ? 24 : 0, paddingTop: mediaMD ? 0 : 24 }}
                >
                    <Paper elevation={3} style={{ padding: 10, textAlign: 'center' }}>
                        <FormationRequests state={state} setState={setState} />
                    </Paper>
                </Grid>
                {/* -------------------- LAST BILL -------------------- */}
                <Grid
                    item xs={12} md={6} lg={3}
                    style={{ paddingLeft: mediaMD ? 24 : 0, paddingTop: mediaMD ? 0 : 24 }}
                >
                    <Paper elevation={3} style={{ padding: 10, textAlign: 'center' }}>
                        <LastBill state={state} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardRouter;
