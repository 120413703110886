import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, Switch, Route, useParams } from 'react-router-dom';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getUser } from 'services/requests';
import {
    userCanUpdateUser, userCanCreateConsultingVisit, userCanCreateFormation,
    userCanHandleSubscriptionForUser, userCanUpdateConsultingVisit,
} from 'services/permissions';
import { getUsername } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import PageNotFound from 'components/page-not-found';

// Views
import UpdateUser from '../update-user';
import UserDetails from './details';
import UserMemberships from './memberships';
import UserSubscriptions from './subscriptions';
import UserFormationRequests from './formation-requests';
import UserBills from './bills';
import CreateConsultingVisit from 'views/management/consulting-visits/create-consulting-visit';
import UpdateConsultingVisit from 'views/management/consulting-visits/update-consulting-visit';
import Bill from 'views/common/bill';
import FormationSession from 'views/dasboard/formation-requests/formation-session';
import UserMembershipTypes from './memberships/user-membership-types';
import UserSubscriptionTypes from './subscriptions/user-subscription-types';
import UserFormationSessions from './formation-requests/user-formation-sessions';
import CreateUserFormationRequest from './formation-requests/create-user-formation-request';
import CreateUserSubscription from './subscriptions/create-user-subscription';

// --------------------------------------------------------------------------------------- \\
// ------------------------------------ USER ROUTER -------------------------------------- \\
// --------------------------------------------------------------------------------------- \\

const UserRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <User />
            </Route>
            <Route exact path={`${match.path}/update`}>
                <UpdateUser />
            </Route>
            <Route path={`${match.path}/bills/:billId`}>
                <Bill />
            </Route>
            <Route exact path={`${match.path}/formation/:formationSessionId`}>
                <FormationSession />
            </Route>
            {/* -------------------- CONSULTING VISIT -------------------- */}
            {userCanCreateConsultingVisit() && (
                <Route exact path={`${match.path}/consulting-visit`}>
                    <CreateConsultingVisit />
                </Route>
            )}
            {userCanUpdateConsultingVisit() && (
                <Route exact path={`${match.path}/consulting-visit/:consultingVisitId`}>
                    <UpdateConsultingVisit />
                </Route>
            )}
            {/* -------------------- SUBSCRIPTION TYPES -------------------- */}
            {userCanHandleSubscriptionForUser() && (
                <Route exact path={`${match.path}/shop/subscriptions`}>
                    <UserSubscriptionTypes />
                </Route>
            )}
            {/* -------------------- MEMBERSHIP TYPES -------------------- */}
            {userCanHandleSubscriptionForUser() && (
                <Route exact path={`${match.path}/shop/memberships`}>
                    <UserMembershipTypes />
                </Route>
            )}
            {/* -------------------- CREATE SUBSCRIPTION -------------------- */}
            {userCanHandleSubscriptionForUser() && (
                <Route exact path={`${match.path}/shop/:subscriptionType/:subscriptionTypeId`}>
                    <CreateUserSubscription />
                </Route>
            )}
            {/* -------------------- FORMATION SESSIONS -------------------- */}
            {userCanCreateFormation() && (
                <Route exact path={`${match.path}/formations`}>
                    <UserFormationSessions />
                </Route>
            )}
            {/* -------------------- CREATE FORMATION REQUEST -------------------- */}
            {userCanCreateFormation() && (
                <Route exact path={`${match.path}/formations/:formationSessionId/session`}>
                    <CreateUserFormationRequest />
                </Route>
            )}
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// --------------------------------------------------------------------------------------- \\
// ---------------------------------------- USER ----------------------------------------- \\
// --------------------------------------------------------------------------------------- \\

const User = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();
    const { userId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: null,
    });

    useEffect(() => {
        if (state.loading) {
            getUser(userId).then(res => {
                let user = res?.data?.data?.user;
                if (res?.status === 200 && user) {
                    setState({ ...state, loading: false, user });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push('/users')}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    <b>{getUsername(state.user)}</b>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-end' : 'center'}
                >
                    {userCanUpdateUser(state.user) && (
                        <Button
                            onClick={() => history.push(`${match.url}/update`)}
                            variant="contained"
                        >
                            Modifier
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- DETAILS -------------------- */}
            <UserDetails
                user={state.user}
            />
            {/* -------------------- MEMBERSHIPS -------------------- */}
            <UserMemberships
                user={state.user}
            />
            {/* -------------------- SUBSCRIPTIONS -------------------- */}
            <UserSubscriptions
                user={state.user}
            />
            {/* -------------------- FORMATION REQUESTS -------------------- */}
            <UserFormationRequests
                user={state.user}
            />
            {/* -------------------- BILLS -------------------- */}
            <UserBills
                user={state.user}
            />
        </>
    );
};

export default UserRouter;
