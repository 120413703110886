import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getFormationRequestsForSession } from 'services/requests';
import { getLabelStatus, getFormattedDate, getAmountTTC, getFormattedCreatedAt } from 'services/helpers';
import { formationRequestStatuses, billStates, paymentMethods, sessionPriceStatuses } from 'services/constants';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------- FORMATION REQUESTS EXPORT ---------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationRequestsExport = ({ formationSession, data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getAllFormationRequestsToExport() {
        getFormationRequestsForSession(formationSession.id, {
            order_by: data.order_by,
            order: data.order,
            statuses: data.statuses != null ? data.statuses.map(s => s.value) : null,
            created_at: data.created_at,
            last_name: data.last_name,
            first_name: data.first_name,
            email: data.email,
            mobile_phone: data.mobile_phone,
            phone: data.phone,
            company_name: data.company_name,
            client_code: data.client_code,
            external_client_code: data.external_client_code,
            session_price_statuses: data.session_price_statuses != null ? data.session_price_statuses.map(s => s.value) : null,
            bill_payment_methods: data.bill_payment_methods != null ? data.bill_payment_methods.map(m => m.value) : null,
            bill_date: data.bill_date,
            bill_reference: data.bill_reference,
            bill_state: data.bill_state,
            address: data.address,
            address_details: data.address_details,
            postal_codes: data.postal_codes != null ? data.postal_codes.map(c => c.value) : null,
            cities: data.cities != null ? data.cities.map(c => c.value) : null,
            birth_date: data.birth_date,
        }).then(res => {
            if (res.status === 200) {
                createExcelFile(res.data.data.formationRequests);
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(formationRequests) {
        if (formationRequests.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {

            // Create Excel File
            var workbook = new ExcelJS.Workbook();
            
            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Utilisateurs incrsits');

            // Headers
            var headers = [
                { header: 'Statut inscription', key: 'status', width: 30 },
                { header: 'Date demande pré-inscription', key: 'created_at', width: 30 },
                { header: 'Prénom', key: 'first_name', width: 30 },
                { header: 'Nom', key: 'last_name', width: 30 },
                { header: 'Email compte', key: 'email', width: 30 },
                { header: 'Téléphone portable', key: 'mobile_phone', width: 30 },
                { header: 'Téléphone fixe', key: 'phone', width: 30 },
                { header: 'Commentaire stagiaire', key: 'message', width: 30 },
                { header: 'Nom de l\'entreprise', key: 'company_name', width: 30 },
                { header: 'Code client', key: 'client_code', width: 30 },
                { header: 'Code client externe', key: 'external_client_code', width: 30 },
                { header: 'Formule choisie', key: 'price_description', width: 30 },
                { header: 'Tarif formule', key: 'price', width: 30 },
                { header: 'Montant total BC', key: 'bill_total_amount', width: 30 },
                { header: 'Montant reçu', key: 'amount_received', width: 30 },
                { header: 'Mode de paiement BC', key: 'bill_payment_methods', width: 30 },
                { header: 'Date BC', key: 'bill_date', width: 30 },
                { header: 'Référence BC', key: 'bill_reference', width: 30 },
                { header: 'Etat BC', key: 'bill_state', width: 30 },
                { header: 'Commentaire responsable', key: 'general_comment', width: 30 },
                { header: 'Adresse (n° et voie)', key: 'address', width: 30 },
                { header: 'Adresse (complément)', key: 'address_details', width: 30 },
                { header: 'Code postal', key: 'postal_code', width: 30 },
                { header: 'Ville', key: 'city', width: 30 },
                { header: 'Date de naissance', key: 'birth_date', width: 30 },
            ];

            // Add Session Options Headers
            data.sessionOptions.forEach(option => {
                headers.push({ header: option.name, key: option.id, width: 30 });
            });

            worksheet.columns = headers;
            worksheet.getRow(1).font = { bold: true };

            // Rows
            formationRequests.forEach(formationRequest => {

                var row = {
                    status: getLabelStatus(formationRequestStatuses, formationRequest.status),
                    created_at: formationRequest.created_at ? getFormattedCreatedAt(formationRequest.created_at) : '',
                    first_name: formationRequest.user?.first_name || '',
                    last_name: formationRequest.user?.last_name || '',
                    email: formationRequest.user?.email || '',
                    mobile_phone: formationRequest.user?.mobile_phone || '',
                    phone: formationRequest.user?.phone || '',
                    message: formationRequest.message || '',
                    company_name: formationRequest.user?.company_name || '',
                    client_code: formationRequest.user?.clientCode || '',
                    external_client_code: formationRequest.user?.external_client_code || '',
                    price_description: getLabelStatus(sessionPriceStatuses, formationRequest.sessionPrice?.status),
                    price: getAmountTTC(formationRequest.sessionPrice?.price),
                    bill_total_amount: getAmountTTC(formationRequest.bill?.total_amount),
                    amount_received: getAmountTTC(formationRequest.amount_received),
                    bill_payment_methods: getLabelStatus(paymentMethods, formationRequest.bill?.payment_method),
                    bill_date: getFormattedCreatedAt(formationRequest.bill?.created_at),
                    bill_reference: formationRequest.bill?.reference,
                    bill_state: getLabelStatus(billStates, formationRequest.bill?.bill_state),
                    general_comment: formationRequest?.general_comment || '',
                    address: formationRequest.user?.address || '',
                    postal_code: formationRequest.user?.postal_code || '',
                    city: formationRequest.user?.city || '',
                    birth_date: formationRequest.user ? getFormattedDate(formationRequest.user.birth_date) : ''
                };

                // Add Session Options Values
                var sessionOptions = {};
                data.sessionOptions.forEach(option => {
                    sessionOptions[option.id] = (formationRequest.sessionOptions.findIndex(o => o.id === option.id) !== -1) ? getAmountTTC(option.price) : '';
                });
                row = Object.assign({}, row, sessionOptions);

                worksheet.addRow(row);
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'utilisateurs-pre-inscrits.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllFormationRequestsToExport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default FormationRequestsExport;
