import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- SESSION OPTION FORM ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const SessionOptionForm = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Intitulé<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Intitulé"
                        value={state.name}
                        onChange={e => setState({ ...state, name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- PRICE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Prix (€)<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Prix (€)"
                        type="number"
                        value={state.price}
                        onChange={e => setState({ ...state, price: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- DESCRIPTION -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 14 }}>
                <InputText
                    label="Description..."
                    multiline
                    value={state.description}
                    onChange={e => setState({ ...state, description: e.target.value })}
                />
            </Grid>
        </>
    );
};

export default SessionOptionForm;
