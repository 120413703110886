import React from 'react';

// Material-UI
import { Grid, Radio, Checkbox, ListItem } from '@material-ui/core';

// Services
import { getAmountTTC } from 'services/helpers';
import { sessionPriceStatuses } from 'services/constants';

// -------------------------------------------------------------------------------------- \\
// ------------------------------- FORMATION REQUEST PRICES ----------------------------- \\
// -------------------------------------------------------------------------------------- \\

const FormationRequestPrices = ({ state, setState }) => {
    const formationSession = state.formationSession;

    function getSelectedPrice(session_price) {
        if (state.session_price && state.session_price.id === session_price.id) {
            return true;
        }
        return false;
    }

    function handleOptions(sessionOption) {
        let options = state.options;

        if (options.map(o => o.id).includes(sessionOption.id)) {
            const index = options.findIndex(o => o.id === sessionOption.id);
            options.splice(index, 1);
        }
        else {
            options.push(sessionOption);
        }
        setState({ ...state, options });
    }

    function getSelectedOptions(sessionOptionId) {
        if (state.options.map(o => o.id).includes(sessionOptionId)) {
            return true;
        }
        return false;
    }

    return (
        <div
            className="light-background-color"
            style={{ padding: 24 }}
        >
            <div style={{ fontWeight: 600, fontSize: 20, textTransform: 'uppercase' }}>
                Choisissez votre formule
            </div>
            {/* ---------------------- SESSION PRICES ---------------------- */}
            <div style={{ marginTop: 24 }}>
                <div
                    className="input-title"
                    style={{ fontSize: 14 }}
                >
                    Tarifs
                </div>
                <div style={{ marginTop: 6 }}>
                    {formationSession.sessionPrices.length > 0 ? (
                        formationSession.sessionPrices.map((sessionPrice, index) => (
                            <ListItem
                                key={sessionPrice.id}
                                onClick={formationSession.status !== 'closed' ? () => setState({ ...state, session_price: sessionPrice }) : undefined}
                                button={formationSession.status !== 'closed'}
                                style={{ padding: 0, backgroundColor: 'white', marginTop: index > 0 ? 2 : undefined }}
                            >
                                <Grid container alignItems="center">
                                    <Grid
                                        item xs="auto"
                                        style={{ padding: 10 }}
                                    >
                                        {formationSession.status !== 'closed' && (
                                            <Radio
                                                color="secondary"
                                                checked={getSelectedPrice(sessionPrice)}
                                                style={{ padding: 6 }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        item xs="auto" sm={2} md={1}
                                        style={{ padding: 10 }}
                                    >
                                        {getAmountTTC(sessionPrice.price)}
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={2}
                                        style={{ padding: 10 }}
                                    >
                                        {sessionPriceStatuses.find(s => s.value === sessionPrice.status)?.label}
                                    </Grid>
                                    <Grid
                                        item xs={12} md
                                        style={{ padding: 10 }}
                                    >
                                        {sessionPrice.description}
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))
                    ) : (
                        <div style={{ padding: 10, backgroundColor: 'white' }}>
                            Aucun
                        </div>
                    )}
                </div>
            </div>
            {/* ---------------------- SESSION OPTIONS ---------------------- */}
            <div style={{ marginTop: 24 }}>
                <div
                    className="input-title"
                    style={{ fontSize: 14 }}
                >
                    Options
                </div>
                <div style={{ marginTop: 6 }}>
                    {formationSession.sessionOptions.length > 0 ? (
                        formationSession.sessionOptions.map((sessionOption, index) => (
                            <ListItem
                                key={sessionOption.id}
                                onClick={formationSession.status !== 'closed' ? () => handleOptions(sessionOption) : undefined}
                                button={formationSession.status !== 'closed'}
                                style={{ padding: 0, backgroundColor: 'white', marginTop: index > 0 ? 2 : undefined }}
                            >
                                <Grid container alignItems="center">
                                    <Grid 
                                        item xs="auto"
                                        style={{ padding: 10 }}
                                    >
                                        {formationSession.status !== 'closed' && (
                                            <Checkbox
                                                color="secondary"
                                                checked={getSelectedOptions(sessionOption.id)}
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        item xs="auto" sm={2} md={1}
                                        style={{ padding: 10 }}
                                    >
                                        {getAmountTTC(sessionOption.price)}
                                    </Grid>
                                    <Grid
                                        item xs={12} sm={2}
                                        style={{ padding: 10 }}
                                    >
                                        {sessionOption.name}
                                    </Grid>
                                    <Grid
                                        item xs={12} md
                                        style={{ padding: 10 }}
                                    >
                                        {sessionOption.description}
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))
                    ) : (
                        <div style={{ padding: 10, backgroundColor: 'white' }}>
                            Aucune
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormationRequestPrices;
