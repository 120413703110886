import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getSession, createGroupedFormationRequests } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { getFormattedDate, amountToSend } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import FormationRequestForm1 from './formation-request-form-1';
import FormationRequestForm2 from './formation-request-form-2';
import FormationRequestForm3 from './formation-request-form-3';

// --------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- FORMATION REQUEST --------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const MembersFormationRequest = () => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { formationSessionId } = useParams();
    const storedUser = getStoredUser();

    const [state, setState] = useState({
        // Common
        page: 1,
        loading: true,
        loadingCreate: false,
        error: false,
        formationSession: undefined,

        // Form 1
        domainsLoading: true,
        domains: [],
        domainsWithUsers: null,

        // Form 2
        users: [],
        user_registrations: [],
        usersCompleted: null,
        accordions: null,

        // Form 3
        first_name: (storedUser && storedUser.first_name) ? storedUser.first_name : '',
        last_name: (storedUser && storedUser.last_name) ? storedUser.last_name : '',
        email: (storedUser && storedUser.email) ? storedUser.email : '',
        company_name: '',
        address: '',
        address_details: '',
        city: '',
        postal_code: '',
        country_id: (storedUser && storedUser.country_id) ? storedUser.country_id : 1,
        contribution_amount: '',
        payment_method: 'cb',
        cgv: false,
        pdc: false,
        price: 0,
    });

    useEffect(() => {
        if (state.loading) {
            getSession(formationSessionId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, formationSession: res.data.data.formationSession });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingCreate) {
            createGroupedFormationRequests(formationSessionId, {
                first_name: state.first_name,
                last_name: state.last_name,
                company_name: state.company_name,
                address: state.address,
                address_details: state.address_details,
                city: state.city,
                postal_code: state.postal_code,
                country_id: state.country_id,
                email: state.email,
                contribution_amount: amountToSend(state.contribution_amount),
                payment_method: state.payment_method,
                user_registrations: state.user_registrations,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/payment-method/' + res.data.data.bill.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingCreate: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingCreate]);

    if (state.loading || state.loadingCreate) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => history.push('/formations')} variant="contained">
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    Formulaire d'inscription pour des membres
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <div style={{ backgroundColor: 'rgba(255, 0, 0, 0.1)', borderRadius: 4, padding: 4 }}>
                        Fin d'inscription : {getFormattedDate(state.formationSession.end_of_registration)}
                    </div>
                </Grid>
            </Grid>
            {/* -------------------- FORMS -------------------- */}
            {state.page === 1 && (
                <FormationRequestForm1
                    state={state}
                    setState={setState}
                />
            )}
            {state.page === 2 && (
                <FormationRequestForm2
                    state={state}
                    setState={setState}
                />
            )}
            {state.page === 3 && (
                <FormationRequestForm3
                    state={state}
                    setState={setState}
                />
            )}
        </>
    );
};

export default MembersFormationRequest;
