import React, { useState, useEffect } from 'react';

// Services
import { consultingVisitNeeds } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------- SELECT CONSULTING VISIT NEEDS -------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectConsultingVisitNeeds = ({ needs, setNeeds }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(consultingVisitNeeds.map(s => ({ label: s.label, value: s.value })));
    }, []);

    return (
        <SelectMultiple
            value={needs}
            onChange={value => setNeeds(value)}
            options={options}
            placeholder="Besoins"
        />
    );
};

export default SelectConsultingVisitNeeds;
