import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, CircularProgress } from '@material-ui/core';

// Services
import { generateTrainingCertificates } from 'services/requests';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------- GENERATE TRAINING CERTIFICATES -------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const GenerateTrainingCertificates = ({ formationSessionId, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            generateTrainingCertificates(formationSessionId).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    console.log(res);
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Button
            onClick={() => setLoading(true)}
            disabled={(formationSessionId == null) || loading}
            variant="contained" color="primary"
        >
            {loading ? (
                <>
                    Génération en cours...
                    <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
                </>
            ) : (
                'Générer les attestations'
            )}
        </Button>
    );
};

export default GenerateTrainingCertificates;
