import React from 'react';

// Material-UI
import { MenuItem } from '@material-ui/core';

// Components
import SelectOutlined from 'components/select-outlined';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- SELECT YES OR NO ------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectYesOrNo = ({ value, onChange, label, disabled, labelFalse, labelTrue }) => {
    return (
        <SelectOutlined
            value={value}
            onChange={e => onChange(e.target.value)}
            disabled={disabled}
        >
            <MenuItem value={-1}>
                <em>{label}</em>
            </MenuItem>
            <MenuItem value={0}>
                {labelFalse}
            </MenuItem>
            <MenuItem value={1}>
                {labelTrue}
            </MenuItem>
        </SelectOutlined>
    );
};

// Propriétés par défaut
SelectYesOrNo.defaultProps = {
    label: 'Sélectionner',
    labelFalse: 'Non',
    labelTrue: 'Oui',
    disabled: false,
};

export default SelectYesOrNo;
