import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Dialog, DialogActions, DialogContent, Divider } from '@material-ui/core';

// Services
import { recoverPayment } from 'services/requests';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------ CANCEL PAYMENT ---------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const CancelPayment = ({ onClose, refetch, payment }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            recoverPayment(payment.id, {
                amount: 0,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog
            open
            fullWidth
        >
            <DialogContent style={{ paddingBottom: 20, textAlign: 'center' }}>
                <p className="dialog-title">
                    Annuler le paiement
                </p>
                <span>
                    Êtes-vous sûr de vouloir annuler ce paiement ?
                </span>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    variant="contained"
                >
                    Non
                </Button>
                <Button
                    onClick={() => setLoading(true)}
                    variant="contained"
                >
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CancelPayment;
