import React, { useEffect } from 'react';

// Moment
import moment from 'moment';

// Services
import { getAmountTTC, isValidAmount } from 'services/helpers';

/* * *
* AFFICHAGE ET CALCUL DU MONTANT
* A chaque fois qu'une valeur constituant le montant total est modifiée, le montant total est recalculé
* à partir de zéro dans le useEffect
*/

// ----------------------------------------------------------------------------------------- \\
// ----------------------------------- PURCHASE SUMMARY ------------------------------------ \\
// ----------------------------------------------------------------------------------------- \\

const PurchaseSummary = ({ subscriptionType, state, setState }) => {

    useEffect(() => {
        if (!state.loading) {
            let price = 0;
            if (subscriptionType === 'membership'
                && state.membership_type?.is_pro === 1
                && state.currentYearMembership?.membershipType?.is_pro === 0
                && state.currentYearMembership?.status === 'validated'
                && moment().format('MM-DD') < '11-01'
            ) {
                price -= state.currentYearMembership?.membershipType?.price;
            }
            if (state.subscription_type) {
                price += state.subscription_type.price;
            }
            if (state.membership_type) {
                price += state.membership_type.price;
            }
            if (state.is_demeter) {
                price -= state.membership_type.demeter_discount;
            }
            if (state.extraType) {
                price += state.extraType.price;
            }
            if (isValidAmount(state.contribution_amount) && Number(state.contribution_amount) > 0) {
                price += (Number(state.contribution_amount) * 100);
            }
            setState(prevState => ({ ...prevState, price }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.subscription_type, state.membership_type, state.is_demeter, state.extraType, state.contribution_amount]);

    return (
        <table style={{ width: '100%' }}>
            <tbody>
                {/* -------------------- MEMBERSHIP TYPE -------------------- */}
                {state.membership_type && (
                    <tr>
                        <td className="table-td">
                            {state.membership_type.name}
                        </td>
                        <td className="table-td">
                            {getAmountTTC(state.membership_type.price)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- SUBSCRIPTION TYPE -------------------- */}
                {state.subscription_type && (
                    <tr>
                        <td className="table-td">
                            {state.subscription_type.name}
                        </td>
                        <td className="table-td">
                            {getAmountTTC(state.subscription_type.price)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- EXTRA -------------------- */}
                {state.extraType && (
                    <tr>
                        <td className="table-td">
                            {state.extraType.name}
                        </td>
                        <td className="table-td">
                            {getAmountTTC(state.extraType.price)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- CONTRIBUTION AMOUNT -------------------- */}
                {(isValidAmount(state.contribution_amount) && Number(state.contribution_amount) > 0) && (
                    <tr>
                        <td className="table-td">
                            Cotisation de solidarité
                        </td>
                        <td className="table-td">
                            {getAmountTTC(state.contribution_amount * 100)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- DEMETER -------------------- */}
                {state.is_demeter && (
                    <tr>
                        <td className="table-td">
                            Remise Demeter
                        </td>
                        <td className="table-td">
                            -{getAmountTTC(state.membership_type.demeter_discount)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- MEMBERSHIP DISCOUNT -------------------- */}
                {(
                    subscriptionType === 'membership'
                    && state.membership_type?.is_pro === 1
                    && state.currentYearMembership?.membershipType?.is_pro === 0
                    && state.currentYearMembership?.status === 'validated'
                    && moment().format('MM-DD') < '11-01'
                ) && (
                    <tr>
                        <td className="table-td">
                            Remise adhésion particulier
                        </td>
                        <td className="table-td">
                            -{getAmountTTC(state.currentYearMembership?.membershipType?.price)} TTC
                        </td>
                    </tr>
                )}
                {/* -------------------- TOTAL -------------------- */}
                <tr style={{ fontWeight: 600 }}>
                    <td className="table-td">
                        PRIX TOTAL
                    </td>
                    <td className="table-td">
                        {getAmountTTC(state.price)} TTC
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default PurchaseSummary;
