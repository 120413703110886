import React, { useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

// Material-UI
import { Grid, Divider } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

// Views
import SubscriptionTypes from 'views/shop/subscription-types';
import MembershipTypes from 'views/shop/membership-types';
import CreateUnloggedSubscription from './create-unlogged-subscription';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------- SHOP ROUTER ------------------------------------ \\
// -------------------------------------------------------------------------------------- \\

const ShopRouter = () => {
    return (
        <Switch>
            <Route exact path="/shop">
                <Shop />
            </Route>
            <Route path="/shop/:subscriptionTypeId/:subscriptionType">
                <CreateUnloggedSubscription />
            </Route>
        </Switch>
    );
};

// -------------------------------------------------------------------------------------- \\
// ---------------------------------------- SHOP ---------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const Shop = () => {
    const [menu, setMenu] = useState(new URLSearchParams(useLocation().search).get('subscriptions') != null
        ? 'subscriptionTypes'
        : 'membershipTypes'
    );

    return (
        <>
            {/* -------------------- SWITCH -------------------- */}
            <Grid
                container
                style={{ padding: 24 }}
            >
                <ToggleButtonGroup
                    value={menu}
                    onChange={(e, value) => setMenu(value == null ? menu : value)}
                    exclusive
                    size="small"
                    className="toggle-button"
                    style={{ backgroundColor: '#e0e0e0' }}
                >
                    <ToggleButton
                        value="membershipTypes"
                        style={{ width: 120 }}
                    >
                        Adhésions
                    </ToggleButton>
                    <ToggleButton
                        value="subscriptionTypes"
                        style={{ width: 120 }}
                    >
                        Abonnements
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Divider />
            {/* -------------------- TYPE LIST -------------------- */}
            <Grid
                container
                style={{ flex: 1 }}
            >
                {menu === 'membershipTypes' && (
                    <MembershipTypes />
                )}
                {menu === 'subscriptionTypes' && (
                    <SubscriptionTypes />
                )}
            </Grid>
        </>
    );
};

export default ShopRouter;
