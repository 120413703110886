import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { getSession, updateSession, removeSession } from 'services/requests';
import { userCanDeleteFormationSession } from 'services/permissions';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import FormationSessionForm from './formation-session-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- FORMATION SESSION ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UpdateFormationSession = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();
    const sessionId = match.params.sessionId;

    const [state, setState] = useState({
        loading: true,
        error: false,
        session: undefined,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getSession(sessionId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, session: res.data.data.formationSession });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Formation #{state.session.number}
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanDeleteFormationSession(state.session.manager_id) && (
                        <Button onClick={() => setState({ ...state, openDelete: true })} variant="contained">
                            Supprimer
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- UPDATE FORMATION SESSION -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <SetFormationSession
                    refetch={() => setState({ ...state, loading: true })}
                    session={state.session}
                />
            </Grid>
            <Divider />
            {/* -------------------- DELETE FORMATION SESSION -------------------- */}
            {state.openDelete && (
                <RemoveFormationSession
                    onClose={() => setState({ ...state, openDelete: false })}
                    session={state.session}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- UPDATE FORMATION SESSION -------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SetFormationSession = ({ refetch, session }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        actionType: 'UPDATE',
        loading: false,
        name: session.name,
        end_of_registration: session.end_of_registration || null,
        capacity: session.capacity || '',
        status: session.status,
        cancel_reason: session.cancel_reason || '',
        manager: session.manager ? ({ label: session.manager.fullName, value: session.manager.id }) : null,
        animator: session.animator ? ({ label: session.animator.fullName, value: session.animator.id }) : null,
        trainers: session.trainers ? session.trainers.map(t => ({ label: t.fullName, value: t.id })) : [],
        french_departments: session.frenchDepartments ? session.frenchDepartments.map(fd => ({ label: (fd.name + ' (' + fd.number + ')'), value: fd.id })) : [],
        uploaded_photo: null,
        uploaded_file: null,
        uploaded_timesheet: null,
        uploaded_timesheet_vivea: null,
        vivea_reference: session.vivea_reference || '',
        map_information: session.map_information || '',
        document_url: session.document_url,
        remove_documentation: false,

        // User Categories
        user_categories: [],
        manager_category_id: 1,
        animator_category_id: 4,
        trainer_category_id: 2,
    });

    function onUpdate() {
        if (state.end_of_registration == null || state.capacity === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (state.status === 'cancelled' && state.cancel_reason === '') {
            enqueueSnackbar('Veuillez saisir la raison de l\'annulation de la formation', { variant: 'warning' });
        }
        else {
            const uploaded_photo = document.getElementById('upload-photo').files[0];
            const uploaded_file = document.getElementById('upload-file').files[0];
            const uploaded_timesheet = document.getElementById('upload-timesheet').files[0];
            const uploaded_timesheet_vivea = document.getElementById('upload-timesheet-vivea').files[0];
            setState({ ...state, loading: true, uploaded_photo, uploaded_file, uploaded_timesheet, uploaded_timesheet_vivea });
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('name', state.name);
            formData.append('end_of_registration', moment(state.end_of_registration).format('YYYY-MM-DD'));
            formData.append('capacity', state.capacity);
            formData.append('status', state.status);
            formData.append('manager_id', state.manager ? state.manager.value : '');
            formData.append('animator_id', state.animator ? state.animator.value : '');
            formData.append('vivea_reference', state.vivea_reference);
            formData.append('map_information', state.map_information);
            formData.append('cancel_reason', state.cancel_reason);
            formData.append('remove_documentation', state.remove_documentation ? '1' : '0');

            if (state.trainers && state.trainers.length > 0) {
                formData.append('trainer_ids', JSON.stringify(state.trainers.map(t => t.value)));
            }
            if (state.french_departments && state.french_departments.length > 0) {
                formData.append('french_department_ids', JSON.stringify(state.french_departments.map(fd => fd.value)));
            }

            if (state.uploaded_photo) {
                formData.append('uploaded_photo', state.uploaded_photo);
            }
            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }
            if (state.uploaded_timesheet) {
                formData.append('uploaded_timesheet', state.uploaded_timesheet);
            }
            if (state.uploaded_timesheet_vivea) {
                formData.append('uploaded_timesheet_vivea', state.uploaded_timesheet_vivea);
            }

            updateSession(session.id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Grid item xs={12} md={10} lg={8}>
                <FormationSessionForm
                    state={state}
                    setState={setState}
                />
            </Grid>
            {/* -------------------- SAVE BUTTON -------------------- */}
            <Grid item xs={12} md={2} lg={4} container alignItems="center" justify="flex-end">
                <Button onClick={onUpdate} variant="contained">
                    Enregistrer
                </Button>
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ REMOVE FORMATION SESSION --------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const RemoveFormationSession = ({ onClose, session }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { formationId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            removeSession(session.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    if (formationId) {
                        history.push('/management/formations/' + formationId);
                    }
                    else {
                        history.push('/management/formation-sessions');
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Formation #{session.number}
                        </p>
                        Êtes-vous sûr de vouloir supprimer cette formation ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateFormationSession;
