import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { updateSessionPrice, removeSessionPrice } from 'services/requests';
import { amountToSend, amountToBeReceived, isValidAmount } from 'services/helpers';

// Components
import Spinner from 'components/spinner';

// Views
import SessionPriceForm from './session-prices-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- UPDATE SESSION PRICE ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const UpdateSessionPrice = ({ onClose, refetch, session_price }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        price: amountToBeReceived(session_price.price),
        tva: amountToBeReceived(session_price.tva),
        status: session_price.status || -1,
        require_birth_date: session_price.require_birth_date ? session_price.require_birth_date.toString() : '0',
        require_company_name: session_price.require_company_name ? session_price.require_company_name.toString() : '0',
        description: session_price.description || '',
        openDelete: false,
    });

    function onUpdate() {
        if (state.price === '' || state.status === -1) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!isValidAmount(state.price)) {
            enqueueSnackbar('Veuillez saisir un montant valide', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateSessionPrice(session_price.id, {
                price: amountToSend(state.price),
                tva: amountToSend(state.tva),
                status: state.status,
                require_birth_date: state.require_birth_date,
                require_company_name: state.require_company_name,
                description: state.description,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <>
            <Dialog open fullWidth>
                {state.loading ? (
                    <Spinner />
                ) : (
                    <>
                        <DialogContent style={{ paddingBottom: 20 }}>
                            <p className="dialog-title">
                                Modifier un prix de la session
                            </p>
                            {/* -------------------- FORM -------------------- */}
                            <SessionPriceForm
                                state={state}
                                setState={setState}
                            />
                            {!session_price.isUsed && (
                                <>
                                    <Divider style={{ margin: '15px 0px' }} />
                                    {/* -------------------- DELETE -------------------- */}
                                    <Grid container alignItems="center" style={{ marginTop: 5 }}>
                                        <Grid item xs={12} sm={4}>
                                            Supprimer ce prix
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Button
                                                onClick={() => setState({ ...state, openDelete: true })}
                                                variant="contained" color="secondary"
                                            >
                                                Supprimer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </DialogContent>
                        <Divider />
                        <DialogActions style={{ justifyContent: 'space-between' }}>
                            <Button onClick={onClose} variant="contained">
                                Annuler
                            </Button>
                            <Button onClick={onUpdate} variant="contained">
                                Enregistrer
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            {/* -------------------- DELETE SESSION PRICE -------------------- */}
            {state.openDelete && (
                <DeleteSessionPrice
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => refetch()}
                    session_price={session_price}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- DELETE SESSION PRICE ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const DeleteSessionPrice = ({ onClose, refetch, session_price }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            removeSessionPrice(session_price.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Supprimer
                        </p>
                        Êtes-vous sûr de vouloir supprimer ce prix ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateSessionPrice;
