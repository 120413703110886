import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { createPayment } from 'services/requests';
import { amountToSend, isValidAmount } from 'services/helpers';

// Components
import Spinner from 'components/spinner';

// Views
import PaymentForm from './payments-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- CREATE PAYMENT --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const CreatePayment = ({ onClose, refetch, bill }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        openImport: false,

        // Data Payment
        payment_date: new Date(),
        amount: '',
        payment_method: -1,
        reference: '',
        status: -1,
        bills: bill ? [{ label: bill.reference, value: bill.id }] : [],
    });

    function onCreate() {
        if (state.bills.length === 0
            || state.payment_method === -1
            || state.status === -1
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!isValidAmount(state.amount)) {
            enqueueSnackbar('Veuillez saisir un montant valide', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createPayment({
                payment_date: state.payment_date ? moment(state.payment_date).format('YYYY-MM-DD') : null,
                amount: amountToSend(state.amount),
                payment_method: state.payment_method,
                reference: state.reference,
                status: state.status,
                bill_ids: state.bills.map(bill => bill.value),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un paiement
                        </p>
                        {/* -------------------- PAYMENT FORM -------------------- */}
                        <PaymentForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onCreate} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreatePayment;
