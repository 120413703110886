import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Services 
import { sendResetPasswordToken } from 'services/requests';

// Material-UI
import { Button, Grid, CircularProgress, Divider } from '@material-ui/core';
import { Person as AccountIcon } from '@material-ui/icons';

// Components
import InputText from 'components/input-text';

// -------------------------------------------------------------------------------------- \\
// ---------------------------- FORGOTTEN PASSWORD CONTAINER ---------------------------- \\
// -------------------------------------------------------------------------------------- \\

const ForgottenPasswordContainer = () => {
    return (
        <Grid
            container
            className="unauthenticated"
        >
            <Grid
                item xs={false} sm={1} md={2} lg={4}
            />
            <Grid
                item xs={12} sm={10} md={8} lg={4}
                container alignItems="center"
            >
                <ForgottenPassword />
            </Grid>
        </Grid>
    );
};

// -------------------------------------------------------------------------------------- \\
// ---------------------------------- FORGOTTEN PASSWORD -------------------------------- \\
// -------------------------------------------------------------------------------------- \\

export const ForgottenPassword = () => {
    const history = useHistory();
    const { search } = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        email: new URLSearchParams(search).get('email') || '',
    });

    useEffect(() => {
        if (state.loading) {
            sendResetPasswordToken(state.email).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/login?email=' + state.email);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(prevState => ({ ...prevState, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div
            className="unauthenticated-container"
            style={{ margin: '24px 12px' }}
        >
            <div style={{ padding: 24 }}>
                {/* ---------------------- TITLE ---------------------- */}
                <p
                    className="mabd-font"
                    style={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}
                >
                    MOT DE PASSE OUBLIÉ ?
                </p>
                {/* ---------------------- EMAIL ---------------------- */}
                <div className="input-title">
                    Email
                </div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <AccountIcon />
                        </span>
                    </div>
                    <InputText
                        label="Email"
                        type="email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        autoComplete="on"
                    />
                </div>
                {/* ---------------------- LOGIN ---------------------- */}
                <Grid
                    container justify="center"
                    style={{ marginTop: 24 }}
                >
                    <Button
                        onClick={() => setState(prevState => ({ ...prevState, loading: true }))}
                        disabled={state.loading}
                        endIcon={state.loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                        variant="contained" color="primary"
                    >
                        Réinitialiser le mot de passe
                    </Button>
                </Grid>
            </div>
            <Divider />
            <Grid
                container justify="flex-end"
                style={{ padding: 12 }}
            >
                <Button
                    onClick={() => history.push('/login?email=' + state.email)}
                    disabled={state.loading}
                    style={{ textTransform: 'none' }}
                >
                    Se connecter
                </Button>
            </Grid>
        </div>
    );
};

export default ForgottenPasswordContainer;
