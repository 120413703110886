import React from 'react';

// MUI
import { Dialog, DialogActions, DialogContent, Divider, Button, Grid, Checkbox } from '@material-ui/core';

/* * *
* Modale de confirmation de modification d'adresse
*/

// ----------------------------------------------------------------------------------------------------- \\
// ------------------------------------- CONFIRM UPDATE ADDRESS ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------------- \\

const ConfirmUpdateAddress = ({ state, setState, onSubmit }) => {
    return (
        <Dialog
            open
            fullWidth
        >
            <DialogContent style={{ paddingBottom: 8 }}>
                <p className="dialog-title">
                    Modification d'adresse
                </p>
                {/* -------------------- BILL ADDRESS -------------------- */}
                {state.update_bill_address != null && (
                    <Grid
                        container alignItems="center"
                        style={{ padding: 12 }}
                    >
                        <Grid item xs="auto">
                            <Checkbox
                                checked={state.update_bill_address}
                                onChange={e => setState(prevState => ({ ...prevState, update_bill_address: e.target.checked }))}
                            />
                        </Grid>
                        <Grid item xs>
                            Je souhaite que les modifications soient aussi faites pour mon adresse de facturation
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- SUBSCRIPTION ADDRESS -------------------- */}
                {state.update_subscriptions_address != null && (
                    <Grid
                        container alignItems="center"
                        style={{ padding: 12 }}
                    >
                        <Grid item xs="auto">
                            <Checkbox
                                checked={state.update_subscriptions_address}
                                onChange={e => setState(prevState => ({ ...prevState, update_subscriptions_address: e.target.checked }))}
                            />
                        </Grid>
                        <Grid item xs>
                            Je souhaite que les modifications soient aussi faites pour mon(mes) abonnement(s)
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- USER ADDRESS -------------------- */}
                {state.update_user_address != null && (
                    <Grid
                        container alignItems="center"
                        style={{ padding: 12 }}
                    >
                        <Grid item xs="auto">
                            <Checkbox
                                checked={state.update_user_address}
                                onChange={e => setState(prevState => ({ ...prevState, update_user_address: e.target.checked }))}
                            />
                        </Grid>
                        <Grid item xs>
                            Je souhaite que les modifications soient aussi faites pour l'adresse de mon compte
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onSubmit}
                    variant="contained" color="inherit"
                >
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmUpdateAddress;
