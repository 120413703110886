import React, { useState, useEffect } from 'react';

// Services
import { getActivities } from 'services/requests';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT ACTIVITIES ------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectActivities = ({ activities, setActivities, label, isPro }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getActivities({ is_pro: isPro }).then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.activities.map(a => ({ value: a.id, label: a.name })));
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SelectMultiple
            value={activities}
            onChange={value => setActivities(value)}
            options={options}
            placeholder={label}
        />
    );
};

// Propriétés par défaut
SelectActivities.defaultProps = {
    label: 'Activité',
    isPro: null,
};

export default SelectActivities;
