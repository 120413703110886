import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getUser } from 'services/requests';
import { getGeocode } from 'services/helpers';
import { isAuthenticated, setStoredSubscription } from 'services/storage';

// Components
import Spinner from 'components/spinner';

// Views
import FormationDetails from 'views/common/formation-details'; // Détails et contenu de la formation
import FormationRequestPrices from './formation-request-prices'; // Choissisez votre formule
import FormationRequestForm from './formation-request-form'; // Formulaire Prénom - Commentaire
import Memberships from './memberships'; // Choix des adhésions
import ContributionAmount from 'views/common/contribution-amount'; // Cotisation de solidarité
import PurchaseConditions from 'views/common/purchase-conditions'; // CGV et Politique de confidentialité
import PurchaseSummary from './purchase-summary'; // Récapitulatif de l'achat
import FormationRequestSubmit from './formation-request-submit'; // Envoi du formulaire

/* * *
* Formulaire d'inscription à une formation
*/

// -------------------------------------------------------------------------------------- \\
// -------------------------------- CREATE FORMATION REQUEST ---------------------------- \\
// -------------------------------------------------------------------------------------- \\

const CreateFormationRequest = ({ formationSession }) => {
    const history = useHistory();
    const { formationSessionId, userId } = useParams();

    const [state, setState] = useState({
        loadingUser: true,
        formationSession: formationSession,
        user: null,

        // Price
        price: 0,

        // Fomation Session
        session_price: null,
        options: [],

        // Formation Request
        message: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        birth_date: null,
        company_name: '',
        address: '',
        address_details: '',
        city: '',
        postal_code: '',
        country_id: 1,
        geocode: null,
        membership: null,
        membership_type: null,
        contribution_amount: '',

        // Memberships
        errorOnMembership: false,
        hasValidMembership: false,
        toRenew: null,
        paymentWaiting: null,
        membershipTypes: [],

        // Purchase Conditions
        cgv: false,
        pdc: false,
    });

    const onNavigateToPurchaseRegistration = (menu) => {
        setStoredSubscription({ formationSessionId });
        history.push('/purchase-registration?menu=' + menu);
    };

    useEffect(() => {
        if (state.loadingUser && userId) {
            getUser(userId).then(res => {
                if (res.status === 200) {
                    const user = res.data.data.user;
                    setState({
                        ...state,
                        loadingUser: false,
                        loading: true,
                        user,
                        first_name: user.first_name || '',
                        last_name: user.last_name || '',
                        company_name: user.company_name || '',
                        address: user.address || '',
                        address_details: user.address_details || '',
                        postal_code: user.postal_code || '',
                        city: user.city || '',
                        country_id: user.country_id || 1,
                        geocode: getGeocode(user),
                        email: user.email || '',
                        phone: user.phone || '',
                        birth_date: user.birth_date || null,
                    });
                }
                else {
                    setState({ ...state, loadingUser: false });
                }
            });
        }
        if (state.loadingUser && !userId) {
            setState({ ...state, loadingUser: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingUser]);

    if (state.loadingUser) return <Spinner />;

    return (
        <>
            {/* -------------------- DETAILS -------------------- */}
            <FormationDetails
                formationSession={formationSession}
            />
            {(
                isAuthenticated()
                && formationSession.status !== 'closed'
            ) && (
                <>
                    {/* -------------------- PRICES & OPTIONS -------------------- */}
                    <FormationRequestPrices
                        state={state}
                        setState={setState}
                    />
                    <Grid
                        container
                        style={{ padding: 24 }}
                    >
                        <Grid
                            item xs={false} sm={1} md={3}
                        />
                        <Grid item xs={12} sm={10} md={6}>
                            {/* -------------------- FORM -------------------- */}
                            <FormationRequestForm
                                state={state}
                                setState={setState}
                            />
                            {/* -------------------- MEMBERSHIPS -------------------- */}
                            <Memberships
                                state={state}
                                setState={setState}
                            />
                            {/* -------------------- CONTRIBUTION AMOUNT -------------------- */}
                            {(state.membership || state.membership_type) && (
                                <>
                                    <ContributionAmount
                                        state={state}
                                        setState={setState}
                                    />
                                    <Divider/>
                                </>
                            )}
                            {/* -------------------- PURCHASE CONDITIONS -------------------- */}
                            <div style={{ padding: '24px 0px' }}>
                                <PurchaseConditions
                                    state={state}
                                    setState={setState}
                                />
                            </div>
                            {/* -------------------- PURCHASE SUMMARY -------------------- */}
                            <PurchaseSummary
                                state={state}
                                setState={setState}
                            />
                            {/* -------------------- IS FULL -------------------- */}
                            {state.formationSession.isFull && (
                                <>
                                    <div style={{ padding: 24, fontWeight: 600, fontSize: 16 }}>
                                        Le nombre maximum de participants pour cette formation a été atteint.<br />
                                        Par conséquent, vous serez en liste d'attente.
                                    </div>
                                    <Divider />
                                </>
                            )}
                            {/* -------------------- SUBMIT -------------------- */}
                            <FormationRequestSubmit
                                state={state}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            {/* -------------------- UNAUTHENTICATED -------------------- */}
            {(
                !isAuthenticated()
                && formationSession.status !== 'closed'
            ) && (
                <>
                    <Divider />
                    <div style={{ padding: '48px 24px', textAlign: 'center' }}>
                        <span style={{ fontSize: 18 }}>
                            Je souhaite m'inscrire à cette formation :
                        </span>
                        <Grid
                            container spacing={1}
                            alignItems="center" justify="center"
                            style={{ marginTop: 12 }}
                        >
                            <Grid item xs="auto">
                                <Button
                                    onClick={() => onNavigateToPurchaseRegistration('login')}
                                    variant="contained"
                                >
                                    J'ai déjà un compte : Se connecter
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Button
                                    onClick={() => onNavigateToPurchaseRegistration('register')}
                                    variant="contained"
                                >
                                    Je n'ai pas de compte : Créer un compte
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </>
            )}
            {/* -------------------- FORMATION SESSION CLOSED -------------------- */}
            {formationSession.status === 'closed' && (
                <>
                    <Divider />
                    <div
                        style={{
                            padding: '48px 24px',
                            textAlign: 'center',
                            fontWeight: 600,
                            fontSize: 16,
                            fontStyle: 'italic',
                        }}
                    >
                        Vous ne pouvez plus vous inscrire à cette formation.
                    </div>
                </>
            )}
        </>
    );
};

export default CreateFormationRequest;
