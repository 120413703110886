import React, { useState, useEffect } from 'react';

// Services
import { magazinePeriods } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------- SELECT MAGAZINE PERIODS ---------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectMagazinePeriods = ({ periods, setPeriods }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(magazinePeriods.map(p => ({ label: p.label, value: p.value })));
    }, []);

    return (
        <SelectMultiple
            value={periods}
            onChange={value => setPeriods(value)}
            options={options}
            placeholder="Période"
        />
    );
};

export default SelectMagazinePeriods;
