import React from 'react';
import SelectCreatable from 'components/select-creatable';

// Column Width
let columnWidth = 140;

// -------------------- HEADER -------------------- \\
const CityHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Ville
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const CityFilter = ({ state, onSelectSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <SelectCreatable
                value={state.cities}
                onChange={cities => onSelectSearch('cities', cities)}
                placeholder="Ville"
                minWidth={(columnWidth - 20)}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const CityValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { CityHeader, CityFilter, CityValue };
