import React from 'react';

// Material-UI
import { Grid, RadioGroup, Radio, Typography, FormControlLabel, MenuItem } from '@material-ui/core';

// Services
import { sessionPriceStatuses } from 'services/constants';

// Components
import InputText from 'components/input-text';
import SelectOutlined from 'components/select-outlined';
import FieldRequired from 'components/field-required';

// -------------------------------------------------------------------------------------- \\
// ---------------------------------- SESSION PRICE FORM -------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const SessionPriceForm = ({ state, setState }) => {

    const onSelectStatus = (value) => {
        if (value === 'training_fund') {
            setState(prevState => ({ ...prevState, status: value, require_company_name: '1' }));
        }
        else {
            setState(prevState => ({ ...prevState, status: value, require_company_name: '0' }));
        }
    };

    return (
        <>
            {/* -------------------- PRICE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={6}>
                    Prix (€)<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputText
                        label="Prix (€)"
                        type="number"
                        value={state.price}
                        onChange={e => setState({ ...state, price: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- TVA -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 6 }}>
                <Grid item xs={12} sm={6}>
                    TVA (%)
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputText
                        label="TVA (%)"
                        type="number"
                        value={state.tva}
                        onChange={e => setState({ ...state, tva: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- STATUS -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 6 }}>
                <Grid item xs={12} sm={6}>
                    Statut<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SelectOutlined
                        value={state.status}
                        onChange={e => onSelectStatus(e.target.value)}
                    >
                        <MenuItem value={-1}>
                            <em>Statut</em>
                        </MenuItem>
                        {sessionPriceStatuses.map(status => (
                            <MenuItem key={status.value} value={status.value}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- REQUIRE BIRTH DATE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 6 }}>
                <Grid item xs={12} sm={6}>
                    Date de naissance requise :
                </Grid>
                <Grid item xs={12} sm={6}>
                    <RadioGroup
                        value={state.require_birth_date}
                        onChange={e => setState({ ...state, require_birth_date: e.target.value })}
                        style={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label={<Typography style={{ fontSize: 12 }}>Non</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={<Typography style={{ fontSize: 12 }}>Oui</Typography>}
                            style={{ margin: '0px 0px 0px 15px' }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            {/* -------------------- REQUIRE COMPANY NAME -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 6 }}>
                <Grid item xs={12} sm={6}>
                    Nom de l'entreprise requis :<br />
                    (employeur)
                </Grid>
                <Grid item xs={12} sm={6}>
                    <RadioGroup
                        value={state.require_company_name}
                        onChange={e => setState({ ...state, require_company_name: e.target.value })}
                        style={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label={<Typography style={{ fontSize: 12 }}>Non</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={<Typography style={{ fontSize: 12 }}>Oui</Typography>}
                            style={{ margin: '0px 0px 0px 15px' }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            {/* -------------------- DESCRIPTION -------------------- */}
            <Grid container style={{ marginTop: 14 }}>
                <InputText
                    label="Description..."
                    multiline
                    value={state.description}
                    onChange={e => setState({ ...state, description: e.target.value })}
                />
            </Grid>
        </>
    );
};

export default SessionPriceForm;
