import React, { useState, useEffect, Fragment } from 'react';

// Material-UI
import { Grid, Divider } from '@material-ui/core';

// Services
import { getSubscriptionTypes } from 'services/requests';
import { getAmountTTC } from 'services/helpers';

// Assets
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import UpdateSubscriptionType from './update-subscription-type';

// -------------------------------------------------------------------------------------------- \\
// --------------------------------- FETCH SUBSCRIPTION TYPES --------------------------------- \\
// -------------------------------------------------------------------------------------------- \\

const FetchSubscriptionTypes = () => {

    const [state, setState] = useState({
        loading: true,
        error: false,
        subscription_types: [],
        subscription_type: null,
        openUpdate: false,
    });

    useEffect(() => {
        if (state.loading) {
            getSubscriptionTypes().then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, subscription_types: res.data.data.subscriptionTypes });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                <Grid
                    container alignItems="center"
                    className="list-headers"
                    style={{ marginTop: 24 }}
                >
                    <Grid
                        item xs={10}
                        style={{ padding: 10 }}
                    >
                        Abonnement
                    </Grid>
                    <Grid
                        item xs={2}
                        container justify="center"
                        style={{ padding: 10 }}
                    >
                        Prix TTC
                    </Grid>
                </Grid>
                {/* -------------------- LIST -------------------- */}
                <div
                    className="main-container-item"
                    style={{ paddingBottom: 24 }}
                >
                    {state.subscription_types.length > 0 ? (
                        state.subscription_types.map(subscription_type => (
                            <Fragment key={subscription_type.id}>
                                <Grid
                                    container
                                    alignItems="center"
                                    className="simple-list"
                                    onClick={() => setState({ ...state, openUpdate: true, subscription_type })}
                                >
                                    <Grid
                                        item xs={10}
                                        style={{ padding: 10 }}
                                    >
                                        {subscription_type.name}
                                    </Grid>
                                    <Grid
                                        item xs={2}
                                        container justify="center"
                                        style={{ padding: 10 }}
                                    >
                                        {getAmountTTC(subscription_type.price)}
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <div style={{ padding: 10 }}>
                                Aucun
                            </div>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
            {/* -------------------- UPDATE -------------------- */}
            {state.openUpdate && (
                <UpdateSubscriptionType
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    subscription_type={state.subscription_type}
                />
            )}
        </>
    );
};

export default FetchSubscriptionTypes;
