import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { addSession } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// Views
import FormationSessionForm from './formation-session-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------- ADD FORMATION SESSION ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const AddFormationSession = ({ onClose, refetch, formation, isSession }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        actionType: 'CREATE',
        loading: false,
        name: '',
        end_of_registration: null,
        capacity: '',
        status: 'draft',
        manager: null,
        animator: null,
        trainers: [],
        french_departments: [],
        uploaded_photo: null,
        uploaded_file: null,
        uploaded_timesheet: null,
        uploaded_timesheet_vivea: null,
        vivea_reference: '',
        map_information: '',

        // User Categories
        user_categories: [],
        manager_category_id: 1,
        animator_category_id: 4,
        trainer_category_id: 2,

        // Formation
        isSession: isSession || false,
        formation: formation || null,
    });

    function onAdd() {
        if (state.formation == null) {
            enqueueSnackbar('Veuillez choisir un modèle de formation', { variant: 'warning' });
        }
        else if (state.end_of_registration == null || state.capacity === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const uploaded_photo = document.getElementById('upload-photo').files[0];
            const uploaded_file = document.getElementById('upload-file').files[0];
            const uploaded_timesheet = document.getElementById('upload-timesheet').files[0];
            const uploaded_timesheet_vivea = document.getElementById('upload-timesheet-vivea').files[0];
            setState({ ...state, loading: true, uploaded_photo, uploaded_file, uploaded_timesheet, uploaded_timesheet_vivea });
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('name', state.name);
            formData.append('end_of_registration', moment(state.end_of_registration).format('YYYY-MM-DD'));
            formData.append('capacity', state.capacity);
            formData.append('status', state.status);
            formData.append('manager_id', state.manager ? state.manager.value : '');
            formData.append('animator_id', state.animator ? state.animator.value : '');
            formData.append('vivea_reference', state.vivea_reference);
            formData.append('map_information', state.map_information);

            if (state.trainers && state.trainers.length > 0) {
                formData.append('trainer_ids', JSON.stringify(state.trainers.map(t => t.value)));
            }
            if (state.french_departments && state.french_departments.length > 0) {
                formData.append('french_department_ids', JSON.stringify(state.french_departments.map(fd => fd.value)));
            }

            if (state.uploaded_photo) {
                formData.append('uploaded_photo', state.uploaded_photo);
            }
            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }
            if (state.uploaded_timesheet) {
                formData.append('uploaded_timesheet', state.uploaded_timesheet);
            }
            if (state.uploaded_timesheet_vivea) {
                formData.append('uploaded_timesheet_vivea', state.uploaded_timesheet_vivea);
            }

            addSession((isSession ? state.formation.value : formation.id), formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter une formation
                        </p>
                        <FormationSessionForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default AddFormationSession;
