import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, CircularProgress } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getGroup } from 'services/requests';
import { getFormattedDate, getLabelStatus } from 'services/helpers';
import { membershipStatuses } from 'services/constants';

// Components
import Tooltip from 'components/tooltip';

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- EXCEL EXPORT ------------------------------------------ \\
// --------------------------------------------------------------------------------------------------- \\

const ExcelExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    function getStringFromArray(array) {
        let result = '';
        array.forEach((item, index) => {
            if (index === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });
        return result;
    }

    function createExcelFile(users) {
        if (users.length === 0) {
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Utilisateurs');

            // Header
            worksheet.columns = [
                { header: 'Statut', key: 'status', width: 30 },
                { header: 'Code client', key: 'client_code', width: 30 },
                { header: 'Code client externe', key: 'external_client_code', width: 30 },
                { header: 'Nom', key: 'last_name', width: 30 },
                { header: 'Prénom', key: 'first_name', width: 30 },
                { header: 'Nom de l\'entreprise', key: 'company_name', width: 30 },
                { header: 'Téléphone portable', key: 'mobile_phone', width: 30 },
                { header: 'Email', key: 'email', width: 30 },
                { header: 'Département', key: 'department', width: 30 },
                { header: 'Type de compte', key: 'role', width: 30 },
                { header: 'Association fédérée', key: 'federated_association', width: 30 },
                { header: 'Téléphone', key: 'phone', width: 30 },
                { header: 'Autre téléphone', key: 'other_phone', width: 30 },
                { header: 'Pays', key: 'country', width: 30 },
                { header: 'Rôle', key: 'user_category', width: 30 },
                { header: 'Date de naissance', key: 'birth_date', width: 30 },
                { header: 'Code postal', key: 'postal_code', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            users.map(user => {
                return worksheet.addRow({
                    status: getLabelStatus(membershipStatuses, user.membershipStatus),
                    client_code: user.clientCode || '',
                    external_client_code: user.external_client_code || '',
                    last_name: user.last_name || '',
                    first_name: user.first_name || '',
                    company_name: user.company_name || '',
                    mobile_phone: user.mobile_phone || '',
                    email: user.email || '',
                    department: user.department ? (user.department.name + ' (' + user.department.number + ')') : '',
                    role: user.role?.display_name,
                    federated_association: user.federatedAssociation ? user.federatedAssociation.name : '',
                    phone: user.phone || '',
                    other_phone: user.other_phone || '',
                    country: user.country ? user.country.name : '',
                    user_category: user.userCategories ? getStringFromArray(user.userCategories) : '',
                    birth_date: user.birth_date ? getFormattedDate(user.birth_date) : '',
                    postal_code: user.postal_code || '',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'utilisateurs.xlsx'));
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
            setLoading(false);
        }
    }

    // Start Export
    useEffect(() => {
        if (loading) {
            getGroup(data?.group?.id).then(res => {
                if (res.status === 200) {
                    createExcelFile(res.data.data.userList.users);
                }
                else {
                    console.log(res);
                    enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setLoading(true)}
                    disabled={loading || data?.group == null}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {loading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default ExcelExport;
