import React, { useEffect } from 'react';

// Moment
import moment from 'moment';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, ListItem, Radio } from '@material-ui/core';

// Services
import { getMembershipTypes, checkMemberships } from 'services/requests';
import { getAmountTTC } from 'services/helpers';

// -------------------------------------------------------------------------------------- \\
// ----------------------------------- MEMBERSHIPS -------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const Memberships = ({ state, setState }) => {
    const previousYear = moment().subtract(1, 'y').year().toString();
    const currentYear = moment().year().toString();
    const nextYear = moment().add(1, 'y').year().toString();

    useEffect(() => {
        if (state.user) {
            axios.all([
                checkMemberships(state.user?.id, state.formationSession?.begin),
                getMembershipTypes({ only_available: 1 }),
            ])
            .then(axios.spread(function (res_check_memberships, res_membership_types) {
                let membershipTypes = res_membership_types?.data?.data?.membershipTypes;
                if (
                    res_check_memberships?.status === 200
                    && res_membership_types?.status === 200
                    && membershipTypes
                ) {
                    setState({
                        ...state,
                        hasValidMembership: res_check_memberships.data.data.hasValidMembership,
                        toRenew: res_check_memberships.data.data.toRenew,
                        paymentWaiting: res_check_memberships.data.data.paymentWaiting,
                        membershipTypes: membershipTypes,
                    });
                }
                else {
                    setState({ ...state, errorOnMembership: true });
                }
            }));
        }
        else {
            getMembershipTypes().then(res => {
                let membershipTypes = res?.data?.data?.membershipTypes;
                if (res?.status === 200 && membershipTypes) {
                    setState({ ...state, membershipTypes });
                }
                else {
                    setState({ ...state, errorOnMembership: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {(
                state.hasValidMembership === false
                && state.errorOnMembership === false
            ) && (
                <div
                    className="light-background-color"
                    style={{ padding: 24, marginTop: 24 }}
                >
                    {/* * *
                    * PAYMENT WAITING
                    */}
                    {(
                        state.toRenew == null
                        && state.paymentWaiting != null
                    ) && (
                        <>
                            <p>
                                Vous possédez actuellement une Adhésion particulier (OU PRO) en attente de paiement pour {state.paymentWaiting?.end_date?.substring(0, 4)}.<br />
                                Le paiement n’étant pas reçu à ce jour, cette adhésion ne peut pas être validée.<br />
                                Par conséquent, votre inscription à la formation n’est pas possible.
                            </p>
                            <span>
                                Vous avez les options suivantes :
                            </span>
                            <ul>
                                <li>
                                    Changer le mode de paiement sur le bon de commande de votre adhésion pour payer par carte bancaire,&nbsp;
                                    le paiement sera reçu immédiatement, ainsi votre adhésion deviendra active immédiatement.
                                    Cette opération rendra votre inscription à la formation possible.
                                </li>
                                <li>
                                    Attendre que votre paiement d’adhésion soit effectivement reçu et traité.
                                    Si le paiement a été réalisé par chèque ou virement, son traitement nécessite plusieurs jours.
                                    Rappel : si la référence du bon de commande n’est pas renseignée dans le message du virement ou au dos du chèque, il est possible que votre paiement ne soit pas retrouvé.
                                </li>
                                <li>
                                    Contacter le responsable de la formation par courriel ou téléphone pour vous aider à finaliser votre inscription.
                                </li>
                            </ul>
                        </>
                    )}
                    {/* * *
                    * FORMATION CURRENT OR PREVIOUS YEAR / NON ADHERENT
                    */}
                    {(
                        state.paymentWaiting == null
                        && state.toRenew == null
                        && [currentYear, previousYear].includes(state.formationSession?.begin?.substring(0, 4))
                    ) && (
                        <>
                            <p>
                                Vous devez être adhérent au MABD pour vous inscrire à une formation.<br />
                                Nous vous proposons donc de prendre une adhésion dès à présent.<br />
                                Si l'adhésion est prise à partir du 1er Novembre, elle durera jusqu'au 31 Décembre de l'année suivante.
                            </p>
                            <SelectMembership
                                year={currentYear}
                                state={state}
                                setState={setState}
                            />
                        </>
                    )}
                    {/* * *
                    * FORMATION CURRENT YEAR / ADHERENT PREVIOUS YEAR EXPIRED
                    */}
                    {(
                        state.formationSession?.begin?.substring(0, 4) === currentYear
                        && state.paymentWaiting == null
                        && (
                            state.toRenew != null
                            && state.toRenew.is_pro === 0
                            && state.toRenew.status === 'expired'
                            && state.toRenew.end_date?.substring(0, 4) === previousYear
                        )
                    ) && (
                        <>
                            <p>
                                Vous possédiez une {state.toRenew.membershipType?.name} pour {previousYear} qui est expirée.<br />
                                Vous pouvez renouveler votre adhésion pour {currentYear} en allant sur votre tableau de bord et en effectuant le règlement.<br />
                                Pour vous inscrire à la formation, soit vous faites le renouvellement décrit avant votre inscription, soit vous devez choisir une nouvelle formule pour valider l’inscription.
                            </p>
                            <SelectMembership
                                year={currentYear}
                                state={state}
                                setState={setState}
                            />
                        </>
                    )}
                    {/* * *
                    * FORMATION CURRENT YEAR / PRO PREVIOUS YEAR EXPIRED
                    */}
                    {(
                        state.formationSession?.begin?.substring(0, 4) === currentYear
                        && state.paymentWaiting == null
                        && (
                            state.toRenew != null
                            && state.toRenew.is_pro === 1
                            && state.toRenew.status === 'expired'
                            && state.toRenew.end_date?.substring(0, 4) === previousYear
                        )
                    ) && (
                        <>
                            <p>
                                Vous possédiez une adhésion professionnelle pour {previousYear} qui est expirée.<br />
                                Vous avez certainement reçu ou vous allez recevoir par courrier la facture que nous avons générée en avance comme chaque année pour l’adhésion professionnelle {currentYear}.
                                Vous pouvez renouveler votre adhésion pour {currentYear} en allant sur votre tableau de bord et en effectuant le règlement.<br />
                                Pour vous inscrire à la formation, soit vous faites le renouvellement décrit avant votre inscription, soit vous devez choisir une nouvelle formule pour valider l’inscription.
                            </p>
                            <SelectMembership
                                year={currentYear}
                                state={state}
                                setState={setState}
                            />
                        </>
                    )}
                    {/* * *
                    * FORMATION NEXT YEAR / NON ADHERENT
                    */}
                    {(
                        state.formationSession?.begin?.substring(0, 4) === nextYear
                        && state.toRenew == null
                        && state.paymentWaiting == null
                    ) && (
                        <>
                            <p>
                                Vous devez être adhérent au MABD pour vous inscrire à une formation.<br />
                                La formation à laquelle vous souhaitez vous inscrire se déroulera l'année prochaine, et nécessite une adhésion valide pour {nextYear}.<br />
                                Nous vous proposons donc de prendre une adhésion pour {nextYear} dès à présent.
                            </p>
                            <SelectMembership
                                year={nextYear}
                                state={state}
                                setState={setState}
                            />
                        </>
                    )}
                    {/* * *
                    * FORMATION NEXT YEAR / ADHERENT
                    */}
                    {(
                        state.formationSession?.begin?.substring(0, 4) === nextYear
                        && state.paymentWaiting == null
                        && (
                            state.toRenew != null
                            && state.toRenew.is_pro === 0
                        )
                    ) && (
                        <>
                            <p>
                                Vous êtes actuellement adhérent {state.toRenew?.membershipType?.is_pro === 1 ? 'Professionnel' : 'Particulier'} pour l'année en cours ({currentYear}).<br />
                                La formation à laquelle vous souhaitez vous inscrire se déroulera l'année prochaine, et nécessite une adhésion valide pour {nextYear}.<br />
                                Nous vous proposons donc de renouveler votre adhésion pour {nextYear} dès à présent, ou de choisir une nouvelle formule.
                            </p>
                            <SelectMembership
                                year={nextYear}
                                state={state}
                                setState={setState}
                            />
                        </>
                    )}
                    {/* * *
                    * FORMATION NEXT YEAR / PRO CURRENT YEAR EXPIRED OR VALIDATED
                    */}
                    {(
                        state.formationSession?.begin?.substring(0, 4) === nextYear
                        && state.paymentWaiting == null
                        && (
                            state.toRenew != null
                            && state.toRenew.is_pro === 1
                            && (state.toRenew.status === 'expired' || state.toRenew.status === 'validated')
                            && state.toRenew.end_date?.substring(0, 4) === currentYear
                        )
                    ) && (
                        <>
                            <p>
                                Vous possédez actuellement une Adhésion professionnelle active ou bien expirée pour {currentYear}.<br />
                                La formation à laquelle vous souhaitez vous inscrire se déroulera l’année prochaine, et nécessite une adhésion valide pour {nextYear}.<br />
                                Pour vous inscrire à la formation, vous devez renouveler votre adhésion ou choisir une nouvelle formule pour valider l’inscription.
                            </p>
                            <SelectMembership
                                year={nextYear}
                                state={state}
                                setState={setState}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

// -------------------------------------------------------------------------------------- \\
// -------------------------------- SELECT MEMBERSHIP ----------------------------------- \\
// -------------------------------------------------------------------------------------- \\

export const SelectMembership = ({ year, state, setState }) => {

    const listItemProps = {
        padding: 12,
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: 'white',
    };

    return (
        <>
            {/* -------------------- TO RENEW -------------------- */}
            {state.toRenew != null && (
                <>
                    <div
                        className="input-title"
                        style={{ marginBottom: 4 }}
                    >
                        Renouveler mon adhésion
                    </div>
                    <ListItem
                        button
                        onClick={() => setState({ ...state, membership_type: null, membership: state.toRenew })}
                        style={listItemProps}
                    >
                        <Grid container alignItems="center">
                            <Radio
                                color="secondary"
                                checked={state.toRenew.id === state.membership?.id}
                                style={{ padding: 6 }}
                            />
                            <Grid
                                item xs
                                style={{ fontSize: 16 }}
                            >
                                {state.toRenew.membershipType?.name} {year} - <b>{getAmountTTC(state.toRenew.membershipType?.price)} TTC</b>
                            </Grid>
                        </Grid>
                        {state.toRenew.membershipType?.definition && (
                            <div
                                className="text"
                                style={{ marginTop: 6 }}
                            >
                                {state.toRenew.membershipType.definition}
                            </div>
                        )}
                    </ListItem>
                    <div
                        className="input-title"
                        style={{ marginBottom: 4, marginTop: 24 }}
                    >
                        Choisir une nouvelle formule
                    </div>
                </>
            )}
            {/* -------------------- NEW MEMBERSHIP -------------------- */}
            {(state.membershipTypes && state.membershipTypes.length > 0) && (
                state.membershipTypes.map((membershipType, index) => (
                    <ListItem
                        key={membershipType.id}
                        button
                        onClick={() => setState({ ...state, membership_type: membershipType, membership: null })}
                        style={{ ...listItemProps, marginTop: index > 0 ? 2 : undefined }}
                    >
                        <Grid container alignItems="center">
                            <Radio
                                color="secondary"
                                checked={membershipType.id === state.membership_type?.id}
                                style={{ padding: 6 }}
                            />
                            <Grid
                                item xs
                                style={{ fontSize: 16 }}
                            >
                                {membershipType.name} {year} - <b>{getAmountTTC(membershipType.price)} TTC</b>
                            </Grid>
                        </Grid>
                        {membershipType.definition && (
                            <div
                                className="text"
                                style={{ marginTop: 6 }}
                            >
                                {membershipType.definition}
                            </div>
                        )}
                    </ListItem>
                ))
            )}
        </>
    );
};

export default Memberships;
