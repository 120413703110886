import React from 'react';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getFormattedDate } from 'services/helpers';

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------------- INFORMATIONS ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Informations = ({ state }) => {
    const history = useHistory();

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={4} />
                <Grid item xs={4} container justify="center">
                    <b>VOS INFORMATIONS</b>
                </Grid>
                <Grid item xs={4} container justify="flex-end">
                    <Button
                        onClick={() => history.push('/profile')}
                        color="primary"
                        style={{ textTransform: 'none', padding: 0 }}
                    >
                        Modifier
                    </Button>
                </Grid>
            </Grid>
            <Divider style={{ margin: '10px 0px' }} />
            <Grid container>
                <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                    {/* -------------------- ROLE -------------------- */}
                    {state.user?.role && (
                        <p>
                            <b>Type de compte</b>
                            <br />
                            {state.user.role?.display_name}
                        </p>
                    )}
                    {/* -------------------- EMAIL -------------------- */}
                    {state.user?.email && (
                        <p>
                            <b>Email</b>
                            <br />
                            {state.user.email}
                        </p>
                    )}
                    {/* -------------------- BIRTHDATE -------------------- */}
                    {state.user?.birth_date && (
                        <p style={{ marginBottom: 0 }}>
                            <b>Date de naissance</b>
                            <br />
                            {getFormattedDate(state.user.birth_date)}
                        </p>
                    )}
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                    {/* -------------------- COUNTRY -------------------- */}
                    {state.user?.country && (
                        <p>
                            <b>Pays</b>
                            <br />
                            {state.user.country?.name}
                        </p>
                    )}
                    {/* -------------------- DEPARTMENT -------------------- */}
                    {state.user?.department && (
                        <p>
                            <b>Département</b>
                            <br />
                            {state.user.department?.name} ({state.user.department?.number})
                        </p>
                    )}
                    {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                    {state.user?.federatedAssociation && (
                        <p style={{ marginBottom: 0 }}>
                            <b>Association fédérée</b>
                            <br />
                            {state.user.federatedAssociation?.name}
                        </p>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default Informations;
