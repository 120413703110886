import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Divider } from '@material-ui/core';

// Services
import { getUser, getMembershipType, getSubscriptionTypes } from 'services/requests';
import { isAuthenticated } from 'services/storage';
import { getGeocode } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import SubscriptionDetails from './subscription-details'; // Image + Détails de l'adhésion ou de l'abonnement
import ValidityYear from './validity-year'; // Année de validité de l'adhésion
import SubscriptionForm from './subscription-form'; // Formulaire Prénom - Activités secondaires (ou tél. portable)
import ContributionAmount from 'views/common/contribution-amount'; // Cotisation de solidarité
import DifferentBillAddress from './different-bill-address'; // Choisir une adresse de facturation différente
import Extra from './extra'; // Adhérer ou s'abonner en plus de l'élément souhaité
import ConsultingVisit from './consulting-visit'; // Demande de visite-conseil pour une adhésion professionnelle
import Demeter from './demeter'; // Adhérent DEMETER
import MabdOnly from './mabd-only'; // Couverture de l'association
import CoupleMembership from 'views/common/couple-membership'; // Adhésion couple (supprimée)
import PurchaseConditions from 'views/common/purchase-conditions'; // CGV et Politique de confidentialité
import PurchaseSummary from './purchase-summary'; // Récapitulatif d'achat
import SubscriptionSubmit from './subscription-submit'; // Envoi du formulaire

/* * *
* Formulaire d'adhésion et d'abonnement
*
* Le formulaire est géré par la même vue que ce soit une adhésion ou un abonnement.
* L'affichage varie en fonction du type (subscriptionType) : subscription pour abonnement et membership pour adhésion.
*/

// ----------------------------------------------------------------------------------------- \\
// ---------------------------------- CREATE SUBSCRIPTION ---------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const CreateSubscription = () => {
    const { subscriptionTypeId, subscriptionType, userId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        country_id_lock: false,
        price: 0,
        countries: [],
        currentYearMembership: null,
        loadingCreate: false,

        // Subscription & Membership
        subscription_type: null,
        subscription_type_id: null,
        membership_type: null,
        membership_type_id: null,

        // Subscription & Membership Fields
        first_name: '',
        last_name: '',
        company_name: '',
        address: '',
        address_details: '',
        postal_code: '',
        city: '',
        country_id: 1,
        geocode: null,
        email: '',
        payment_method: 'cb',
        contribution_amount: '',
        only_mabd: false,
        phone: '',
        mobile_phone: '',
        federatedAssociation: null,

        // Membership Fields Only
        main_activity_id: -1,
        secondary_activities: [],
        profile: '',
        is_demeter: false,
        next_year: false,
        nonMemberSubscriptionPrice: 0,

        // Subscriptions Only
        subscriptionTypes: [],
        membershipStatus: null,

        // Different Bill Address
        different_bill_address: false,
        bill_first_name: '',
        bill_last_name: '',
        bill_company_name: '',
        bill_address: '',
        bill_address_details: '',
        bill_city: '',
        bill_postal_code: '',
        bill_country_id: 1,
        bill_email: '',
        bill_geocode: null,

        // Extra
        extra: false,
        extraType: null,

        // Second Email (couple)
        second_user_email: '',
        second_user_first_name: '',
        second_user_last_name: '',
        second_user_is_verified: false,
        second_user_not_exist: false,
        second_user_loading: false,

        // Consulting Visit
        wants_visit: '',
        agricultural_area: '',
        is_in_conventional: false,
        is_in_conversion: false,
        is_in_bio: false,
        is_in_biodynamic: false,
        is_certified_in_biodynamics: false,
        no_knowledge_about_biodynamic: false,
        has_followed_biodynamic_training: false,
        practice_biodynamics: false,
        wish_diagnostic: false,
        wish_practice_biodynamics: false,
        wish_to_be_accompanied: false,
        has_manual_energizer: false,
        has_mechanical_energizer: false,
        has_backpack_sprayer: false,
        has_mechanical_sprayer: false,
        comments: '',

        // Purchase conditions
        cgv: false,
        pdc: false,
    });

    function fetchUser() {
        getUser(userId).then(res => {
            if (res.status === 200) {
                const user = res.data.data.user;
                fetchSubscriptionTypes(user);
            }
            else {
                fetchSubscriptionTypes({});
            }
        });
    }

    function fetchSubscriptionTypes(user) {
        
        /* * *
        * Membership
        */
        if (subscriptionType === 'membership') {
            getMembershipType(subscriptionTypeId).then(res => {
                let membership_type = res.data?.data?.membershipType;
                if (res.status === 200 && membership_type) {
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        membership_type,
                        membership_type_id: membership_type.id,
                        first_name: user.first_name || '',
                        last_name: user.last_name || '',
                        company_name: user.company_name || '',
                        address: user.address || '',
                        address_details: user.address_details || '',
                        postal_code: user.postal_code || '',
                        city: user.city || '',
                        country_id: user.country_id || 1,
                        geocode: getGeocode(user),
                        email: user.membershipEmail || '',
                        phone: user.phone || '',
                        mobile_phone: user.mobile_phone || '',
                        bill_first_name: user?.billAddress?.first_name || '',
                        bill_last_name: user?.billAddress?.last_name || '',
                        bill_company_name: user?.billAddress?.company_name || '',
                        bill_address: user?.billAddress?.address || '',
                        bill_address_details: user?.billAddress?.address_details || '',
                        bill_city: user?.billAddress?.city || '',
                        bill_postal_code: user?.billAddress?.postal_code || '',
                        bill_country_id: user?.billAddress?.country_id || 1,
                        bill_email: user?.billAddress?.email || '',
                        bill_geocode: getGeocode(user?.billAddress),
                        currentYearMembership: user.currentYearMembership,
                        federatedAssociation: user.federatedAssociation,
                        nonMemberSubscriptionPrice: res.data?.data?.nonMemberSubscriptionPrice,
                    }));
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }

        /* * *
        * Subscription
        */
        if (subscriptionType === 'subscription') {
            getSubscriptionTypes().then(res => {
                let subscriptionTypes = res?.data?.data?.subscriptionTypes;
                if (res.status === 200 && subscriptionTypes) {
                    let subscription_type = subscriptionTypes.find(st => st.id === Number(subscriptionTypeId));
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        subscription_type,
                        subscription_type_id: subscription_type.id,
                        first_name: user.first_name || '',
                        last_name: user.last_name || '',
                        company_name: user.company_name || '',
                        address: user.address || '',
                        address_details: user.address_details || '',
                        postal_code: user.postal_code || '',
                        city: user.city || '',
                        country_id: user.country_id || 1,
                        geocode: getGeocode(user),
                        email: user.membershipEmail || '',
                        phone: user.phone || '',
                        mobile_phone: user.mobile_phone || '',
                        bill_first_name: user?.billAddress?.first_name || '',
                        bill_last_name: user?.billAddress?.last_name || '',
                        bill_company_name: user?.billAddress?.company_name || '',
                        bill_address: user?.billAddress?.address || '',
                        bill_address_details: user?.billAddress?.address_details || '',
                        bill_city: user?.billAddress?.city || '',
                        bill_postal_code: user?.billAddress?.postal_code || '',
                        bill_country_id: user?.billAddress?.country_id || 1,
                        bill_email: user?.billAddress?.email || '',
                        bill_geocode: getGeocode(user?.billAddress),
                        currentYearMembership: user.currentYearMembership,
                        federatedAssociation: user.federatedAssociation,
                        subscriptionTypes,
                        membershipStatus: user.membershipStatus,
                    }));
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
    }

    useEffect(() => {
        if (state.loading) {
            if (isAuthenticated() && userId) {
                fetchUser();
            }
            else {
                fetchSubscriptionTypes({});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBSCRIPTION DETAILS -------------------- */}
            <SubscriptionDetails
                subscriptionType={subscriptionType}
                state={state}
            />
            <Divider />
            <Grid
                container
                style={{ padding: 24 }}
            >
                <Grid
                    item xs={false} sm={1} md={3}
                />
                <Grid item xs={12} sm={10} md={6}>
                    {/* -------------------- VALIDITY YEAR -------------------- */}
                    {(
                        subscriptionType === 'membership'
                        && moment().format('MM-DD') >= '07-01' && moment().format('MM-DD') < '11-01'
                    ) && (
                        <>
                            <ValidityYear
                                state={state}
                                setState={setState}
                            />
                            <Divider />
                        </>
                    )}
                    {/* -------------------- SUBSCRIPTION FORM -------------------- */}
                    <SubscriptionForm
                        subscriptionType={subscriptionType}
                        state={state}
                        setState={setState}
                    />
                    <Divider />
                    {/* -------------------- CONTRIBUTION AMOUNT -------------------- */}
                    <ContributionAmount
                        subscriptionType={subscriptionType}
                        state={state}
                        setState={setState}
                    />
                    <Divider />
                    {/* -------------------- DIFFERENT BILL ADDRESS -------------------- */}
                    <DifferentBillAddress
                        subscriptionType={subscriptionType}
                        state={state}
                        setState={setState}
                    />
                    <Divider />
                    {/* -------------------- EXTRA -------------------- */}
                    {(
                        (
                            subscriptionType === 'subscription'
                            && state.subscription_type?.membershipTypes?.length > 0
                        ) || (
                            subscriptionType === 'membership'
                            && state.membership_type?.subscriptionTypes?.length > 0
                        )
                    ) && (
                        <Extra
                            subscriptionType={subscriptionType}
                            state={state}
                            setState={setState}
                        />
                    )}
                    {/* -------------------- WANTS VISIT -------------------- */}
                    {(
                        subscriptionType === 'membership'
                        && state.membership_type?.is_pro === 1
                    ) && (
                        <>
                            <ConsultingVisit
                                state={state}
                                setState={setState}
                            />
                            <Divider />
                        </>
                    )}
                    {/* -------------------- DEMETER -------------------- */}
                    {(
                        subscriptionType === 'membership'
                        && state.membership_type?.demeter_discount > 0
                    ) && (
                        <>
                            <Demeter
                                state={state}
                                setState={setState}
                            />
                            <Divider />
                        </>
                    )}
                    {/* -------------------- MABD ONLY -------------------- */}
                    {(
                        subscriptionType === 'membership'
                        || state.membership_type_id
                    ) && (
                        <>
                            <MabdOnly
                                state={state}
                                setState={setState}
                            />
                            <Divider />
                        </>
                    )}
                    {/* -------------------- SECOND USER EMAIL -------------------- */}
                    {state.membership_type_id === 2 && (
                        <>
                            <div style={{ padding: '24px 0px' }}>
                                <CoupleMembership
                                    state={state}
                                    setState={setState}
                                />
                            </div>
                            <Divider />
                        </>
                    )}
                    {/* -------------------- PURCHASE CONDITIONS -------------------- */}
                    <div style={{ padding: '24px 0px' }}>
                        <PurchaseConditions
                            state={state}
                            setState={setState}
                        />
                    </div>
                    {/* -------------------- PURCHASE SUMMARY -------------------- */}
                    <PurchaseSummary
                        subscriptionType={subscriptionType}
                        state={state}
                        setState={setState}
                    />
                    {/* -------------------- SUBMIT -------------------- */}
                    <Grid
                        container justify="center"
                        style={{ padding: '24px 0px' }}
                    >
                        <SubscriptionSubmit
                            subscriptionType={subscriptionType}
                            userId={userId}
                            state={state}
                            setState={setState}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default CreateSubscription;
