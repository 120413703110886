import React, { useState } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Button, Divider } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';

// Views
import PostalCodeAndCityInputs from '../postal-code-and-city-inputs';

// ------------------------------------------------------------------------------------------ \\
// ------------------------------- POSTAL CODE AND CITY DIALOG ------------------------------ \\
// ------------------------------------------------------------------------------------------ \\

const PostalCodeAndCityDialog = ({ onClose, setGeocode, requiredPostalCode, requiredCity }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        bill_postal_code: '',
        bill_city: '',
    });

    const onValidate = () => {
        if (
            state.bill_postal_code === ''
            || state.bill_city === ''
        ) {
            enqueueSnackbar('Veuillez saisir le code postal et la ville', { variant: 'warning' });
        }
        else {
            setGeocode({
                label: state.bill_postal_code + ' ' + state.bill_city,
                value: state.bill_postal_code + '&' + state.bill_city,
            });
            onClose();
        }
    };

    return (
        <Dialog
            open
            fullWidth
            fullScreen={!mediaMD}
        >
            <DialogContent style={{ paddingBottom: 20 }}>
                <p style={{ fontWeight: 600 }}>
                    Je possède une adresse CEDEX ou je ne trouve pas la ville souhaitée en renseignant le code postal,
                    je peux les saisir manuellement :
                </p>
                {/* -------------------- POSTAL CODE & CITY -------------------- */}
                <PostalCodeAndCityInputs
                    state={state}
                    setState={setState}
                    requiredPostalCode={requiredPostalCode}
                    requiredCity={requiredCity}
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    variant="contained"
                >
                    Fermer
                </Button>
                <Button
                    onClick={onValidate}
                    variant="contained"
                >
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PostalCodeAndCityDialog;
