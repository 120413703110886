import React, { useState, Fragment } from 'react';

// Material-UI
import { Grid, Button, Divider, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as UpdateIcon } from '@material-ui/icons';

// Components
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import AddContact from './add-contact';
import UpdateContact from './update-contact';
import DeleteContact from './delete-contact';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- CONTACTS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SetContacts = ({ federated_association }) => {
    
    const [state, setState] = useState({
        loading: false,
        error: false,
        contacts: federated_association.contacts,
        contact: null,
        openAdd: false,
        openUpdate: false,
        openDelete: false,
    });

    function onAddContact(contact) {
        let contacts = state.contacts;
        contacts.push(contact);
        setState({ ...state, openAdd: false, contacts });
    }

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <Grid item xs={6}>
                    Contacts
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                    <Button onClick={() => setState({ ...state, openAdd: true })} variant="contained">
                        Ajouter un contact
                    </Button>
                </Grid>
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                <Grid container alignItems="center" className="list-headers">
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Nom
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Fonction ou rôle
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Email
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Téléphone
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Description
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10, textAlign: 'center' }}>
                        Action
                    </Grid>
                </Grid>
                {/* -------------------- CONTACT LIST -------------------- */}
                <ContactList
                    state={state}
                    setState={setState}
                />
            </div>
            {/* -------------------- ADD CONTACT -------------------- */}
            {state.openAdd && (
                <AddContact
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={contact => onAddContact(contact)}
                />
            )}
        </>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- CONTACT LIST --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const ContactList = ({ state, setState }) => {

    function onUpdate(contact) {
        let contacts = state.contacts;
        let index = contacts.findIndex(c => c.id === contact.id);
        contacts.splice(index, 1, contact);
        setState({ ...state, openUpdate: false, contacts });
    }

    function onDelete(contact_id) {
        let contacts = state.contacts;
        let index = contacts.findIndex(c => c.id === contact_id);
        contacts.splice(index, 1);
        setState({ ...state, openDelete: false, contacts });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <div className="main-container-item">
            {state.contacts.length > 0 ? (
                state.contacts.map(contact => (
                    <Fragment key={contact.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                                {contact.name}
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                                {contact.type}
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                                {contact.email}
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                                {contact.phone}
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                                {contact.description}
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ padding: 10, textAlign: 'center' }}>
                                <Tooltip title="Modifier"
                                    item={(
                                        <IconButton
                                            onClick={() => setState({ ...state, openUpdate: true, contact })}
                                            style={{ padding: 6 }}
                                        >
                                            <UpdateIcon />
                                        </IconButton>
                                    )}
                                />
                                <Tooltip title="Supprimer"
                                    item={(
                                        <IconButton
                                            onClick={() => setState({ ...state, openDelete: true, contact })}
                                            style={{ padding: 6 }}       
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid container style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- DELETE CONTACT -------------------- */}
            {state.openUpdate && (
                <UpdateContact
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={contact => onUpdate(contact)}
                    contact={state.contact}
                />
            )}
            {/* -------------------- DELETE CONTACT -------------------- */}
            {state.openDelete && (
                <DeleteContact
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={contact_id => onDelete(contact_id)}
                    contact={state.contact}
                />
            )}
        </div>
    );
};

export default SetContacts;
