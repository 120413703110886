import React, { useState, useEffect } from 'react';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';

// Material-UI
import { Grid, Paper, GridList, Badge } from '@material-ui/core';

// Services
import {
    userCanAccessToSubscriptionList, userCanAccessToMembershipList, userCanAccessToMagazineList,
    userCanAccessToBillList, userCanAccessToPaymentList, userCanAccessToConsultingVisitList,
    userCanAccessToFormationList, userCanAccessToTrainingPlaceList, userCanAccessToTransferList,
} from 'services/permissions';
import { getCountNotProcessed } from 'services/requests';
import { getStoredUser } from 'services/storage';

// Services
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Tooltip from 'components/tooltip';

// Views
import Subscriptions from './subscriptions';
import Memberships from './memberships';
import FormationSessions from './formation-sessions';
import Bills from './bills';
import Payments from './payments';
import ConsultingVisits from './consulting-visits';
import Magazines from './magazines';
import Categories from './categories';
import UserCategories from './user-categories';
import Formations from './formations';
import TrainingPlaces from './training-places';
import Refunds from './refunds';
import Transfers from './transfers';

// Assets
import ManagementIcon from 'assets/images/management.png';

// -------------------------------------------------------------------------------------------- \\
// ------------------------------------- MANAGEMENT ROUTER ------------------------------------ \\
// -------------------------------------------------------------------------------------------- \\

const ManagementRouter = () => {
    const storedUser = getStoredUser();
    const roleId = storedUser?.role?.id;
    const federatedAssociationId = storedUser?.federatedAssociation?.id;

    return (
        <Switch>
            <Route exact path="/management">
                <Management
                    roleId={roleId}
                    federatedAssociationId={federatedAssociationId}
                />
            </Route>
            {userCanAccessToSubscriptionList() && (
                <Route path="/management/subscriptions">
                    <Subscriptions />
                </Route>
            )}
            {userCanAccessToMembershipList() && (
                <Route path="/management/memberships">
                    <Memberships />
                </Route>
            )}
            {userCanAccessToFormationList() && (
                <Route path="/management/formation-sessions">
                    <FormationSessions />
                </Route>
            )}
            {userCanAccessToBillList() && (
                <Route path="/management/bills">
                    <Bills />
                </Route>
            )}
            {userCanAccessToPaymentList() && (
                <Route exact path="/management/payments">
                    <Payments />
                </Route>
            )}
            {userCanAccessToConsultingVisitList() && (
                <Route path="/management/consulting-visits">
                    <ConsultingVisits />
                </Route>
            )}
            {(userCanAccessToMagazineList() && (roleId === 1 || federatedAssociationId === 1)) && (
                <Route path="/management/magazines">
                    <Magazines />
                </Route>
            )}
            {(roleId === 1 || federatedAssociationId === 1) && (
                <Route path="/management/categories">
                    <Categories />
                </Route>
            )}
            {(roleId === 1 || federatedAssociationId === 1) && (
                <Route path="/management/user-categories">
                    <UserCategories />
                </Route>
            )}
            {(userCanAccessToFormationList() && (roleId === 1 || federatedAssociationId === 1)) && (
                <Route path="/management/formations">
                    <Formations />
                </Route>
            )}
            {(userCanAccessToTrainingPlaceList() && (roleId === 1 || federatedAssociationId === 1)) && (
                <Route path="/management/training-places">
                    <TrainingPlaces />
                </Route>
            )}
            {userCanAccessToBillList() && (
                <Route path="/management/refunds">
                    <Refunds />
                </Route>
            )}
            {userCanAccessToTransferList() && (
                <Route path="/management/transfers">
                    <Transfers />
                </Route>
            )}
            <Redirect to="/management" />
        </Switch>
    );
};

// -------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGEMENT ---------------------------------------- \\
// -------------------------------------------------------------------------------------------- \\

const Management = ({ roleId, federatedAssociationId }) => {
    const history = useHistory();
    const { mediaXS, mediaSM, mediaMD, mediaLG, mediaXL } = useMediaQueries();
    const [cols, setCols] = useState(6);

    const [state, setState] = useState({
        loading: true,
        count_bills: 0,
        count_subscriptions: 0,
        count_memberships: 0,
        count_formation_requests: 0,
        count_consulting_visits: 0,
    });

    useEffect(() => {
        if (mediaXS) setCols(1);
        if (mediaSM) setCols(2);
        if (mediaMD) setCols(4);
        if (mediaLG) setCols(5);
        if (mediaXL) setCols(6);
    }, [mediaXS, mediaSM, mediaMD, mediaLG, mediaXL]);

    useEffect(() => {
        if (state.loading) {
            getCountNotProcessed().then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        count_bills: res.data.data.count_bills,
                        count_subscriptions: res.data.data.count_subscriptions,
                        count_memberships: res.data.data.count_memberships,
                        count_formation_requests: res.data.data.count_formation_requests,
                        count_consulting_visits: res.data.data.count_consulting_visits,
                    });
                }
                else {
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                Gestion
            </Grid>
            <Grid className="main-container" style={{ padding: 24 }}>
                <GridList cellHeight={160} cols={cols} style={{ margin: 0 }}>
                    {/* -------------------- SUBSCRIPTIONS -------------------- */}
                    {userCanAccessToSubscriptionList() && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/subscriptions')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Abonnements</b>
                                {state.count_subscriptions > 0 ? (
                                    <Tooltip title="Nombre BC non traités"
                                        item={(
                                            <Badge max={999} badgeContent={state.count_subscriptions} color="secondary">
                                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                            </Badge>
                                        )}
                                    />
                                ) : (
                                    <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                )}
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- MEMBERSHIPS -------------------- */}
                    {userCanAccessToMembershipList() && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/memberships')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Adhésions</b>
                                {state.count_memberships > 0 ? (
                                    <Tooltip title="Nombre BC non traités"
                                        item={(
                                            <Badge max={999} badgeContent={state.count_memberships} color="secondary">
                                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                            </Badge>
                                        )}
                                    />
                                ) : (
                                    <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                )}
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- FORMATION SESSIONS -------------------- */}
                    {userCanAccessToFormationList() && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/formation-sessions')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Formations</b>
                                {state.count_formation_requests > 0 ? (
                                    <Tooltip title="Nombre BC non traités"
                                        item={(
                                            <Badge max={999} badgeContent={state.count_formation_requests} color="secondary">
                                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                            </Badge>
                                        )}
                                    />
                                ) : (
                                    <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                )}
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- FORMATIONS -------------------- */}
                    {(userCanAccessToFormationList() && (roleId === 1 || federatedAssociationId === 1)) && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/formations')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Modèles de formation</b>
                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- TRAINING PLACES -------------------- */}
                    {(userCanAccessToTrainingPlaceList() && (roleId === 1 || federatedAssociationId === 1)) && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/training-places')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Lieux de formation</b>
                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- CATEGORIES -------------------- */}
                    {(roleId === 1 || federatedAssociationId === 1) && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/categories')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Catégories de formation</b>
                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- USER CATEGORIES -------------------- */}
                    {(roleId === 1 || federatedAssociationId === 1) && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/user-categories')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Rôles au sein du MABD</b>
                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- CONSULTING VISITS -------------------- */}
                    {userCanAccessToConsultingVisitList() && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/consulting-visits')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Visite-conseil</b>
                                {state.count_consulting_visits > 0 ? (
                                    <Tooltip title="Nombre visite-conseil non traitées"
                                        item={(
                                            <Badge max={999} badgeContent={state.count_consulting_visits} color="secondary">
                                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                            </Badge>
                                        )}
                                    />
                                ) : (
                                    <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                )}
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- MAGAZINES -------------------- */}
                    {(userCanAccessToMagazineList() && (roleId === 1 || federatedAssociationId === 1)) && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/magazines')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Revues</b>
                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- BILLS -------------------- */}
                    {userCanAccessToBillList() && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/bills')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Bons de commande</b>
                                {state.count_bills > 0 ? (
                                    <Tooltip title="Nombre BC non traités"
                                        item={(
                                            <Badge max={999} badgeContent={state.count_bills} color="secondary">
                                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                            </Badge>
                                        )}
                                    />
                                ) : (
                                    <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                                )}
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- PAYMENTS -------------------- */}
                    {userCanAccessToPaymentList() && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/payments')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Paiements</b>
                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- REFUNDS -------------------- */}
                    {userCanAccessToBillList() && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/refunds')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Remboursements</b>
                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                            </Paper>
                        </Grid>
                    )}
                    {/* -------------------- TRANSFERS -------------------- */}
                    {userCanAccessToTransferList() && (
                        <Grid container style={{ padding: 10 }}>
                            <Paper
                                onClick={() => history.push('/management/transfers')}
                                elevation={3}
                                className="paper"
                            >
                                <b style={{ marginBottom: 10 }}>Transferts</b>
                                <img alt="management-icon" src={ManagementIcon} style={{ height: 40, with: 'auto' }} />
                            </Paper>
                        </Grid>
                    )}
                </GridList>
            </Grid>
        </>
    );
};

export default ManagementRouter;
