import React from 'react';

// Material-UI
import { MenuItem } from '@material-ui/core';

// Services
import { adherentTypes } from 'services/constants';

// Components
import SelectOutlined from 'components/select-outlined';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------- SELECT ADHERENT TYPE ------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectAdherentType = ({ adherentType, setAdherentType }) => {
    return (
        <SelectOutlined
            value={adherentType}
            onChange={e => setAdherentType(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>Type d'adhérent</em>
            </MenuItem>
            {adherentTypes.map(adherentType => (
                <MenuItem
                    key={adherentType.value}
                    value={adherentType.value}
                >
                    {adherentType.label}
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

export default SelectAdherentType;
