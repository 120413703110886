import React, { useState, useEffect } from 'react';

// Services
import { getUserCategories } from 'services/requests';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------ SELECT USER CATEGORIES ---------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectUserCategories = ({ userCategories, setUserCategories }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getUserCategories({}).then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.userCategories.map(c => ({ label: c.name, value: c.id })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <SelectMultiple
            value={userCategories}
            onChange={value => setUserCategories(value)}
            options={options}
            placeholder="Rôle"
        />
    );
};

export default SelectUserCategories;
