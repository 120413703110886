import React, { useState, useEffect } from 'react';

// Services
import { getSubscriptionTypes } from 'services/requests';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------- SELECT SUBSCRIPTION TYPES --------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectSubscriptionTypes = ({ subscriptionTypes, setSubscriptionTypes }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getSubscriptionTypes(true).then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.subscriptionTypes.map(a => ({ label: a.name, value: a.id })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <SelectMultiple
            value={subscriptionTypes}
            onChange={value => setSubscriptionTypes(value)}
            options={options}
            placeholder="Type d'abonnement"
        />
    );
};

export default SelectSubscriptionTypes;
