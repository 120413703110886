import React, { useState, useEffect } from 'react';

// Services
import { transferStatuses } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------- SELECT TRANSFER STATUSES ---------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectTransferStatuses = ({ statuses, setStatuses }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(transferStatuses.map(s => ({ label: s.label, value: s.value })));
    }, []);

    return (
        <SelectMultiple
            value={statuses}
            onChange={value => setStatuses(value)}
            options={options}
            placeholder="Statut du transfert"
        />
    );
};

export default SelectTransferStatuses;
