import React, { useState, useEffect, Fragment } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, CircularProgress } from '@material-ui/core';

// Services
import { removeUserFromGroup } from 'services/requests';

// Views
import AddUser from './add-user';

// --------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- USERS ---------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

export default function Users({ group }) {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        users: group.users || [],
        user: null,
        openAdd: false,
        loadingRemove: false,
    });

    useEffect(() => {
        if (state.loadingRemove) {
            removeUserFromGroup(group.id, state.user.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingRemove: false, users: res.data.data.userList.users });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingRemove: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingRemove]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <Grid item xs={6}>
                    Utilisateurs
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                    <Button onClick={() => setState({ ...state, openAdd: true })} variant="contained">
                        Ajouter un utilisateur
                    </Button>
                </Grid>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                <Grid container alignItems="center" className="list-headers">
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Code client
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Entreprise
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Prénom
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10 }}>
                        Nom
                    </Grid>
                </Grid>
                <div className="main-container-item">
                    {/* -------------------- USER LIST -------------------- */}
                    {state.users.length > 0 ? (
                        state.users.map(user => (
                            <Fragment key={user.id}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                        {user.clientCode}
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                        {user.company_name}
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                        {user.first_name}
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                        {user.last_name}
                                    </Grid>
                                    <Grid item xs={false} md={2} />
                                    <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                        <Button
                                            onClick={() => setState({ ...state, loadingRemove: true, user })}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            {state.loadingRemove ? (
                                                <CircularProgress size={24} style={{ color: 'white' }} />
                                            ) : (
                                                'Retirer'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <Grid container style={{ padding: 10 }}>
                                Aucun
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
            {/* -------------------- ADD USER -------------------- */}
            {state.openAdd && (
                <AddUser
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={users => setState({ ...state, openAdd: false, users })}
                />
            )}
        </>
    );
}
