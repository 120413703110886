import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getTrainingPlaces } from 'services/requests';
import { getFrenchDepartmentLabel } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------- TRAINING PLACES EXPORT ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const TrainingPlacesExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getAllTrainingPlacesToExport(training_places, offset) {
        getTrainingPlaces({
            offset: offset,
            limit: data.limit,
            names: data.names ? data.names.map(n => n.value) : null,
            address: data.address,
            address_details: data.address_details,
            country_ids: data.countries ? data.countries.map(c => c.value) : null,
            cities: data.cities ? data.cities.map(c => c.value) : null,
            mobile_phone: data.mobile_phone,
            phone: data.phone,
            contact: data.contact,
            french_department_ids: data.french_departments ? data.french_departments.map(d => d.value) : null,
            email: data.email,
            postal_codes: data.postal_codes ? data.postal_codes.map(c => c.value) : null,
            other_phone: data.other_phone,
            other_email: data.other_email,
        }).then(res => {
            if (res.status === 200) {

                let res_training_places = res.data.data.trainingPlaces;
                training_places = training_places.concat(res_training_places);

                setState({
                    ...state,
                    percentage: Math.round((100 * training_places.length) / data.total_count) + '%',
                });

                if (res_training_places.length < data.limit) {
                    createExcelFile(training_places);
                }
                else {
                    getAllTrainingPlacesToExport(training_places, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(training_places) {
        if (training_places.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Lieux de formations');

            // Header
            worksheet.columns = [
                { header: 'Nom du lieu', key: 'name', width: 30 },
                { header: 'Adresse (n° et voie)', key: 'address', width: 30 },
                { header: 'Adresse (complément)', key: 'address_details', width: 30 },
                { header: 'Pays', key: 'country', width: 30 },
                { header: 'Ville', key: 'city', width: 30 },
                { header: 'Téléphone portable', key: 'mobile_phone', width: 30 },
                { header: 'Téléphone', key: 'phone', width: 30 },
                { header: 'Contact du lieu', key: 'contact', width: 30 },
                { header: 'Département', key: 'department', width: 30 },
                { header: 'Email', key: 'email', width: 30 },
                { header: 'Code postal', key: 'postal_code', width: 30 },
                { header: 'Autre téléphone', key: 'other_phone', width: 30 },
                { header: 'Autre email', key: 'other_email', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            training_places.map(training_place => {
                return worksheet.addRow({
                    name: training_place.name || '',
                    address: training_place.address || '',
                    address_details: training_place.address_details || '',
                    country: training_place.country ? training_place.country.name : '',
                    city: training_place.city || '',
                    mobile_phone: training_place.mobile_phone || '',
                    phone: training_place.phone || '',
                    contact: training_place.contact || '',
                    department: getFrenchDepartmentLabel(training_place.frenchDepartment),
                    email: training_place.email || '',
                    postal_code: training_place.postal_code || '',
                    other_phone: training_place.other_phone || '',
                    other_email: training_place.other_email || '',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'lieux-de-formations.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllTrainingPlacesToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default TrainingPlacesExport;
