import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getUser, updateUser } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { getGeocode } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import ConfirmUpdateAddress from 'views/common/confirm-update-address';
import ProfileForm from './form';
import UpdatePassword from './update-password';

// Messages
import messages from 'messages.json';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------- FETCH USER ------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const FetchUser = () => {
    let storedUserId = getStoredUser()?.id;

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: null,
    });

    useEffect(() => {
        if (state.loading) {
            getUser(storedUserId).then(res => {
                let user = res?.data?.data?.user
                if (res?.status === 200 && user) {
                    setState({ ...state, loading: false, user });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <Profile
            user={state.user}
            refetch={() => setState(prevState => ({ ...prevState, loading: true }))}
        />
    );
};

// -------------------------------------------------------------------------------------- \\
// -------------------------------------- PROFILE --------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const Profile = ({ user, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        error: false,
        openUpdatePassword: false,
        openConfirmUpdateAddress: false,

        // Data
        photo_url: user.photo_url,
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        company_name: user.company_name || '',
        birth_date: user.birth_date ? moment(user.birth_date, 'YYYY-MM-DD') : null,
        email: user.email || '',
        role: user.role,
        phone: user.phone || '',
        mobile_phone: user.mobile_phone || '',
        other_phone: user.other_phone || '',
        presentation: user.presentation || '',
        address: user.address || '',
        address_details: user.address_details || '',
        postal_code: user.postal_code || '',
        city: user.city || '',
        french_department_id: user.french_department_id ? user.french_department_id : -1,
        country_id: user.country_id ? user.country_id : 1,
        geocode: getGeocode(user),
        bill_first_name: user?.billAddress?.first_name || '',
        bill_last_name: user?.billAddress?.last_name || '',
        bill_company_name: user?.billAddress?.company_name || '',
        bill_address: user?.billAddress?.address || '',
        bill_address_details: user?.billAddress?.address_details || '',
        bill_city: user?.billAddress?.city || '',
        bill_postal_code: user?.billAddress?.postal_code || '',
        bill_country_id: user?.billAddress?.country_id || 1,
        bill_email: user?.billAddress?.email || '',
        bill_geocode: getGeocode(user?.billAddress),
        update_bill_address: true,
        update_subscriptions_address: true,
        siret: user?.siret || '',
    });

    function onSave() {
        if (
            state.first_name === ''
            || state.last_name === ''
            || state.country_id === -1
            || (state.country_id === 1 && state.french_department_id === -1)
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-photo').files[0];

            setState(prevState => ({ ...prevState, openConfirmUpdateAddress: true, uploaded_file: file }));
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('first_name', state.first_name);
            formData.append('last_name', state.last_name);
            formData.append('company_name', state.company_name);
            formData.append('role_id', state.role.id);
            formData.append('email', state.email);
            formData.append('phone', state.phone);
            formData.append('mobile_phone', state.mobile_phone);
            formData.append('other_phone', state.other_phone);
            formData.append('birth_date', state.birth_date ? moment(state.birth_date).format('YYYY-MM-DD') : '');
            formData.append('address', state.address || '');
            formData.append('address_details', state.address_details || '');
            formData.append('postal_code', state.postal_code || '');
            formData.append('city', state.city || '');
            formData.append('french_department_id', state.country_id === 1 ? state.french_department_id : '');
            formData.append('country_id', state.country_id);
            formData.append('presentation', state.presentation);
            formData.append('remove_photo', state.remove_photo ? '1' : '0');
            formData.append('update_bill_address', state.update_bill_address ? '1' : '0');
            formData.append('update_subscriptions_address', state.update_subscriptions_address ? '1' : '0');
            formData.append('siret', state.siret);

            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }

            formData.append('bill_address', JSON.stringify({
                first_name: state.bill_first_name,
                last_name: state.bill_last_name,
                company_name: state.bill_company_name,
                email: state.bill_email,
                address: state.bill_address,
                address_details: state.bill_address_details,
                city: state.bill_city,
                postal_code: state.bill_postal_code,
                country_id: state.bill_country_id,
            }));

            updateUser(user?.id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container justify="space-between" alignItems="center"
                className="subheader"
            >
                <span>
                    Profil
                </span>
                <Button
                    onClick={() => setState({ ...state, openUpdatePassword: true })}
                    variant="contained" color="primary"
                >
                    Modifier son mot de passe
                </Button>
            </Grid>
            {/* -------------------- FORM -------------------- */}
            <div
                className="light-background-color"
                style={{ padding: 24 }}
            >
                <ProfileForm
                    state={state}
                    setState={setState}
                    onSave={onSave}
                />
            </div>
            {/* -------------------- UPDATE PASSWORD MODAL -------------------- */}
            {state.openUpdatePassword && (
                <UpdatePassword
                    onClose={() => setState({ ...state, openUpdatePassword: false })}
                    userId={user?.id}
                />
            )}
            {/* -------------------- CONFIRM UPDATE ADDRESS -------------------- */}
            {state.openConfirmUpdateAddress && (
                <ConfirmUpdateAddress
                    state={state}
                    setState={setState}
                    onSubmit={() => setState(prevState => ({ ...prevState, openConfirmUpdateAddress: false, loading: true }))}
                />
            )}
        </>
    );
};

export default FetchUser;
