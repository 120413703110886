import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Route, Switch, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Button, Grid } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';
import { getUserFormationRequests } from 'services/requests';
import useMediaQueries from 'services/media';
import { formationRequestStatuses } from 'services/constants';
import { getFormattedDate, getLabelStatus, getColorStatus, cancelParticipationIsAvailable, getAmountTTC } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import PageNotFound from 'components/page-not-found';

// Views
import FormationSession from './formation-session';
import CancelParticipation from './formation-requests-cancel';
import DownloadTrainingCertificate from 'views/management/formations/formation-session/formation-participations/download-training-certificate';

/* * *
* Historique des formations
*/

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------- FORMATION REQUESTS ROUTER --------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FormationRequestsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <FormationRequestsHistory />
            </Route>
            <Route exact path={`${match.path}/:formationSessionId`}>
                <FormationSession />
            </Route>
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------- FORMATION REQUESTS HISTORY -------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FormationRequestsHistory = () => {
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const USER_ID = storedUser ? storedUser.id : null;
    const history = useHistory();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formationRequests: [],

        // Cancel Participation
        formationRequest: null,
        openCancelParticipation: false,
    });

    useEffect(() => {
        if (state.loading) {
            getUserFormationRequests(USER_ID).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, formationRequests: res.data.data.formationRequests });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/dashboard')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Historique des formations
                </Grid>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24, paddingTop: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers />
                    </div>
                    <FormationRequestList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
        </>
    )
};

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------ HEADERS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Headers = () => {
    return (
        <div className="list-headers">
            <div className="column-list">
                Action
            </div>
            <div className="column-list">
                Attestation
            </div>
            <div className="column-list">
                Statut
            </div>
            <div className="column-list">
                Montant
            </div>
            <div className="column-list">
                Formation
            </div>
            <div className="column-list">
                Intitulé
            </div>
            <div className="column-list">
                Date
            </div>
            <div className="column-list">
                Responsable
            </div>
        </div>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------ FORMATION REQUEST LISTS --------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const FormationRequestList = ({ state, setState }) => {
    const match = useRouteMatch();
    const history = useHistory();

    function openModalCancelParticipation(event, formationRequest) {
        event.stopPropagation();
        setState({ ...state, openCancelParticipation: true, formationRequest });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {(state.formationRequests && state.formationRequests.length > 0) && (
                state.formationRequests.map(formationRequest => (
                    <Fragment key={formationRequest.id}>
                        <div
                            onClick={() => history.push(match.url + '/' + formationRequest.formationSession?.id)}
                            className="simple-list"
                            style={{ display: 'inline-flex' }}
                        >
                            <div className="column-list">
                                {cancelParticipationIsAvailable(formationRequest.status) && (
                                    <Button
                                        onClick={e => openModalCancelParticipation(e, formationRequest)}
                                        variant="contained"
                                        style={{ textTransform: 'none', padding: '4px 8px' }}
                                    >
                                        Annuler la participation
                                    </Button>
                                )}
                            </div>
                            <div className="column-list">
                                <DownloadTrainingCertificate
                                    formationRequest={formationRequest}
                                />
                            </div>
                            <div className="column-list">
                                <b style={{ color: getColorStatus(formationRequestStatuses, formationRequest.status) }}>
                                    {getLabelStatus(formationRequestStatuses, formationRequest.status)?.toUpperCase()}
                                </b>
                            </div>
                            <div className="column-list">
                                {getAmountTTC(formationRequest.sessionPrice?.price)}
                            </div>
                            <div className="column-list">
                                Formation #{formationRequest.formationSession?.number}
                            </div>
                            <div className="column-list">
                                {formationRequest.formationTitle}
                            </div>
                            <div className="column-list">
                                {formationRequest.formationSession ? getFormattedDate(formationRequest.formationSession.begin) + ' - ' + getFormattedDate(formationRequest.formationSession.end) : ''}
                            </div>
                            <div className="column-list">
                                {formationRequest.formationSession?.manager?.fullName}
                            </div>
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
            {/* -------------------- CANCEL PARTICIPATION -------------------- */}
            {state.openCancelParticipation && (
                <CancelParticipation
                    onClose={() => setState({ ...state, openCancelParticipation: false })}
                    refetch={() => setState({ ...state, openCancelParticipation: false, loading: true })}
                    formationRequest={state.formationRequest}
                />
            )}
        </>
    );
};

export default FormationRequestsRouter;
