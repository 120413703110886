import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services
import { updatePayment } from 'services/requests';
import { userCanDeletePayment } from 'services/permissions';
import { amountToSend, amountToBeReceived, isValidAmount } from 'services/helpers';

// Components
import Spinner from 'components/spinner';

// Views
import PaymentForm from './payments-form';
import DeletePayment from './payment-delete';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- UPDATE PAYMENT ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UpdatePayment = ({ onClose, refetch, payment, bill }) => {
    const { enqueueSnackbar } = useSnackbar();

    const getSelectedBills = () => {
        if (bill) {
            return [{ label: bill.reference, value: bill.id }];
        }
        return payment.bills.map(bill => ({ label: bill.reference, value: bill.id }));
    };

    const [state, setState] = useState({
        loading: false,
        openDelete: false,
        openImport: false,

        // Data Payment
        payment_date: payment.payment_date || new Date(),
        amount: amountToBeReceived(payment.amount),
        payment_method: payment.payment_method || -1,
        reference: payment.reference || '',
        status: payment.status || -1,
        bills: getSelectedBills(),
    });

    function onUpdate() {
        if (payment.bills && state.bills.length === 0) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (state.payment_method === -1
            || state.status === -1
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!isValidAmount(state.amount)) {
            enqueueSnackbar('Veuillez saisir un montant valide', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    function onRefetch() {
        setState({ ...state, openDelete: false });
        refetch();
    }

    useEffect(() => {
        if (state.loading) {
            updatePayment(payment.id, {
                payment_date: state.payment_date ? moment(state.payment_date).format('YYYY-MM-DD') : null,
                amount: amountToSend(state.amount),
                payment_method: state.payment_method,
                reference: state.reference,
                status: state.status,
                bill_ids: payment.bills ? state.bills.map(bill => bill.value) : [bill.id],
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Dialog open fullWidth>
                {state.loading ? (
                    <Spinner />
                ) : (
                    <>
                        <DialogContent style={{ paddingBottom: 20 }}>
                            <p className="dialog-title">
                                Modifier le paiement
                            </p>
                            {/* -------------------- PAYMENT FORM -------------------- */}
                            <PaymentForm
                                state={state}
                                setState={setState}
                            />
                            {/* -------------------- DELETE -------------------- */}
                            {userCanDeletePayment() && (
                                <>
                                    <Divider style={{ margin: '15px 0px' }} />
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            Supprimer le paiement
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Button
                                                onClick={() => setState({ ...state, openDelete: true })}
                                                variant="contained" color="secondary"
                                            >
                                                Supprimer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </DialogContent>
                        <Divider />
                        <DialogActions style={{ justifyContent: 'space-between' }}>
                            <Button onClick={onClose} variant="contained">
                                Annuler
                            </Button>
                            <Button onClick={onUpdate} variant="contained">
                                Enregistrer
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <DeletePayment
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => onRefetch()}
                    payment={payment}
                />
            )}
        </>
    );
};

export default UpdatePayment;
