import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Material-UI
import { Button, Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';

// Services
import { searchUsers, addUserToGroup } from 'services/requests';

// Components
import Autocomplete from 'components/autocomplete';
import Spinner from 'components/spinner';

// Messages
import messages from 'messages.json';

// --------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- ADD USER --------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

export default function AddUser({ onClose, refetch }) {
    const { enqueueSnackbar } = useSnackbar();
    const { groupId } = useParams();

    const [state, setState] = useState({
        loading: false,      
        options: [],
        user: null,
        searchRef: undefined,
        loadingAdd: false,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    function onAdd() {
        if (state.user == null) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loadingAdd: true });
        }
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 3) {
                searchUsers({ search: state.searchRef }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.data.users.map(u => ({ label: u.fullName + ' (' + u.clientCode + ')', value: u.id })),
                        });
                    }
                    else {
                        setState({ ...state, loading: false });
                    }
                });
            } 
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingAdd) {
            addUserToGroup(groupId, { user_id: state.user.value }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.userList.users);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingAdd]);

    return (
        <Dialog open fullWidth>
            {state.loadingAdd ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un utilisateur
                        </p>
                        <Autocomplete
                            value={state.user}
                            options={state.options}
                            loading={state.loading}
                            onChange={(e, value) => setState({ ...state, user: value })}
                            onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
                            placeholder="Chercher un utilisateur..."
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
