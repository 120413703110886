import React, { useState, useEffect } from 'react';

// Services
import { consultingVisitStatuses } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------ SELECT CONSULTING VISIT STATUSES ------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const SelectConsultingVisitStatuses = ({ statuses, setStatuses }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(consultingVisitStatuses.map(s => ({ label: s.label, value: s.value })));
    }, []);

    return (
        <SelectMultiple
            value={statuses}
            onChange={value => setStatuses(value)}
            options={options}
            placeholder="Statut visite-conseil"
        />
    );
};

export default SelectConsultingVisitStatuses;
