import React, { useState, useEffect } from 'react';

// Services
import { consultingVisitSituations } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------- SELECT CONSULTING VISIT SITUATIONS ----------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectConsultingVisitSituations = ({ situations, setSituations }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(consultingVisitSituations.map(s => ({ label: s.label, value: s.value })));
    }, []);

    return (
        <SelectMultiple
            value={situations}
            onChange={value => setSituations(value)}
            options={options}
            placeholder="Situation"
        />
    );
};

export default SelectConsultingVisitSituations;
