import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { createConsultingVisit } from 'services/requests';
import useMediaQueries from 'services/media';

// Views
import ConsultingVisitForm from '../consulting-visit-form';

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------- CREATE CONSULTING VISIT ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const CreateConsultingVisit = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const location = useLocation();
    const history = useHistory();
    const { userId } = useParams();

    const [state, setState] = useState({
        actionType: 'CREATE',
        loading: false,
        agricultural_area: '',
        is_in_conventional: false,
        is_in_conversion: false,
        is_in_bio: false,
        is_in_biodynamic: false,
        is_certified_in_biodynamics: false,
        no_knowledge_about_biodynamic: false,
        has_followed_biodynamic_training: false,
        practice_biodynamics: false,
        wish_diagnostic: false,
        wish_practice_biodynamics: false,
        wish_to_be_accompanied: false,
        has_manual_energizer: false,
        has_mechanical_energizer: false,
        has_backpack_sprayer: false,
        has_mechanical_sprayer: false,
        comments: '',
    });

    function goBack() {
        if (location.pathname.includes('dashboard')) {
            history.push(`/dashboard`);
        }
        if (location.pathname.includes('users')) {
            history.push(`/users/${userId}`);
        }
    }

    useEffect(() => {
        if (state.loading) {
            createConsultingVisit({
                user_id: userId,
                agricultural_area: state.agricultural_area,
                is_in_conventional: state.is_in_conventional,
                is_in_conversion: state.is_in_conversion,
                is_in_bio: state.is_in_bio,
                is_in_biodynamic: state.is_in_biodynamic,
                is_certified_in_biodynamics: state.is_certified_in_biodynamics,
                no_knowledge_about_biodynamic: state.no_knowledge_about_biodynamic,
                has_followed_biodynamic_training: state.has_followed_biodynamic_training,
                practice_biodynamics: state.practice_biodynamics,
                wish_diagnostic: state.wish_diagnostic,
                wish_practice_biodynamics: state.wish_practice_biodynamics,
                wish_to_be_accompanied: state.wish_to_be_accompanied,
                has_manual_energizer: state.has_manual_energizer,
                has_mechanical_energizer: state.has_mechanical_energizer,
                has_backpack_sprayer: state.has_backpack_sprayer,
                has_mechanical_sprayer: state.has_mechanical_sprayer,
                comments: state.comments,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    goBack();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => goBack()} variant="contained">
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    Demande de visite-conseil
                </Grid>
            </Grid>
            {/* -------------------- FORM -------------------- */}
            <Grid container style={{ padding: 24 }}>
                <Grid item xs={false} sm={1} md={3} />
                <Grid item xs={12} sm={10} md={6}>
                    <ConsultingVisitForm state={state} setState={setState} />
                </Grid>
            </Grid>
        </>
    );
};

export default CreateConsultingVisit;
