import React from 'react';
import ReactSelect, { createFilter } from 'react-select';

/* * *
* Composant permettant de sélectionner plusieurs éléments à la fois
*/

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT MULTIPLE --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectMultiple = ({ value, onChange, options, placeholder, minWidth }) => {
    return (
        <ReactSelect
            value={value}
            onChange={onChange}
            options={options}
            isMulti
            placeholder={placeholder}
            noOptionsMessage={() => 'Aucun'}
            isClearable={false}
            maxMenuHeight={200}
            menuPortalTarget={document.body}
            filterOption={createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                matchFrom: 'any',
                stringify: option => `${option.label}`,
                trim: true,
            })}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                container: base => ({ ...base, minWidth: minWidth, width: '100%' }),
                placeholder: base => ({ ...base, whiteSpace: 'nowrap' })
            }}
        />
    );
};

// Propriétés par défaut
SelectMultiple.defaultProps = {
    placeholder: 'Sélectionner...',
    minWidth: 120,
};

export default SelectMultiple;
