import React from 'react';
import Select from 'components/react-select';
import { membershipStatuses } from 'services/constants';

// Column Width
let columnWidth = 180;

// -------------------- HEADER -------------------- \\
const MembershipStatusHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Statut adhésion
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const MembershipStatusFilter = ({ state, onSelectSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <Select
                placeholder="Statut adhésion"
                value={state.membership_statuses}
                onChange={membership_statuses => onSelectSearch('membership_statuses', membership_statuses)}
                options={membershipStatuses}
                isMulti
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const MembershipStatusValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { MembershipStatusHeader, MembershipStatusFilter, MembershipStatusValue };
