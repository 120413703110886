import React, { useState, Fragment } from 'react';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Components
import { getFormattedTime } from 'services/helpers';
import useMediaQueries from 'services/media';

// Views
import AddDayProgram from './day-programs-add';
import UpdateDayProgram from './day-programs-update';

// ----------------------------------------------------------------------------------- \\
// ----------------------------------- DAY PROGRAMS ---------------------------------- \\
// ----------------------------------------------------------------------------------- \\

const DayPrograms = ({ refetch, session_day }) => {
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        day_programs: session_day.dayPrograms,
        day_program: null,
        total_count: session_day.dayPrograms.length,
        openAdd: false,
        openUpdate: false,
    });

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid
                container justify="space-between" alignItems="center"
                className="toolbar"
            >
                <b>PROGRAMMES</b>
                <Button
                    onClick={() => setState({ ...state, openAdd: true })}
                    variant="contained"
                    style={{ textTransform: 'none' }}
                >
                    Ajouter un programme
                </Button>
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADER LIST -------------------- */}
                <Grid
                    container alignItems="center"
                    className="list-headers"
                >
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Heure de début
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Heure de fin
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Durée (h)
                            </Grid>
                            <Grid item xs={6} style={{ padding: 10 }}>
                                Description
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ padding: 10 }}>
                            Options
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- SESSION OPTION LIST -------------------- */}
                <div
                    className="main-container-item"
                    style={{ paddingBottom: 24 }}
                >
                    {state.day_programs.length > 0 ? (
                        state.day_programs.map(day_program => (
                            <Fragment key={day_program.id}>
                                <Grid
                                    onClick={() => setState({ ...state, openUpdate: true, day_program })}
                                    className="simple-list"
                                    container alignItems="center"
                                >
                                    <Grid
                                        item xs={12} md={2}
                                        style={{ padding: 10 }}
                                    >
                                        {getFormattedTime(day_program.start_time)}
                                    </Grid>
                                    <Grid
                                        item xs={12} md={2}
                                        style={{ padding: 10 }}
                                    >
                                        {getFormattedTime(day_program.end_time)}
                                    </Grid>
                                    <Grid
                                        item xs={12} md={2}
                                        style={{ padding: 10 }}
                                    >
                                        {day_program.duration + 'h'}
                                    </Grid>
                                    <Grid
                                        item xs={12} md={6}
                                        className="text"
                                        style={{ padding: 10 }}
                                    > 
                                        {day_program.description}
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <Grid container style={{ padding: 10 }}>
                                Aucun
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid
                container justify="flex-end"
                style={{ padding: 24 }}
            >
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            {/* -------------------- ADD DAY PROGRAM -------------------- */}
            {state.openAdd && (
                <AddDayProgram
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={() => refetch()}
                    session_day={session_day}
                />
            )}
            {/* -------------------- UPDATE DAY PROGRAM -------------------- */}
            {state.openUpdate && (
                <UpdateDayProgram
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => refetch()}
                    day_program={state.day_program}
                />
            )}
        </>
    );
};

export default DayPrograms;
