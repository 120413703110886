import React, { useState, useEffect } from 'react';
import Select from 'components/react-select';
import { getCountries } from 'services/requests';

// Column Width
let columnWidth = 160;

// -------------------- HEADER -------------------- \\
const CountryHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Pays
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const CountryFilter = ({ state, onSelectSearch }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getCountries().then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.countries.map(c => ({ label: c.name, value: c.id })));
            }
        });
    }, []);

    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <Select
                placeholder="Pays"
                value={state.countries}
                onChange={countries => onSelectSearch('countries', countries)}
                options={options}
                isMulti
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const CountryValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { CountryHeader, CountryFilter, CountryValue };
