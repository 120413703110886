import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Divider, Button, Grid, Dialog, DialogContent, DialogActions, Checkbox, CircularProgress } from '@material-ui/core';

// Services
import { renewMembership } from 'services/requests';
import { getAmountTTC } from 'services/helpers';

/* * *
* Propriétés d'une adhésion :
* is_pro : définit si l'adhésion est une adhésion professionnelle (1 = professionnel, 0 = particulier)
* is_demeter : définit si l'utilisateur est un adhérent DEMETER pour l'année civile en cours
* is_biodyvin : définit si l'utilisateur appartient à l'organisme Biodyvin
* with_subscription : définit si l'utilisateur souhaite renouveler son abonnement en même temps que son adhésion
*/

// ----------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ RENEW MEMBERSHIP ----------------------------------------- \\
// ----------------------------------------------------------------------------------------------------- \\

const RenewMembership = ({ onClose, membership }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        price: 0,
        with_subscription: true,
    });

    useEffect(() => {

        var price = membership.membershipType?.price;

        // is_pro : boolean indiquant si l'adhésion est une adhésion professionnelle ou non (valeur à 1 lorsqu'elle est professionnelle)
        if (membership.membershipType?.is_pro === 0 && membership.subscription != null && state.with_subscription) {
            price += membership.subscription?.subscriptionType?.price;
        }
        if (membership.is_demeter === 1) {
            price -= membership.membershipType?.demeter_discount;
        }
        setState({ ...state, price });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.with_subscription]);

    useEffect(() => {
        if (state.loading) {
            renewMembership(membership.id, (membership.subscription != null && state.with_subscription) ? '1' : '0').then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/payment-method/' + res.data.data.bill.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20, textAlign: 'center' }}>
                <p className="dialog-title">
                    Renouvellement adhésion
                </p>
                {/* -------------------- BIODYVIN -------------------- */}
                {membership.is_biodyvin === 1 ? (
                    <>
                        {`Votre adhésion professionnelle au MABD est collectée et gérée par l'organisme Biodyvin. Vous ne pouvez la renouveler par vous-même, Biodyvin nous enverra votre renouvellement.
                        Si vous ne faites plus partie de Biodyvin, vous pouvez prendre une nouvelle adhésion professionnelle au MABD en cliquant sur le bouton "Choisir une nouvelle formule".`}
                    </>
                ) : (
                    <>
                        {`Vous possédez actuellement une ${membership.membershipType?.is_pro === 1 ?'Adhésion professionnelle' : membership.membershipType?.name} ${membership.status === 'validated' ? 'valide' : 'expirée'}.
                        Vous pouvez dès à présent renouveler votre adhésion pour ${moment(membership.end_date?.substring(0, 4), 'YYYY').add(1, 'y').year()}.`}
                    </>
                )}
                {/* -------------------- DEMETER -------------------- */}
                {membership.is_demeter === 1 && (
                    <>
                        {`Votre adhésion bénéficie de la remise Demeter.`}
                    </>
                )}
                {/* -------------------- REMOVE SUBSCRIPTION -------------------- */}
                {(membership.membershipType?.is_pro === 0 && membership.subscription != null) && (
                    <>
                        <br />
                        {`Vous avez pris un ${membership.subscription?.subscriptionType?.name} en même temps que votre adhésion.
                        Nous vous proposons de renouveler votre abonnement également.`}
                        <Grid container alignItems="center" justify="center">
                            <Checkbox
                                checked={!state.with_subscription}
                                onChange={e => setState({ ...state, with_subscription: !e.target.checked })}
                                color="primary"
                            />
                            Renouveler uniquement l'adhésion
                        </Grid>
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    disabled={state.loading}
                    variant="contained"
                >
                    Annuler
                </Button>
                <Button
                    onClick={membership.is_biodyvin === 0 ? () => setState({ ...state, loading: true }) : null}
                    disabled={state.loading || membership.is_biodyvin === 1}
                    variant="contained" color="primary"
                >
                    Renouveler ({getAmountTTC(state.price)})
                    {state.loading && (
                        <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RenewMembership;
