import React from 'react';

// Material-UI
import { Button, Grid } from '@material-ui/core';

// Services
import { billStates } from 'services/constants';
import { getLabelStatus } from 'services/helpers';

// Views
import UpdateBillState from './update-bill-state';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------- BILL STATE ------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const BillState = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container justify="space-between"
                style={{ marginTop: 50 }}
            >
                <Grid>
                    <b>Etat BC</b>
                    <br />
                    {getLabelStatus(billStates, state.bill.bill_state)}
                </Grid>
                <Grid>
                    <Button
                        onClick={() => setState({ ...state, openBillState: true })}
                        variant="contained"
                        style={{ textTransform: 'none' }}
                    >
                        Changer l'état du BC
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- UPDATE BILL STATE -------------------- */}
            {state.openBillState && (
                <UpdateBillState
                    bill={state.bill}
                    onClose={() => setState({ ...state, openBillState: false })}
                    refetch={() => setState({ ...state, openBillState: false, loading: true })}
                />
            )}
        </>
    );
};

export default BillState;
