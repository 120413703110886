import React, { useState, useEffect } from 'react';

// Material-UI
import Pagination from '@material-ui/lab/Pagination';

/* * *
* Composant permettant de gérer la pagination :
*
* page : numéro de la page actuelle
* limit : nombre d'éléments maximum que l'on souhaite avoir dans la réponse de la requête
* offset : numéro de l'élément actuel
* total_count : nombre total d'éléments
*/

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- PAGINATION ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const EditedPagination = ({ limit, total_count, offset, setOffset }) => {

    const [state, setState] = useState({
        page: 1,
        count: undefined,
    });

    function handleChange(event, value) {
        setState({ ...state, page: value });
        setOffset((value - 1) * limit);
    }   

    useEffect(() => {
        if (limit != null && total_count != null) {
            setState({ ...state, count: Math.ceil(total_count / limit)});
        }
        if (state.page > 1 && offset === 0) {
            setState({ ...state, page: 1 })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, total_count, offset]);

    return (
        <Pagination
            variant="outlined"
            shape="rounded"
            page={state.page}
            count={state.count}
            onChange={handleChange}
        />
    );
};

export default EditedPagination;
