import React, { useState, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { IconButton, Button, Grid, Checkbox, Divider, CircularProgress } from '@material-ui/core';
import {
    Person as AccountIcon, Lock as PasswordIcon,
    Visibility as VisibilityOnIcon, VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

// Messages
import messages from 'messages.json';

// Services
import { login } from 'services/requests';
import { setToken, isAuthenticated } from 'services/storage';
import { isValidEmail } from 'services/helpers';

// Components
import InputText from 'components/input-text';

// Views
import { handleCreateSubscription } from '../purchase-registration';

// -------------------------------------------------------------------------------------- \\
// ----------------------------------- LOGIN CONTAINER ---------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const LoginContainer = () => {

    if (isAuthenticated()) {
        return <Redirect to="/" />;
    }

    return (
        <Grid
            container
            className="unauthenticated"
        >
            <Grid
                item xs={false} sm={1} md={2} lg={4}
            />
            <Grid
                item xs={12} sm={10} md={8} lg={4}
                container alignItems="center"
            >
                <Login />
            </Grid>
        </Grid>
    );
};

// -------------------------------------------------------------------------------------- \\
// ---------------------------------------- LOGIN --------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

export const Login = () => {
    const history = useHistory();
    const { pathname, search } = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        email: new URLSearchParams(search).get('email') || '',
        password: '',
        password_type: 'password',
        remember: false,
        forgotPassword: false,
    });

    function handleRemember() {
        setState({ ...state, remember: !state.remember });
    }

    function onLogin() {
        if (state.email === '' || !isValidEmail(state.email)) {
            enqueueSnackbar(messages['email.no.valid'], { variant: 'warning' });
        }
        else if (state.password === '') {
            enqueueSnackbar(messages['password.missing'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            login({ email: state.email, password: state.password }).then(res => {
                if (res.status === 200) {

                    setToken(res.data.data, state.remember);

                    if (pathname.includes('/purchase-registration')) {
                        handleCreateSubscription(enqueueSnackbar, history, res.data.data.user);
                    }
                    else {
                        history.push('/');
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'warning' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div
            className="unauthenticated-container"
            style={{ margin: '24px 12px' }}
        >
            {/* ---------------------- TITLE ---------------------- */}
            <Grid
                container justify="center"
                style={{ padding: '24px 24px 0px 24px' }}
            >
                <span
                    className="mabd-font"
                    style={{ fontSize: 16, fontWeight: 600 }}
                >
                    CONNEXION
                </span>
            </Grid>
            <Grid style={{ padding: 24 }}>
                {/* ---------------------- EMAIL ---------------------- */}
                <div className="input-title">
                    Email
                </div>
                <div
                    className="input-group"
                    style={{ marginBottom: 24 }}
                >
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <AccountIcon />
                        </span>
                    </div>
                    <InputText
                        label="Email"
                        type="email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onLogin() : null}
                        autoComplete="on"
                    />
                </div>
                {/* ---------------------- PASSWORD ---------------------- */}
                <div className="input-title">
                    Mot de passe
                </div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <PasswordIcon />
                        </span>
                    </div>
                    <IconButton
                        onClick={() => setState({ ...state, password_type: state.password_type === 'password' ? 'text' : 'password' })}
                        tabIndex={3}
                        id="password-icon"
                    >
                        {state.password_type === 'text' ? (
                            <VisibilityOnIcon />
                        ) : (
                            <VisibilityOffIcon />
                        )}
                    </IconButton>
                    <InputText
                        label="Mot de passe"
                        type={state.password_type}
                        value={state.password}
                        onChange={e => setState({ ...state, password: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onLogin() : null}
                        autoComplete="on"
                    />
                </div>
                {/* ---------------------- REMEMBER ---------------------- */}
                <Grid
                    container justify="center" alignItems="center"
                    style={{ marginTop: 10 }}
                >
                    <Checkbox
                        tabIndex={4}
                        color="primary"
                        checked={state.remember}
                        onKeyPress={e => e.key === 'Enter' ? handleRemember() : null}
                        onChange={handleRemember}
                    />
                    Rester connecté
                </Grid>
                {/* ---------------------- LOGIN BUTTON ---------------------- */}
                <Grid
                    container justify="center"
                    style={{ marginTop: 10 }}
                >
                    <Button
                        onClick={onLogin}
                        disabled={state.loading}
                        endIcon={state.loading ? <CircularProgress size={20} color="inherit"/> : <></>}
                        variant="contained" color="primary"
                    >
                        Se connecter
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            {/* ---------------------- REGISTER BUTTON ---------------------- */}
            <Grid
                container justify="space-between"
                style={{ padding: 12 }}
            >
                <Button
                    onClick={() => history.push('/register')}
                    disabled={state.loading}
                    style={{ textTransform: 'none' }}
                >
                    Pas de compte ? Créer un compte ici
                </Button>
                <Button
                    onClick={() => history.push('/forgotten-password?email=' + state.email)}
                    disabled={state.loading}
                    style={{ textTransform: 'none' }}
                >
                    Mot de passe oublié ?
                </Button>
            </Grid>
        </div>
    );
};

export default LoginContainer;
