import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, CircularProgress } from '@material-ui/core';

// Services
import { createFormationRequest } from 'services/requests';
import { isAuthenticated, setStoredSubscription  } from 'services/storage';
import { amountToSend, isValidAmount } from 'services/helpers';

// Messages
import messages from 'messages.json';

// -------------------------------------------------------------------------------------- \\
// ------------------------------- FORMATION REQUEST SUBMIT ----------------------------- \\
// -------------------------------------------------------------------------------------- \\

const FormationRequestSubmit = ({ state }) => {
    const history = useHistory();
    const { userId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    function onSubmit() {
        if (state.session_price == null) {
            enqueueSnackbar('Veuillez choisir une formule', { variant: 'warning' });
        }
        else if (
            state.phone === ''
            && state.mobile_phone === ''
        ) {
            enqueueSnackbar('Veuillez renseigner au moins 1 numéro de téléphone', { variant: 'warning' });
        }
        else if (
            state.session_price.require_birth_date
            && state.birth_date == null
        ) {
            enqueueSnackbar('Veuillez renseigner votre date de naissance', { variant: 'warning' });
        }
        else if (
            state.session_price.require_company_name
            && state.company_name === ''
        ) {
            enqueueSnackbar('Veuillez renseigner le nom de votre entreprise ou de votre employeur', { variant: 'warning' });
        }
        else if (
            state.contribution_amount !== ''
            && !isValidAmount(state.contribution_amount)
        ) {
            enqueueSnackbar('Veuillez saisir un montant de cotisation valide', { variant: 'warning' });
        }
        else if (
            state.first_name === ''
            || state.last_name === ''
            || state.address === ''
            || state.postal_code === ''
            || state.city === ''
            || state.country_id === -1
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!state.cgv || !state.pdc) {
            enqueueSnackbar('Veuillez accepter les CGV et la politique de confidentialité', { variant: 'warning' });
        }
        else {
            setLoading(true);
        }
    }

    useEffect(() => {
        if (loading) {

            const data = {
                message: state.message,
                session_price_id: state.session_price.id,
                option_ids: state.options.map(option => option.id),
                first_name: state.first_name,
                last_name: state.last_name,
                company_name: state.company_name,
                address: state.address,
                address_details: state.address_details,
                city: state.city,
                postal_code: state.postal_code,
                country_id: state.country_id,
                email: state.email,
                contribution_amount: amountToSend(state.contribution_amount),
                payment_method: 'cb',
                phone: state.phone,
                mobile_phone: state.mobile_phone,
                birth_date: state.birth_date ? moment(state.birth_date).format('YYYY-MM-DD') : null,
                membership_id: state.membership?.id || null,
                membership_type_id: state.membership_type?.id || null,
            };

            if (isAuthenticated()) {
                data.user_id = userId;
                createFormationRequest(state.formationSession.id, data).then(res => {
                    if (res.status === 200) {
                        enqueueSnackbar(res.data.message, { variant: 'success' });
                        history.push('/payment-method/' + res.data.data.bill.id);
                    }
                    else {
                        enqueueSnackbar(res, { variant: 'error' });
                        setLoading(false);
                    }
                });
            }
            else {
                data.formationSessionId = state.formationSession?.id;
                setStoredSubscription(data);
                history.push('/purchase-registration');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Grid
            container justify="center"
            style={{ padding: '24px 0px' }}
        >
            <Button
                onClick={() => onSubmit()}
                endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                disabled={loading}
                variant="contained"
            >
                Valider mon inscription
            </Button>
        </Grid>
    );
};

export default FormationRequestSubmit;
