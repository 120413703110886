import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { createMagazine } from 'services/requests';
import { amountToSend, isValidAmount } from 'services/helpers';

// Views
import MagazineForm from './magazines-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- CREATE MAGAZINE -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const CreateMagazine = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: '',
        description: '',
        number: '',
        publication_date: null,
        start_date: null,
        end_date: null,
        price: '',
        is_special_issue: '0',
        period: -1,
        status: -1,
        uploaded_file: null,
    });

    function onCreate() {
        if (state.name === '' || state.status === -1) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (!isValidAmount(state.price)) {
            enqueueSnackbar('Veuillez saisir un montant valide', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            const formData = new FormData();

            formData.append('name', state.name);
            formData.append('description', state.description);
            formData.append('number', state.number);
            formData.append('publication_date', state.publication_date ? moment(state.publication_date).format('YYYY-MM-DD') : '');
            formData.append('start_date', state.start_date ? moment(state.start_date).format('YYYY-MM-DD') : '');
            formData.append('end_date', state.end_date ? moment(state.end_date).format('YYYY-MM-DD') : '');
            formData.append('price', amountToSend(state.price).toString());
            formData.append('is_special_issue', state.is_special_issue);
            formData.append('period', state.period !== -1 ? state.period : '');
            formData.append('status', state.status !== -1 ? state.status : '');

            const file = document.getElementById('upload-magazine').files[0];;
            if (file) {
                formData.append('uploaded_file', file);
            }

            createMagazine(formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Ajouter une revue
                </p>
                {/* -------------------- FORM -------------------- */}
                <MagazineForm
                    state={state}
                    setState={setState}
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Annuler
                </Button>
                <Button onClick={onCreate} variant="contained">
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateMagazine;
