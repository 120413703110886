import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid, CircularProgress } from '@material-ui/core';
// import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

// Services
import { updateSubscription } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { getGeocode } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/input-text';
import DatePicker from 'components/date-picker';
import FieldRequired from 'components/field-required';

// Views
import ConfirmUpdateAddress from 'views/common/confirm-update-address';
import AddressForm from 'views/common/address-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- UPDATE SUBSCRIPTION ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------------- \\

const UpdateSubscription = ({ onClose, refetch, subscription }) => {
    let storedUser = getStoredUser();
    let storedUserIsAdmin = (storedUser && storedUser.role && storedUser.role.id === 1) ? true : false;
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        subscription_type_id: subscription.subscriptionType.id,
        start_date: subscription.start_date || null,
        end_date: subscription.end_date || null,
        first_name: subscription.first_name || '',
        last_name: subscription.last_name || '',
        email: subscription.email || '',
        company_name: subscription.company_name || '',
        address: subscription.address || '',
        address_details: subscription.address_details || '',
        postal_code: subscription.postal_code || '',
        city: subscription.city || '',
        country_id: subscription.country_id || null,
        geocode: getGeocode(subscription),
        is_alterrenat: subscription.is_alterrenat || 0,
        openConfirmUpdateAddress: false,
        update_user_address: false,
        update_bill_address: false,
    });

    const onUpdate = () => {
        if (
            storedUserIsAdmin
            && (state.start_date == null || state.end_date == null)
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (
            state.first_name === ''
            || state.last_name === ''
            || state.address === ''
            || state.postal_code === ''
            || state.city === ''
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (
            subscription.address !== state.address
            || subscription.address_details !== state.address_details
            || subscription.postal_code !== state.postal_code
            || subscription.city !== state.city
        ) {
            setState(prevState => ({ ...prevState, openConfirmUpdateAddress: true }));
        }
        else {
            setState({ ...state, loading: true });
        }
    };
    
    useEffect(() => {
        if (state.loading) {

            const data = {
                subscription_type_id: state.subscription_type_id,
                first_name: state.first_name,
                last_name: state.last_name,
                email: state.email,
                company_name: state.company_name,
                address: state.address,
                address_details: state.address_details,
                postal_code: state.postal_code,
                city: state.city,
                is_alterrenat: state.is_alterrenat,
                update_user_address: state.update_user_address,
                update_bill_address: state.update_bill_address,
            };

            if (storedUserIsAdmin) {
                data.start_date = moment(state.start_date).format('YYYY-MM-DD');
                data.end_date = moment(state.end_date).format('YYYY-MM-DD');
            };

            updateSubscription(subscription.id, data).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Dialog
                open
                fullWidth
                fullScreen={!mediaMD}
            >
                <DialogContent style={{ paddingBottom: 20 }}>
                    <p className="dialog-title">
                        Modifier abonnement #{subscription?.id}
                    </p>
                    {storedUserIsAdmin && (
                        <>
                            {/* ---------------------- START DATE ---------------------- */}
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    Date de début<FieldRequired />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <DatePicker
                                        value={state.start_date}
                                        onChange={start_date => setState({ ...state, start_date })}
                                        outlined
                                    />
                                </Grid>
                            </Grid>
                            {/* ---------------------- END DATE ---------------------- */}
                            <Grid
                                container alignItems="center"
                                style={{ marginTop: 12 }}
                            >
                                <Grid item xs={12} sm={4}>
                                    Date de fin<FieldRequired />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <DatePicker
                                        value={state.end_date}
                                        onChange={end_date => setState({ ...state, end_date })}
                                        outlined
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {/* ---------------------- FIRST NAME ---------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 12 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Prénom<FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Prénom"
                                value={state.first_name}
                                onChange={e => setState({ ...state, first_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* ---------------------- LAST NAME ---------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 12 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Nom<FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom"
                                value={state.last_name}
                                onChange={e => setState({ ...state, last_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* ---------------------- EMAIL ---------------------- */}
                    <Grid container alignItems="center" style={{ marginTop: 12 }}>
                        <Grid item xs={12} sm={4}>
                            Email abonnement{state.subscription_type_id === 1 && <FieldRequired />}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Email abonnement"
                                value={state.email}
                                onChange={e => setState({ ...state, email: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* ---------------------- COMPANY NAME ---------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 12 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Nom de l'entreprise
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom de l'entreprise"
                                value={state.company_name}
                                onChange={e => setState({ ...state, company_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* ---------------------- ADDRESS FORM ---------------------- */}
                    <AddressForm
                        state={state}
                        setState={setState}
                        requiredAddress
                        requiredPostalCode
                        requiredCity
                        disabledDepartment
                        disabledCountry
                    />
                    {/* ---------------------- COUNTRY ---------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 12 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Pays
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {subscription.country?.name}
                        </Grid>
                    </Grid>
                    {/* ---------------------- IS ALTERRENAT ---------------------- */}
                    {/* <Grid
                        container alignItems="center"
                        style={{ marginTop: 12 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Alterrenat
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <ToggleButtonGroup
                                value={state.is_alterrenat}
                                onChange={(e, value) => setState({ ...state, is_alterrenat: value != null ? value : state.is_alterrenat })}
                                exclusive size="small"
                                style={{ width: '100%' }}
                            >
                                <ToggleButton
                                    value={0}
                                    disabled={!(storedUser?.role?.id === 1)}
                                    style={{ width: '50%' }}
                                >
                                    Non
                                </ToggleButton>
                                <ToggleButton
                                    value={1}
                                    disabled={!(storedUser?.role?.id === 1)}
                                    style={{ width: '50%' }}
                                >
                                    Oui
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid> */}
                </DialogContent>
                <Divider />
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button
                        onClick={onClose}
                        disabled={state.loading}
                        variant="contained"
                    >
                        Fermer
                    </Button>
                    <Button
                        onClick={onUpdate}
                        disabled={state.loading}
                        endIcon={state.loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                        variant="contained"
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
            {/* -------------------- CONFIRM UPDATE ADDRESS -------------------- */}
            {state.openConfirmUpdateAddress && (
                <ConfirmUpdateAddress
                    state={state}
                    setState={setState}
                    onSubmit={() => setState(prevState => ({ ...prevState, openConfirmUpdateAddress: false, loading: true }))}
                />
            )}
        </>
    );
};

export default UpdateSubscription;
