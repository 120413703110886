import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { updateDayProgram, removeDayProgram } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// Views
import DayProgramForm from './day-programs-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- UPDATE DAY PROGRAM ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const UpdateDayProgram = ({ onClose, refetch, day_program }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        start_time: day_program.start_time ? day_program.start_time.slice(0, 5) : '',
        end_time: day_program.end_time ? day_program.end_time.slice(0, 5) : '',
        duration: day_program.duration || '',
        description: day_program.description || '',
        openDelete: false,
    });

    function onUpdate() {
        if (state.start_time === '' || state.end_time === '' || state.duration === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateDayProgram(day_program.id, {
                start_time: state.start_time,
                end_time: state.end_time,
                duration: state.duration,
                description: state.description,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <>
            <Dialog open fullWidth>
                {state.loading ? (
                    <Spinner />
                ) : (
                    <>
                        <DialogContent style={{ paddingBottom: 20 }}>
                            <p className="dialog-title">
                                Modifier le programme
                            </p>
                            {/* -------------------- FORM -------------------- */}
                            <DayProgramForm
                                state={state}
                                setState={setState}
                            />
                            {/* -------------------- DELETE -------------------- */}
                            <Grid container alignItems="center" style={{ marginTop: 24 }}>
                                <Grid item xs={12} sm={4}>
                                    Supprimer ce programme
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Button
                                        onClick={() => setState({ ...state, openDelete: true })}
                                        variant="contained" color="secondary"
                                    >
                                        Supprimer
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions style={{ justifyContent: 'space-between' }}>
                            <Button onClick={onClose} variant="contained">
                                Annuler
                            </Button>
                            <Button onClick={onUpdate} variant="contained">
                                Enregistrer
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            {/* -------------------- DELETE DAY PROGRAM -------------------- */}
            {state.openDelete && (
                <DeleteDayProgram
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => refetch()}
                    day_program={day_program}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- DELETE DAY PROGRAM ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const DeleteDayProgram = ({ onClose, refetch, day_program }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            removeDayProgram(day_program.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Supprimer
                        </p>
                        Êtes-vous sûr de vouloir supprimer ce programme ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateDayProgram;
