import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services
import { cancelParticipation } from 'services/requests';

// Components
import InputText from 'components/input-text';
import Spinner from 'components/spinner';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------ CANCEL PARTICIPATION ------------------------------------ \\
// ---------------------------------------------------------------------------------------------- \\

const CancelParticipation = ({ onClose, refetch, formationRequest }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        cancel_reason: '',
    });

    function onValidate() {
        if (state.cancel_reason === '') {
            enqueueSnackbar('Veuillez saisir la raison de votre annulation', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            cancelParticipation(formationRequest.id, { cancel_reason: state.cancel_reason }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Annuler la formation #{formationRequest.formationSession.number} : {formationRequest.formationTitle}
                            <br />
                            {formationRequest.user.first_name} {formationRequest.user.last_name}
                        </p>
                        Êtes-vous sûr de vouloir annuler la formation ?
                        <Grid container style={{ marginTop: 10 }}>
                            <InputText
                                multiline
                                label="Veuillez saisir la raison de l'annulation..."
                                value={state.cancel_reason}
                                onChange={e => setState({ ...state, cancel_reason: e.target.value })}
                            />
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={onValidate} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CancelParticipation;
