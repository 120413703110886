import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';

// Services
import { deleteFormation } from 'services/requests';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- DELETE FORMATION --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

export default function DeleteFormation({ onClose, formation }) {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteFormation(formation.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/management/formations');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    {formation.title}
                </p>
                Êtes-vous sûr de vouloir supprimer ce modèle de formation ?
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Non
                </Button>
                <Button onClick={() => setLoading(true)} variant="contained">
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    );
}
