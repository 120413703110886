import React, { useState } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Eject as UploadIcon } from '@material-ui/icons';

// Messages
import messages from '../messages.json';

/* * *
* SÉLECTION DE FICHIERS : TAILLE MAXIMUM = 25 Mo
* Modifiable sous condition d'être en cohérence avec la taille de fichier sur le serveur
*/

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- INPUT FILE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const InputFile = ({ inputId }) => {

    const { enqueueSnackbar } = useSnackbar();
    const [fileName, setFileName] = useState(undefined);

    function onChange(e) {
        // Max size file : 25 Mo (soit 25 000 000 bytes)
        if (document.getElementById('upload-' + inputId).files[0].size >= 25000000) {
            enqueueSnackbar(messages['file.max.size'], { variant: 'warning' });
        }
        else {
            const fileName = e.target.value;
            setFileName(fileName.replace(/^.*[\\/]/, ''));
        }
    }

    return (
        <div className="div-input-file">
            <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                    htmlFor={'upload-' + inputId}
                    className="label-input-file"
                >
                    <span>Sélectionner...</span>
                    <UploadIcon
                        style={{ fontSize: 20 }}
                    />
                </label>
                <input
                    id={'upload-' + inputId}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={e => onChange(e)}
                />
            </div>
            <span style={{ margin: 5 }}>
                {fileName}
            </span>
        </div>
    );
};

export default InputFile;
