import React, { useState } from 'react';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';

// Material-UI
import { Grid, IconButton, Drawer } from '@material-ui/core';
import { Apps as MenuIcon } from '@material-ui/icons';

// Services
import { getStoredUser } from 'services/storage';
import { userCanAccessToUserList, userCanAccessToGroups, userCanAccessToManagement } from 'services/permissions';
import useMediaQueries from 'services/media';

// Components
import Tooltip from 'components/tooltip';

// Views
import MenuItems from './menu-items';
import Profile from '../profile';
import Logout from './logout';
import Dashboard from '../dasboard';
import FederatedAssociations from '../federated-associations';
import Domains from '../domains';
import Users from '../users';
import Groups from '../groups';
import Formations from '../formations';
import Shop from '../shop';
import Management from '../management';
import PaymentMethod from '../common/payment-method';
import MoneticoCallback from '../common/monetico-callback';
import ConsultingVisits from '../management/consulting-visits';

// Assets
import LogoMABD from 'assets/images/logo-mabd-trans.png';
import ProfileIcon from 'assets/images/profile.png';
import LogoutIcon from 'assets/images/logout.png';

// -------------------------------------------------------------------------------------- \\
// ---------------------------------------- MENU ---------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const Menu = () => {
    let storedUser = getStoredUser();
    const history = useHistory();
    const { mediaLG } = useMediaQueries();
    const [openLogout, setOpenLogout] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const iconProps = {
        width: 'auto',
        height: 40,
    };

    return (
        <>
            <Grid
                container alignItems="center"
                className="menu"
            >
                <Grid
                    item xs={2} lg={10}
                    container alignItems="center"
                >
                    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', height: 73 }}>
                        <Tooltip title="Site www.bio-dynamie.org"
                            item={(
                                <a
                                    href="https://www.bio-dynamie.org/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img
                                        alt="logo-mabd"
                                        src={LogoMABD}
                                        style={{ height: 73, width: 'auto' }}
                                        // style={{ height: 'auto', width: 135}}
                                    />
                                </a>
                            )}
                        />
                    </div>
                    {mediaLG && (
                        <MenuItems />
                    )}
                </Grid>
                <Grid
                    item xs lg={2}
                    container justify="flex-end"
                    style={{ padding: '0px 10px' }}
                >
                    {/* -------------------- MENU ICON -------------------- */}
                    {!mediaLG && (
                        <IconButton onClick={() => setOpenDrawer(true)}>
                            <MenuIcon style={{ color: 'white', fontSize: 26 }} />
                        </IconButton>
                    )}
                    {/* -------------------- PROFILE -------------------- */}
                    <Tooltip title="Profil"
                        item={(
                            <IconButton onClick={() => history.push('/profile')}>
                                <img
                                    alt="profile-icon"
                                    src={ProfileIcon}
                                    style={iconProps}
                                />
                            </IconButton>
                        )}
                    />
                    {/* -------------------- LOGOUT -------------------- */}
                    <Tooltip title="Se déconnecter"
                        item={(
                            <IconButton onClick={() => setOpenLogout(true)}>
                                <img
                                    alt="logout-icon"
                                    src={LogoutIcon}
                                    style={iconProps}
                                />
                            </IconButton>
                        )}
                    />
                </Grid>
            </Grid>
            {/* -------------------- ROUTES -------------------- */}
            <Switch>
                <Route exact path="/profile">
                    <Profile />
                </Route>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>
                <Route path="/federated-associations">
                    <FederatedAssociations />
                </Route>
                <Route path="/domains">
                    <Domains />
                </Route>
                {userCanAccessToUserList() && (
                    <Route path="/users">
                        <Users />
                    </Route>
                )}
                {userCanAccessToGroups() && (
                    <Route path="/groups">
                        <Groups />
                    </Route>
                )}
                <Route path="/formations">
                    <Formations />
                </Route>
                <Route path="/shop">
                    <Shop />
                </Route>
                {userCanAccessToManagement() && (
                    <Route path="/management">
                        <Management />
                    </Route>
                )}
                {storedUser?.hasVisitsAsContact && (
                    <Route path="/consulting-visits">
                        <ConsultingVisits />
                    </Route>
                )}
                <Route exact path="/payment-method/:billId">
                    <PaymentMethod />
                </Route>
                <Route path="/monetico_callback">
                    <MoneticoCallback />
                </Route>
                <Redirect to="/dashboard" />
            </Switch>
            {/* -------------------- LOGOUT MODAL -------------------- */}
            {openLogout && (
                <Logout
                    onClose={() => setOpenLogout(false)}
                />
            )}
            {/* -------------------- SIDE MENU -------------------- */}
            {(!mediaLG && openDrawer) && (
                <Drawer
                    open
                    onClose={() => setOpenDrawer(false)}
                    anchor="left"
                >
                    <MenuItems
                        fromSideDrawer
                        onClose={() => setOpenDrawer(false)}
                    />
                </Drawer>
            )}
        </>
    );
};

export default Menu;
