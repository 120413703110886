import React from 'react';

/* * *
* L'élément HTML textarea est utilisé (à la place d'une input text) si l'on souhaite un champ de saisie à plusieurs lignes
*/

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- INPUT TEXT ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const InputText = ({ label, type, value, onChange, multiline, disabled, onKeyPress, autoComplete }) => {
    return (
        <>
            {multiline ? (
                <textarea
                    className="form-control"
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    style={{ fontSize: 14, height: 80 }}
                />
            ) : (
                <input
                    className="form-control"
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    style={{ fontSize: 14 }}
                    onKeyPress={onKeyPress}
                    min={type === 'number' ? 0 : undefined}
                    type={type}
                    autoComplete={autoComplete}
                    auto
                />
            )}
        </>
    );
};

// Propriétés par défaut
InputText.defaultProps = {
    type: 'text',
    multiline: false,
    disabled: false,
    autoComplete: 'off',
};

export default InputText;
