import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// HTML React Parser
import parse from 'html-react-parser';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Paper, Divider, IconButton, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { AddCircle as AddIcon } from '@material-ui/icons';

// Services
import { getMembershipTypes, getUser } from 'services/requests';
import { isAuthenticated, getStoredUser } from 'services/storage';
import { getAmountTTC } from 'services/helpers';
import { fileUrl } from 'services/axios-config';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Tooltip from 'components/tooltip';
import FieldRequired from 'components/field-required';

// Assets
import ImgMABD from 'assets/images/logo-mabd.jpg';

/* * *
* Image dimension
*/
const ImgWidth = 260;

// ------------------------------------------------------------------------------------------------ \\
// --------------------------------------- MEMBERSHIP TYPES --------------------------------------- \\
// ------------------------------------------------------------------------------------------------ \\

const MembershipTypes = () => {
    let storedUserId = getStoredUser()?.id;
    const history = useHistory();
    const { userId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        membership_types: [],

        // Infos Modal
        openDescription: false,
        membership_type: null,

        // Current Membership
        currentYearMembership: null,
    });

    function getPushUrl(membership_type) {
        if (userId) {
            return '/users/' + userId + '/shop/membership/' + membership_type.id;
        }
        return '/shop/' + membership_type.id + '/membership/' + storedUserId;
    }

    function openModalInfos(event, membership_type) {
        event.stopPropagation();
        setState({ ...state, openDescription: true, membership_type });
    }

    function hasDiscount() {
        if (
            state.currentYearMembership
            && state.currentYearMembership?.membershipType?.is_pro === 0
            && state.currentYearMembership?.status === 'validated'
            && (moment().format('MM-DD') < '11-01')
        ) {
            return true;
        }
        return false;
    }

    function getAmount(membership_type) {
        if (membership_type.is_pro === 1 && hasDiscount()) {
            let discount = (membership_type.price - state.currentYearMembership?.membershipType?.price);
            return getAmountTTC(discount) + ' TTC*';
        }
        return getAmountTTC(membership_type.price) + ' TTC';
    }

    function fetchUser() {
        getUser(userId || storedUserId).then(res => {
            if (res.status === 200) {
                const user = res.data.data.user;
                fetchMembershipTypes(user ? user.currentYearMembership : null);
            }
            else {
                fetchMembershipTypes();
            }
        });
    }

    function fetchMembershipTypes(currentYearMembership) {
        getMembershipTypes({ only_available: 1 }).then(res => {
            if (res.status === 200) {
                setState({
                    ...state,
                    loading: false,
                    membership_types: res.data.data.membershipTypes,
                    currentYearMembership,
                });
            }
            else {
                console.log(res);
                setState({ ...state, loading: false, error: true });
            }
        });
    }

    useEffect(() => {
        if (state.loading) {
            if (isAuthenticated() && (userId || storedUserId)) {
                fetchUser();
            }
            else {
                fetchMembershipTypes();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <div
                className="main-container"
                style={{ padding: 24 }}
            >
                <Grid style={{ fontSize: 16 }}>
                    Adhésions au MABD
                </Grid>
                <div style={{ padding: 24 }}>
                    Le Mouvement de l’Agriculture Bio-Dynamique (MABD) est une association française qui œuvre pour la fédération
                    des différents acteurs de la biodynamie francophone, le partage de l’information et une approche sensible du vivant.<br />
                    La fédération organise des rencontres locales et nationales ; propose des formations aux professionnels et aux particuliers ;
                    publie des livres et des articles ainsi qu’un calendrier biodynamique annuel et une revue trimestrielle <em>Biodynamis</em>.<br />
                    Le MABD a pour vocation de développer la biodynamie avec tous les producteurs, jardiniers,
                    consommateurs et associations impliqués.<br />
                    Adhérez à l’association et soutenez les acteurs du développement de la biodynamie !
                </div>
                {/* -------------------- MEMBERSHIP TYPE LIST -------------------- */}
                <Grid
                    container spacing={3}
                    style={{ padding: 12 }}
                >
                    {state.membership_types.map(membership_type => (
                        <Grid
                            key={membership_type.id}
                            item xs={12} sm="auto"
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <Paper
                                onClick={() => history.push(getPushUrl(membership_type))}
                                className="simple-list"
                                elevation={3}
                                style={{ borderRadius: 8, maxWidth: ImgWidth }}
                            >
                                {/* -------------------- IMAGE -------------------- */}
                                <Grid
                                    container justify="center" alignItems="center"
                                    style={{ height: ImgWidth }}
                                >
                                    <img
                                        alt="membership/img"
                                        src={membership_type.image_url ? (fileUrl + membership_type.image_url) : ImgMABD}
                                        style={{ width: ImgWidth, height: 'auto', maxHeight: ImgWidth }}
                                    />
                                </Grid>
                                <Divider />
                                {/* -------------------- NAME & AMOUNT -------------------- */}
                                <div style={{ textAlign: 'center', padding: 12 }}>
                                    {membership_type.is_pro === 0 && (
                                        <b>{membership_type.name}</b>
                                    )}
                                    {membership_type.is_pro === 1 && (
                                        <>
                                            <b>Adhésion professionnelle</b>
                                            <br />
                                            <span>
                                                {membership_type.name}
                                            </span>
                                        </>
                                    )}
                                    <br />
                                    <span style={{ fontSize: 16, fontWeight: 600, color: 'gray' }}>
                                        {getAmount(membership_type)}
                                    </span>
                                </div>
                                {/* -------------------- DEFINITION -------------------- */}
                                {membership_type.definition && (
                                    <div
                                        className="text"
                                        style={{ padding: '0px 12px 12px 12px' }}
                                    >
                                        {membership_type.definition}
                                    </div>
                                )}
                                {/* -------------------- DESCRIPTION -------------------- */}
                                {membership_type.description && (
                                    <Grid container justify="flex-end">
                                        <Tooltip title="Informations sur l'adhésion"
                                            item={(
                                                <IconButton
                                                    onClick={e => openModalInfos(e, membership_type)}
                                                    style={{ padding: 4 }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            )}
                                        />
                                    </Grid>
                                )}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
                {hasDiscount() && (
                    <p style={{ fontStyle: 'italic', fontWeight: 600 }}>
                        <FieldRequired /> Vous bénéficiez d'une réduction de {getAmountTTC(state.currentYearMembership?.membershipType?.price)} car vous avez déjà une adhésion valide en cours qui sera remplacée
                    </p>
                )}
            </div>
            {/* -------------------- DESCRIPTION -------------------- */}
            {state.openDescription && (
                <MembershipTypeDescription
                    onClose={() => setState({ ...state, openDescription: false })}
                    membership_type={state.membership_type}
                />
            )}
        </>
    );
};

// ------------------------------------------------------------------------------------------------ \\
// ------------------------------------ MEMBERSHIP DESCRIPTION ------------------------------------ \\
// ------------------------------------------------------------------------------------------------ \\

const MembershipTypeDescription = ({ onClose, membership_type }) => {
    return (
        <Dialog
            open
            fullWidth
        >
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    {membership_type.name}
                </p>
                <div className="text">
                    {parse(membership_type.description)}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant="contained"
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MembershipTypes;
