import React, { useState, useEffect } from 'react';

// Services
import { consultingVisitEquipments } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------- SELECT CONSULTING VISIT EQUIPMENTS ----------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectConsultingVisitEquipments = ({ equipments, setEquipments }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(consultingVisitEquipments.map(s => ({ label: s.label, value: s.value })));
    }, []);

    return (
        <SelectMultiple
            value={equipments}
            onChange={value => setEquipments(value)}
            options={options}
            placeholder="Matériel"
        />
    );
};

export default SelectConsultingVisitEquipments;
