import React, { useState, useEffect } from 'react';

// Material-UI
import { MenuItem } from '@material-ui/core';

// Services
import { getFederatedAssociationList } from 'services/requests';

// Components
import SelectOutlined from 'components/select-outlined';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------- SELECT FEDERATED ASSOCIATION ------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectFederatedAssociation = ({ federatedAssociationId, setFederatedAssociationId }) => {
    const [federatedAssociations, setFederatedAssociations] = useState([]);

    useEffect(() => {
        getFederatedAssociationList().then(res => {
            if (res.status === 200) {
                setFederatedAssociations(res.data.data.federatedAssociations);
            }
        });
    }, []);

    return (
        <SelectOutlined
            value={federatedAssociationId}
            onChange={e => setFederatedAssociationId(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>Association fédérée</em>
            </MenuItem>
            {federatedAssociations.map(federatedAssociation => (
                <MenuItem
                    key={federatedAssociation.id}
                    value={federatedAssociation.id}
                >
                    {federatedAssociation.name}
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

export default SelectFederatedAssociation;
