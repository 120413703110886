import React, { useState, useEffect } from 'react';

// Axios
import axios from 'axios';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { createTrainingPlace } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// Views
import TrainingPlaceForm from './training-places-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ CREATE TRAINING PLACE ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const CreateTrainingPlace = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: '',
        address: '',
        address_details: '',
        city: '',
        postal_code: '',
        french_department_id: -1,
        country_id: 1,
        geocode: null,
        longitude: '',
        latitude: '',
        phone: '',
        mobile_phone: '',
        other_phone: '',
        email: '',
        other_email: '',
        contact: '',
    });

    function onCreate() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            if ((state.latitude === '' || state.longitude === '')
                && state.country_id === 1
                && state.address !== ''
                && state.postal_code !== ''
                && state.city !== ''
            ) {
                getCoordinates(state.address, state.postal_code, state.city);
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    }

    function getCoordinates(address, postal_code, city) {
        axios.get(
            `https://api-adresse.data.gouv.fr/search/?q=${address}
                &postcode=${postal_code}
                &city=${city}
                &limit=20`
        )
        .then(res => {
            if (res.status === 200) {
                const features = res.data.features;
                if (features && features.length > 0) {
                    setState({
                        ...state,
                        loading: true,
                        longitude: features[0].geometry.coordinates[0],
                        latitude: features[0].geometry.coordinates[1],
                    });
                }
                else {
                    setState({ ...state, loading: true });
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: true });
            }
        });
    }

    useEffect(() => {
        if (state.loading) {
            createTrainingPlace({
                name: state.name,
                address: state.address,
                address_details: state.address_details,
                city: state.city,
                postal_code: state.postal_code,
                french_department_id: state.french_department_id !== -1 ? state.french_department_id : null,
                country_id: state.country_id !== -1 ? state.country_id : null,
                longitude: state.longitude,
                latitude: state.latitude,
                phone: state.phone,
                mobile_phone: state.mobile_phone,
                other_phone: state.other_phone,
                email: state.email,
                other_email: state.other_email,
                contact: state.contact,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {(state.loading || state.loadingCoordinates) ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un lieu de formation
                        </p>
                        {/* -------------------- FORM -------------------- */}
                        <TrainingPlaceForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onCreate} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateTrainingPlace;
