import React from 'react';

// Moment
import moment from 'moment';

// HTML React Parser
import parse from 'html-react-parser';

// Material-UI
import { Grid } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';
import { fileUrl } from 'services/axios-config';

// Assets
import LogoMABD from 'assets/images/logo-mabd.jpg';

// ------------------------------------------------------------------------------------------- \\
// ----------------------------------- SUBSCRIPTION DETAILS ---------------------------------- \\
// ------------------------------------------------------------------------------------------- \\

const SubscriptionDetails = ({ subscriptionType, state }) => {
    const { mediaSM } = useMediaQueries();

    return (
        <Grid
            container alignItems="center"
            style={{ padding: 24 }}
        >
            {/* -------------------- SUBSCRIPTION IMAGE -------------------- */}
            <Grid
                item xs={12} sm={4}
                container justify="center"
                style={{ marginBottom: mediaSM ? 0 : 24 }}
            >
                {(
                    subscriptionType === 'membership'
                    && state.membership_type?.image_url
                ) && (
                    <img
                        alt="membership/img"
                        src={fileUrl + state.membership_type.image_url}
                        style={{ width: 300, height: 300 }}
                    />
                )}
                {(
                    subscriptionType === 'subscription'
                    && state.subscription_type?.image_url
                ) && (
                    <img
                        alt="subscription/img"
                        src={fileUrl + state.subscription_type.image_url}
                        style={{ width: 300, height: 500 }}
                    />
                )}
                {(
                    (
                        subscriptionType === 'membership'
                        && state.membership_type?.image_url == null
                    ) || (
                        subscriptionType === 'subscription'
                        && state.subscription_type?.image_url == null
                    )
                ) && (
                    <img
                        alt="mabd/logo"
                        src={LogoMABD}
                        style={{ width: 180, height: 92.16 }}
                    />
                )}
            </Grid>
            <Grid item xs={12} sm={8}>
                {/* -------------------- SUBSCRIPTION NAME -------------------- */}
                <div style={{ textTransform: 'uppercase', fontWeight: 600 }}> 
                    {subscriptionType === 'subscription' && (
                        state.subscription_type.name
                    )}
                    {subscriptionType === 'membership' && (state.membership_type.is_pro ? (
                        'Adhésion professionnelle : ' + state.membership_type.name
                    ) : (
                        state.membership_type.name
                    ))}
                </div>
                {/* -------------------- SUBSCRIPTION DESCRIPTION -------------------- */}
                {subscriptionType === 'subscription' && (
                    <div style={{ marginTop: 12 }}>
                        {parse(state.subscription_type.description)}
                    </div>
                )}
                {subscriptionType === 'membership' && (
                    <>
                        {parse(state.membership_type.description)}
                        {(moment().format('MM-DD') >= '11/01') && (
                            <div style={{ fontStyle: 'italic', fontWeight: 600, marginTop: 12 }}>
                                L'adhésion prise entre le 1er Novembre {moment().year()} et le 31 Décembre {moment().year()}&nbsp;
                                sera valable jusqu'au 31 Décembre {moment().add(1, 'y').year()}.<br />
                                Votre adhésion sera une adhésion {moment().add(1, 'y').year()}.
                            </div>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default SubscriptionDetails;
