import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getMagazines } from 'services/requests';
import { magazinePeriods, magazineStatuses } from 'services/constants';
import { getLabelStatus, getAmountTTC, getFormattedDate } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- MAGAZINES EXPORT -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const MagazinesExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getPeriodLabel(period) {
        const foundedPeriod = magazinePeriods.find(p => p.value === period);
        if (foundedPeriod) {
            return foundedPeriod.label;
        }
        return '';
    }

    function getAllMagazinesToExport(magazines, offset) {
        getMagazines({
            offset: offset,
            limit: data.limit,
            statuses: data.statuses ? data.statuses.map(s => s.value) : null,
            numbers: data.numbers ? data.numbers.map(n => n.value) : null,
            publication_date: data.publication_date,
            periods: data.periods ? data.periods.map(p => p.value) : null,
            name: data.name,
            price: data.price,
            summary: data.summary,
            publication_years: data.publication_years ? data.publication_years.map(y => y.value) : null,
        }).then(res => {
            if (res.status === 200) {

                let res_magazines = res.data.data.magazines;
                magazines = magazines.concat(res_magazines);

                setState({
                    ...state,
                    percentage: Math.round((100 * magazines.length) / data.total_count) + '%',
                });

                if (res_magazines.length < data.limit) {
                    createExcelFile(magazines);
                }
                else {
                    getAllMagazinesToExport(magazines, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(magazines) {
        if (magazines.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Revues');

            // Header
            worksheet.columns = [
                { header: 'Statut', key: 'status', width: 30 },
                { header: 'Numéro revue', key: 'number', width: 30 },
                { header: 'Date de publication', key: 'publication_date', width: 30 },
                { header: 'Période', key: 'period', width: 30 },
                { header: 'Nom du n°', key: 'name', width: 30 },
                { header: 'Prix', key: 'price', width: 30 },
                { header: 'Sommaire', key: 'summary', width: 30 },
                { header: 'Année de publication', key: 'publication_year', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            magazines.map(magazine => {
                return worksheet.addRow({
                    status: getLabelStatus(magazineStatuses, magazine.status),
                    number: magazine.number || '',
                    publication_date: magazine.publication_date ? getFormattedDate(magazine.publication_date) : '',
                    period: getPeriodLabel(magazine.period),
                    name: magazine.name || '',
                    price: getAmountTTC(magazine.price),
                    summary: magazine.description || '',
                    publication_year: magazine.publication_date ? magazine.publication_date.split('-')[0] : '',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'revues.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllMagazinesToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default MagazinesExport;
