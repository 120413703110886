import React, { useMemo, useEffect } from 'react';

// HTML React Parser
import parse from 'html-react-parser';

// Material-UI
import { Grid, Checkbox } from '@material-ui/core';
import { ArrowRightAlt as ArrowRight } from '@material-ui/icons';

// Services
import { getAmountTTC } from 'services/helpers';

// ----------------------------------------------------------------------------------------- \\
// ---------------------------------------- EXTRA ------------------------------------------ \\
// ----------------------------------------------------------------------------------------- \\

const Extra = ({ subscriptionType, state, setState }) => {

    const subscriptionTypes = useMemo(() => {
        if (subscriptionType === 'membership') {
            let subscriptionTypes = state.membership_type?.subscriptionTypes;
            if (state.country_id === 1) {
                subscriptionTypes = subscriptionTypes.filter(t => t.id === 1 || t.id === 4);
            }
            else {
                subscriptionTypes = subscriptionTypes.filter(t => t.id === 1 || t.id === 5);
            }
            return subscriptionTypes;
        }
        return [];
        // eslint-disable-next-line
    }, [state.country_id]);

    const getSubscriptionTypeAmount = (subscriptionType) => {

        if (subscriptionType.id === 1) {
            return getAmountTTC(subscriptionType.price);
        }

        let memberPriceFrance = state.membership_type?.subscriptionTypes?.find(t => t.id === 4)?.price;
        let memberPriceStranger = state.membership_type?.subscriptionTypes?.find(t => t.id === 5)?.price;

        return (
            <>
                <span style={{ textDecoration: 'line-through #565656 solid 2px' }}>
                    {(state.country_id === 1) ? (
                        getAmountTTC(state.nonMemberSubscriptionPrice)
                    ) : (
                        getAmountTTC(state.nonMemberSubscriptionPrice + (memberPriceStranger - memberPriceFrance))
                    )}
                </span>
                <span style={{ marginLeft: 4 }}>
                    TTC <ArrowRight /> tarif réduit adhérent {getAmountTTC(memberPriceFrance)} TTC
                </span>
                <span style={{ marginLeft: 4 }}>
                    / +{(memberPriceStranger - memberPriceFrance) / 100}€ TTC si étranger ou DOM-TOM
                </span>
            </>
        );
    };

    const handleCheckBox = checked => {

        /* * *
        * Select simple membership as extra per default when is a subscription
        */
        if (checked && subscriptionType === 'subscription') {
            let membership_type = state.subscription_type.membershipTypes?.find(t => t.id === 1);
            setState({
                ...state,
                extra: checked,
                extraType: membership_type,
                membership_type_id: membership_type?.id,
            });
        }
        else if (checked && subscriptionType === 'membership') {
            setState({ ...state, extra: checked });
        }
        else {
            switch (subscriptionType) {
                case 'subscription':
                    setState({
                        ...state,
                        extra: checked,
                        extraType: null,
                        membership_type_id: null,
                    });
                    break;

                case 'membership':
                    setState({
                        ...state,
                        extra: checked,
                        extraType: null,
                        subscription_type_id: null,
                    });
                    break;

                default:
                    break;
            }
        }
    };

    const handleExtra = extraType => {
        switch (subscriptionType) {
            case 'subscription':
                if (state.membership_type_id === extraType.id) {
                    setState(prevState => ({ ...prevState, extraType: null, membership_type_id: null }));
                }
                else {
                    setState(prevState => ({ ...prevState, extraType, membership_type_id: extraType.id }));
                }
                break;

            case 'membership':
                if (state.subscription_type_id === extraType.id) {
                    setState(prevState => ({ ...prevState, extraType: null, subscription_type_id: null }));
                }
                else {
                    setState(prevState => ({ ...prevState, extraType, subscription_type_id: extraType.id }));
                }
                break;
            
            default:
                break;
        }
    };

    useEffect(() => {
        if (subscriptionType === 'membership') {

            /* * *
            * Switch to foreigner subscription if france subscription was selected
            */
            if (
                state.country_id === 1
                && state.extraType !== null
                && state.subscription_type_id === 5
            ) {
                let subscriptionType = state.membership_type?.subscriptionTypes?.find(t => t.id === 4);
                setState(prevState => ({
                    ...prevState,
                    extraType: subscriptionType,
                    subscription_type_id: subscriptionType?.id,
                }));
            }

            /* * *
            * Switch to france subscription if foreigner subscription was selected
            */
            if (
                state.country_id !== 1
                && state.extraType !== null
                && state.subscription_type_id === 4
            ) {
                let subscriptionType = state.membership_type?.subscriptionTypes?.find(t => t.id === 5);
                setState(prevState => ({
                    ...prevState,
                    extraType: subscriptionType,
                    subscription_type_id: subscriptionType?.id,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.country_id]);

    return (
        <div
            className="extra-background"
            style={{ padding: '24px 0px' }}
        >
            <Grid container alignItems="center">
                <Checkbox
                    checked={state.extra}
                    onChange={e => handleCheckBox(e.target.checked)}
                    color="secondary"
                />
                <Grid item xs>
                    <span>
                        {subscriptionType === 'subscription' && (
                            <>
                                Je souhaite adhérer au MABD et à l'association fédérée régionale qui couvre mon territoire
                            </>
                        )}
                        {subscriptionType === 'membership' && (
                            <>
                                Je souhaite aussi m'abonner à la revue trimestrielle <em><b>Biodynamis</b></em>
                            </>
                        )}
                    </span>
                </Grid>
            </Grid>
            {subscriptionType === 'membership' && (
                <p style={{ margin: 12 }}>
                    Elle s'adresse à tous les publics qui font de la biodynamie d'aujourd'hui, débutants ou confirmés.
                    Au programme : reportages, gestes fondamentaux, approfondissements, portraits... entre champs et jardins,
                    jusqu'à nos assiettes.
                    <br />
                    <em>
                        L'abonnement comprend 5 revues : 1 numéro par saison et 1 hors-série.
                    </em>
                </p>
            )}
            {state.extra && (
                <div style={{ padding: 12 }}>
                    {/* * *
                    * MEMBERSHIP TYPES LINKED TO SUBSCRIPTION TYPE
                    */}
                    {subscriptionType === 'subscription' && (
                        <>
                            {state.subscription_type.membershipTypes.map((membershipType, index) => (
                                <Grid
                                    key={membershipType.id}
                                    onClick={() => handleExtra(membershipType)}
                                    className={membershipType.id === state.membership_type_id
                                        ? 'extra extra-selected'
                                        : 'extra'
                                    }
                                    style={{ padding: 12, marginTop: index > 0 ? 12 : undefined }}
                                >
                                    <Grid item xs={12}>
                                        <span style={{ textTransform: 'uppercase' }}>
                                            {membershipType.name} ({getAmountTTC(membershipType.price)})
                                        </span>
                                        <br/>
                                        <span>
                                            {parse(membershipType.description)}
                                        </span>
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    )}
                    {/* * *
                    * SUBSCRIPTION TYPES LINKED TO MEMBERSHIP TYPE
                    */}
                    {subscriptionType === 'membership' && (
                        <>
                            <div style={{ padding: 10, fontWeight: 600 }}>
                                Sélectionnez le type d'abonnement souhaité :
                            </div>
                            {subscriptionTypes.map((subscriptionType, index) => (
                                <Grid
                                    key={subscriptionType.id}
                                    onClick={() => handleExtra(subscriptionType)}
                                    container alignItems="center"
                                    className={subscriptionType.id === state.subscription_type_id
                                        ? 'extra extra-selected'
                                        : 'extra'
                                    }
                                    style={{ padding: 12, marginTop: index > 0 ? 12 : undefined }}
                                >
                                    <Grid item xs>
                                        <span style={{ textTransform: 'uppercase', fontSize: 16 }}>
                                            {subscriptionType.name}
                                        </span>
                                        <span style={{ marginLeft: 4, fontSize: 16 }}>
                                            ({getSubscriptionTypeAmount(subscriptionType)})
                                        </span>
                                        <br />
                                        <span className="text">
                                            {parse(subscriptionType.description)}
                                        </span>
                                    </Grid>
                                    {/* <Grid item xs={12} sm="auto">   
                                        <img
                                            alt="subscriptionType/img"
                                            src={getImage(subscriptionType.id)}
                                            style={{ width: 110, height: 154.05 }}
                                        />
                                    </Grid> */}
                                </Grid>
                            ))}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default Extra;
