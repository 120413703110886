import React from 'react';

// Material-UI
import { ChevronLeft as LowerIcon, ChevronRight as UpperIcon } from '@material-ui/icons';

/* * *
* Composant permettant de faire une recherche par comparaison (supérieur ou inférieur) au nombre saisi
*/

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- INPUT COMPARATOR -------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const InputComparator = ({ label, value, onChange, onKeyPress, comparator, onChangeComparator }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}
        >
                <div
                    onClick={onChangeComparator}
                    className="input-comparator"
                >
                    {comparator === '<' ? (
                        <LowerIcon />
                    ) : (
                        <UpperIcon />
                    )}
                </div>
                <input
                    className="form-control"
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    min={0}
                    type="Number"
                    style={{
                        fontSize: 14,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                />
        </div>
    );
};

export default InputComparator;
