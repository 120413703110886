import React from 'react';

// Material-UI
import { Grid, Checkbox } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// Views
import BillAddressForm from 'views/common/bill-address-form';

// ----------------------------------------------------------------------------------------- \\
// --------------------------------- DIFFERENT BILL ADDRESS -------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const DifferentBillAddress = ({ subscriptionType, state, setState }) => {
    return (
        <div style={{ padding: '24px 0px' }}>
            <Grid container alignItems="center">
                <Checkbox
                    checked={state.different_bill_address}
                    onChange={e => setState(prevState => ({ ...prevState, different_bill_address: e.target.checked }))}
                    color="secondary"
                />
                <span>
                    Choisir une adresse de facturation différente {subscriptionType === 'subscription' ? 'de mon adresse d\'abonnement' : ''}
                </span>
            </Grid>
            {state.different_bill_address && (
                <>
                    {/* -------------------- FIRST NAME -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            Prénom<FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Prénom"
                                value={state.bill_first_name}
                                onChange={e => setState({ ...state, bill_first_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- LAST NAME -------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Nom<FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom"
                                value={state.bill_last_name}
                                onChange={e => setState({ ...state, bill_last_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- EMAIL -------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Email facturation
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Email facturation"
                                value={state.bill_email}
                                onChange={e => setState({ ...state, bill_email: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- COMPANY NAME -------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Nom de l'entreprise
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom de l'entreprise"
                                value={state.bill_company_name}
                                onChange={e => setState({ ...state, bill_company_name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- ADDRESS -------------------- */}
                    <BillAddressForm
                        state={state}
                        setState={setState}
                        requiredAddress
                        requiredPostalCode
                        requiredCity
                        requiredCountry
                        disabledDepartment
                    />
                </>
            )}
        </div>
    );
};

export default DifferentBillAddress;
