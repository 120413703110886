import React from 'react';

// Components
import Asterisk from './field-required';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- TITLE ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Title = ({ children, required, titleProps }) => {
    return (
        <span
            className="text"
            style={{
                ...titleProps,
                position: 'relative',
                textTransform: 'uppercase',
                fontWeight: 600,
                fontSize: 12,
                color: '#565656',
            }}
        >
            {children}{required && <Asterisk props={{ position: 'absolute', marginLeft: 2, marginTop: -4 }} />}
        </span>
    );
};

export default Title;
