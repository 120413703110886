import React from 'react';

// Material-UI
import { Grid, RadioGroup, FormControlLabel, Typography, Radio } from '@material-ui/core';

// Views
import ConsultingVisitForm from 'views/management/consulting-visits/consulting-visit-form';

// ----------------------------------------------------------------------------------------- \\
// ------------------------------------ CONSULTING VISIT ----------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const ConsultingVisit = ({ state, setState }) => {
    return (
        <div style={{ padding: '24px 0px' }}>
            <Grid container alignItems="center">
                <div>
                    Je souhaite bénéficier gratuitement d’une visite conseil :
                </div>
                <div>
                    <RadioGroup
                        value={state.wants_visit}
                        onChange={e => setState({ ...state, wants_visit: e.target.value })}
                        style={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label={<Typography style={{ fontSize: 12 }}>Non</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={<Typography style={{ fontSize: 12 }}>Oui</Typography>}
                            style={{ margin: '0px 0px 0px 15px' }}
                        />
                    </RadioGroup>
                </div>
            </Grid>
            {state.wants_visit === '1' && (
                <ConsultingVisitForm
                    state={state}
                    setState={setState}
                />
            )}
        </div>
    );
};

export default ConsultingVisit;
