import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getBills } from 'services/requests';
import { billStatuses, paymentMethods, billStates } from 'services/constants';
import { getLabelStatus, getAmountTTC, getFormattedCreatedAt } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- BILLS EXPORT ------------------------------------------ \\
// --------------------------------------------------------------------------------------------------- \\

const BillsExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getPaymentMethods(array) {
        let result = '';
        array.forEach((item, index) => {
            if (index === 0) {
                result += getLabelStatus(paymentMethods, item);
            }
            else {
                result += ', ' + getLabelStatus(paymentMethods, item);
            }
        });
        return result;
    }

    function getAllBillsToExport(bills, offset) {
        getBills({
            offset: offset,
            limit: data.limit,
            statuses: data.statuses ? data.statuses.map(s => s.value) : null,
            client_code: data.client_code,
            external_client_code: data.external_client_code,
            last_name: data.last_name,
            first_name: data.first_name,
            company_name: data.company_name,
            reference: data.reference,
            total_amount: data.total_amount,
            rest: data.rest,
            external_reference: data.external_reference,
            pdf_bill: data.pdf_bill,
            pdf_credit: data.pdf_credit,
            email: data.email,
            mobile_phone: data.mobile_phone,
            phone: data.phone,
            address: data.address,
            address_details: data.address_details,
            country_ids: data.countries ? data.countries.map(c => c.value) : null,
            postal_codes: data.postal_codes ? data.postal_codes.map(c => c.value) : null,
            federated_association_ids: data.federated_associations ? data.federated_associations.map(a => a.value) : null,
            payment_methods: data.payment_methods ? data.payment_methods.map(m => m.value) : null,
            membership_email: data.membership_email,
            subscription_email: data.subscription_email,
            bill_email: data.bill_email,
            created_at: data.created_at,
            bill_state: data.bill_state,
            payment_recipient_id: data.payment_recipient_association,
            payment_creator: data.payment_creator,
        }).then(res => {
            if (res.status === 200) {

                let res_bills = res.data.data.bills;
                bills = bills.concat(res_bills);

                setState({
                    ...state,
                    percentage: Math.round((100 * bills.length) / data.total_count) + '%',
                });

                if (res_bills.length < data.limit) {
                    createExcelFile(bills);
                }
                else {
                    getAllBillsToExport(bills, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(bills) {
        if (bills.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Bons de commande');

            // Headers
            let columns = [];
            if (data.enabled_status) {
                columns.push({ header: 'Statut', key: 'status', width: 30 });
            }
            if (data.enabled_client_code) {
                columns.push({ header: 'Code client', key: 'client_code', width: 30 });
            }
            if (data.enabled_external_client_code) {
                columns.push({ header: 'Code client externe', key: 'external_client_code', width: 30 });
            }
            if (data.enabled_last_name) {
                columns.push({ header: 'Nom', key: 'last_name', width: 30 });
            }
            if (data.enabled_first_name) {
                columns.push({ header: 'Prénom', key: 'first_name', width: 30 });
            }
            if (data.enabled_company_name) {
                columns.push({ header: 'Nom de l\'entreprise', key: 'company_name', width: 30 });
            }
            if (data.enabled_reference) {
                columns.push({ header: 'Réf. bon de commande', key: 'reference', width: 30 });
            }
            if (data.enabled_total_amount) {
                columns.push({ header: 'Montant total TTC', key: 'total_amount', width: 30 });
            }
            if (data.enabled_rest) {
                columns.push({ header: 'Restant dû', key: 'left_to_pay', width: 30 });
            }
            if (data.enabled_external_reference) {
                columns.push({ header: 'Réf. externe', key: 'external_reference', width: 30 });
            }
            if (data.enabled_pdf_bill) {
                columns.push({ header: 'Facture PDF', key: 'document_url', width: 30 });
            }
            if (data.enabled_pdf_credit) {
                columns.push({ header: 'Avoir PDF', key: 'credit_document_url', width: 30 });
            }
            if (data.enabled_email) {
                columns.push({ header: 'Email compte', key: 'email', width: 30 });
            }
            if (data.enabled_mobile_phone) {
                columns.push({ header: 'Téléphone portable', key: 'mobile_phone', width: 30 });
            }
            if (data.enabled_phone) {
                columns.push({ header: 'Téléphone', key: 'phone', width: 30 });
            }
            if (data.enabled_address) {
                columns.push({ header: 'Adresse (n° et voie)', key: 'address', width: 30 });
            }
            if (data.enabled_address_details) {
                columns.push({ header: 'Adresse (complément)', key: 'address_details', width: 30 });
            }
            if (data.enabled_country) {
                columns.push({ header: 'Pays', key: 'country', width: 30 });
            }
            if (data.enabled_postal_code) {
                columns.push({ header: 'Code postal', key: 'postal_code', width: 30 });
            }
            if (data.enabled_federated_association) {
                columns.push({ header: 'Association fédérée', key: 'federated_association', width: 30 });
            }
            if (data.enabled_payment_method) {
                columns.push({ header: 'Mode de paiement', key: 'payment_method', width: 30 });
            }
            if (data.enabled_membership_email) {
                columns.push({ header: 'Email adhésion', key: 'membership_email', width: 30 });
            }
            if (data.enabled_subscription_email) {
                columns.push({ header: 'Email abonnement', key: 'subscription_email', width: 30 });
            }
            if (data.enabled_bill_email) {
                columns.push({ header: 'Email bon de commande', key: 'bill_email', width: 30 });
            }
            if (data.enabled_created_at) {
                columns.push({ header: 'Date BC', key: 'created_at', width: 30 });
            }
            if (data.enabled_bill_state) {
                columns.push({ header: 'Etat BC', key: 'bill_state', width: 30 });
            }
            worksheet.columns = columns;
            worksheet.getRow(1).font = { bold: true };

            // Rows
            bills.map(bill => {
                return worksheet.addRow({
                    status: getLabelStatus(billStatuses, bill.status),
                    client_code: bill.user ? bill.user.clientCode : '',
                    external_client_code: bill.user ? bill.user.external_client_code : '',
                    last_name: bill.user ? bill.user.last_name : '',
                    first_name: bill.user ? bill.user.first_name : '',
                    company_name: bill.user ? bill.user.company_name : '',
                    reference: bill.reference || '',
                    total_amount: getAmountTTC(bill.total_amount),
                    left_to_pay: getAmountTTC(bill.leftToPay),
                    external_reference: bill.external_reference || '',
                    document_url: bill.document_url ? 'Oui' : 'Non',
                    credit_document_url: bill.credit_document_url ? 'Oui' : 'Non',
                    email: bill.user ? bill.user.email : '',
                    mobile_phone: bill.user ? bill.user.mobile_phone : '',
                    phone: bill.user ? bill.user.phone : '',
                    address: bill.address || '',
                    address_details: bill.address_details || '',
                    country: bill.country ? bill.country.name : '',
                    postal_code: bill.postal_code || '',
                    federated_association: (bill.user && bill.user.federatedAssociation) ? bill.user.federatedAssociation.name : '',
                    payment_method: bill.paymentMethods ? getPaymentMethods(bill.paymentMethods) : '',
                    membership_email: bill.user ? bill.user.membershipEmail : '',
                    subscription_email: bill.user ? bill.user.subscriptionEmail : '',
                    bill_email: bill.user ? bill.user.billEmail : '',
                    created_at: bill.created_at ? getFormattedCreatedAt(bill.created_at) : '',
                    bill_state: bill.bill_state ? getLabelStatus(billStates, bill.bill_state) : '',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'bons-de-commande.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllBillsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default BillsExport;
