import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, Checkbox, CircularProgress } from '@material-ui/core';

// Services
import { register, checkCaptcha, checkExistingFullName, checkExistingEmail } from 'services/requests';
import { setToken, getStoredSubscription } from 'services/storage';
import { isValidEmail } from 'services/helpers';

// Components
import InputText from 'components/input-text';
import InputPhone from 'components/input-phone';
import FieldRequired from 'components/field-required';

// Components
import AddressForm from 'views/common/address-form';

// Views
import EmailUsed from './email-used';
import FullNameUsed from './full-name-used';
import { handleCreateSubscription } from '../purchase-registration';

// Messages
import messages from 'messages.json';

// ------------------------------------------------------------------------------------------ \\
// ------------------------------------ REGISTER CONTAINER ---------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const RegisterContainer = () => {
    return (
        <Grid
            container
            className="unauthenticated"
        >
            <Grid
                item xs={false} sm={1} md={2} lg={4}
            />
            <Grid
                item xs={12} sm={10} md={8} lg={4}
                container alignItems="center"
            >
                <Register />
            </Grid>
        </Grid>
    );
};

// ------------------------------------------------------------------------------------------ \\
// ---------------------------------------- REGISTER ---------------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

export const Register = () => {
    const history = useHistory();
    const pathname = useLocation().pathname;
    const storedSubscription = getStoredSubscription();
    const { enqueueSnackbar } = useSnackbar();

    const getStoredData = (item, defaultValue) => {
        if (pathname.includes('purchase-registration') && (storedSubscription && storedSubscription[item])) {
            return storedSubscription[item];
        }
        return defaultValue;
    };

    const [state, setState] = useState({
        loading: false,

        // Register
        first_name: getStoredData('first_name', ''),
        last_name: getStoredData('last_name', ''),
        company_name: getStoredData('company_name', ''),
        website: getStoredData('website', ''),
        email: getStoredData('email', ''),
        address: getStoredData('address', ''),
        address_details: getStoredData('address_details', ''),
        postal_code: getStoredData('postal_code', ''),
        city: getStoredData('city', ''),
        country_id: getStoredData('country_id', 1),
        geocode: null,
        phone: getStoredData('phone', ''),
        mobile_phone: getStoredData('mobile_phone', ''),
        password: '',
        confirm_password: '',
        gcu_accepted: false,

        // Captcha
        is_verified: false,

        // Dialogs
        openEmailUsed: false,
        openFullNameUsed: false,
        hiddenEmails: [],
    });

    function verifyUser(token) {
        checkCaptcha({
            secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
            response: token,
        }).then(res => {
            if (res.status === 200) {
                const success = JSON.parse(res.data.data).success;
                setState({ ...state, is_verified: success });
            }
            else {
                console.log(res);
            }
        });
    }

    function onSubmit() {
        if (
            state.first_name === ''
            || state.last_name === ''
            || state.email === ''
            || state.address === ''
            || state.postal_code === ''
            || state.city === ''
            || state.country_id === -1
            || state.confirm_password === ''
            || state.password === ''
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (state.phone === '' && state.mobile_phone === '') {
            enqueueSnackbar('Veuillez saisir au moins 1 numéro de téléphone', { variant: 'warning' });
        }
        else if (!isValidEmail(state.email)) {
            enqueueSnackbar(messages['email.no.valid'], { variant: 'warning' });
        }
        else if (state.password !== state.confirm_password) {
            enqueueSnackbar(messages['passwords.do.not.match'], { variant: 'warning' });
        }
        else if (!state.gcu_accepted) {
            enqueueSnackbar('Veuillez accepter la politique de confidentialité', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    function onCheckExistingFullName() {
        checkExistingFullName(
            state.first_name,
            state.last_name,
        ).then(res => {
            let hiddenEmails = res?.data?.data?.hiddenEmails;
            if (hiddenEmails && hiddenEmails.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    openFullNameUsed: true,
                    hiddenEmails,
                }));
            }
            else if (res?.status === 200) {
                onRegister();
            }
            else {
                enqueueSnackbar(res, { variant: 'error' });
                setState(prevState => ({ ...prevState, loading: false }));
            }
        });
    }

    function onRegister() {
        register({
            first_name: state.first_name,
            last_name: state.last_name,
            email: state.email,
            company_name: state.company_name,
            website: state.website,
            address: state.address,
            address_details: state.address_details,
            postal_code: state.postal_code,
            city: state.city,
            country_id: state.country_id,
            phone: state.phone,
            mobile_phone: state.mobile_phone,
            password: state.password,
            gcu_accepted: state.gcu_accepted,
        }).then(res => {
            if (res.status === 200) {
                if (pathname.includes('purchase-registration')) {
                    setToken(res.data.data, false);
                    handleCreateSubscription(enqueueSnackbar, history, res.data.data.user);
                }
                else {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/login');
                }
            }
            else {
                enqueueSnackbar(res, { variant: 'error' });
                setState({ ...state, loading: false });
            }
        });
    }

    useEffect(() => {
        if (state.loading) {
            checkExistingEmail(state.email).then(res => {
                if (res?.data?.data?.emailUsed) {
                    setState(prevState => ({ ...prevState, loading: false, openEmailUsed: true }));
                }
                else if (res?.status === 200) {
                    onCheckExistingFullName();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(prevState => ({ ...prevState, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div 
            className="unauthenticated-container"
            style={{ margin: '24px 12px' }}
        >
            {/* ---------------------- TITLE ---------------------- */}
            <Grid
                container justify="center"
                style={{ padding: '24px 24px 0px 24px' }}
            >
                <span
                    className="mabd-font"
                    style={{ fontSize: 16, fontWeight: 600 }}
                >
                    INSCRIPTION
                </span>
            </Grid>
            <Grid style={{ padding: 24 }}>
                {/* ---------------------- FIRST NAME ---------------------- */}
                <Grid container>
                    <div className="input-title">
                        Prénom<FieldRequired />
                    </div>
                    <InputText
                        label="Prénom"
                        type="text"
                        value={state.first_name}
                        onChange={e => setState({ ...state, first_name: e.target.value })}
                    />
                </Grid>
                {/* ---------------------- LAST NAME ---------------------- */}
                <Grid
                    container
                    style={{ marginTop: 12 }}
                >
                    <div className="input-title">
                        Nom<FieldRequired />
                    </div>
                    <InputText
                        label="Nom"
                        type="text"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                    />
                </Grid>
                {/* ---------------------- EMAIL ---------------------- */}
                <Grid
                    container
                    style={{ marginTop: 12 }}
                >
                    <div className="input-title">
                        Email<FieldRequired />
                    </div>
                    <InputText
                        label="Email"
                        type="email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                    />
                </Grid>
                {/* ---------------------- COMPANY NAME ---------------------- */}
                <Grid
                    container
                    style={{ marginTop: 12 }}
                >
                    <div className="input-title">
                        Nom de l'entreprise
                    </div>
                    <InputText
                        label="Nom de l'entreprise"
                        type="text"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                    />
                </Grid>
                {/* ---------------------- WEBSITE ---------------------- */}
                <Grid
                    container
                    style={{ marginTop: 12 }}
                >
                    <div className="input-title">
                        Site web
                    </div>
                    <InputText
                        label="Site web"
                        type="text"
                        value={state.website}
                        onChange={e => setState({ ...state, website: e.target.value })}
                    />
                </Grid>
                {/* ---------------------- ADDRESS ---------------------- */}
                <AddressForm
                    state={state}
                    setState={setState}
                    requiredAddress
                    requiredPostalCode
                    requiredCity
                    requiredCountry
                    disabledDepartment
                    fullWidth
                    labelTitle
                    marginTop={12}
                />
                {/* ---------------------- PHONE ---------------------- */}
                <div style={{ color: '#f50057', marginTop: 24 }}>
                    <FieldRequired /> Renseigner au moins 1 numéro de téléphone
                </div>
                <Grid
                    container
                    style={{ marginTop: 4 }}
                >
                    <div className="input-title">
                        Téléphone fixe
                    </div>
                    <InputPhone
                        label="Téléphone fixe"
                        value={state.phone}
                        setValue={phone => setState({ ...state, phone })}
                    />
                </Grid>
                {/* ---------------------- MOBILE PHONE ---------------------- */}
                <Grid
                    container
                    style={{ marginTop: 12 }}
                >
                    <div className="input-title">
                        Téléphone mobile
                    </div>
                    <InputPhone
                        label="Téléphone mobile"
                        value={state.mobile_phone}
                        setValue={mobile_phone => setState({ ...state, mobile_phone })}
                    />
                </Grid>
                {/* ---------------------- PASSWORD ---------------------- */}
                <Grid
                    container
                    style={{ marginTop: 24 }}
                >
                    <div className="input-title">
                        Mot de passe<FieldRequired />
                    </div>
                    <InputText
                        label="Mot de passe"
                        type="password"
                        value={state.password}
                        onChange={e => setState({ ...state, password: e.target.value })}
                    />
                </Grid>
                {/* ---------------------- CONFIRM PASSWORD ---------------------- */}
                <Grid
                    container
                    style={{ marginTop: 12 }}
                >
                    <div className="input-title">
                        Confirmer le mot de passe<FieldRequired />
                    </div>
                    <InputText
                        label="Mot de passe"
                        type="password"
                        value={state.confirm_password}
                        onChange={e => setState({ ...state, confirm_password: e.target.value })}
                    />
                </Grid>
                {/* ---------------------- GCU ---------------------- */}
                <Grid
                    container alignItems="center" justify="center"
                    style={{ marginTop: 12 }}
                >
                    <Checkbox
                        state={state.gcu_accepted}
                        onChange={e => setState({ ...state, gcu_accepted: e.target.checked })}
                    />
                    <span>
                        J'ai lu et j'accepte la
                    </span>
                    <Button
                        href="https://www.bio-dynamie.org/politique-de-confidentialite/"
                        target="blank_"
                        color="primary"
                        className="purchase-conditions-link"
                        style={{ textTransform: 'none' }}
                    >
                        politique de confidentialité
                    </Button>
                    <FieldRequired />
                </Grid>
                {/* ---------------------- CAPTCHA ---------------------- */}
                <Grid
                    container justify="center"
                    style={{ marginTop: 12 }}
                >
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                        onChange={token => verifyUser(token)}
                    />
                </Grid>
                {/* ---------------------- SUBMIT ---------------------- */}
                <Grid
                    container justify="center"
                    style={{ marginTop: 10 }}
                >
                    <Button
                        onClick={onSubmit}
                        disabled={!state.is_verified}
                        variant="contained" color="primary"
                    >
                        {state.loading ? (
                            <CircularProgress size={24} style={{ color: 'white' }} />
                        ) : (
                            'S\'inscrire'
                        )}
                    </Button>
                </Grid>
            </Grid>
            {/* ---------------------- EMAIL USED DIALOG ---------------------- */}
            {state.openEmailUsed && (
                <EmailUsed
                    emailUsed={state.email}
                    onClose={() => setState(prevState => ({ ...prevState, openEmailUsed: false }))}
                />
            )}
            {/* ---------------------- FULL NAME USED DIALOG ---------------------- */}
            {state.openFullNameUsed && (
                <FullNameUsed
                    fullName={state.first_name + ' ' + state.last_name}
                    hiddenEmails={state.hiddenEmails}
                    onClose={() => setState(prevState => ({ ...prevState, openFullNameUsed: false }))}
                    onRegister={() => { setState(prevState => ({ ...prevState, openFullNameUsed: false })); onRegister(); }}
                />
            )}
        </div>
    );
};

export default RegisterContainer;
