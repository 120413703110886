import React from 'react';

// Material-UI
import { Grid, Checkbox, Avatar, Button, CircularProgress } from '@material-ui/core';
import { AccountCircle as ProfileIcon } from '@material-ui/icons';

// Services
import { fileUrl } from 'services/axios-config';

// Components
import InputText from 'components/input-text';
import DatePicker from 'components/date-picker';
import InputFile from 'components/input-file';
import InputPhone from 'components/input-phone';
import FieldRequired from 'components/field-required';

// Views
import AddressForm from 'views/common/address-form';
import BillAddressForm from 'views/common/bill-address-form';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------ PROFILE FORM ------------------------------------ \\
// -------------------------------------------------------------------------------------- \\

const ProfileForm = ({ state, setState, onSave }) => {
    return (
        <Grid container>
            <Grid
                item xs={false} md={1} lg={2} xl={3}
            />
            <Grid
                item xs={12} md={10} lg={8} xl={6}
                style={{ padding: 12, backgroundColor: 'white' }}
            >
                <Grid
                    container alignItems="center"
                    style={{ padding: 12, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                >
                    <Grid item xs>
                        <span
                            className="subtitle-primary-color"
                            style={{ fontSize: 16 }}
                        >
                            Modifier son profil
                        </span>
                    </Grid>
                    <Grid item xs="auto">
                        <Button
                            onClick={onSave}
                            disabled={state.loading}
                            endIcon={state.loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                            variant="contained" color="primary"
                        >
                            Enregistrer
                        </Button>
                    </Grid>
                </Grid>
                {/* -------------------- PHOTO -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 24 }}
                >
                    {state.photo_url ? (
                        <Avatar
                            alt="user-photo"
                            className="avatar"
                            src={fileUrl + state.photo_url}
                        />
                    ) : (
                        <ProfileIcon
                            className="avatar"
                            style={{ color: 'lightgray' }}
                        />
                    )}
                </Grid>
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 24 }}
                >
                    <Grid item xs={12} sm={4}>
                        Photo
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputFile inputId="photo" />
                    </Grid>
                </Grid>
                {state.photo_url && (
                    <Grid container alignItems="center">
                        <Grid
                            item xs={12} sm={4}
                        />
                        <Grid
                            item xs={12} sm={8}
                            container alignItems="center"
                        >
                            <Checkbox
                                value={state.remove_photo}
                                onChange={e => setState({ ...state, remove_photo: e.target.checked })}
                            />
                            <span>
                                Supprimer la photo de profil
                            </span>
                        </Grid>
                    </Grid>
                )}
                {/* -------------------- LAST NAME -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Nom<FieldRequired />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Nom"
                            type="text"
                            value={state.last_name}
                            onChange={e => setState({ ...state, last_name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- FIRST NAME -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Prénom<FieldRequired />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Prénom"
                            type="text"
                            value={state.first_name}
                            onChange={e => setState({ ...state, first_name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- COMPANY NAME -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Nom de l'entreprise
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Nom de l'entreprise"
                            type="text"
                            value={state.company_name}
                            onChange={e => setState({ ...state, company_name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- ROLES -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Type de compte
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText 
                            label="Rôle"
                            type="text"
                            value={state.role.display_name}
                            disabled
                        />
                    </Grid>
                </Grid>
                {/* -------------------- BIRTH DATE -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Date de naissance
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <DatePicker
                            value={state.birth_date}
                            onChange={birth_date => setState({ ...state, birth_date })}
                            outlined
                        />
                    </Grid>
                </Grid>
                {/* -------------------- EMAIL -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Email
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Email"
                            type="email"
                            value={state.email}
                            onChange={e => setState({ ...state, email: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- PHONE -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Téléphone fixe
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputPhone
                            label="Téléphone fixe"
                            value={state.phone}
                            setValue={phone => setState({ ...state, phone })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- MOBILE PHONE -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Téléphone portable
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputPhone
                            label="Téléphone portable"
                            value={state.mobile_phone}
                            setValue={mobile_phone => setState({ ...state, mobile_phone })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- OTHER PHONE -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Autre téléphone
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputPhone
                            label="Aute téléphone"
                            value={state.other_phone}
                            setValue={other_phone => setState({ ...state, other_phone })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- SIRET -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        N°SIRET
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="N°SIRET"
                            type="number"
                            value={state.siret}
                            onChange={e => e.target.value <= 14 ? setState({ ...state, siret: e.target.value }) : undefined}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- ADDRESS FORM -------------------- */}
                <AddressForm
                    state={state}
                    setState={setState}
                    requiredCountry
                    requiredDepartment
                />
                {/* -------------------- PRESENTATION -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Présentation
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Présentation"
                            multiline
                            value={state.presentation}
                            onChange={e => setState({ ...state, presentation: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- BILL ADDRESS -------------------- */}
                <div style={{ marginTop: 24 }}>
                    <span
                        className="subtitle-primary-color"
                    >
                        Adresse de facturation
                    </span>
                </div>
                {/* -------------------- FIRST NAME -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Prénom
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Prénom"
                            value={state.bill_first_name}
                            onChange={e => setState({ ...state, bill_first_name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- LAST NAME -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Nom
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Nom"
                            value={state.bill_last_name}
                            onChange={e => setState({ ...state, bill_last_name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- EMAIL -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Email
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Email"
                            value={state.bill_email}
                            onChange={e => setState({ ...state, bill_email: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- COMPANY NAME -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Nom de l'entreprise
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Nom de l'entreprise"
                            value={state.bill_company_name}
                            onChange={e => setState({ ...state, bill_company_name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- BILL ADDRESS FORM -------------------- */}
                <BillAddressForm
                    state={state}
                    setState={setState}
                    disabledDepartment
                />
            </Grid>
        </Grid>
    );
};

export default ProfileForm;
