import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getSession, getUser } from 'services/requests';
import { getUsername } from 'services/helpers';
import useMediaQueries from 'services/media';
import { getFormattedDate } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import CreateFormationRequest from 'views/formations/create-formation-request';

// --------------------------------------------------------------------------------------- \\
// ---------------------------- CREATE USER FORMATION REQUEST ---------------------------- \\
// --------------------------------------------------------------------------------------- \\

const CreateUserFormationRequest = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { userId, formationSessionId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: null,
        formationSession: null,
    });

    useEffect(() => {
        if (state.loading) {
            axios
            .all([getUser(userId), getSession(formationSessionId)])
            .then(axios.spread(function (res_user, res_formation_session) {
                if (
                    res_user?.status === 200
                    && res_formation_session?.status === 200
                ) {
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        user: res_user?.data?.data?.user,
                        formationSession: res_formation_session?.data?.data?.formationSession,
                    }));
                }
                else {
                    console.log(res_user, res_formation_session);
                    setState({ ...state, loading: false, error: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push('/users/' + state.user?.id + '/formations')}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center" style={{ margin: mediaMD ? 0 : 10 }}
                >
                    Formulaire d'inscription pour {getUsername(state.user)}
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-end' : 'center'}
                >
                    <div style={{ backgroundColor: 'rgba(255, 0, 0, 0.1)', borderRadius: 4, padding: 4 }}>
                        Fin d'inscription : {getFormattedDate(state.formationSession.end_of_registration)}
                    </div>
                </Grid>
            </Grid>
            {/* -------------------- CREATE FORMATION REQUEST -------------------- */}
            <CreateFormationRequest
                formationSession={state.formationSession}
            />
        </>
    );
};

export default CreateUserFormationRequest;
