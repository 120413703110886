import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, CircularProgress, Radio } from '@material-ui/core';

// Services
import { updateUserManagement } from 'services/requests';

// Components
import InputText from 'components/input-text';

// Views
import SelectFederatedAssociation from 'selects/select-federated-association';
import SelectUserCategories from 'selects/select-user-categories';

// ------------------------------------------------------------------------------------------ \\
// ------------------------------------- USER SETTINGS -------------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const UserSettings = ({ user }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        external_client_code: user?.external_client_code || '',
        federated_association_id: user?.federated_association_id || -1,
        userCategories: (user && user.userCategories) ? user.userCategories.map(c => ({ label: c.name, value: c.id })) : [],
        only_mabd: user?.only_mabd || 0,
        auto_update_fa: user?.auto_update_fa !== null ? user.auto_update_fa : 1,
    });

    useEffect(() => {
        if (state.loading) {
            updateUserManagement(user.id, {
                external_client_code: state.external_client_code,
                federated_association_id: state.federated_association_id,
                user_category_ids: state.userCategories.map(uc => uc.value),
                only_mabd: state.only_mabd,
                auto_update_fa: state.auto_update_fa,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loading: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div style={{ padding: 12, backgroundColor: 'white' }}>
            {/* -------------------- TITLE & SUBMIT -------------------- */}
            <Grid
                container alignItems="center"
                style={{ padding: 12, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            >
                <Grid item xs>
                    <span
                        className="subtitle-primary-color"
                        style={{ fontSize: 16 }}
                    >
                        Paramètres du compte
                    </span>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        onClick={() => setState(prevState => ({ ...prevState, loading: true }))}
                        disabled={state.loading}
                        endIcon={state.loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                        variant="contained" color="primary"
                    >
                        Enregistrer
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- SETTINGS -------------------- */}
            <Grid
                container
                style={{ marginTop: 24 }}
            >
                <Grid
                    item xs={false} md={1} lg={2} xl={3}
                />
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            Code client externe
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Code client externe"
                                value={state.external_client_code}
                                onChange={e => setState({ ...state, external_client_code: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Association fédérée
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectFederatedAssociation
                                federatedAssociationId={state.federated_association_id}
                                setFederatedAssociationId={federated_association_id => setState({ ...state, federated_association_id })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- USER CATEGORIES -------------------- */}
                    <Grid
                        container alignItems="center"
                        style={{ marginTop: 6 }}
                    >
                        <Grid item xs={12} sm={4}>
                            Rôles
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectUserCategories
                                userCategories={state.userCategories}
                                setUserCategories={userCategories => setState({ ...state, userCategories })}
                            />
                        </Grid>
                    </Grid>
                    {/* -------------------- ONLY MABD -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            Adhérent uniquement MABD
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Radio
                                    checked={state.only_mabd === 1}
                                    onChange={() => setState(prevState => ({ ...prevState, only_mabd: 1 }))}
                                    color="secondary"
                                />
                                <span>
                                    Oui
                                </span>
                                <Radio
                                    checked={state.only_mabd === 0}
                                    onChange={() => setState(prevState => ({ ...prevState, only_mabd: 0 }))}
                                    color="default"
                                />
                                <span>
                                    Non
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                    {/* -------------------- AUTO UPDATE FA -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            Mise à jour automatique de l'association fédérée
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Radio
                                    checked={state.auto_update_fa === 1}
                                    onChange={() => setState(prevState => ({ ...prevState, auto_update_fa: 1 }))}
                                    color="secondary"
                                />
                                <span>
                                    Oui
                                </span>
                                <Radio
                                    checked={state.auto_update_fa === 0}
                                    onChange={() => setState(prevState => ({ ...prevState, auto_update_fa: 0 }))}
                                    color="default"
                                />
                                <span>
                                    Non
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default UserSettings;
