import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// Material-UI
import { Divider, Grid, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon, AddCircle as AddIcon, Bookmark as TagIcon, Edit as EditIcon } from '@material-ui/icons';

// Services
import { getUserNotes } from 'services/requests';
import { userCanCreateNote, userCanDeleteNote, userCanUpdateNote } from 'services/permissions';

// Components
import Tooltip from 'components/tooltip';

// Views
import CreateNote from './create-note';
import UpdateNote from './update-note';
import DeleteNote from './delete-note';

// --------------------------------------------------------------------------------------- \\
// -------------------------------------- USER NOTES ------------------------------------- \\
// --------------------------------------------------------------------------------------- \\

const UserNotes = ({ user }) => {

    const [state, setState] = useState({
        loading: true,
        error: false,
        notes: [],
        note: null,
        openCreate: false,
        openUpdate: false,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getUserNotes(user.id).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, notes: res.data.data.notes });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & CREATE BUTTON -------------------- */}
            <Grid
                container alignItems="center"
                style={{ padding: 6 }}
            >
                <Grid
                    item xs
                    className="subtitle-primary-color"
                    style={{ padding: 6 }}
                >
                    Notes
                </Grid>
                <Grid item xs="auto">
                    {userCanCreateNote() && (
                        <Tooltip title="Ajouter une note"
                            item={(
                                <IconButton
                                    onClick={() => setState({ ...state, openCreate: true })}
                                    style={{ padding: 6 }}
                                >
                                    <AddIcon />
                                </IconButton>
                            )}
                        />
                    )}
                </Grid>
            </Grid>
            {/* -------------------- NOTE LIST -------------------- */}
            <div style={{ padding: '12px 24px 24px 24px', maxHeight: 400, overflow: 'auto' }}>
                <Grid container spacing={3}>
                    <NoteList
                        state={state}
                        setState={setState}
                        user={user}
                    />
                </Grid>
            </div>
            {/* -------------------- CREATE NOTE -------------------- */}
            {state.openCreate && (
                <CreateNote
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                    user={user}
                />
            )}
        </>
    );
};

// --------------------------------------------------------------------------------------- \\
// --------------------------------------- NOTE LIST ------------------------------------- \\
// --------------------------------------------------------------------------------------- \\


const NoteList = ({ state, setState, user }) => {

    const noteProps = {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '0px 0px 8px 8px',
        position: 'relative',
    };

    const tagNoteProps = {
        position: 'absolute',
        top: -8,
        left: -12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 35,
        height: 35,
    };

    return (
        <>
            {state.notes.map((note, index) => (
                <Grid
                    key={note.id}
                    item xs={12}
                >
                    <div style={noteProps}>
                        {/* -------------------- ICON -------------------- */}
                        <div style={tagNoteProps}>
                            <TagIcon
                                fontSize="large"
                            />
                        </div>
                        {/* -------------------- INDEX -------------------- */}
                        <div style={tagNoteProps}>
                            <span style={{ fontWeight: 600, color: 'white' }}>
                                {index + 1}
                            </span>
                        </div>
                        {/* -------------------- CREATED AT -------------------- */}
                        <div style={{ padding: '8px 8px 8px 24px' }}>
                            <b style={{ color: 'gray', textTransform: 'uppercase' }}>
                                {moment(note.created_at).format('dddd DD MMMM YYYY')}
                            </b>
                        </div>
                        <Divider />
                        {/* -------------------- CONTENT -------------------- */}
                        <div
                            className="text"
                            style={{ padding: 8 }}
                        >
                            {note.content}
                        </div>
                        <Divider />
                        {/* -------------------- CREATOR -------------------- */}
                        <Grid container alignItems="center">
                            <Grid 
                                item xs
                                style={{ padding: 8 }}
                            >
                                {note.creator && (
                                    <span>
                                        Créé par {note.creator?.first_name} {note.creator?.last_name}
                                    </span>
                                )}
                                {(note.lastModifier && note.updated_at) && (
                                    <>
                                        <br />
                                        <span>
                                            Dernière modification le {moment(note.updated_at).format('DD/MM/YYYY')} par {note.lastModifier.first_name} {note.lastModifier.last_name}
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs="auto">
                                {userCanUpdateNote() && (
                                    <Tooltip title="Modifier"
                                        item={(
                                            <IconButton
                                            onClick={() => setState({ ...state, openUpdate: true, note })}
                                                style={{ padding: 6 }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                    />
                                )}
                                {userCanDeleteNote() && (
                                    <Tooltip title="Supprimer"
                                        item={(
                                            <IconButton
                                                onClick={() => setState({ ...state, openDelete: true, note })}
                                                style={{ padding: 6 }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            ))}
            {/* -------------------- UPDATE NOTE -------------------- */}
            {state.openUpdate && (
                <UpdateNote
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    user={user}
                    note={state.note}
                />
            )}
            {/* -------------------- DELETE NOTE -------------------- */}
            {state.openDelete && (
                <DeleteNote
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => setState({ ...state, openDelete: false, loading: true })}
                    note={state.note}
                />
            )}
        </>
    );
};

export default UserNotes;
