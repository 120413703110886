import React, { useState, useEffect } from 'react';

// Services
import { paymentMethods } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------- SELECT PAYMENT METHODS ----------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectPaymentMethods = ({ methods, setMethods }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(paymentMethods.map(s => ({ label: s.label, value: s.value })));
    }, []);

    return (
        <SelectMultiple
            value={methods}
            onChange={value => setMethods(value)}
            options={options}
            placeholder="Mode de paiement"
        />
    );
};

export default SelectPaymentMethods;
