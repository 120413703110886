import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogActions, DialogContent, Checkbox } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';

// Services
import { getPayments, getIndexPreferences, updateIndexPreferences } from 'services/requests';
import { userCanCreatePayment, userCanUpdatePayment } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';
import { paymentMethods, paymentStatuses } from 'services/constants';
import { getLabelStatus, getColorStatus, getAmountTTC } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Tooltip from 'components/tooltip';
import Pagination from 'components/pagination';
import InputText from 'components/input-text';
import SelectCreatable from 'components/select-creatable';

// Views
import CreatePayment from './payments-create';
import UpdatePayment from './payments-update';
import PaymentsExport from './payments-export';

// Selects
import SelectFederatedAssociations from 'selects/select-federated-associations';
import SelectCountries from 'selects/select-countries';
import SelectPaymentStatuses from 'selects/select-payment-statuses';
import SelectPaymentMethods from 'selects/select-payment-methods';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------ PAYMENTS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Payments = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        payments: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        payment: null,

        // Modal
        openCreate: false,
        openUpate: false,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_status: true,
        enabled_client_code: true,
        enabled_external_client_code: true,
        enabled_last_name: true,
        enabled_company_name: true,
        enabled_bill_reference: true,
        enabled_external_reference: true,
        enabled_amount: true,
        enabled_payment_method: true,
        enabled_check_reference: true,
        enabled_first_name: false,
        enabled_email: false,
        enabled_mobile_phone: false,
        enabled_phone: false,
        enabled_address: false,
        enabled_address_details: false,
        enabled_country: false,
        enabled_postal_code: false,
        enabled_federated_association: false,

        // Filters
        statuses: [],
        client_code: '',
        external_client_code: '',
        last_name: '',
        company_name: '',
        bill_reference: '',
        external_reference: '',
        amount: '',
        payment_methods: [],
        check_reference: '',
        first_name: '',
        email: '',
        mobile_phone: '',
        phone: '',
        address: '',
        address_details: '',
        countries: [],
        postal_codes: [],
        federated_associations: [],
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            offset: 0,
            error: false,
            statuses: [],
            client_code: '',
            external_client_code: '',
            last_name: '',
            company_name: '',
            bill_reference: '',
            external_reference: '',
            amount: '',
            payment_methods: [],
            check_reference: '',
            first_name: '',
            email: '',
            mobile_phone: '',
            phone: '',
            address: '',
            address_details: '',
            countries: [],
            postal_codes: [],
            federated_associations: [],
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'payments',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_status: columns.enabled_status,
                            enabled_client_code: columns.enabled_client_code,
                            enabled_external_client_code: columns.enabled_external_client_code,
                            enabled_last_name: columns.enabled_last_name,
                            enabled_company_name: columns.enabled_company_name,
                            enabled_bill_reference: columns.enabled_bill_reference,
                            enabled_external_reference: columns.enabled_external_reference,
                            enabled_amount: columns.enabled_amount,
                            enabled_payment_method: columns.enabled_payment_method,
                            enabled_check_reference: columns.enabled_check_reference,
                            enabled_first_name: columns.enabled_first_name,
                            enabled_email: columns.enabled_email,
                            enabled_mobile_phone: columns.enabled_mobile_phone,
                            enabled_phone: columns.enabled_phone,
                            enabled_address: columns.enabled_address,
                            enabled_address_details: columns.enabled_address_details,
                            enabled_country: columns.enabled_country,
                            enabled_postal_code: columns.enabled_postal_code,
                            enabled_federated_association: columns.enabled_federated_association,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            getPayments({
                offset: state.offset,
                limit: state.limit,
                statuses: state.statuses ? state.statuses.map(s => s.value) : null,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                last_name: state.last_name,
                company_name: state.company_name,
                bill_reference: state.bill_reference,
                external_reference: state.external_reference,
                amount: state.amount,
                payment_methods: state.payment_methods ? state.payment_methods.map(m => m.value) : null,
                check_reference: state.check_reference,
                first_name: state.first_name,
                email: state.email,
                mobile_phone: state.mobile_phone,
                phone: state.phone,
                address: state.address,
                address_details: state.address_details,
                country_ids: state.countries ? state.countries.map(c => c.value) : null,
                postal_codes: state.postal_codes ? state.postal_codes.map(c => c.value) : null,
                federated_association_ids: state.federated_associations ? state.federated_associations.map(a => a.value) : null,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        payments: res.data.data.payments,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Paiements
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanCreatePayment() && (
                        <Button variant="contained" onClick={() => setState({ ...state, openCreate: true })}>
                            Ajouter un paiement
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <PaymentsExport data={state} />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <PaymentList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- CREATE PAYMENT -------------------- */}
            {state.openCreate && (
                <CreatePayment
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    Statut paiement
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    Code client
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    Code client externe
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    Nom
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    Nom de l'entreprise
                </div>
            )}
            {/* -------------------- BILL REFERENCE -------------------- */}
            {state.enabled_bill_reference && (
                <div className="column-list">
                    Réf. bon de commande
                </div>
            )}
            {/* -------------------- EXTERNAL REFERENCE -------------------- */}
            {state.enabled_external_reference && (
                <div className="column-list">
                    Réf. externe
                </div>
            )}
            {/* -------------------- AMOUNT -------------------- */}
            {state.enabled_amount && (
                <div className="column-list">
                    Montant TTC
                </div>
            )}
            {/* -------------------- PAYMENT METHOD -------------------- */}
            {state.enabled_payment_method && (
                <div className="column-list">
                    Mode de paiement
                </div>
            )}
            {/* -------------------- CHECK REFERENCE -------------------- */}
            {state.enabled_check_reference && (
                <div className="column-list">
                    Réf. chèque
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    Prénom
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    Email compte
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    Téléphone portable
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    Téléphone fixe
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    Adresse FACT (n° et voie)
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    Adresse FACT (complément)
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    Pays
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    Code postal
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    Destinataire paiement
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    <SelectPaymentStatuses
                        statuses={state.statuses}
                        setStatuses={statuses => onSelectSearch('statuses', statuses)}
                    />
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client"
                        value={state.client_code}
                        onChange={e => setState({ ...state, client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client externe"
                        value={state.external_client_code}
                        onChange={e => setState({ ...state, external_client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    <InputText
                        label="Nom"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    <InputText
                        label="Nom de l'entreprise"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL REFERENCE -------------------- */}
            {state.enabled_bill_reference && (
                <div className="column-list">
                    <InputText
                        label="Réf. bon de commande"
                        value={state.reference}
                        onChange={e => setState({ ...state, reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EXTERNAL REFERENCE -------------------- */}
            {state.enabled_external_reference && (
                <div className="column-list">
                    <InputText
                        label="Réf. externe"
                        value={state.external_reference}
                        onChange={e => setState({ ...state, external_reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- AMOUNT -------------------- */}
            {state.enabled_amount && (
                <div className="column-list">
                    <InputText
                        label="Montant TTC"
                        value={state.amount}
                        onChange={e => setState({ ...state, amount: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PAYMENT METHOD -------------------- */}
            {state.enabled_payment_method && (
                <div className="column-list">
                    <SelectPaymentMethods
                        methods={state.payment_methods}
                        setMethods={payment_methods => onSelectSearch('payment_methods', payment_methods)}
                    />
                </div>
            )}
            {/* -------------------- CHECK REFERENCE -------------------- */}
            {state.enabled_check_reference && (
                <div className="column-list">
                    <InputText
                        label="Réf. chèque"
                        value={state.check_reference}
                        onChange={e => setState({ ...state, check_reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    <InputText
                        label="Prénom"
                        value={state.first_name}
                        onChange={e => setState({ ...state, first_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    <InputText
                        label="Email compte"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone portable"
                        value={state.mobile_phone}
                        onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone fixe"
                        value={state.phone}
                        onChange={e => setState({ ...state, phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    <InputText
                        label="Adresse (n° et voie)"
                        value={state.address}
                        onChange={e => setState({ ...state, address: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    <InputText
                        label="Adresse (complément)"
                        value={state.address_details}
                        onChange={e => setState({ ...state, address_details: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    <SelectCountries
                        countries={state.countries}
                        setCountries={countries => onSelectSearch('countries', countries)}
                    />
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    <SelectCreatable
                        placeholder="Code postal"
                        value={state.postal_codes}
                        onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                    />
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    <SelectFederatedAssociations
                        federatedAssociations={state.federated_associations}
                        setFederatedAssociations={federated_associations => onSelectSearch('federated_associations', federated_associations)}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- PAYMENT LIST ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const PaymentList = ({ state, setState }) => {

    function userCanUpdate(payment_method) {
        if (userCanUpdatePayment() && payment_method !== 'cb') {
            return true;
        }
        return false;
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.payments.length > 0 && (
                state.payments.map(payment => (
                    <Fragment key={payment.id}>
                        <div
                            onClick={userCanUpdate(payment.payment_method) ? () => setState({ ...state, openUpate: true, payment }) : null}
                            className={userCanUpdate(payment.payment_method) ? 'simple-list' : null}
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- STATUS -------------------- */}
                            {state.enabled_status && (
                                <div className="column-list">
                                    <b className="title-status-list" style={{ color: getColorStatus(paymentStatuses, payment.status) }}>
                                        {getLabelStatus(paymentStatuses, payment.status)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- CLIENT CODE -------------------- */}
                            {state.enabled_client_code && (
                                <div className="column-list">
                                    {payment.client_codes}
                                </div>
                            )}
                            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                            {state.enabled_external_client_code && (
                                <div className="column-list">
                                    {payment.external_client_codes}
                                </div>
                            )}
                            {/* -------------------- LAST NAME -------------------- */}
                            {state.enabled_last_name && (
                                <div className="column-list">
                                    {payment.last_names}
                                </div>
                            )}
                            {/* -------------------- COMPANY NAME -------------------- */}
                            {state.enabled_company_name && (
                                <div className="column-list">
                                    {payment.company_names}
                                </div>
                            )}
                            {/* -------------------- BILL REFERENCE -------------------- */}
                            {state.enabled_bill_reference && (
                                <div className="column-list">
                                    {payment.references}
                                </div>
                            )}
                            {/* -------------------- EXTERNAL REFERENCE -------------------- */}
                            {state.enabled_external_reference && (
                                <div className="column-list">
                                    {payment.external_references}
                                </div>
                            )}
                            {/* -------------------- AMOUNT -------------------- */}
                            {state.enabled_amount && (
                                <div className="column-list">
                                    {getAmountTTC(payment.amount)}
                                </div>
                            )}
                            {/* -------------------- PAYMENT METHOD -------------------- */}
                            {state.enabled_payment_method && (
                                <div className="column-list">
                                    {getLabelStatus(paymentMethods, payment.payment_method)}
                                </div>
                            )}
                            {/* -------------------- CHECK REFERENCE -------------------- */}
                            {state.enabled_check_reference && (
                                <div className="column-list">
                                    {payment.check_number}
                                </div>
                            )}
                            {/* -------------------- FIRST NAME -------------------- */}
                            {state.enabled_first_name && (
                                <div className="column-list">
                                    {payment.first_names}
                                </div>
                            )}
                            {/* -------------------- EMAIL -------------------- */}
                            {state.enabled_email && (
                                <div className="column-list">
                                    {payment.emails}
                                </div>
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <div className="column-list">
                                    {payment.mobile_phones}
                                </div>
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <div className="column-list">
                                    {payment.phones}
                                </div>
                            )}
                            {/* -------------------- ADDRESS -------------------- */}
                            {state.enabled_address && (
                                <div className="column-list">
                                    {payment.addresses}
                                </div>
                            )}
                            {/* -------------------- ADDRESS DETAILS -------------------- */}
                            {state.enabled_address_details && (
                                <div className="column-list">
                                    {payment.addresses_details}
                                </div>
                            )}
                            {/* -------------------- COUNTRY -------------------- */}
                            {state.enabled_country && (
                                <div className="column-list">
                                    {payment.countries ? payment.countries.map((c, index) => {
                                        if (index === 0) return c.name;
                                        else return ', ' + c.name;
                                    }) : ''}
                                </div>
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <div className="column-list">
                                    {payment.postal_codes}
                                </div>
                            )}
                            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                            {state.enabled_federated_association && (
                                <div className="column-list">
                                    {payment.federatedAssociation ? payment.federatedAssociation.name : ''}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
            {/* -------------------- UPDATE PAYMENT -------------------- */}
            {state.openUpate && (
                <UpdatePayment
                    onClose={() => setState({ ...state, openUpate: false })}
                    refetch={() => setState({ ...state, openUpate: false, loading: true })}
                    payment={state.payment}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_status: state.enabled_status,
            enabled_client_code: state.enabled_client_code,
            enabled_external_client_code: state.enabled_external_client_code,
            enabled_last_name: state.enabled_last_name,
            enabled_company_name: state.enabled_company_name,
            enabled_bill_reference: state.enabled_bill_reference,
            enabled_external_reference: state.enabled_external_reference,
            enabled_amount: state.enabled_amount,
            enabled_payment_method: state.enabled_payment_method,
            enabled_check_reference: state.enabled_check_reference,
            enabled_first_name: state.enabled_first_name,
            enabled_email: state.enabled_email,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_phone: state.enabled_phone,
            enabled_address: state.enabled_address,
            enabled_address_details: state.enabled_address_details,
            enabled_country: state.enabled_country,
            enabled_postal_code: state.enabled_postal_code,
            enabled_federated_association: state.enabled_federated_association,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'payments',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                <div style={{ display: 'flex' }}>
                    <div>
                        {/* -------------------- STATUS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_status}
                                onChange={e => setState({ ...state, enabled_status: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Statut bon de commande
                        </Grid>
                        {/* -------------------- CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_client_code}
                                onChange={e => setState({ ...state, enabled_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client
                        </Grid>
                        {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_external_client_code}
                                onChange={e => setState({ ...state, enabled_external_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client externe
                        </Grid>
                        {/* -------------------- LAST NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_last_name}
                                onChange={e => setState({ ...state, enabled_last_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom
                        </Grid>
                        {/* -------------------- COMPANY NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_company_name}
                                onChange={e => setState({ ...state, enabled_company_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom de l'entreprise
                        </Grid>
                        {/* -------------------- BILL REFERENCE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_bill_reference}
                                onChange={e => setState({ ...state, enabled_bill_reference: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Réf. bon de commande
                        </Grid>
                        {/* -------------------- EXTERNAL REFERENCE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_external_reference}
                                onChange={e => setState({ ...state, enabled_external_reference: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Réf. externe
                        </Grid>
                        {/* -------------------- AMOUNT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_amount}
                                onChange={e => setState({ ...state, enabled_amount: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Montant TTC
                        </Grid>
                        {/* -------------------- PAYMENT METHOD -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_payment_method}
                                onChange={e => setState({ ...state, enabled_payment_method: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Mode de paiement
                        </Grid>
                        {/* -------------------- CHECK REFERENCE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_check_reference}
                                onChange={e => setState({ ...state, enabled_check_reference: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Réf. chèque
                        </Grid>
                    </div>
                    <div>
                        {/* -------------------- FIRST NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_first_name}
                                onChange={e => setState({ ...state, enabled_first_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Prénom
                        </Grid>
                        {/* -------------------- EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_email}
                                onChange={e => setState({ ...state, enabled_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email compte
                        </Grid>
                        {/* -------------------- MOBILE PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_mobile_phone}
                                onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone portable
                        </Grid>
                        {/* -------------------- PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_phone}
                                onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone fixe
                        </Grid>
                        {/* -------------------- ADDRESS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address}
                                onChange={e => setState({ ...state, enabled_address: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse FACT (n° et voie)
                        </Grid>
                        {/* -------------------- ADDRESS DETAILS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address_details}
                                onChange={e => setState({ ...state, enabled_address_details: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse FACT (complément)
                        </Grid>
                        {/* -------------------- COUNTRY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_country}
                                onChange={e => setState({ ...state, enabled_country: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Pays
                        </Grid>
                        {/* -------------------- POSTAL CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_postal_code}
                                onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code postal
                        </Grid>
                        {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_federated_association}
                                onChange={e => setState({ ...state, enabled_federated_association: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Destinataire paiement
                        </Grid>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Payments;
