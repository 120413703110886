import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

// Services
import { isAuthenticated } from 'services/storage';
import PACKAGE_JSON from '../package.json';

// Components
import Header from 'views/unauthenticated/header';
import Footer from 'views/unauthenticated/footer';
import Login from 'views/unauthenticated/login';
import ForgottenPassword from 'views/unauthenticated/forgotten-password';
import ResetPassword from 'views/unauthenticated/reset-password';
import Register from 'views/unauthenticated/register';
import Formations from 'views/unauthenticated/formations';
import Shop from 'views/unauthenticated/shop';
import Verify from 'views/unauthenticated/verify';
import PurchaseRegistration from 'views/unauthenticated/purchase-registration';
import Menu from 'views/menu';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------------- APP -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const App = () => {

    let APP_VERSION = localStorage.getItem('APP_VERSION');
    const location = useLocation();
    const [authenticated, setAuthenticated] = useState(isAuthenticated());

    useEffect(() => {

        setAuthenticated(isAuthenticated());

        /* * *
        * Compare la version contenue dans le cache du navigateur avec la version du package.json
        * Si les versions sont différentes ou s'il n'y a pas de version dans le cache du navigateur,
        * mets à jour la version du cache et reload la page.
        */
        if (APP_VERSION !== PACKAGE_JSON.version) {
            
            localStorage.setItem('APP_VERSION', PACKAGE_JSON.version);

            if (caches) {
                caches.keys().then(keys => {
                    keys.forEach(key => {
                        caches.delete(key);
                    });
                    window.location.reload();
                });
            }
            else {
                window.location.reload();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Switch>
            <PublicRoute exact path="/login">
                <Login />
            </PublicRoute>
            <PublicRoute exact path="/forgotten-password">
                <ForgottenPassword />
            </PublicRoute>
            <PublicRoute exact path="/reset-password">
                <ResetPassword />
            </PublicRoute>
            <PublicRoute exact path="/register">
                <Register />
            </PublicRoute>
            <PublicRoute exact path="/purchase-registration">
                <PurchaseRegistration />
            </PublicRoute>
            <PublicRoute exact path="/verify">
                <Verify />
            </PublicRoute>
            {!authenticated && (
                <PublicRoute path="/shop">
                    <Shop />
                </PublicRoute>
            )}
            {!authenticated && (
                <PublicRoute path="/formations">
                    <Formations />
                </PublicRoute>
            )}
            <PrivateRoute path="/">
                <Menu />
            </PrivateRoute>
        </Switch>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- PUBLIC ROUTE ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

/* * *
* Routes accessibles lorsque l'utilisateur n'est pas authentifié
* (header et footer ajoutés ici)
*/

function PublicRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={() => (
                <>
                    <Header />
                    {children}
                    <Footer />
                </>
            )}
        />
    );
}

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- PRIVATE ROUTE ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

/* * *
* Routes accessibles lorsque l'utilisateur est authentifié
* (définit par la présence du token dans le storage du navigateur)
*/

function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated() ? (
                    children
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: location } }} />
                )
            }
        />
    );
}

export default App;
