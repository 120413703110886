import React, { useEffect, useState } from 'react';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Avatar, Button, CircularProgress } from '@material-ui/core';

// Services
import { fileUrl } from 'services/axios-config';
import { getFormattedDate, getColorStatus, getLabelStatus } from 'services/helpers';
import { membershipStatuses } from 'services/constants';
import { sendConfirmEmail } from 'services/requests';
import { userCanReadNote } from 'services/permissions';

// Views
import UserNotes from '../notes';

/* * *
* CARD PROPS
*/
const cardProps = {
    flex: 1,
    backgroundColor: 'white',
};

const itemCardProps = {
    padding: 12,
    lineHeight: 1.2,
    display: 'flex',
    flexDirection: 'column',
};

// ---------------------------------------------------------------------------------------- \\
// ------------------------------------- USER DETAILS ------------------------------------- \\
// ---------------------------------------------------------------------------------------- \\

const UserDetails = ({ user }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [sending, setSending] = useState(false);

    let membership;
    if (user?.currentYearMembership) {
        membership = user.currentYearMembership;
    }
    else if (user?.nextYearMembership) {
        membership = user.nextYearMembership;
    }
    else {
        membership = user.lastMembership;
    }

    useEffect(() => {
        if (sending) {
            sendConfirmEmail(user.email).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                }
                setSending(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sending]);

    return (
        <div
            className="light-background-color"
            style={{ padding: 24 }}
        >
            <Grid container spacing={4}>
                {/* * *
                * FIRST COLUMN
                */}
                <Grid
                    item xs={12} lg={4}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <div style={cardProps}>
                        {/* -------------------- STATUS -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Statut
                            </span>
                            <b style={{ color: getColorStatus(membershipStatuses, user.membershipStatus), textTransform: 'uppercase' }}>
                                {getLabelStatus(membershipStatuses, user.membershipStatus)}
                            </b>
                        </div>
                        <Divider />
                        {/* -------------------- CLIENT CODE -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Code client
                            </span>
                            <span>
                                {user.clientCode}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- ROLE -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Type de compte
                            </span>
                            <span>
                                {user.role?.display_name}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- USER CATEGORIES -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Rôle(s)
                            </span>
                            <span>
                                {(user.userCategories && user.userCategories.length > 0) ? (
                                    user.userCategories.map(c => c.name).join(', ')
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- MAIN ACTIVITY -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Activité principale
                            </span>
                            <span>
                                {(membership?.mainActivity) ? (
                                    membership.mainActivity.name
                                ) : (
                                    'Aucune'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- BIODYVIN -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Biodyvin
                            </span>
                            <span>
                                {membership?.is_biodyvin === 1? 'Oui' : 'Non'}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- DEMETER -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Demeter
                            </span>
                            <span>
                                {membership?.is_demeter === 1 ? 'Oui' : 'Non'}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- BIRTHDATE -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Date de naissance
                            </span>
                            <span>
                                {user.birth_date ? (
                                    getFormattedDate(user.birth_date)
                                ) : (
                                    'Aucune'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- USER LISTS -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Groupes
                            </span>
                            <span>
                                {(user.userLists && user.userLists.length > 0) ? (
                                    user.userLists.map(ul => ul.name).join(', ')
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- DOMAINS -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Domaines
                            </span>
                            <span>
                                {(user.domains && user.domains.length > 0) ? (
                                    user.domains.map(d => d.name).join(', ')
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- ONLY MABD -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Adhére uniquement au MABD
                            </span>
                            <span>
                                {user.only_mabd === 1 ? (
                                    'Oui'
                                ) : user.only_mabd === 0 ? (
                                    'Non'
                                ) : (
                                    'Non renseigné'
                                )}
                            </span>
                        </div>
                    </div>
                </Grid>
                {/* * *
                * SECOND COLUMN
                */}
                <Grid
                    item xs={12} lg={4}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <div style={cardProps}>
                        {/* -------------------- EMAIL -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Email compte&nbsp;
                                {user.email_verified_at ? (
                                    <b style={{ color: 'green' }}>
                                        (activé)
                                    </b>
                                ) : (
                                    <b style={{ color: 'red' }}>
                                        (non activé)
                                    </b>
                                )}
                            </span>
                            <span>
                                {user.email ? (
                                    user.email
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                            {(user.email && !user.email_verified_at) && (
                                <Button
                                    onClick={() => setSending(true)}
                                    disabled={sending}
                                    endIcon={sending ? <CircularProgress size={20} color="inherit" /> : <></>}
                                    variant="contained"
                                    style={{ textTransform: 'none', width: 'fit-content', marginTop: 8 }}
                                >
                                    Renvoyer email d'activation
                                </Button>
                            )}
                        </div>
                        <Divider />
                        {/* -------------------- BILL EMAIL -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Email de facturation
                            </span>
                            <span>
                                {user.billEmail ? (
                                    user.billEmail
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- MOBILE PHONE -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Téléphone portable
                            </span>
                            <span>
                                {user.mobile_phone ? (
                                    user.mobile_phone
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- PHONE -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Téléphone fixe
                            </span>
                            <span>
                                {user.phone ? (
                                    user.phone
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- SIRET -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                N°siret
                            </span>
                            <span>
                                {user.siret ? (
                                    user.siret
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- ADDRESS -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Adresse (n° et voie)
                            </span>
                            <span>
                                {user.address ? (
                                    user.address
                                ) : (
                                    'Aucune'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- ADDRESS DETAILS -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Adresse (complément)
                            </span>
                            <span>
                                {user.address_details ? (
                                    user.address_details
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- POSTAL CODE -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Code postal
                            </span>
                            <span>
                                {user.postal_code ? (
                                    user.postal_code
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- CITY -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Ville
                            </span>
                            <span>
                                {user.city ? (
                                    user.city
                                ) : (
                                    'Aucune'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- DEPARTMENT -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Département
                            </span>
                            <span>
                                {user.department ? (
                                    user.department.name + ' (' + user.department.number + ')'
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- COUNTRY -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Pays
                            </span>
                            <span>
                                {user.country ? (
                                    user.country.name
                                ) : (
                                    'Aucun'
                                )}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Association fédérée
                            </span>
                            <span>
                                {user.federatedAssociation ? (
                                    user.federatedAssociation.name
                                ) : (
                                    'Aucune'
                                )}
                            </span>
                        </div>
                    </div>
                </Grid>
                {/* * *
                * THIRD COLUMN
                */}
                <Grid
                    item xs={12} lg={4}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <div style={cardProps}>
                        {/* -------------------- PHOTO -------------------- */}
                        <Grid
                            container justify="center"
                            style={{ padding: 12 }}
                        >
                            <Avatar
                                alt="user/photo"
                                className="avatar"
                                src={user.photo_url ? fileUrl + user.photo_url : ''}
                            />
                        </Grid>
                        {/* -------------------- CREATED AT -------------------- */}
                        <div style={{ padding: '0px 12px 12px 12px' }}>
                            <span className="subtitle-primary-color">
                                Créé le {moment(user.created_at).format('dddd DD MMMM YYYY')}
                            </span>
                        </div>
                        <Divider />
                        {/* -------------------- PRESENTATION -------------------- */}
                        <div style={itemCardProps}>
                            <span className="subtitle-primary-color">
                                Présentation
                            </span>
                            <span className="text">
                                {user.presentation ? (
                                    user.presentation
                                ) : (
                                    'Aucune'
                                )}
                            </span>
                        </div>
                        {/* -------------------- NOTES -------------------- */}
                        {userCanReadNote() && (
                            <>
                                <Divider />
                                <UserNotes
                                    user={user}
                                />
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default UserDetails;
