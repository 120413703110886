import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Notistack (Handle Alert message)
import { SnackbarProvider } from 'notistack';

// Material-UI
import moment from 'moment';
import { IconButton, Zoom } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/fr';

// CSS
import './index.css';
import 'simplebar/dist/simplebar.min.css';

// App Component
import App from './app';

/* * *
* Point d'entrée du projet Frontend dans la div possédant l'ID "root" du fichier racine HTML (dossier public)
*
* Les providers permettent l'utilisation des composants de la librairie liée dans toute l'application
* Exemple avec SnackbarProvider : permet l'utilisateur du hook useSnackbar avec les propriétés définies dans le provider
*/

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------------- ROOT -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Root = () => {
    const notistackRef = createRef();

    const onClickDismiss = key => () => { 
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <SnackbarProvider
            ref={notistackRef}
            maxSnack={1}
            TransitionComponent={Zoom}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            style={{ marginTop: 66 }}
            action={key => (
                <IconButton onClick={onClickDismiss(key)}>
                    <IconClose style={{ color: 'white' }} />
                </IconButton>
            )}
        >
            <App />
        </SnackbarProvider>
    );
};

ReactDOM.render(
    <BrowserRouter>
        <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale="fr"
        >
            <Root />
        </MuiPickersUtilsProvider>
    </BrowserRouter>, 
    document.getElementById('root')
);
