import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Checkbox } from '@material-ui/core';

// Services
import { getUpcomingSessions } from 'services/requests';
import { userCanFetchDomainWithUsers } from 'services/permissions';

// Views
import UpcomingSessionList from './upcoming-session-list';
import GoogleMapInteractive from './google-map-interactive';
import Categories from './categories';

/* * *
* GOOGLE MAP HEIGHT
*/
const mapHeight = 400;

// --------------------------------------------------------------------------------------------------- \\
// --------------------------------------- UPCOMING SESSIONS ----------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const UpcomingSessions = () => {
    const { userId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formationSessions: [],
        paginatedFormationSessions: [],
        trainers: [],
        managers: [],
        offset: 0,
        limit: 5,
        total_count: 0,

        // Domain members
        members_registration: false,

        // Filters
        status: null,
        begin: null,
        end: null,
        session_date: null,
        name: '',
        category_id: -1,
        french_departments: [],
        trainersFilter: [],
        managersFilter: [],
    });

    useEffect(() => {
        if (state.loading) {
            getUpcomingSessions({
                status: state.status?.value,
                begin: state.begin ? moment(state.begin).format('YYYY-MM-DD') : null,
                end: state.end ? moment(state.end).format('YYYY-MM-DD') : null,
                session_date: state.session_date ? moment(state.session_date).format('YYYY-MM-DD') : null,
                name: state.name,
                category_id: state.category_id,
                french_department_ids: state.french_departments ? state.french_departments.map(d => d.value) : null,
                trainer_ids: state.trainersFilter ? state.trainersFilter.map(t => t.value) : null,
                manager_ids: state.managersFilter ? state.managersFilter.map(m => m.value) : null,
            }).then(res => {
                let formationSessions = res?.data?.data?.formationSessions;
                if (res?.status === 200 && formationSessions) {
                    setState({
                        ...state,
                        loading: false,
                        offset: 0,
                        limit: 5,
                        total_count: res.data.data?.totalCount,
                        formationSessions,
                        paginatedFormationSessions: formationSessions.filter((s, index) => index >= 0 && index < 5),
                        trainers: res.data.data?.trainers,
                        managers: res.data.data?.managers,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div style={{ flex: 1 }}>
            {/* -------------------- SUBHEADER -------------------- */}
            {!userId && (
                <Grid
                    container alignItems="center" justify="space-between"
                    className="subheader"
                >
                    <span>
                        Formations
                    </span>
                    {userCanFetchDomainWithUsers() && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>
                                Inscrire des membres d'un domaine
                            </span>
                            <Checkbox
                                value={state.members_registration}
                                onChange={e => setState({ ...state, members_registration: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                        </div>
                    )}
                </Grid>
            )}
            {/* -------------------- UPCOMING SESSION LIST -------------------- */}
            <UpcomingSessionList
                state={state}
                setState={setState}
            />
            {/* -------------------- INTERACTIVE MAP & CATEGORIES -------------------- */}
            <Grid
                container
                style={{ padding: 24 }}
            >
                <Grid item xs={12} md={8}>
                    {!state.loading && (
                        <GoogleMapInteractive
                            formationSessions={state.formationSessions}
                            membersRegistration={state.members_registration}
                            mapHeight={mapHeight}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Categories
                        parentState={state}
                        setParentState={setState}
                        mapHeight={mapHeight}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default UpcomingSessions;
