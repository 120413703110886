import React, { useState, useEffect } from 'react';

// Moment
import moment from 'moment';

// File-Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';
import { SaveAlt as SaveIcon } from '@material-ui/icons';

// Services
import { getFormation } from 'services/requests';
import { fileUrl } from 'services/axios-config';
import { getFileExtension, getAmountTTC } from 'services/helpers';
import { sessionPriceStatuses } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import FormationContent from './formation-content';

// -------------------------------------------------------------------------------------------- \\
// -------------------------------- FORMATION DETAILS CONTAINER ------------------------------- \\
// -------------------------------------------------------------------------------------------- \\

const FormationDetailsContainer = ({ formationSession }) => {
    const { mediaMD } = useMediaQueries();

    return (
        <>
            {/* -------------------- FORMATION DETAILS -------------------- */}
            <Grid
                container
                style={{ padding: 24 }}
            >
                <Grid item xs={12} md={8} lg={7}>
                    <FormationDetails
                        formationSession={formationSession}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4} lg={5}
                    container justify="center" alignItems="center"
                    style={{ marginTop: mediaMD ? undefined : 12 }}
                >
                    {formationSession.photo_url && (
                        <img
                            alt="formationSession/img"
                            src={fileUrl + formationSession.photo_url}
                            style={{ width: 'auto', maxWidth: 300, maxHeight: 500 }}
                        />
                    )}
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- FORMATION CONTENT -------------------- */}
            <FormationContent
                formationSession={formationSession}
            />
        </>
    );
};

// -------------------------------------------------------------------------------------------- \\
// ------------------------------------- FORMATION DETAILS ------------------------------------ \\
// -------------------------------------------------------------------------------------------- \\

const FormationDetails = ({ formationSession }) => {

    const [state, setState] = useState({
        loading: true,
        error: false,
        formation: null,
    });

    const chipProps = {
        borderRadius: 16,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        padding: '6px 12px',
    };

    useEffect(() => {
        if (state.loading) {
            getFormation(formationSession.formation_id).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, formation: res.data.data.formation });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    function getContactInformation(user) {
        let array = [];
        if (user.email) {
            array.push(user.email);
        }
        if (user.phone) {
            array.push(user.phone);
        }
        if (user.mobile_phone) {
            array.push(user.mobile_phone);
        }
        return array.join(' / ');
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <div style={{ fontWeight: 600, fontSize: 20, textTransform: 'uppercase' }}>
                {formationSession.name}
            </div>
            {/* -------------------- NUMBER / CATEGORY -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 14 }}
            >
                <Grid
                    item xs={12} sm={4}
                    style={{ padding: 10 }}
                >
                    <b className="primary-color">
                        Référence formation / Catégorie(s)
                    </b>
                </Grid>
                <Grid
                    item xs={12} sm={8}
                    style={{ padding: 10 }}
                >
                    #{formationSession.number} / {state.formation.categories.map(c => c.name).join(', ')}
                </Grid>
            </Grid>
            {/* -------------------- TRAINERS -------------------- */}
            {(formationSession.trainers && formationSession.trainers.length > 0) && (
                <Grid container alignItems="center">
                    <Grid
                        item xs={12} sm={4}
                        style={{ padding: 10 }}
                    >
                        <b className="primary-color">
                            Formateur(s)
                        </b>
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        style={{ padding: 10 }}
                    >
                        {formationSession.trainers.map(t => t.fullName).join(' / ')}
                    </Grid>
                </Grid>
            )}
            {/* -------------------- DATES -------------------- */}
            {(formationSession.sessionDays && formationSession.sessionDays.length > 0) && (
                <Grid container alignItems="center">
                    <Grid
                        item xs={12} sm={4}
                        style={{ padding: 10 }}
                    >
                        <b className="primary-color">
                            Date(s)
                        </b>
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        container spacing={1}
                        style={{ padding: 4, display: 'flex' }}
                    >
                        {formationSession.sessionDays.map(sessionDay => (
                            <Grid
                                key={sessionDay.id}
                                item xs="auto"
                            >
                                <div style={chipProps}>
                                    {sessionDay.session_date ? moment(sessionDay.session_date).format('dddd DD MMMM YYYY') : ''}
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
            {/* -------------------- DEPARTMENTS -------------------- */}
            {(formationSession.frenchDepartments && formationSession.frenchDepartments.length > 0) && (
                <Grid container alignItems="center">
                    <Grid
                        item xs={12} sm={4}
                        style={{ padding: 10 }}
                    >
                        <b className="primary-color">
                            Département(s)
                        </b>
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        style={{ padding: 10 }}
                    >
                        {formationSession.frenchDepartments.map(fd => fd.name + ` (${fd.number})`).join(', ')}
                    </Grid>
                </Grid>
            )}
            {/* -------------------- DESCRIPTION -------------------- */}
            {state.formation.description && (
                <Grid container alignItems="center">
                    <Grid
                        item xs={12} sm={4}
                        style={{ padding: 10 }}
                    >
                        <b className="primary-color">
                            Objectifs
                        </b>
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        style={{ padding: 10 }}
                    >
                        <span className="text">
                            {state.formation.description}
                        </span>
                    </Grid>
                </Grid>
            )}
            {/* -------------------- PREREQUISITE -------------------- */}
            {state.formation.prerequisite && (
                <Grid container alignItems="center">
                    <Grid
                        item xs={12} sm={4}
                        style={{ padding: 10 }}
                    >
                        <b className="primary-color">
                            Prérequis
                        </b>
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        style={{ padding: 10 }}
                    >
                        <span className="text">
                            {state.formation.prerequisite}
                        </span>
                    </Grid>
                </Grid>
            )}
            {/* -------------------- DURATION / CAPACITY -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4} style={{ padding: 10 }}>
                    <b className="primary-color">
                        Durée / Nombre de places
                    </b>
                </Grid>
                <Grid item xs={12} sm={8} style={{ padding: 10 }}>
                    {state.formation.duration}h / {formationSession.capacity} places maximum
                </Grid>
            </Grid>
            {/* -------------------- DOCUMENT -------------------- */}
            {formationSession.document_url && (
                <Grid container alignItems="center">
                    <Grid
                        item xs={12} sm={4}
                        style={{ padding: 10 }}
                    >
                        <b className="primary-color">
                            Programme à imprimer
                        </b>
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        style={{ padding: 10 }}
                    >
                        <Button
                            onClick={() => saveAs(fileUrl + formationSession.document_url, 'Programme formation #' + formationSession.number + getFileExtension(formationSession.document_url))}
                            color="secondary"
                            style={{ textTransform: 'none', padding: '6px 0px' }}
                        >
                            <span>
                                Télécharger
                            </span>
                            <SaveIcon style={{ marginLeft: 6 }} />
                        </Button>
                    </Grid>
                </Grid>
            )}
            {/* -------------------- MANAGER -------------------- */}
            {formationSession.manager && (
                <>
                    <Grid container alignItems="center">
                        <Grid
                            item xs={12} sm={4}
                            style={{ padding: 10 }}
                        >
                            <b className="primary-color">
                                Responsable
                            </b>
                        </Grid>
                        <Grid
                            item xs={12} sm={8}
                            style={{ padding: 10 }}
                        >
                            <span style={{ fontSize: 16, fontWeight: "bold"}}>{formationSession.manager.fullName}</span>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid
                            item xs={12} sm={4}
                            style={{ padding: 10 }}
                        >
                            <b className="primary-color">
                                Pour en savoir plus
                            </b>
                        </Grid>
                        <Grid
                            item xs={12} sm={8}
                            style={{ padding: 10 }}
                        >
                            {getContactInformation(formationSession.manager)}
                        </Grid>
                    </Grid>
                </>
            )}
            {/* -------------------- PRICES -------------------- */}
            {(formationSession.sessionPrices && formationSession.sessionPrices.length > 0) && (
                <Grid container alignItems="center">
                    <Grid
                        item xs={12} sm={4}
                        style={{ padding: 10 }}
                    >
                        <b className="primary-color">
                            Tarifs
                        </b>
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        container spacing={1}
                        style={{ padding: 4, display: 'flex' }}
                    >
                        {formationSession.sessionPrices.map(sessionPrice => (
                            <Grid
                                key={sessionPrice.id}
                                item xs="auto"
                            >
                                <div style={chipProps}>
                                    {getAmountTTC(sessionPrice.price)} {sessionPriceStatuses.find(s => s.value === sessionPrice.status)?.label}
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default FormationDetailsContainer;
