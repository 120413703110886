import React, { useState, useEffect } from 'react';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { addSessionDay } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// Views
import SessionDayForm from './session-days-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- ADD SESSION DAY -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const AddSessionDay = ({ onClose, refetch, session }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        session_date: null,
        training_place: null,
        address: '',
        city: '',
        postal_code: '',
        french_department_id: -1,
        country_id: 1,
        longitude: '',
        latitude: '',
        geocode: null,
    });

    function onAdd() {
        if (state.session_date == null) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            if ((state.latitude === '' || state.longitude === '')
                && state.country_id === 1
                && state.address !== ''
                && state.postal_code !== ''
                && state.city !== ''
            ) {
                getCoordinates(state.address, state.postal_code, state.city);
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    }

    function getCoordinates(address, postal_code, city) {
        axios.get(
            `https://api-adresse.data.gouv.fr/search/?q=${address}
                &postcode=${postal_code}
                &city=${city}
                &limit=20`
        )
        .then(res => {
            if (res.status === 200) {
                const features = res.data.features;
                if (features && features.length > 0) {
                    setState({
                        ...state,
                        loading: true,
                        longitude: features[0].geometry.coordinates[0],
                        latitude: features[0].geometry.coordinates[1],
                    });
                }
                else {
                    setState({ ...state, loading: true });
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: true });
            }
        });
    }

    useEffect(() => {
        if (state.loading) {
            addSessionDay(session.id, {
                session_date: moment(state.session_date).format('YYYY-MM-DD'),
                training_place_id: state.training_place ? state.training_place.value : null,
                address: state.address,
                city: state.city,
                postal_code: state.postal_code,
                french_department_id: state.french_department_id !== -1 ? state.french_department_id : null,
                country_id: state.country_id !== -1 ? state.country_id : null,
                longitude: state.longitude,
                latitude: state.latitude,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter une journée de formation
                        </p>
                        {/* -------------------- SESSION DAY FORM -------------------- */}
                        <SessionDayForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default AddSessionDay;
