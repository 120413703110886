import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';

// Services
import { updateUser } from 'services/requests';

// Components
import FieldRequired from 'components/field-required';

// Messages
import messages from 'messages.json';

// -------------------------------------------------------------------------------------- \\
// ----------------------------------- UPDATE PASSWORD ---------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const UpdatePassword = ({ onClose, userId }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        password: '',
        password_type: 'password',
        confirm_password: '',
        confirm_password_type: 'password',
    });

    function onChangePassworType(type) {
        setState(prevState => ({
            ...prevState,
            [type]: prevState[type] === 'password' ? 'text' : 'password',
        }));
    };

    function onSave() {
        if (state.password === '' || state.confirm_password === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (state.password !== state.confirm_password) {
            enqueueSnackbar(messages['passwords.do.not.match'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('password', state.password);

            updateUser(userId, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    onClose();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog
            open
            fullWidth
            maxWidth="xs"
        >
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Modifier son mot de passe
                </p>
                {/* -------------------- PASSWORD -------------------- */}
                <div>
                    <span className="subtitle-primary-color">
                        Nouveau mot de passe<FieldRequired />
                    </span>
                    <br />
                    <TextField
                        value={state.password}
                        onChange={e => setState({ ...state, password: e.target.value })}
                        placeholder="Nouveau mot de passe"
                        variant="outlined"
                        size="small"
                        type={state.password_type}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => onChangePassworType('password_type')}
                                        style={{ padding: 6 }}
                                    >
                                        {state.password_type === 'password' ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                {/* -------------------- CONFIRM PASSWORD -------------------- */}
                <div style={{ marginTop: 12 }}>
                    <span className="subtitle-primary-color">
                        Confirmer le mot de passe<FieldRequired />
                    </span>
                    <br />
                    <TextField
                        value={state.confirm_password}
                        onChange={e => setState({ ...state, confirm_password: e.target.value })}
                        placeholder="Confirmer le mot de passe"
                        variant="outlined"
                        size="small"
                        type={state.confirm_password_type}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => onChangePassworType('confirm_password_type')}
                                        style={{ padding: 6 }}
                                    >
                                        {state.confirm_password_type === 'password' ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    variant="contained"
                >
                    Fermer
                </Button>
                <Button
                    onClick={onSave}
                    variant="contained"
                >
                    Modifier
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdatePassword;
