// ------------------------------------------------------------------------- \\
// --------------------------- PAYMENT METHODS ----------------------------- \\
// ------------------------------------------------------------------------- \\

export const paymentMethods = [{
    value: 'cb',
    label: 'Carte bancaire'
},{
    value: 'check',
    label: 'Chèque',
}, {
    value: 'transfer',
    label: 'Virement bancaire',
}, {
    value: 'cash',
    label: 'Espèces',
}, {
    value: 'ticket',
    label: 'Chèque vacances',
}];

// ------------------------------------------------------------------------- \\
// ------------------------- SUBSCRIPTION STATUSES ------------------------- \\
// ------------------------------------------------------------------------- \\

export const subscriptionStatuses = [{
    value: 'payment_waiting',
    label: 'En attente de réception du paiement',
    color: 'orange'
}, {
    value: 'validated',
    label: 'Valide',
    color: 'green',
}, {
    value : 'cancelled',
    label: 'Annulé',
    color: 'red',
}, {
    value: 'expired',
    label: 'Expiré',
    color: 'red',
}, {
    value: 'stopped',
    label: 'Arrêté',
    color: 'red',
}];

// ------------------------------------------------------------------------- \\
// ----------------------------- BILL STATUSES ----------------------------- \\
// ------------------------------------------------------------------------- \\

export const billStatuses = [{
    value: 'payment_waiting',
    label: 'En attente du paiement',
    color: 'orange',
}, {
    value: 'paid',
    label: 'Réglé',
    color: 'green',
}, {
    value: 'cancelled',
    label: 'Annulé',
    color: 'red',
}, {
    value: 'refunded',
    label: 'Remboursé',
    color: 'green',
}, {
    value: 'payment_deferred',
    label: 'Règlement différé',
    color: 'orange',
}];

// ------------------------------------------------------------------------- \\
// --------------------------- PAYMENT STATUSES ---------------------------- \\
// ------------------------------------------------------------------------- \\

export const paymentStatuses = [{
    value: 'received',
    label: 'Reçu',
    color: 'gray',
}, {
    value: 'recovered',
    label: 'Recouvré',
    color: 'green',
}, {
    value: 'recovery_failed',
    label: 'Recouvrement échoué',
    color: 'red',
}, {
    value: 'cancelled',
    label: 'Annulé',
    color: 'red',
}];

// ------------------------------------------------------------------------- \\
// ---------------------------- MAGAZINE PERIODS --------------------------- \\
// ------------------------------------------------------------------------- \\

export const magazinePeriods = [{
    value: 'spring',
    label: 'Printemps',
}, {
    value: 'summer',
    label: 'Été',
}, {
    value: 'autumn',
    label: 'Automne',
}, {
    value: 'winter',
    label: 'Hiver',
}];

// ------------------------------------------------------------------------- \\
// --------------------------- MAGAZINES STATUSES -------------------------- \\
// ------------------------------------------------------------------------- \\

export const magazineStatuses = [{
    value: 'draft',
    label: 'Brouillon',
    color: 'orange',
}, {
    value: 'available',
    label: 'Disponible',
    color: 'green',
}, {
    value : 'unavailable',
    label: 'Indisponible',
    color: 'red',
}];

// ------------------------------------------------------------------------- \\
// ----------------------- FEDERATED ASSOCIATION TYPES --------------------- \\
// ------------------------------------------------------------------------- \\

export const federatedAssociationTypes = [{
    value: 'root',
    label: 'Siège social',
}, {
    value: 'associated',
    label: 'Association',
}, {
    value: 'antenna',
    label: 'Antenne',
}];

// ------------------------------------------------------------------------- \\
// ---------------------------- MEMBERSHIP STATUSES ------------------------ \\
// ------------------------------------------------------------------------- \\

export const membershipStatuses = [{
    value: 'active',
    label: 'Actif',
    color: 'green',
}, {
    value: 'waiting',
    label: 'En attente',
    color: 'orange',
}, {
    value: 'inactive',
    label: 'Inactif',
    color: 'red',
}];

// ------------------------------------------------------------------------- \\
// --------------------- FORMATION SESSION STATUSES ------------------------ \\
// ------------------------------------------------------------------------- \\

export const formationSessionStatuses = [{
    value: 'draft',
    label: 'Brouillon',
    color: 'orange',
}, {
    value: 'available',
    label: 'Disponible',
    color: 'green',
}, {
    value: 'closed',
    label: 'Clôturée',
    color: 'gray',
}, {
    value: 'cancelled',
    label: 'Annulée',
    color: 'red',
}];

// ------------------------------------------------------------------------- \\
// ------------------------- SESSION PRICE STATUSES ------------------------ \\
// ------------------------------------------------------------------------- \\

export const sessionPriceStatuses = [{
    value: 'vivea',
    label: 'VIVEA',
}, {
    value: 'training_fund',
    label: 'Fonds de formation',
}, {
    value: 'individual',
    label: 'Particulier',
}, {
    value: 'other',
    label: 'Autre',
}];

// ------------------------------------------------------------------------- \\
// ----------------------- FORMATION REQUEST STATUSES ---------------------- \\
// ------------------------------------------------------------------------- \\

export const formationRequestStatuses = [{
    value: 'request_payment_waiting',
    label: 'Pré inscrit - paiement en attente',
    color: 'orange',
}, {
    value: 'request_paid',
    label: 'Pré inscrit',
    color: 'orange',
}, {
    value: 'registered_payment_waiting',
    label: 'Inscrit - paiement en attente',
    color: 'orange',
}, {
    value: 'registered_paid',
    label: 'Inscrit',
    color: 'green',
}, {
    value: 'cancelled',
    label: 'Annulé',
    color: 'red',
}, {
    value: 'participation_cancelled',
    label: 'Participation annulée',
    color: 'red',
}];

// ------------------------------------------------------------------------- \\
// ----------------------------- REFUND STATUSES --------------------------- \\
// ------------------------------------------------------------------------- \\

export const refundStatuses = [{
    value: 'sent',
    label: 'Envoyé',
    color: 'orange',
}, {
    value: 'recovered',
    label: 'Recouvré',
    color: 'green',
}];

// ------------------------------------------------------------------------- \\
// ---------------------------- CUSTOM ITEM TYPES -------------------------- \\
// ------------------------------------------------------------------------- \\

export const customItemTypes = [{
    value: 'discount',
    label: 'Remise'
}, {
    value: 'supp',
    label: 'Article supplémentaire'
}];

// ------------------------------------------------------------------------- \\
// ------------------------------ ADHERENT TYPES --------------------------- \\
// ------------------------------------------------------------------------- \\

export const adherentTypes = [{
    value: 'notPro',
    label: 'Particulier',
}, {
    value: 'pro',
    label: 'Professionnel',
}];

// ------------------------------------------------------------------------- \\
// --------------------------- AGRICULTURAL METHODS ------------------------ \\
// ------------------------------------------------------------------------- \\

export const agriculturalMethods = [{
    value: 'is_in_conventional',
    label: 'Conventionnelle'
}, {
    value: 'is_in_bio',
    label: 'Biologique',
}, {
    value: 'is_in_conversion',
    label: 'En conversion bio',
}];

// ------------------------------------------------------------------------- \\
// ------------------------ CONSULTING VISIT SITUATIONS -------------------- \\
// ------------------------------------------------------------------------- \\

export const consultingVisitSituations = [{
    value: 'no_knowledge_about_biodynamic',
    label: 'Je n\'ai pas de connaissances spécifiques à la biodynamie',
}, {
    value: 'has_followed_biodynamic_training',
    label: 'J\'ai déjà suivi une formation en biodynamie',
}, {
    value: 'practice_biodynamics',
    label: 'Je pratique déjà la biodynamie',
}];

// ------------------------------------------------------------------------- \\
// -------------------------- CONSULTING VISIT NEEDS ----------------------- \\
// ------------------------------------------------------------------------- \\

export const consultingVisitNeeds = [{
    value: 'wish_diagnostic',
    label: 'Etablir un diagnostic pour accompagner la conversion',
}, {
    value: 'wish_practice_biodynamics',
    label: 'Mettre en oeuvre une des pratiques biodynamiques',
}, {
    value: 'wish_to_be_accompanied',
    label: 'Faire le point sur mes pratiques',
}];

// ------------------------------------------------------------------------- \\
// ------------------------ CONSULTING VISIT EQUIPMENTS -------------------- \\
// ------------------------------------------------------------------------- \\

export const consultingVisitEquipments = [{
    value: 'has_manual_energizer',
    label: 'J\'ai un dynamiseur manuel',
}, {
    value: 'has_mechanical_energizer',
    label: 'J\'ai un dynamiseur mécanique',
}, {
    value: 'has_backpack_sprayer',
    label: 'J\'ai un pulvérisateur à dos',
}, {
    value: 'has_mechanical_sprayer',
    label: 'J\'ai un pulvérisateur mécanique',
}];

// ------------------------------------------------------------------------- \\
// ----------------------------- TRANSFER STATUSES ------------------------- \\
// ------------------------------------------------------------------------- \\

export const transferStatuses = [{
    value: 'not_transferred',
    label: 'Non transféré',
}, {
    value: 'transferred',
    label: 'Transféré',
}, {
    value: 'not_transferable',
    label: 'Non transférable',
}];

// ------------------------------------------------------------------------- \\
// -------------------------------- BILL STATES ---------------------------- \\
// ------------------------------------------------------------------------- \\

export const billStates = [{
    value: 'not_processed',
    label: 'Non traité',
}, {
    value: 'processed',
    label: 'Traité',
}];

// ------------------------------------------------------------------------- \\
// ------------------------ CONSULTING VISIT STATUSES ---------------------- \\
// ------------------------------------------------------------------------- \\

export const consultingVisitStatuses = [{
    value: 'not_processed',
    label: 'Non traité',
}, {
    value: 'processing',
    label: 'En cours de traitement'
}, {
    value: 'processed',
    label: 'Traité'
}];
