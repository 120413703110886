import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// ------------------------------------------------------------------------------------------ \\
// ------------------------------- POSTAL CODE AND CITY INPUTS ------------------------------ \\
// ------------------------------------------------------------------------------------------ \\

const PostalCodeCityInputs = ({ state, setState, requiredPostalCode, requiredCity }) => {
    return (
        <>
            {/* -------------------- POSTAL CODE -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Code postal facturation{requiredPostalCode && <FieldRequired />}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Code postal facturation"
                        value={state.bill_postal_code}
                        onChange={e => setState({ ...state, bill_postal_code: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- CITY -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Ville facturation{requiredCity && <FieldRequired />}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Ville facturation"
                        value={state.bill_city}
                        onChange={e => setState({ ...state, bill_city: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PostalCodeCityInputs;
