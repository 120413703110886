import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogActions, DialogContent, Checkbox } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';

// Services
import { getConsultingVisits, getIndexPreferences, updateIndexPreferences } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { membershipStatuses, consultingVisitStatuses } from 'services/constants';
import { getColorStatus, getLabelStatus, getFormattedCreatedAt, getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Pagination from 'components/pagination';
import Spinner from 'components/spinner';
import Error from 'components/error';
import SelectCreatable from 'components/select-creatable';
import Tooltip from 'components/tooltip';
import PageNotFound from 'components/page-not-found';
import InputText from 'components/input-text';
import InputComparator from 'components/input-comparator';
import Select from 'components/react-select';

// Selects
import SelectCountries from 'selects/select-countries';
import SelectActivities from 'selects/select-activities';
import SelectFrenchDepartments from 'selects/select-french-departments';
import SelectMembershipStatuses from 'selects/select-membership-statuses';
import SelectMembershipTypes from 'selects/select-membership-types';
import SelectAgriculturalMethods from 'selects/select-agricultural-methods';
import SelectConsultingVisitSituations from 'selects/select-consulting-visit-situations';
import SelectConsultingVisitNeeds from 'selects/select-consulting-visit-needs';
import SelectConsultingVisitEquipments from 'selects/select-consulting-visit-equipments';
import SelectConsultingVisitStatuses from 'selects/select-consulting-visit-statuses';
import SearchAndSelectUsers from 'selects/search-and-select-users';

// Views
import UpdateConsultingVisit from './update-consulting-visit';
import ConsultingVisitsExport from './consulting-visit-export';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------- CONSULTING VISITS ROUTER ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ConsultingVisitsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <ConsultingVisits />
            </Route>
            <Route exact path={`${match.path}/:consultingVisitId`}>
                <UpdateConsultingVisit />
            </Route>
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- CONSULTING VISITS -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ConsultingVisits = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;
    const storedFilters = JSON.parse(sessionStorage.getItem('consulting-visit-filters'));

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        consulting_visits: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        searchName: '',
        reportOptions: [
            { label: 'Oui', value: 'yes' },
            { label: 'Non', value: 'no' },
        ],
        
        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_membership_status: true,
        enabled_client_code: true,
        enabled_external_client_code: true,
        enabled_membership_type: true,
        enabled_last_name: true,
        enabled_first_name: true,
        enabled_company_name: true,
        enabled_mobile_phone: true,
        enabled_phone: true,
        enabled_membership_email: true,
        enabled_french_department: true,
        enabled_city: true,
        enabled_request_date: true,
        enabled_request_year: false,
        enabled_visit_one_year: false,
        enabled_visit_date_one: false,
        enabled_visit_date_two: false,
        enabled_report_first_visit: false,
        enabled_report_second_visit: false,
        enabled_main_activity: true,
        enabled_secondary_activity: true,
        enabled_address: false,
        enabled_address_details: false,
        enabled_postal_code: false,
        enabled_country: false,
        enabled_agricultural_area: false,
        enabled_agricultural_method: false,
        enabled_situation: false,
        enabled_need: false,
        enabled_equipment: false,
        enabled_comment: false,
        enabled_contact_user: false,

        // Filters
        statuses: storedFilters?.statuses || [],
        membership_statuses: storedFilters?.membership_statuses || [],
        client_code: storedFilters?.client_code || '',
        external_client_code: storedFilters?.external_client_code || '',
        membership_types: storedFilters?.membership_types || [],
        last_name: storedFilters?.last_name || '',
        first_name: storedFilters?.first || '',
        company_name: storedFilters?.company_name || '',
        mobile_phone: storedFilters?.mobile_phone || '',
        phone: storedFilters?.phone || '',
        membership_email: storedFilters?.membership_email || '',
        french_departments: storedFilters?.french_departments || [],
        cities: storedFilters?.cities || [],
        request_date: storedFilters?.request_date || '',
        years_of_request: storedFilters?.years_of_request || [],
        years_visit_one: storedFilters?.years_visit_one || [],
        visit_date_one: storedFilters?.visit_date_one || '',
        visit_date_two: storedFilters?.visit_date_two || '',
        report_first_visit: storedFilters?.report_first_visit || '',
        report_second_visit: storedFilters?.report_second_visit || '',
        main_activities: storedFilters?.main_activities || [],
        secondary_activities: storedFilters?.secondary_activities || [],
        address: storedFilters?.address || '',
        address_details: storedFilters?.address_details || '',
        postal_codes: storedFilters?.postal_codes || [],
        countries: storedFilters?.countries || [],
        agricultural_area: storedFilters?.agricultural_area || '',
        agricultural_area_comparator: storedFilters?.agricultural_area_comparator || '<',
        agricultural_methods: storedFilters?.agricultural_methods || [],
        situations: storedFilters?.situations || [],
        needs: storedFilters?.needs || [],
        equipment: storedFilters?.equipment || [],
        comments: storedFilters?.comments || '',
        contact_users: storedFilters?.contact_users || [],
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            statuses: [],
            membership_statuses: [],
            client_code: '',
            external_client_code: '',
            membership_types: [],
            last_name: '',
            first_name: '',
            company_name: '',
            mobile_phone: '',
            phone: '',
            membership_email: '',
            french_departments: [],
            cities: [],
            request_date: '',
            years_of_request: [],
            years_visit_one: [],
            visit_date_one: '',
            visit_date_two: '',
            report_first_visit: '',
            report_second_visit: '',
            main_activities: [],
            secondary_activities: [],
            address: '',
            address_details: '',
            postal_codes: [],
            countries: [],
            agricultural_area: '',
            agricultural_area_comparator: '<',
            agricultural_methods: [],
            situations: [],
            needs: [],
            equipment: [],
            comments: '',
            contact_users: [],
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'consultingVisits',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_status: columns.enabled_status,
                            enabled_membership_status: columns.enabled_membership_status,
                            enabled_client_code: columns.enabled_client_code,
                            enabled_external_client_code: columns.enabled_external_client_code,
                            enabled_membership_type: columns.enabled_membership_type,
                            enabled_last_name: columns.enabled_last_name,
                            enabled_first_name: columns.enabled_first_name,
                            enabled_company_name: columns.enabled_company_name,
                            enabled_mobile_phone: columns.enabled_mobile_phone,
                            enabled_phone: columns.enabled_phone,
                            enabled_membership_email: columns.enabled_membership_email,
                            enabled_french_department: columns.enabled_french_department,
                            enabled_city: columns.enabled_city,
                            enabled_request_date: columns.enabled_request_date,
                            enabled_request_year: columns.enabled_request_year,
                            enabled_visit_one_year: columns.enabled_visit_one_year,
                            enabled_visit_date_one: columns.enabled_visit_date_one,
                            enabled_visit_date_two: columns.enabled_visit_date_two,
                            enabled_report_first_visit: columns.enabled_report_first_visit,
                            enabled_report_second_visit: columns.enabled_report_second_visit,
                            enabled_main_activity: columns.enabled_main_activity,
                            enabled_secondary_activity: columns.enabled_secondary_activity,
                            enabled_address: columns.enabled_address,
                            enabled_address_details: columns.enabled_address_details,
                            enabled_postal_code: columns.enabled_postal_code,
                            enabled_country: columns.enabled_country,
                            enabled_agricultural_area: columns.enabled_agricultural_area,
                            enabled_agricultural_method: columns.enabled_agricultural_method,
                            enabled_situation: columns.enabled_situation,
                            enabled_need: columns.enabled_need,
                            enabled_equipment: columns.enabled_equipment,
                            enabled_comment: columns.enabled_comment,
                            enabled_contact_user: columns.enabled_contact_user,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            sessionStorage.setItem('consulting-visit-filters', JSON.stringify({
                statuses: state.statuses,
                membership_statuses: state.membership_statuses,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                membership_types: state.membership_types,
                last_name: state.last_name,
                first_name: state.first_name,
                company_name: state.company_name,
                mobile_phone: state.mobile_phone,
                phone: state.phone,
                membership_email: state.membership_email,
                french_departments: state.french_departments,
                cities: state.cities,
                request_date: state.request_date,
                years_of_request: state.years_of_request,
                years_visit_one: state.years_visit_one,
                visit_date_one: state.visit_date_one,
                visit_date_two: state.visit_date_two,
                report_first_visit: state.report_first_visit,
                report_second_visit: state.report_second_visit,
                main_activities: state.main_activities,
                secondary_activities: state.secondary_activities,
                address: state.address,
                address_details: state.address_details,
                postal_codes: state.postal_codes,
                countries: state.countries,
                agricultural_area: state.agricultural_area,
                agricultural_area_comparator: state.agricultural_area_comparator,
                agricultural_methods: state.agricultural_methods,
                situations: state.situations,
                needs: state.needs,
                equipment: state.equipment,
                comments: state.comments,
                contact_users: state.contact_users,
            }));

            getConsultingVisits({
                offset: state.offset,
                limit: state.limit,
                statuses: state.statuses ? state.statuses.map(s => s.value) : null,
                membership_statuses: state.membership_statuses ? state.membership_statuses.map(s => s.value) : null,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                membership_type_ids: state.membership_types ? state.membership_types.map(t => t.value) : null,
                last_name: state.last_name,
                first_name: state.first_name,
                company_name: state.company_name,
                mobile_phone: state.mobile_phone,
                phone: state.phone,
                membership_email: state.membership_email,
                french_department_ids: state.french_departments ? state.french_departments.map(d => d.value) : null,
                cities: state.cities ? state.cities.map(c => c.value) : null,
                request_date: state.request_date,
                years_of_request: state.years_of_request ? state.years_of_request.map(y => y.value) : null,
                years_visit_one: state.years_visit_one ? state.years_visit_one.map(y => y.value) : null,
                visit_date_one: state.visit_date_one,
                visit_date_two: state.visit_date_two,
                report_first_visit: state.report_first_visit?.value,
                report_second_visit: state.report_second_visit?.value,
                main_activity_ids: state.main_activities ? state.main_activities.map(a => a.value) : null,
                secondary_activity_ids: state.secondary_activities ? state.secondary_activities.map(a => a.value) : null,
                address: state.address,
                address_details: state.address_details,
                postal_codes: state.postal_codes ? state.postal_codes.map(c => c.value) : null,
                country_ids: state.countries ? state.countries.map(c => c.value) : null,
                agricultural_area: state.agricultural_area,
                agricultural_area_comparator: state.agricultural_area_comparator,
                agricultural_methods: state.agricultural_methods ? state.agricultural_methods.map(m => m.value) : null,
                situations: state.situations ? state.situations.map(s => s.value) : null,
                needs: state.needs ? state.needs.map(n => n.value) : null,
                equipment: state.equipment ? state.equipment.map(e => e.value) : null,
                comments: state.comments,
                contact_user_ids: state.contact_users ? state.contact_users.map(c => c.value) : null,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        consulting_visits: res.data.data.consultingVisits,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    {pathname.includes('/management') && (
                        <Button variant="contained" onClick={() => history.push('/management')}>
                            Retour
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Visite-conseil
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <ConsultingVisitsExport data={state} />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <ConsultingVisitList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    Statut visite-conseil
                </div>
            )}
            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
            {state.enabled_membership_status && (
                <div className="column-list">
                    Statut adhésion
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    Code client
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    Code client externe
                </div>
            )}
            {/* -------------------- MEMBERSHIP TYPE -------------------- */}
            {state.enabled_membership_type && (
                <div className="column-list">
                    Type d'adhésion
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    Nom
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    Prénom
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    Nom de l'entreprise
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    Téléphone portable
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    Téléphone fixe
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    Email adhésion
                </div>
            )}
            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    Département
                </div>
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <div className="column-list">
                    Ville
                </div>
            )}
            {/* -------------------- REQUEST DATE -------------------- */}
            {state.enabled_request_date && (
                <div className="column-list">
                    Date de la demande
                </div>
            )}
            {/* -------------------- REQUEST YEAR -------------------- */}
            {state.enabled_request_year && (
                <div className="column-list">
                    Année de la demande
                </div>
            )}
            {/* -------------------- VISIT ONE YEAR -------------------- */}
            {state.enabled_visit_one_year && (
                <div className="column-list">
                    Année visite 1
                </div>
            )}
            {/* -------------------- VISIT DATE ONE -------------------- */}
            {state.enabled_visit_date_one && (
                <div className="column-list">
                    Date visite 1
                </div>
            )}
            {/* -------------------- VISIT DATE TWO -------------------- */}
            {state.enabled_visit_date_two && (
                <div className="column-list">
                    Date visite 2
                </div>
            )}
            {/* -------------------- REPORT FIRST VISIT -------------------- */}
            {state.enabled_report_first_visit && (
                <div className="column-list">
                    Rapport 1
                </div>
            )}
            {/* -------------------- REPORT SECOND VISIT -------------------- */}
            {state.enabled_report_second_visit && (
                <div className="column-list">
                    Rapport 2
                </div>
            )}
            {/* -------------------- MAIN ACTIVITY -------------------- */}
            {state.enabled_main_activity && (
                <div className="column-list">
                    Activité principale
                </div>
            )}
            {/* -------------------- SECONDARY ACTIVITY -------------------- */}
            {state.enabled_secondary_activity && (
                <div className="column-list">
                    Activité secondaire
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    Adresse ADH (n° et voie)
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    Adresse ADH (complément)
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    Code postal
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    Pays
                </div>
            )}
            {/* -------------------- AGRICULTURAL AREA -------------------- */}
            {state.enabled_agricultural_area && (
                <div className="column-list">
                    Surface agricole (ha)
                </div>
            )}
            {/* -------------------- AGRICULTURAL METHOD -------------------- */}
            {state.enabled_agricultural_method && (
                <div className="column-list">
                    Conduite agricole
                </div>
            )}
            {/* -------------------- SITUATION -------------------- */}
            {state.enabled_situation && (
                <div className="column-list">
                    Situation
                </div>
            )}
            {/* -------------------- NEED -------------------- */}
            {state.enabled_need && (
                <div className="column-list">
                    Besoins de la visite
                </div>
            )}
            {/* -------------------- EQUIPMENT -------------------- */}
            {state.enabled_equipment && (
                <div className="column-list">
                    Matériel
                </div>
            )}
            {/* -------------------- COMMENT -------------------- */}
            {state.enabled_comment && (
                <div className="column-list">
                    Commentaires
                </div>
            )}
            {/* -------------------- CONTACT USER -------------------- */}
            {state.enabled_contact_user && (
                <div className="column-list">
                    Accompagnateur
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    <SelectConsultingVisitStatuses
                        statuses={state.statuses}
                        setStatuses={statuses => onSelectSearch('statuses', statuses)}
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
            {state.enabled_membership_status && (
                <div className="column-list">
                    <SelectMembershipStatuses
                        statuses={state.membership_statuses}
                        setStatuses={membership_statuses => onSelectSearch('membership_statuses', membership_statuses)}
                    />
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client"
                        value={state.client_code}
                        onChange={e => setState({ ...state, client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client externe"
                        value={state.external_client_code}
                        onChange={e => setState({ ...state, external_client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP TYPE -------------------- */}
            {state.enabled_membership_type && (
                <div className="column-list">
                    <SelectMembershipTypes
                        membershipTypes={state.membership_types}
                        setMembershipTypes={membership_types => onSelectSearch('membership_types', membership_types)}
                    />
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    <InputText
                        label="Nom"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    <InputText
                        label="Prénom"
                        value={state.first_name}
                        onChange={e => setState({ ...state, first_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    <InputText
                        label="Nom de l'entreprise"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone portable"
                        value={state.mobile_phone}
                        onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone fixe"
                        value={state.phone}
                        onChange={e => setState({ ...state, phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    <InputText
                        label="Email adhésion"
                        value={state.membership_email}
                        onChange={e => setState({ ...state, membership_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    <SelectFrenchDepartments
                        frenchDepartments={state.french_departments}
                        setFrenchDepartments={french_departments => onSelectSearch('french_departments', french_departments)}
                    />
                </div>
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.cities}
                        onChange={cities => onSelectSearch('cities', cities)}
                        placeholder="Ville"
                    />
                </div>
            )}
            {/* -------------------- REQUEST DATE -------------------- */}
            {state.enabled_request_date && (
                <div className="column-list">
                    <InputText
                        label="Date de demande"
                        value={state.request_date}
                        onChange={e => setState({ ...state, request_date: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- REQUEST YEAR -------------------- */}
            {state.enabled_request_year && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.years_of_request}
                        onChange={year => onSelectSearch('years_of_request', year)}
                        placeholder="Année(s) de la demande"
                    />
                </div>
            )}
            {/* -------------------- VISIT ONE YEAR -------------------- */}
            {state.enabled_visit_one_year && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.years_visit_one}
                        onChange={year => onSelectSearch('years_visit_one', year)}
                        placeholder="Année(s) visite 1"
                    />
                </div>
            )}
            {/* -------------------- VISIT DATE ONE -------------------- */}
            {state.enabled_visit_date_one && (
                <div className="column-list">
                    <InputText
                        label="Date visite 1 ou vide"
                        value={state.visit_date_one}
                        onChange={e => setState({ ...state, visit_date_one: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- VISIT DATE TWO -------------------- */}
            {state.enabled_visit_date_two && (
                <div className="column-list">
                    <InputText
                        label="Date visite 2 ou vide"
                        value={state.visit_date_two}
                        onChange={e => setState({ ...state, visit_date_two: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- REPORT FIRST VISIT -------------------- */}
            {state.enabled_report_first_visit && (
                <div className="column-list">
                    <Select
                        placeholder="Rapport présent"
                        value={state.report_first_visit}
                        onChange={reportPresent => onSelectSearch('report_first_visit', reportPresent)}
                        options={state.reportOptions}
                    />
                </div>
            )}
            {/* -------------------- REPORT SECOND VISIT -------------------- */}
            {state.enabled_report_second_visit && (
                <div className="column-list">
                    <Select
                        placeholder="Rapport présent"
                        value={state.report_second_visit}
                        onChange={reportPresent => onSelectSearch('report_second_visit', reportPresent)}
                        options={state.reportOptions}
                    />
                </div>
            )}
            {/* -------------------- MAIN ACTIVITY -------------------- */}
            {state.enabled_main_activity && (
                <div className="column-list">
                    <SelectActivities
                        label="Activité principale"
                        activities={state.main_activities}
                        setActivities={main_activities => onSelectSearch('main_activities', main_activities)}
                    />
                </div>
            )}
            {/* -------------------- SECONDARY ACTIVITY -------------------- */}
            {state.enabled_secondary_activity && (
                <div className="column-list">
                    <SelectActivities
                        label="Activité secondaire"
                        activities={state.secondary_activities}
                        setActivities={secondary_activities => onSelectSearch('secondary_activities', secondary_activities)}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    <InputText
                        label="Adresse (n° et voie)"
                        value={state.address}
                        onChange={e => setState({ ...state, address: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    <InputText
                        label="Adresse (complément)"
                        value={state.address_details}
                        onChange={e => setState({ ...state, address_details: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    <SelectCreatable
                        placeholder="Code postal"
                        value={state.postal_codes}
                        onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                    />
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    <SelectCountries
                        countries={state.countries}
                        setCountries={countries => onSelectSearch('countries', countries)}
                    />
                </div>
            )}
            {/* -------------------- AGRICULTURAL AREA -------------------- */}
            {state.enabled_agricultural_area && (
                <div className="column-list">
                    <InputComparator
                        label="Surface agricole (ha)"
                        value={state.agricultural_area}
                        onChange={e => setState({ ...state, agricultural_area: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                        comparator={state.agricultural_area_comparator}
                        onChangeComparator={() => setState({ ...state, agricultural_area_comparator: state.agricultural_area_comparator === '>' ? '<' : '>' })}
                    />
                </div>
            )}
            {/* -------------------- AGRICULTURAL METHOD -------------------- */}
            {state.enabled_agricultural_method && (
                <div className="column-list">
                    <SelectAgriculturalMethods
                        methods={state.agricultural_methods}
                        setMethods={agricultural_methods => onSelectSearch('agricultural_methods', agricultural_methods)}
                    />
                </div>
            )}
            {/* -------------------- SITUATION -------------------- */}
            {state.enabled_situation && (
                <div className="column-list">
                    <SelectConsultingVisitSituations
                        situations={state.situations}
                        setSituations={situations => onSelectSearch('situations', situations)}
                    />
                </div>
            )}
            {/* -------------------- NEED -------------------- */}
            {state.enabled_need && (
                <div className="column-list">
                    <SelectConsultingVisitNeeds
                        needs={state.needs}
                        setNeeds={needs => onSelectSearch('needs', needs)}
                    />
                </div>
            )}
            {/* -------------------- EQUIPMENT -------------------- */}
            {state.enabled_equipment && (
                <div className="column-list">
                    <SelectConsultingVisitEquipments
                        equipments={state.equipment}
                        setEquipments={equipment => onSelectSearch('equipment', equipment)} 
                    />
                </div>
            )}
            {/* -------------------- COMMENT -------------------- */}
            {state.enabled_comment && (
                <div className="column-list">
                    <InputText
                        label="Commentaires"
                        value={state.comments}
                        onChange={e => setState({ ...state, comments: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- CONTACT USER -------------------- */}
            {state.enabled_contact_user && (
                <div className="column-list">
                    <SearchAndSelectUsers
                        users={state.contact_users}
                        setUsers={contact_users => onSelectSearch('contact_users', contact_users)}
                        placeholder="Accompagnateur"
                        userCategoryId={6}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- CONSULTING VISIT LIST ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ConsultingVisitList = ({ state }) => {
    const history = useHistory();
    const match = useRouteMatch();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.consulting_visits.length > 0 && (
                state.consulting_visits.map(consulting_visit => (
                    <Fragment key={consulting_visit.id}>
                        <div
                            onClick={() => history.push(`${match.path}/${consulting_visit.id}`)}
                            className="simple-list"
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- STATUS -------------------- */}
                            {state.enabled_status && (
                                <div className="column-list">
                                    {getLabelStatus(consultingVisitStatuses, consulting_visit.status)}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
                            {state.enabled_membership_status && (
                                <div className="column-list">
                                    <b style={{ color: getColorStatus(membershipStatuses, (consulting_visit.user ? consulting_visit.user.membershipStatus : null)) }}>
                                        {getLabelStatus(membershipStatuses, (consulting_visit.user ? consulting_visit.user.membershipStatus : null))}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- CLIENT CODE -------------------- */}
                            {state.enabled_client_code && (
                                <div className="column-list">
                                    {consulting_visit.user ? consulting_visit.user.clientCode : ''}
                                </div>
                            )}
                            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                            {state.enabled_external_client_code && (
                                <div className="column-list">
                                    {consulting_visit.user ? consulting_visit.user.external_client_code : ''}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP TYPE -------------------- */}
                            {state.enabled_membership_type && (
                                <div className="column-list">
                                    {(consulting_visit.membership && consulting_visit.membership.membershipType) ? consulting_visit.membership.membershipType.name : ''}
                                </div>
                            )}
                            {/* -------------------- LAST NAME -------------------- */}
                            {state.enabled_last_name && (
                                <div className="column-list">
                                    {consulting_visit.user ? consulting_visit.user.last_name : ''}
                                </div>
                            )}
                            {/* -------------------- FIRST NAME -------------------- */}
                            {state.enabled_first_name && (
                                <div className="column-list">
                                    {consulting_visit.user ? consulting_visit.user.first_name : ''}
                                </div>
                            )}
                            {/* -------------------- COMPANY NAME -------------------- */}
                            {state.enabled_company_name && (
                                <div className="column-list">
                                    {consulting_visit.user ? consulting_visit.user.company_name : ''}
                                </div>
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <div className="column-list">
                                    {consulting_visit.user ? consulting_visit.user.mobile_phone : ''}
                                </div>
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <div className="column-list">
                                    {consulting_visit.user ? consulting_visit.user.phone : ''}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                            {state.enabled_membership_email && (
                                <div className="column-list">
                                    {consulting_visit.membership ? consulting_visit.membership.email : ''}
                                </div>
                            )}
                            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                            {state.enabled_french_department && (
                                <div className="column-list">
                                    {(consulting_visit.user && consulting_visit.user.department) ? (consulting_visit.user.department.name + ' (' + consulting_visit.user.department.number + ')') : ''}
                                </div>
                            )}
                            {/* -------------------- CITY -------------------- */}
                            {state.enabled_city && (
                                <div className="column-list">
                                    {consulting_visit.membership ? consulting_visit.membership.city : ''}
                                </div>
                            )}
                            {/* -------------------- REQUEST DATE -------------------- */}
                            {state.enabled_request_date && (
                                <div className="column-list">
                                    {consulting_visit.created_at ? getFormattedCreatedAt(consulting_visit.created_at) : ''}
                                </div>
                            )}
                            {/* -------------------- REQUEST YEAR -------------------- */}
                            {state.enabled_request_year && (
                                <div className="column-list">
                                    {consulting_visit.created_at ? consulting_visit.created_at.substring(0, 4) : ''}
                                </div>
                            )}
                            {/* -------------------- VISIT ONE YEAR -------------------- */}
                            {state.enabled_visit_one_year && (
                                <div className="column-list">
                                    {consulting_visit.visit_date_one ? consulting_visit.visit_date_one.substring(0, 4) : ''}
                                </div>
                            )}
                            {/* -------------------- VISIT DATE ONE -------------------- */}
                            {state.enabled_visit_date_one && (
                                <div className="column-list">
                                    {consulting_visit.visit_date_one ? getFormattedDate(consulting_visit.visit_date_one) : ''}
                                </div>
                            )}
                            {/* -------------------- VISIT DATE TWO -------------------- */}
                            {state.enabled_visit_date_two && (
                                <div className="column-list">
                                    {consulting_visit.visit_date_two ? getFormattedDate(consulting_visit.visit_date_two) : ''}
                                </div>
                            )}
                            {/* -------------------- REPORT FIRST VISIT -------------------- */}
                            {state.enabled_report_first_visit && (
                                <div className="column-list">
                                    {consulting_visit.first_visit_doc_url ? "Oui" : "Non"}
                                </div>
                            )}
                            {/* -------------------- REPORT SECOND VISIT -------------------- */}
                            {state.enabled_report_second_visit && (
                                <div className="column-list">
                                    {consulting_visit.second_visit_doc_url ? "Oui" : "Non"}
                                </div>
                            )}
                            {/* -------------------- MAIN ACTIVITY -------------------- */}
                            {state.enabled_main_activity && (
                                <div className="column-list">
                                    {consulting_visit.membership && consulting_visit.membership.mainActivity ? consulting_visit.membership.mainActivity.name : ''}
                                </div>
                            )}
                            {/* -------------------- SECONDARY ACTIVITY -------------------- */}
                            {state.enabled_secondary_activity && (
                                <div className="column-list">
                                    {consulting_visit.membership && consulting_visit.membership.secondaryActivities ? consulting_visit.membership.secondaryActivities.map((activity, index) => {
                                        if (index === 0) return activity.name;
                                        else return ', ' + activity.name;
                                    }) : ''}
                                </div>
                            )}
                            {/* -------------------- ADDRESS -------------------- */}
                            {state.enabled_address && (
                                <div className="column-list">
                                    {consulting_visit.membership ? consulting_visit.membership.address : ''}
                                </div>
                            )}
                            {/* -------------------- ADDRESS DETAILS -------------------- */}
                            {state.enabled_address_details && (
                                <div className="column-list">
                                    {consulting_visit.membership ? consulting_visit.membership.address_details : ''}
                                </div>
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <div className="column-list">
                                    {consulting_visit.membership ? consulting_visit.membership.postal_code : ''}
                                </div>
                            )}
                            {/* -------------------- COUNTRY -------------------- */}
                            {state.enabled_country && (
                                <div className="column-list">
                                    {(consulting_visit.membership && consulting_visit.membership.country) ? consulting_visit.membership.country.name : ''}
                                </div>
                            )}
                            {/* -------------------- AGRICULTURAL AREA -------------------- */}
                            {state.enabled_agricultural_area && (
                                <div className="column-list">
                                    {consulting_visit.agricultural_area}
                                </div>
                            )}
                            {/* -------------------- AGRICULTURAL METHOD -------------------- */}
                            {state.enabled_agricultural_method && (
                                <div className="column-list">
                                    {consulting_visit.is_in_conventional ? (
                                        <>Conventionnelle<br /></>
                                    ) : ''}
                                    {consulting_visit.is_in_bio ? (
                                        <>Biologique<br /></>
                                    ) : ''}
                                    {consulting_visit.is_in_conversion ? (
                                        <>En conversion bio<br /></>
                                    ) : ''}
                                </div>
                            )}
                            {/* -------------------- SITUATION -------------------- */}
                            {state.enabled_situation && (
                                <div className="column-list">
                                    {consulting_visit.has_followed_biodynamic_training ? (
                                        <>J'ai déjà suivi une formation en biodynamie<br /></>
                                    ) : ''}
                                    {consulting_visit.practice_biodynamics ? (
                                        <>Je pratique déjà la biodynamie<br /></>
                                    ) : ''}
                                </div>
                            )}
                            {/* -------------------- NEED -------------------- */}
                            {state.enabled_need && (
                                <div className="column-list">
                                    {consulting_visit.wish_diagnostic ? (
                                        <>Etablir un diagnostic pour accompagner la conversion<br /></>
                                    ) : ''}
                                    {consulting_visit.wish_practice_biodynamics ? (
                                        <>Mettre en oeuvre une des pratiques biodynamiques<br /></>
                                    ) : ''}
                                    {consulting_visit.wish_to_be_accompanied ? (
                                        <>Faire le point sur mes pratiques<br /></>
                                    ) : ''}
                                </div>
                            )}
                            {/* -------------------- EQUIPMENT -------------------- */}
                            {state.enabled_equipment && (
                                <div className="column-list">
                                    {consulting_visit.has_manual_energizer ? (
                                        <>J'ai un dynamiseur manuel<br /></>
                                    ) : ''}
                                    {consulting_visit.has_mechanical_energizer ? (
                                        <>J'ai un dynamiseur mécanique<br /></>
                                    ) : ''}
                                    {consulting_visit.has_backpack_sprayer ? (
                                        <>J'ai un pulvérisateur à dos<br /></>
                                    ) : ''}
                                    {consulting_visit.has_mechanical_sprayer ? (
                                        <>J'ai un pulvérisateur mécanique<br /></>
                                    ) : ''}
                                </div>
                            )}
                            {/* -------------------- COMMENT -------------------- */}
                            {state.enabled_comment && (
                                <div className="column-list">
                                    {consulting_visit.comments}
                                </div>
                            )}
                            {/* -------------------- CONTACT USER -------------------- */}
                            {state.enabled_contact_user && (
                                <div className="column-list">
                                    {consulting_visit.contactUser?.fullName}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_status: state.enabled_status,
            enabled_membership_status: state.enabled_membership_status,
            enabled_client_code: state.enabled_client_code,
            enabled_external_client_code: state.enabled_external_client_code,
            enabled_membership_type: state.enabled_membership_type,
            enabled_last_name: state.enabled_last_name,
            enabled_first_name: state.enabled_first_name,
            enabled_company_name: state.enabled_company_name,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_phone: state.enabled_phone,
            enabled_membership_email: state.enabled_membership_email,
            enabled_french_department: state.enabled_french_department,
            enabled_city: state.enabled_city,
            enabled_request_date: state.enabled_request_date,
            enabled_request_year: state.enabled_request_year,
            enabled_visit_one_year: state.enabled_visit_one_year,
            enabled_visit_date_one: state.enabled_visit_date_one,
            enabled_visit_date_two: state.enabled_visit_date_two,
            enabled_report_first_visit: state.enabled_report_first_visit,
            enabled_report_second_visit: state.enabled_report_second_visit,
            enabled_main_activity: state.enabled_main_activity,
            enabled_secondary_activity: state.enabled_secondary_activity,
            enabled_address: state.enabled_address,
            enabled_address_details: state.enabled_address_details,
            enabled_postal_code: state.enabled_postal_code,
            enabled_country: state.enabled_country,
            enabled_agricultural_area: state.enabled_agricultural_area,
            enabled_agricultural_method: state.enabled_agricultural_method,
            enabled_situation: state.enabled_situation,
            enabled_need: state.enabled_need,
            enabled_equipment: state.enabled_equipment,
            enabled_comment: state.enabled_comment,
            enabled_contact_user: state.enabled_contact_user,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'consultingVisits',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                <div style={{ display: 'flex' }}>
                    <div>
                        {/* -------------------- STATUS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_status}
                                onChange={e => setState({ ...state, enabled_status: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Statut visite-conseil
                        </Grid>
                        {/* -------------------- MEMBERSHIP STATUS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_membership_status}
                                onChange={e => setState({ ...state, enabled_membership_status: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Statut adhésion
                        </Grid>
                        {/* -------------------- CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_client_code}
                                onChange={e => setState({ ...state, enabled_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client
                        </Grid>
                        {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_external_client_code}
                                onChange={e => setState({ ...state, enabled_external_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client externe
                        </Grid>
                        {/* -------------------- MEMBERSHIP TYPE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_membership_type}
                                onChange={e => setState({ ...state, enabled_membership_type: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Type d'adhésion
                        </Grid>
                        {/* -------------------- LAST NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_last_name}
                                onChange={e => setState({ ...state, enabled_last_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom
                        </Grid>
                        {/* -------------------- FIRST NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_first_name}
                                onChange={e => setState({ ...state, enabled_first_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Prénom
                        </Grid>
                        {/* -------------------- COMPANY NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_company_name}
                                onChange={e => setState({ ...state, enabled_company_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom de l'entreprise
                        </Grid>
                        {/* -------------------- MOBILE PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_mobile_phone}
                                onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone portable
                        </Grid>
                        {/* -------------------- PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_phone}
                                onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone fixe
                        </Grid>
                        {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_membership_email}
                                onChange={e => setState({ ...state, enabled_membership_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email adhésion
                        </Grid>
                        {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_french_department}
                                onChange={e => setState({ ...state, enabled_french_department: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Département
                        </Grid>
                        {/* -------------------- CITY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_city}
                                onChange={e => setState({ ...state, enabled_city: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Ville
                        </Grid>
                        {/* -------------------- REQUEST DATE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_request_date}
                                onChange={e => setState({ ...state, enabled_request_date: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Date de demande
                        </Grid>
                        {/* -------------------- REQUEST YEAR -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_request_year}
                                onChange={e => setState({ ...state, enabled_request_year: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Année de la demande
                        </Grid>
                        {/* -------------------- VISIT ONE YEAR -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_visit_one_year}
                                onChange={e => setState({ ...state, enabled_visit_one_year: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Année visite 1
                        </Grid>
                        {/* -------------------- VISIT DATE ONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_visit_date_one}
                                onChange={e => setState({ ...state, enabled_visit_date_one: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Date visite 1
                        </Grid>
                    </div>
                    <div>
                        {/* -------------------- VISIT DATE TWO -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_visit_date_two}
                                onChange={e => setState({ ...state, enabled_visit_date_two: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Date visite 2
                        </Grid>
                        {/* -------------------- REPORT FIRST VISIT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_report_first_visit}
                                onChange={e => setState({ ...state, enabled_report_first_visit: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Rapport 1
                        </Grid>
                        {/* -------------------- REPORT SECOND VISIT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_report_second_visit}
                                onChange={e => setState({ ...state, enabled_report_second_visit: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Rapport 2
                        </Grid>
                        {/* -------------------- MAIN ACTIVITY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_main_activity}
                                onChange={e => setState({ ...state, enabled_main_activity: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Activité principale
                        </Grid>
                        {/* -------------------- SECONDARY ACTIVITY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_secondary_activity}
                                onChange={e => setState({ ...state, enabled_secondary_activity: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Activité secondaire
                        </Grid>
                        {/* -------------------- ADDRESS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address}
                                onChange={e => setState({ ...state, enabled_address: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse ADH (n° et voie)
                        </Grid>
                        {/* -------------------- ADDRESS DETAILS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address_details}
                                onChange={e => setState({ ...state, enabled_address_details: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse ADH (complément)
                        </Grid>
                        {/* -------------------- POSTAL CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_postal_code}
                                onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code postal
                        </Grid>
                        {/* -------------------- COUNTRY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_country}
                                onChange={e => setState({ ...state, enabled_country: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Pays
                        </Grid>
                        {/* -------------------- AGRICULTURAL AREA -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_agricultural_area}
                                onChange={e => setState({ ...state, enabled_agricultural_area: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Surface agricole (ha)
                        </Grid>
                        {/* -------------------- AGRICULTURAL METHOD -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_agricultural_method}
                                onChange={e => setState({ ...state, enabled_agricultural_method: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Conduite agricole
                        </Grid>
                        {/* -------------------- SITUATION -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_situation}
                                onChange={e => setState({ ...state, enabled_situation: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Situation
                        </Grid>
                        {/* -------------------- NEED -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_need}
                                onChange={e => setState({ ...state, enabled_need: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Besoins pour la visite
                        </Grid>
                        {/* -------------------- EQUIPMENT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_equipment}
                                onChange={e => setState({ ...state, enabled_equipment: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Matériel
                        </Grid>
                        {/* -------------------- COMMENT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_comment}
                                onChange={e => setState({ ...state, enabled_comment: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Commentaires
                        </Grid>
                        {/* -------------------- CONTACT USER -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_contact_user}
                                onChange={e => setState({ ...state, enabled_contact_user: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Accompagnateur
                        </Grid>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConsultingVisitsRouter;
