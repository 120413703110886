import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getSubscriptionsToAddInMailingList } from 'services/requests';
import { subscriptionStatuses, membershipStatuses } from 'services/constants';
import { getLabelStatus, getMonthYearDate, getFormattedDate } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------- SUBSCRIPTIONS FOR MAILING LIST EXPORT ---------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SubscriptionsForMailingListExport = ({ mailingListId, data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getStringFromArray(array) {
        let result = '';
        array.forEach((item, index) => {
            if (index === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });
        return result;
    }

    function getAllSubscriptionsToExport(subscriptions, offset) {
        getSubscriptionsToAddInMailingList(mailingListId, {
            offset: offset,
            limit: data.limit,
            name: data.name,
            first_name: data.first_name,
            last_name: data.last_name,
            subscription_type_ids: data.subscription_types ? data.subscription_types.map(s => s.value) : null,
            statuses: data.statuses ? data.statuses.map(s => s.value) : null,
        }).then(res => {
            if (res.status === 200) {

                let res_subscriptions = res.data.data.subscriptions;
                subscriptions = subscriptions.concat(res_subscriptions);

                setState({
                    ...state,
                    percentage: Math.round((100 * subscriptions.length) / data.total_count) + '%',
                });

                if (res_subscriptions.length < data.limit) {
                    createExcelFile(subscriptions);
                }
                else {
                    getAllSubscriptionsToExport(subscriptions, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(subscriptions) {
        if (subscriptions.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Liste des nouveaux abonnements');

            // Header
            worksheet.columns = [
                { header: 'Statut de l\'envoi', key: 'is_sent_status', width: 30 },
                { header: 'Statut abonnement', key: 'status', width: 30 },
                { header: 'Code client', key: 'client_code', width: 30 },
                { header: 'Code client externe', key: 'external_client_code', width: 30 },
                { header: 'Type d\'abonnement', key: 'subscription_type', width: 30 },
                { header: 'Statut adhésion', key: 'membership_status', width: 30 },
                { header: 'Nom', key: 'last_name', width: 30 },
                { header: 'Prénom', key: 'first_name', width: 30 },
                { header: 'Nom de l\'entreprise', key: 'company_name', width: 30 },
                { header: 'Téléphone portable', key: 'mobile_phone', width: 30 },
                { header: 'Email', key: 'email', width: 30 },
                { header: 'Département', key: 'department', width: 30 },
                { header: 'Ville', key: 'city', width: 30 },
                { header: 'Code postal', key: 'postal_code', width: 30 },
                { header: 'Adresse (n° et voie)', key: 'address', width: 30 },
                { header: 'Adresse (complément)', key: 'address_details', width: 30 },
                { header: 'Pays', key: 'country', width: 30 },
                { header: 'Téléphone', key: 'phone', width: 30 },
                { header: 'Autre téléphone', key: 'other_phone', width: 30 },
                { header: 'Email abonnement', key: 'subscription_email', width: 30 },
                { header: 'Date d\'expiration abo.', key: 'subscription_expiration', width: 30 },
                { header: 'Mois / Année expiration abo.', key: 'month_year_subscription_expiration', width: 30 },
                { header: 'Rôle', key: 'user_category', width: 30 },
                { header: 'Date de naissance', key: 'birth_date', width: 30 },
                { header: 'Association fédérée', key: 'federated_association', width: 30 },
                { header: 'Date d\'expiration adhé.', key: 'membership_expiration', width: 30 },
                { header: 'Email adhésion', key: 'membership_email', width: 30 },
                { header: 'Email bon de commande', key: 'bill_email', width: 30 },
                { header: 'Type d\'adhérent', key: 'adherent_type', width: 30 },
                { header: 'Dernier n° reçu', key: 'last_number_received', width: 30 },
                { header: 'Abonné jusqu\'au n°', key: 'last_number_to_receive', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            subscriptions.map(subscription => {
                return worksheet.addRow({
                    is_sent_status: subscription.is_sent === 1 ? 'Envoyé' : 'Non envoyé',
                    status: getLabelStatus(subscriptionStatuses, subscription.status),
                    client_code: subscription.user ? subscription.user.clientCode : '',
                    external_client_code: subscription.user ? subscription.user.external_client_code : '',
                    subscription_type: subscription.subscriptionType ? subscription.subscriptionType.name : '',
                    membership_status: subscription.user ? getLabelStatus(membershipStatuses, subscription.user.membershipStatus) : '',
                    last_name: subscription.last_name || '',
                    first_name: subscription.first_name || '',
                    company_name: subscription.company_name || '',
                    mobile_phone: subscription.user ? subscription.user.mobile_phone : '',
                    email: subscription.user ? subscription.user.email : '',
                    department: (subscription.user && subscription.user.department) ? (subscription.user.department.name + ' (' + subscription.user.department.number + ')') : '',
                    city: subscription.city || '',
                    postal_code: subscription.postal_code || '',
                    address: subscription.address || '',
                    address_details: subscription.address_details || '',
                    country: subscription.country ? subscription.country.name : '',
                    phone: subscription.user ? subscription.user.phone : '',
                    other_phone: subscription.user ? subscription.user.other_phone : '',
                    subscription_email: subscription.email || '',
                    subscription_expiration: subscription.end_date ? getFormattedDate(subscription.end_date) : '',
                    month_year_subscription_expiration: subscription.end_date ? getMonthYearDate(subscription.end_date) : '',
                    user_category: (subscription.user && subscription.user.userCategories) ? getStringFromArray(subscription.user.userCategories) : '',
                    birth_date: (subscription.user && subscription.user.birth_date) ? getFormattedDate(subscription.user.birth_date) : '',
                    federated_association: (subscription.user && subscription.user.federatedAssociation) ? subscription.user.federatedAssociation.name : '',
                    membership_expiration: (subscription.user && subscription.user.membershipExpiration) ? getFormattedDate(subscription.user.membershipExpiration) : '',
                    membership_email: subscription.user ? subscription.user.membershipEmail : '',
                    bill_email: subscription.user ? subscription.user.billEmail : '',
                    adherent_type: subscription.user ? subscription.user.adherentType : '',
                    last_number_received: subscription.lastNumberReceived || '',
                    last_number_to_receive: subscription.lastNumberToReceive || '',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'nouveaux-abonnements.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllSubscriptionsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default SubscriptionsForMailingListExport;
