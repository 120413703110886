import React, { useState, useEffect, useRef } from 'react';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Material-UI
import { Grid, Divider } from '@material-ui/core';

// Services
import { getTrainingPlaces } from 'services/requests';

// Components
import DatePicker from 'components/date-picker';
import Autocomplete from 'components/autocomplete';
import FieldRequired from 'components/field-required';
import InputText from 'components/input-text';

// Views
import AddressForm from 'views/common/address-form';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SESSION DAY FORM ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SessionDayForm = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- SESSION DATE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Date<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <DatePicker
                        outlined
                        value={state.session_date}
                        onChange={session_date => setState({ ...state, session_date })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- TRAINING PLACE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Lieu de formation
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectTrainingPlace
                        trainingPlace={state.training_place}
                        setTrainingPlace={training_place => setState({ ...state, training_place })}
                    />
                </Grid>
            </Grid>
            <Divider style={{ margin: '15px 0px' }} />
            {/* -------------------- ADDRESS -------------------- */}
            <Grid container>
                <b>Vous pouvez remplir les champs ci-dessous si l'adresse est différente du lieu de formation :</b>
            </Grid>
            <AddressForm
                state={state}
                setState={setState}
                disabledDetailsAddress
            />
            {/* -------------------- LATITUDE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Latitude
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Latitude"
                        value={state.latitude}
                        onChange={e => setState({ ...state, latitude: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- LONGITUDE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Longitude
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Longitude"
                        value={state.longitude}
                        onChange={e => setState({ ...state, longitude: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- SELECT TRAINING PLACE ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SelectTrainingPlace = ({ trainingPlace, setTrainingPlace }) => {

    const [state, setState] = useState({
        loading: false,
        options: [],
        searchRef: null,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    useEffect(() => {
        if (state.loading) {
            getTrainingPlaces({ offset: 0, limit: 20, names: [state.searchRef] }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        options: res.data.data.trainingPlaces.map(t => ({ value: t.id, label: t.name })),
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Autocomplete
            value={trainingPlace}
            options={state.options}
            loading={state.loading}
            onChange={(e, value) => setTrainingPlace(value)}
            onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
            placeholder="Chercher un lieu..."
        />
    );
};

export default SessionDayForm;
