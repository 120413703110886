import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, CircularProgress } from '@material-ui/core';

// Services 
import { resetPassword } from 'services/requests';

// Components
import InputText from 'components/input-text';

// -------------------------------------------------------------------------------------- \\
// ------------------------------ RESET PASSWORD CONTAINER ------------------------------ \\
// -------------------------------------------------------------------------------------- \\

const ResetPasswordContainer = () => {
    return (
        <Grid
            container
            className="unauthenticated"
        >
            <Grid
                item xs={false} sm={1} md={2} lg={4}
            />
            <Grid
                item xs={12} sm={10} md={8} lg={4}
                container alignItems="center"
            >
                <ResetPassword />
            </Grid>
        </Grid>
    );
};

// -------------------------------------------------------------------------------------- \\
// ------------------------------------ RESET PASSWORD ---------------------------------- \\
// -------------------------------------------------------------------------------------- \\

export const ResetPassword = () => {
    const history = useHistory();
    const { search } = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        password: '',
        confirm_password: '',
    });

    const onSubmit = () => {
        if (
            state.password === ''
            || state.confirm_password === ''
        ) {
            enqueueSnackbar('Veuillez saisir votre mot de passe dans les 2 entrées !', { variant: 'warning' });
        }
        else if (state.password !== state.confirm_password) {
            enqueueSnackbar('Les mots de passe de correspondent pas', { variant: 'warning' });
        }
        else {
            setState(prevState => ({ ...prevState, loading: true }));
        }
    }
    
    useEffect(() => {
        if (state.loading) {
            resetPassword({
                email: new URLSearchParams(search).get('email'),
                reset_token: new URLSearchParams(search).get('reset_token'),
                password: state.password,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/login?email=' + new URLSearchParams(search).get('email'));
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(prevState => ({ ...prevState, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div
            className="unauthenticated-container"
            style={{ margin: '24px 12px' }}
        >
            <div style={{ padding: 24 }}>
                {/* ---------------------- TITLE ---------------------- */}
                <p
                    className="mabd-font"
                    style={{ fontSize: 16, fontWeight: 600, textAlign: 'center' }}
                >
                    CHOISIR UN NOUVEAU MOT DE PASSE
                </p>
                {/* ---------------------- NEW PASSWORD ---------------------- */}
                <div>
                    <span className="input-title">
                        Nouveau mot de passe
                    </span>
                    <InputText
                        label="Nouveau mot de passe"
                        type="password"
                        value={state.password}
                        onChange={e => setState({ ...state, password: e.target.value })}
                        autoComplete="on"
                    />
                </div>
                {/* ---------------------- CONFIRM PASSWORD ---------------------- */}
                <div style={{ marginTop: 6 }}>
                    <InputText
                        label="Confirmer le mot de passe"
                        type="password"
                        value={state.confirm_password}
                        onChange={e => setState({ ...state, confirm_password: e.target.value })}
                        autoComplete="on"
                    />
                </div>
                {/* ---------------------- RESET ---------------------- */}
                <Grid
                    container justify="center"
                    style={{ marginTop: 24 }}
                >
                    <Button
                        onClick={onSubmit}
                        disabled={state.loading}
                        endIcon={state.loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                        variant="contained" color="primary"
                    >
                        Mettre à jour
                    </Button>
                </Grid>
            </div>
        </div>
    );
};

export default ResetPasswordContainer;
