import React, { useState, useEffect } from 'react';

// Services
import { agriculturalMethods } from 'services/constants';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------- SELECT AGRICULTURAL METHODS --------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectAgriculturalMethods = ({ methods, setMethods }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(agriculturalMethods.map(s => ({ label: s.label, value: s.value })));
    }, []);

    return (
        <SelectMultiple
            value={methods}
            onChange={value => setMethods(value)}
            options={options}
            placeholder="Conduite agricole"
        />
    );
};

export default SelectAgriculturalMethods;
