import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Button, CircularProgress } from '@material-ui/core';

// Services
import { renewSubscription } from 'services/requests'
import { getAmountTTC } from 'services/helpers';

// ----------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- RENEW SUBSCRIPTION -------------------------------------- \\
// ----------------------------------------------------------------------------------------------------- \\

const RenewSubscription = ({ state, setState }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (state.subscriptionId) {
            renewSubscription(state.subscriptionId).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/payment-method/' + res.data.data.bill.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, subscriptionId: null });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.subscriptionId]);

    return (
        <div
            className="main-container"
            style={{ padding: '48px 24px', alignItems: 'center' }}
        >
            {state.hasValidSubscription.length > 1 ? (
                `Vous possédez actuellement plusieurs abonnements valides ou expirés. Vous pouvez dès à présent renouveler vos abonnements pour ${moment(state.hasValidSubscription[0].end_date?.substring(0, 4), 'YYYY').add(1, 'y').year()}.`
            ) : (
                `Vous possédez actuellement un ${state.hasValidSubscription[0].subscriptionType?.name} ${state.hasValidSubscription[0].status === 'validated' ? 'valide' : 'expiré'} pour ${state.hasValidSubscription[0].end_date?.substring(0, 4)}. Vous pouvez dès à présent renouveler votre abonnement pour ${moment(state.hasValidSubscription[0].end_date?.substring(0, 4), 'YYYY').add(1, 'y').year()}.`
            )}
            {state.hasValidSubscription?.map(subscription => {
                if (subscription.newSubscription == null) {
                    return (
                        <Button
                            key={subscription.id}
                            onClick={() => setState({ ...state, subscriptionId: subscription.id })}
                            variant="contained" color="primary"
                            disabled={state.subscriptionId != null}
                            style={{ margin: '12px 0px' }}
                        >
                            Renouveler {subscription.subscriptionType?.name} ({getAmountTTC(subscription.subscriptionType?.price)})
                            {state.subscriptionId && (
                                <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
                            )}
                        </Button>
                    );
                }
            })}
            ou
            <Button
                onClick={() => setState({ ...state, hasValidSubscription: null })}
                variant="contained" color="primary"
                style={{ margin: '12px 0px' }}
            >
                Choisir une nouvelle formule
            </Button>
        </div>
    );
};

export default RenewSubscription;
