import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, MenuItem } from '@material-ui/core';

// Services
import { mergeUsers } from 'services/requests';

// Components
import Spinner from 'components/spinner';
import FieldRequired from 'components/field-required';
import SelectOutlined from 'components/select-outlined';

// Selects
import SearchAndSelectUser from 'selects/search-and-select-user';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------- \\
// -------------------------------------- MERGE USERS -------------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const MergeUsers = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        mainUser: null,
        mergedUser: null,
        emailToKeep: 'main',
    });

    function onMerge() {
        if (
            state.mainUser == null
            || state.mergedUser == null
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else if (state.mainUser.value === state.mergedUser.value) {
            enqueueSnackbar('Vous devez sélectionner deux utilisateurs différents pour la fusion', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            mergeUsers({
                main_user_id: state.mainUser?.value,
                user_to_merge_id: state.mergedUser?.value,
                email_to_keep: state.emailToKeep,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog
            open
            fullWidth
        >
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Fusionner deux utilisateurs
                        </p>
                        {/* ---------------------- MAIN USER ---------------------- */}
                        <div>
                            <span>
                                Utilisateur principal (sera conservé)<FieldRequired />
                            </span>
                            <SearchAndSelectUser
                                label="Chercher l'utilisateur principal"
                                user={state.mainUser}
                                setUser={mainUser => setState(prevState => ({ ...prevState, mainUser }))}
                            />
                        </div>
                        {/* ---------------------- USER TO MERGE ---------------------- */}
                        <div style={{ marginTop: 8 }}>
                            <span>
                                Utilisateur à fusionner (sera supprimé)<FieldRequired />
                            </span>
                            <SearchAndSelectUser
                                label="Chercher l'utilisateur à fusionner"
                                user={state.mergedUser}
                                setUser={mergedUser => setState(prevState => ({ ...prevState, mergedUser }))}
                            />
                        </div>
                        {/* ---------------------- EMAIL TO KEEP ---------------------- */}
                        <div style={{ marginTop: 8 }}>
                            <span>
                                Email à conserver<FieldRequired />
                            </span>
                            <SelectOutlined
                                value={state.emailToKeep}
                                onChange={e => setState({ ...state, emailToKeep: e.target.value })}
                            >
                                <MenuItem value="main">
                                    Utilisateur principal {state.mainUser?.label || ''}
                                </MenuItem>
                                <MenuItem value="merged">
                                    Utilisateur à fusionner {state.mergedUser?.label || ''}
                                </MenuItem>
                            </SelectOutlined>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            Fermer
                        </Button>
                        <Button
                            onClick={onMerge}
                            variant="contained"
                        >
                            Fusionner
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default MergeUsers;
