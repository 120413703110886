import React, { Fragment } from 'react';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Divider } from '@material-ui/core';

// Services
import { getFormattedTime, getSessionDayAddress } from 'services/helpers';

// Components
import Scrollbar from 'components/scrollbar';

// -------------------------------------------------------------------------------------------- \\
// ------------------------------------- FORMATION CONTENT ------------------------------------ \\
// -------------------------------------------------------------------------------------------- \\

const FormationContent = ({ formationSession }) => {

    function getTrainingPlaceName(sessionDay) {
        let trainingPlaceName = '';
        if (sessionDay.address == null && sessionDay.trainingPlace) {
            trainingPlaceName += sessionDay.trainingPlace.name + ' : ';
        }
        return trainingPlaceName;
    }

    return (
        <>
            <div style={{ padding: 24, fontWeight: 600, fontSize: 20, textTransform: 'uppercase' }}>
                Contenu de la formation
            </div>
            <div style={{ padding: '0px 24px 34px 24px' }}>
                <Scrollbar>
                    <div style={{ display: 'inline-flex', marginBottom: 6 }}>
                        {formationSession.sessionDays.map((sessionDay, index) => (
                            <Grid
                                key={sessionDay.id}
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: 8,
                                    padding: 12,
                                    width: 400,
                                    minWidth: 400,
                                    marginLeft: index === 0 ? 0 : 24,
                                }}
                            >
                                {/* -------------------- SESSION DATE -------------------- */}
                                <div style={{ backgroundColor: 'white', padding: 6, textAlign: 'center' }}>
                                    <span
                                        className="primary-color"
                                        style={{ fontWeight: 600, textTransform: 'uppercase' }}
                                    >
                                        {sessionDay.session_date ? moment(sessionDay.session_date).format('dddd DD MMMM YYYY') : ''}
                                    </span>
                                </div>
                                {/* -------------------- ADDRESS -------------------- */}
                                <div style={{ marginTop: 12 }}>
                                    {getTrainingPlaceName(sessionDay)}{getSessionDayAddress(sessionDay)}
                                </div>
                                {/* -------------------- DAY PROGRAMS -------------------- */}
                                {sessionDay.dayPrograms.map(program => (
                                    <Fragment key={program.id}>
                                        <Divider
                                            style={{ backgroundColor: 'white', margin: '10px 0px' }}
                                        />
                                        <div style={{ fontWeight: 600 }}>
                                            {getFormattedTime(program.start_time) + ' - ' + getFormattedTime(program.end_time)}
                                        </div>
                                        <div
                                            className="text"
                                            style={{ marginTop: 6 }}
                                        >
                                            {program.description}
                                        </div>
                                    </Fragment>
                                ))}
                            </Grid>
                        ))}
                    </div>
                </Scrollbar>
            </div>
        </>
    );
};

export default FormationContent;
