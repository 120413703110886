import React, { useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Accordion, AccordionSummary, Checkbox } from '@material-ui/core';

// Services
import { getDomainsWithUsers } from 'services/requests';

// Components
import useMediaQueries from 'services/media';

// Views
import FormationDetails from 'views/common/formation-details';

// --------------------------------------------------------------------------------------------------- \\
// -------------------------------------- FORMATION REQUEST FORM 1 ----------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const FormationRequestForm1 = ({ state, setState }) => {
    const { mediaMD } = useMediaQueries();

    return (
        <>
            <Grid className="main-container" style={{ padding: 0 }}>
                <Grid className="main-container-item">
                    {/* -------------------- FORMATION DETAILS -------------------- */}
                    <FormationDetails 
                        formationSession={state.formationSession}
                    />
                    <Divider />
                    {/* -------------------- SELECT USERS -------------------- */}
                    <SelectUsers
                        state={state}
                        setState={setState}
                    />
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- SUBMIT -------------------- */}
            <Grid container alignItems="center" justify="center" style={{ padding: 24 }}>
                <Grid item xs={false} md={4} />
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    1 / 3
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <Submit state={state} setState={setState} />
                </Grid>
            </Grid>
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ SELECT USERS ------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const SelectUsers = ({ state, setState }) => {

    function userIsSelected(domain, user) {
        if (state.domainsWithUsers && state.domainsWithUsers[domain.id].findIndex(u => u.id === user.id) !== -1) {
            return true;
        }
        return false;
    }

    function onSelectUser(domain, user) {
        const domains = state.domainsWithUsers;
        const users = domains[domain.id];
        const index = domains[domain.id].findIndex(u => u.id === user.id);

        if (index === -1) {
            users.push({ id: user.id, fullName: user.fullName });
        }
        else {
            users.splice(index, 1);
        }

        setState({ ...state, domainsWithUsers: domains });
    }

    useEffect(() => {
        if (state.domainsLoading) {
            getDomainsWithUsers().then(res => {
                if (res.status === 200) {
                    const domains = res.data.data.domains;
    
                    let domainsWithUsers = {};
                    domains.forEach(domain => {
                        domainsWithUsers[domain.id] = [];
                    });
    
                    setState({ ...state, domainsLoading: false, domains, domainsWithUsers });
                }
                else {
                    console.log(res);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.domainsLoading]);

    return (
        <>
            <Grid style={{ padding: 24 }}>
                <b>UTILISATEURS</b>
                <br />
                Sélectionnez les utilisateurs à inscrire à la formation
            </Grid>
            <Grid style={{ padding: '0px 24px 24px 24px' }}>
                {state.domains.map(domain => (
                    <Accordion key={domain.id}>
                        <AccordionSummary>
                            <Grid container alignItems="center" justify="space-between">
                                <Grid>
                                    <b
                                        className="primary-color"
                                        style={{ textTransform: 'uppercase', marginRight: 5 }}
                                    >
                                        {domain.name}
                                    </b>
                                    ({domain.users.length} {domain.users.length > 1 ? 'membres' : 'membre'})
                                </Grid>
                                <Grid>
                                    {state.domainsWithUsers && state.domainsWithUsers[domain.id].length > 0 && (
                                        <div className="pellet-primary">
                                            {state.domainsWithUsers[domain.id].length}
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <Divider />
                        <div>
                            {domain.users.map(user => (
                                <Grid
                                    key={user.id}
                                    onClick={() => onSelectUser(domain, user)}
                                    container alignItems="center"
                                    className="simple-list"
                                    style={{ padding: 10 }}
                                >
                                    <Checkbox
                                        checked={userIsSelected(domain, user)}
                                        color="primary"
                                        style={{ padding: 0, marginRight: 10 }}
                                    />
                                    {user.fullName}
                                </Grid>
                            ))}
                        </div>
                    </Accordion>
                ))}
            </Grid>
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- SUBMIT --------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const Submit = ({ state, setState }) => {
    const { enqueueSnackbar } = useSnackbar();

    function onSubmit() {
        if (Object.values(state.domainsWithUsers).every(value => value.length === 0)) {
            enqueueSnackbar('Veuillez sélectionner au moins 1 utilisateur', { variant: 'warning' });
        }
        else {

            let users = [];
            Object.values(state.domainsWithUsers).forEach(value => {
                users = users.concat(value);
            });

            let user_registrations = users.map(user => ({
                user: user,
                session_price: null,
                options: [],
                phone: '',
                birth_date: null,
            }));

            let usersCompleted = {};
            let accordions = {};
            users.forEach((user, index) => {
                usersCompleted[user.id] = false;
                if (index === 0) {
                    accordions[user.id] = true;
                }
                else {
                    accordions[user.id] = false;
                }
            });

            setState({ ...state, page: 2, user_registrations, usersCompleted, accordions });
        }
    }

    return (
        <Button
            onClick={onSubmit}
            variant="contained"
        >
            Suivant
        </Button>
    );
};

export default FormationRequestForm1;
