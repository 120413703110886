import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Divider } from '@material-ui/core';

// Services
import { getUserBills } from 'services/requests';
import { billStatuses, paymentMethods } from 'services/constants';
import { getLabelStatus, getColorStatus, getAmountTTC, getFormattedCreatedAt } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// --------------------------------------------------------------------------------------- \\
// ---------------------------------------- BILLS ---------------------------------------- \\
// --------------------------------------------------------------------------------------- \\

const Bills = ({ user }) => {
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        bills: [],
        total_count: 0,
    });

    useEffect(() => {
        if (state.loading) {
            getUserBills(user.id).then(res => {
                if (res.status === 200) {
                    const bills = res.data.data.bills;
                    setState({ ...state, loading: false, bills, total_count: bills.length });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div
            className="light-background-color"
            style={{ padding: '0px 24px 24px 24px' }}
        >
            <div style={{ padding: 12, backgroundColor: 'white' }}>
                {/* -------------------- TOOLBAR -------------------- */}
                <Grid container justify="space-between" alignItems="center">
                    <b>BONS DE COMMANDE</b>
                    <b>
                        {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                    </b>
                </Grid>
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid
                        container alignItems="center"
                        className="subtitle-primary-color"
                        style={{ border: '1px solid rgba(0, 0, 0, 0.12)', marginTop: 12 }}
                    >
                        <Grid item xs={1} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={1} style={{ padding: 10 }}>
                            Référence
                        </Grid>
                        <Grid item xs={1} style={{ padding: 10 }}>
                            Date BC
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Articles
                        </Grid>
                        <Grid item xs={1} style={{ padding: 10 }}>
                            Montant
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Mode de paiement
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Créateur paiement
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Créateur BC
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- BILL LIST -------------------- */}
                <BillList
                    state={state}
                />
            </div>
        </div>
    );
};

// --------------------------------------------------------------------------------------- \\
// -------------------------------------- BILL LIST -------------------------------------- \\
// --------------------------------------------------------------------------------------- \\

const BillList = ({ state }) => {
    const history = useHistory();
    const match = useRouteMatch();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;
 
    return (
        <>
            {state.bills.length > 0 ? (
                state.bills.map(bill => (
                    <Fragment key={bill.id}>
                        <Grid
                            onClick={() => history.push(`${match.url}/bills/${bill.id}`)}
                            container alignItems="center"
                            className="simple-list"
                        >
                            <Grid
                                item xs={12} md={1}
                                style={{ padding: 10 }}
                            >
                                <span style={{ color: getColorStatus(billStatuses, bill.status) }}>
                                    {getLabelStatus(billStatuses, bill.status)}
                                </span>
                            </Grid>
                            <Grid
                                item xs={12} md={1}
                                style={{ padding: 10 }}
                            >
                                {bill.reference}
                            </Grid>
                            <Grid
                                item xs={12} md={1}
                                style={{ padding: 10 }}
                            >
                                {getFormattedCreatedAt(bill.created_at)}
                            </Grid>
                            <Grid
                                item xs={12} md={2}
                                style={{ padding: 10 }}
                            >
                                {bill.billItems?.map(item => item.name).join(', ')}
                            </Grid>
                            <Grid
                                item xs={12} md={1}
                                style={{ padding: 10 }}
                            >
                                {getAmountTTC(bill.total_amount)}
                            </Grid>
                            <Grid
                                item xs={12} md={2}
                                style={{ padding: 10 }}
                            >
                                {bill.paymentMethods?.map(pm => getLabelStatus(paymentMethods, pm)).join(', ')}
                            </Grid>
                            <Grid
                                item xs={12} md={2}
                                style={{ padding: 10 }}
                            >
                                {bill.payments?.map(p => p.creator?.fullName)?.join(', ')}
                            </Grid>
                            <Grid
                                item xs={12} md={2}
                                style={{ padding: 10 }}
                            >
                                {bill.creator?.fullName}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <div style={{ padding: 10 }}>
                        Aucun
                    </div>
                    <Divider />
                </>
            )}
        </>
    );
};

export default Bills;
