import React, { Fragment } from 'react';

// Material-UI
import { Divider, Grid } from '@material-ui/core';

// Services
import { userCanAccessToBillList } from 'services/permissions';
import { paymentMethods, refundStatuses } from 'services/constants';
import { getFormattedDate, getLabelStatus, getColorStatus, getAmountTTC } from 'services/helpers';

// Views
import UpdateRefund from 'views/management/refunds/refunds-update';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------- BILL REFUNDS ----------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const BillRefunds = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container alignItems="center" justify="space-between"
                style={{ marginTop: 50 }}
            >
                <b>Remboursements</b>
            </Grid>
            {/* -------------------- HEADERS -------------------- */}
            <Grid
                container
                className="list-headers"
                style={{ marginTop: 10 }}
            >
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Statut
                </Grid>
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Montant
                </Grid>
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Référence
                </Grid>
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Mode de paiement
                </Grid>
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Date
                </Grid>
                <Grid
                    item xs={2}
                    style={{ padding: 10 }}
                >
                    Association fédérée
                </Grid>
            </Grid>
            {/* -------------------- LIST -------------------- */}
            {state.bill.refunds.map(refund => (
                <Fragment key={refund.id}>
                    <Grid
                        onClick={userCanAccessToBillList() ? () => setState({ ...state, openUpdateRefund: true, refund }) : null}
                        className={userCanAccessToBillList() ? 'simple-list' : null}
                        container alignItems="center"
                    >
                        <Grid item xs={2} style={{ padding: 10 }}>
                            <b style={{ color: getColorStatus(refundStatuses, refund.status) }}>
                                {getLabelStatus(refundStatuses, refund.status)}
                            </b>
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            {getAmountTTC(refund.amount)}
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            {refund.reference}
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            {getLabelStatus(paymentMethods, refund.payment_method)}
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            {refund.refund_date ? getFormattedDate(refund.refund_date) : ''}
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            {refund.federatedAssociation?.name}
                        </Grid>
                    </Grid>
                    <Divider />
                </Fragment>
            ))}
            <Grid
                container justify="flex-end"
                style={{ marginTop: 10 }}
            >
                <b>TOTAL REMBOURSÉ : {state.total_refunded / 100}€</b>
            </Grid>
            {/* -------------------- UPDATE REFUND -------------------- */}
            {state.openUpdateRefund && (
                <UpdateRefund
                    onClose={() => setState({ ...state, openUpdateRefund: false })}
                    refetch={() => setState({ ...state, openUpdateRefund: false, loading: true })}
                    refund={state.refund}
                />
            )}
        </>
    );
};

export default BillRefunds;
