import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon, UnfoldMore as OrderIcon } from '@material-ui/icons';

// Services
import { getFormationRequestsForSession, getSession, confirmFormationRequest, getIndexPreferences, updateIndexPreferences } from 'services/requests';
import { userCanHandleParticipations } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';
import { billStates, formationRequestStatuses, paymentMethods, sessionPriceStatuses } from 'services/constants';
import { getFormattedCreatedAt, cancelParticipationIsAvailable, getLabelStatus, getColorStatus, getFormattedDate, getAmountTTC } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Tooltip from 'components/tooltip';
import InputText from 'components/input-text';
import Select from 'components/react-select';

// Views
import CancelParticipation from '../../../../dasboard/formation-requests/formation-requests-cancel';
import FormationRequestMessage from './formation-requests-message';
import FormationRequestComment from './formation-requests-comment';
import FormationSessionExport from './formation-requests-export';

// Selects
import SelectBillState from 'selects/select-bill-state';

// Columns
import { FirstNameHeader, FirstNameFilter, FirstNameValue } from 'views/columns/first-name';
import { LastNameHeader, LastNameFilter, LastNameValue } from 'views/columns/last-name';
import { CompanyNameHeader, CompanyNameFilter, CompanyNameValue } from 'views/columns/company-name';
import { MobilePhoneHeader, MobilePhoneFilter, MobilePhoneValue } from 'views/columns/mobile-phone';
import { PhoneHeader, PhoneFilter, PhoneValue } from 'views/columns/phone';
import { EmailHeader, EmailFilter, EmailValue } from 'views/columns/email';
import { ClientCodeHeader, ClientCodeFilter, ClientCodeValue } from 'views/columns/client-code';
import { ExternalClientCodeHeader, ExternalClientCodeFilter, ExternalClientCodeValue } from 'views/columns/external-client-code';
import { BirthDateHeader, BirthDateFilter, BirthDateValue } from 'views/columns/birth-date';
import { AddressHeader, AddressFilter, AddressValue } from 'views/columns/address';
import { AddressDetailsHeader, AddressDetailsFilter, AddressDetailsValue } from 'views/columns/address-details';
import { PostalCodeHeader, PostalCodeFilter, PostalCodeValue } from 'views/columns/postal-code';
import { CityHeader, CityFilter, CityValue } from 'views/columns/city';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ FORMATION SUBHEADER -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationRequestsSubheader = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { sessionId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formationSession: null,
    });

    useEffect(() => {
        if (state.loading) {
            getSession(sessionId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, formationSession: res.data.data.formationSession });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Demandes d'inscription pour la formation #{state.formationSession.number}
                </Grid>
            </Grid>
            <FormationRequests formationSession={state.formationSession} />
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- FORMATION REQUESTS ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationRequests = ({ formationSession }) => {
    const storedUserId = getStoredUser()?.id;

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        formationRequests: [],
        sessionOptions: [],
        total_count: 0,

        // Confirm Request
        formationRequest: null,
        openConfirm: false,

        // Cancel Participation
        openCancelParticipation: false,

        // Message
        openMessage: false,
        message: null,

        // Comment
        openComment: false,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_confirmation: true,
        enabled_status: true,
        enabled_created_at: true,
        enabled_first_name: true,
        enabled_last_name: true,
        enabled_email: true,
        enabled_mobile_phone: true,
        enabled_phone: true,
        enabled_message: true,
        enabled_company_name: false,
        enabled_client_code: true,
        enabled_external_client_code: false,
        enabled_session_price_status: true,
        enabled_session_price_price: true,
        enabled_bill_total_amount: true,
        enabled_amount_received: true,
        enabled_bill_payment_method: true,
        enabled_bill_date: true,
        enabled_bill_reference: true,
        enabled_bill_state: true,
        enabled_general_comment: true,
        enabled_address: false,
        enabled_address_details: false,
        enabled_postal_code: false,
        enabled_city: false,
        enabled_birth_date: false,

        // Filters
        order_by: '',
        order: 'desc',
        statuses: [],
        created_at: '',
        first_name: '',
        last_name: '',
        email: '',
        mobile_phone: '',
        phone: '',
        company_name: '',
        client_code: '',
        external_client_code: '',
        session_price_statuses: [],
        session_price_price: '',
        bill_total_amount: '',
        amount_received: '',
        bill_payment_methods: [],
        bill_date: '',
        bill_reference: '',
        bill_state: -1,
        address: '',
        address_details: '',
        postal_codes: [],
        cities: [],
        birth_date: '',
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false });
    }

    function onOrder(order_by) {
        if (order_by === state.order_by) {
            setState({ ...state, order: state.order === 'desc' ? 'asc' : 'desc', loading: true, error: false });
        }
        else {
            setState({ ...state, order_by, order: 'desc', loading: true, error: false });
        }
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            order_by: '',
            order: 'desc',
            statuses: [],
            created_at: '',
            first_name: '',
            last_name: '',
            email: '',
            mobile_phone: '',
            phone: '',
            company_name: '',
            client_code: '',
            external_client_code: '',
            session_price_statuses: [],
            session_price_price: '',
            bill_total_amount: '',
            amount_received: '',
            bill_payment_methods: [],
            bill_date: '',
            bill_reference: '',
            bill_state: -1,
            address: '',
            address_details: '',
            postal_codes: [],
            cities: [],
            birth_date: '',
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'formation-requests',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_confirmation: columns.enabled_confirmation,
                            enabled_status: columns.enabled_status,
                            enabled_created_at: columns.enabled_created_at,
                            enabled_first_name: columns.enabled_first_name,
                            enabled_last_name: columns.enabled_last_name,
                            enabled_email: columns.enabled_email,
                            enabled_mobile_phone: columns.enabled_mobile_phone,
                            enabled_phone: columns.enabled_phone,
                            enabled_message: columns.enabled_message,
                            enabled_company_name: columns.enabled_company_name,
                            enabled_client_code: columns.enabled_client_code,
                            enabled_external_client_code: columns.enabled_external_client_code,
                            enabled_session_price_status: columns.enabled_session_price_status,
                            enabled_session_price_price: columns.enabled_session_price_price,
                            enabled_bill_total_amount: columns.enabled_bill_total_amount,
                            enabled_amount_received: columns.enabled_amount_received,
                            enabled_bill_payment_method: columns.enabled_bill_payment_method,
                            enabled_bill_date: columns.enabled_bill_date,
                            enabled_bill_reference: columns.enabled_bill_reference,
                            enabled_bill_state: columns.enabled_bill_state,
                            enabled_general_comment: columns.enabled_general_comment,
                            enabled_address: columns.enabled_address,
                            enabled_address_details: columns.enabled_address_details,
                            enabled_postal_code: columns.enabled_postal_code,
                            enabled_city: columns.enabled_city,
                            enabled_birth_date: columns.enabled_birth_date,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            getFormationRequestsForSession(formationSession.id, {
                order_by: state.order_by,
                order: state.order,
                statuses: state.statuses != null ? state.statuses.map(s => s.value) : null,
                created_at: state.created_at,
                last_name: state.last_name,
                first_name: state.first_name,
                email: state.email,
                mobile_phone: state.mobile_phone,
                phone: state.phone,
                company_name: state.company_name,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                session_price_statuses: state.session_price_statuses != null ? state.session_price_statuses.map(s => s.value) : null,
                bill_payment_methods: state.bill_payment_methods != null ? state.bill_payment_methods.map(m => m.value) : null,
                bill_date: state.bill_date,
                bill_reference: state.bill_reference,
                bill_state: state.bill_state,
                address: state.address,
                address_details: state.address_details,
                postal_codes: state.postal_codes != null ? state.postal_codes.map(c => c.value) : null,
                cities: state.cities != null ? state.cities.map(c => c.value) : null,
                birth_date: state.birth_date,
            }).then(res => {
                if (res.status === 200) {
                    let formationRequests = res.data.data.formationRequests;
                    setState({
                        ...state,
                        loading: false,
                        formationRequests,
                        total_count: formationRequests.length,
                        sessionOptions: res.data.data.sessionOptions,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <FormationSessionExport
                        formationSession={formationSession}
                        data={state}
                    />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state}
                            setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                            onOrder={onOrder}
                        />
                    </div>
                    <FormationRequestList
                        state={state}
                        setState={setState}
                        formationRequests={state.formationRequests}
                        formationSession={formationSession}
                    />
                </div>
            </div>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
        </>
    );
};

// --------------------------------------- HEADERS -------------------------------------- \\
const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- CONFIRMATION -------------------- */}
            {state.enabled_confirmation && (
                <div className="column-list">
                    Confirmation participation
                </div>
            )}
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list" style={{ width: 245 }}>
                    Statut inscription
                </div>
            )}
            {/* -------------------- CREATED AT -------------------- */}
            {state.enabled_created_at && (
                <div className="column-list" style={{ width: 215 }}>
                    Date demande pré-inscription
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <FirstNameHeader />
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <LastNameHeader />
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <EmailHeader />
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <MobilePhoneHeader />
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <PhoneHeader />
            )}
            {/* -------------------- MESSAGE -------------------- */}
            {state.enabled_message && (
                <div className="column-list" style={{ width: 170 }}>
                    Commentaire stagiaire
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <CompanyNameHeader />
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <ClientCodeHeader />
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <ExternalClientCodeHeader />
            )}
            {/* -------------------- SESSION PRICE STATUS -------------------- */}
            {state.enabled_session_price_status && (
                <div className="column-list" style={{ width: 160 }}>
                    Formule choisie
                </div>
            )}
            {/* -------------------- SESSION PRICE PRICE -------------------- */}
            {state.enabled_session_price_price && (
                <div className="column-list" style={{ width: 160 }}>
                    Tarif formule
                </div>
            )}
            {/* -------------------- BILL TOTAL AMOUNT -------------------- */}
            {state.enabled_bill_total_amount && (
                <div className="column-list" style={{ width: 160 }}>
                    Montant total BC
                </div>
            )}
            {/* -------------------- AMOUNT RECEIVED -------------------- */}
            {state.enabled_amount_received && (
                <div className="column-list" style={{ width: 160 }}>
                    Montant reçu
                </div>
            )}
            {/* -------------------- BILL PAYMENT METHOD -------------------- */}
            {state.enabled_bill_payment_method && (
                <div className="column-list" style={{ width: 170 }}>
                    Mode de paiement BC
                </div>
            )}
            {/* -------------------- BILL DATE -------------------- */}
            {state.enabled_bill_date && (
                <div className="column-list" style={{ width: 160 }}>
                    Date BC
                </div>
            )}
            {/* -------------------- BILL REFERENCE -------------------- */}
            {state.enabled_bill_reference && (
                <div className="column-list" style={{ width: 180 }}>
                    Référence BC
                </div>
            )}
            {/* -------------------- BILL STATE -------------------- */}
            {state.enabled_bill_state && (
                <div className="column-list" style={{ width: 160 }}>
                    État BC
                </div>
            )}
            {/* -------------------- GENERAL COMMENT -------------------- */}
            {state.enabled_general_comment && (
                <div className="column-list" style={{ width: 190 }}>
                    Commentaire responsable
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <AddressHeader />
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <AddressDetailsHeader />
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <PostalCodeHeader />
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <CityHeader />
            )}
            {/* -------------------- BIRTH DATE -------------------- */}
            {state.enabled_birth_date && (
                <BirthDateHeader />
            )}
            {/* -------------------- SESSION OPTIONS -------------------- */}
            {state.sessionOptions.map(option => (
                <div className="column-list" key={option.id}>
                    {option.name}
                </div>
            ))}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch, onOrder }) => {
    return (
        <div className="list-filters">
            {/* -------------------- CONFIRMATION -------------------- */}
            {state.enabled_confirmation && (
                <div className="column-list" />
            )}
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list" style={{ width: 245 }}>
                    <Select
                        placeholder="Statut inscription"
                        value={state.statuses}
                        onChange={statuses => onSelectSearch('statuses', statuses)}
                        options={formationRequestStatuses}
                        isMulti
                    />
                </div>
            )}
            {/* -------------------- CREATED AT -------------------- */}
            {state.enabled_created_at && (
                <div className="column-list" style={{ width: 215 }}>
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.created_at}
                        onChange={e => setState({ ...state, created_at: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <FirstNameFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <LastNameFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <EmailFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <MobilePhoneFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <PhoneFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- MESSAGE -------------------- */}
            {state.enabled_message && (
                <div className="column-list" style={{ width: 170 }} />
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <CompanyNameFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <ClientCodeFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <ExternalClientCodeFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- SESSION PRICE STATUS -------------------- */}
            {state.enabled_session_price_status && (
                <div className="column-list" style={{ width: 160 }}>
                    <Select
                        placeholder="Formule choisie"
                        value={state.session_price_statuses}
                        onChange={session_price_statuses => onSelectSearch('session_price_statuses', session_price_statuses)}
                        options={sessionPriceStatuses}
                        isMulti
                    />
                </div>
            )}
            {/* -------------------- SESSION PRICE PRICE -------------------- */}
            {state.enabled_session_price_price && (
                <div className="column-list" style={{ width: 160 }}>
                    <Button
                        onClick={() => onOrder('session_price_price')}
                        variant="outlined"
                    >
                        <OrderIcon />
                    </Button>
                </div>
            )}
            {/* -------------------- BILL TOTAL AMOUNT -------------------- */}
            {state.enabled_bill_total_amount && (
                <div className="column-list" style={{ width: 160 }}>
                    <Button
                        onClick={() => onOrder('bill_total_amount')}
                        variant="outlined"
                    >
                        <OrderIcon />
                    </Button>
                </div>
            )}
            {/* -------------------- AMOUNT RECEIVED -------------------- */}
            {state.enabled_amount_received && (
                <div className="column-list" style={{ width: 160 }}>
                    <Button
                        onClick={() => onOrder('amount_received')}
                        variant="outlined"
                    >
                        <OrderIcon />
                    </Button>
                </div>
            )}
            {/* -------------------- BILL PAYMENT METHOD -------------------- */}
            {state.enabled_bill_payment_method && (
                <div className="column-list" style={{ width: 170 }}>
                    <Select
                        placeholder="Mode de paiement BC"
                        value={state.bill_payment_methods}
                        onChange={bill_payment_methods => onSelectSearch('bill_payment_methods', bill_payment_methods)}
                        options={paymentMethods}
                        isMulti
                    />
                </div>
            )}
            {/* -------------------- BILL DATE -------------------- */}
            {state.enabled_bill_date && (
                <div className="column-list" style={{ width: 160 }}>
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.bill_date}
                        onChange={e => setState({ ...state, bill_date: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL REFERENCE -------------------- */}
            {state.enabled_bill_reference && (
                <div className="column-list" style={{ width: 180 }}>
                    <InputText
                        label="Référence BC"
                        value={state.bill_reference}
                        onChange={e => setState({ ...state, bill_reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL STATE -------------------- */}
            {state.enabled_bill_state && (
                <div className="column-list" style={{ width: 160 }}>
                    <SelectBillState
                        billState={state.bill_state}
                        setBillState={bill_state => onSelectSearch('bill_state', bill_state)}
                    />
                </div>
            )}
            {/* -------------------- GENERAL COMMENT -------------------- */}
            {state.enabled_general_comment && (
                <div className="column-list" style={{ width: 190 }} />
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <AddressFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <AddressDetailsFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <PostalCodeFilter
                    state={state}
                    onSelectSearch={onSelectSearch}
                />
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <CityFilter
                    state={state}
                    onSelectSearch={onSelectSearch}
                />
            )}
            {/* -------------------- BIRTH DATE -------------------- */}
            {state.enabled_birth_date && (
                <BirthDateFilter
                    state={state}
                    setState={setState}
                    onSearch={onSearch}
                />
            )}
            {/* -------------------- SESSION OPTIONS -------------------- */}
            {state.sessionOptions.map(option => (
                <div className="column-list" key={option.id} />
            ))}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- FORMATION REQUEST LIST ---------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationRequestList = ({ state, setState, formationSession }) => {

    function getSessionOption(formationRequest, option) {
        let foundedOption = formationRequest.sessionOptions.find(o => o.id === option.id);
        if (foundedOption != null) {
            return getAmountTTC(option.price);
        }
        return '';
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.formationRequests.length > 0 && (
                state.formationRequests.map(formationRequest => (
                    <Fragment key={formationRequest.id}>
                        <div style={{ display: 'inline-flex' }}>
                            {/* -------------------- CONFIRMATION -------------------- */}
                            {state.enabled_confirmation && (
                                <div className="column-list">
                                    {(userCanHandleParticipations() && cancelParticipationIsAvailable(formationRequest)) && (
                                        <Button
                                            onClick={() => setState({ ...state, openCancelParticipation: true, formationRequest })}
                                            variant="contained"
                                            style={{ padding: '4px 8px', textTransform: 'none', marginRight: 10 }}
                                        >
                                            Annuler la participation
                                        </Button>
                                    )}
                                    {(formationRequest.status === 'request_payment_waiting' || formationRequest.status === 'request_paid') && (
                                        <Button
                                            onClick={() => setState({ ...state, openConfirm: true, formationRequest })}
                                            variant="contained"
                                            color="primary"
                                            style={{ padding: '4px 8px', textTransform: 'none' }}
                                        >
                                            Confirmer
                                        </Button>
                                    )}
                                </div>
                            )}
                            {/* -------------------- STATUS -------------------- */}
                            {state.enabled_status && (
                                <div className="column-list" style={{ width: 245 }}>
                                    <b style={{ color: getColorStatus(formationRequestStatuses, formationRequest.status) }}>
                                        {getLabelStatus(formationRequestStatuses, formationRequest.status)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- CREATED AT -------------------- */}
                            {state.enabled_created_at && (
                                <div className="column-list" style={{ width: 215 }}>
                                    {formationRequest.created_at ? getFormattedCreatedAt(formationRequest.created_at) : ''}
                                </div>
                            )}
                            {/* -------------------- FIRST NAME -------------------- */}
                            {state.enabled_first_name && (
                                <FirstNameValue
                                    value={formationRequest.user?.first_name}
                                />
                            )}
                            {/* -------------------- LAST NAME -------------------- */}
                            {state.enabled_last_name && (
                                <LastNameValue
                                    value={formationRequest.user?.last_name}
                                />
                            )}
                            {/* -------------------- EMAIL -------------------- */}
                            {state.enabled_email && (
                                <EmailValue
                                    value={formationRequest.user?.email}
                                />
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <MobilePhoneValue
                                    value={formationRequest.user?.mobile_phone}
                                />
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <PhoneValue
                                    value={formationRequest.user?.phone}
                                />
                            )}
                            {/* -------------------- MESSAGE -------------------- */}
                            {state.enabled_message && (
                                <div className="column-list" style={{ width: 170 }}>
                                    <Button
                                        onClick={() => setState({ ...state, openMessage: true, message: formationRequest.message })}
                                        color="secondary" variant={formationRequest.message ? 'contained' : 'outlined'}
                                    >
                                        Lire
                                    </Button>
                                </div>
                            )}
                            {/* -------------------- COMPANY NAME -------------------- */}
                            {state.enabled_company_name && (
                                <CompanyNameValue
                                    value={formationRequest.user?.company_name}
                                />
                            )}
                            {/* -------------------- CLIENT CODE -------------------- */}
                            {state.enabled_client_code && (
                                <ClientCodeValue
                                    value={formationRequest.user?.clientCode}
                                />
                            )}
                            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                            {state.enabled_external_client_code && (
                                <ExternalClientCodeValue
                                    value={formationRequest.user?.external_client_code}
                                />
                            )}
                            {/* -------------------- SESSION PRICE STATUS -------------------- */}
                            {state.enabled_session_price_status && (
                                <div className="column-list" style={{ width: 160 }}>
                                    {getLabelStatus(sessionPriceStatuses, formationRequest.sessionPrice?.status)}
                                </div>
                            )}
                            {/* -------------------- SESSION PRICE PRICE -------------------- */}
                            {state.enabled_session_price_price && (
                                <div className="column-list" style={{ width: 160 }}>
                                    {getAmountTTC(formationRequest.sessionPrice?.price)}
                                </div>
                            )}
                            {/* -------------------- BILL TOTAL AMOUNT -------------------- */}
                            {state.enabled_bill_total_amount && (
                                <div className="column-list" style={{ width: 160 }}>
                                    {getAmountTTC(formationRequest.bill?.total_amount)}
                                </div>
                            )}
                            {/* -------------------- AMOUNT RECEIVED -------------------- */}
                            {state.enabled_amount_received && (
                                <div className="column-list" style={{ width: 160 }}>
                                    {getAmountTTC(formationRequest.amount_received)}
                                </div>
                            )}
                            {/* -------------------- BILL PAYMENT METHOD -------------------- */}
                            {state.enabled_bill_payment_method && (
                                <div className="column-list" style={{ width: 170 }}>
                                    {getLabelStatus(paymentMethods, formationRequest.bill?.payment_method)}
                                </div>
                            )}
                            {/* -------------------- BILL DATE -------------------- */}
                            {state.enabled_bill_date && (
                                <div className="column-list" style={{ width: 160 }}>
                                    {getFormattedCreatedAt(formationRequest.bill?.created_at)}
                                </div>
                            )}
                            {/* -------------------- BILL REFERENCE -------------------- */}
                            {state.enabled_bill_reference && (
                                <div className="column-list" style={{ width: 180 }}>
                                    {formationRequest.bill?.reference}
                                </div>
                            )}
                            {/* -------------------- BILL STATE -------------------- */}
                            {state.enabled_bill_state && (
                                <div className="column-list" style={{ width: 160 }}>
                                    {getLabelStatus(billStates, formationRequest.bill?.bill_state)}
                                </div>
                            )}
                            {/* -------------------- GENERAL COMMENT -------------------- */}
                            {state.enabled_general_comment && (
                                <div className="column-list" style={{ width: 190 }}>
                                    <Button
                                        onClick={() => setState({ ...state, openComment: true, formationRequest })}
                                        color="secondary" variant={formationRequest.general_comment ? 'contained' : 'outlined'}
                                    >
                                        Écrire
                                    </Button>
                                </div>
                            )}
                            {/* -------------------- ADDRESS -------------------- */}
                            {state.enabled_address && (
                                <AddressValue
                                    value={formationRequest.user?.address}
                                />
                            )}
                            {/* -------------------- ADDRESS DETAILS -------------------- */}
                            {state.enabled_address_details && (
                                <AddressDetailsValue
                                    value={formationRequest.user?.address_details}
                                />
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <PostalCodeValue
                                    value={formationRequest.user?.postal_code}
                                />
                            )}
                            {/* -------------------- CITY -------------------- */}
                            {state.enabled_city && (
                                <CityValue
                                    value={formationRequest.user?.city}
                                />
                            )}
                            {/* -------------------- BIRTH DATE -------------------- */}
                            {state.enabled_birth_date && (
                                <BirthDateValue
                                    value={getFormattedDate(formationRequest.user?.birth_date)}
                                />
                            )}
                            {/* -------------------- SESSION OPTIONS -------------------- */}
                            {state.sessionOptions.map(option => (
                                <div className="column-list" key={option.id}>
                                    {getSessionOption(formationRequest, option)}
                                </div>
                            ))}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
            {/* ---------------------- CONFIRM FORMATION REQUEST ---------------------- */}
            {state.openConfirm && (
                <ConfirmFormationRequest
                    onClose={() => setState({ ...state, openConfirm: false })}
                    refetch={() => setState({ ...state, openConfirm: false, loading: true })}
                    formationSession={formationSession}
                    formationRequest={state.formationRequest}
                />
            )}
            {/* -------------------- CANCEL PARTICIPATION -------------------- */}
            {state.openCancelParticipation && (
                <CancelParticipation
                    onClose={() => setState({ ...state, openCancelParticipation: false })}
                    refetch={() => setState({ ...state, openCancelParticipation: false, loading: true })}
                    formationRequest={state.formationRequest}
                />
            )}
            {/* -------------------- FORMATION REQUEST MESSAGE -------------------- */}
            {state.openMessage && (
                <FormationRequestMessage
                    onClose={() => setState({ ...state, openMessage: false })}
                    message={state.message}
                />
            )}
            {/* -------------------- COMMENT -------------------- */}
            {state.openComment && (
                <FormationRequestComment
                    onClose={() => setState({ ...state, openComment: false })}
                    refetch={() => setState({ ...state, openComment: false, loading: true })}
                    formationRequestId={state.formationRequest?.id}
                    commentType="general_comment"
                    commentContent={state.formationRequest?.general_comment}
                />
            )}
        </>
    );
};

// ------------------------------- CONFIRM FORMATION REQUEST ---------------------------- \\
const ConfirmFormationRequest = ({ onClose, refetch, formationSession, formationRequest }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            confirmFormationRequest(formationRequest.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Confirmer l'inscription
                        </p>
                        Confirmez-vous l'inscription de {formationRequest.user.first_name} {formationRequest.user.last_name} à la formation #{formationSession.number} ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------ MANAGE COLUMNS ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_confirmation: state.enabled_confirmation,
            enabled_status: state.enabled_status,
            enabled_created_at: state.enabled_created_at,
            enabled_first_name: state.enabled_first_name,
            enabled_last_name: state.enabled_last_name,
            enabled_email: state.enabled_email,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_phone: state.enabled_phone,
            enabled_message: state.enabled_message,
            enabled_company_name: state.enabled_company_name,
            enabled_client_code: state.enabled_client_code,
            enabled_external_client_code: state.enabled_external_client_code,
            enabled_session_price_status: state.enabled_session_price_status,
            enabled_session_price_price: state.enabled_session_price_price,
            enabled_bill_total_amount: state.enabled_bill_total_amount,
            enabled_amount_received: state.enabled_amount_received,
            enabled_bill_payment_method: state.enabled_bill_payment_method,
            enabled_bill_date: state.enabled_bill_date,
            enabled_bill_reference: state.enabled_bill_reference,
            enabled_bill_state: state.enabled_bill_state,
            enabled_general_comment: state.enabled_general_comment,
            enabled_address: state.enabled_address,
            enabled_address_details: state.enabled_address_details,
            enabled_postal_code: state.enabled_postal_code,
            enabled_city: state.enabled_city,
            enabled_birth_date: state.enabled_birth_date,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'formation-requests',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            {state.loadingColumns ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Gérer les colonnes affichées
                        </p>
                        <div style={{ display: 'flex' }}>
                            <div>
                                {/* -------------------- CONFIRMATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_confirmation}
                                        onChange={e => setState({ ...state, enabled_confirmation: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Confirmation participation
                                </Grid>
                                {/* -------------------- STATUS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_status}
                                        onChange={e => setState({ ...state, enabled_status: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Statut inscription
                                </Grid>
                                {/* -------------------- CREATED AT -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_created_at}
                                        onChange={e => setState({ ...state, enabled_created_at: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date demande pré-inscription
                                </Grid>
                                {/* -------------------- LAST NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_last_name}
                                        onChange={e => setState({ ...state, enabled_last_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Nom
                                </Grid>
                                {/* -------------------- FIRST NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_first_name}
                                        onChange={e => setState({ ...state, enabled_first_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Prénom
                                </Grid>
                                {/* -------------------- EMAIL -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_email}
                                        onChange={e => setState({ ...state, enabled_email: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Email compte
                                </Grid>
                                {/* -------------------- MOBILE PHONE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_mobile_phone}
                                        onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Téléphone portable
                                </Grid>
                                {/* -------------------- PHONE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_phone}
                                        onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Téléphone fixe
                                </Grid>
                                {/* -------------------- MESSAGE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_message}
                                        onChange={e => setState({ ...state, enabled_message: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Commentaire stagiaire
                                </Grid>
                                {/* -------------------- COMPANY NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_company_name}
                                        onChange={e => setState({ ...state, enabled_company_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Nom de l'entreprise
                                </Grid>
                                {/* -------------------- CLIENT CODE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_client_code}
                                        onChange={e => setState({ ...state, enabled_client_code: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Code client
                                </Grid>
                                {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_external_client_code}
                                        onChange={e => setState({ ...state, enabled_external_client_code: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Code client externe
                                </Grid>
                                {/* -------------------- SESSION PRICE STATUS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_session_price_status}
                                        onChange={e => setState({ ...state, enabled_session_price_status: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Formule choisie
                                </Grid>
                            </div>
                            <div>
                                {/* -------------------- SESSION PRICE PRICE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_session_price_price}
                                        onChange={e => setState({ ...state, enabled_session_price_price: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Tarif formule
                                </Grid>
                                {/* -------------------- BILL TOTAL AMOUNT -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_total_amount}
                                        onChange={e => setState({ ...state, enabled_bill_total_amount: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Montant total BC
                                </Grid>
                                {/* -------------------- AMOUNT RECEIVED -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_amount_received}
                                        onChange={e => setState({ ...state, enabled_amount_received: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Montant reçu
                                </Grid>
                                {/* -------------------- BILL PAYMENT METHOD -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_payment_method}
                                        onChange={e => setState({ ...state, enabled_bill_payment_method: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Mode de paiement BC
                                </Grid>
                                {/* -------------------- BILL DATE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_date}
                                        onChange={e => setState({ ...state, enabled_bill_date: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date BC
                                </Grid>
                                {/* -------------------- BILL REFERENCE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_reference}
                                        onChange={e => setState({ ...state, enabled_bill_reference: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Référence BC
                                </Grid>
                                {/* -------------------- BILL STATE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_state}
                                        onChange={e => setState({ ...state, enabled_bill_state: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Etat BC
                                </Grid>
                                {/* -------------------- GENERAL COMMENT -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_general_comment}
                                        onChange={e => setState({ ...state, enabled_general_comment: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Commentaire responsable
                                </Grid>
                                {/* -------------------- ADDRESS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_address}
                                        onChange={e => setState({ ...state, enabled_address: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Adresse (n° et voie)
                                </Grid>
                                {/* -------------------- ADDRESS DETAILS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_address_details}
                                        onChange={e => setState({ ...state, enabled_address_details: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Adresse (complément)
                                </Grid>
                                {/* -------------------- POSTAL CODE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_postal_code}
                                        onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Code postal
                                </Grid>
                                {/* -------------------- CITY -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_city}
                                        onChange={e => setState({ ...state, enabled_city: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Ville
                                </Grid>
                                {/* -------------------- BIRTH DATE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_birth_date}
                                        onChange={e => setState({ ...state, enabled_birth_date: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date de naissance
                                </Grid>
                            </div>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                            Fermer
                        </Button>
                        <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default FormationRequestsSubheader;
