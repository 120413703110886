import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory, useRouteMatch, useParams, useLocation } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Button } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { EditOutlined as UpdateIcon } from '@material-ui/icons';

// Services
import { userCanUpdateSubscriptionTypes, userCanUpdateMembershipTypes } from 'services/permissions';
import { fetchRenewableMembershipsForUser, fetchRenewableSubscriptionsForUser } from 'services/requests';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';

// Views
import SubscriptionTypes from './subscription-types';
import MembershipTypes from './membership-types';
import CreateSubscription from 'views/shop/create-subscription';
import ShopUpdate from './shop-update';
import RenewMembership from 'views/common/renew/renew-membership-shop';
import RenewSubscription from 'views/common/renew/renew-subscripton-shop';

// CSS
import './shop.css';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------- SHOP ROUTER ------------------------------------ \\
// -------------------------------------------------------------------------------------- \\

const ShopRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Shop />
            </Route>
            {(userCanUpdateSubscriptionTypes() && userCanUpdateMembershipTypes()) && (
                <Route exact path={`${match.path}/update`}>
                    <ShopUpdate />
                </Route>
            )}
            <Route path={`${match.path}/:subscriptionTypeId/:subscriptionType/:userId`}>
                <CreateLoggedUserSubscription />
            </Route>
        </Switch>
    );
};

// -------------------------------------------------------------------------------------- \\
// ---------------------------------------- SHOP ---------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const Shop = () => {
    const storedUserId = getStoredUser()?.id;
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    
    const [state, setState] = useState({
        loading: true,
        menu: new URLSearchParams(useLocation().search).get('subscriptions') != null ? 'subscriptionTypes' : 'membershipTypes',

        // Renew Membership
        hasValidMembership: null,
        with_subscription: true,
        price: 0,
        loadingRenewMembership: false,

        // Renew Subscription
        hasValidSubscription: null,
        subscriptionId: null,
    });

    useEffect(() => {
        // -------------------- RENEWABLE MEMBERSHIPS -------------------- //
        if (state.loading && state.menu === 'membershipTypes') {
            fetchRenewableMembershipsForUser(storedUserId).then(res => {
                if (res.status === 200) {
                    const membership = res.data.data.membership;
                    setState({
                        ...state,
                        loading: false,
                        hasValidMembership: membership || null,
                        hasValidSubscription: membership?.subscription || null,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false });
                }
            });
        }
        // -------------------- RENEWABLE SUBSCRIPTIONS -------------------- //
        if (state.loading && state.menu === 'subscriptionTypes') {
            fetchRenewableSubscriptionsForUser(storedUserId).then(res => {
                if (res.status === 200) {
                    const subscriptions = res.data.data.subscriptions;
                    setState({
                        ...state,
                        loading: false,
                        hasValidSubscription: subscriptions || null,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading])

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <ToggleButtonGroup
                        value={state.menu}
                        onChange={(e, value) => setState({ ...state, loading: true, menu: (value != null ? value : state.menu) })}
                        exclusive size="small"
                        className="toggle-button"
                    >
                        <ToggleButton
                            value="membershipTypes"
                            style={{ width: 120 }}
                        >
                            Adhésions
                        </ToggleButton>
                        <ToggleButton
                            value="subscriptionTypes"
                            style={{ width: 120 }}
                        >
                            Abonnements
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    style={{ margin: mediaMD ? 0 : 10, textAlign: 'center' }}
                >
                    Boutique
                </Grid>
                {(userCanUpdateSubscriptionTypes() && userCanUpdateMembershipTypes()) && (
                    <Grid
                        item xs={12} md={4}
                        container justify={mediaMD ? 'flex-end' : 'center'}
                    >
                        <Tooltip title="Modifier la boutique"
                            item={(
                                <Button
                                    onClick={() => history.push('/shop/update')}
                                    variant="contained"
                                    style={{ marginLeft: 5 }}
                                >
                                    <UpdateIcon />
                                </Button>
                            )}
                        />
                    </Grid>
                )}
            </Grid>
            {state.loading ?(
                <Spinner />
            ) : (
                <>
                    {/* -------------------- MEMBERSHIPS -------------------- */}
                    {state.menu === 'membershipTypes' && (
                        (
                            state.hasValidMembership != null
                            && state.hasValidMembership.newMembership == null
                            && (
                                (
                                    Number(state.hasValidMembership.end_date?.substring(0, 4)) === moment().year()
                                    && moment().format('MM-DD') >= '07-01'
                                ) || (
                                    Number(state.hasValidMembership.end_date?.substring(0, 4)) === moment().year() - 1
                                )
                            )
                        ) ? (
                            <RenewMembership
                                state={state}
                                setState={setState}
                            />
                        ) : (
                            <MembershipTypes />
                        )
                    )}
                    {/* -------------------- SUBSCRIPTIONS -------------------- */}
                    {state.menu === 'subscriptionTypes' && (
                        (
                            state.hasValidSubscription != null
                            && state.hasValidSubscription.length > 0
                            && state.hasValidSubscription.some(s => s.newSubscription == null)
                        ) ? (
                            <RenewSubscription
                                state={state}
                                setState={setState}
                            />
                        ) : (
                            <SubscriptionTypes />
                        )
                    )}
                </>
            )}
        </>
    );
};

// ---------------------------------- CREATE SUBSCRIPTION ------------------------------- \\
const CreateLoggedUserSubscription = () => {
    const history = useHistory();
    const { subscriptionType } = useParams();
    const { mediaMD } = useMediaQueries();

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push('/shop')}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    Formulaire d'{subscriptionType === 'membership' ? 'adhésion' : 'abonnement'}
                </Grid>
            </Grid>
            {/* -------------------- CREATE SUBSCRIPTION -------------------- */}
            <CreateSubscription />
        </>
    );
};

export default ShopRouter;
