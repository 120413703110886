import React, { useState, Fragment } from 'react';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';
import { getAmountTTC } from 'services/helpers';

// Views
import AddSessionOption from './session-options-add';
import UpdateSessionOption from './session-options-update';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- SESSION OPTIONS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SessionOptions = ({ refetch, session }) => {
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        session_options: session.sessionOptions,
        session_option: undefined,
        total_count: session.sessionOptions.length,
        openAdd: false,
        openUpdate: false,
    });

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <b>OPTIONS</b>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            {/* -------------------- ADD SESSION OPTIONS -------------------- */}
            <Grid container justify="flex-end" style={{ padding: '0px 24px' }}>
                <Button
                    onClick={() => setState({ ...state, openAdd: true })}
                    variant="contained"
                    style={{ textTransform: 'none' }}
                >
                    Ajouter une option à la session
                </Button>
            </Grid>
            <Grid style={{ padding: 24 }}>
                {/* -------------------- HEADER LIST -------------------- */}
                <Grid container alignItems="center" className="list-headers">
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                Prix (€)
                            </Grid>
                            <Grid item xs={3} style={{ padding: 10 }}>
                                Intitulé sur bon de commande
                            </Grid>
                            <Grid item xs={7} style={{ padding: 10 }}>
                                Description pour le formulaire d'inscription
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ padding: 10 }}>
                            Options
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- SESSION OPTION LIST -------------------- */}
                {state.session_options.length > 0 ? (
                    state.session_options.map(session_option => (
                        <Fragment key={session_option.id}>
                            <Grid
                                onClick={() => setState({ ...state, openUpdate: true, session_option })}
                                className="simple-list"
                                container alignItems="center"
                            >
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {getAmountTTC(session_option.price)}
                                </Grid>
                                <Grid item xs={12} md={3} style={{ padding: 10 }}>
                                    {session_option.name}
                                </Grid>
                                <Grid item xs={12} md={7} style={{ padding: 10 }}> 
                                    {session_option.description}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid container style={{ padding: 10 }}>
                            Aucune
                        </Grid>
                        <Divider />
                    </>
                )}
            </Grid>
            {/* -------------------- ADD SESSION OPTION -------------------- */}
            {state.openAdd && (
                <AddSessionOption
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={() => refetch()}
                    session={session}
                />
            )}
            {/* -------------------- UPDATE SESSION OPTION -------------------- */}
            {state.openUpdate && (
                <UpdateSessionOption
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => refetch()}
                    session_option={state.session_option}
                />
            )}
        </>
    );
};

export default SessionOptions;
