import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Google Maps API
import GoogleMapReact from 'google-map-react';

// Material-UI
import { Grid, Popover, Button, Divider } from '@material-ui/core';

// Services
import { fileUrl } from 'services/axios-config';
import { getStoredUser } from 'services/storage';
import { getFormattedDate } from 'services/helpers';

// Components
import Spinner from 'components/spinner';

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------- GOOGLE MAP INTERACTIVE -------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const GoogleMapInteractive = ({ formationSessions, membersRegistration, mapHeight }) => {

    const [state, setState] = useState({
        loading: true,
        sessions: [],
    });

    useEffect(() => {
        if (state.loading) {

            var sessions = [];
            formationSessions.forEach(session => {

                if (session.mapAddress?.longitude != null && session.mapAddress?.latitude != null) {

                    var sessionsAtSamePlace = formationSessions.filter(s =>
                        s.mapAddress?.longitude === session.mapAddress.longitude
                        && s.mapAddress?.latitude === session.mapAddress.latitude
                    );

                    /* * *
                    * Si plusieurs sessions de formations ont exactement les mêmes coordonnées,
                    * on écarte très légèrement les marqueurs du centre initial pour éviter qu'ils se superposent
                    */
                    if (sessionsAtSamePlace.length > 1) {

                        var mapAddress = session.mapAddress;
                        var index = sessionsAtSamePlace.findIndex(s => s.id === session.id);

                        switch (index) {
                            case 1:
                                mapAddress = Object.assign({}, mapAddress, {
                                    latitude: Number(mapAddress.latitude) + 0.001,
                                });
                                break;
                            
                            case 2:
                                mapAddress = Object.assign({}, mapAddress, {
                                    longitude: Number(mapAddress.longitude) + 0.001,
                                });
                                break;

                            case 3:
                                mapAddress = Object.assign({}, mapAddress, {
                                    latitude: Number(mapAddress.latitude) - 0.001,
                                });
                                break;

                            case 4:
                                mapAddress = Object.assign({}, mapAddress, {
                                    longitude: Number(mapAddress.longitude) - 0.001,
                                });
                                break;

                            default:
                                break;
                        }

                        sessions.push(Object.assign({}, session, {
                            mapAddress,
                            markersNumber: (index + 1 === sessionsAtSamePlace.length) ? sessionsAtSamePlace.length : null,
                        }));
                    }
                    else {
                        sessions.push(session);
                    }
                }
                else {
                    sessions.push(session);
                }
            });
            
            setState({ ...state, loading: false, sessions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;

    return (
        <div style={{ width: '100%', height: mapHeight }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
                defaultCenter={{ lat: 47.00, lng: 2.00 }}
                defaultZoom={5.5}
            >
                {state.sessions.map(session => {
                    if (session.mapAddress?.longitude != null && session.mapAddress?.latitude != null) {
                        return (
                            <Markers
                                key={session.id}
                                lng={session.mapAddress.longitude}
                                lat={session.mapAddress.latitude}
                                formationSession={session}
                                membersRegistration={membersRegistration}
                            />
                        );
                    }
                    return null;
                })}
            </GoogleMapReact>
        </div>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------------ MARKERS ------------------------------------------ \\
// --------------------------------------------------------------------------------------------------- \\

const Markers = ({ formationSession, membersRegistration }) => {
    let mapAddress = formationSession.mapAddress;
    let storedUserId = getStoredUser()?.id;
    const history = useHistory();
    const { userId } = useParams();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getOpacity = () => {
        if (formationSession.status === 'cancelled' || formationSession.status === 'closed') {
            return 0.3;
        }
        return 1;
    };

    const getPushUrl = () => {
        if (userId) {
            return '/users/' + userId + '/formations/' + formationSession.id + '/session';
        }
        if (membersRegistration) {
            return '/formations/' + formationSession.id + '/session/' + storedUserId + '/members';
        }
        return '/formations/' + formationSession.id + '/session/' + storedUserId;
    };

    const getDate = () => {
        if (formationSession.begin && formationSession.end) {
            return getFormattedDate(formationSession.begin) + ' - ' + getFormattedDate(formationSession.end);
        }
        return '';
    };

    return (
        <div>
            <img
                alt={formationSession.title}
                onClick={handleClick}
                src={fileUrl + formationSession.categories[0]?.icon_url}
                style={{
                    height: 30,
                    width: 'auto',
                    cursor: 'pointer',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    opacity: getOpacity(),
                }}
            />
            {formationSession.markersNumber != null && (
                <div
                    onClick={handleClick}
                    style={{
                        textAlign: 'center',
                        position: 'absolute',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        height: 14,
                        width: 14,
                        fontSize: 12,
                    }}
                >
                    {formationSession.markersNumber.toString()}
                </div>
            )}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{ style: { maxWidth: 300 } }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: 12 }}>
                    <div style={{ fontWeight: 600 }}>
                        {formationSession.name} (#{formationSession.number})
                    </div>
                    <div style={{ fontWeight: 600, color: '#3f51b5' }}>
                        {formationSession.title}
                    </div>
                    <div style={{ fontSize: 16 }}>
                        {getDate()}
                    </div>
                    <div>
                        {mapAddress.address} {mapAddress.postal_code} {mapAddress.city}
                    </div>
                </div>
                {formationSession.map_information && (
                    <>
                        <Divider />
                        <div
                            className="text"
                            style={{ padding: 12 }}
                        >
                            {formationSession.map_information}
                        </div>
                    </>
                )}
                <Divider />
                <Grid
                    container justify="center"
                    style={{ padding: 12 }}
                >
                    <Button
                        onClick={() => history.push(getPushUrl())}
                        color="primary" variant="contained"
                        style={{ textTransform: 'none' }}
                    >
                        Informations et inscription
                    </Button>
                </Grid>
            </Popover>
        </div>
    );
};

export default GoogleMapInteractive;
