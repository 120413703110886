import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Checkbox, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';

// Services
import { getFormations, updateIndexPreferences, getIndexPreferences } from 'services/requests';
import { userCanCreateFormation } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';
import Error from 'components/error';
import Pagination from 'components/pagination';
import SelectCreatable from 'components/select-creatable';

// Selects
import SelectYesOrNo from 'selects/select-yes-or-no';
import SelectCategories from 'selects/select-categories';

// Views
import CreateFormation from './create-formation';
import Formation from './formation';
import FormationsExport from './formations-export';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- FORMATIONS ROUTER -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Formations />
            </Route>
            <Route path={`${match.path}/:formationId`}>
                <Formation />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- FORMATIONS ------------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const Formations = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        formations: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        openCreate: false,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_title: true,
        enabled_duration: true,
        enabled_category: true,
        enabled_support: true,

        // Filters
        titles: [],
        durations: [],
        categories: [],
        support: -1,
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            titles: [],
            durations: [],
            categories: [],
            support: -1,
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'formations',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_title: columns.enabled_title,
                            enabled_duration: columns.enabled_duration,
                            enabled_category: columns.enabled_category,
                            enabled_support: columns.enabled_support,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            getFormations({
                offset: state.offset,
                limit: state.limit,
                titles: state.titles ? state.titles.map(t => t.value) : null,
                durations: state.durations ? state.durations.map(d => d.value) : null,
                category_ids: state.categories ? state.categories.map(c => c.value) : null,
                support: state.support,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        formations: res.data.data.formations,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Modèles de formation
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanCreateFormation() && (
                        <Button onClick={() => setState({ ...state, openCreate: true })} variant="contained">
                            Ajouter un modèle
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <FormationsExport data={state} />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <FormationList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
            {/* -------------------- CREATE FORMATION -------------------- */}
            {state.openCreate && (
                <CreateFormation
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- TITLE -------------------- */}
            {state.enabled_title && (
                <div className="column-list">
                    Intitulé du modèle
                </div>
            )}
            {/* -------------------- DURATION -------------------- */}
            {state.enabled_duration && (
                <div className="column-list">
                    Durée
                </div>
            )}
            {/* -------------------- CATEGORY -------------------- */}
            {state.enabled_category && (
                <div className="column-list">
                    Catégorie
                </div>
            )}
            {/* -------------------- SUPPORT -------------------- */}
            {state.enabled_support && (
                <div className="column-list">
                    Support pédagogique
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- TITLE -------------------- */}
            {state.enabled_title && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.titles}
                        onChange={titles => onSelectSearch('titles', titles)}
                        placeholder="Intitulé du modèle"
                    />
                </div>
            )}
            {/* -------------------- DURATION -------------------- */}
            {state.enabled_duration && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.durations}
                        onChange={durations => onSelectSearch('durations', durations)}
                        placeholder="Durée"
                    />
                </div>
            )}
            {/* -------------------- CATEGORY -------------------- */}
            {state.enabled_category && (
                <div className="column-list">
                    <SelectCategories
                        categories={state.categories}
                        setCategories={categories => onSelectSearch('categories', categories)}
                    />
                </div>
            )}
            {/* -------------------- SUPPORT -------------------- */}
            {state.enabled_support && (
                <div className="column-list">
                    <SelectYesOrNo
                        label="Support pédagogique"
                        value={state.support}
                        onChange={support => onSelectSearch('support', support)}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- FORMATION LIST --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationList = ({ state }) => {
    const history = useHistory();
    const match = useRouteMatch();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.formations.length > 0 && (
                state.formations.map(formation => (
                    <Fragment key={formation.id}>
                        <div
                            onClick={() => history.push(`${match.url}/${formation.id}`)}
                            className="simple-list"
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- TITLE -------------------- */}
                            {state.enabled_title && (
                                <div className="column-list">
                                    {formation.title}
                                </div>
                            )}
                            {/* -------------------- DURATION -------------------- */}
                            {state.enabled_duration && (
                                <div className="column-list">
                                    {formation.duration}h
                                </div>
                            )}
                            {/* -------------------- CATEGORY -------------------- */}
                            {state.enabled_category && (
                                <div className="column-list">
                                    {formation.categories.map((c, index) => {
                                        if (index === 0) return c.name;
                                        else return ', ' + c.name;
                                    })}
                                </div>
                            )}
                            {/* -------------------- SUPPORT -------------------- */}
                            {state.enabled_support && (
                                <div className="column-list">
                                    {formation.document_url ? 'Oui' : 'Non'}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_title: state.enabled_title,
            enabled_duration: state.enabled_duration,
            enabled_category: state.enabled_category,
            enabled_support: state.enabled_support,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'formations',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                {/* -------------------- TITLE -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_title}
                        onChange={e => setState({ ...state, enabled_title: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Intitulé du modèle
                </Grid>
                {/* -------------------- DURATION -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_duration}
                        onChange={e => setState({ ...state, enabled_duration: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Durée
                </Grid>
                {/* -------------------- CATEGORY -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_category}
                        onChange={e => setState({ ...state, enabled_category: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Catégorie
                </Grid>
                {/* -------------------- SUPPORT -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_support}
                        onChange={e => setState({ ...state, enabled_support: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Support pédagogique
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormationsRouter;
