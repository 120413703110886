import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { createFormation } from 'services/requests';

// Views
import FormationForm from '../formation-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- CREATE FORMATION --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const CreateFormation = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        actionType: 'CREATE',
        loading: false,
        title: '',
        description: '',
        prerequisite: '',
        duration: '',
        categories: [],
        uploaded_file: null,
        uploaded_photo: null,
    });

    function onCreate() {
        if (state.title === ''
            || state.duration === ''
            || (state.categories == null || state.categories.length === 0)
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const photo = document.getElementById('upload-photo').files[0];
            const file = document.getElementById('upload-file').files[0];

            setState({ ...state, loading: true, uploaded_photo: photo, uploaded_file: file });
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('title', state.title);
            formData.append('description', state.description);
            formData.append('prerequisite', state.prerequisite);
            formData.append('duration', state.duration);
            formData.append('category_ids', JSON.stringify(state.categories.map(c => c.value)));

            if (state.uploaded_photo) {
                formData.append('uploaded_photo', state.uploaded_photo);
            }
            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }

            createFormation(formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Ajouter un modèle de formation
                </p>
                <FormationForm
                    state={state}
                    setState={setState}
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Annuler
                </Button>
                <Button onClick={onCreate} variant="contained">
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateFormation;
