import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Checkbox, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

// Services
import { getManagementSessions, updateIndexPreferences, getIndexPreferences } from 'services/requests';
import { userCanCreateFormationSession} from 'services/permissions';
import { getStoredUser } from 'services/storage';
import { formationSessionStatuses } from 'services/constants';
import { getLabelStatus, getColorStatus, getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';
import Error from 'components/error';
import Pagination from 'components/pagination';
import InputText from 'components/input-text';
import InputComparator from 'components/input-comparator';
import SelectCreatable from 'components/select-creatable';

// Views
import CreateFormationSession from '../formations/formation-session/formation-session-add';
import FormationSession from '../formations/formation-session';
import FormationSessionsExport from './formation-sessions-export';

// Selects
import SelectFormationSessionStatuses from 'selects/select-formation-session-statuses';
import SelectCategories from 'selects/select-categories';
import SelectFrenchDepartments from 'selects/select-french-departments';
import SearchAndSelectUsers from 'selects/search-and-select-users';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ROUTER -------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSessionsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <FormationSessions />
            </Route>
            <Route path={`${match.path}/:sessionId`}>
                <FormationSession />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- FORMATION SESSIONS ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSessions = () => {
    const history = useHistory();
    const { mediaMD, mediaSM } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        formationSessions: [],
        offset: 0,
        limit: 30,
        total_count: 0,

        // Create Formation
        openCreate: false,
        session: undefined,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_status: true,
        enabled_title: true,
        enabled_name: true,
        enabled_french_department: true,
        enabled_category: true,
        enabled_manager: true,
        enabled_trainer: true,
        enabled_start_date: true,
        enabled_end_date: true,
        enabled_place_name: true,
        enabled_end_of_registration: false,
        enabled_vivea_reference: false,
        enabled_nb_registered: false,
        enabled_nb_requested: false,
        enabled_animator: false,
        enabled_season: false,

        // Filters
        order_by: '',
        order: 'desc',
        period: 'all',
        statuses: [],
        titles: [],
        names: [],
        french_departments: [],
        categories: [],
        managers: [],
        trainers: [],
        start_date: '',
        end_date: '',
        place_name: '',
        end_of_registration: '',
        vivea_reference: '',
        nb_registered: '',
        nb_registered_comparator: '<',
        nb_requested: '',
        nb_requested_comparator: '<',
        animators: [],
        seasons: [],
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            order_by: '',
            order: 'desc',
            period: 'all',
            statuses: [],
            titles: [],
            names: [],
            french_departments: [],
            categories: [],
            managers: [],
            trainers: [],
            start_date: '',
            end_date: '',
            place_name: '',
            end_of_registration: '',
            vivea_reference: '',
            nb_registered: '',
            nb_registered_comparator: '<',
            nb_requested: '',
            nb_requested_comparator: '<',
            animators: [],
            seasons: [],
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'formationSessions',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_status: columns.enabled_status,
                            enabled_title: columns.enabled_title,
                            enabled_name: columns.enabled_name,
                            enabled_french_department: columns.enabled_french_department,
                            enabled_category: columns.enabled_category,
                            enabled_manager: columns.enabled_manager,
                            enabled_trainer: columns.enabled_trainer,
                            enabled_start_date: columns.enabled_start_date,
                            enabled_end_date: columns.enabled_end_date,
                            enabled_place_name: columns.enabled_place_name,
                            enabled_end_of_registration: columns.enabled_end_of_registration,
                            enabled_vivea_reference: columns.enabled_vivea_reference,
                            enabled_nb_registered: columns.enabled_nb_registered,
                            enabled_nb_requested: columns.enabled_nb_requested,
                            enabled_animator: columns.enabled_animator,
                            enabled_season: columns.enabled_season,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            getManagementSessions({
                offset: state.offset,
                limit: state.limit,
                order_by: state.order_by,
                order: state.order,
                period: state.period,
                statuses: state.statuses ? state.statuses.map(s => s.value) : null,
                titles: state.titles ? state.titles.map(f => f.value) : null,
                names: state.names ? state.names.map(n => n.value) : null,
                french_department_ids: state.french_departments ? state.french_departments.map(d => d.value) : null,
                category_ids: state.categories ? state.categories.map(c => c.value) : null,
                manager_ids: state.managers ? state.managers.map(m => m.value) : null,
                trainer_ids: state.trainers ? state.trainers.map(t => t.value) : null,
                start_date: state.start_date,
                end_date: state.end_date,
                place_name: state.place_name,
                end_of_registration: state.end_of_registration,
                vivea_reference: state.vivea_reference,
                nb_registered: state.nb_registered,
                nb_registered_comparator: state.nb_registered_comparator,
                nb_requested: state.nb_requested,
                nb_requested_comparator: state.nb_requested_comparator,
                animator_ids: state.animators ? state.animators.map(a => a.value) : null,
                seasons: state.seasons ? state.seasons.map(s => s.value) : null,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        formationSessions: res.data.data.formationSessions,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Formations
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanCreateFormationSession() && (
                        <Button onClick={() => setState({ ...state, openCreate: true })} variant="contained">
                            Ajouter une formation
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <FormationSessionsExport data={state} />
                    <ToggleButtonGroup
                        value={state.period}
                        onChange={(e, value) => value != null ? setState({ ...state, loading: true, period: value }) : null}
                        exclusive size="small"
                        className="toggle-button"
                        style={{ marginLeft: mediaSM ? 5 : 0, marginTop: mediaSM ? 0 : 10 }}
                    >
                        <ToggleButton value="all" style={{ width: 84, textTransform: 'none' }}>
                            Toutes
                        </ToggleButton>
                        <ToggleButton value="upcoming" style={{ width: 84, textTransform: 'none' }}>
                            À venir
                        </ToggleButton>
                        <ToggleButton value="current" style={{ width: 84, textTransform: 'none' }}>
                            En cours
                        </ToggleButton>
                        <ToggleButton value="past" style={{ width: 84, textTransform: 'none' }}>
                            Passées
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <b style={{ marginTop: mediaSM ? 0 : 10 }}>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <FormationSessionList
                        state={state}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
            {/* -------------------- CREATE FORMATION SESSION -------------------- */}
            {state.openCreate && (
                <CreateFormationSession
                    isSession
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    Statut
                </div>
            )}
            {/* -------------------- TITLE -------------------- */}
            {state.enabled_title && (
                <div className="column-list">
                    Modèle de formation
                </div>
            )}
            {/* -------------------- NAME -------------------- */}
            {state.enabled_name && (
                <div className="column-list">
                    Nom de la formation
                </div>
            )}
            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    Département
                </div>
            )}
            {/* -------------------- CATEGORY -------------------- */}
            {state.enabled_category && (
                <div className="column-list">
                    Catégorie
                </div>
            )}
            {/* -------------------- MANAGER -------------------- */}
            {state.enabled_manager && (
                <div className="column-list">
                    Responsable
                </div>
            )}
            {/* -------------------- TRAINER -------------------- */}
            {state.enabled_trainer && (
                <div className="column-list">
                    Formateur
                </div>
            )}
            {/* -------------------- START DATE -------------------- */}
            {state.enabled_start_date && (
                <div className="column-list">
                    Date première session
                </div>
            )}
            {/* -------------------- END DATE -------------------- */}
            {state.enabled_end_date && (
                <div className="column-list">
                    Date dernière session
                </div>
            )}
            {/* -------------------- PLACE NAME -------------------- */}
            {state.enabled_place_name && (
                <div className="column-list">
                    Lieu de formation
                </div>
            )}
            {/* -------------------- END OF REGISTRATION -------------------- */}
            {state.enabled_end_of_registration && (
                <div className="column-list">
                    Date fin d'inscription
                </div>
            )}
            {/* -------------------- VIVEA REFERENCE -------------------- */}
            {state.enabled_vivea_reference && (
                <div className="column-list">
                    Référence VIVEA
                </div>
            )}
            {/* -------------------- NB REGISTERED -------------------- */}
            {state.enabled_nb_registered && (
                <div className="column-list">
                    Nombre d'inscrits confirmés
                </div>
            )}
            {/* -------------------- NB REQUESTED -------------------- */}
            {state.enabled_nb_requested && (
                <div className="column-list">
                    Nombre de pré-inscrits
                </div>
            )}
            {/* -------------------- ANIMATOR -------------------- */}
            {state.enabled_animator && (
                <div className="column-list">
                    Animateur
                </div>
            )}
            {/* -------------------- SEASON -------------------- */}
            {state.enabled_season && (
                <div className="column-list">
                    Saison
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    <SelectFormationSessionStatuses
                        statuses={state.statuses}
                        setStatuses={statuses => onSelectSearch('statuses', statuses)}
                    />
                </div>
            )}
            {/* -------------------- TITLE -------------------- */}
            {state.enabled_title && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.titles}
                        onChange={titles => onSelectSearch('titles', titles)}
                        placeholder="Modèle de formation"
                    />
                </div>
            )}
            {/* -------------------- NAME -------------------- */}
            {state.enabled_name && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.names}
                        onChange={names => onSelectSearch('names', names)}
                        placeholder="Nom de la formation"
                    />
                </div>
            )}
            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    <SelectFrenchDepartments
                        frenchDepartments={state.french_departments}
                        setFrenchDepartments={french_departments => onSelectSearch('french_departments', french_departments)}
                    />
                </div>
            )}
            {/* -------------------- CATEGORY -------------------- */}
            {state.enabled_category && (
                <div className="column-list">
                    <SelectCategories
                        categories={state.categories}
                        setCategories={categories => onSelectSearch('categories', categories)}
                    />
                </div>
            )}
            {/* -------------------- MANAGER -------------------- */}
            {state.enabled_manager && (
                <div className="column-list">
                    <SearchAndSelectUsers
                        users={state.managers}
                        setUsers={managers => onSelectSearch('managers', managers)}
                        placeholder="Responsable"
                    />
                </div>
            )}
            {/* -------------------- TRAINER -------------------- */}
            {state.enabled_trainer && (
                <div className="column-list">
                    <SearchAndSelectUsers
                        users={state.trainers}
                        setUsers={trainers => onSelectSearch('trainers', trainers)}
                        placeholder="Formateur"
                    />
                </div>
            )}
            {/* -------------------- START DATE -------------------- */}
            {state.enabled_start_date && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.start_date}
                        onChange={e => setState({ ...state, start_date: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- END DATE -------------------- */}
            {state.enabled_end_date && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.end_date}
                        onChange={e => setState({ ...state, end_date: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PLACE NAME -------------------- */}
            {state.enabled_place_name && (
                <div className="column-list">
                    <InputText
                        label="Lieu première session"
                        value={state.place_name}
                        onChange={e => setState({ ...state, place_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- END OF REGISTRATION -------------------- */}
            {state.enabled_end_of_registration && (
                <div className="column-list">
                    <InputText
                        label="Date fin d'inscription"
                        value={state.end_of_registration}
                        onChange={e => setState({ ...state, end_of_registration: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- VIVEA REFERENCE -------------------- */}
            {state.enabled_vivea_reference && (
                <div className="column-list">
                    <InputText
                        label="Référence VIVEA"
                        value={state.vivea_reference}
                        onChange={e => setState({ ...state, vivea_reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- NB REGISTERED -------------------- */}
            {state.enabled_nb_registered && (
                <div className="column-list">
                    <InputComparator
                        label="Nombre d'inscrits confirmés"
                        value={state.nb_registered}
                        onChange={e => setState({ ...state, nb_registered: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                        comparator={state.nb_registered_comparator}
                        onChangeComparator={() => setState({ ...state, nb_registered_comparator: state.nb_registered_comparator === '>' ? '<' : '>' })}
                    />
                </div>
            )}
            {/* -------------------- NB REQUESTED -------------------- */}
            {state.enabled_nb_requested && (
                <div className="column-list">
                    <InputComparator
                        label="Nombre de pré-inscrits"
                        value={state.nb_requested}
                        onChange={e => setState({ ...state, nb_requested: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                        comparator={state.nb_requested_comparator}
                        onChangeComparator={() => setState({ ...state, nb_requested_comparator: state.nb_requested_comparator === '>' ? '<' : '>' })}
                    />
                </div>
            )}
            {/* -------------------- ANIMATOR -------------------- */}
            {state.enabled_animator && (
                <div className="column-list">
                    <SearchAndSelectUsers
                        users={state.animators}
                        setUsers={animators => onSelectSearch('animators', animators)}
                        placeholder="Animateur"
                    />
                </div>
            )}
            {/* -------------------- SEASON -------------------- */}
            {state.enabled_season && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.seasons}
                        onChange={seasons => onSelectSearch('seasons', seasons)}
                        placeholder="Saison"
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ FORMATION SESSION LIST ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSessionList = ({ state }) => {
    const match = useRouteMatch();
    const history = useHistory();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.formationSessions.length > 0 && (
                state.formationSessions.map(session => (
                    <Fragment key={session.id}>
                        <div
                            onClick={() => history.push(match.url + '/' + session.id)}
                            className="simple-list"
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- STATUS -------------------- */}
                            {state.enabled_status && (
                                <div className="column-list">
                                    <b className="title-status-list" style={{ color: getColorStatus(formationSessionStatuses, session.status) }}>
                                        {getLabelStatus(formationSessionStatuses, session.status)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- TITLE -------------------- */}
                            {state.enabled_title && (
                                <div className="column-list">
                                    {session.formation ? session.formation.title : ''}
                                </div>
                            )}
                            {/* -------------------- NAME -------------------- */}
                            {state.enabled_name && (
                                <div className="column-list">
                                    {session.name} (#{session.number})
                                </div>
                            )}
                            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                            {state.enabled_french_department && (
                                <div className="column-list">
                                    {session.frenchDepartments.map((d, index) => {
                                        if (index === 0) return d.name + ' (' + d.number + ')';
                                        else return ', ' + d.name + ' (' + d.number + ')';
                                    })}
                                </div>
                            )}
                            {/* -------------------- CATEGORY -------------------- */}
                            {state.enabled_category && (
                                <div className="column-list">
                                    {session.formation ? session.formation.categories.map((c, index) => {
                                        if (index === 0) return c.name;
                                        else return ', ' + c.name;
                                    }) : ''}
                                </div>
                            )}
                            {/* -------------------- MANAGER -------------------- */}
                            {state.enabled_manager && (
                                <div className="column-list">
                                    {session.manager ? session.manager.fullName : ''}
                                </div>
                            )}
                            {/* -------------------- TRAINER -------------------- */}
                            {state.enabled_trainer && (
                                <div className="column-list">
                                    {session.trainers.map((t, index) => {
                                        if (index === 0) return t.fullName;
                                        else return ', ' + t.fullName;
                                    })}
                                </div>
                            )}
                            {/* -------------------- START DATE -------------------- */}
                            {state.enabled_start_date && (
                                <div className="column-list">
                                    {session.begin ? getFormattedDate(session.begin) : ''}
                                </div>
                            )}
                            {/* -------------------- END DATE -------------------- */}
                            {state.enabled_end_date && (
                                <div className="column-list">
                                    {session.end ? getFormattedDate(session.end) : ''}
                                </div>
                            )}
                            {/* -------------------- PLACE NAME -------------------- */}
                            {state.enabled_place_name && (
                                <div className="column-list">
                                    {session.placeName}
                                </div>
                            )}
                            {/* -------------------- END OF REGISTRATION -------------------- */}
                            {state.enabled_end_of_registration && (
                                <div className="column-list">
                                    {session.end_of_registration ? getFormattedDate(session.end_of_registration) : ''}
                                </div>
                            )}
                            {/* -------------------- VIVEA REFERENCE -------------------- */}
                            {state.enabled_vivea_reference && (
                                <div className="column-list">
                                    {session.vivea_reference}
                                </div>
                            )}
                            {/* -------------------- NB REGISTERED -------------------- */}
                            {state.enabled_nb_registered && (
                                <div className="column-list">
                                    {session.countRegistered}
                                </div>
                            )}
                            {/* -------------------- NB REQUESTED -------------------- */}
                            {state.enabled_nb_requested && (
                                <div className="column-list">
                                    {session.countRequested}
                                </div>
                            )}
                            {/* -------------------- ANIMATOR -------------------- */}
                            {state.enabled_animator && (
                                <div className="column-list">
                                    {session.animator ? session.animator.fullName : ''}
                                </div>
                            )}
                            {/* -------------------- SEASON -------------------- */}
                            {state.enabled_season && (
                                <div className="column-list">
                                    {session.season}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- MANAGE COLUMNS ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_status: state.enabled_status,
            enabled_title: state.enabled_title,
            enabled_name: state.enabled_name,
            enabled_french_department: state.enabled_french_department,
            enabled_category: state.enabled_category,
            enabled_manager: state.enabled_manager,
            enabled_trainer: state.enabled_trainer,
            enabled_start_date: state.enabled_start_date,
            enabled_end_date: state.enabled_end_date,
            enabled_place_name: state.enabled_place_name,
            enabled_end_of_registration: state.enabled_end_of_registration,
            enabled_vivea_reference: state.enabled_vivea_reference,
            enabled_nb_registered: state.enabled_nb_registered,
            enabled_nb_requested: state.enabled_nb_requested,
            enabled_animator: state.enabled_animator,
            enabled_season: state.enabled_season,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'formationSessions',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            {state.loadingColumns ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Gérer les colonnes affichées
                        </p>
                        <div style={{ display: 'flex' }}>
                            <div>
                                {/* -------------------- STATUS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_status}
                                        onChange={e => setState({ ...state, enabled_status: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Statut
                                </Grid>
                                {/* -------------------- TITLE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_title}
                                        onChange={e => setState({ ...state, enabled_title: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Modèle de formation
                                </Grid>
                                {/* -------------------- NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_name}
                                        onChange={e => setState({ ...state, enabled_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Nom de la formation
                                </Grid>
                                {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_french_department}
                                        onChange={e => setState({ ...state, enabled_french_department: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Département
                                </Grid>
                                {/* -------------------- CATEGORY -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_category}
                                        onChange={e => setState({ ...state, enabled_category: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Catégorie
                                </Grid>
                                {/* -------------------- MANAGER -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_manager}
                                        onChange={e => setState({ ...state, enabled_manager: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Responsable
                                </Grid>
                                {/* -------------------- TRAINER -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_trainer}
                                        onChange={e => setState({ ...state, enabled_trainer: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Formateur
                                </Grid>
                                {/* -------------------- START DATE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_start_date}
                                        onChange={e => setState({ ...state, enabled_start_date: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date première session
                                </Grid>
                            </div>
                            <div>
                                {/* -------------------- END DATE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_end_date}
                                        onChange={e => setState({ ...state, enabled_end_date: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date dernière session
                                </Grid>
                                {/* -------------------- PLACE NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_place_name}
                                        onChange={e => setState({ ...state, enabled_place_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Lieu de formation
                                </Grid>
                                {/* -------------------- END OF REGISTRATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_end_of_registration}
                                        onChange={e => setState({ ...state, enabled_end_of_registration: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date fin d'inscription
                                </Grid>
                                {/* -------------------- VIVEA REFERENCE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_vivea_reference}
                                        onChange={e => setState({ ...state, enabled_vivea_reference: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Référence VIVEA
                                </Grid>
                                {/* -------------------- NB REGISTERED -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_nb_registered}
                                        onChange={e => setState({ ...state, enabled_nb_registered: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Nombre d'inscrits confirmés
                                </Grid>
                                {/* -------------------- NB REQUESTED -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_nb_requested}
                                        onChange={e => setState({ ...state, enabled_nb_requested: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Nombre de pré-inscrits
                                </Grid>
                                {/* -------------------- ANIMATOR -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_animator}
                                        onChange={e => setState({ ...state, enabled_animator: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Animateur
                                </Grid>
                                {/* -------------------- SEASON -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_season}
                                        onChange={e => setState({ ...state, enabled_season: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Saison
                                </Grid>
                            </div>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                            Fermer
                        </Button>
                        <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default FormationSessionsRouter;
