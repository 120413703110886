import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Checkbox, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';

// Services
import { getTrainingPlaces, updateIndexPreferences, getIndexPreferences } from 'services/requests';
import { userCanCreateTrainingPlace, userCanUpdateTrainingPlace } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';
import { getFrenchDepartmentLabel } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';
import Spinner from 'components/spinner';
import Error from 'components/error';
import Pagination from 'components/pagination';
import InputText from 'components/input-text';
import SelectCreatable from 'components/select-creatable';

// Views
import CreateTrainingPlace from './training-places-create';
import UpdateTrainingPlace from './training-places-update';
import TrainingPlacesExport from './training-places-export';

// Selects
import SelectFrenchDepartments from 'selects/select-french-departments';
import SelectCountries from 'selects/select-countries';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- TRAINING PLACES --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const TraningPlaces = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        training_places: [],
        offset: 0,
        limit: 30,
        total_count: 0,

        // Modals
        openCreate: false,
        openUpdate: false,
        training_place: undefined,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_name: true,
        enabled_address: true,
        enabled_address_details: true,
        enabled_country: true,
        enabled_city: true,
        enabled_mobile_phone: true,
        enabled_phone: true,
        enabled_contact: true,
        enabled_french_department: true,
        enabled_email: true,
        enabled_postal_code: false,
        enabled_other_phone: false,
        enabled_other_email: false,

        // Filters
        names: [],
        address: '',
        address_details: '',
        countries: [],
        cities: [],
        mobile_phone: '',
        phone: '',
        contact: '',
        french_departments: [],
        email: '',
        postal_codes: [],
        other_phone: '',
        other_email: '',
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            names: [],
            address: '',
            address_details: '',
            countries: [],
            cities: [],
            mobile_phone: '',
            phone: '',
            contact: '',
            french_departments: [],
            email: '',
            postal_codes: [],
            other_phone: '',
            other_email: '',
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'training_places',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_name: columns.enabled_name,
                            enabled_address: columns.enabled_address,
                            enabled_address_details: columns.enabled_address_details,
                            enabled_country: columns.enabled_country,
                            enabled_city: columns.enabled_city,
                            enabled_mobile_phone: columns.enabled_mobile_phone,
                            enabled_phone: columns.enabled_phone,
                            enabled_contact: columns.enabled_contact,
                            enabled_french_department: columns.enabled_french_department,
                            enabled_email: columns.enabled_email,
                            enabled_postal_code: columns.enabled_postal_code,
                            enabled_other_phone: columns.enabled_other_phone,
                            enabled_other_email: columns.enabled_other_email,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            getTrainingPlaces({
                offset: state.offset,
                limit: state.limit,
                names: state.names ? state.names.map(n => n.value) : null,
                address: state.address,
                address_details: state.address_details,
                country_ids: state.countries ? state.countries.map(c => c.value) : null,
                cities: state.cities ? state.cities.map(c => c.value) : null,
                mobile_phone: state.mobile_phone,
                phone: state.phone,
                contact: state.contact,
                french_department_ids: state.french_departments ? state.french_departments.map(d => d.value) : null,
                email: state.email,
                postal_codes: state.postal_codes ? state.postal_codes.map(c => c.value) : null,
                other_phone: state.other_phone,
                other_email: state.other_email,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        training_places: res.data.data.trainingPlaces,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Lieux de formation
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanCreateTrainingPlace() && (
                        <Button onClick={() => setState({ ...state, openCreate: true })} variant="contained">
                            Ajouter un lieu
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <TrainingPlacesExport data={state} />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <TrainingPlaceList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
            {/* -------------------- CREATE TRAINING PLACE -------------------- */}
            {state.openCreate && (
                <CreateTrainingPlace
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- HEADERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- NAME -------------------- */}
            {state.enabled_name && (
                <div className="column-list">
                    Nom du lieu
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    Adresse (n° et voie)
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    Adresse (complément)
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    Pays
                </div>
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <div className="column-list">
                    Ville
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    Téléphone portable
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    Téléphone fixe
                </div>
            )}
            {/* -------------------- CONTACT -------------------- */}
            {state.enabled_contact && (
                <div className="column-list">
                    Contact du lieu
                </div>
            )}
            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    Département
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    Email
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    Code postal
                </div>
            )}
            {/* -------------------- OTHER PHONE -------------------- */}
            {state.enabled_other_phone && (
                <div className="column-list">
                    Autre téléphone
                </div>
            )}
            {/* -------------------- OTHER EMAIL -------------------- */}
            {state.enabled_other_email && (
                <div className="column-list">
                    Autre email
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- FILTERS ------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- NAME -------------------- */}
            {state.enabled_name && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.names}
                        onChange={names => onSelectSearch('names', names)}
                        placeholder="Nom du lieu"
                    />
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    <InputText
                        label="Adresse (n° et voie)"
                        value={state.address}
                        onChange={e => setState({ ...state, address: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    <InputText
                        label="Adresse (complément)"
                        value={state.address_details}
                        onChange={e => setState({ ...state, address_details: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    <SelectCountries
                        countries={state.countries}
                        setCountries={countries => onSelectSearch('countries', countries)}
                    />
                </div>
            )}
            {/* -------------------- CITY -------------------- */}
            {state.enabled_city && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.cities}
                        onChange={cities => onSelectSearch('cities', cities)}
                        placeholder="Ville"
                    />
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone portable"
                        value={state.mobile_phone}
                        onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone fixe"
                        value={state.phone}
                        onChange={e => setState({ ...state, phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- CONTACT -------------------- */}
            {state.enabled_contact && (
                <div className="column-list">
                    <InputText
                        label="Contact du lieu"
                        value={state.contact}
                        onChange={e => setState({ ...state, contact: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    <SelectFrenchDepartments
                        frenchDepartments={state.french_departments}
                        setFrenchDepartments={french_departments => onSelectSearch('french_departments', french_departments)}
                    />
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    <InputText
                        label="Email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.postal_codes}
                        onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                        placeholder="Code postal"
                    />
                </div>
            )}
            {/* -------------------- OTHER PHONE -------------------- */}
            {state.enabled_other_phone && (
                <div className="column-list">
                    <InputText
                        label="Autre téléphone"
                        value={state.other_phone}
                        onChange={e => setState({ ...state, other_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- OTHER EMAIL -------------------- */}
            {state.enabled_other_email && (
                <div className="column-list">
                    <InputText
                        label="Autre email"
                        value={state.other_email}
                        onChange={e => setState({ ...state, other_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- TRAINING PLACE LIST ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const TrainingPlaceList = ({ state, setState }) => {

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.training_places.length > 0 && (
                state.training_places.map(training_place => (
                    <Fragment key={training_place.id}>
                        <div
                            onClick={userCanUpdateTrainingPlace() ? () => setState({ ...state, openUpdate: true, training_place }) : null}
                            className={userCanUpdateTrainingPlace() ? 'simple-list' : null}
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- NAME -------------------- */}
                            {state.enabled_name && (
                                <div className="column-list">
                                    {training_place.name}
                                </div>
                            )}
                            {/* -------------------- ADDRESS -------------------- */}
                            {state.enabled_address && (
                                <div className="column-list">
                                    {training_place.address}
                                </div>
                            )}
                            {/* -------------------- ADDRESS DETAILS -------------------- */}
                            {state.enabled_address_details && (
                                <div className="column-list">
                                    {training_place.address_details}
                                </div>
                            )}
                            {/* -------------------- COUNTRY -------------------- */}
                            {state.enabled_country && (
                                <div className="column-list">
                                    {training_place.country ? training_place.country.name : ''}
                                </div>
                            )}
                            {/* -------------------- CITY -------------------- */}
                            {state.enabled_city && (
                                <div className="column-list">
                                    {training_place.city}
                                </div>
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <div className="column-list">
                                    {training_place.mobile_phone}
                                </div>
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <div className="column-list">
                                    {training_place.phone}
                                </div>
                            )}
                            {/* -------------------- CONTACT -------------------- */}
                            {state.enabled_contact && (
                                <div className="column-list">
                                    {training_place.contact}
                                </div>
                            )}
                            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                            {state.enabled_french_department && (
                                <div className="column-list">
                                    {getFrenchDepartmentLabel(training_place.frenchDepartment)}
                                </div>
                            )}
                            {/* -------------------- EMAIL -------------------- */}
                            {state.enabled_email && (
                                <div className="column-list">
                                    {training_place.email}
                                </div>
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <div className="column-list">
                                    {training_place.postal_code}
                                </div>
                            )}
                            {/* -------------------- OTHER PHONE -------------------- */}
                            {state.enabled_other_phone && (
                                <div className="column-list">
                                    {training_place.other_phone}
                                </div>
                            )}
                            {/* -------------------- OTHER EMAIL -------------------- */}
                            {state.enabled_other_email && (
                                <div className="column-list">
                                    {training_place.other_email}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
            {/* -------------------- UPDATE TRAINING PLACE -------------------- */}
            {state.openUpdate && (
                <UpdateTrainingPlace 
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    training_place={state.training_place}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_name: state.enabled_name,
            enabled_address: state.enabled_address,
            enabled_address_details: state.enabled_address_details,
            enabled_country: state.enabled_country,
            enabled_city: state.enabled_city,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_phone: state.enabled_phone,
            enabled_contact: state.enabled_contact,
            enabled_french_department: state.enabled_french_department,
            enabled_email: state.enabled_email,
            enabled_postal_code: state.enabled_postal_code,
            enabled_other_phone: state.enabled_other_phone,
            enabled_other_email: state.enabled_other_email,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'training_places',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                <div style={{ display: 'inline-flex' }}>
                    <div>
                        {/* -------------------- NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_name}
                                onChange={e => setState({ ...state, enabled_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom du lieu
                        </Grid>
                        {/* -------------------- ADDRESS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address}
                                onChange={e => setState({ ...state, enabled_address: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse (n° et voie)
                        </Grid>
                        {/* -------------------- ADDRESS DETAILS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address_details}
                                onChange={e => setState({ ...state, enabled_address_details: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse (complément)
                        </Grid>
                        {/* -------------------- COUNTRY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_country}
                                onChange={e => setState({ ...state, enabled_country: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Pays
                        </Grid>
                        {/* -------------------- CITY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_city}
                                onChange={e => setState({ ...state, enabled_city: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Ville
                        </Grid>
                        {/* -------------------- MOBILE PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_mobile_phone}
                                onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone portable
                        </Grid>
                        {/* -------------------- PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_phone}
                                onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone fixe
                        </Grid>
                    </div>
                    <div>
                        {/* -------------------- CONTACT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_contact}
                                onChange={e => setState({ ...state, enabled_contact: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Contact du lieu
                        </Grid>
                        {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_french_department}
                                onChange={e => setState({ ...state, enabled_french_department: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Département
                        </Grid>
                        {/* -------------------- EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_email}
                                onChange={e => setState({ ...state, enabled_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email
                        </Grid>
                        {/* -------------------- POSTAL CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_postal_code}
                                onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code postal
                        </Grid>
                        {/* -------------------- OTHER PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_other_phone}
                                onChange={e => setState({ ...state, enabled_other_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Autre téléphone
                        </Grid>
                        {/* -------------------- OTHER EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_other_email}
                                onChange={e => setState({ ...state, enabled_other_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Autre email
                        </Grid>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TraningPlaces;
