import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// ------------------------------------------------------------------------------------------ \\
// ------------------------------- POSTAL CODE AND CITY INPUTS ------------------------------ \\
// ------------------------------------------------------------------------------------------ \\

const PostalCodeCityInputs = ({
    state,
    setState,
    requiredPostalCode,
    requiredCity,
    fullWidth,
    labelTitle,
    marginTop = 6,
}) => {

    return (
        <>
            {/* -------------------- POSTAL CODE -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: marginTop }}
            >
                <Grid item xs={12} sm={fullWidth ? 12 : 4}>
                    <span className={labelTitle ? 'input-title' : ''}>
                        Code postal{requiredPostalCode && <FieldRequired />}
                    </span>
                </Grid>
                <Grid item xs={12} sm={fullWidth ? 12 : 8}>
                    <InputText
                        label="Code postal"
                        value={state.postal_code}
                        onChange={e => setState({ ...state, postal_code: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- CITY -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: marginTop }}
            >
                <Grid item xs={12} sm={fullWidth ? 12 : 4}>
                    <span className={labelTitle ? 'input-title' : ''}>
                        Ville{requiredCity && <FieldRequired />}
                    </span>
                </Grid>
                <Grid item xs={12} sm={fullWidth ? 12 : 8}>
                    <InputText
                        label="Ville"
                        value={state.city}
                        onChange={e => setState({ ...state, city: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PostalCodeCityInputs;
