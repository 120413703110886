import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Checkbox, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon } from '@material-ui/icons';

// Services
import { getUsersInCategory, getIndexPreferences, updateIndexPreferences } from 'services/requests';
import { userCanUpdateCategory } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import InputText from 'components/input-text';
import Tooltip from 'components/tooltip';
import Pagination from 'components/pagination';
import SelectCreatable from 'components/select-creatable';

// Views
import UpdateUserCategory from './user-categories-update';

// Selects
import SelectFederatedAssociations from 'selects/select-federated-associations';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- USER CATEGORY ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UserCategory = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { userCategoryId } = useParams();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        offset: 0,
        limit: 30,
        total_count: 0,
        users: [],
        user_category: null,
        openUpdate: false,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_full_name: true,
        enabled_company_name: true,
        enabled_email: true,
        enabled_membership_email: true,
        enabled_mobile_phone: true,
        enabled_phone: true,
        enabled_federated_association: true,
        enabled_postal_code: true,

        // Filters
        full_names: [],
        company_name: '',
        email: '',
        membership_email: '',
        mobile_phone: '',
        phone: '',
        federated_associations: [],
        postal_codes: [],
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            users: [],
            company_name: '',
            email: '',
            membership_email: '',
            mobile_phone: '',
            phone: '',
            federated_associations: [],
            postal_codes: [],
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'user_category',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_full_name: columns.enabled_full_name,
                            enabled_company_name: columns.enabled_company_name,
                            enabled_email: columns.enabled_email,
                            enabled_membership_email: columns.enabled_membership_email,
                            enabled_mobile_phone: columns.enabled_mobile_phone,
                            enabled_phone: columns.enabled_phone,
                            enabled_federated_association: columns.enabled_federated_association,
                            enabled_postal_code: columns.enabled_postal_code,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {
            getUsersInCategory(userCategoryId, {
                offset: state.offset,
                limit: state.limit,
                full_names: state.full_names ? state.full_names.map(n => n.value) : null,
                company_name: state.company_name,
                email: state.email,
                membership_email: state.membership_email,
                mobile_phone: state.mobile_phone,
                phone: state.phone,
                federated_association_ids: state.federated_associations ? state.federated_associations.map(a => a.value) : null,
                postal_codes: state.postal_codes ? state.postal_codes.map(c => c.value) : null,
            }).then(res => {
                if (res.status === 200) {
                    setState({ ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        users: res.data.data.users,
                        user_category: res.data.data.userCategory,
                    })
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: false });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences || state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    {state.user_category ? state.user_category.name : ''}
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanUpdateCategory() && (
                        <Button onClick={() => setState({ ...state, openUpdate: true })} variant="contained">
                            Modifier
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <UserCategoryList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
            {/* -------------------- UPDATE CATEGORY -------------------- */}
            {state.openUpdate && (
                <UpdateUserCategory
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => setState({ ...state, openUpdate: false, loading: true })}
                    userCategory={state.user_category}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------------- HEADERS ------------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- FULL NAME -------------------- */}
            {state.enabled_full_name && (
                <div className="column-list">
                    Utilisateur
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    Nom de l'entreprise
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    Email du compte
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    Email adhésion
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    Téléphone portable
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    Téléphone fixe
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    Association fédérée
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    Code postal
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------------- FILTERS ------------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- FULL NAME -------------------- */}
            {state.enabled_full_name && (
                <div className="column-list">
                    <SelectCreatable
                        placeholder="Utilisateur"
                        value={state.full_names}
                        onChange={full_names => onSelectSearch('full_names', full_names)}
                    />
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    <InputText
                        label="Nom de l'entreprise"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    <InputText
                        label="Email du compte"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    <InputText
                        label="Email adhésion"
                        value={state.membership_email}
                        onChange={e => setState({ ...state, membership_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone portable"
                        value={state.mobile_phone}
                        onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone fixe"
                        value={state.phone}
                        onChange={e => setState({ ...state, phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    <SelectFederatedAssociations
                        federatedAssociations={state.federated_associations}
                        setFederatedAssociations={federated_associations => onSelectSearch('federated_associations', federated_associations)}
                    />
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    <SelectCreatable
                        placeholder="Code postal"
                        value={state.postal_codes}
                        onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                    />
                </div>
            )}
        </div>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- USER CATEGORY LIST ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UserCategoryList = ({ state }) => {
    return (
        <>
            {state.users.length > 0 && (
                state.users.map(user => (
                    <Fragment key={user.id}>
                        <div style={{ display: 'inline-flex' }}>
                            {/* -------------------- FULL NAME -------------------- */}
                            {state.enabled_full_name && (
                                <div className="column-list">
                                    {user.fullName}
                                </div>
                            )}
                            {/* -------------------- COMPANY NAME -------------------- */}
                            {state.enabled_company_name && (
                                <div className="column-list">
                                    {user.company_name}
                                </div>
                            )}
                            {/* -------------------- EMAIL -------------------- */}
                            {state.enabled_email && (
                                <div className="column-list">
                                    {user.email}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                            {state.enabled_membership_email && (
                                <div className="column-list">
                                    {user.membershipEmail}
                                </div>
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <div className="column-list">
                                    {user.mobile_phone}
                                </div>
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <div className="column-list">
                                    {user.phone}
                                </div>
                            )}
                            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                            {state.enabled_federated_association && (
                                <div className="column-list">
                                    {user.federatedAssociation ? user.federatedAssociation.name : ''}
                                </div>
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <div className="column-list">
                                    {user.postal_code}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- MANAGE COLUMNS --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_full_name: state.enabled_full_name,
            enabled_company_name: state.enabled_company_name,
            enabled_email: state.enabled_email,
            enabled_membership_email: state.enabled_membership_email,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_phone: state.enabled_phone,
            enabled_federated_association: state.enabled_federated_association,
            enabled_postal_code: state.enabled_postal_code,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'user_category',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                {/* -------------------- FULL NAME -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_full_name}
                        onChange={e => setState({ ...state, enabled_full_name: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Utilisateur
                </Grid>
                {/* -------------------- COMPANY NAME -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_company_name}
                        onChange={e => setState({ ...state, enabled_company_name: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Nom d'entreprise
                </Grid>
                {/* -------------------- EMAIL -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_email}
                        onChange={e => setState({ ...state, enabled_email: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Email compte
                </Grid>
                {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_membership_email}
                        onChange={e => setState({ ...state, enabled_membership_email: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Email adhésion
                </Grid>
                {/* -------------------- MOBILE PHONE -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_mobile_phone}
                        onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Téléphone portable
                </Grid>
                {/* -------------------- PHONE -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_phone}
                        onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Téléphone fixe
                </Grid>
                {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_federated_association}
                        onChange={e => setState({ ...state, enabled_federated_association: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Association fédérée
                </Grid>
                {/* -------------------- POSTAL CODE -------------------- */}
                <Grid container alignItems="center">
                    <Checkbox
                        checked={state.enabled_postal_code}
                        onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                        style={{ padding: 6 }}
                    />
                    Code postal
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserCategory;
