import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import InputPhone from 'components/input-phone';
import DatePicker from 'components/date-picker';
import FieldRequired from 'components/field-required';

// Views
import AddressForm from 'views/common/address-form';

// -------------------------------------------------------------------------------------- \\
// ------------------------------- FORMATION REQUEST FORM ------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const FormationRequestForm = ({ state, setState }) => {
    return (
        <>
            <p
                className="secondary-color"
                style={{ textAlign: 'center', fontStyle: 'italic' }}
            >
                Les champs avec un astérisque (*) sont obligatoires
            </p>
            {/* -------------------- FIRST NAME -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Prénom<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Prénom"
                        value={state.first_name}
                        onChange={e => setState({ ...state, first_name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- LAST NAME -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Nom<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- EMAIL -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Email
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- COMPANY NAME -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Nom de l'entreprise{(state.session_price && state.session_price.require_company_name) ? <FieldRequired /> : null}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Nom de l'entreprise"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                    />
                </Grid>
            </Grid>
            <div style={{ margin: '24px 0px' }}>
                <span style={{ color: 'gray' }}>
                    <FieldRequired /> Renseigner au moins 1 numéro de téléphone
                </span>
                {/* -------------------- MOBILE PHONE -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Téléphone portable
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputPhone
                            label="Téléphone portable"
                            value={state.mobile_phone}
                            setValue={mobile_phone => setState({ ...state, mobile_phone })}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- PHONE -------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={12} sm={4}>
                        Téléphone fixe
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputPhone
                            label="Téléphone fixe"
                            value={state.phone}
                            setValue={phone => setState({ ...state, phone })}
                        />
                    </Grid>
                </Grid>
            </div>
            {/* -------------------- BIRTH DATE -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: 6 }}
            >
                <Grid item xs={12} sm={4}>
                    Date de naissance{(state.session_price && state.session_price.require_birth_date) ? <FieldRequired /> : null}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <DatePicker
                        outlined
                        value={state.birth_date}
                        onChange={birth_date => setState({ ...state, birth_date })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- ADDRESS FORM -------------------- */}
            <AddressForm
                state={state}
                setState={setState}
                requiredAddress
                requiredPostalCode
                requiredCity
                requiredCountry
                disabledDepartment
            />
            {/* -------------------- MESSAGE -------------------- */}
            <div style={{ marginTop: 6 }}>
                <InputText
                    label="Commentaire"
                    value={state.message}
                    onChange={e => setState({ ...state, message: e.target.value })}
                    multiline
                />
            </div>
        </>
    );
};

export default FormationRequestForm;
