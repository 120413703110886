import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 180;

// -------------------- HEADER -------------------- \\
const LastNameHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Nom
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const LastNameFilter = ({ state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label="Nom"
                value={state.last_name}
                onChange={e => setState({ ...state, last_name: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const LastNameValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { LastNameHeader, LastNameFilter, LastNameValue };
