import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Divider, Button } from '@material-ui/core';

// Services
import { getFormattedDate, getLabelStatus, getColorStatus } from 'services/helpers';
import { subscriptionStatuses } from 'services/constants';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------- LAST SUBSCRIPTION -------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const LastSubscription = ({ state }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const subscription = state.last_subscription;

    const getDate = () => {
        if (subscription?.start_date && subscription?.end_date) {
            return getFormattedDate(subscription.start_date) + ' - ' + getFormattedDate(subscription.end_date);
        }
        return '';
    };

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <b>ABONNEMENT</b>
            {subscription ? (
                <>
                    <br />
                    {/* -------------------- DATE -------------------- */}
                    {getDate()}
                    {/* -------------------- RENEWAL -------------------- */}
                    {((subscription.status === 'validated' || subscription.status === 'expired')
                        && subscription.newSubscription == null
                        && (subscription.membership == null || subscription.membership.membershipType?.is_pro === 0)
                        && (Number(subscription.end_date?.substring(0, 4)) === moment().year()
                            || Number(subscription.end_date?.substring(0, 4)) === moment().year() - 1
                            || Number(subscription.end_date?.substring(0, 4)) === moment().year() + 1)
                    ) && (
                        <>
                            <br />
                            <Button
                                onClick={() => history.push('/shop?subscriptions=1')}
                                color="primary" variant="contained"
                            >
                                Renouveler votre abonnement
                            </Button>
                        </>
                    )}
                    <Divider style={{ margin: '10px 0px' }} />
                    {/* -------------------- SUBSCRIPTION TYPE -------------------- */}
                    <p>
                        <b>Type d'abonnement</b>
                        <br />
                        {subscription.subscriptionType?.name}
                    </p>
                    {/* -------------------- ADDRESS -------------------- */}
                    <p>
                        <b>Adresse de facturation</b>
                        <br />
                        {subscription.address} {subscription.postal_code} {subscription.city} {subscription.country?.name}
                    </p>
                    {/* -------------------- EMAIL -------------------- */}
                    {subscription.subscription_type_id === 1 && (
                        <p>
                            <b>Email</b>
                            <br />
                            {subscription.email}
                        </p>
                    )}
                    {/* -------------------- STATUS -------------------- */}
                    <p>
                        <b>Statut</b>
                        <br />
                        <b style={{ color: getColorStatus(subscriptionStatuses, subscription.status) }}>
                            {getLabelStatus(subscriptionStatuses, subscription.status)?.toUpperCase()}
                        </b>
                    </p>
                    <Divider style={{ margin: '10px 0px' }} />
                    {/* -------------------- HISTORICAL -------------------- */}
                    <Button
                        onClick={() => history.push(`${match.url}/subscriptions`)}
                        variant="contained"
                        style={{ textTransform: 'none' }}
                    >
                        Historique des abonnements
                    </Button>
                </>
            ) : (
                <>
                    <Divider style={{ margin: '10px 0px' }} />
                    <span>Aucun</span>
                </>
            )}
        </>
    );
};

export default LastSubscription;
