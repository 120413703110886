import React from 'react';

// Material-UI
import { Grid, MenuItem } from '@material-ui/core';

// Services
import { paymentMethods, refundStatuses } from 'services/constants';

// Components
import DatePicker from 'components/date-picker';
import InputText from 'components/input-text';
import SelectOutlined from 'components/select-outlined';
import FieldRequired from 'components/field-required';

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- REFUND FORM ----------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const RefundForm = ({ state, setState }) => {

    const filteredPaymentMethods = paymentMethods.filter(m => m.value !== 'cash' && m.value !== 'ticket');

    function onChangePaymentMethod(value) {
        if (value === 'transfer') {
            setState({ ...state, payment_method: value, status: 'recovered' });
        }
        else {
            setState({ ...state, payment_method: value });
        }
    }

    return (
        <>
            {/* -------------------- REFUND DATE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Date du remboursement
                </Grid>
                <Grid item xs={12} sm={8}>
                    <DatePicker
                        outlined
                        value={state.refund_date}
                        onChange={refund_date => setState({ ...state, refund_date })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- AMOUNT -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Montant (€)<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Montant (€)"
                        type="number"
                        value={state.amount}
                        onChange={e => setState({ ...state, amount: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- PAYMENT METHOD -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Mode de paiement<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectOutlined
                        value={state.payment_method}
                        onChange={e => onChangePaymentMethod(e.target.value)}
                    >
                        <MenuItem value={-1}>
                            <em>Mode de paiement</em>
                        </MenuItem>
                        {filteredPaymentMethods.map(paymentMethod => (
                            <MenuItem key={paymentMethod.value} value={paymentMethod.value}>
                                {paymentMethod.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- REFERENCE -------------------- */}
            {state.payment_method === 'check' && (
                <Grid container alignItems="center" style={{ marginTop: 5 }}>
                    <Grid item xs={12} sm={4}>
                        Numéro de chèque
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Numéro de chèque (7 chiffres)"
                            value={state.reference}
                            onChange={e => setState({ ...state, reference: e.target.value })}
                        />
                    </Grid>
                </Grid>
            )}
            {/* -------------------- STATUS -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Statut<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectOutlined
                        value={state.status}
                        onChange={e => setState({ ...state, status: e.target.value })}
                        disabled={state.payment_method === 'transfer'}
                    >
                        <MenuItem value={-1}>
                            <em>Statut</em>
                        </MenuItem>
                        {refundStatuses.map(status => (
                            <MenuItem key={status.value} value={status.value}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
        </>
    );
};

export default RefundForm;
