import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { billStatuses, paymentMethods } from 'services/constants';
import { getLabelStatus, getColorStatus, getFormattedCreatedAt } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------ BILL DETAILS ------------------------------------ \\
// -------------------------------------------------------------------------------------- \\

const BillDetails = ({ bill }) => {
    const match = useRouteMatch();
    const history = useHistory();

    return (
        <>
            {/* -------------------- PAYMENT METHOD -------------------- */}
            {bill.status === 'payment_waiting' && (
                <>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid>
                            Mode de paiement : <b>{paymentMethods.find(p => p.value === bill.payment_method).label}</b>
                        </Grid>
                        <Grid>
                            <Button
                                onClick={() => history.push(match.url + '/payment-method')}
                                variant="contained" color="primary"
                            >
                                Payer en ligne / Changer le mode de paiement
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider
                        style={{ margin: '24px 0px' }}
                    />
                </>
            )}
            {/* -------------------- USER -------------------- */}
            <Grid container justify="space-between">
                <Grid>
                    <Tooltip title="Ouvrir la fiche dans un nouvel onglet"
                        item={(
                            <b
                                onClick={() => window.open('/users/' + bill.user.id, '_blank')}
                                style={{ fontSize: 16, cursor: 'pointer', textDecoration: 'underline' }}
                            >
                                {bill.user?.fullName}
                            </b>
                        )}
                    />
                    {bill.user?.clientCode && (
                        <>
                            <br />
                            <b className="primary-color" stye={{ fontSize: 16 }}>{bill.user.clientCode}</b>
                        </>
                    )}
                    {bill.user?.email && (
                        <>
                            <br />
                            {bill.user.email}
                        </>
                    )}
                    {bill.user?.mobile_phone && (
                        <>
                            <br />
                            {bill.user.mobile_phone}
                        </>
                    )}
                    {bill.user?.phone && (
                        <>
                            <br />
                            {bill.user.phone}
                        </>
                    )}
                    {bill.user?.federatedAssociation && (
                        <>
                            <br />
                            {bill.user.federatedAssociation.name}
                        </>
                    )}
                </Grid>
                {/* -------------------- REFERENCE -------------------- */}
                <Grid style={{ textAlign: 'right', fontSize: 16 }}>
                    <b>BON DE COMMANDE</b>
                    <br />
                    <b className="secondary-color">{bill.reference}</b>
                    <br />
                    <span style={{ fontSize: 14 }}>
                        Créé le {getFormattedCreatedAt(bill.created_at)}
                    </span>
                </Grid>
            </Grid>
            {/* -------------------- ADDRESS -------------------- */}
            <Grid
                container justify="space-between"
                style={{ marginTop: 50 }}
            >
                <Grid>
                    <b>Facturé à</b>
                    {bill.company_name && (
                        <>
                            <br />
                            {bill.company_name}
                        </>
                    )}
                    <br />
                    {bill.address}
                    <br />
                    {bill.postal_code} {bill.city}, {bill.country.name}
                </Grid>
                <Grid style={{ textAlign: 'right' }}>
                    <b>Statut</b>
                    <br />
                    <b style={{ color: getColorStatus(billStatuses, bill.status) }}>
                        {getLabelStatus(billStatuses, bill.status)}
                    </b>
                </Grid>
            </Grid>
        </>
    );
};

export default BillDetails;
