import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

// Services
import { updateMembershipType } from 'services/requests';
import { amountToSend } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import InputText from 'components/input-text';
import Title from 'components/title';
import InputFile from 'components/input-file';

// Messages
import messages from 'messages.json';

// ------------------------------------------------------------------------------------------------ \\
// ------------------------------------ UPDATE MEMBERSHIP TYPE ------------------------------------ \\
// ------------------------------------------------------------------------------------------------ \\

const UpdateMembershipType = ({ membership_type, onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        name: membership_type.name || '',
        price: membership_type.price > 0 ? (membership_type.price / 100) : 0,
        definition: membership_type.definition || '',
        description: membership_type.description || '',
        demeter_discount: membership_type.demeter_discount > 0 ? (membership_type.demeter_discount / 100) : 0,
        biodyvin_discount: membership_type.biodyvin_discount > 0 ? (membership_type.biodyvin_discount / 100) : 0,
        available: membership_type.available?.toString() || '0',
        uploaded_file: null,
    });

    function onUpdate() {
        if (
            state.name === ''
            || state.price === ''
            || state.demeter_discount === ''
            || state.biodyvin_discount === ''
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-membership-image').files[0];

            if (file) {
                setState({ ...state, loading: true, uploaded_file: file });
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    }

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('name', state.name);
            formData.append('price', amountToSend(state.price).toString());
            formData.append('definition', state.definition);
            formData.append('description', state.description);
            formData.append('demeter_discount', amountToSend(state.demeter_discount).toString());
            formData.append('biodyvin_discount', amountToSend(state.biodyvin_discount).toString());
            formData.append('available', state.available.toString());

            if (state.uploaded_file) {
                formData.append('uploaded_file', state.uploaded_file);
            }

            updateMembershipType(membership_type.id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog
            open
            fullWidth
            fullScreen={!mediaMD}
        >
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Mettre à jour une adhésion
                        </p>
                        {/* ---------------------- AVAILABLE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={5}>
                                <Title required>
                                    Adhésion disponible à la vente
                                </Title>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <ToggleButtonGroup
                                    value={state.available}
                                    onChange={(e, value) => value ? setState(prevState => ({ ...prevState, available: value })) : undefined}
                                    exclusive
                                    size="small"
                                >
                                    <ToggleButton
                                        value="1"
                                        style={{ minWidth: 80, fontWeight: 600 }}
                                    >
                                        Oui
                                    </ToggleButton>
                                    <ToggleButton
                                        value="0"
                                        style={{ minWidth: 80, fontWeight: 600 }}
                                    >
                                        Non
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                        {/* ---------------------- NAME ---------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <Title required>
                                Nom de l'adhésion
                            </Title>
                            <InputText
                                label="Nom de l'adhésion"
                                type="text"
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                            />
                        </div>
                        {/* ---------------------- PRICE ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 12 }}
                        >
                            <Grid item xs={12} sm={5}>
                                <Title required>
                                    Prix TTC (€)
                                </Title>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <InputText
                                    label="Prix TTC (€)"
                                    type="number"
                                    value={state.price}
                                    onChange={e => setState({ ...state, price: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- DEMETER DISCOUNT ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 12 }}
                        >
                            <Grid item xs={12} sm={5}>
                                <Title required>
                                    Remise DEMETER (€)
                                </Title>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <InputText
                                    label="Remise DEMETER (€)"
                                    type="number"
                                    value={state.demeter_discount}
                                    onChange={e => setState({ ...state, demeter_discount: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- BIODYVIN DISCOUNT ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 12 }}
                        >
                            <Grid item xs={12} sm={5}>
                                <Title required>
                                    Remise BIODYVIN (€)
                                </Title>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <InputText
                                    label="Remise BIODYVIN (€)"
                                    type="number"
                                    value={state.biodyvin_discount}
                                    onChange={e => setState({ ...state, biodyvin_discount: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------- IMAGE ---------------------- */}
                        <Grid
                            container alignItems="center"
                            style={{ marginTop: 12 }}
                        >
                            <Grid item xs={12} sm={5}>
                                <Title>
                                    Image de l'adhésion
                                </Title>
                                <br />
                                <em>
                                    dimension carré (300*300)
                                </em>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <InputFile inputId="membership-image" />
                            </Grid>
                        </Grid>
                        {/* ---------------------- DEFINITION ---------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <Title>
                                Description courte
                            </Title>
                            <InputText
                                label="Description courte"
                                multiline
                                value={state.definition}
                                onChange={e => setState({ ...state, definition: e.target.value })}
                            />
                        </div>
                        {/* ---------------------- DESCRIPTION ---------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <Title>
                                Description longue
                            </Title>
                            <InputText
                                label="Description longue"
                                multiline
                                value={state.description}
                                onChange={e => setState({ ...state, description: e.target.value })}
                            />
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            Fermer
                        </Button>
                        <Button
                            onClick={onUpdate}
                            variant="contained"
                        >
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateMembershipType;