import React, { Fragment } from 'react';

// Material-UI
import { Grid, Divider } from '@material-ui/core';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- SUBDOMAINS ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

export default function Subdomains({ subdomains }) {
    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                Sous-domaines
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                <Grid container alignItems="center" className="list-headers" style={{ padding: 10 }}>
                    Nom
                </Grid>
                {/* -------------------- SUBDOMAIN LIST -------------------- */}
                <div className="main-container-item">
                    {subdomains.length > 0 ? (
                        subdomains.map(subdomain => (
                            <Fragment key={subdomain.id}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} style={{ padding: 10 }}>
                                        {subdomain.name}
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <Grid container style={{ padding: 10 }}>
                                Aucun
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
