import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Material-UI
import { Button, Grid } from '@material-ui/core';

// Services
import { createSubscription } from 'services/requests';
import { getStoredSubscription } from 'services/storage';

// Components
import { Register } from 'views/unauthenticated/register';
import { Login } from 'views/unauthenticated/login';

// ------------------------------------------------------------------------------------- \\
// ------------------------------------- CONTAINER ------------------------------------- \\
// ------------------------------------------------------------------------------------- \\

const PurchaseRegistrationConstainer = () => {
    const history = useHistory();

    return (
        <Grid
            container
            className="unauthenticated"
        >
            <Grid
                item xs={false} sm={1} md={2} lg={4}
            />
            <Grid item xs={12} sm={10} md={8} lg={4}>
                <Grid
                    container justify="center"
                    style={{ padding: 24 }}
                >
                    <Button
                        onClick={() => history.goBack()}
                        variant="contained"
                    >
                        Retour au formulaire
                    </Button>
                </Grid>
                <PurchaseRegistration />
            </Grid>
        </Grid>
    );
};

// ------------------------------------------------------------------------------------- \\
// -------------------------------- PURCHASE REGISTRATION ------------------------------ \\
// ------------------------------------------------------------------------------------- \\

const PurchaseRegistration = () => {
    const { search } = useLocation();
    const [menu, setMenu] = useState(new URLSearchParams(search).get('menu') || 'register');

    return (
        <Grid>
            {/* ---------------------- TITLE ---------------------- */}
            <Grid style={{ textAlign: 'center' }}>
                <b style={{ color: 'white', fontSize: 16 }}>
                    Créez un compte pour finaliser votre demande d'abonnement, d'adhésion ou de formation !
                </b>
            </Grid>
            {/* ---------------------- SWITCH ---------------------- */}
            <Grid container justify="center" alignItems="center">
                {menu === 'register' && (
                    <>
                        <b style={{ color: 'white', fontSize: 16 }}>
                            Vous avez déjà un compte :
                        </b>
                        <Button
                            onClick={() => setMenu('login')}
                            variant="contained"
                            style={{ marginLeft: 5 }}
                        >
                            Se connecter
                        </Button>
                    </>
                )}
                {menu === 'login' && (
                    <>
                        <b style={{ color: 'white', fontSize: 16 }}>
                            Vous n'avez pas encore de compte :
                        </b>
                        <Button
                            onClick={() => setMenu('register')}
                            variant="contained"
                            style={{ marginLeft: 5 }}
                        >
                            Créer un compte
                        </Button>
                    </>
                )}
            </Grid>
            {/* ---------------------- REGISTER / LOGIN ---------------------- */}
            {menu === 'register' && (
                <Register />
            )}
            {menu === 'login' && (
                <Login />
            )}
        </Grid>
    );
};

// ------------------------------------------------------------------------------------- \\
// --------------------------------- CREATE SUBSCRIPTION ------------------------------- \\
// ------------------------------------------------------------------------------------- \\

export const handleCreateSubscription = (enqueueSnackbar, history, user) => {
    const data = getStoredSubscription();
    data.user_id = user.id;

    if (data.formationSessionId) {

        history.push('/formations/' + data.formationSessionId + '/session/' + data.user_id);
        
        // createFormationRequest(data.formationSessionId, data).then(res => {
        //     if (res.status === 200) {
        //         enqueueSnackbar(res.data.message, { variant: 'success' });
        //         history.push('/payment-method/' + res.data.data.bill.id);
        //     }
        //     else {
        //         enqueueSnackbar(res, { variant: 'error' });
        //         history.push('/formations/' + data.formationSessionId);
        //     }
        // });
    }
    else {
        createSubscription(data).then(res => {
            if (res.status === 200) {
                enqueueSnackbar(res.data.message, { variant: 'success' });
                history.push('/payment-method/' + res.data.data.bill.id);
            }
            else {
                enqueueSnackbar(res, { variant: 'error' });
                if (data.subscriptionType === 'membership') {
                    history.push('/shop/' + data.membership_type_id + '/' + data.subscriptionType + '/' + user.id);
                }
                if (data.subscriptionType === 'subscription') {
                    history.push('/shop/' + data.subscription_type_id + '/' + data.subscriptionType + '/' + user.id);
                }
            }
        });
    }
};

export default PurchaseRegistrationConstainer;
