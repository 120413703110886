
/* * *
* Permet l'enregistrement de données dans le navigateur (tel que le jeton d'authentification, le rôle de l'utilsateur, ect...)
*
* Session storage = réinitialisé lorsque le navigateur ou l'onglet en question est fermé
* Locale storage = conserve les données même si le navigateur est fermé
*/

// --------------------------------------------------------------- \\
// --------------------------- TOKEN ----------------------------- \\
// --------------------------------------------------------------- \\

export const setToken = (data, remember) => {
    if (remember) {
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('user', JSON.stringify(data.user));
    }
    else {
        sessionStorage.setItem('token', data.access_token);
        sessionStorage.setItem('user', JSON.stringify(data.user));
    }
};

export const getToken = () => {
    return sessionStorage.getItem('token') || localStorage.getItem('token');
};

export const clearToken = () => {
    sessionStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
};

// --------------------------------------------------------------- \\
// ------------------------- AUTHENTICATION ---------------------- \\
// --------------------------------------------------------------- \\

export const isAuthenticated = () => {
    if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
        return true;
    }
    return false;
};

// --------------------------------------------------------------- \\
// ------------------------------ USER --------------------------- \\
// --------------------------------------------------------------- \\

export const getStoredUser = () => {
    let user = sessionStorage.getItem('user') || localStorage.getItem('user');
    if (user) {
        return JSON.parse(user);
    }
    return null;
};

// --------------------------------------------------------------- \\
// -------------------------- SUBSCRIPTION ----------------------- \\
// --------------------------------------------------------------- \\

export const setStoredSubscription = subscription => {
    sessionStorage.setItem('subscription', JSON.stringify(subscription));
};

export const getStoredSubscription = () => {
    let subscription = sessionStorage.getItem('subscription');
    if (subscription) {
        return JSON.parse(subscription)
    }
    return null;
};

// --------------------------------------------------------------- \\
// ---------------------------- BACK URL ------------------------- \\
// --------------------------------------------------------------- \\

export const setBackUrl = url => {
    localStorage.setItem('back_url', url);
};

export const getBackUrl = () => {
    return localStorage.getItem('back_url');
};

// --------------------------------------------------------------- \\
// ---------------------------- BILL ID -------------------------- \\
// --------------------------------------------------------------- \\

export const setBillId = bill_id => {
    localStorage.setItem('bill_id', bill_id);
};

export const getBillId = () => {
    return localStorage.getItem('bill_id');
};
