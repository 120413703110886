import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions, Radio, RadioGroup, FormControlLabel, Typography, CircularProgress } from '@material-ui/core';

// Services
import { updateNote } from 'services/requests';

// Components
import InputText from 'components/input-text';

// --------------------------------------------------------------------------------------- \\
// ------------------------------------- UPDATE NOTE ------------------------------------- \\
// --------------------------------------------------------------------------------------- \\

const UpdateNote = ({ onClose, refetch, user, note }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        content: note.content || '',
        global: note.global ? note.global.toString() : '1',
    });

    function onSave() {
        if (state.content === '') {
            enqueueSnackbar('Veuillez saisir le contenu de la note', { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateNote(note.id, {
                content: state.content,
                global: state.global,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog
            open
            fullWidth
        >
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Modifier la note {note.id}
                </p>
                <RadioGroup
                    value={state.global}
                    onChange={e => setState({ ...state, global: e.target.value })}
                >
                    <FormControlLabel
                        value="1"
                        control={<Radio style={{ padding: 6 }} />}
                        style={{ margin: 0 }}
                        label={
                            <Typography style={{ fontSize: 14 }}>
                                Note globale
                            </Typography>
                        }
                    />
                    {user.federatedAssociation && (
                        <FormControlLabel
                            value="0"
                            control={<Radio style={{ padding: 6 }} />}
                            style={{ margin: 0 }}
                            label={
                                <Typography style={{ fontSize: 14 }}>
                                    Note uniquement visible par {user.federatedAssociation.name}
                                </Typography>
                            }
                        />
                    )}
                </RadioGroup>
                <InputText
                    label="Note"
                    value={state.content}
                    onChange={e => setState({ ...state, content: e.target.value })}
                    multiline
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    disabled={state.loading}
                    variant="contained"
                >
                    Fermer
                </Button>
                <Button
                    onClick={onSave}
                    disabled={state.loading}
                    endIcon={state.loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                    variant="contained"
                >
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateNote;
