import React, { useState, useEffect } from 'react';

// Services
import { getFederatedAssociationList } from 'services/requests';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------- SELECT FEDERATED ASSOCIATIONS ------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectFederatedAssociations = ({ federatedAssociations, setFederatedAssociations }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getFederatedAssociationList().then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.federatedAssociations.map(a => ({ label: a.name, value: a.id })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <SelectMultiple
            value={federatedAssociations}
            onChange={value => setFederatedAssociations(value)}
            options={options}
            placeholder="Association fédérée"
        />
    );
};

export default SelectFederatedAssociations;
