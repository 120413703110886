import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getRefunds } from 'services/requests';
import { refundStatuses, paymentMethods } from 'services/constants';
import { getLabelStatus, getAmountTTC } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- REFUNDS EXPORT ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const RefundsExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getAllRefundsToExport(refunds, offset) {
        getRefunds({
            offset: offset,
            limit: data.limit,
            statuses: data.statuses ? data.statuses.map(s => s.value) : null,
            client_code: data.client_code,
            external_client_code: data.external_client_code,
            last_name: data.last_name,
            company_name: data.company_name,
            bill_reference: data.bill_reference,
            external_reference: data.external_reference,
            amount: data.amount,
            refund_methods: data.refund_methods ? data.refund_methods.map(m => m.value) : null,
            email: data.email,
            mobile_phone: data.mobile_phone,
            phone: data.phone,
            address: data.address,
            address_details: data.address_details,
            country_ids: data.countries ? data.countries.map(c => c.value) : null,
            postal_codes: data.postal_codes ? data.postal_codes.map(c => c.value) : null,
            federated_association_ids: data.federated_associations ? data.federated_associations.map(a => a.value) : null,
            payment_methods: data.payment_methods ? data.payment_methods.map(m => m.value) : null,
        }).then(res => {
            if (res.status === 200) {

                let res_refunds = res.data.data.refunds;
                refunds = refunds.concat(res_refunds);

                setState({
                    ...state,
                    percentage: Math.round((100 * refunds.length) / data.total_count) + '%',
                });

                if (res_refunds.length < data.limit) {
                    createExcelFile(refunds);
                }
                else {
                    getAllRefundsToExport(refunds, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(refunds) {
        if (refunds.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Remboursements');

            // Header
            worksheet.columns = [
                { header: 'Statut', key: 'status', width: 30 },
                { header: 'Code client', key: 'client_code', width: 30 },
                { header: 'Code client externe', key: 'external_client_code', width: 30 },
                { header: 'Nom', key: 'last_name', width: 30 },
                { header: 'Nom de l\'entreprise', key: 'company_name', width: 30 },
                { header: 'Réf. bon de commande', key: 'reference', width: 30 },
                { header: 'Réf. externe', key: 'external_reference', width: 30 },
                { header: 'Montant TTC', key: 'amount', width: 30 },
                { header: 'Mode de remboursement', key: 'refund_method', width: 30 },
                { header: 'Email compte', key: 'email', width: 30 },
                { header: 'Téléphone portable', key: 'mobile_phone', width: 30 },
                { header: 'Téléphone', key: 'phone', width: 30 },
                { header: 'Adresse (n° et voie)', key: 'address', width: 30 },
                { header: 'Adresse (complément)', key: 'address_details', width: 30 },
                { header: 'Pays', key: 'country', width: 30 },
                { header: 'Code postal', key: 'postal_code', width: 30 },
                { header: 'Association fédérée', key: 'federated_association', width: 30 },
                { header: 'Mode de paiement', key: 'payment_method', width: 30 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            refunds.map(refund => {
                return worksheet.addRow({
                    status: getLabelStatus(refundStatuses, refund.status),
                    client_code: (refund.bill && refund.bill.user) ? refund.bill.user.clientCode : '',
                    external_client_code: (refund.bill && refund.bill.user) ? refund.bill.user.external_client_code : '',
                    last_name: refund.bill ? refund.bill.last_name : '',
                    company_name: refund.bill ? refund.bill.company_name : '',
                    reference: refund.bill ? refund.bill.reference : '',
                    external_reference: refund.bill ? refund.bill.external_reference : '',
                    amount: getAmountTTC(refund.amount),
                    refund_method: getLabelStatus(paymentMethods, refund.payment_method),
                    email: (refund.bill && refund.bill.user) ? refund.bill.user.email : '',
                    mobile_phone: (refund.bill && refund.bill.user) ? refund.bill.user.mobile_phone : '',
                    phone: (refund.bill && refund.bill.user) ? refund.bill.user.phone : '',
                    address: refund.bill ? refund.bill.address : '',
                    address_details: refund.bill ? refund.bill.address_details : '',
                    country: (refund.bill && refund.bill.country) ? refund.bill.country.name : '',
                    postal_code: refund.bill ? refund.bill.postal_code : '',
                    federated_association: refund.federatedAssociation ? refund.federatedAssociation.name : '',
                    payment_method: getLabelStatus(paymentMethods, (refund.bill ? refund.bill.payment_method : null)),
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'remboursements.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllRefundsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default RefundsExport;
