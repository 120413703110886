import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

// Services
import { updateTransfer } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- UPADTE TRANSFER --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UpdateTransfer = ({ onClose, refetch, transfer }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        money_is_transferred: transfer.money_is_transferred ?  transfer.money_is_transferred.toString() : '0',
    });

    useEffect(() => {
        if (state.loading) {
            updateTransfer(transfer.id, {
                money_is_transferred: state.money_is_transferred,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20, textAlign: 'center' }}>
                        <p className="dialog-title">
                            Modifier le statut du transfert
                        </p>
                        <ToggleButtonGroup
                            value={state.money_is_transferred}
                            onChange={(e, value) => setState({ ...state, money_is_transferred: (value == null ? state.money_is_transferred : value) })}
                            exclusive
                            size="small"
                            className="toggle-button"
                        >
                            <ToggleButton value="0" style={{ width: 140 }}>
                                Non transféré
                            </ToggleButton>
                            <ToggleButton value="1" style={{ width: 140 }}>
                                Transféré
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Fermer
                        </Button>
                        <Button onClick={() => setState({ ...state, loading: true })} variant="contained">
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateTransfer;
