import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getPayments } from 'services/requests';
import { paymentStatuses, paymentMethods } from 'services/constants';
import { getLabelStatus, getAmountTTC } from 'services/helpers';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- PAYMENTS EXPORT --------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const PaymentsExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getStringFromArray(array) {
        let result = '';
        array.forEach((item, index) => {
            if (index === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });
        return result;
    }

    function getAllPaymentsToExport(payments, offset) {
        getPayments({
            offset: offset,
            limit: data.limit,
            statuses: data.statuses ? data.statuses.map(s => s.value) : null,
            client_code: data.client_code,
            external_client_code: data.external_client_code,
            last_name: data.last_name,
            company_name: data.company_name,
            bill_reference: data.bill_reference,
            external_reference: data.external_reference,
            amount: data.amount,
            payment_methods: data.payment_methods ? data.payment_methods.map(m => m.value) : null,
            check_reference: data.check_reference,
            first_name: data.first_name,
            email: data.email,
            mobile_phone: data.mobile_phone,
            phone: data.phone,
            address: data.address,
            address_details: data.address_details,
            country_ids: data.countries ? data.countries.map(c => c.value) : null,
            postal_codes: data.postal_codes ? data.postal_codes.map(c => c.value) : null,
            federated_association_ids: data.federated_associations ? data.federated_associations.map(a => a.value) : null,
        }).then(res => {
            if (res.status === 200) {

                let res_payments = res.data.data.payments;
                payments = payments.concat(res_payments);

                setState({
                    ...state,
                    percentage: Math.round((100 * payments.length) / data.total_count) + '%',
                });

                if (res_payments.length < data.limit) {
                    createExcelFile(payments);
                }
                else {
                    getAllPaymentsToExport(payments, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(payments) {
        if (payments.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Paiements');

            // Header
            let columns = [];
            if (data.enabled_status) {
                columns.push({ header: 'Statut', key: 'status', width: 30 });
            }
            if (data.enabled_client_code) {
                columns.push({ header: 'Code client', key: 'client_code', width: 30 });
            }
            if (data.enabled_external_client_code) {
                columns.push({ header: 'Code client externe', key: 'external_client_code', width: 30 });
            }
            if (data.enabled_last_name) {
                columns.push({ header: 'Nom', key: 'last_name', width: 30 });
            }
            if (data.enabled_company_name) {
                columns.push({ header: 'Nom de l\'entreprise', key: 'company_name', width: 30 });
            }
            if (data.enabled_bill_reference) {
                columns.push({ header: 'Réf. bon de commande', key: 'reference', width: 30 });
            }
            if (data.enabled_external_reference) {
                columns.push({ header: 'Réf. externe', key: 'external_reference', width: 30 });
            }
            if (data.enabled_amount) {
                columns.push({ header: 'Montant TTC', key: 'amount', width: 30 });
            }
            if (data.enabled_payment_method) {
                columns.push({ header: 'Mode de paiement', key: 'payment_method', width: 30 });
            }
            if (data.enabled_check_reference) {
                columns.push({ header: 'Réf. chèque', key: 'check_number', width: 30 });
            }
            if (data.enabled_first_name) {
                columns.push({ header: 'Prénom', key: 'first_name', width: 30 });
            }
            if (data.enabled_email) {
                columns.push({ header: 'Email compte', key: 'email', width: 30 });
            }
            if (data.enabled_mobile_phone) {
                columns.push({ header: 'Téléphone portable', key: 'mobile_phone', width: 30 });
            }
            if (data.enabled_phone) {
                columns.push({ header: 'Téléphone', key: 'phone', width: 30 });
            }
            if (data.enabled_address) {
                columns.push({ header: 'Adresse (n° et voie)', key: 'address', width: 30 });
            }
            if (data.enabled_address_details) {
                columns.push({ header: 'Adresse (complément)', key: 'address_details', width: 30 });
            }
            if (data.enabled_country) {
                columns.push({ header: 'Pays', key: 'country', width: 30 });
            }
            if (data.enabled_postal_code) {
                columns.push({ header: 'Code postal', key: 'postal_code', width: 30 });
            }
            if (data.enabled_federated_association) {
                columns.push({ header: 'Association fédérée', key: 'federated_association', width: 30 });
            }
            worksheet.columns = columns;
            worksheet.getRow(1).font = { bold: true };

            // Rows
            payments.map(payment => {
                return worksheet.addRow({
                    status: getLabelStatus(paymentStatuses, payment.status),
                    client_code: payment.client_codes || '',
                    external_client_code: payment.external_client_codes || '',
                    last_name: payment.last_names || '',
                    company_name: payment.company_names || '',
                    reference: payment.references || '',
                    external_reference: payment.external_references || '',
                    amount: getAmountTTC(payment.amount),
                    payment_method: getLabelStatus(paymentMethods, payment.payment_method),
                    check_number: payment.check_number || '',
                    first_name: payment.first_names || '',
                    email: payment.emails || '',
                    mobile_phone: payment.mobile_phones || '',
                    phone: payment.phones || '',
                    address: payment.addresses || '',
                    address_details: payment.addresses_details || '',
                    country: payment.countries ? getStringFromArray(payment.countries) : '',
                    postal_code: payment.postal_codes || '',
                    federated_association: payment.federatedAssociation ? payment.federatedAssociation.name : '',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'paiements.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllPaymentsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default PaymentsExport;
