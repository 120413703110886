import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 180;

// -------------------- HEADER -------------------- \\
const AddressHeader = ({ label }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {label ? label : 'Adresse (n° et voie)'}
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const AddressFilter = ({ label, state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label={label ? label : 'Adresse (n° et voie)'}
                value={state.address}
                onChange={e => setState({ ...state, address: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const AddressValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { AddressHeader, AddressFilter, AddressValue };
