import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getManagementSessions } from 'services/requests';
import { getFormattedDate, getLabelStatus } from 'services/helpers';
import { formationSessionStatuses } from 'services/constants';

// Components
import Tooltip from 'components/tooltip';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------- FORMATION SESSIONS EXPORT ---------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSessionsExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getDepartments(departments) {
        let result = '';
        departments.forEach((item, index) => {
            if (index === 0) {
                result += item.name + ' (' + item.number + ')';
            }
            else {
                result += ', ' + item.name + ' (' + item.number + ')';
            }
        });
        return result;
    }

    function getTrainers(trainers) {
        let result = '';
        trainers.forEach((item, index) => {
            if (index === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });
        return result;
    }

    function getStringFromArray(array) {
        let result = '';
        array.forEach((item, index) => {
            if (index === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });
        return result;
    }

    function getAllFormationSessionsToExport(formation_sessions, offset) {
        getManagementSessions({
            offset: offset,
            limit: data.limit,
            order_by: data.order_by,
            order: data.order,
            period: data.period,
            statuses: data.statuses ? data.statuses.map(s => s.value) : null,
            titles: data.titles ? data.titles.map(f => f.value) : null,
            names: data.names ? data.names.map(n => n.value) : null,
            french_department_ids: data.french_department_ids ? data.french_department_ids.map(d => d.value) : null,
            category_ids: data.categories ? data.categories.map(c => c.value) : null,
            manager_ids: data.managers ? data.managers.map(m => m.value) : null,
            trainer_ids: data.trainers ? data.trainers.map(t => t.value) : null,
            start_date: data.start_date,
            end_date: data.end_date,
            place_name: data.place_name,
            end_of_registration: data.end_of_registration,
            vivea_reference: data.vivea_reference,
            nb_registered: data.nb_registered,
            nb_registered_comparator: data.nb_registered_comparator,
            nb_requested: data.nb_requested,
            nb_requested_comparator: data.nb_requested_comparator,
            animator_ids: data.animators ? data.animators.map(a => a.value) : null,
            seasons: data.seasons ? data.seasons.map(s => s.value) : null,
        }).then(res => {
            if (res.status === 200) {

                let res_formation_sessions = res.data.data.formationSessions;
                formation_sessions = formation_sessions.concat(res_formation_sessions);

                setState({
                    ...state,
                    percentage: Math.round((100 * formation_sessions.length) / data.total_count) + '%',
                });

                if (res_formation_sessions.length < data.limit) {
                    createExcelFile(formation_sessions);
                }
                else {
                    getAllFormationSessionsToExport(formation_sessions, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(formation_sessions) {
        if (formation_sessions.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Formations');

            // Headers
            let columns = [];
            if (data.enabled_status) {
                columns.push({ header: 'Statut', key: 'status', width: 30 });
            }
            if (data.enabled_title) {
                columns.push({ header: 'Modèle de formation', key: 'title', width: 30 });
            }
            if (data.enabled_name) {
                columns.push({ header: 'Nom de la formation', key: 'name', width: 30 });
            }
            if (data.enabled_french_department) {
                columns.push({ header: 'Département', key: 'department', width: 30 });
            }
            if (data.enabled_category) {
                columns.push({ header: 'Catégorie', key: 'category', width: 30 });
            }
            if (data.enabled_manager) {
                columns.push({ header: 'Responsable', key: 'manager', width: 30 });
            }
            if (data.enabled_trainer) {
                columns.push({ header: 'Formateur', key: 'trainer', width: 30 });
            }
            if (data.enabled_start_date) {
                columns.push({ header: 'Date première session', key: 'start_date', width: 30 });
            }
            if (data.enabled_end_date) {
                columns.push({ header: 'Date dernière session', key: 'end_date', width: 30 });
            }
            if (data.enabled_place_name) {
                columns.push({ header: 'Lieu de formation', key: 'place_name', width: 30 });
            }
            if (data.enabled_end_of_registration) {
                columns.push({ header: 'Date fin d\'inscription', key: 'end_of_registration', width: 30 });
            }
            if (data.enabled_vivea_reference) {
                columns.push({ header: 'Référence VIVEA', key: 'vivea_reference', width: 30 });
            }
            if (data.enabled_nb_registered) {
                columns.push({ header: 'Nombre d\'inscrits confirmés', key: 'nb_registered', width: 30 });
            }
            if (data.enabled_nb_requested) {
                columns.push({ header: 'Nombre de pré-inscrits', key: 'nb_requested', width: 30 });
            }
            if (data.enabled_animator) {
                columns.push({ header: 'Animateur', key: 'animator', width: 30 });
            }
            if (data.enabled_season) {
                columns.push({ header: 'Saison', key: 'season', width: 30 });
            }
            worksheet.columns = columns;
            worksheet.getRow(1).font = { bold: true };

            // Rows
            formation_sessions.map(session => {
                return worksheet.addRow({
                    status: getLabelStatus(formationSessionStatuses, session.status),
                    title: session.formation ? session.formation.title : '',
                    name: (session.name && session.number) ? session.name + ' (#' + session.number + ')' : '',
                    department: session.frenchDepartments ? getDepartments(session.frenchDepartments) : '',
                    category: (session.formation && session.formation.categories) ? getStringFromArray(session.formation.categories) : '',
                    manager: session.manager ? session.manager.fullName : '',
                    trainer: session.trainers ? getTrainers(session.trainers) : '',
                    start_date: session.begin ? getFormattedDate(session.begin) : '',
                    end_date: session.end ? getFormattedDate(session.end) : '',
                    place_name: session.placeName || '',
                    end_of_registration: session.end_of_registration ? getFormattedDate(session.end_of_registration) : '',
                    vivea_reference: session.vivea_reference || '',
                    nb_registered: session.countRegistered || '',
                    nb_requested: session.countRequested || '',
                    animator: session.animator ? session.animator.fullName : '',
                    season: session.season || '',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'formations.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllFormationSessionsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default FormationSessionsExport;
