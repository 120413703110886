import React, { useState, useEffect, useRef } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Material-UI
import { Divider, Grid, MenuItem, IconButton, Button, DialogContent, DialogActions, Dialog } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

// Services
import { getBills, getBillIdsFromReferences } from 'services/requests';
import { paymentMethods, paymentStatuses } from 'services/constants';

// Components
import Spinner from 'components/spinner';
import Tooltip from 'components/tooltip';
import DatePicker from 'components/date-picker';
import InputText from 'components/input-text';
import SelectOutlined from 'components/select-outlined';
import FieldRequired from 'components/field-required';
import Autocomplete from 'components/autocomplete';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- PAYMENT FORM ----------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const PaymentForm = ({ state, setState }) => {
    const filteredPaymentMethods = paymentMethods.filter(m => m.value !== 'cb');
    const filteredPaymentStatuses = paymentStatuses.filter(s => s.value !== 'recovery_failed' && s.value !== 'cancelled');

    function onRemoveBill(value) {
        const bills = state.bills;
        const index = bills.findIndex(b => b.value === value);
        bills.splice(index, 1);
        setState({ ...state, bills });
    }

    function onChangePaymentMethod(value) {
        if (value === 'transfer') {
            setState({ ...state, payment_method: value, status: 'recovered' });
        }
        else {
            setState({ ...state, payment_method: value });
        }
    }
        
    return (
        <>
            {/* -------------------- PAYMENT DATE -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    Date du paiement
                </Grid>
                <Grid item xs={12} sm={8}>
                    <DatePicker
                        outlined
                        value={state.payment_date}
                        onChange={payment_date => setState({ ...state, payment_date })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- AMOUNT -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    Montant (€)<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Montant (€)"
                        type="number"
                        value={state.amount}
                        onChange={e => setState({ ...state, amount: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- PAYMENT METHOD -------------------- */}
            <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                <Grid item xs={12} sm={4}>
                    Mode de paiement<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectOutlined
                        value={state.payment_method}
                        onChange={e => onChangePaymentMethod(e.target.value)}
                    >
                        <MenuItem value={-1}>
                            <em>Mode de paiement</em>
                        </MenuItem>
                        {filteredPaymentMethods.map(paymentMethod => (
                            <MenuItem key={paymentMethod.value} value={paymentMethod.value}>
                                {paymentMethod.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- REFERENCE -------------------- */}
            {state.payment_method === 'check' && (
                <Grid container alignItems="center" style={{ marginBottom: 5 }}>
                    <Grid item xs={12} sm={4}>
                        Numéro de chèque
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Numéro de chèque (7 chiffres)"
                            value={state.reference}
                            onChange={e => setState({ ...state, reference: e.target.value })}
                        />
                    </Grid>
                </Grid>
            )}
            {/* -------------------- STATUS -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Statut<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectOutlined
                        value={state.status}
                        onChange={e => setState({ ...state, status: e.target.value })}
                        disabled={state.payment_method === 'transfer'}
                    >
                        <MenuItem value={-1}>
                            <em>Statut</em>
                        </MenuItem>
                        {filteredPaymentStatuses.map(status => (
                            <MenuItem key={status.value} value={status.value}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            <Divider style={{ margin: '15px 0px' }} />
            {/* -------------------- BILLS -------------------- */}
            <Grid container justify="flex-end" style={{ marginBottom: 10 }}>
                <Button
                    onClick={() => setState({ ...state, openImport: true })}
                    variant="contained"
                    style={{ textTransform: 'none' }}
                >
                    Importer depuis une liste de référence
                </Button>
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Bon(s) de commande<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectBills
                        bills={state.bills}
                        setBills={bills => setState({ ...state, bills })}
                    />
                </Grid>
            </Grid>
            {state.bills.map(bill => (
                <Grid
                    key={bill.value}
                    container alignItems="center" justify="space-between"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: 4, padding: '0px 10px', marginTop: 10 }}
                >
                    {bill.label}
                    <Tooltip title="Supprimer"
                        item={(
                            <IconButton onClick={() => onRemoveBill(bill.value)} style={{ padding: 6 }}>
                                <DeleteIcon />
                            </IconButton>
                        )}
                    />
                </Grid>
            ))}
            {/* -------------------- IMPORT BILLS -------------------- */}
            {state.openImport && (
                <ImportBills
                    onClose={() => setState({ ...state, openImport: false })}
                    bills={state.bills}
                    setBills={bills => setState({ ...state, openImport: false, bills })}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SELECT BILLS ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SelectBills = ({ bills, setBills }) => {

    const [state, setState] = useState({
        loading: false,
        options: [],
        bill: null,
        searchRef: undefined,  
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    function onChange(value) {
        const updatedBills = bills;
        if (value && bills.findIndex(b => b.value === value.value) === -1)  {
            updatedBills.push(value);
        }
        setState({ ...state, bill: value });
        setBills(updatedBills);
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 3) {
                getBills({
                    reference: state.searchRef,
                    offset: 0,
                    limit: 20,
                }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.data.bills.map(b => ({ label: b.reference, value: b.id })),
                        });
                    }
                    else {
                        setState({ ...state, loading: false });
                    }
                });
            } 
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Autocomplete
            value={state.bill}
            options={state.options}
            loading={state.loading}
            onChange={(e, value) => onChange(value)}
            onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
            placeholder="Chercher un bon de commande (référence)..."
        />
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------------- IMPORT BILLS ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const ImportBills = ({ onClose, bills, setBills }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        references: '',
    });

    useEffect(() => {
        if (state.loading) {
            getBillIdsFromReferences(state.references.split('\n').map(item => item.replace(/\s/g, '')))
            .then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar('Bons de commande ajoutés', { variant: 'success' });
                    let updatedBills = bills;
                    res.data.data.bills.forEach(bill => {
                        if (bills.findIndex(b => b.value === bill.id) === -1) {
                            updatedBills.push({ value: bill.id, label: bill.reference });
                        }
                    });
                    setBills(updatedBills);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Importer des bons de commande depuis une liste de référence
                        </p>
                        <p>
                            Veuillez copier-coller les réferences l'une en dessous de l'autre :<br />
                            - REFERENCE0001<br />
                            - REFERENCE0002<br />
                            ...
                        </p>
                        <InputText
                            value={state.references}
                            onChange={e => setState({ ...state, references: e.target.value })}
                            multiline
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={() => setState({ ...state, loading: true })} variant="contained">
                            Valider
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
};

export default PaymentForm;
