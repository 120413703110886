import React from 'react';

// Material-UI
import { Grid, Avatar } from '@material-ui/core';
import { AccountCircle as ProfileIcon } from '@material-ui/icons';

// Services
import { fileUrl } from 'services/axios-config';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- PROFILE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Profile = ({ state }) => {
    return (
        <Grid container alignItems="center" style={{ flexDirection: 'column' }}>
            {/* -------------------- PHOTO -------------------- */}
            {state.user?.photo_url ? (
                <Avatar className="avatar" alt="user-photo" src={fileUrl + state.user.photo_url} />
            ) : (
                <ProfileIcon className="avatar" style={{ color: 'lightgray' }} />
            )}
            {/* -------------------- NAME -------------------- */}
            <div style={{ fontSize: 16 }}>
                {state.user?.fullName}
            </div>
            {/* -------------------- CLIENT CODE -------------------- */}          
            {state.user?.clientCode && (
                <div>
                    <b>VOTRE CODE CLIENT</b>&nbsp;:&nbsp;
                    <b className="primary-color" style={{ fontSize: 16 }}>
                        {state.user.clientCode}
                    </b>
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}          
            {state.user?.external_client_code && (
                <div>
                    <b>VOTRE CODE CLIENT EXTERNE</b>&nbsp;:&nbsp;
                    <b className="secondary-color" style={{ fontSize: 16 }}>
                        {state.user.external_client_code}
                    </b>
                </div>
            )}
        </Grid>
    );
};

export default Profile;
