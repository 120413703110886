import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';
import { getUserBills } from 'services/requests';

// Assets
import Spinner from 'components/spinner';
import Error from 'components/error';
import useMediaQueries from 'services/media';
import { billStatuses, paymentMethods } from 'services/constants';
import { getLabelStatus, getColorStatus, getAmountTTC } from 'services/helpers';

// Components
import Bill from 'views/common/bill';

/* * *
* Historique des bons de commande
*/

// ---------------------------------------------------------------------------------------------- \\
// ---------------------------------------- BILLS ROUTER ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const BillsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <BillsHistory />
            </Route>
            <Route path={`${match.path}/:billId`}>
                <Bill />
            </Route>
        </Switch>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- BILLS HISTORY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const BillsHistory = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const storedUser = getStoredUser();
    const USER_ID = storedUser ? storedUser.id : null;
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        bills: [],
    });

    useEffect(() => {
        if (state.loading) {
            getUserBills(USER_ID).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, bills: res.data.data.bills });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/dashboard')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Historique des bons de commande
                </Grid>
            </Grid>
            <Grid style={{ padding: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Statut
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Montant
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Référence
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Mode de paiement
                        </Grid>
                        <Grid item xs={4} style={{ padding: 10 }}>
                            Détails
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- LIST -------------------- */}
                {state.bills.length > 0 ? (
                    state.bills.map(bill => (
                        <Fragment key={bill.id}>
                            <Grid
                                onClick={() => history.push(`${match.url}/${bill.id}`)}
                                container alignItems="center"
                                className="simple-list"
                            >
                                <Grid item xs={12} md={2} container alignItems="center" style={{ padding: 10 }}>
                                    <b style={{ color: getColorStatus(billStatuses, bill.status) }}>
                                        {getLabelStatus(billStatuses, bill.status)}
                                    </b>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    <b>{getAmountTTC(bill.total_amount)}</b>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {bill.reference}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                    {getLabelStatus(paymentMethods, bill.payment_method)}
                                </Grid>
                                <Grid item xs={12} md={4} style={{ padding: 10 }}>
                                    {bill.billItems.map((billItem, index) => {
                                        if (index === 0) return billItem.name;
                                        else return ', ' + billItem.name;
                                    })}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid container style={{ padding: 10 }}>
                            Aucun
                        </Grid>
                        <Divider />
                    </>
                )}
            </Grid>
        </>
    );
};

export default BillsRouter;
