import React, { useState, useEffect } from 'react';

// Services
import { getMembershipTypes } from 'services/requests';

// Components
import SelectMultiple from 'components/select-multiple';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------- SELECT MEMBERSHIP TYPES ---------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectMembershipTypes = ({ membershipTypes, setMembershipTypes }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getMembershipTypes().then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.membershipTypes.map(a => ({ label: a.name, value: a.id })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <SelectMultiple
            value={membershipTypes}
            onChange={value => setMembershipTypes(value)}
            options={options}
            placeholder="Type d'adhésion"
        />
    );
};

export default SelectMembershipTypes;
