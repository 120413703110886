import React from 'react';

// Material-UI
import { KeyboardDatePicker } from '@material-ui/pickers';

/* * *
* Composant permettant de séléctionner une date
*/

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------------- DATE PICKER ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const DatePicker = ({ value, onChange, outlined, fullWidth }) => {
    return (
        <KeyboardDatePicker
            placeholder="Date"
            value={value}
            onChange={onChange}
            fullWidth={fullWidth}
            clearable
            inputVariant={outlined ? 'outlined' : 'standard'}
            size="small"
            format={'DD/MM/YYYY'}
            clearLabel="Effacer"
            cancelLabel="Annuler"
            InputProps={{ style: { fontSize: 14 } }}
        />
    );
};

// Propriétés par défaut
DatePicker.defaultProps = {
    fullWidth: true,
};

export default DatePicker;
