import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getUser } from 'services/requests';
import { getUsername } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Views
import FormationRequests from 'views/formations/upcoming-sessions';

// --------------------------------------------------------------------------------------- \\
// -------------------------------- USER FORMAITON SESSIONS ------------------------------ \\
// --------------------------------------------------------------------------------------- \\

const UserFormationSessions = () => {
    const history = useHistory();
    const { userId } = useParams();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: null,
    });

    useEffect(() => {
        if (state.loading) {
            getUser(userId).then(res => {
                let user = res?.data?.data?.user;
                if (res?.status === 200 && user) {
                    setState({ ...state, loading: false, user });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid
                container alignItems="center"
                className="subheader"
            >
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push('/users/' + state.user?.id)}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    Choisir une formation pour {getUsername(state.user)}
                </Grid>
            </Grid>
            {/* -------------------- FORMATION REQUESTS -------------------- */}
            <FormationRequests />
        </>
    );
};

export default UserFormationSessions;
