import React from 'react';
import Select from 'components/react-select';
import { billStates } from 'services/constants';

// Column Width
let columnWidth = 140;

// -------------------- HEADER -------------------- \\
const BillStateHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            État BC
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const BillStateFilter = ({ state, onSelectSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <Select
                placeholder="État BC"
                value={state.bill_state ? billStates.find(s => s.value === state.bill_state) : null}
                onChange={bill_state => onSelectSearch('bill_state', bill_state ? bill_state.value : null)}
                options={billStates}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const BillStateValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { BillStateHeader, BillStateFilter, BillStateValue };
