import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';
import InputPhone from 'components/input-phone';

// Views
import AddressForm from 'views/common/address-form';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ TRAINING PLACE FORM -------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const TrainingPlaceForm = ({ state, setState }) => {
    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Intitulé<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Intitulé"
                        value={state.name}
                        onChange={e => setState({ ...state, name: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- ADDRESS -------------------- */}
            <AddressForm
                state={state}
                setState={setState}
            />
            {/* -------------------- LATITUDE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Latitude
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Latitude"
                        value={state.latitude}
                        onChange={e => setState({ ...state, latitude: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- LONGITUDE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Longitude
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Longitude"
                        value={state.longitude}
                        onChange={e => setState({ ...state, longitude: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- MOBILE PHONE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Téléphone portable
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputPhone
                        label="Téléphone portable"
                        value={state.mobile_phone}
                        setValue={mobile_phone => setState({ ...state, mobile_phone })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- PHONE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Téléphone fixe
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputPhone
                        label="Téléphone fixe"
                        value={state.phone}
                        setValue={phone => setState({ ...state, phone })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- OTHER PHONE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Autre téléphone
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputPhone
                        label="Autre téléphone"
                        value={state.other_phone}
                        setValue={other_phone => setState({ ...state, other_phone })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- EMAIL -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Email
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- OTHER EMAIL -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Autre email
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Autre email"
                        value={state.other_email}
                        onChange={e => setState({ ...state, other_email: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- CONTACT -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={4}>
                    Contact du lieu
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Contact du lieu"
                        value={state.contact}
                        onChange={e => setState({ ...state, contact: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default TrainingPlaceForm;
