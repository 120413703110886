import React, { Fragment } from 'react';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { userCanCreatePayment, userCanUpdatePayment } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import { paymentMethods, paymentStatuses } from 'services/constants';
import { getFormattedDate, getLabelStatus, getColorStatus, getAmountTTC } from 'services/helpers';

// Views
import CreatePayment from 'views/management/payments/payments-create';
import UpdatePayment from 'views/management/payments/payments-update';
import RecoverPayment from 'views/management/payments/recover-payment';
import DeletePayment from 'views/management/payments/payment-delete';
import CancelPayment from './cancel-payment';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------ BILL PAYMENTS ----------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const BillPayments = ({ state, setState }) => {
    const storedUser = getStoredUser();

    function userCanUpdate(payment_method) {
        if ((userCanUpdatePayment() && payment_method !== 'cb')
            || (storedUser?.role?.id === 1 && payment_method === 'cb')
        ) {
            return true;
        }
        return false;
    }

    function onClickPayment(event, open, payment) {
        event.stopPropagation();
        setState({ ...state, [open]: true, payment });
    }

    const onUpdate = payment => {
        if (storedUser?.role?.id === 1 && payment.payment_method === 'cb') {
            setState({ ...state, openDeletePayment: true, payment });
        }
        else {
            setState({ ...state, openUpdatePayment: true, payment });
        }
    };

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container alignItems="center" justify="space-between"
                style={{ marginTop: 50 }}
            >
                <b>Paiements</b>
                {userCanCreatePayment() && (
                    <Button
                        onClick={() => setState({ ...state, openCreatePayment: true })}
                        variant="contained"
                        style={{ textTransform: 'none' }}
                    >
                        Ajouter un paiement
                    </Button>
                )}
            </Grid>
            {/* -------------------- HEADERS -------------------- */}
            <Grid
                container className="list-headers"
                style={{ marginTop: 10 }}
            >
                <Grid item xs={2} style={{ padding: 10 }}>
                    Statut
                </Grid>
                <Grid item xs={2} style={{ padding: 10 }}>
                    Date - Montant
                </Grid>
                <Grid item xs={2} style={{ padding: 10 }}>
                    Référence
                </Grid>
                <Grid item xs={2} style={{ padding: 10 }}>
                    Mode de paiement
                </Grid>
                <Grid item xs={2} style={{ padding: 10 }}>
                    Créateur
                </Grid>
                <Grid item xs={2} style={{ padding: 10 }}>
                    Association fédérée
                </Grid>
            </Grid>
            {/* -------------------- LIST -------------------- */}
            {state.bill.payments.length > 0 ? (
                state.bill.payments.map(payment => (
                    <Fragment key={payment.id}>
                        <Grid
                            onClick={userCanUpdate(payment.payment_method) ? () => onUpdate(payment) : null}
                            className={userCanUpdate(payment.payment_method) ? 'simple-list' : null}
                            container alignItems="center"
                        >
                            <Grid item xs={2} style={{ padding: 10 }}>
                                <b style={{ color: getColorStatus(paymentStatuses, payment.status) }}>
                                    {getLabelStatus(paymentStatuses, payment.status)}
                                </b>
                                {(userCanUpdatePayment() && payment.status === 'received' && payment.payment_method === 'cb') && (
                                    <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                                        <button
                                            onClick={e => onClickPayment(e, 'openRecoverPayment', payment)}
                                            className="button-group button-group-left"
                                        >
                                            Recouvrer
                                        </button>
                                        <button
                                            onClick={e => onClickPayment(e, 'openCancelPayment', payment)}
                                            className="button-group button-group-right"
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                {getFormattedDate(payment?.payment_date)} - {getAmountTTC(payment.amount)}
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10, overflowWrap: 'break-word' }}>
                                {payment.reference}
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                {getLabelStatus(paymentMethods, payment.payment_method)}
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                {payment.creator?.fullName}
                            </Grid>
                            <Grid item xs={2} style={{ padding: 10 }}>
                                {payment.federatedAssociation ? payment.federatedAssociation.name : ''}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- TOTAL -------------------- */}
            <Grid
                container justify="flex-end"
                style={{ marginTop: 10 }}
            >
                <b style={{ marginRight: 10 }}>
                    TOTAL PAYÉ : {getAmountTTC(state.total_paid)}
                </b>
                <b className={(state.left_to_pay - state.total_paid > 0) ? 'secondary-color' : null}>
                    RESTANT DÛ : {getAmountTTC(state.left_to_pay)}
                </b>
            </Grid>
            {/* -------------------- CREATE PAYMENT -------------------- */}
            {state.openCreatePayment && (
                <CreatePayment
                    onClose={() => setState({ ...state, openCreatePayment: false })}
                    refetch={() => setState({ ...state, openCreatePayment: false, loading: true })}
                    bill={state.bill}
                />
            )}
            {/* -------------------- UPDATE PAYMENT -------------------- */}
            {state.openUpdatePayment && (
                <UpdatePayment
                    onClose={() => setState({ ...state, openUpdatePayment: false })}
                    refetch={() => setState({ ...state, openUpdatePayment: false, loading: true })}
                    payment={state.payment}
                    bill={state.bill}
                />
            )}
            {/* -------------------- RECOVER PAYMENT -------------------- */}
            {state.openRecoverPayment && (
                <RecoverPayment
                    onClose={() => setState({ ...state, openRecoverPayment: false })}
                    refetch={() => setState({ ...state, openRecoverPayment: false, loading: true })}
                    payment={state.payment}
                />
            )}
            {/* -------------------- CANCELLED PAYMENT -------------------- */}
            {state.openCancelPayment && (
                <CancelPayment
                    onClose={() => setState({ ...state, openCancelPayment: false })}
                    refetch={() => setState({ ...state, openCancelPayment: false, loading: true })}
                    payment={state.payment}
                />
            )}
            {/* -------------------- DELETE PAYMENT -------------------- */}
            {state.openDeletePayment && (
                <DeletePayment
                    onClose={() => setState({ ...state, openDeletePayment: false })}
                    refetch={() => setState({ ...state, openDeletePayment: false, loading: true })}
                    payment={state.payment}
                />
            )}
        </>
    );
};

export default BillPayments;
