import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Dialog, DialogContent, DialogActions, Divider, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

// Services
import { deleteDomain, createDomain } from 'services/requests';
import { userCanDeleteDomain, userCanUpdateDomain } from 'services/permissions';

// Assets
import InputText from 'components/input-text';
import Spinner from 'components/spinner';
import Error from 'components/error';
import Tooltip from 'components/tooltip';
import FieldRequired from 'components/field-required';

// Messages
import messages from 'messages.json';

// ---------------------------------------- SUBDOMAINS ---------------------------------------- \\
const Subdomains = ({ domain }) => {
    const [state, setState] = useState({
        loading: false,
        error: false,
        subdomains: domain.domains || [],
        subdomain: null,
        openAdd: false,
        openUpdate: false,
        openDelete: false,
    });

    function onAddSubdomain(subdomain) {
        let subdomains = state.subdomains;
        subdomains.push(subdomain);
        setState({ ...state, openAdd: false, subdomains });
    }

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <Grid item xs={6}>
                    Sous-domaines
                </Grid>
                <Grid item xs={6} container justify="flex-end">
                    <Button onClick={() => setState({ ...state, openAdd: true })} variant="contained">
                        Ajouter un sous-domaine
                    </Button>
                </Grid>
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                <Grid container alignItems="center" className="list-headers">
                    <Grid item xs={12} sm={10} style={{ padding: 10 }}>
                        Nom
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10, textAlign: 'center' }}>
                        {userCanDeleteDomain() && 'Action'}
                    </Grid>
                </Grid>
                {/* -------------------- SUBDOMAIN LIST -------------------- */}
                <SubdomainList
                    state={state}
                    setState={setState}
                />
            </div>
            {/* -------------------- ADD SUBDOMAIN -------------------- */}
            {state.openAdd && (
                <AddSubdomain
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={subdomain => onAddSubdomain(subdomain)}
                />
            )}
        </>
    );
};

// ----------------------------------- SUBDOMAIN LIST ---------------------------------- \\
const SubdomainList = ({ state, setState }) => {

    function onDelete(subdomain_id) {
        let subdomains = state.subdomains;
        let index = subdomains.findIndex(c => c.id === subdomain_id);
        subdomains.splice(index, 1);
        setState({ ...state, openDelete: false, subdomains });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <div className="main-container-item">
            {state.subdomains.length > 0 ? (
                state.subdomains.map(subdomain => (
                    <Fragment key={subdomain.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={10} style={{ padding: 10 }}>
                                {subdomain.name}
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ padding: 10, textAlign: 'center' }}>
                                {userCanUpdateDomain() && (
                                    <Tooltip title="Supprimer"
                                        item={(
                                            <IconButton
                                                onClick={() => setState({ ...state, openDelete: true, subdomain })}
                                                style={{ padding: 6 }}       
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid container style={{ padding: 10 }}>
                        Aucun
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- DELETE SUBDOMAIN -------------------- */}
            {state.openDelete && (
                <DeleteSubdomain
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={subdomain_id => onDelete(subdomain_id)}
                    subdomain={state.subdomain}
                />
            )}
        </div>
    );
};

// ----------------------------------- ADD SUBDOMAIN ----------------------------------- \\
const AddSubdomain = ({ onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { domainId } = useParams();

    const [state, setState] = useState({
        loading: false,
        name: '',
    });

    function onAdd() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createDomain({ name: state.name, root_domain_id: domainId }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.domain);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Ajouter un sous-domaine
                        </p>
                        {/* -------------------- NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4}>
                                <span>Nom du sous-domaine</span><FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <InputText
                                    label="Nom du sous-domaine"
                                    type="text"
                                    value={state.name}
                                    onChange={e => setState({ ...state, name: e.target.value })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onAdd} variant="contained">
                            Ajouter
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ---------------------------------- DELETE SUBDOMAIN ---------------------------------- \\
const DeleteSubdomain = ({ onClose, refetch, subdomain }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteDomain(subdomain.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(subdomain.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {subdomain.name}
                        </p>
                        Êtes-vous sûr de vouloir supprimer ce sous-domaine ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default Subdomains;
