import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services
import { updateUserCategory, deleteUserCategory } from 'services/requests';
import { userCanDeleteUserCategory } from 'services/permissions';

// Components
import InputText from 'components/input-text';
import Spinner from 'components/spinner';
import FieldRequired from 'components/field-required';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- UPDATE USER CATEGORY ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const UpdateUserCategory = ({ onClose, refetch, userCategory }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: userCategory.name || '',
        openDelete: false,
    });

    function onUpdate() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateUserCategory(userCategory.id, { name: state.name }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Dialog open fullWidth>
                <DialogContent style={{ paddingBottom: 20 }}>
                    <p className="dialog-title">
                        Modifier un rôle
                    </p>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={4}>
                            Nom<FieldRequired />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InputText
                                label="Nom"
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {userCanDeleteUserCategory() && (
                        <>
                            <Divider style={{ margin: '15px 0px' }} />
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    Supprimer ce rôle
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Button
                                        onClick={() => setState({ ...state, openDelete: true })}
                                        variant="contained" color="secondary"
                                    >
                                        Supprimer
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={onClose} variant="contained">
                        Annuler
                    </Button>
                    <Button onClick={onUpdate} variant="contained">
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <DeleteUserCategory
                    onClose={() => setState({ ...state, openDelete: false })}
                    userCategory={userCategory}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- DELETE USER CATEGORY ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const DeleteUserCategory = ({ onClose, userCategory }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteUserCategory(userCategory.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/management/user-categories');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {userCategory.name}
                        </p>
                        Êtes-vous sûr de vouloir supprimer cette catégorie ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateUserCategory;
