import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, Paper, Divider } from '@material-ui/core';

// Services
import { getSubscriptionTypes, getUser } from 'services/requests';
import { isAuthenticated, getStoredUser } from 'services/storage';
import { fileUrl } from 'services/axios-config';
import { getAmountTTC } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';

// Assets
import ImgMABD from 'assets/images/logo-mabd.jpg';

/* * *
* Image dimensions
*/

const ImgWidth = 260;
const ImgHeight = 433;

// -------------------------------------------------------------------------------------- \\
// --------------------------------- SUBSCRIPTION TYPES --------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const SubscriptionTypes = () => {
    let storedUserId = getStoredUser()?.id;
    const history = useHistory();
    const { userId } = useParams();
    const USER_ID = userId ? userId : storedUserId;

    const [state, setState] = useState({
        loading: true,
        error: false,
        subscription_types: [],
        membershipStatus: null,
    });

    const getPushUrl = subscription_type => {

        let subscriptionTypeId = subscription_type?.id;
        if (subscriptionTypeId !== 1 && state.membershipStatus === 'active') {
            subscriptionTypeId = 4;
        }

        if (userId) {
            return '/users/' + userId + '/shop/subscription/' + subscriptionTypeId;
        }
        return '/shop/' + subscriptionTypeId + '/subscription/' + storedUserId;
    };

    useEffect(() => {
        if (state.loading) {
            if (isAuthenticated() && USER_ID) {
                axios.all([getSubscriptionTypes(), getUser(USER_ID)])
                .then(axios.spread(function (res_subscription_types, res_user) {
                    if (
                        res_user?.status === 200
                        && res_subscription_types?.status === 200
                    ) {
                        setState({
                            ...state,
                            loading: false,
                            membershipStatus: res_user?.data?.data?.user?.membershipStatus,
                            subscription_types: res_subscription_types?.data?.data?.subscriptionTypes,
                        });
                    }
                    else {
                        console.log(res_subscription_types + res_user);
                        setState({ ...state, loading: false, error: true });
                    }
                }));
            }
            else {
                getSubscriptionTypes().then(res => {
                    let subscription_types = res?.data?.data?.subscriptionTypes;
                    if (res?.status === 200 && subscription_types) {
                        setState({ ...state, loading: false, subscription_types });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, loading: false, error: true });
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <div
            className="main-container"
            style={{ padding: 24 }}
        >
            <Grid style={{ fontSize: 16 }}>
                Abonnements à la revue Biodynamis
            </Grid>
            <Grid style={{ padding: 24 }}>
                Bienvenue dans l’espace d’abonnement à Biodynamis !
                Notre revue trimestrielle s’adresse à tous les publics qui font la biodynamie d’aujourd’hui, débutants ou confirmés.
                <br />
                Au programme : reportages, gestes fondamentaux, approfondissements, portraits… entre champs et jardins, jusqu'à nos assiettes.
                Elle relaie également l'actualité du réseau, en France et à l'international.
                <br />
                Pour feuilleter un numéro, c'est ici :&nbsp;
                <a
                    href="https://www.bio-dynamie.org/publications/revue-biodynamis/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="a-override"
                >
                    https://www.bio-dynamie.org/publications/revue-biodynamis/
                </a>
                <br />
                <b>Vous pouvez avoir accès à un tarif préférentiel si vous êtes adhérent au MABD.</b>&nbsp;
                Si vous êtes adhérent professionnel, l’abonnement est déjà inclus.
            </Grid>
            {/* -------------------- MEMBERSHIP TYPE LIST -------------------- */}
            <Grid
                container spacing={3}
                style={{ padding: 12 }}
            >
                {state.subscription_types
                    .filter(st => st.id === 1 || st.id === 2)
                    .map(subscription_type => (
                        <Grid
                            key={subscription_type.id}
                            item xs={12} sm="auto"
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <Paper
                                onClick={() => history.push(getPushUrl(subscription_type))}
                                className="simple-list"
                                elevation={3}
                                style={{ borderRadius: 8, maxWidth: ImgWidth }}
                            >
                                {/* -------------------- IMAGE -------------------- */}
                                <Grid
                                    container justify="center" alignItems="center"
                                    style={{ height: ImgHeight }}
                                >
                                    <img
                                        alt="membership/img"
                                        src={subscription_type.image_url ? (fileUrl + subscription_type.image_url) : ImgMABD}
                                        style={{ width: ImgWidth, height: 'auto', maxHeight: ImgHeight }}
                                    />
                                </Grid>
                                <Divider />
                                {/* -------------------- NAME & AMOUNT -------------------- */}
                                <div style={{ textAlign: 'center', padding: 12 }}>
                                    <b>
                                        {subscription_type.id === 1 ? (
                                            subscription_type.name
                                        ) : (
                                            'Abonnement revue'
                                        )}
                                    </b>
                                    <br />
                                    <span style={{ fontSize: 16, fontWeight: 600, color: 'gray' }}>
                                        {getAmountTTC(subscription_type.price)} TTC
                                    </span>
                                    {subscription_type.id !== 1 && (
                                        <>
                                            <br />
                                            <span style={{ color: 'gray' }}>
                                                {getAmountTTC(state.subscription_types.find(st => st.id === 4)?.price)} si adhérent
                                                <br />
                                                +{getAmountTTC(state.subscription_types.find(st => st.id === 5)?.price - state.subscription_types.find(st => st.id === 4)?.price)} si étranger ou DOM-TOM
                                            </span>
                                        </>
                                    )}
                                </div>
                            </Paper>
                        </Grid>
                    )
                )}
            </Grid>
        </div>
    );
};

export default SubscriptionTypes;
