import React from 'react';

// Material-UI
import { Grid, Checkbox } from '@material-ui/core';

// Services
import { getAmountTTC } from 'services/helpers';

// ----------------------------------------------------------------------------------------- \\
// --------------------------------------- DEMETER ----------------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const Demeter = ({ state, setState }) => {
    return (
        <Grid style={{ padding: '24px 0px' }}>
            <Grid>
                Je certifie être adhérent Demeter pour l’année civile en cours.
                J'ai accès à une réduction de {getAmountTTC(state.membership_type?.demeter_discount)} si je le souhaite.
            </Grid>
            <Grid container alignItems="center">
                <Checkbox
                    color="secondary"
                    checked={state.is_demeter}
                    onChange={e => setState(prevState => ({ ...prevState, is_demeter: e.target.checked }))}
                />
                Je souhaite obtenir ma réduction
            </Grid>
        </Grid>
    );
};

export default Demeter;
