import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// Selects
import SelectCountry from 'selects/select-country';

// Views
import Geocode from './geocode';
import PostalCodeAndCityInputs from './postal-code-and-city-inputs';
import SelectFrenchDepartment from './select-french-department';

/* * *
* Formulaire permettant de renseigner les champs liés à l'adresse : numéro et voie, complément, code postal, ville, département et pays
*/

// ------------------------------------------------------------------------------------------ \\
// -------------------------------------- ADDRESS FORM -------------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const AddressForm = ({
    state,
    setState,
    addressLabel,
    requiredAddress,
    requiredPostalCode,
    requiredCity,
    requiredCountry,
    requiredDepartment,
    disabledDetailsAddress,
    disabledDepartment,
    disabledCountry,
    fullWidth,
    labelTitle,
    marginTop,
}) => {

    function handleChangeGeocode(geocode) {
        if (geocode) {
            setState({
                ...state,
                geocode: geocode,
                postal_code: geocode.value.split('&')[0],
                city: geocode.value.split('&')[1],
            });
        }
        else {
            setState({ ...state, geocode: null, postal_code: '', city: '' });
        }
    }

    return (
        <>
            {/* -------------------- ADDRESS -------------------- */}
            <Grid
                container alignItems="center"
                style={{ marginTop: marginTop }}
            >
                <Grid item xs={12} sm={fullWidth ? 12 : 4}>
                    <span className={labelTitle ? 'input-title' : ''}>
                        {addressLabel}{requiredAddress && <FieldRequired />}
                    </span>
                </Grid>
                <Grid item xs={12} sm={fullWidth ? 12 : 8}>
                    <InputText
                        label={addressLabel}
                        value={state.address}
                        onChange={e => setState({ ...state, address: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {!disabledDetailsAddress && (
                <Grid
                    container alignItems="center"
                    style={{ marginTop: marginTop }}
                >
                    <Grid item xs={12} sm={fullWidth ? 12 : 4}>
                        <span className={labelTitle ? 'input-title' : ''}>
                            Adresse (complément)
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={fullWidth ? 12 : 8}>
                        <InputText
                            label="Adresse (complément)"
                            value={state.address_details}
                            onChange={e => setState({ ...state, address_details: e.target.value })}
                        />
                    </Grid>
                </Grid>
            )}
            {/* -------------------- POSTAL CODE AND CITY -------------------- */}
            {state.country_id !== 1 && (
                <PostalCodeAndCityInputs
                    state={state}
                    setState={setState}
                    requiredPostalCode={requiredPostalCode}
                    requiredCity={requiredCity}
                    fullWidth={fullWidth}
                    labelTitle={labelTitle}
                    marginTop={marginTop}
                />
            )}
            {/* -------------------- GEOCODE -------------------- */}
            {state.country_id === 1 && (
                <Geocode
                    requiredPostalCode={requiredPostalCode}
                    requiredCity={requiredCity}
                    geocode={state.geocode}
                    setGeocode={geocode => handleChangeGeocode(geocode)}
                    fullWidth={fullWidth}
                    labelTitle={labelTitle}
                    marginTop={marginTop}
                />
            )}
            {/* ---------------------- DEPARTMENT ---------------------- */}
            {(!disabledDepartment && state.country_id === 1) && (
                <Grid
                    container alignItems="center"
                    style={{ marginTop: marginTop }}
                >
                    <Grid item xs={12} sm={fullWidth ? 12 : 4}>
                        <span className={labelTitle ? 'input-title' : ''}>
                            Département{requiredDepartment && <FieldRequired />}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={fullWidth ? 12 : 8}>
                        <SelectFrenchDepartment
                            frenchDepartmentId={state.french_department_id}
                            setFrenchDepartmentId={french_department_id => setState({ ...state, french_department_id })}
                            postalCode={state.postal_code}
                            countryId={state.country_id}
                        />
                    </Grid>
                </Grid>
            )}
            {/* ---------------------- COUNTRY ---------------------- */}
            {!disabledCountry && (
                <Grid
                    container alignItems="center"
                    style={{ marginTop: marginTop }}
                >
                    <Grid item xs={12} sm={fullWidth ? 12 : 4}>
                        <span className={labelTitle ? 'input-title' : ''}>
                            Pays{requiredCountry && <FieldRequired />}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={fullWidth ? 12 : 8}>
                        <SelectCountry
                            countryId={state.country_id}
                            setCountryId={country_id => setState({ ...state, country_id })}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

// Propriétés par défaut
AddressForm.defaultProps = {
    addressLabel: 'Adresse (n° et voie)',
    requiredAddress: false,
    requiredPostalCode: false,
    requiredCity: false,
    requiredCountry: false,
    requiredDepartment: false,
    disabledDetailsAddress: false,
    disabledDepartment: false,
    disabledCountry: false,
    fullWidth: false,
    labelTitle: false,
    marginTop: 6,
};

export default AddressForm;
