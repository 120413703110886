import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button } from '@material-ui/core';

// Services
import { deleteCustomItem } from '../../../services/requests';

// Components
import Spinner from '../../../components/spinner';

// ------------------------------------- DELETE CUSTOM ITEM ------------------------------------- \\
const DeleteCustomItem = ({ onClose, refetch, customItem }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteCustomItem(customItem.billable_id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {customItem.name}
                        </p>
                        Êtes-vous sûr de vouloir supprimer cet article personnalisé ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default DeleteCustomItem;
