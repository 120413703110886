import React, { useState, useEffect } from 'react';

// Material-UI
import { MenuItem } from '@material-ui/core';

// Components
import SelectOutlined from 'components/select-outlined';

// Services
import { getActivities } from 'services/requests';

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT ACTIVITY --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectActivity = ({ activityId, setActivityId, label, isPro }) => {
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        getActivities({ is_pro: isPro }).then(res => {
            if (res.status === 200) {
                setActivities(res.data.data.activities);
            }
            else {
                console.log(res);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SelectOutlined
            value={activityId}
            onChange={e => setActivityId(e.target.value)}
        >
            <MenuItem value={-1}>
                <em>{label}</em>
            </MenuItem>
            {activities.map(activity => (
                <MenuItem key={activity.id} value={activity.id}>
                    {activity.name}
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

// Propriétés par défaut
SelectActivity.defaultProps = {
    label: 'Activité',
    isPro: null,
};

export default SelectActivity;
