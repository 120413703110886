import React, { Fragment } from 'react';

// Material-UI
import { Grid, Divider } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------- CONTACTS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

export default function Contacts({ contacts }) {
    const { mediaMD } = useMediaQueries();

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                Contacts
            </Grid>
            <div className="main-container">
                {/* -------------------- HEADERS -------------------- */}
                {mediaMD ? (
                    <Grid container alignItems="center" className="list-headers">
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Nom
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Fonction ou rôle
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Email
                        </Grid>
                        <Grid item xs={2} style={{ padding: 10 }}>
                            Téléphone
                        </Grid>
                        <Grid item xs={4} style={{ padding: 10 }}>
                            Description
                        </Grid>
                    </Grid>
                ) : (
                    <Divider />
                )}
                {/* -------------------- CONTACT LIST -------------------- */}
                <div className="main-container-item">
                    {contacts.length > 0 ? (
                        contacts.map(contact => (
                            <Fragment key={contact.id}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                        {contact.name}
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                        {contact.type}
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                        {contact.email}
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                        {contact.phone}
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ padding: 10 }}>
                                        {contact.description}
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <Grid container style={{ padding: 10 }}>
                                Aucun
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
