import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Dialog, DialogContent, DialogActions, Divider, Button, CircularProgress } from '@material-ui/core';

// Services
import { sendResetPasswordToken } from 'services/requests';

// ------------------------------------------------------------------------------------------ \\
// --------------------------------------- EMAIL USED --------------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const EmailUsed = ({ emailUsed, onClose }) => {
    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            sendResetPasswordToken(emailUsed).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/login?email=' + emailUsed);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog
            open
            fullWidth
        >
            <DialogContent
                style={{ marginBottom: 20 }}
            >
                <p style={{ fontSize: 16 }}>
                    Un compte existe déjà avec l’adresse mail :<br />
                    <b>{emailUsed}</b>
                </p>
                <p>
                    Nous vous invitons à récupérer ce compte en cliquant sur “Mot de passe oublié ?”.<br />
                    Vous recevrez un mail avec la procédure pour récupérer votre mot de passe.
                </p>
                <Grid container justify="center">
                    <Button
                        onClick={() => setLoading(true)}
                        disabled={loading}
                        endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                        variant="contained" color="primary"
                    >
                        Réinitialiser mon mot de passe
                    </Button>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={loading}
                    variant="contained" color="inherit"
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailUsed;
