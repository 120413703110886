import React, { useState, useEffect, useRef } from 'react';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Material-UI
import { Divider, Grid, MenuItem, Checkbox, IconButton } from '@material-ui/core';
import { Delete as IconDelete } from '@material-ui/icons';

// Services
import { searchUsers, getFormations, getUserCategories } from 'services/requests';
import useMediaQueries from 'services/media';
import { formationSessionStatuses } from 'services/constants';

// Components
import Autocomplete from 'components/autocomplete';
import SelectOutlined from 'components/select-outlined';
import InputText from 'components/input-text';
import InputFile from 'components/input-file';
import DatePicker from 'components/date-picker';
import FieldRequired from 'components/field-required';

// Selects
import SelectFrenchDepartments from 'selects/select-french-departments';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------- FORMATION SESSION FORM ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationSessionForm = ({ state, setState }) => {
    let filteredSessionStatus = formationSessionStatuses;

    if (state.actionType === 'CREATE') {
        filteredSessionStatus = filteredSessionStatus.filter(s => s.value !== 'closed' && s.value !== 'cancelled');
    }

    useEffect(() => {
        getUserCategories({}).then(res => {
            if (res.status === 200) {
                setState({ ...state, user_categories: res.data.data.userCategories });
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* -------------------- FORMATION -------------------- */}
            {state.isSession && (
                <>
                    <Grid>
                        Modèle de formation<FieldRequired />
                    </Grid>
                    <Grid style={{ marginTop: 5 }}>
                        <SelectFormation
                            formation={state.formation}
                            setFormation={formation => setState({ ...state, formation })}
                        />
                    </Grid>
                    <Divider style={{ margin: '15px 0px' }} />
                </>
            )}
            {/* -------------------- NAME -------------------- */}
            <Grid style={{ marginTop: 10 }}>
                Nom de la formation
            </Grid>
            <Grid style={{ marginTop: 5 }}>
                <InputText
                    label="Nom de la formation"
                    value={state.name}
                    onChange={e => setState({ ...state, name: e.target.value })}
                />
            </Grid>
            {/* -------------------- END OF REGISTRATION -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={6}>
                    Date de fin d'inscription<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DatePicker
                        outlined
                        value={state.end_of_registration}
                        onChange={end_of_registration => setState({ ...state, end_of_registration })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- CAPACITY -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={6}>
                    Nombre de participants maximum<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputText
                        label="Nombre de participants maximum"
                        type="number"
                        value={state.capacity}
                        onChange={e => setState({ ...state, capacity: e.target.value })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- STATUS -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={6}>
                    Statut<FieldRequired />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SelectOutlined
                        value={state.status}
                        onChange={e => setState({ ...state, status: e.target.value })}
                    >
                        {filteredSessionStatus.map(status => (
                            <MenuItem key={status.value} value={status.value}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- CANCEL REASON -------------------- */}
            {(state.actionType === 'UPDATE' && state.status === 'cancelled') && (
                <>
                    <Grid style={{ marginTop: 10 }}>
                        <b>Veuillez saisir la raison de l'annulation de la formation :</b>
                    </Grid>
                    <Grid style={{ marginTop: 5 }}>
                        <InputText
                            multiline
                            value={state.cancel_reason}
                            onChange={e => setState({ ...state, cancel_reason: e.target.value })}
                        />
                    </Grid>
                </>
            )}
            {/* -------------------- DEPARTMENTS -------------------- */}
            <Grid style={{ marginTop: 10 }}>
                Départements
            </Grid>
            <Grid style={{ marginTop: 5 }}>
                <SelectFrenchDepartments
                    frenchDepartments={state.french_departments}
                    setFrenchDepartments={french_departments => setState({ ...state, french_departments })}
                />
            </Grid>
            {/* -------------------- MANAGER -------------------- */}
            <SelectManager
                manager={state.manager}
                setManager={manager => setState({ ...state, manager })}
                managerCategoryId={state.manager_category_id}
                setManagerCategoryId={manager_category_id => setState({ ...state, manager_category_id })}
                userCategories={state.user_categories}
            />
            {/* -------------------- ANIMATOR -------------------- */}
            <SelectAnimator
                animator={state.animator}
                setAnimator={animator => setState({ ...state, animator })}
                animatorCategoryId={state.animator_category_id}
                setAnimatorCategoryId={animator_category_id => setState({ ...state, animator_category_id })}
                userCategories={state.user_categories}
            />
            {/* -------------------- TRAINERS -------------------- */}
            <SelectTrainers
                trainers={state.trainers}
                setTrainers={trainers => setState({ ...state, trainers })}
                trainerCategoryId={state.trainer_category_id}
                setTrainerCategoryId={trainer_category_id => setState({ ...state, trainer_category_id })}
                userCategories={state.user_categories}
            />
            <Divider style={{ margin: '15px 0px' }} />
            {/* -------------------- UPLOADED PHOTO -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    Photo
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputFile inputId="photo" />
                </Grid>
            </Grid>
            {/* -------------------- UPLOADED FILE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={4}>
                    Programme détaillé et infos pratiques
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputFile inputId="file" />
                </Grid>
            </Grid>
            {state.document_url && (
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} />
                    <Grid item xs={12} sm={8} style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            checked={state.remove_documentation}
                            onChange={e => setState({ ...state, remove_documentation: e.target.checked })}
                            color="secondary"
                            style={{ padding: 6 }}
                        />
                        Supprimer la plaquette
                    </Grid>
                </Grid>
            )}
            {/* -------------------- UPLOADED TIMESHEET -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={4}>
                    Feuille d'émargement
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputFile inputId="timesheet" />
                </Grid>
            </Grid>
            {/* -------------------- PEDAGOGICAL SUPPORT -------------------- */}
            {/* -------------------- PREVIOUSLY : UPLOADED TIMESHEET VIVEA -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={4}>
                    Support pédagogique
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputFile inputId="timesheet-vivea" />
                </Grid>
            </Grid>
            {/* -------------------- VIVEA REFERENCE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={4}>
                    Référence VIVEA
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputText
                        label="Référence VIVEA"
                        value={state.vivea_reference}
                        onChange={e => setState({ ...state, vivea_reference: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Divider style={{ margin: '15px 0px' }}  />
            {/* -------------------- MAP INFORMATIONS -------------------- */}
            <Grid container>
                Informations pour la carte Google Maps
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
                <InputText
                    multiline
                    label="Informations..."
                    value={state.map_information}
                    onChange={e => setState({ ...state, map_information: e.target.value })}
                />
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT MANAGER ----------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SelectFormation = ({ formation, setFormation }) => {

    const [state, setState] = useState({
        loading: false,      
        options: [],
        searchRef: null,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 1) {
                getFormations({ offset: 0, limit: 10, titles: [state.searchRef] }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.data.formations.map(f => ({ label: f.title, value: f.id })),
                        });
                    }
                    else {
                        setState({ ...state, loading: false });
                    }
                });
            } 
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Autocomplete
            value={formation}
            options={state.options}
            loading={state.loading}
            onChange={(e, value) => setFormation(value)}
            onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
            placeholder="Chercher un modèle de formation..."
        />
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT MANAGER ----------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SelectManager = ({ manager, setManager, managerCategoryId, setManagerCategoryId, userCategories }) => {
    const { mediaSM } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,      
        options: [],
        searchRef: undefined,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 3) {
                searchUsers({
                    search: state.searchRef,
                    user_category_id: managerCategoryId === -1 ? null : managerCategoryId,
                }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.data.users.map(u => ({ label: u.fullName + ' (' + u.clientCode + ')', value: u.id })),
                        });
                    }
                    else {
                        setState({ ...state, loading: false });
                    }
                });
            } 
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Grid style={{ marginTop: 10 }}>
                Responsable de la formation
            </Grid>
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={6} style={{ paddingRight: mediaSM ? 10 : 0 }}>
                    <Autocomplete
                        value={manager}
                        options={state.options}
                        loading={state.loading}
                        onChange={(e, value) => setManager(value)}
                        onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
                        placeholder="Chercher un utilisateur..."
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SelectOutlined
                        value={managerCategoryId}
                        onChange={e => setManagerCategoryId(e.target.value)}
                    >
                        <MenuItem value={-1}>
                            <em>Tous les utilisateurs</em>
                        </MenuItem>
                        {userCategories.map(category => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------- SELECT ANIMATOR ----------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SelectAnimator = ({ animator, setAnimator, animatorCategoryId, setAnimatorCategoryId, userCategories }) => {
    const { mediaSM } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,      
        options: [],
        searchRef: undefined,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 3) {
                searchUsers({
                    search: state.searchRef,
                    user_category_id: animatorCategoryId === -1 ? null : animatorCategoryId,
                }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.data.users.map(u => ({ label: u.fullName + ' (' + u.clientCode + ')', value: u.id })),
                        });
                    }
                    else {
                        setState({ ...state, loading: false });
                    }
                });
            } 
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Grid style={{ marginTop: 10 }}>
                Animateur de la formation
            </Grid>
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={6} style={{ paddingRight: mediaSM ? 10 : 0 }}>
                    <Autocomplete
                        value={animator}
                        options={state.options}
                        loading={state.loading}
                        onChange={(e, value) => setAnimator(value)}
                        onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
                        placeholder="Chercher un utilisateur..."
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SelectOutlined
                        value={animatorCategoryId}
                        onChange={e => setAnimatorCategoryId(e.target.value)}
                    >
                        <MenuItem value={-1}>
                            <em>Tous les utilisateurs</em>
                        </MenuItem>
                        {userCategories.map(category => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT TRAINERS ---------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SelectTrainers = ({ trainers, setTrainers, trainerCategoryId, setTrainerCategoryId, userCategories }) => {
    const { mediaSM } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,      
        options: [],
        trainer: null,
        searchRef: undefined,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    function onChangeTrainer(trainer) {
        let updatedTrainers = trainers;
        if (!trainers.map(t => t.value).includes(trainer.value)) {
            updatedTrainers.push(trainer);
            setTrainers(updatedTrainers);
        }
    }

    function onRemoveTrainer(trainer) {
        let updatedTrainers = trainers;
        const index = trainers.map(t => t.value).findIndex(value => value === trainer.value);
        updatedTrainers.splice(index, 1);
        setTrainers(updatedTrainers);
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 3) {
                searchUsers({
                    search: state.searchRef,
                    user_category_id: trainerCategoryId === -1 ? null : trainerCategoryId,
                }).then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.data.users.map(u => ({ label: u.fullName + ' (' + u.clientCode + ')', value: u.id })),
                        });
                    }
                    else {
                        setState({ ...state, loading: false });
                    }
                });
            } 
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Grid style={{ marginTop: 10 }}>
                Formateurs
            </Grid>
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={6} style={{ paddingRight: mediaSM ? 10 : 0 }}>
                    <Autocomplete
                        value={state.trainer}
                        options={state.options}
                        loading={state.loading}
                        onChange={(e, value) => onChangeTrainer(value)}
                        onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
                        placeholder="Chercher un utilisateur..."
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SelectOutlined
                        value={trainerCategoryId}
                        onChange={e => setTrainerCategoryId(e.target.value)}
                    >
                        <MenuItem value={-1}>
                            <em>Tous les utilisateurs</em>
                        </MenuItem>
                        {userCategories.map(category => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            <Grid container>
                {trainers.map(trainer => (
                    <Grid
                        key={trainer.value}
                        container justify="space-between" alignItems="center"
                        style={{ marginTop: 10, backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: 4, padding: 6 }}
                    >
                        {trainer.label}
                        <IconButton onClick={() => onRemoveTrainer(trainer)} style={{ padding: 0 }}>
                            <IconDelete />
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default FormationSessionForm;
