import React, { useState, useEffect, useRef } from 'react';

// Axios
import axios from 'axios';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Material-UI
import { Grid, IconButton } from '@material-ui/core';
import { AddCircle as AddIcon } from '@material-ui/icons';

// Components
import Autocomplete from 'components/autocomplete';
import FieldRequired from 'components/field-required';

// Views
import PostalCodeAndCityDialog from './postal-code-and-city-dialog';

// ------------------------------------------------------------------------------------------ \\
// ----------------------------------------- GEOCODE ---------------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const Geocode = ({
    geocode,
    setGeocode,
    requiredPostalCode,
    requiredCity,
    fullWidth,
    labelTitle,
    marginTop,
}) => {

    const [state, setState] = useState({
        loading: false,
        options: [],
        searchRef: null,
        open: false,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    useEffect(() => {
        if (state.loading) {       
            if (state.searchRef.length >= 5) {
                // `https://api-adresse.data.gouv.fr/search/?q=${state.searchRef}&postcode=${state.searchRef}&type=municipality&limit=20`
                // `https://geo.api.gouv.fr/communes?codePostal={state.searchRef}`
                axios.get(
                    `https://api-adresse.data.gouv.fr/search/?q=${state.searchRef}&postcode=${state.searchRef}&type=municipality&limit=20`
                )
                .then(res => {
                    if (res.status === 200) {
                        setState({
                            ...state,
                            loading: false,
                            options: res.data.features.map(f => ({
                                label: f.properties.postcode + ' ' + f.properties.city,
                                value: f.properties.postcode + '&' + f.properties.city,
                            })),
                        });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, loading: false });
                    }
                });
            } 
            else {
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Grid
                container alignItems="center"
                style={{ marginTop: marginTop }}
            >
                <Grid item xs={12} sm={fullWidth ? 12 : 4}>
                    <span className={labelTitle ? 'input-title' : ''}>
                        Code postal - Ville{requiredPostalCode && <FieldRequired />}
                    </span>
                    <IconButton
                        onClick={() => setState({ ...state, open: true })}
                        style={{ padding: 4 }}
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12} sm={fullWidth ? 12 : 8}>
                    <Autocomplete
                        value={geocode}
                        options={state.options}
                        loading={state.loading}
                        onChange={(e, value) => setGeocode(value)}
                        onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
                        placeholder="Code postal (ex: 68000)..."
                    />
                </Grid>
            </Grid>
            {/* -------------------- MODAL INPUTS -------------------- */}
            {state.open && (
                <PostalCodeAndCityDialog
                    onClose={() => setState({ ...state, open: false })}
                    setGeocode={setGeocode}
                    requiredPostalCode={requiredPostalCode}
                    requiredCity={requiredCity}
                />
            )}
        </>
    );
};

export default Geocode;
