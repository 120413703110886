import React from 'react';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Button } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- PAGE NOT FOUND --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const PageNotFound = () => {
    const history = useHistory();

    return (
        <div
            className="feedback"
            style={{ flexDirection: 'column' }}
        >
            <div style={{ fontSize: 20, display: 'flex', alignItems: 'center' }}>
                <ErrorIcon style={{ marginRight: 6 }} />
                PAGE INTROUVABLE
            </div>
            <div style={{ margin: '15px 0px' }}>
                La page que vous recherchez n'existe plus ou a été déplacée.
            </div>
            <Button
                onClick={() => history.push('/login')}
                variant="contained"
            >
                Revenir à l'accueil du site
            </Button>
        </div>
    );
};

export default PageNotFound;
