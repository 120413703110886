import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// ----------------------------------------------------------------------------------------- \\
// -------------------------------------- MABD ONLY ---------------------------------------- \\
// ----------------------------------------------------------------------------------------- \\

const MabdOnly = ({ state, setState }) => {
    return (
        <Grid style={{ padding: '24px 0px 12px 0px' }}>
            <p>
                En adhérent au MABD, si votre territoire est couvert par une association régionale fédérée au MABD,
                vous deviendrez membre des 2 associations. Dans ce cas, nous reverserons une part de votre cotisation et transmettrons vos
                coordonnées à cette association.
            </p>
            {(state.federatedAssociation && state.federatedAssociation.id === 1) && (
                <p>
                    Votre territoire n'est pas encore couvert par une association locale, vous serez uniquement adhérent
                    à l'association nationale MABD.
                </p>
            )}
            {(state.federatedAssociation && state.federatedAssociation.id !== 1) && (
                <p>
                    Votre territoire est couvert par {state.federatedAssociation.name}.
                </p>
            )}
            {/* <Grid container alignItems="center">
                <Checkbox
                    color="secondary"
                    checked={state.only_mabd}
                    onChange={e => setState({ ...state, only_mabd: e.target.checked })}
                />
                Je souhaite être uniquement adhérent au MABD
            </Grid> */}
        </Grid>
    );
};

export default MabdOnly;
