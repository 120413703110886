import React from 'react';
import Select from 'components/react-select';
import { subscriptionStatuses } from 'services/constants';

// Column Width
let columnWidth = 180;

// -------------------- HEADER -------------------- \\
const SubscriptionStatusHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Statut abonnement
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const SubscriptionStatusFilter = ({ state, onSelectSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <Select
                placeholder="Statut abonnement"
                value={state.subscription_statuses}
                onChange={subscription_statuses => onSelectSearch('subscription_statuses', subscription_statuses)}
                options={subscriptionStatuses}
                isMulti
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const SubscriptionStatusValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { SubscriptionStatusHeader, SubscriptionStatusFilter, SubscriptionStatusValue };
