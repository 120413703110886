import React, { useState, useEffect, useRef } from 'react';
import ReactSelect from 'react-select';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Services
import { getGroups } from 'services/requests';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------- SELECT AND SEARCH GROUPS --------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SearchAndSelectGroups = ({ groups, setGroups, placeholder }) => {
    const [state, setState] = useState({
        loading: false,
        inputValue: '',
        options: [],
    });

    // Effectue une recherche seulement tous les 500ms et non à chaque caractère saisi
    const onSearch = value => {
        loadGroups(value);
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    // Effectue une recherche seulement à partir de 3 caractères saisis
    function handleDelayedOnSearch(inputValue) {
        if (inputValue && inputValue.length >= 3) {
            setState({ ...state, loading: true, inputValue });
        }
    }

    function loadGroups(inputValue) {
        getGroups({ offset: 0, limit: 10, name: inputValue }).then(res => {
            if (res.status === 200) {
                setState({
                    ...state,
                    loading: false,
                    options: res.data.data.userLists.map(l => ({ label: l.name, value: l.id })),
                });
            }
        });
    }

    useEffect(() => {
        if (state.loading) {
            delayedOnSearch(state.inputValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <ReactSelect
            value={groups}
            onChange={value => setGroups(value)}
            onInputChange={inputValue => handleDelayedOnSearch(inputValue)}
            options={state.options}
            isLoading={state.loading}
            placeholder={placeholder}
            isMulti
            noOptionsMessage={() => 'Aucun'}
            isClearable={false}
            maxMenuHeight={200}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                container: base => ({ ...base, minWidth: 200 })
            }}
        />
    );
};

// Propriétés par défaut
SearchAndSelectGroups.defaultProps = {
    placeholder: 'Chercher...',
};

export default SearchAndSelectGroups;
