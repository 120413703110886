import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogActions, DialogContent, Checkbox } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon, ViewWeek as ColumnIcon, UnfoldMore as OrderIcon, OpenInNew, OpenInNewOutlined  } from '@material-ui/icons';

// Services
import { getBills, getIndexPreferences, updateIndexPreferences } from 'services/requests';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';
import { billStatuses, paymentMethods, billStates } from 'services/constants';
import { getLabelStatus, getColorStatus, getAmountTTC, getFormattedCreatedAt, getFilterOrder } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import InputText from 'components/input-text';
import Tooltip from 'components/tooltip';
import SelectCreatable from 'components/select-creatable';
import Pagination from 'components/pagination';

// Selects
import SelectFederatedAssociations from 'selects/select-federated-associations';
import SelectFederatedAssociation from 'selects/select-federated-association';
import SelectCountries from 'selects/select-countries';
import SelectYesOrNo from 'selects/select-yes-or-no';
import SelectBillStatuses from 'selects/select-bill-statuses';
import SelectPaymentMethods from 'selects/select-payment-methods';
import SelectBillState from 'selects/select-bill-state';

// Views
import Bill from 'views/common/bill';
import BillsExport from './bills-export';

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- BILLS ROUTER ------------------------------------------ \\
// --------------------------------------------------------------------------------------------------- \\

const BillRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Bills />
            </Route>
            <Route path={`${match.path}/:billId`}>
                <Bill />
            </Route>
        </Switch>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- BILLS ---------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const Bills = () => {
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;
    const storedFilters = JSON.parse(sessionStorage.getItem('bills-filters'));

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        bills: [],
        offset: 0,
        limit: 30,
        total_count: 0,

        // Manage Columns
        loadingColumns: false,
        openColumns: false,
        enabled_status: true,
        enabled_client_code: true,
        enabled_external_client_code: true,
        enabled_last_name: true,
        enabled_first_name: true,
        enabled_company_name: true,
        enabled_reference: true,
        enabled_total_amount: true,
        enabled_rest: true,
        enabled_external_reference: true,
        enabled_pdf_bill: true,
        enabled_pdf_credit: true,
        enabled_email: false,
        enabled_mobile_phone: false,
        enabled_phone: false,
        enabled_address: false,
        enabled_address_details: false,
        enabled_country: false,
        enabled_postal_code: false,
        enabled_federated_association: false,
        enabled_payment_method: false,
        enabled_membership_email: false,
        enabled_subscription_email: false,
        enabled_bill_email: false,
        enabled_created_at: false,
        enabled_bill_state: false,
        enabled_payment_recipient_association: false,
        enabled_payment_creator: false,

        // Filters
        order_by: storedFilters?.order_by || '',
        order: storedFilters?.order || 'desc',
        statuses: storedFilters?.statuses || [],
        client_code: storedFilters?.client_code || '',
        external_client_code: storedFilters?.external_client_code || '',
        last_name: storedFilters?.last_name || '',
        first_name: storedFilters?.first_name || '',
        company_name: storedFilters?.company_name || '',
        reference: storedFilters?.reference || '',
        total_amount: storedFilters?.total_amount || '',
        rest: storedFilters?.rest || '',
        external_reference: storedFilters?.external_reference || '',
        pdf_bill: storedFilters?.pdf_bill != null ? storedFilters?.pdf_bill : -1,
        pdf_credit: storedFilters?.pdf_credit != null ? storedFilters?.pdf_credit : -1,
        email: storedFilters?.email || '',
        mobile_phone: storedFilters?.mobile_phone || '',
        phone: storedFilters?.phone || '',
        address: storedFilters?.address || '',
        address_details: storedFilters?.address_details || '',
        countries: storedFilters?.countries || [],
        postal_codes: storedFilters?.postal_codes || [],
        federated_associations: storedFilters?.federated_associations || [],
        payment_methods: storedFilters?.payment_methods || [],
        membership_email: storedFilters?.membership_email || '',
        subscription_email: storedFilters?.subscription_email || '',
        bill_email: storedFilters?.bill_email || '',
        created_at: storedFilters?.created_at || '',
        bill_state: storedFilters?.bill_state || -1,
        payment_recipient_association: storedFilters?.payment_recipient_association != null ? storedFilters?.payment_recipient_association : -1,
        payment_creator: storedFilters?.payment_creator || '',

        // Checkbox to open bill in new tab
        new_tab_checked: localStorage.getItem('new_tab_checked') === '1' ? true : false,
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        sessionStorage.removeItem('bills-filters');
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            statuses: [],
            order_by: '',
            order: 'desc',
            client_code: '',
            external_client_code: '',
            last_name: '',
            first_name: '',
            company_name: '',
            reference: '',
            total_amount: '',
            rest: '',
            external_reference: '',
            pdf_bill: -1,
            pdf_credit: -1,
            email: '',
            mobile_phone: '',
            phone: '',
            address: '',
            address_details: '',
            countries: [],
            postal_codes: [],
            federated_associations: [],
            payment_methods: [],
            membership_email: '',
            subscription_email: '',
            bill_email: '',
            created_at: '',
            bill_state: -1,
            payment_recipient_association: -1,
            payment_creator: '',
        });
    }

    const onChangeNewTabChecked = checked => {
        localStorage.setItem('new_tab_checked', checked ? '1' : '0');
        setState({ ...state, new_tab_checked: checked });
    };

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'bills',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false,
                            loading: true,
                            enabled_status: columns.enabled_status,
                            enabled_client_code: columns.enabled_client_code,
                            enabled_external_client_code: columns.enabled_external_client_code,
                            enabled_last_name: columns.enabled_last_name,
                            enabled_first_name: columns.enabled_first_name,
                            enabled_company_name: columns.enabled_company_name,
                            enabled_reference: columns.enabled_reference,
                            enabled_total_amount: columns.enabled_total_amount,
                            enabled_rest: columns.enabled_rest,
                            enabled_external_reference: columns.enabled_external_reference,
                            enabled_pdf_bill: columns.enabled_pdf_bill,
                            enabled_pdf_credit: columns.enabled_pdf_credit,
                            enabled_email: columns.enabled_email,
                            enabled_mobile_phone: columns.enabled_mobile_phone,
                            enabled_phone: columns.enabled_phone,
                            enabled_address: columns.enabled_address,
                            enabled_address_details: columns.enabled_address_details,
                            enabled_country: columns.enabled_country,
                            enabled_postal_code: columns.enabled_postal_code,
                            enabled_federated_association: columns.enabled_federated_association,
                            enabled_payment_method: columns.enabled_payment_method,
                            enabled_membership_email: columns.enabled_membership_email,
                            enabled_subscription_email: columns.enabled_subscription_email,
                            enabled_bill_email: columns.enabled_bill_email,
                            enabled_created_at: columns.enabled_created_at,
                            enabled_bill_state: columns.enabled_bill_state,
                            enabled_payment_recipient_association: columns.enabled_payment_recipient_association,
                            enabled_payment_creator: columns.enabled_payment_creator,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('bills-filters', JSON.stringify({
                statuses: state.statuses,
                order_by: state.order_by,
                order: state.order,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                last_name: state.last_name,
                first_name: state.first_name,
                company_name: state.company_name,
                reference: state.reference,
                total_amount: state.total_amount,
                rest: state.rest,
                external_reference: state.external_reference,
                pdf_bill: state.pdf_bill,
                pdf_credit: state.pdf_credit,
                email: state.email,
                mobile_phone: state.mobile_phone,
                phone: state.phone,
                address: state.address,
                address_details: state.address_details,
                countries: state.countries,
                postal_codes: state.postal_codes,
                federated_associations: state.federated_associations,
                payment_methods: state.payment_methods,
                membership_email: state.membership_email,
                subscription_email: state.subscription_email,
                bill_email: state.bill_email,
                created_at: state.created_at,
                bill_state: state.bill_state,
                payment_recipient_association: state.payment_recipient_association,
                payment_creator: state.payment_creator,
            }));

            getBills({
                offset: state.offset,
                limit: state.limit,
                order_by: state.order_by,
                order: state.order,
                statuses: state.statuses ? state.statuses.map(s => s.value) : null,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                last_name: state.last_name,
                first_name: state.first_name,
                company_name: state.company_name,
                reference: state.reference,
                total_amount: state.total_amount,
                rest: state.rest,
                external_reference: state.external_reference,
                pdf_bill: state.pdf_bill,
                pdf_credit: state.pdf_credit,
                email: state.email,
                mobile_phone: state.mobile_phone,
                phone: state.phone,
                address: state.address,
                address_details: state.address_details,
                country_ids: state.countries ? state.countries.map(c => c.value) : null,
                postal_codes: state.postal_codes ? state.postal_codes.map(c => c.value) : null,
                federated_association_ids: state.federated_associations ? state.federated_associations.map(a => a.value) : null,
                payment_methods: state.payment_methods ? state.payment_methods.map(m => m.value) : null,
                membership_email: state.membership_email,
                subscription_email: state.subscription_email,
                bill_email: state.bill_email,
                created_at: state.created_at,
                bill_state: state.bill_state,
                payment_recipient_id: state.payment_recipient_association,
                payment_creator: state.payment_creator,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        bills: res.data.data.bills,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.push('/management')}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Bons de commande
                </Grid>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="toolbar">
                <div>
                    <Tooltip title="Chercher"
                        item={(
                            <Button onClick={onSearch} variant="contained">
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button onClick={cancelSearch} variant="contained" style={{ marginLeft: 5 }}>
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <BillsExport data={state} />
                    <Tooltip title="Ouvrir les BC dans un nouvel onglet"
                        item={(
                            <Checkbox
                                checked={state.new_tab_checked}
                                onChange={e => onChangeNewTabChecked(e.target.checked)}
                                icon={<OpenInNewOutlined />}
                                checkedIcon={<OpenInNew />}
                                style={{ marginLeft: 5 }}
                            />
                        )}
                    />
                </div>
                <b>
                    {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                </b>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers
                            state={state}
                            setState={setState}
                        />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <BillList
                        state={state}
                        setState={setState}
                    />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- HEADERS --------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const Headers = ({ state, setState }) => {

    function onSearch(order_by) {
        setState({ ...state, loading: true, error: false, offset: 0, order_by, order: getFilterOrder(state.order) });
    }

    return (
        <div className="list-headers">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    Statut bon de commande
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    Code client
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    Code client externe
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    Nom
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    Prénom
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    Nom de l'entreprise
                </div>
            )}
            {/* -------------------- REFERENCE -------------------- */}
            {state.enabled_reference && (
                <div className="column-list">
                    <Button
                        onClick={() => onSearch('reference')}
                        style={{ textTransform: 'none', padding: 0, color: 'white' }}
                    >
                        Réf. bon de commande
                        <OrderIcon style={{ marginLeft: 5 }} />
                    </Button>
                </div>
            )}
            {/* -------------------- TOTAL AMOUNT -------------------- */}
            {state.enabled_total_amount && (
                <div className="column-list">
                    Montant total TTC
                </div>
            )}
            {/* -------------------- REST -------------------- */}
            {state.enabled_rest && (
                <div className="column-list">
                    Restant dû
                </div>
            )}
            {/* -------------------- EXTERNAL REFERENCE -------------------- */}
            {state.enabled_external_reference && (
                <div className="column-list">
                    Réf. externe
                </div>
            )}
            {/* -------------------- PDF BILL -------------------- */}
            {state.enabled_pdf_bill && (
                <div className="column-list">
                    Facture PDF
                </div>
            )}
            {/* -------------------- PDF CREDIT -------------------- */}
            {state.enabled_pdf_credit && (
                <div className="column-list">
                    Avoir PDF
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    Email compte
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    Téléphone portable
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    Téléphone fixe
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    Adresse FACT (n° et voie)
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    Adresse FACT (complément)
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    Pays
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    Code postal
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    Association fédérée
                </div>
            )}
            {/* -------------------- PAYMENT METHOD -------------------- */}
            {state.enabled_payment_method && (
                <div className="column-list">
                    Mode de paiement
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    Email adhésion
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
            {state.enabled_subscription_email && (
                <div className="column-list">
                    Email abonnement
                </div>
            )}
            {/* -------------------- BILL EMAIL -------------------- */}
            {state.enabled_bill_email && (
                <div className="column-list">
                    Email bon de commande
                </div>
            )}
            {/* -------------------- CREATED AT -------------------- */}
            {state.enabled_created_at && (
                <div className="column-list">
                    <Button
                        onClick={() => onSearch('created_at')}
                        style={{ textTransform: 'none', padding: 0, color: 'white' }}
                    >
                        Date BC
                        <OrderIcon style={{ marginLeft: 5 }} />
                    </Button>
                </div>
            )}
            {/* -------------------- BILL STATE -------------------- */}
            {state.enabled_bill_state && (
                <div className="column-list">
                    Etat BC
                </div>
            )}
            {/* -------------------- PAYMENT RECIPIENT ASSOCIATION -------------------- */}
            {state.enabled_payment_recipient_association && (
                <div className="column-list">
                    Association destinataire du paiement
                </div>
            )}
            {/* -------------------- PAYMENT CREATOR -------------------- */}
            {state.enabled_payment_creator && (
                <div className="column-list">
                    Créateur du paiement
                </div>
            )}
        </div>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- FILTERS --------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_status && (
                <div className="column-list">
                    <SelectBillStatuses
                        statuses={state.statuses}
                        setStatuses={statuses => onSelectSearch('statuses', statuses)}
                    />
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client"
                        value={state.client_code}
                        onChange={e => setState({ ...state, client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client externe"
                        value={state.external_client_code}
                        onChange={e => setState({ ...state, external_client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    <InputText
                        label="Nom"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    <InputText
                        label="Prénom"
                        value={state.first_name}
                        onChange={e => setState({ ...state, first_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    <InputText
                        label="Nom de l'entreprise"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- REFERENCE -------------------- */}
            {state.enabled_reference && (
                <div className="column-list">
                    <InputText
                        label="Réf. bon de commande"
                        value={state.reference}
                        onChange={e => setState({ ...state, reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- TOTAL AMOUNT -------------------- */}
            {state.enabled_total_amount && (
                <div className="column-list">
                    <InputText
                        label="Montant total TTC"
                        value={state.total_amount}
                        onChange={e => setState({ ...state, total_amount: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- REST -------------------- */}
            {state.enabled_rest && (
                <div className="column-list">
                    <InputText
                        label="Restant dû"
                        value={state.rest}
                        onChange={e => setState({ ...state, rest: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EXTERNAL REFERENCE -------------------- */}
            {state.enabled_external_reference && (
                <div className="column-list">
                    <InputText
                        label="Réf. externe"
                        value={state.external_reference}
                        onChange={e => setState({ ...state, external_reference: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PDF BILL -------------------- */}
            {state.enabled_pdf_bill && (
                <div className="column-list">
                    <SelectYesOrNo
                        label="Facture PDF"
                        value={state.pdf_bill}
                        onChange={pdf_bill => onSelectSearch('pdf_bill', pdf_bill)}
                    />
                </div>
            )}
            {/* -------------------- PDF CREDIT -------------------- */}
            {state.enabled_pdf_credit && (
                <div className="column-list">
                    <SelectYesOrNo
                        label="Avoir PDF"
                        value={state.pdf_credit}
                        onChange={pdf_credit => onSelectSearch('pdf_credit', pdf_credit)}
                    />
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    <InputText
                        label="Email compte"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone portable"
                        value={state.mobile_phone}
                        onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone fixe"
                        value={state.phone}
                        onChange={e => setState({ ...state, phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS -------------------- */}
            {state.enabled_address && (
                <div className="column-list">
                    <InputText
                        label="Adresse (n° et voie)"
                        value={state.address}
                        onChange={e => setState({ ...state, address: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- ADDRESS DETAILS -------------------- */}
            {state.enabled_address_details && (
                <div className="column-list">
                    <InputText
                        label="Adresse (complément)"
                        value={state.address_details}
                        onChange={e => setState({ ...state, address_details: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    <SelectCountries
                        countries={state.countries}
                        setCountries={countries => onSelectSearch('countries', countries)}
                    />
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    <SelectCreatable
                        placeholder="Code postal"
                        value={state.postal_codes}
                        onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                    />
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    <SelectFederatedAssociations
                        federatedAssociations={state.federated_associations}
                        setFederatedAssociations={federated_associations => onSelectSearch('federated_associations', federated_associations)}
                    />
                </div>
            )}
            {/* -------------------- PAYMENT METHOD -------------------- */}
            {state.enabled_payment_method && (
                <div className="column-list">
                    <SelectPaymentMethods
                        methods={state.payment_methods}
                        setMethods={payment_methods => onSelectSearch('payment_methods', payment_methods)}
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    <InputText
                        label="Email adhésion"
                        value={state.membership_email}
                        onChange={e => setState({ ...state, membership_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
            {state.enabled_subscription_email && (
                <div className="column-list">
                    <InputText
                        label="Email abonnement"
                        value={state.subscription_email}
                        onChange={e => setState({ ...state, subscription_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL EMAIL -------------------- */}
            {state.enabled_bill_email && (
                <div className="column-list">
                    <InputText
                        label="Email bon de commande"
                        value={state.bill_email}
                        onChange={e => setState({ ...state, bill_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- CREATED AT -------------------- */}
            {state.enabled_created_at && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.created_at}
                        onChange={e => setState({ ...state, created_at: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL STATE -------------------- */}
            {state.enabled_bill_state && (
                <div className="column-list">
                    <SelectBillState
                        billState={state.bill_state}
                        setBillState={bill_state => onSelectSearch('bill_state', bill_state)}
                    />
                </div>
            )}
            {/* -------------------- PAYMENT RECIPIENT ASSOCIATION -------------------- */}
            {state.enabled_payment_recipient_association && (
                <div className="column-list">
                    <SelectFederatedAssociation
                        federatedAssociationId={state.payment_recipient_association}
                        setFederatedAssociationId={payment_recipient_association => onSelectSearch('payment_recipient_association', payment_recipient_association)}
                    />
                </div>
            )}
            {/* -------------------- PAYMENT CREATOR -------------------- */}
            {state.enabled_payment_creator && (
                <div className="column-list">
                    <InputText
                        label="Créateur du paiement"
                        value={state.payment_creator}
                        onChange={e => setState({ ...state, payment_creator: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
        </div>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- BILL LIST ------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const BillList = ({ state }) => {
    const match = useRouteMatch();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    function openBill(billId) {
        let target = state.new_tab_checked ? "_blank" : "_self";
        window.open(`${match.url}/${billId}`, target);
    }

    return (
        <>
            {state.bills.length > 0 && (
                state.bills.map(bill => (
                    <Fragment key={bill.id}>
                        <div
                            onClick={() => openBill(bill.id)}
                            className="simple-list"
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- STATUS -------------------- */}
                            {state.enabled_status && (
                                <div className="column-list">
                                    <b className="title-status-list" style={{ color: getColorStatus(billStatuses, bill.status) }}>
                                        {getLabelStatus(billStatuses, bill.status)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- CLIENT CODE -------------------- */}
                            {state.enabled_client_code && (
                                <div className="column-list">
                                    {bill.user ? bill.user.clientCode : ''}
                                </div>
                            )}
                            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                            {state.enabled_external_client_code && (
                                <div className="column-list">
                                    {bill.user ? bill.user.external_client_code : ''}
                                </div>
                            )}
                            {/* -------------------- LAST NAME -------------------- */}
                            {state.enabled_last_name && (
                                <div className="column-list">
                                    {bill.user ? bill.user.last_name : ''}
                                </div>
                            )}
                            {/* -------------------- FIRST NAME -------------------- */}
                            {state.enabled_first_name && (
                                <div className="column-list">
                                    {bill.user ? bill.user.first_name : ''}
                                </div>
                            )}
                            {/* -------------------- COMPANY NAME -------------------- */}
                            {state.enabled_company_name && (
                                <div className="column-list">
                                    {bill.user ? bill.user.company_name : ''}
                                </div>
                            )}
                            {/* -------------------- REFERENCE -------------------- */}
                            {state.enabled_reference && (
                                <div className="column-list">
                                    {bill.reference}
                                </div>
                            )}
                            {/* -------------------- TOTAL AMOUNT -------------------- */}
                            {state.enabled_total_amount && (
                                <div className="column-list">
                                    {getAmountTTC(bill.total_amount)}
                                </div>
                            )}
                            {/* -------------------- REST -------------------- */}
                            {state.enabled_rest && (
                                <div className="column-list">
                                    {getAmountTTC(bill.leftToPay)}
                                </div>
                            )}
                            {/* -------------------- EXTERNAL REFERENCE -------------------- */}
                            {state.enabled_external_reference && (
                                <div className="column-list">
                                    {bill.external_reference}
                                </div>
                            )}
                            {/* -------------------- PDF BILL -------------------- */}
                            {state.enabled_pdf_bill && (
                                <div className="column-list">
                                    {bill.document_url ? 'Oui' : 'Non'}
                                </div>
                            )}
                            {/* -------------------- PDF CREDIT -------------------- */}
                            {state.enabled_pdf_credit && (
                                <div className="column-list">
                                    {bill.credit_document_url ? 'Oui' : 'Non'}
                                </div>
                            )}
                            {/* -------------------- EMAIL -------------------- */}
                            {state.enabled_email && (
                                <div className="column-list">
                                    {bill.user ? bill.user.email : ''}
                                </div>
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <div className="column-list">
                                    {bill.user ? bill.user.mobile_phone : ''}
                                </div>
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <div className="column-list">
                                    {bill.user ? bill.user.phone : ''}
                                </div>
                            )}
                            {/* -------------------- ADDRESS -------------------- */}
                            {state.enabled_address && (
                                <div className="column-list">
                                    {bill.address}
                                </div>
                            )}
                            {/* -------------------- ADDRESS DETAILS -------------------- */}
                            {state.enabled_address_details && (
                                <div className="column-list">
                                    {bill.address_details}
                                </div>
                            )}
                            {/* -------------------- COUNTRY -------------------- */}
                            {state.enabled_country && (
                                <div className="column-list">
                                    {bill.country ? bill.country.name : ''}
                                </div>
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <div className="column-list">
                                    {bill.postal_code}
                                </div>
                            )}
                            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                            {state.enabled_federated_association && (
                                <div className="column-list">
                                    {(bill.user && bill.user.federatedAssociation) ? bill.user.federatedAssociation.name : ''}
                                </div>
                            )}
                            {/* -------------------- PAYMENT METHOD -------------------- */}
                            {state.enabled_payment_method && (
                                <div className="column-list">
                                    {bill.paymentMethods ? bill.paymentMethods.map((m, index) => {
                                        if (index === 0) return getLabelStatus(paymentMethods, m);
                                        return ', ' + getLabelStatus(paymentMethods, m);
                                    }) : ''}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                            {state.enabled_membership_email && (
                                <div className="column-list">
                                    {bill.user ? bill.user.membershipEmail : ''}
                                </div>
                            )}
                            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
                            {state.enabled_subscription_email && (
                                <div className="column-list">
                                    {bill.user ? bill.user.subscriptionEmail : ''}
                                </div>
                            )}
                            {/* -------------------- BILL EMAIL -------------------- */}
                            {state.enabled_bill_email && (
                                <div className="column-list">
                                    {bill.user ? bill.user.billEmail : ''}
                                </div>
                            )}
                            {/* -------------------- CREATED AT -------------------- */}
                            {state.enabled_created_at && (
                                <div className="column-list">
                                    {getFormattedCreatedAt(bill.created_at)}
                                </div>
                            )}
                            {/* -------------------- BILL STATE -------------------- */}
                            {state.enabled_bill_state && (
                                <div className="column-list">
                                    {getLabelStatus(billStates, bill.bill_state)}
                                </div>
                            )}
                            {/* -------------------- PAYMENT RECIPIENT ASSOCIATION -------------------- */}
                            {state.enabled_payment_recipient_association && (
                                <div className="column-list">
                                    {bill.paymentRecipient?.name}
                                </div>
                            )}
                            {/* -------------------- PAYMENT CREATOR -------------------- */}
                            {state.enabled_payment_creator && (
                                <div className="column-list">
                                    {bill.paymentCreators.map((creator, index) => {
                                        if (index === 0) return creator;
                                        return ', ' + creator;
                                    })}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ MANAGE COLUMNS ----------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_status: state.enabled_status,
            enabled_client_code: state.enabled_client_code,
            enabled_external_client_code: state.enabled_external_client_code,
            enabled_last_name: state.enabled_last_name,
            enabled_first_name: state.enabled_first_name,
            enabled_company_name: state.enabled_company_name,
            enabled_reference: state.enabled_reference,
            enabled_total_amount: state.enabled_total_amount,
            enabled_rest: state.enabled_rest,
            enabled_external_reference: state.enabled_external_reference,
            enabled_pdf_bill: state.enabled_pdf_bill,
            enabled_pdf_credit: state.enabled_pdf_credit,
            enabled_email: state.enabled_email,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_phone: state.enabled_phone,
            enabled_address: state.enabled_address,
            enabled_address_details: state.enabled_address_details,
            enabled_country: state.enabled_country,
            enabled_postal_code: state.enabled_postal_code,
            enabled_federated_association: state.enabled_federated_association,
            enabled_payment_method: state.enabled_payment_method,
            enabled_membership_email: state.enabled_membership_email,
            enabled_subscription_email: state.enabled_subscription_email,
            enabled_bill_email: state.enabled_bill_email,
            enabled_created_at: state.enabled_created_at,
            enabled_bill_state: state.enabled_bill_state,
            enabled_payment_recipient_association: state.enabled_payment_recipient_association,
            enabled_payment_creator: state.enabled_payment_creator,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'bills',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Gérer les colonnes affichées
                </p>
                <div style={{ display: 'flex' }}>
                    <div>
                        {/* -------------------- STATUS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_status}
                                onChange={e => setState({ ...state, enabled_status: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Statut bon de commande
                        </Grid>
                        {/* -------------------- CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_client_code}
                                onChange={e => setState({ ...state, enabled_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client
                        </Grid>
                        {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_external_client_code}
                                onChange={e => setState({ ...state, enabled_external_client_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code client externe
                        </Grid>
                        {/* -------------------- LAST NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_last_name}
                                onChange={e => setState({ ...state, enabled_last_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom
                        </Grid>
                        {/* -------------------- FIRST NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_first_name}
                                onChange={e => setState({ ...state, enabled_first_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Prénom
                        </Grid>
                        {/* -------------------- COMPANY NAME -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_company_name}
                                onChange={e => setState({ ...state, enabled_company_name: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Nom de l'entreprise
                        </Grid>
                        {/* -------------------- REFERENCE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_reference}
                                onChange={e => setState({ ...state, enabled_reference: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Réf. bon de commande
                        </Grid>
                        {/* -------------------- TOTAL AMOUNT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_total_amount}
                                onChange={e => setState({ ...state, enabled_total_amount: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Montant total TTC
                        </Grid>
                        {/* -------------------- REST -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_rest}
                                onChange={e => setState({ ...state, enabled_rest: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Restant dû
                        </Grid>
                        {/* -------------------- EXTERNAL REFERENCE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_external_reference}
                                onChange={e => setState({ ...state, enabled_external_reference: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Réf. externe
                        </Grid>
                        {/* -------------------- PDF BILL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_pdf_bill}
                                onChange={e => setState({ ...state, enabled_pdf_bill: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Facture PDF
                        </Grid>
                        {/* -------------------- PDF CREDIT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_pdf_credit}
                                onChange={e => setState({ ...state, enabled_pdf_credit: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Avoir PDF
                        </Grid>
                        {/* -------------------- EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_email}
                                onChange={e => setState({ ...state, enabled_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email compte
                        </Grid>
                        {/* -------------------- MOBILE PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_mobile_phone}
                                onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone portable
                        </Grid>
                    </div>
                    <div>
                        {/* -------------------- PHONE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_phone}
                                onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Téléphone fixe
                        </Grid>
                        {/* -------------------- ADDRESS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address}
                                onChange={e => setState({ ...state, enabled_address: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse FACT (n° et voie)
                        </Grid>
                        {/* -------------------- ADDRESS DETAILS -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_address_details}
                                onChange={e => setState({ ...state, enabled_address_details: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Adresse FACT (complément)
                        </Grid>
                        {/* -------------------- COUNTRY -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_country}
                                onChange={e => setState({ ...state, enabled_country: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Pays
                        </Grid>
                        {/* -------------------- POSTAL CODE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_postal_code}
                                onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Code postal
                        </Grid>
                        {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_federated_association}
                                onChange={e => setState({ ...state, enabled_federated_association: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Association fédérée
                        </Grid>
                        {/* -------------------- PAYMENT METHOD -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_payment_method}
                                onChange={e => setState({ ...state, enabled_payment_method: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Mode de paiement
                        </Grid>
                        {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_membership_email}
                                onChange={e => setState({ ...state, enabled_membership_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email adhésion
                        </Grid>
                        {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_subscription_email}
                                onChange={e => setState({ ...state, enabled_subscription_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email abonnement
                        </Grid>
                        {/* -------------------- BILL EMAIL -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_bill_email}
                                onChange={e => setState({ ...state, enabled_bill_email: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Email bon de commande
                        </Grid>
                        {/* -------------------- CREATED AT -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_created_at}
                                onChange={e => setState({ ...state, enabled_created_at: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Date BC
                        </Grid>
                        {/* -------------------- BILL STATE -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_bill_state}
                                onChange={e => setState({ ...state, enabled_bill_state: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Etat BC
                        </Grid>
                        {/* -------------------- PAYMENT RECIPIENT ASSOCIATION -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_payment_recipient_association}
                                onChange={e => setState({ ...state, enabled_payment_recipient_association: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Association destinataire du paiement
                        </Grid>
                        {/* -------------------- PAYMENT CREATOR -------------------- */}
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={state.enabled_payment_creator}
                                onChange={e => setState({ ...state, enabled_payment_creator: e.target.checked })}
                                style={{ padding: 6 }}
                            />
                            Créateur du paiement
                        </Grid>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                    Fermer
                </Button>
                <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BillRouter;
