import React, { useEffect, useState } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button, CircularProgress } from '@material-ui/core';

// Services
import { updateFormationRequestComment } from 'services/requests';

// Components
import InputText from 'components/input-text';

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ FORMATION REQUEST COMMENT -------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationRequestComment = ({ onClose, refetch, formationRequestId, commentType, commentContent }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        comment: commentContent || '',
    });

    useEffect(() => {
        if (state.loading) {
            updateFormationRequestComment(formationRequestId, {
                comment_type: commentType,
                comment_content: state.comment,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    console.log(res);
                    enqueueSnackbar(res, { variant: 'error' });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Commentaire {commentType === 'payment_comment' ? 'paiement' : 'responsable'}
                </p>
                <InputText
                    label="Commentaire"
                    value={state.comment}
                    onChange={e => setState({ ...state, comment: e.target.value })}
                    multiline
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant="contained">
                    Fermer
                </Button>
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                >
                    {state.loading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        'Enregistrer'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormationRequestComment;
