import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';

// Services
import { getDomain, deleteDomain, updateDomain } from 'services/requests';
import { userCanDeleteDomain } from 'services/permissions';
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/input-text';
import Spinner from 'components/spinner';
import Error from 'components/error';
import FieldRequired from 'components/field-required';

// Views
import Subdomains from './domain-attributs/domain-subdomain';
import Users from './domain-attributs/domain-users';
import Managers from './domain-attributs/domain-managers';
import Contacts from './domain-attributs/domain-contacts';

// Messages
import messages from 'messages.json';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- UPDATE DOMAIN ---------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const UpdateDomain = () => {
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const match = useRouteMatch();

    // Params
    const backUrl = match.path.split('/:domainId')[0];
    const domainId = match.params.domainId;

    const [state, setState] = useState({
        loading: true,
        error: false,
        domain: null,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getDomain(domainId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, domain: res.data.data.domain });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button
                        onClick={() => history.push(`${backUrl}/${domainId}`)}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    <div style={{ textAlign: 'center' }}>
                        <b>Modifier le {state.domain.root_domain_id == null ? 'domaine' : 'sous-domaine'}</b>
                        {state.domain.root_domain_id != null && (
                            <>
                                <br />
                                Sous-domaine du {state.domain.rootDomain.name}
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanDeleteDomain(domainId) && (
                        <Button
                            onClick={() => setState({ ...state, openDelete: true })}
                            variant="contained"
                        >
                            Supprimer
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- UPDATE -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <SetDomain domain={state.domain} />
            </Grid>
            <Divider />
            {/* -------------------- MENU -------------------- */}
            <Menu domain={state.domain} />
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <DeleteDomain
                    domain={state.domain}
                    onClose={() => setState({ ...state, openDelete: false })}
                />
            )}
        </>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------------- MENU -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const Menu = ({ domain }) => {
    const [menu, setMenu] = useState(domain.root_domain_id == null ? 1 : 2);

    function handleMenuSelected(selectedMenu) {
        if (selectedMenu === menu) {
            return 'domains-menu-item domains-menu-item-selected';
        }
        return 'domains-menu-item';
    }

    return (
        <Grid container style={{ padding: 24, flex: 1 }}>
            <Grid container style={{ border: '1px solid lightgray', display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Grid container className="domains-menu">
                    {domain.root_domain_id == null && (
                        <Grid
                            item xs="auto"
                            className={handleMenuSelected(1)}
                            onClick={() => setMenu(1)}
                        >
                            Sous-domaines
                        </Grid>
                    )}
                    <Grid
                        item xs="auto"
                        className={handleMenuSelected(2)}
                        onClick={() => setMenu(2)}
                    >
                        Membres
                    </Grid>
                    <Grid
                        item xs="auto"
                        className={handleMenuSelected(3)}
                        onClick={() => setMenu(3)}
                    >
                        Gestionnaires
                    </Grid>
                    <Grid
                        item xs="auto"
                        className={handleMenuSelected(4)}
                        onClick={() => setMenu(4)}
                    >
                        Contacts
                    </Grid>
                </Grid>
                {/* -------------------- CONTACT -------------------- */}
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {(domain.root_domain_id == null && menu === 1) && <Subdomains domain={domain} />}
                    {menu === 2 && <Users domain={domain} />}
                    {menu === 3 && <Managers domain={domain} />}
                    {menu === 4 && <Contacts domain={domain} />}
                </div>
            </Grid>
        </Grid>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------- DELETE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const DeleteDomain = ({ domain, onClose }) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { domainId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteDomain(domainId).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/domains');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {domain.name}
                        </p>
                        Êtes-vous sûr de vouloir supprimer ce {domain.root_domain_id == null ? 'domaine' : 'sous-domaine'} ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------------- UPDATE ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SetDomain = ({ domain }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { domainId } = useParams();

    const [state, setState] = useState({
        loading: false,
        error: false,
        name: domain.name || '',
    });

    function onSave() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateDomain(domainId, { name: state.name }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loading: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);
    
    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <Grid item xs={12} md={10} lg={8}>
                {/* -------------------- NAME -------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <span>Nom du {domain.root_domain_id == null ? 'domaine' : 'sous-domaine'}</span><FieldRequired />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Nom du domaine"
                            type="text"
                            value={state.name}
                            onChange={e => setState({ ...state, name: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* -------------------- SAVE BUTTON -------------------- */}
            <Grid item xs={12} md={2} lg={4} container alignItems="center" justify="flex-end">
                <Button onClick={onSave} variant="contained">
                    Enregistrer
                </Button>
            </Grid>
        </>
    );
};

export default UpdateDomain;
