import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Messages
import messages from 'messages.json';

// ---------------------------------------------------------------------------------------- \\
// ---------------------------------------- VERIFY ---------------------------------------- \\
// ---------------------------------------------------------------------------------------- \\

const Verify = () => {
    const history = useHistory();
    const { search } = useLocation();

    return (
        <div className="verify">
            <Grid container>
                <Grid
                    item xs={false} md={4}
                />
                <Grid
                    item xs={12} md={4}
                    style={{ textAlign: 'center' }}
                >
                    <Grid style={{ padding: 24 }}>
                        <span style={{ color: 'white', fontSize: 16 }}>
                            {(new URLSearchParams(search).get('confirmed') === 'true')
                                ? messages['email.checked']
                                : messages['link.invalid']
                            }
                        </span>
                    </Grid>
                    <Button
                        onClick={() => history.push('/login')}
                        variant="contained"
                    >
                        Aller à la page d'accueil
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default Verify;
