import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';

// Services
import { updateContactFromFederationAssociation } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// Messages
import messages from 'messages.json';

// Views
import ContactForm from '../contact-form';

// ---------------------------------------------------------------------------------------------- \\
// --------------------------------------- UPDATE CONTACT --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

export default function UpdateContact({ onClose, refetch, contact }) {
    const { enqueueSnackbar } = useSnackbar();
    const { federatedAssociationId } = useParams();

    const [state, setState] = useState({
        loading: false,
        name: contact.name,
        type: contact.type || '',
        email: contact.email || '',
        phone: contact.phone || '',
        description: contact.description || '',
    });

    function onUpdate() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateContactFromFederationAssociation(federatedAssociationId, contact.id, {
                name: state.name,
                type: state.type,
                email: state.email,
                phone: state.phone,
                description: state.description,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.contact);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Modifier un contact
                        </p>
                        <ContactForm state={state} setState={setState} />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={onUpdate} variant="contained">
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
