import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button } from '@material-ui/core';

// Services
import { stopSubscription } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// ----------------------------------------------------------------------------------------------- \\
// --------------------------------------- STOP SUBSCRIPTION ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const StopSubscription = ({ onClose, refetch, subscription }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            stopSubscription(subscription.id, {
                stop: subscription.status === 'stopped' ? false : true,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        {(subscription.status === 'validated' || subscription.status === 'expired') && (
                            <>
                                <p className="dialog-title">
                                    Stopper l'abonnement
                                </p>
                                Êtes-vous sûr de vouloir stopper l'abonnement de {subscription.last_name ? (subscription.first_name + ' ' + subscription.last_name) : subscription.company_name} ?
                            </>
                        )}
                        {subscription.status === 'stopped' && (
                            <>
                                <p className="dialog-title">
                                    Reprendre l'abonnement
                                </p>
                                Êtes-vous sûr de vouloir reprendre l'abonnement de {subscription.last_name ? (subscription.first_name + ' ' + subscription.last_name) : subscription.company_name} ?
                            </>
                        )}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default StopSubscription;
