import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';

// Services
import { deleteUser } from 'services/requests';

// ------------------------------------------------------------------------------------------ \\
// ------------------------------------- DELETE USER ---------------------------------------- \\
// ------------------------------------------------------------------------------------------ \\

const DeleteUser = ({ onClose, user }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    // Params
    const backUrl = match.path.split('/:userId')[0];

    useEffect(() => {
        if (loading) {
            deleteUser(user.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(backUrl);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    {user.first_name} {user.last_name}
                </p>
                <span>
                    Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                </span>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    variant="contained"
                >
                    Non
                </Button>
                <Button
                    onClick={() => setLoading(true)}
                    variant="contained"
                >
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteUser;
