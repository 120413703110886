import React, { useState, useEffect, useRef } from 'react';
import ReactSelect from 'react-select';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Services
import { searchUsers } from 'services/requests';

// ---------------------------------------------------------------------------------------------- \\
// ----------------------------------- SELECT AND SEARCH USERS ---------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SearchAndSelectUsers = ({ users, setUsers, placeholder, userCategoryId }) => {
    const [state, setState] = useState({
        loading: false,
        inputValue: '',
        options: [],
    });

    // Effectue une recherche seulement tous les 500ms et non à chaque caractère saisi
    const onSearch = value => {
        loadUsers(value);
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    // Effectue une recherche seulement à partir de 3 caractères saisis
    function handleDelayedOnSearch(inputValue) {
        if (inputValue && inputValue.length >= 3) {
            setState({ ...state, loading: true, inputValue });
        }
    }

    function loadUsers(inputValue) {
        searchUsers({ search: inputValue, user_category_id: userCategoryId }).then(res => {
            if (res.status === 200) {
                setState({
                    ...state,
                    loading: false,
                    options: res.data.data.users.map(u => ({ label: u.fullName + ' (' + u.clientCode + ')', value: u.id })),
                });
            }
        });
    };

    useEffect(() => {
        if (state.loading) {
            delayedOnSearch(state.inputValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <ReactSelect
            value={users}
            onChange={value => setUsers(value)}
            onInputChange={inputValue => handleDelayedOnSearch(inputValue)}
            options={state.options}
            isLoading={state.loading}
            placeholder={placeholder}
            isMulti
            noOptionsMessage={() => 'Aucun'}
            isClearable={false}
            maxMenuHeight={200}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                container: base => ({ ...base, minWidth: 200, width: '100%' })
            }}
        />
    );
};

// Propriétés par défaut
SearchAndSelectUsers.defaultProps = {
    placeholder: 'Chercher...',
    userCategoryId: null,
};

export default SearchAndSelectUsers;
