import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, CircularProgress, ButtonGroup } from '@material-ui/core';
import { Check as IconCheck, Clear as IconClear } from '@material-ui/icons';

// Services
import { getFormationParticipationsForSession, setParticipations } from 'services/requests';
import useMediaQueries from 'services/media';
import { getFormattedTime, getFormattedDate } from 'services/helpers';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Scrollbar from 'components/scrollbar';

// Views
import GenerateTrainingCertificates from './generate-training-certificates';
import DownloadTrainingCertificate from './download-training-certificate';

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------- FORMATION PARTICIPATIONS ----------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const FormationParticipations = () => {
    const history = useHistory();
    const { sessionId } = useParams();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        dayPrograms: [],
        users: [],
        formationParticipations: [],
    });

    useEffect(() => {
        if (state.loading) {
            getFormationParticipationsForSession(sessionId).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        dayPrograms: res.data.data.dayPrograms,
                        users: res.data.data.users,
                        formationParticipations: res.data.data.formationParticipations,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button variant="contained" onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    Valider la présence des participants
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    <Submit
                        formationParticipations={state.formationParticipations}
                    />
                </Grid>
            </Grid>
            {/* -------------------- SET PRESENCE -------------------- */}
            <Grid style={{ padding: 24 }}>
                {state.formationParticipations.length > 0 && (
                    <Scrollbar>
                        <SetPresence
                            state={state}
                            setState={setState}
                        />
                    </Scrollbar>
                )}
            </Grid>
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ---------------------------------------- SET PRESENCE ----------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const SetPresence = ({ state, setState }) => {
    const { sessionId } = useParams();

    function isPresent(user, dayProgram) {
        const formationParticipation = state.formationParticipations.find(participation =>
            participation.user_id === user.id
            && participation.day_program_id === dayProgram.id
            && participation.formation_session_id === Number(sessionId)
        );

        if (formationParticipation && formationParticipation.present === 1) {
            return true;
        }
        return false;
    }

    function onChange(user, dayProgram, value) {
        const formationParticipations = state.formationParticipations;

        const formationParticipation = formationParticipations.find(participation =>
            participation.user_id === user.id
            && participation.day_program_id === dayProgram.id
            && participation.formation_session_id === Number(sessionId)
        );

        if (formationParticipation) {
            formationParticipation.present = value;
        }

        setState({ ...state, formationParticipations });
    }

    function onValidateDayProgram(dayProgram, value) {
        let updatedParticipations = state.formationParticipations;
        updatedParticipations.filter(p => p.day_program_id === dayProgram.id).forEach(p => {
            p.present = value;
        });
        setState({ ...state, formationParticipations: updatedParticipations });
    }

    function onValidateUser(user, value) {
        let updatedParticipations = state.formationParticipations;
        updatedParticipations.filter(p => p.user_id === user.id).forEach(p => {
            p.present = value;
        });
        setState({ ...state, formationParticipations: updatedParticipations });
    }

    function getFormationRequest(user) {
        var formationRequest = user.formationRequest;
        return Object.assign({}, formationRequest, { user: user });
    };

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <table style={{ marginBottom: 12 }}>
            {/* -------------------- DAY PROGRAM TITLE -------------------- */}
            <thead>
                <tr>
                    <td
                        colSpan={state.dayPrograms.length > 1 ? 3 : 2}
                        style={{ textAlign: 'center', padding: 12, border: '1px solid lightgray' }}
                    >
                        <GenerateTrainingCertificates
                            formationSessionId={sessionId}
                            refetch={() => setState({ ...state, loading: true })}
                        />
                    </td>
                    <td
                        colSpan={state.dayPrograms.length}
                        style={{ textAlign: 'center', padding: 12, border: '1px solid lightgray' }}
                    >
                        <b>JOURNÉES DE FORMATION</b>
                    </td>
                </tr>
            </thead>
            {/* -------------------- USERS TITLE & DAY PROGRAMS -------------------- */}
            <tbody>
                <tr>
                    <td className="users-td">
                        <b>PARTICIPANTS</b>
                    </td>
                    <td className="day-programs-td">
                        <b>ATTESTATIONS</b>
                    </td>
                    {state.dayPrograms.length > 1 && (
                        <td className="day-programs-td" />
                    )}
                    {state.dayPrograms.map(dayProgram => (
                        <td key={dayProgram.id} className="day-programs-td">
                            <b>{getFormattedDate(dayProgram.session_date)}</b>
                            <br />
                            {getFormattedTime(dayProgram.start_time)} - {getFormattedTime(dayProgram.end_time)}
                        </td>
                    ))}
                </tr>
            </tbody>
            {/* -------------------- DAY PROGRAM SHORTCUTS -------------------- */}
            {state.users.length > 1 && (
                <tbody>
                    <tr>
                        <td className="day-programs-td" />
                        <td className="day-programs-td" />
                        {state.dayPrograms.length > 1 && (
                            <td className="day-programs-td" />
                        )}
                        {state.dayPrograms.map(dayProgram => (
                            <td className="day-programs-td" key={dayProgram.id}>
                                <ButtonGroup size="small">
                                    <Button onClick={() => onValidateDayProgram(dayProgram, 1)}>
                                        <IconCheck />
                                    </Button>
                                    <Button onClick={() => onValidateDayProgram(dayProgram, 0)}>
                                        <IconClear />
                                    </Button>
                                </ButtonGroup>
                            </td>
                        ))}
                    </tr>
                </tbody>
            )}
            {/* -------------------- USERS & FORMATION PARTICIPATIONS -------------------- */}
            <tbody>
                {state.users.map(user => (
                    <tr key={user.id}>
                        <td className="users-td">
                            {user.fullName}
                        </td>
                        <td className="day-programs-td">
                            <DownloadTrainingCertificate
                                formationRequest={getFormationRequest(user)}
                            />
                        </td>
                        {state.dayPrograms.length > 1 && (
                            <td className="day-programs-td">
                                <ButtonGroup size="small">
                                    <Button onClick={() => onValidateUser(user, 1)}>
                                        <IconCheck />
                                    </Button>
                                    <Button onClick={() => onValidateUser(user, 0)}>
                                        <IconClear />
                                    </Button>
                                </ButtonGroup>
                            </td>
                        )}
                        {state.dayPrograms.map(dayProgram => (
                            <td key={dayProgram.id} className="day-programs-td">
                                {isPresent(user, dayProgram) ? (
                                    <Button
                                        onClick={() => onChange(user, dayProgram, 0)}
                                        variant="contained"
                                        style={{ backgroundColor: '#3E896B', color: 'white' }}
                                    >
                                        Présent
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => onChange(user, dayProgram, 1)}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Absent
                                    </Button>
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SUBMIT -------------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const Submit = ({ formationParticipations }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { sessionId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            setParticipations(sessionId, { formationParticipations }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                }
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Button
            onClick={() => setLoading(true)}
            variant="contained"
            color="primary"
        >
            {loading ? (
                <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
                'Enregistrer'
            )}
        </Button>
    );
};

export default FormationParticipations;
