import React, { useState, useEffect } from 'react';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button } from '@material-ui/core';

// Services
import { deleteBill } from 'services/requests';

// Components
import Spinner from 'components/spinner';

// -------------------------------------------------------------------------------------- \\
// ------------------------------------ DELETE BILL ------------------------------------- \\
// -------------------------------------------------------------------------------------- \\

const DeleteBill = ({ bill, onClose, refetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [cancelOrder, setCancelOrder] = useState(false);

    useEffect(() => {
        if (cancelOrder) {
            deleteBill(bill.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setCancelOrder(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cancelOrder]);

    return (
        <Dialog
            open
        >
            {cancelOrder ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            commande : {bill.reference}
                        </p>
                        <span>
                            Êtes-vous sûr de vouloir annuler cette commande ?
                        </span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            Non
                        </Button>
                        <Button
                            onClick={() => setCancelOrder(true)}
                            variant="contained"
                        >
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default DeleteBill;
