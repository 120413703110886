import React, { useState, useEffect } from 'react';

// Axios
import axios from 'axios';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services
import { updateTrainingPlace, deleteTrainingPlace } from 'services/requests';
import { userCanDeleteTrainingPlace } from 'services/permissions';
import { getGeocode } from 'services/helpers';

// Components
import Spinner from 'components/spinner';

// Views
import TrainingPlaceForm from './training-places-form';

// Messages
import messages from 'messages.json';

// ----------------------------------------------------------------------------------------------- \\
// ----------------------------------- UPDATE TRAINING PLACE ------------------------------------- \\
// ----------------------------------------------------------------------------------------------- \\

const UpdateTrainingPlace = ({ onClose, refetch, training_place }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: training_place.name,
        address: training_place.address || '',
        address_details: training_place.address_details || '',
        city: training_place.city || '',
        postal_code: training_place.postal_code || '',
        country_id: training_place.country_id || 1,
        french_department_id: training_place.french_department_id || -1,
        geocode: getGeocode(training_place),
        longitude: training_place.longitude || '',
        latitude: training_place.latitude || '',
        phone: training_place.phone || '',
        mobile_phone: training_place.mobile_phone || '',
        other_phone: training_place.other_phone || '',
        email: training_place.email || '',
        other_email: training_place.other_email || '',
        contact: training_place.contact || '',
        openDelete: false,
    });

    function onRefetch() {
        refetch();
        setState({ ...state, openDelete: false });
    }

    function onUpdate() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            if ((state.latitude === '' || state.longitude === '')
                && state.country_id === 1
                && state.address !== ''
                && state.postal_code !== ''
                && state.city !== ''
            ) {
                getCoordinates(state.address, state.postal_code, state.city);
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    }

    function getCoordinates(address, postal_code, city) {
        axios.get(
            `https://api-adresse.data.gouv.fr/search/?q=${address}
                &postcode=${postal_code}
                &city=${city}
                &limit=20`
        )
        .then(res => {
            if (res.status === 200) {
                const features = res.data.features;
                if (features && features.length > 0) {
                    setState({
                        ...state,
                        loading: true,
                        longitude: features[0].geometry.coordinates[0],
                        latitude: features[0].geometry.coordinates[1],
                    });
                }
                else {
                    setState({ ...state, loading: true });
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: true });
            }
        });
    }

    useEffect(() => {
        if (state.loading) {
            updateTrainingPlace(training_place.id, {
                name: state.name,
                address: state.address,
                address_details: state.address_details,
                city: state.city,
                postal_code: state.postal_code,
                country_id: state.country_id !== -1 ? state.country_id : null,
                french_department_id: state.french_department_id !== -1 ? state.french_department_id : null,
                longitude: state.longitude,
                latitude: state.latitude,
                phone: state.phone,
                mobile_phone: state.mobile_phone,
                other_phone: state.other_phone,
                email: state.email,
                other_email: state.other_email,
                contact: state.contact,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Dialog open fullWidth>
                {state.loading ? (
                    <Spinner />
                ) : (
                    <>
                        <DialogContent style={{ paddingBottom: 20 }}>
                            <p className="dialog-title">
                                Modifier le lieu de formation
                            </p>
                            {/* -------------------- NAME -------------------- */}
                            <TrainingPlaceForm
                                state={state}
                                setState={setState}
                            />
                            {/* -------------------- DELETE -------------------- */}
                            {userCanDeleteTrainingPlace() && (
                                <>
                                    <Divider style={{ margin: '10px 0px' }} />
                                    <Grid container alignItems="center" style={{ marginTop: 5 }}>
                                        <Grid item xs={12} sm={4}>
                                            Supprimer ce lieu
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Button
                                                onClick={() => setState({ ...state, openDelete: true })}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Supprimer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </DialogContent>
                        <Divider />
                        <DialogActions style={{ justifyContent: 'space-between' }}>
                            <Button onClick={onClose} variant="contained">
                                Annuler
                            </Button>
                            <Button onClick={onUpdate} variant="contained">
                                Enregistrer
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <DeleteTrainingPlace
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => onRefetch()}
                    training_place={training_place}
                />
            )}
        </>
    );
};

// ----------------------------------------------------------------------------------------------- \\
// ------------------------------------ DELETE TRAINING PLACE ------------------------------------ \\
// ----------------------------------------------------------------------------------------------- \\

const DeleteTrainingPlace = ({ onClose, refetch, training_place }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteTrainingPlace(training_place.id).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {training_place.name}
                        </p>
                        Êtes-vous sûr de vouloir supprimer ce lieu de formation ?
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            Non
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            Oui
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateTrainingPlace;
