import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 150;

// -------------------- HEADER -------------------- \\
const ExternalClientCodeHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Code client externe
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const ExternalClientCodeFilter = ({ state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label="Code client externe"
                value={state.external_client_code}
                onChange={e => setState({ ...state, external_client_code: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const ExternalClientCodeValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { ExternalClientCodeHeader, ExternalClientCodeFilter, ExternalClientCodeValue };
