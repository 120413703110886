import axios from 'axios';
import { getToken, clearToken } from './storage';

/* * *
* IMPORTANT : À COMMENTER / DÉCOMMENTER en fonction de la version voulue
* Utiliser les URLs de production en développement pourrait engendrer des modifications de données
* non voulues dans la base de données de production.
*/

// ------------------------------------------------------------------------- \\
// ------------------------------- API URL --------------------------------- \\
// ------------------------------------------------------------------------- \\

// --------------------------------- PROD ---------------------------------- \\
// const apiUrl = 'https://app.bio-dynamie.org/backend/public/api/';
// const fileUrl = 'https://app.bio-dynamie.org/backend/public/';
// const moneticoUrl = 'https://p.monetico-services.com/paiement.cgi';

// --------------------------------- PRE-PROD ---------------------------------- \\
const apiUrl = 'https://app-preprod.bio-dynamie.org/backend/public/api/';
const fileUrl = 'https://app-preprod.bio-dynamie.org/backend/public/';
const moneticoUrl = 'https://p.monetico-services.com/test/paiement.cgi';

// --------------------------------- BETA ---------------------------------- \\
// const apiUrl = 'https://beta.bio-dynamie.org/backend/public/api/';
// const fileUrl = 'https://beta.bio-dynamie.org/backend/public/';
// const moneticoUrl = 'https://p.monetico-services.com/test/paiement.cgi';

// ------------------------------- LOCAL HOST ------------------------------ \\
// const apiUrl = 'http://localhost:8000/api/';
// const fileUrl = 'http://localhost:8000/';
// const moneticoUrl = 'https://p.monetico-services.com/test/paiement.cgi';

export { fileUrl, moneticoUrl };

// ------------------------------------------------------------------------- \\
// ------------------------ AXIOS CONFIGURATION ---------------------------- \\
// ------------------------------------------------------------------------- \\

const axiosConfig = fileType => {

    const config = {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    };

    const token = getToken();
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    /* * 
    * Le Content-Type multipart/form-data permet l'envoie de fichier
    * (le fileType "file" doit alors être ajouté sur les requêtes envoyant un fichier)
    */

    if (fileType === 'file') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else {
        config.headers['Content-Type'] = 'application/json';
    }

    if (fileType === 'blob') {
        config.responseType = 'blob';
    }
    else {
        config.responseType = 'json';
    }

    return config;
};

// ------------------------------------------------------------------------- \\
// --------------------------- ERRORS MANAGEMENT --------------------------- \\
// ------------------------------------------------------------------------- \\

const errorsManagement = error => {

    let status = error?.response?.status;
    switch (status) {

        // Default
        case 400:
            return error?.response?.data?.error;

        // Unauthenticated
        case 401:
            if (error.response.data?.message?.includes('Unauthenticated')) {
                clearToken();
                window.location.reload();
                break;
            }
            return "Vous devez être authentifié pour effectuer cette action. " + error?.response?.data?.error;

        // Unauthorized
        case 403:
            return "Vous n'avez pas l'authorisation d'effectuer cette action. " + error?.response?.data?.error;

        // Not found
        case 404:
            return  "Élément introuvable. " + error?.response?.data?.error;

        case 422:
            let errors = '';
            Object.values(error.response.data.errors).forEach(value => {
                errors += value + ' ';
            });
            return error.response.data.message + ' ' + errors;

        default:
            return status + " : Une erreur est survenue.";
    };
};

// ------------------------------------------------------------------------- \\
// -------------------------- AXIOS REQUESTS ------------------------------- \\
// ------------------------------------------------------------------------- \\

export const Get = (url, fileType) => {
    return (
        axios.get(
            apiUrl + url,
            axiosConfig(fileType)
        )
        .then(res => {
            return res;
        })
        .catch(error => {
            return errorsManagement(error);
        })
    );
};

export const Post = (url, data, fileType) => {
    return (
        axios.post(
            apiUrl + url,
            data,
            axiosConfig(fileType)
        )
        .then(res => {
            return res;
        })
        .catch(error => {
            return errorsManagement(error);
        })
    );
};

export const Delete = url => {
    return (
        axios.delete(
            apiUrl + url,
            axiosConfig()
        )
        .then(res => {
            return res;
        })
        .catch(error => {
            return errorsManagement(error);
        })
    );
};
