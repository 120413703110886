import React, { useState, useEffect, Fragment } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button, Dialog, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import {
    Search as SearchIcon, DeleteOutline as TrashIcon,
    Cancel as CancelIcon, ViewWeek as ColumnIcon,
} from '@material-ui/icons';

// Services
import { getUsers, updateIndexPreferences, getIndexPreferences } from 'services/requests';
import { userCanCreateUser, userCanAccessToUserTrash, userCanMergeUsers } from 'services/permissions';
import { getStoredUser } from 'services/storage';
import { getLabelStatus, getColorStatus, getFormattedDate } from 'services/helpers';
import { membershipStatuses } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/spinner';
import Error from 'components/error';
import Pagination from 'components/pagination';
import Tooltip from 'components/tooltip';
import InputText from 'components/input-text';
import SelectCreatable from 'components/select-creatable';

// Selects
import SelectMembershipStatuses from 'selects/select-membership-statuses';
import SelectFederatedAssociations from 'selects/select-federated-associations';
import SelectRoles from 'selects/select-roles';
import SelectCountries from 'selects/select-countries';
import SelectUserCategories from 'selects/select-user-categories';
import SelectFrenchDepartments from 'selects/select-french-departments';

// Views
import User from './user';
import CreateUser from './users-create';
import TrashUsers from './users-trash';
import UsersExport from './users-export';
import MergeUsers from "./users-merge";

// ----------------------------------------------------------------------------------------- \\
// --------------------------------------- ROUTER ------------------------------------------ \\
// ----------------------------------------------------------------------------------------- \\

const UsersRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={match.path}>
                <Users />
            </Route>
            {userCanAccessToUserTrash() && (
                <Route exact path={`${match.path}/trash`}>
                    <TrashUsers />
                </Route>
            )}
            <Route path={`${match.path}/:userId`}>
                <User />
            </Route>
        </Switch>
    );
};

// ----------------------------------------------------------------------------------------- \\
// ---------------------------------------- USERS ------------------------------------------ \\
// ----------------------------------------------------------------------------------------- \\

const Users = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;
    const storedFilters = JSON.parse(sessionStorage.getItem('users-filters'));

    const [state, setState] = useState({
        loadingPreferences: true,
        loading: false,
        error: false,
        users: [],
        offset: 0,
        limit: 20,
        total_count: 0,
        user: null,
        openCreate: false,
        openMerge: false,

        // Columns
        loadingColumns: false,
        openColumns: false,
        enabled_membership_status: true,
        enabled_client_code: true,
        enabled_external_client_code: true,
        enabled_first_name: true,
        enabled_last_name: true,
        enabled_company_name: true,
        enabled_mobile_phone: true,
        enabled_email: true,
        enabled_french_department: true,
        enabled_role: true,
        enabled_federated_association: false,
        enabled_phone: false,
        enabled_other_phone: false,
        enabled_membership_email: false,
        enabled_country: false,
        enabled_user_category: false,
        enabled_birth_date: false,
        enabled_postal_code: false,
        enabled_membership_expiration: false,
        enabled_subscription_email: false,
        enabled_bill_email: false,
        enabled_subscription_expiration: false,

        // Filters
        categories: storedFilters?.categories || [],
        membership_statuses: storedFilters?.membership_statuses || [],
        client_code: storedFilters?.client_code || '',
        external_client_code: storedFilters?.external_client_code || '',
        first_name: storedFilters?.first_name || '',
        last_name: storedFilters?.last_name || '',
        company_name: storedFilters?.company_name || '',
        mobile_phone: storedFilters?.mobile_phone || '',
        email: storedFilters?.email || '',
        french_departments: storedFilters?.french_departments || [],
        roles: storedFilters?.roles || [],
        federated_associations: storedFilters?.federated_associations || [],
        phone: storedFilters?.phone || '',
        other_phone: storedFilters?.other_phone || '',
        membership_email: storedFilters?.membership_email || '',
        countries: storedFilters?.countries || [],
        user_categories: storedFilters?.user_categories || [],
        birth_date: storedFilters?.birth_date || '',
        postal_codes: storedFilters?.postal_codes || [],
        membership_expiration: storedFilters?.membership_expiration || '',
        subscription_email: storedFilters?.subscription_email || '',
        bill_email: storedFilters?.bill_email || '',
        subscription_expiration: storedFilters?.subscription_expiration || '',
    });

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        sessionStorage.removeItem('users-filters');
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            membership_statuses: [],
            client_code: '',
            external_client_code: '',
            first_name: '',
            last_name: '',
            company_name: '',
            mobile_phone: '',
            email: '',
            french_departments: [],
            roles: [],
            federated_associations: [],
            phone: '',
            other_phone: '',
            membership_email: '',
            countries: [],
            user_categories: [],
            birth_date: '',
            postal_codes: [],
            membership_expiration: '',
            subscription_email: '',
            bill_email: '',
            subscription_expiration: '',
        });
    }

    useEffect(() => {
        if (state.loadingPreferences) {
            getIndexPreferences(storedUserId, {
                name: 'users',
            }).then(res => {
                if (res.status === 200) {

                    const indexPreferences = res.data.data.indexPreferences;

                    if (indexPreferences != null && indexPreferences.length > 0) {
                        const columns = JSON.parse(indexPreferences[0].columns);
                        setState({
                            ...state,
                            loadingPreferences: false, 
                            loading: true,
                            enabled_membership_status: columns.enabled_membership_status,
                            enabled_client_code: columns.enabled_client_code,
                            enabled_external_client_code: columns.enabled_external_client_code,
                            enabled_first_name: columns.enabled_first_name,
                            enabled_last_name: columns.enabled_last_name,
                            enabled_company_name: columns.enabled_company_name,
                            enabled_mobile_phone: columns.enabled_mobile_phone,
                            enabled_email: columns.enabled_email,
                            enabled_french_department: columns.enabled_french_department,
                            enabled_role: columns.enabled_role,
                            enabled_federated_association: columns.enabled_federated_association,
                            enabled_phone: columns.enabled_phone,
                            enabled_other_phone: columns.enabled_other_phone,
                            enabled_membership_email: columns.enabled_membership_email,
                            enabled_country: columns.enabled_country,
                            enabled_user_category: columns.enabled_user_category,
                            enabled_birth_date: columns.enabled_birth_date,
                            enabled_postal_code: columns.enabled_postal_code,
                            enabled_membership_expiration: columns.enabled_membership_expiration,
                            enabled_subscription_email: columns.enabled_subscription_email,
                            enabled_bill_email: columns.enabled_bill_email,
                            enabled_subscription_expiration: columns.enabled_subscription_expiration,
                        });
                    }
                    else {
                        setState({ ...state, loadingPreferences: false, loading: true });
                    }

                }
                else {
                    console.log(res);
                    setState({ ...state, loadingPreferences: false, loading: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPreferences]);

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('users-filters', JSON.stringify({
                membership_statuses: state.membership_statuses,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                first_name: state.first_name,
                last_name: state.last_name,
                company_name: state.company_name,
                mobile_phone: state.mobile_phone,
                email: state.email,
                french_departments: state.french_departments,
                roles: state.roles,
                federated_associations: state.federated_associations,
                phone: state.phone,
                other_phone: state.other_phone,
                membership_email: state.membership_email,
                countries: state.countries,
                user_categories: state.user_categories,
                birth_date: state.birth_date,
                postal_codes: state.postal_codes,
                membership_expiration: state.membership_expiration,
                subscription_email: state.subscription_email,
                bill_email: state.bill_email,
                subscription_expiration: state.subscription_expiration,
            }));

            getUsers({
                offset: state.offset,
                limit: state.limit,
                membership_statuses: state.membership_statuses ? state.membership_statuses.map(s => s.value) : null,
                client_code: state.client_code,
                external_client_code: state.external_client_code,
                first_name: state.first_name,
                last_name: state.last_name,
                company_name: state.company_name,
                mobile_phone: state.mobile_phone,
                email: state.email,
                french_department_ids: state.french_departments ? state.french_departments.map(d => d.value) : null,
                role_ids: state.roles ? state.roles.map(r => r.value) : null,
                federated_association_ids: state.federated_associations ? state.federated_associations.map(a => a.value) : null,
                phone: state.phone,
                other_phone: state.other_phone,
                membership_email: state.membership_email,
                country_ids: state.countries ? state.countries.map(c => c.value) : null,
                user_category_ids: state.user_categories ? state.user_categories.map(c => c.value) : null,
                birth_date: state.birth_date,
                postal_codes: state.postal_codes ? state.postal_codes.map(c => c.value) : null,
                membership_expiration: state.membership_expiration,
                subscription_email: state.subscription_email,
                bill_email: state.bill_email,
                subscription_expiration: state.subscription_expiration,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        limit: res.data.data.limit,
                        total_count: res.data.data.totalCount,
                        users: res.data.data.users,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingPreferences) return <Spinner />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container justify="space-between" alignItems="center" className="subheader">
                Utilisateurs
                <div>
                    {userCanCreateUser() && (
                        <Button
                            onClick={() => setState({ ...state, openCreate: true })}
                            variant="contained"
                        >
                            Créer un utilisateur
                        </Button>
                    )}
                    {userCanMergeUsers() && (
                        <Button
                            onClick={() => setState({ ...state, openMerge: true })}
                            variant="contained"
                            style={{ marginLeft: 5 }}
                        >
                            Fusion
                        </Button>
                    )}
                    {userCanAccessToUserTrash() && (
                        <Tooltip title="Corbeille utilisateurs"
                            item={(
                                <Button
                                    onClick={() => history.push(`${match.url}/trash`)}
                                    variant="contained"
                                    style={{ marginLeft: 5 }}
                                >
                                    <TrashIcon />
                                </Button>
                            )}
                        />
                    )}
                </div>
            </Grid>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={8} container alignItems="center">
                    <Tooltip title="Chercher"
                        item={(
                            <Button
                                onClick={onSearch}
                                variant="contained"
                            >
                                <SearchIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Annuler la recherche"
                        item={(
                            <Button
                                onClick={cancelSearch}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <CancelIcon />
                            </Button>
                        )}
                    />
                    <Tooltip title="Gérer les colonnes"
                        item={(
                            <Button
                                onClick={() => setState({ ...state, openColumns: true })}
                                variant="contained"
                                style={{ marginLeft: 5 }}
                            >
                                <ColumnIcon />
                            </Button>
                        )}
                    />
                    <UsersExport
                        data={state}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-end' : 'flex-start'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <b>
                        {state.total_count} {state.total_count > 1 ? 'Résultats' : 'Résultat'}
                    </b>
                </Grid>
            </Grid>
            <div className="main-container" style={{ paddingBottom: 24 }}>
                <div className="main-container-item">
                    <div className="list-sticky">
                        <Headers state={state} />
                        <Filters
                            state={state} setState={setState}
                            onSearch={onSearch}
                            onSelectSearch={onSelectSearch}
                        />
                    </div>
                    <UserList state={state} />
                </div>
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container justify="flex-end" style={{ padding: 24 }}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    offset={state.offset}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            {/* -------------------- COLUMNS -------------------- */}
            {state.openColumns && (
                <ManageColumns
                    state={state}
                    setState={setState}
                    storedUserId={storedUserId}
                />
            )}
            {/* -------------------- CREATE -------------------- */}
            {state.openCreate && (
                <CreateUser
                    onClose={() => setState({ ...state, openCreate: false })}
                />
            )}
            {/* -------------------- MERGE -------------------- */}
            {state.openMerge && (
                <MergeUsers
                    onClose={() => setState({ ...state, openMerge: false })}
                    refetch={() => setState({ ...state, openMerge: false, loading: true })}
                />
            )}
        </>
    );
};

// --------------------------------------- HEADERS ----------------------------------------- \\
const Headers = ({ state }) => {
    return (
        <div className="list-headers">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_membership_status && (
                <div className="column-list">
                    Statut
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    Code client
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    Code client externe
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    Nom
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    Prénom
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    Nom de l'entreprise
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    Téléphone portable
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    Email
                </div>
            )}
            {/* -------------------- DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    Département adhé. / abo.
                </div>
            )}
            {/* -------------------- ROLE -------------------- */}
            {state.enabled_role && (
                <div style={{ width: 280, padding: 10 }}>
                    Type de compte
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div style={{ width: 280, padding: 10 }}>
                    Association fédérée
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    Téléphone fixe
                </div>
            )}
            {/* -------------------- OTHER PHONE -------------------- */}
            {state.enabled_other_phone && (
                <div className="column-list">
                    Autre téléphone
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    Email adhésion
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                    Pays
                </div>
            )}
            {/* -------------------- USER CATEGORY -------------------- */}
            {state.enabled_user_category && (
                <div className="column-list">
                    Rôle
                </div>
            )}
            {/* -------------------- BIRTH DATE -------------------- */}
            {state.enabled_birth_date && (
                <div className="column-list">
                    Date de naissance
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    Code postal
                </div>
            )}
            {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
            {state.enabled_membership_expiration && (
                <div className="column-list">
                    Date d'expiration adhé.
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
            {state.enabled_subscription_email && (
                <div className="column-list">
                    Email abonnement
                </div>
            )}
            {/* -------------------- BILL EMAIL -------------------- */}
            {state.enabled_bill_email && (
                <div className="column-list">
                    Email bon de commande
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_subscription_expiration && (
                <div className="column-list">
                    Date d'expiration abo.
                </div>
            )}
        </div>
    );
};

// --------------------------------------- FILTERS ----------------------------------------- \\
const Filters = ({ state, setState, onSearch, onSelectSearch }) => {
    return (
        <div className="list-filters">
            {/* -------------------- STATUS -------------------- */}
            {state.enabled_membership_status && (
                <div className="column-list">
                    <SelectMembershipStatuses
                        statuses={state.membership_statuses}
                        setStatuses={membership_statuses => onSelectSearch('membership_statuses', membership_statuses)}
                    />
                </div>
            )}
            {/* -------------------- CLIENT CODE -------------------- */}
            {state.enabled_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client"
                        value={state.client_code}
                        onChange={e => setState({ ...state, client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
            {state.enabled_external_client_code && (
                <div className="column-list">
                    <InputText
                        label="Code client externe"
                        value={state.external_client_code}
                        onChange={e => setState({ ...state, external_client_code: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- LAST NAME -------------------- */}
            {state.enabled_last_name && (
                <div className="column-list">
                    <InputText
                        label="Nom"
                        value={state.last_name}
                        onChange={e => setState({ ...state, last_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- FIRST NAME -------------------- */}
            {state.enabled_first_name && (
                <div className="column-list">
                    <InputText
                        label="Prénom"
                        value={state.first_name}
                        onChange={e => setState({ ...state, first_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COMPANY NAME -------------------- */}
            {state.enabled_company_name && (
                <div className="column-list">
                    <InputText
                        label="Nom de l'entreprise"
                        value={state.company_name}
                        onChange={e => setState({ ...state, company_name: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MOBILE PHONE -------------------- */}
            {state.enabled_mobile_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone portable"
                        value={state.mobile_phone}
                        onChange={e => setState({ ...state, mobile_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- EMAIL -------------------- */}
            {state.enabled_email && (
                <div className="column-list">
                    <InputText
                        label="Email"
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- DEPARTMENT -------------------- */}
            {state.enabled_french_department && (
                <div className="column-list">
                    <SelectFrenchDepartments
                        frenchDepartments={state.french_departments}
                        setFrenchDepartments={french_departments => onSelectSearch('french_departments', french_departments)}
                    />
                </div>
            )}
            {/* -------------------- ROLE -------------------- */}
            {state.enabled_role && (
                <div className="column-list">
                    <SelectRoles
                        roles={state.roles}
                        setRoles={roles => onSelectSearch('roles', roles)}
                    />
                </div>
            )}
            {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
            {state.enabled_federated_association && (
                <div className="column-list">
                    <SelectFederatedAssociations
                        federatedAssociations={state.federated_associations}
                        setFederatedAssociations={federated_associations => onSelectSearch('federated_associations', federated_associations)}
                    />
                </div>
            )}
            {/* -------------------- PHONE -------------------- */}
            {state.enabled_phone && (
                <div className="column-list">
                    <InputText
                        label="Téléphone fixe"
                        value={state.phone}
                        onChange={e => setState({ ...state, phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- OTHER PHONE -------------------- */}
            {state.enabled_other_phone && (
                <div className="column-list">
                    <InputText
                        label="Autre téléphone"
                        value={state.other_phone}
                        onChange={e => setState({ ...state, other_phone: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
            {state.enabled_membership_email && (
                <div className="column-list">
                    <InputText
                        label="Email adhésion"
                        value={state.membership_email}
                        onChange={e => setState({ ...state, membership_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- COUNTRY -------------------- */}
            {state.enabled_country && (
                <div className="column-list">
                   <SelectCountries
                        countries={state.countries}
                        setCountries={countries => onSelectSearch('countries', countries)}
                    />
                </div>
            )}
            {/* -------------------- USER CATEGORY -------------------- */}
            {state.enabled_user_category && (
                <div className="column-list">
                    <SelectUserCategories
                        userCategories={state.user_categories}
                        setUserCategories={user_categories => onSelectSearch('user_categories', user_categories)}
                    />
                </div>
            )}
            {/* -------------------- BIRTH DATE -------------------- */}
            {state.enabled_birth_date && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.birth_date}
                        onChange={e => setState({ ...state, birth_date: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- POSTAL CODE -------------------- */}
            {state.enabled_postal_code && (
                <div className="column-list">
                    <SelectCreatable
                        value={state.postal_codes}
                        onChange={postal_codes => onSelectSearch('postal_codes', postal_codes)}
                        placeholder="Code postal"
                    />
                </div>
            )}
            {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
            {state.enabled_membership_expiration && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.membership_expiration}
                        onChange={e => setState({ ...state, membership_expiration: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
            {state.enabled_subscription_email && (
                <div className="column-list">
                    <InputText
                        label="Email abonnement"
                        value={state.subscription_email}
                        onChange={e => setState({ ...state, subscription_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- BILL EMAIL -------------------- */}
            {state.enabled_bill_email && (
                <div className="column-list">
                    <InputText
                        label="Email bon de commande"
                        value={state.bill_email}
                        onChange={e => setState({ ...state, bill_email: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
            {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
            {state.enabled_subscription_expiration && (
                <div className="column-list">
                    <InputText
                        label="jj/mm/aaaa"
                        value={state.subscription_expiration}
                        onChange={e => setState({ ...state, subscription_expiration: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                    />
                </div>
            )}
        </div>
    );
};

// -------------------------------------- USER LIST ---------------------------------------- \\
const UserList = ({ state }) => {
    const history = useHistory();
    const match = useRouteMatch();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {state.users.length > 0 && (
                state.users.map(user => (
                    <Fragment key={user.id}>
                        <div
                            
                            onClick={() => history.push(`${match.url}/${user.id}`)}
                            className="simple-list"
                            style={{ display: 'inline-flex' }}
                        >
                            {/* -------------------- MEMBERSHIP STATUS -------------------- */}
                            {state.enabled_membership_status && (
                                <div className="column-list">
                                    <b style={{ color: getColorStatus(membershipStatuses, user.membershipStatus) }}>
                                        {getLabelStatus(membershipStatuses, user.membershipStatus)}
                                    </b>
                                </div>
                            )}
                            {/* -------------------- CLIENT CODE -------------------- */}
                            {state.enabled_client_code && (
                                <div className="column-list">
                                    {user.clientCode}
                                </div>
                            )}
                            {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                            {state.enabled_external_client_code && (
                                <div className="column-list">
                                    {user.external_client_code}
                                </div>
                            )}
                            {/* -------------------- LAST NAME -------------------- */}
                            {state.enabled_last_name && (
                                <div className="column-list">
                                    {user.last_name}
                                </div>
                            )}
                            {/* -------------------- FIRST NAME -------------------- */}
                            {state.enabled_first_name && (
                                <div className="column-list">
                                    {user.first_name}
                                </div>
                            )}
                            {/* -------------------- COMPANY NAME -------------------- */}
                            {state.enabled_company_name && (
                                <div className="column-list">
                                    {user.company_name}
                                </div>
                            )}
                            {/* -------------------- MOBILE PHONE -------------------- */}
                            {state.enabled_mobile_phone && (
                                <div className="column-list">
                                    {user.mobile_phone}
                                </div>
                            )}
                            {/* -------------------- EMAIL -------------------- */}
                            {state.enabled_email && (
                                <div className="column-list">
                                    {user.email}
                                </div>
                            )}
                            {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                            {state.enabled_french_department && (
                                <div className="column-list">
                                    {user.department ? (user.department.name + ' (' + user.department.number + ')') : ''}
                                </div>
                            )}
                            {/* -------------------- ROLE -------------------- */}
                            {state.enabled_role && (
                                <div className="column-list">
                                    {user.role ? user.role.display_name : ''}
                                </div>
                            )}
                            {/* -------------------- FEDERADTED ASSOCIATION -------------------- */}
                            {state.enabled_federated_association && (
                                <div className="column-list">
                                    {user.federatedAssociation ? user.federatedAssociation.name : ''}
                                </div>
                            )}
                            {/* -------------------- PHONE -------------------- */}
                            {state.enabled_phone && (
                                <div className="column-list">
                                    {user.phone}
                                </div>
                            )}
                            {/* -------------------- OTHER PHONE -------------------- */}
                            {state.enabled_other_phone && (
                                <div className="column-list">
                                    {user.other_phone}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                            {state.enabled_membership_email && (
                                <div className="column-list">
                                    {user.membershipEmail}
                                </div>
                            )}
                            {/* -------------------- COUNTRY -------------------- */}
                            {state.enabled_country && (
                                <div className="column-list">
                                    {user.country ? user.country.name : ''}
                                </div>
                            )}
                            {/* -------------------- USER CATEGORY -------------------- */}
                            {state.enabled_user_category && (
                                <div className="column-list">
                                    {user.userCategories.map((category, index) => {
                                        if (index === 0) return category.name;
                                        return ', ' + category.name;
                                    })}
                                </div>
                            )}
                            {/* -------------------- BIRTH DATE -------------------- */}
                            {state.enabled_birth_date && (
                                <div className="column-list">
                                    {user.birth_date ? getFormattedDate(user.birth_date) : ''}
                                </div>
                            )}
                            {/* -------------------- POSTAL CODE -------------------- */}
                            {state.enabled_postal_code && (
                                <div className="column-list">
                                    {user.postal_code}
                                </div>
                            )}
                            {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
                            {state.enabled_membership_expiration && (
                                <div className="column-list">
                                    {user.membershipExpiration ? getFormattedDate(user.membershipExpiration) : ''}
                                </div>
                            )}
                            {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
                            {state.enabled_subscription_email && (
                                <div className="column-list">
                                    {user.subscriptionEmail}
                                </div>
                            )}
                            {/* -------------------- BILL EMAIL -------------------- */}
                            {state.enabled_bill_email && (
                                <div className="column-list">
                                    {user.billEmail}
                                </div>
                            )}
                            {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
                            {state.enabled_subscription_expiration && (
                                <div className="column-list">
                                    {user.subscriptionExpiration ? getFormattedDate(user.subscriptionExpiration) : ''}
                                </div>
                            )}
                        </div>
                        <br />
                    </Fragment>
                ))
            )}
        </>
    );
};

// ------------------------------------ MANAGE COLUMNS ------------------------------------- \\
const ManageColumns = ({ state, setState, storedUserId }) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {

        const columns = {
            enabled_membership_status: state.enabled_membership_status,
            enabled_client_code: state.enabled_client_code,
            enabled_external_client_code: state.enabled_external_client_code,
            enabled_first_name: state.enabled_first_name,
            enabled_last_name: state.enabled_last_name,
            enabled_company_name: state.enabled_company_name,
            enabled_mobile_phone: state.enabled_mobile_phone,
            enabled_email: state.enabled_email,
            enabled_french_department: state.enabled_french_department,
            enabled_role: state.enabled_role,
            enabled_federated_association: state.enabled_federated_association,
            enabled_phone: state.enabled_phone,
            enabled_other_phone: state.enabled_other_phone,
            enabled_membership_email: state.enabled_membership_email,
            enabled_country: state.enabled_country,
            enabled_user_category: state.enabled_user_category,
            enabled_birth_date: state.enabled_birth_date,
            enabled_postal_code: state.enabled_postal_code,
            enabled_membership_expiration: state.enabled_membership_expiration,
            enabled_subscription_email: state.enabled_subscription_email,
            enabled_bill_email: state.enabled_bill_email,
            enabled_subscription_expiration: state.enabled_subscription_expiration,
        };

        if (state.loadingColumns) {
            updateIndexPreferences(storedUserId, {
                name: 'users',
                columns: JSON.stringify(columns),
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingColumns: false, openColumns: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingColumns: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingColumns]);

    return (
        <Dialog open>
            {state.loadingColumns ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            Gérer les colonnes affichées
                        </p>
                        <div style={{ display: 'flex' }}>
                            <div>
                                {/* -------------------- MEMBERSHIP STATUS -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_membership_status}
                                        onChange={e => setState({ ...state, enabled_membership_status: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Statut
                                </Grid>
                                {/* -------------------- CLIENT CODE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_client_code}
                                        onChange={e => setState({ ...state, enabled_client_code: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Code client
                                </Grid>
                                {/* -------------------- EXTERNAL CLIENT CODE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_external_client_code}
                                        onChange={e => setState({ ...state, enabled_external_client_code: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Code client externe
                                </Grid>
                                {/* -------------------- LAST NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_last_name}
                                        onChange={e => setState({ ...state, enabled_last_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Nom
                                </Grid>
                                {/* -------------------- FIRST NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_first_name}
                                        onChange={e => setState({ ...state, enabled_first_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Prénom
                                </Grid>
                                {/* -------------------- COMPANY NAME -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_company_name}
                                        onChange={e => setState({ ...state, enabled_company_name: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Nom de l'entreprise
                                </Grid>
                                {/* -------------------- MOBILE PHONE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_mobile_phone}
                                        onChange={e => setState({ ...state, enabled_mobile_phone: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Téléphone portable
                                </Grid>
                                {/* -------------------- EMAIL -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_email}
                                        onChange={e => setState({ ...state, enabled_email: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Email
                                </Grid>
                                {/* -------------------- FRENCH DEPARTMENT -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_french_department}
                                        onChange={e => setState({ ...state, enabled_french_department: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Département adhé. / abo.
                                </Grid>
                                {/* -------------------- ROLE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_role}
                                        onChange={e => setState({ ...state, enabled_role: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Type de compte
                                </Grid>
                                {/* -------------------- FEDERATED ASSOCIATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_federated_association}
                                        onChange={e => setState({ ...state, enabled_federated_association: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Association fédérée
                                </Grid>
                            </div>
                            <div>
                                {/* -------------------- PHONE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_phone}
                                        onChange={e => setState({ ...state, enabled_phone: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Téléphone fixe
                                </Grid>
                                {/* -------------------- OTHER PHONE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_other_phone}
                                        onChange={e => setState({ ...state, enabled_other_phone: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Autre téléphone
                                </Grid>
                                {/* -------------------- MEMBERSHIP EMAIL -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_membership_email}
                                        onChange={e => setState({ ...state, enabled_membership_email: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Email adhésion
                                </Grid>
                                {/* -------------------- COUNTRY -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_country}
                                        onChange={e => setState({ ...state, enabled_country: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Pays
                                </Grid>
                                {/* -------------------- USER CATEGORY -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_user_category}
                                        onChange={e => setState({ ...state, enabled_user_category: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Rôle
                                </Grid>
                                {/* -------------------- BIRTH DATE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_birth_date}
                                        onChange={e => setState({ ...state, enabled_birth_date: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date de naissance
                                </Grid>
                                {/* -------------------- POSTAL CODE -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_postal_code}
                                        onChange={e => setState({ ...state, enabled_postal_code: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Code postal
                                </Grid>
                                {/* -------------------- MEMBERSHIP EXPIRATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_membership_expiration}
                                        onChange={e => setState({ ...state, enabled_membership_expiration: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date d'expiration adhé.
                                </Grid>
                                {/* -------------------- SUBSCRIPTION EMAIL -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_subscription_email}
                                        onChange={e => setState({ ...state, enabled_subscription_email: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Email abonnement
                                </Grid>
                                {/* -------------------- BILL EMAIL -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_bill_email}
                                        onChange={e => setState({ ...state, enabled_bill_email: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Email dernier bon de commande
                                </Grid>
                                {/* -------------------- SUBSCRIPTION EXPIRATION -------------------- */}
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.enabled_subscription_expiration}
                                        onChange={e => setState({ ...state, enabled_subscription_expiration: e.target.checked })}
                                        style={{ padding: 6 }}
                                    />
                                    Date d'expiration abo.
                                </Grid>
                            </div>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={() => setState({ ...state, openColumns: false })} variant="contained">
                            Fermer
                        </Button>
                        <Button onClick={() => setState({ ...state, loadingColumns: true })} variant="contained">
                            Enregistrer
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UsersRouter;
