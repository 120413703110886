import React from 'react';

// Material-UI
import Select from '@material-ui/core/Select';

/* * *
* Composant de base pour sélectionner un élément
*/

// ---------------------------------------------------------------------------------------------- \\
// -------------------------------------- SELECT OUTLINED --------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const SelectOutlined = ({ children, value, onChange, disabled }) => {
    return (
        <Select
            value={value}
            onChange={onChange}
            disabled={disabled}
            fullWidth
            variant="outlined"
            SelectDisplayProps={{ style: { padding: '10px 14px', fontSize: 14 } }}
            MenuProps={{
                style: { maxHeight: 300, maxWidth: 500 },
                getContentAnchorEl: null, 
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
            }}
            style={{ minWidth: 120 }}
        >
            {children}
        </Select>
    );
};

// Propriétés par défaut
SelectOutlined.defaultProps = {
    disabled: false,
};

export default SelectOutlined;
