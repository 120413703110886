import React, { useState, useEffect } from 'react';
import Select from 'components/react-select';
import { getSubscriptionTypes } from 'services/requests';

// Column Width
let columnWidth = 220;

// -------------------- HEADER -------------------- \\
const SubscriptionTypeHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Type d'abonnement
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const SubscriptionTypeFilter = ({ state, onSelectSearch }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getSubscriptionTypes(true).then(res => {
            if (res.status === 200) {
                setOptions(res.data.data.subscriptionTypes.map(a => ({ label: a.name, value: a.id })));
            }
        });
    }, []);

    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <Select
                placeholder="Type d'abonnement"
                value={state.subscription_types}
                onChange={subscription_types => onSelectSearch('subscription_types', subscription_types)}
                options={options}
                isMulti
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const SubscriptionTypeValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { SubscriptionTypeHeader, SubscriptionTypeFilter, SubscriptionTypeValue };
