import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid, CircularProgress } from '@material-ui/core';

// Services
import { updateMembership } from 'services/requests';
import { getStoredUser } from 'services/storage';
// import { getGeocode } from 'services/helpers';

// Components
import InputText from 'components/input-text';
import FieldRequired from 'components/field-required';

// Views
import CoupleMembership from './couple-membership';
// import AddressForm from 'views/common/address-form';

// Selects
import SelectYesOrNo from 'selects/select-yes-or-no';
import SelectActivity from 'selects/select-activity';
import SelectActivities from 'selects/select-activities';

// Messages
import messages from 'messages.json';

// --------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- UPDATE MEMBERSHIP ---------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const UpdateMembership = ({ onClose, refetch, membership }) => {
    let storedUser = getStoredUser();
    const { pathname } = useLocation();
    const { userId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const isSecondUser = () => {
        if (
            pathname.includes('dashboard')
            && membership.linkedUsers.length > 0
            && membership.linkedUsers[0]?.id === storedUser?.id
        ) {
            return true;
        }
        if (
            pathname.includes('users')
            && membership.linkedUsers.length > 0
            && membership.linkedUsers[0]?.id === Number(userId)
        ) {
            return true;
        }
        return false;
    };

    const getSecondUserItem = item => {
        var value = '';
        if (pathname.includes('dashboard') || pathname.includes('users')) {
            value = (membership.linkedUsers.length > 0) ? membership.linkedUsers[0][item] : '';
        }
        if (pathname.includes('management')) {
            value = membership['second_user_' + item];
        }
        return value;
    };

    const [state, setState] = useState({
        loading: false,
        first_name: membership.first_name || '',
        last_name: membership.last_name || '',
        company_name: membership.company_name || '',
        phone: membership.phone || '',
        // address: membership.address || '',
        // address_details: membership.address_details || '',
        // city: membership.city || '',
        // postal_code: membership.postal_code || '',
        // country_id: membership.country_id || 1,
        // geocode: getGeocode(membership) || null,
        // email: membership.email || '',
        profile: membership.profile || '',
        main_activity_id: membership.mainActivity ? membership.mainActivity.id : -1,
        secondary_activities: membership.secondaryActivities ? membership.secondaryActivities.map(a => ({ label: a.name, value: a.id })) : [],
        is_demeter: membership.is_demeter || 0,
        is_biodyvin: membership.is_biodyvin || 0,
        second_user_email: getSecondUserItem('email') || '',
        second_user_first_name: getSecondUserItem('first_name') || '',
        second_user_last_name: getSecondUserItem('last_name') || '',
        second_user_is_verified: false,
        second_user_not_exist: false,
        second_user_loading: getSecondUserItem('email') ? true : false,
    });

    function onUpdate() {
        if (
            state.first_name === ''
            || state.last_name === ''
            // || state.address === ''
            // || state.city === ''
            // || state.postal_code === ''
            // || state.country_id === -1
            || state.main_activity_id === -1
        ) {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateMembership(membership.id, {
                first_name: state.first_name,
                last_name: state.last_name,
                company_name: state.company_name,
                phone: state.phone,
                // address: state.address,
                // address_details: state.address_details,
                // city: state.city,
                // postal_code: state.postal_code,
                // country_id: state.country_id,
                // email: state.email,
                profile: state.profile,
                main_activity_id: state.main_activity_id,
                secondary_activity_ids: state.secondary_activities ? state.secondary_activities.map(a => a.value) : null,
                is_demeter: state.is_demeter,
                is_biodyvin: state.is_biodyvin,
                second_user_email: membership.membership_type_id === 2 ? state.second_user_email : null,
                second_user_first_name: membership.membership_type_id === 2 ? state.second_user_first_name : null,
                second_user_last_name: membership.membership_type_id === 2 ? state.second_user_last_name : null,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog
            open
            fullWidth
        >
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    Modifier une adhésion
                </p>
                {/* ---------------------- FIRST NAME ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        Prénom<FieldRequired />
                    </Grid>
                    <Grid item xs={8}>
                        <InputText
                            label="Prénom"
                            value={state.first_name}
                            onChange={e => setState({ ...state, first_name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* ---------------------- LAST NAME ---------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={4}>
                        Nom<FieldRequired />
                    </Grid>
                    <Grid item xs={8}>
                        <InputText
                            label="Nom"
                            value={state.last_name}
                            onChange={e => setState({ ...state, last_name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* ---------------------- EMAIL ---------------------- */}
                {/*<Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={4}>
                        Email adhésion
                    </Grid>
                    <Grid item xs={8}>
                        <InputText
                            label="Email adhésion"
                            value={state.email}
                            onChange={e => setState({ ...state, email: e.target.value })}
                        />
                    </Grid>
                </Grid>*/}
                {/* ---------------------- COMPANY NAME ---------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={4}>
                        Nom de l'entreprise
                    </Grid>
                    <Grid item xs={8}>
                        <InputText
                            label="Nom de l'entreprise"
                            value={state.company_name}
                            onChange={e => setState({ ...state, company_name: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* ---------------------- ADDRESS ---------------------- */}
                {/* <AddressForm
                    state={state}
                    setState={setState}
                    disabledDepartment
                    requiredAddress
                    requiredPostalCode
                    requiredCity
                    requiredCountry
                /> */}
                {/* ---------------------- MAIN ACTIVITY ---------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={4}>
                        Activité principale<FieldRequired />
                    </Grid>
                    <Grid item xs={8}>
                        <SelectActivity
                            isPro={(membership.membershipType && membership.membershipType.is_pro === 1)}
                            label="Activité principale"
                            activityId={state.main_activity_id}
                            setActivityId={main_activity_id => setState({ ...state, main_activity_id })}
                        />
                    </Grid>
                </Grid>
                {/* ---------------------- PROFILE ---------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={4}>
                        Détails de l'activité
                    </Grid>
                    <Grid item xs={8}>
                        <InputText
                            label="Détails de l'activité"
                            value={state.profile}
                            onChange={e => setState({ ...state, profile: e.target.value })}
                        />
                    </Grid>
                </Grid>
                {/* ---------------------- SECONDARY ACTIVITY ---------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={4}>
                        Activité(s) secondaire(s)
                    </Grid>
                    <Grid item xs={8}>
                        <SelectActivities
                            isPro
                            label="Activité(s) secondaire(s)"
                            activities={state.secondary_activities}
                            setActivities={secondary_activities => setState({ ...state, secondary_activities })}
                        />
                    </Grid>
                </Grid>
                {/* ---------------------- IS DEMETER ---------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={4}>
                        Membre DEMETER
                    </Grid>
                    <Grid item xs={8}>
                        <SelectYesOrNo
                            label="Membre DEMETER"
                            value={state.is_demeter}
                            onChange={is_demeter => setState({ ...state, is_demeter })}
                            disabled={pathname.includes('dashboard')}
                        />
                    </Grid>
                </Grid>
                {/* ---------------------- IS BIODYVIN ---------------------- */}
                <Grid
                    container alignItems="center"
                    style={{ marginTop: 6 }}
                >
                    <Grid item xs={4}>
                        Membre BIODYVIN
                    </Grid>
                    <Grid item xs={8}>
                        <SelectYesOrNo
                            label="Membre BIODYVIN"
                            value={state.is_biodyvin}
                            onChange={is_biodyvin => setState({ ...state, is_biodyvin })}
                            disabled={pathname.includes('dashboard')}
                        />
                    </Grid>
                </Grid>
                {/* ---------------------- COUPLE ---------------------- */}
                {membership.membership_type_id === 2 && (
                    <>
                        <Divider style={{ margin: '24px 0px' }} />
                        <div>
                            <b>Adhésion couple</b>
                            <br />
                            <span className="secondary-color">
                                {isSecondUser() ? (
                                    `Vous bénéficiez de l'adhésion couple souscrite par ${membership.user?.fullName}`
                                ) : (
                                    `Le second bénéficiaire est ${state.second_user_first_name} ${state.second_user_last_name}`
                                )}
                            </span>
                        </div>
                        {(storedUser?.role?.id < 5 && !isSecondUser()) && (
                            <CoupleMembership
                                state={state}
                                setState={setState}
                            />
                        )}
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    disabled={state.loading}
                    variant="contained"
                >
                    Annuler
                </Button>
                <Button
                    onClick={onUpdate}
                    disabled={state.loading}
                    endIcon={state.loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                    variant="contained"
                >
                    Enregistrer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateMembership;
