import React from 'react';
import InputText from 'components/input-text';

// Column Width
let columnWidth = 180;

// -------------------- HEADER -------------------- \\
const FirstNameHeader = () => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            Prénom
        </div>
    );
};

// -------------------- FILTER -------------------- \\
const FirstNameFilter = ({ state, setState, onSearch }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            <InputText
                label="Prénom"
                value={state.first_name}
                onChange={e => setState({ ...state, first_name: e.target.value })}
                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
            />
        </div>
    );
};

// -------------------- VALUE -------------------- \\
const FirstNameValue = ({ value }) => {
    return (
        <div className="column-list" style={{ width: columnWidth }}>
            {value}
        </div>
    );
};

export { FirstNameHeader, FirstNameFilter, FirstNameValue };
