import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getGroup, updateGroup } from 'services/requests';
import { userCanDeleteGroup } from 'services/permissions';
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/input-text';
import Spinner from 'components/spinner';
import Error from 'components/error';
import FieldRequired from 'components/field-required';

// Messages
import messages from 'messages.json';

// Views
import DeleteGroup from '../delete-group';
import Users from './users';

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ UPDATE GROUP ------------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const UpateGroup = () => {
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const match = useRouteMatch();

    // Params
    const groupId = match.params.groupId;
    const backUrl = match.path.split('/:groupId')[0];

    const [state, setState] = useState({
        loading: true,
        error: false,
        group: null,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getGroup(groupId).then(res => {
                if (res.status === 200) {
                    setState({ ...state, loading: false, group: res.data.data.userList });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- SUBHEADER -------------------- */}
            <Grid container alignItems="center" className="subheader">
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-start' : 'center'}>
                    <Button
                        onClick={() => history.push(`${backUrl}/${groupId}`)}
                        variant="contained"
                    >
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justify="center" style={{ margin: mediaMD ? 0 : 10 }}>
                    <div style={{ textAlign: 'center' }}>
                        <b>Modifier le groupe</b>
                    </div>
                </Grid>
                <Grid item xs={12} md={4} container justify={mediaMD ? 'flex-end' : 'center'}>
                    {userCanDeleteGroup() && (
                        <Button
                            onClick={() => setState({ ...state, openDelete: true })}
                            variant="contained"
                        >
                            Supprimer
                        </Button>
                    )}
                </Grid>
            </Grid>
            {/* -------------------- UPDATE GROUP -------------------- */}
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <SetGroupName
                    group={state.group}
                />
            </Grid>
            <Divider />
            {/* -------------------- USERS -------------------- */}
            <Users
                group={state.group}
            />
            {/* -------------------- DELETE GROUP -------------------- */}
            {state.openDelete && (
                <DeleteGroup
                    group={state.group}
                    onClose={() => setState({ ...state, openDelete: false })}
                />
            )}
        </>
    );
};

// --------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- SET GROUP NAME ------------------------------------------ \\
// --------------------------------------------------------------------------------------------------- \\

const SetGroupName = ({ group }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { groupId } = useParams();

    const [state, setState] = useState({
        loading: false,
        error: false,
        name: group.name || '',
    });

    function onSave() {
        if (state.name === '') {
            enqueueSnackbar(messages['fields.required'], { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateGroup(groupId, { name: state.name }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loading: false });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);
    
    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <Grid item xs={12} md={10} lg={8}>
                {/* -------------------- NAME -------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <span>Nom du Groupe</span><FieldRequired />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <InputText
                            label="Nom du groupe"
                            type="text"
                            value={state.name}
                            onChange={e => setState({ ...state, name: e.target.value })}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* -------------------- SAVE BUTTON -------------------- */}
            <Grid item xs={12} md={2} lg={4} container alignItems="center" justify="flex-end">
                <Button onClick={onSave} variant="contained">
                    Enregistrer
                </Button>
            </Grid>
        </>
    );
};

export default UpateGroup;
