import React, { useState, useEffect } from 'react';

// ExcelJS
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button } from '@material-ui/core';
import { GetApp as ExportIcon } from '@material-ui/icons';

// Services
import { getMemberships } from 'services/requests';
import { getFormattedDate, getLabelStatus, getMonthYearDate, getFormattedCreatedAt } from 'services/helpers';
import { subscriptionStatuses, transferStatuses, billStates, billStatuses } from 'services/constants';

// Components
import Tooltip from 'components/tooltip';

// ---------------------------------------------------------------------------------------------- \\
// ------------------------------------- MEMBERSHIPS EXPORT ------------------------------------- \\
// ---------------------------------------------------------------------------------------------- \\

const MembershipsExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        percentage: '0%',
    });

    function getStringFromArray(array) {
        let result = '';
        array.forEach((item, index) => {
            if (index === 0) {
                result += item.name;
            }
            else {
                result += ', ' + item.name;
            }
        });
        return result;
    }

    function getAllMembershipsToExport(memberships, offset) {
        getMemberships({
            offset: offset,
            limit: data.limit,
            membership_statuses: data.membership_statuses ? data.membership_statuses.map(s => s.value) : null,
            order_by: data.order_by,
            order: data.order,
            client_code: data.client_code,
            external_client_code: data.external_client_code,
            adherent_type: data.adherent_type,
            membership_type_ids: data.membership_types ? data.membership_types.map(s => s.value) : null,
            last_name: data.last_name,
            first_name: data.first_name,
            company_name: data.company_name,
            mobile_phone: data.mobile_phone,
            membership_email: data.membership_email,
            french_department_ids: data.french_departments ? data.french_departments.map(d => d.value) : null,
            cities: data.cities ? data.cities.map(c => c.value) : null,
            year_membership_expirations: data.year_membership_expirations ? data.year_membership_expirations.map(e => e.value) : null,
            membership_expiration: data.membership_expiration,
            address: data.address,
            address_details: data.address_details,
            country_ids: data.countries ? data.countries.map(c => c.value) : null,
            phone: data.phone,
            other_phone: data.other_phone,
            email: data.email,
            subscription_expiration: data.subscription_expiration,
            month_year_subscription_expirations: data.month_year_subscription_expirations ? data.month_year_subscription_expirations.map(e => e.value) : null,
            user_category_ids: data.user_categories ? data.user_categories.map(c => c.value) : null,
            birth_date: data.birth_date,
            federated_association_ids: data.federated_associations ? data.federated_associations.map(a => a.value) : null,
            subscription_email: data.subscription_email,
            bill_email: data.bill_email,
            is_biodyvin: data.is_biodyvin,
            is_demeter: data.is_demeter,
            main_activity_ids: data.main_activities ? data.main_activities.map(a => a.value) : null,
            secondary_activity_ids: data.secondary_activities ? data.secondary_activities.map(a => a.value) : null,
            postal_codes: data.postal_codes ? data.postal_codes.map(c => c.value) : null,
            transfer_statuses: data.transfer_statuses ? data.transfer_statuses.map(s => s.value) : null,
            created_at: data.created_at,
            bill_state: data.bill_state,
            bill_reference: data.bill_reference,
            bill_statuses: data.bill_statuses ? data.bill_statuses.map(s => s.value) : null,
            user_list_ids: data.user_list_ids,
            renew_status: data.renew_status ? data.renew_status.value : null,
        }).then(res => {
            if (res.status === 200) {

                let res_memberships = res.data.data.memberships;
                memberships = memberships.concat(res_memberships);

                setState({
                    ...state,
                    percentage: Math.round((100 * memberships.length) / data.total_count) + '%',
                });

                if (res_memberships.length < data.limit) {
                    createExcelFile(memberships);
                }
                else {
                    getAllMembershipsToExport(memberships, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, loading: false });
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
            }
        });
    }

    function createExcelFile(memberships) {
        if (memberships.length === 0) {
            setState({ ...state, loading: false });
            enqueueSnackbar('Aucune donnée à exporter', { variant: 'warning' });
        }
        else {

            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet('Adhésions');

            // Headers
            let columns = [];
            if (data.enabled_membership_status) {
                columns.push({ header: 'Statut', key: 'status', width: 30 });
            }
            if (data.enabled_client_code) {
                columns.push({ header: 'Code client', key: 'client_code', width: 30 });
            }
            if (data.enabled_external_client_code) {
                columns.push({ header: 'Code client externe', key: 'external_client_code', width: 30 });
            }
            if (data.enabled_adherent_type) {
                columns.push({ header: 'Type d\'adhérent', key: 'adherent_type', width: 30 });
            }
            if (data.enabled_membership_type) {
                columns.push({ header: 'Type d\'adhésion', key: 'membership_type', width: 30 });
            }
            if (data.enabled_last_name) {
                columns.push({ header: 'Nom', key: 'last_name', width: 30 });
            }
            if (data.enabled_first_name) {
                columns.push({ header: 'Prénom', key: 'first_name', width: 30 });
            }
            if (data.enabled_company_name) {
                columns.push({ header: 'Nom de l\'entreprise', key: 'company_name', width: 30 });
            }
            if (data.enabled_mobile_phone) {
                columns.push({ header: 'Téléphone portable', key: 'mobile_phone', width: 30 });
            }
            if (data.enabled_membership_email) {
                columns.push({ header: 'Email adhésion', key: 'membership_email', width: 30 });
            }
            if (data.enabled_french_department) {
                columns.push({ header: 'Département', key: 'department', width: 30 });
            }
            if (data.enabled_city) {
                columns.push({ header: 'Ville', key: 'city', width: 30 });
            }
            if (data.enabled_year_membership_expiration) {
                columns.push({ header: 'Année de validité adhé.', key: 'year_membership_expiration', width: 30 });
            }
            if (data.enabled_membership_expiration) {
                columns.push({ header: 'Date d\'expiration adhé.', key: 'membership_expiration', width: 30 });
            }
            if (data.enabled_address) {
                columns.push({ header: 'Adresse (n° et voie)', key: 'address', width: 30 });
            }
            if (data.enabled_address_details) {
                columns.push({ header: 'Adresse (complément)', key: 'address_details', width: 30 });
            }
            if (data.enabled_country) {
                columns.push({ header: 'Pays', key: 'country', width: 30 });
            }
            if (data.enabled_phone) {
                columns.push({ header: 'Téléphone', key: 'phone', width: 30 });
            }
            if (data.enabled_other_phone) {
                columns.push({ header: 'Autre téléphone', key: 'other_phone', width: 30 });
            }
            if (data.enabled_email) {
                columns.push({ header: 'Email compte', key: 'email', width: 30 });
            }
            if (data.enabled_subscription_expiration) {
                columns.push({ header: 'Date d\'expiration abo.', key: 'subscription_expiration', width: 30 });
            }
            if (data.enabled_month_year_subscription_expiration) {
                columns.push({ header: 'Mois / Année expiration abo.', key: 'month_year_subscription_expiration', width: 30 });
            }
            if (data.enabled_user_category) {
                columns.push({ header: 'Rôle', key: 'user_category', width: 30 });
            }
            if (data.enabled_birth_date) {
                columns.push({ header: 'Date de naissance', key: 'birth_date', width: 30 });
            }
            if (data.enabled_federated_association) {
                columns.push({ header: 'Association fédérée', key: 'federated_association', width: 30 });
            }
            if (data.enabled_subscription_email) {
                columns.push({ header: 'Email abonnement', key: 'subscription_email', width: 30 });
            }
            if (data.enabled_bill_email) {
                columns.push({ header: 'Email bon de commande', key: 'bill_email', width: 30 });
            }
            if (data.enabled_is_biodyvin) {
                columns.push({ header: 'Adhérent Biodyvin', key: 'is_biodyvin', width: 30 });
            }
            if (data.enabled_is_demeter) {
                columns.push({ header: 'Adhérent Demeter', key: 'is_demeter', width: 30 });
            }
            if (data.enabled_main_activity) {
                columns.push({ header: 'Activité principale', key: 'main_activity', width: 30 });
            }
            if (data.enabled_secondary_activity) {
                columns.push({ header: 'Activité secondaire', key: 'secondary_activity', width: 30 });
            }
            if (data.enabled_postal_code) {
                columns.push({ header: 'Code postal', key: 'postal_code', width: 30 });
            }
            if (data.enabled_transfer_status) {
                columns.push({ header: 'Statut du transfert', key: 'transfer_status', width: 30 });
            }
            if (data.enabled_created_at) {
                columns.push({ header: 'Date BC', key: 'created_at', width: 30 });
            }
            if (data.enabled_bill_state) {
                columns.push({ header: 'Etat BC', key: 'bill_state', width: 30 });
            }
            if (data.enabled_bill_reference) {
                columns.push({ header: 'Référence BC', key: 'bill_reference', width: 30 });
            }
            if (data.enabled_bill_status) {
                columns.push({ header: 'Statut BC', key: 'bill_status', width: 30 });
            }
            if (data.enabled_renew_status) {
                columns.push({ header: 'Statut renouvellement', key: 'renew_status', width: 30 });
            }
            worksheet.columns = columns;
            worksheet.getRow(1).font = { bold: true };

            // Rows
            memberships.map(membership => {
                return worksheet.addRow({
                    status: getLabelStatus(subscriptionStatuses, membership.status),
                    client_code: membership.user ? membership.user.clientCode : '',
                    external_client_code: membership.user ? membership.user.external_client_code : '',
                    adherent_type: membership.membershipType && membership.membershipType.is_pro === 0 ? 'Particulier' : 'Professionnel',
                    membership_type: membership.membershipType ? membership.membershipType.name : '',
                    last_name: membership.user ? membership.user.last_name : '',
                    first_name: membership.user ? membership.user.first_name : '',
                    company_name: membership.company_name || '',
                    mobile_phone: membership.user ? membership.user.mobile_phone : '',
                    membership_email: membership.user ? membership.user.membershipEmail : '',
                    department: (membership.user && membership.user.department) ? (membership.user.department.name + ' (' + membership.user.department.number + ')') : '',
                    city: membership.city || '',
                    year_membership_expiration: membership.end_date ? membership.end_date.split('-')[0] : '',
                    membership_expiration: membership.end_date ? getFormattedDate(membership.end_date) : '',
                    address: membership.address || '',
                    address_details: membership.address_details || '',
                    country: (membership.user && membership.user.country) ? membership.user.country.name : '',
                    phone: membership.user ? membership.user.phone : '',
                    other_phone: membership.user ? membership.user.other_phone : '',
                    email: membership.user ? membership.user.email : '',
                    subscription_expiration: (membership.user && membership.user.subscriptionExpiration) ? getFormattedDate(membership.user.subscriptionExpiration) : '',
                    month_year_subscription_expiration: (membership.user && membership.user.subscriptionExpiration) ? getMonthYearDate(membership.user.subscriptionExpiration) : '',
                    user_category: (membership.user && membership.user.userCategories) ? getStringFromArray(membership.user.userCategories) : '',
                    birth_date: (membership.user && membership.user.birth_date) ? getFormattedDate(membership.user.birth_date) : '',
                    federated_association: (membership.user && membership.user.federatedAssociation) ? membership.user.federatedAssociation.name : '',
                    subscription_email: membership.user ? membership.user.subscriptionEmail : '',
                    bill_email: membership.user ? membership.user.billEmail : '',
                    is_biodyvin: membership.is_biodyvin === 0 ? 'Non' : 'Oui',
                    is_demeter: membership.is_demeter === 0 ? 'Non' : 'Oui',
                    main_activity: membership.mainActivity ? membership.mainActivity.name : '',
                    secondary_activity: membership.secondaryActivities ? getStringFromArray(membership.secondaryActivities) : '',
                    postal_code: membership.postal_code || '',
                    transfer_status: getLabelStatus(transferStatuses, membership.transfer_status),
                    created_at: membership.created_at ? getFormattedCreatedAt(membership.created_at) : '',
                    bill_state: membership.bill_state ? getLabelStatus(billStates, membership.bill_state) : '',
                    bill_reference: membership.bill_reference || '',
                    bill_status: getLabelStatus(billStatuses, membership.bill_status),
                    renew_status: membership.renewStatus === 'renewed' ? 'Renouvelé' : 'Non renouvelé',
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'adhesions.xlsx'));
            setState({ ...state, loading: false });
            enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.loading) {
            getAllMembershipsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Tooltip title="Exporter sous Excel"
            item={(
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={state.loading}
                    variant="contained"
                    style={{ marginLeft: 5, marginRight: 5 }}
                >
                    {state.loading ? (
                        state.percentage
                    ) : (
                        <ExportIcon />
                    )}
                </Button>
            )}
        />
    );
};

export default MembershipsExport;
