import React, { Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon } from '@material-ui/icons';

// Services
import { getStoredUser } from 'services/storage';
import { getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Tooltip from 'components/tooltip';
import DatePicker from 'components/date-picker';
import InputText from 'components/input-text';
import Pagination from 'components/pagination';
import Spinner from 'components/spinner';
import Error from 'components/error';
import SelectMultiple from 'components/select-multiple';

// Selects
import SelectCategory from 'selects/select-category';
import SelectFrenchDepartments from 'selects/select-french-departments';

// --------------------------------------------------------------------------------------------------- \\
// ------------------------------------- UPCOMING SESSION LIST --------------------------------------- \\
// --------------------------------------------------------------------------------------------------- \\

const UpcomingSessionList = ({ state, setState }) => {
    const storedUserId = getStoredUser()?.id;
    const history = useHistory();
    const { userId } = useParams();
    const { mediaMD } = useMediaQueries();

    function onSearch() {
        setState({ ...state, loading: true, error: false, offset: 0 });
    }

    function onSelectSearch(item, value) {
        setState({ ...state, [item]: value, loading: true, error: false, offset: 0 });
    }

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            status: null,
            begin: null,
            end: null,
            session_date: null,
            name: '',
            category_id: -1,
            french_departments: [],
            trainersFilter: [],
            managersFilter: [],
        });
    }

    function setOffset(offset) {
        let paginatedFormationSessions = state.formationSessions.filter((s, index) => index >= offset && index < (offset + state.limit));
        setState({ ...state, paginatedFormationSessions, offset });
    }

    function getPushUrl(formationSession) {
        if (userId) {
            return '/users/' + userId + '/formations/' + formationSession.id + '/session';
        }
        if (state.members_registration) {
            return '/formations/' + formationSession.id + '/session/' + storedUserId + '/members';
        }
        return '/formations/' + formationSession.id + '/session/' + storedUserId;
    }

    return (
        <div style={{ padding: 24 }}>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid
                container justify="space-between" alignItems="center"
                style={{ paddingBottom: 24 }}
            >
                {mediaMD && (
                    <Grid
                        item xs={12} md={4}
                        container justify={mediaMD ? 'flex-start' : 'center'}
                    >
                        <Tooltip title="Chercher"
                            item={(
                                <Button
                                    onClick={onSearch}
                                    variant="contained"
                                >
                                    <SearchIcon />
                                </Button>
                            )}
                        />
                        <Tooltip title="Annuler la recherche"
                            item={(
                                <Button
                                    onClick={cancelSearch}
                                    variant="contained"
                                    style={{ marginLeft: 6 }}
                                >
                                    <CancelIcon />
                                </Button>
                            )}
                        />
                    </Grid>
                )}
                <Grid
                    item xs={12} md={4}
                    container justify="center"
                    style={{ padding: mediaMD ? 0 : 10 }}
                >
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        offset={state.offset}
                        setOffset={offset => setOffset(offset)}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justify={mediaMD ? 'flex-end' : 'center'}
                >
                    <b>
                        {state.total_count} {state.total_count > 1 ? 'Formations' : 'Formation'}
                    </b>
                </Grid>
            </Grid>
            {/* -------------------- HEADERS -------------------- */}
            {mediaMD ? (
                <Grid
                    container alignItems="center"
                    className="list-headers"
                >
                    {/* <Grid item xs={1} style={{ padding: 10 }}>
                        Statut
                    </Grid> */}
                    <Grid
                        item xs={2}
                        style={{ padding: 10 }}
                    >
                        Catégorie
                    </Grid>
                    <Grid
                        item xs={2}
                        style={{ padding: 10 }}
                    >
                        Nom de la formation
                    </Grid>
                    <Grid
                        item xs={1}
                        style={{ padding: 10 }}
                    >
                        À partir de
                    </Grid>
                    <Grid
                        item xs={1}
                        style={{ padding: 10 }}
                    >
                        Jusqu'au
                    </Grid>
                    <Grid
                        item xs={2}
                        style={{ padding: 10 }}
                    >
                        Départements
                    </Grid>
                    <Grid
                        item xs={2}
                        style={{ padding: 10 }}
                    >
                        Formateurs
                    </Grid>
                    <Grid
                        item xs={2}
                        style={{ padding: 10 }}
                    >
                        Responsable
                    </Grid>
                </Grid>
            ) : (
                <Divider />
            )}
            {/* -------------------- FILTERS -------------------- */}
            {mediaMD && (
                <>
                    <Grid container alignItems="center">
                        {/* <Grid item xs={1} style={{ padding: 10 }}>
                            <Select
                                placeholder="Statut"
                                value={state.status}
                                onChange={status => onSelectSearch('status', status)}
                                options={[{ value: 'available', label: 'Disponible' }, { value: 'closed',  label: 'Clôturée' }]}
                            />
                        </Grid> */}
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        >
                            <SelectCategory
                                categoryId={state.category_id}
                                setCategoryId={category_id => onSelectSearch('category_id', category_id)}
                            />
                        </Grid>
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        >
                            <InputText
                                label="Nom de la formation"
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={e => e.key === 'Enter' ? onSearch() : null}
                            />
                        </Grid>
                        <Grid
                            item xs={1}
                            style={{ padding: 10 }}
                        >
                            <DatePicker
                                outlined
                                value={state.begin}
                                onChange={begin => onSelectSearch('begin', begin)}
                            />
                        </Grid>
                        <Grid
                            item xs={1}
                            style={{ padding: 10 }}
                        >
                            <DatePicker
                                outlined
                                value={state.end}
                                onChange={end => onSelectSearch('end', end)}
                            />
                        </Grid>
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        >
                            <SelectFrenchDepartments
                                frenchDepartments={state.french_departments}
                                setFrenchDepartments={french_departments => onSelectSearch('french_departments', french_departments)}
                            />
                        </Grid>
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        >
                            <SelectMultiple
                                value={state.trainersFilter}
                                onChange={value => onSelectSearch('trainersFilter', value)}
                                options={state.trainers}
                                placeholder="Formateurs"
                            />
                        </Grid>
                        <Grid
                            item xs={2}
                            style={{ padding: 10 }}
                        >
                            <SelectMultiple
                                value={state.managersFilter}
                                onChange={value => onSelectSearch('managersFilter', value)}
                                options={state.managers}
                                placeholder="Responsable"
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- LIST -------------------- */}
            {state.loading ? (
                <Spinner />
            ) : state.error ? (
                <Error />
            ) : (
                <>
                    {state.paginatedFormationSessions.length > 0 ? (
                        state.paginatedFormationSessions.map(formationSession => (
                            <Fragment key={formationSession.id}>
                                <Grid
                                    onClick={() => history.push(getPushUrl(formationSession))}
                                    container alignItems="center"
                                    className="simple-list"
                                >
                                    {/* <Grid
                                        item xs={12} md={1}
                                        style={{ padding: 10 }}
                                    >
                                    <b style={{ color: getColorStatus(formationSessionStatuses, formationSession.status) }}>
                                        {getLabelStatus(formationSessionStatuses, formationSession.status)}
                                    </b>
                                    </Grid> */}
                                    <Grid
                                        item xs={12} md={2}
                                        style={{ padding: 10 }}
                                    >
                                        {formationSession.categories ? formationSession.categories.map(c => c.name).join(', ') : ''}
                                    </Grid>
                                    <Grid
                                        item xs={12} md={2}
                                        style={{ padding: 10 }}
                                    >
                                        {formationSession.name}
                                    </Grid>
                                    <Grid
                                        item xs={12} md={1}
                                        style={{ padding: 10 }}
                                    >
                                        {getFormattedDate(formationSession.begin)}
                                    </Grid>
                                    <Grid
                                        item xs={12} md={1}
                                        style={{ padding: 10 }}
                                    >
                                        {getFormattedDate(formationSession.end)}
                                    </Grid>
                                    <Grid
                                        item xs={12} md={2}
                                        style={{ padding: 10 }}
                                    >
                                        {formationSession.frenchDepartments
                                            ? formationSession.frenchDepartments.map(fd => fd.name + ` (${fd.number})`).join(', ')
                                            : null
                                        }
                                    </Grid>
                                    <Grid
                                        item xs={12} md={2}
                                        style={{ padding: 10 }}
                                    >
                                        {formationSession.trainers ? formationSession.trainers.map(t => t.fullName).join(', ') : ''}
                                    </Grid>
                                    <Grid
                                        item xs={12} md={2}
                                        style={{ padding: 10 }}
                                    >
                                        {formationSession.manager?.fullName}
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <>
                            <div style={{ padding: 10 }}>
                                Aucune
                            </div>
                            <Divider />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default UpcomingSessionList;
